import { useFormik } from "formik";
import * as Yup from "yup";
import { useDropzone } from "react-dropzone";
import { uploadMultipleFileApi } from "../../../services/_other";
import { errorToast, successToast } from "../../../components/Toast";

const UploadMultipleFileDropzone = () => {
  const uploadMultipleFileValidationSchema = Yup.object().shape({
    media: Yup.array()
      .of(
        Yup.object().shape({
          fileName: Yup.string().required("File is required"),
          fileData: Yup.string().required("File is required"),
        })
      )
      .min(1, "At least one file is required"),
  });

  const formik = useFormik({
    initialValues: {
      media: [],
    },
    validationSchema: uploadMultipleFileValidationSchema,
    onSubmit: async (values, { resetForm }) => {
      const result: any = await uploadMultipleFileApi(values);
      if (!result.isSuccess) {
        errorToast(result.message);
        return;
      } else {
        successToast(result.message);
        resetForm();
        formik.setFieldValue("media", []);
      }
    },
  });

  const { getRootProps, getInputProps } = useDropzone({
    multiple: true,
    onDrop: (acceptedFiles) => {
      const filePromises = acceptedFiles.map(
        (file) =>
          new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
              resolve({
                fileName: file.name,
                fileData: reader.result,
              });
            };
            reader.onerror = (error) => reject(error);
          })
      );

      Promise.all(filePromises)
        .then((newFiles) => {
          formik.setFieldValue("media", [...formik.values.media, ...newFiles]);
        })
        .catch((error) => {
          console.error("File loading error: ", error);
          errorToast(
            "Something went wrong while uploading file, please try again letter!"
          );
        });
    },
  });

  const removeFile = (fileName: any) => {
    const updatedFiles = formik.values.media.filter(
      (file: any) => file.fileName !== fileName
    );
    formik.setFieldValue("media", updatedFiles);
  };

  return (
    <div className="card mt-6">
      <div className="card-header pb-0 d-flex justify-content-between">
        <div>
          <h5>Upload Multiple Files (Dropzone)</h5>
        </div>
      </div>
      <div className="card-body">
        <form onSubmit={formik.handleSubmit}>
          <div className="row">
            <div className="col-12">
              <div {...getRootProps()} className="custom-dropzone">
                <input {...getInputProps()} />
                <p>Drag & drop files here, or click to select files</p>
              </div>

              {formik.touched.media && formik.errors.media && (
                <div className="text-danger">{formik.errors.media}</div>
              )}

              <div className="custom-dropzone-file-list">
                {formik.values.media.map((file: any, index) => (
                  <div key={index} className="custom-dropzone-file-item">
                    <span>{file.fileName}</span>
                    <button
                      type="button"
                      className="custom-dropzone-remove-button"
                      onClick={() => removeFile(file.fileName)}
                    >
                      x
                    </button>
                  </div>
                ))}
              </div>
            </div>
            <div className="col-12">
              <button type="submit" className="btn btn-primary">
                Upload
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default UploadMultipleFileDropzone;
