import { GET, POST, PUT } from "./_config";

export const getRoleListApi = async () => await GET(`/role/list`, true);

export const getAllRoleList = async (query: string) =>
  await GET(`/role?${query || ""}`, true);

export const updateRoleStatusApi = async (body: any) =>
  await PUT(`/role/update-status`, body, true);

export const getAllPermissionApi = async () =>
  await GET(`/role/all-permissions`, true);

export const createRoleApi = async (body: any) =>
  await POST(`/role`, body, true);

export const getRoleDetailsByIdApi = async (id: any) =>
  await GET(`/role/id/${id}`, true);

export const updateRole = async (id: any, body: any) =>
  await PUT(`/role?id=${id}`, body, true);

const RoleService = {
  getRoleListApi,
  getAllRoleList,
  updateRoleStatusApi,
  getAllPermissionApi,
  createRoleApi,
  getRoleDetailsByIdApi,
  updateRole,
};

export default RoleService;
