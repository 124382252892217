/* eslint-disable react-hooks/exhaustive-deps */
import { useNavigate, useParams } from "react-router-dom";
import * as Yup from "yup";
import { Formik, Form } from "formik";
import { useEffect, useState } from "react";
import { getBankByIdApi, updateBankApi } from "../../services/_bank";
import { errorToast, successToast } from "../../components/Toast";
import { getSupplierNameListApi } from "../../services/_supplier";
import { getAllLogisticApi } from "../../services/_logistic";
import { getAllChaApi } from "../../services/_cha";
import { BankType } from "../../components/Enums";
import { getBuyerNameListApi } from "../../services/_buyer";
import { getAllBrokerApi } from "../../services/_broker";
import BankForm from "./BankForm";

const updateBankInitialValues = {
  type: "",
  relation_id: "",
  bank_name: "",
  account_number: "",
  ifsc_code: "",
  branch_name: "",
  swift_code: "",
  ad_code: "",
  status: "",
  address: "",
};

const EditBank = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [relationList, setRelationList] = useState([]);
  const [initialValues, setInitialValues] = useState(updateBankInitialValues);
  const bankDetailsValidationSchema = Yup.object().shape({
    type: Yup.string().required("Type is required"),
    relation_id: Yup.string().required("required"),
    bank_name: Yup.string().required("Bank Name is required"),
    account_number: Yup.string().required("Account Number is required"),
    ifsc_code: Yup.string().required("IFSC is required"),
    branch_name: Yup.string().required("Branch Name is required"),
    swift_code: Yup.string().nullable(),
    ad_code: Yup.string().nullable(),
    status: Yup.string().required("Status is required"),
    address: Yup.string().nullable(),
  });

  const getBankDetails = async () => {
    const result: any = await getBankByIdApi(id as string);
    if (result.isSuccess) {
      setInitialValues({
        type: result?.data?.type,
        relation_id:
          result.data.buyer_id ||
          result.data.cha_id ||
          result.data.logistic_id ||
          result.data.supplier_id ||
          result.data.broker_id ||
          "",
        bank_name: result?.data?.bank_name,
        account_number: result?.data?.account_number,
        ifsc_code: result?.data?.ifsc_code,
        branch_name: result?.data?.branch_name,
        swift_code: result?.data?.swift_code || "",
        ad_code: result?.data?.ad_code || "",
        status: result?.data?.status?.toString(),
        address: result?.data?.address || "",
      });
      handleTypeChange(result?.data?.type);
    }
  };

  useEffect(() => {
    if (!id) {
      navigate("/bank-list");
    } else {
      getBankDetails();
    }
  }, []);

  const handleTypeChange = async (filterType: any) => {
    if (filterType === BankType.EXPORTER) {
      setRelationList([]);
    }
    if (filterType === BankType.BUYER) {
      const result: any = await getBuyerNameListApi();
      if (result.isSuccess) {
        setRelationList(result?.data);
      }
    }
    if (filterType === BankType.SUPPLIER) {
      const result: any = await getSupplierNameListApi();
      if (result.isSuccess) {
        setRelationList(result?.data);
      }
    }
    if (filterType === BankType.LOGISTIC) {
      const result: any = await getAllLogisticApi();
      if (result.isSuccess) {
        setRelationList(result?.data);
      }
    }
    if (filterType === BankType.CHA) {
      const result: any = await getAllChaApi();
      if (result.isSuccess) {
        setRelationList(result?.data);
      }
    }
    if (filterType === BankType.BROKER) {
      const result: any = await getAllBrokerApi();
      if (result.isSuccess) {
        setRelationList(result?.data);
      }
    }
  };

  const handleSubmit = async (values: any, { resetForm }: any) => {
    values.status = values.status === "true" ? true : false;
    const result: any = await updateBankApi(id as string, values);
    if (result.isSuccess) {
      successToast(result.message);
      resetForm({ values: updateBankInitialValues });
      navigate("/bank-list");
    } else {
      errorToast(result.message);
    }
    return;
  };
  return (
    <>
      <div className="card">
        <div className="card-header pb-0">
          <div>
            <h5>Edit Bank</h5>
          </div>
        </div>
        <div className="card-body">
          <Formik
            enableReinitialize={true}
            initialValues={initialValues}
            validationSchema={bankDetailsValidationSchema}
            onSubmit={handleSubmit}
          >
            {({ values, handleChange, setFieldValue }) => {
              return (
                <Form className="dt_adv_search">
                  <BankForm
                    relationList={relationList}
                    handleTypeChange={handleTypeChange}
                    values={values}
                    handleChange={handleChange}
                    setFieldValue={setFieldValue}
                  />
                </Form>
              );
            }}
          </Formik>
        </div>
      </div>
    </>
  );
};

export default EditBank;
