/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { ErrorMessage, Field, Form, Formik } from "formik";
import * as Yup from "yup";
import {
  PaymentMethods,
  PaymentRole,
  PaymentStatus,
  TransactionTypes,
} from "../../components/Enums";
import { formatString } from "../../components/Helper";
import { Link } from "react-router-dom";
import { getSupplierNameListApi } from "../../services/_supplier";
import { getAllLogisticApi } from "../../services/_logistic";
import { getAllExpensesApi } from "../../services/_expenses";
import { getBuyerNameListApi } from "../../services/_buyer";
import { getAllChaApi } from "../../services/_cha";
import { getAllBrokerApi } from "../../services/_broker";
import {
  getAllMiscellaneousSalesApi,
  getAllSalesContractApi,
  getAllSalesInvoiceApi,
} from "../../services/_sales";
import { getAllPurchasesApi } from "../../services/_purchase";
import { CURRENCY } from "../../components/Constants";
import { DatePicker } from "rsuite";

const PaymentForm = ({
  initialValues,
  handleSubmit,
  submitLoader,
  fetchInitialPaymentData,
}: any) => {
  const [relationList, setRelationList] = useState([]);
  const [salesList, setSalesList] = useState([]);
  const [salesOrder, setSalesOrderList] = useState([]);
  const [miscellaneousSalesList, setMiscellaneousSalesList] = useState([]);
  const [purchaseList, setPurchaseList] = useState([]);
  const [filterType, setFilterType] = useState<any>();
  const [filterSalesType, setFilterSalesType] = useState<any>();
  const roleWiseField: { [key: string]: string[] } = {
    BUYER: [
      "SALES_TYPE",
      "SALES_CONTRACT",
      "SALES_INVOICE",
      "MISCELLANEOUS_SALES",
      "CURRENCY_EXCHANGE",
    ],
    BROKER: ["SALES_CONTRACT", "CURRENCY_EXCHANGE"],
    CHA: ["SALES_CONTRACT", "SALES_INVOICE"],
    LOGISTIC: ["SALES_CONTRACT", "SALES_INVOICE"],
    EXPENSE: ["SALES_CONTRACT", "SALES_INVOICE"],
    SUPPLIER: ["PURCHASE_INVOICE"],
  };

  const isFieldAccess = (fieldName: string, payment_role?: string): boolean => {
    return (
      roleWiseField[payment_role ? payment_role : filterType]?.includes(
        fieldName
      ) || false
    );
  };

  const checkAccessSalesField = (fieldName: string, role?: string): boolean => {
    const paymentRole = role ? role : filterType;

    if (!isFieldAccess(fieldName, paymentRole)) {
      return false;
    }
    if (!isFieldAccess("SALES_TYPE", paymentRole)) {
      return true;
    }

    if (
      (fieldName === "SALES_CONTRACT" || fieldName === "SALES_INVOICE") &&
      filterSalesType === "SALES"
    ) {
      return true;
    }
    if (
      fieldName === "MISCELLANEOUS_SALES" &&
      filterSalesType === "MISCELLANEOUS_SALES"
    ) {
      return true;
    }

    return false;
  };

  const resetDynamicFieldValues = (setFieldValue: any, fieldName?: string) => {
    if (fieldName === "payment_role") {
      setFieldValue("sales_type", "");
      setFieldValue("relation_id", "");
      setFieldValue("sales_contract_id", "");
      setFieldValue("sales_invoice_id", "");
      setFieldValue("miscellaneous_sales_id", "");
      setFieldValue("purchase_id", "");
    }
    if (fieldName === "relation_id") {
      setFieldValue("sales_contract_id", "");
      setFieldValue("sales_invoice_id", "");
      setFieldValue("miscellaneous_sales_id", "");
      setFieldValue("purchase_id", "");
    }
    if (fieldName === "sales_type") {
      setFieldValue("sales_contract_id", "");
      setFieldValue("sales_invoice_id", "");
      setFieldValue("miscellaneous_sales_id", "");
    }
    if (fieldName === "sales_contract_id") {
      setFieldValue("sales_invoice_id", "");
    }
  };

  const validationSchema = Yup.object().shape({
    sales_type: isFieldAccess("SALES_TYPE")
      ? Yup.string().required("Sales Type is required")
      : Yup.string().optional(),
    sales_contract_id: checkAccessSalesField("SALES_CONTRACT")
      ? Yup.string().required("Sales contract is required")
      : Yup.string().optional(),
    sales_invoice_id: checkAccessSalesField("SALES_INVOICE")
      ? Yup.string().required("Sales order is required")
      : Yup.string().optional(),
    miscellaneous_sales_id: checkAccessSalesField("MISCELLANEOUS_SALES")
      ? Yup.string().required("Miscellaneous Sales Order is required")
      : Yup.string().optional(),
    purchase_id: isFieldAccess("PURCHASE_INVOICE")
      ? Yup.string().required("Purchase is required")
      : Yup.string().optional(),
    payment_role: Yup.string().required("Payment role is required"),
    transaction_type: Yup.string().required("Transaction type is required"),
    payment_method: Yup.string().required("Payment method is required"),
    payment_status: Yup.string().required("Payment status is required"),
    transaction_no: Yup.string().optional(),
    amount: Yup.number()
      .required("Amount is required")
      .test(
        "amount-required",
        "Amount must be a positive value",
        function (value: any) {
          return !(value <= 0);
        }
      ),
    transaction_date: Yup.date().required("Transaction date is required"),
    relation_id: Yup.string().required("This field is required"),
    remark: Yup.string().optional(),
    exchange_currency: Yup.string().nullable().optional(),
    exchange_rate: Yup.string()
      .nullable()
      .test(
        "is-valid-decimal",
        "Exchange rate must be a positive value",
        function (value: any) {
          return !(value < 0);
        }
      ),
    exchange_amount: Yup.string()
      .nullable()
      .test(
        "is-valid-decimal",
        "Exchange No. must be a positive value",
        function (value: any) {
          return !(value < 0);
        }
      ),
  });

  const fetchSalesContractList = async (query?: string) => {
    const sales: any = await getAllSalesContractApi(query && query);
    if (sales.isSuccess) {
      setSalesList(sales.data);
    }
  };

  const fetchSalesOrderList = async (query?: string) => {
    const salesOrders: any = await getAllSalesInvoiceApi(query && query);
    if (salesOrders.isSuccess) {
      setSalesOrderList(salesOrders.data);
    }
  };

  const fetchPurchaseOrderList = async (query?: string) => {
    const purchaseList: any = await getAllPurchasesApi(query && query);
    if (purchaseList.isSuccess) {
      setPurchaseList(purchaseList.data);
    }
  };

  const fetchMiscellaneousSalesOrder = async (query?: string) => {
    const miscellaneousSalesList: any = await getAllMiscellaneousSalesApi(
      query && query
    );
    if (miscellaneousSalesList.isSuccess) {
      setMiscellaneousSalesList(miscellaneousSalesList?.data);
    }
  };

  useEffect(() => {
    const fetchTypeData = async () => {
      if (filterType === PaymentRole.SUPPLIER) {
        const result: any = await getSupplierNameListApi();
        if (result.isSuccess) {
          setRelationList(result?.data);
        }
      }
      if (filterType === PaymentRole.LOGISTIC) {
        const result: any = await getAllLogisticApi();
        if (result.isSuccess) {
          setRelationList(result?.data);
        }
      }
      if (filterType === PaymentRole.EXPENSE) {
        const result: any = await getAllExpensesApi();
        if (result.isSuccess) {
          setRelationList(result?.data);
        }
      }
      if (filterType === PaymentRole.BUYER) {
        const result: any = await getBuyerNameListApi();
        if (result.isSuccess) {
          setRelationList(result?.data);
        }
      }
      if (filterType === PaymentRole.CHA) {
        const result: any = await getAllChaApi();
        if (result.isSuccess) {
          setRelationList(result?.data);
        }
      }
      if (filterType === PaymentRole.BROKER) {
        const result: any = await getAllBrokerApi();
        if (result.isSuccess) {
          setRelationList(result?.data);
        }
      }
    };
    fetchTypeData();
  }, [filterType]);

  const handleChangePaymentRole = async (value: any) => {
    if (checkAccessSalesField("SALES_CONTRACT", value)) {
      await fetchSalesContractList();
    }
    if (checkAccessSalesField("SALES_INVOICE", value)) {
      await fetchSalesOrderList();
    }
    if (isFieldAccess("PURCHASE_INVOICE", value)) {
      await fetchPurchaseOrderList();
    }
  };

  const handleChangeStackHolder = async (paymentRole: string, id: string) => {
    if (paymentRole === PaymentRole.SUPPLIER) {
      const query = `supplier_id=${id}`;
      fetchPurchaseOrderList(query);
    }
    if (
      (paymentRole === PaymentRole.BUYER && filterSalesType === "SALES") ||
      paymentRole === PaymentRole.CHA ||
      paymentRole === PaymentRole.LOGISTIC ||
      paymentRole === PaymentRole.EXPENSE ||
      paymentRole === PaymentRole.BROKER
    ) {
      let query = "";
      if (paymentRole === PaymentRole.BUYER) {
        query = `buyer_id=${id}`;
      }
      if (paymentRole === PaymentRole.CHA) {
        query = `cha_id=${id}`;
      }
      if (paymentRole === PaymentRole.LOGISTIC) {
        query = `logistic_id=${id}`;
      }
      if (paymentRole === PaymentRole.BROKER) {
        query = `broker_id=${id}`;
      }
      fetchSalesContractList(query);
      fetchSalesOrderList(query);
    }
    if (
      paymentRole === PaymentRole.BUYER &&
      filterSalesType === "MISCELLANEOUS_SALES"
    ) {
      const query = `buyer_id=${id}`;
      fetchMiscellaneousSalesOrder(query);
    }
  };

  const handleChangeSalesType = async (salesType: string, id?: string) => {
    const query = id && `buyer_id=${id}`;

    if (filterSalesType !== salesType) {
      setFilterSalesType(salesType);
    }

    if (salesType === "SALES") {
      fetchSalesContractList(query);
      fetchSalesOrderList(query);
    }
    if (salesType === "MISCELLANEOUS_SALES") {
      fetchMiscellaneousSalesOrder(query);
    }
  };

  const handleChangeSalesContract = async (
    contract_id: string,
    relation_id?: string
  ) => {
    let query = `sales_contract_id=${contract_id}`;
    if (filterType === PaymentRole.BUYER) {
      query += `&buyer_id=${relation_id}`;
    }
    if (filterType === PaymentRole.CHA) {
      query += `&cha_id=${relation_id}`;
    }
    if (filterType === PaymentRole.LOGISTIC) {
      query += `&logistic_id=${relation_id}`;
    }
    fetchSalesOrderList(query);
  };

  const handleEditPaymentInitialData = async () => {
    const data = await fetchInitialPaymentData();
    if (data.payment_role) {
      setFilterType(data.payment_role);
      setFilterSalesType(data.sales_type);
      if (isFieldAccess("SALES_TYPE", data.payment_role)) {
        handleChangeSalesType(data.sales_type, data.buyer_id);
      } else {
        handleChangeStackHolder(
          data.payment_role,
          data.buyer_id ||
            data.cha_id ||
            data.expense_id ||
            data.logistic_id ||
            data.supplier_id ||
            data.broker_id ||
            ""
        );
      }
    }
  };

  useEffect(() => {
    if (fetchInitialPaymentData) {
      handleEditPaymentInitialData();
    }
  }, []);

  return (
    <Formik
      enableReinitialize={true}
      initialValues={initialValues as any}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({ handleChange, values, setFieldValue }) => {
        return (
          <Form className="dt_adv_search">
            <div className="row">
              <div className="col-12">
                <div className="row g-3">
                  <div className="col-12 col-sm-6 col-lg-4">
                    <label
                      htmlFor="transaction_type"
                      className="form-label"
                      style={{ fontSize: "0.9375rem" }}
                    >
                      Transaction Type: <span className="text-danger">*</span>
                    </label>
                    <div className="form-control custom-radio-form-control">
                      <div className="form-check form-check-inline custom-inline-radio">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="transaction_type"
                          id={`transaction_type_${TransactionTypes.CREDIT}"`}
                          defaultValue={TransactionTypes.CREDIT}
                          checked={
                            values.transaction_type === TransactionTypes.CREDIT
                          }
                          onChange={handleChange}
                          disabled={filterType === PaymentRole.EXPENSE}
                        />
                        <label
                          className="form-check-label"
                          htmlFor={`transaction_type_${TransactionTypes.CREDIT}"`}
                        >
                          {TransactionTypes.CREDIT}
                        </label>
                      </div>
                      <div className="form-check form-check-inline custom-inline-radio">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="transaction_type"
                          id={`transaction_type_${TransactionTypes.DEBIT}"`}
                          defaultValue={TransactionTypes.DEBIT}
                          checked={
                            values.transaction_type === TransactionTypes.DEBIT
                          }
                          onChange={handleChange}
                        />
                        <label
                          className="form-check-label"
                          htmlFor={`transaction_type_${TransactionTypes.DEBIT}"`}
                        >
                          {TransactionTypes.DEBIT}
                        </label>
                      </div>
                    </div>
                    <ErrorMessage
                      name="transaction_type"
                      className="text-danger"
                      component="div"
                    />
                  </div>

                  <div className="col-12 col-sm-6 col-lg-4">
                    <label
                      htmlFor="payment_role"
                      className="form-label"
                      style={{ fontSize: "0.9375rem" }}
                    >
                      Payment Role: <span className="text-danger">*</span>
                    </label>
                    <select
                      id="payment_role"
                      name="payment_role"
                      className="form-select"
                      value={values.payment_role}
                      onChange={(e) => {
                        handleChange(e);
                        setFilterType(e.target.value);
                        setFilterSalesType("");
                        setFieldValue(
                          "transaction_type",
                          e.target.value === PaymentRole.EXPENSE
                            ? TransactionTypes.DEBIT
                            : values.transaction_type
                        );
                        handleChangePaymentRole(e.target.value);
                        resetDynamicFieldValues(setFieldValue, e.target.name);
                      }}
                    >
                      <option value="">Select Payment Role</option>
                      {[
                        PaymentRole.BUYER,
                        PaymentRole.SUPPLIER,
                        PaymentRole.CHA,
                        PaymentRole.LOGISTIC,
                        PaymentRole.EXPENSE,
                        PaymentRole.BROKER,
                      ].map((item) => (
                        <option value={item} key={item}>
                          {item}
                        </option>
                      ))}
                    </select>
                    <ErrorMessage
                      name="payment_role"
                      className="text-danger"
                      component="div"
                    />
                  </div>

                  {values.payment_role && (
                    <div className="col-12 col-sm-6 col-lg-4">
                      <label
                        className="form-label"
                        style={{ fontSize: "0.9375rem" }}
                      >
                        {filterType
                          ? formatString(filterType)
                          : "Select Stackholder"}
                        : <span className="text-danger">*</span>
                      </label>
                      <select
                        id="relation_id"
                        name="relation_id"
                        className="form-select"
                        value={values.relation_id}
                        onChange={(e: any) => {
                          handleChange(e);
                          handleChangeStackHolder(filterType, e.target.value);
                          resetDynamicFieldValues(setFieldValue, e.target.name);
                          setFieldValue(
                            "transaction_type",
                            filterType === PaymentRole.EXPENSE
                              ? TransactionTypes.DEBIT
                              : values.transaction_type
                          );
                        }}
                      >
                        <option defaultChecked value={""}>
                          Select{" "}
                          {filterType ? formatString(filterType) : "Type"}
                        </option>
                        {relationList.length > 0 &&
                          relationList.map((data: any) => (
                            <option key={data.id} value={data.id}>
                              {data.name}
                            </option>
                          ))}
                      </select>
                      <ErrorMessage
                        name="relation_id"
                        className="text-danger"
                        component="div"
                      />
                    </div>
                  )}

                  {isFieldAccess("SALES_TYPE") && (
                    <div className="col-12 col-sm-6 col-lg-4">
                      <label
                        htmlFor="sales_type"
                        className="form-label"
                        style={{ fontSize: "0.9375rem" }}
                      >
                        Select Sales Type:
                        <span className="text-danger">*</span>
                      </label>
                      <select
                        name="sales_type"
                        className="form-select"
                        id="sales_type"
                        value={values.sales_type}
                        onChange={(e: any) => {
                          handleChange(e);
                          resetDynamicFieldValues(setFieldValue, e.target.name);
                          setFilterSalesType(e.target.value);
                          handleChangeSalesType(
                            e.target.value,
                            values.relation_id ?? values.relation_id
                          );
                        }}
                      >
                        <option value="">Select Sales Type:</option>
                        {["MISCELLANEOUS_SALES", "SALES"].map((type: any) => {
                          return (
                            <option value={type} key={type}>
                              {formatString(type)}
                            </option>
                          );
                        })}
                      </select>
                      <ErrorMessage
                        name="sales_type"
                        className="text-danger"
                        component="div"
                      />
                    </div>
                  )}

                  {checkAccessSalesField("SALES_CONTRACT") && (
                    <div className="col-12 col-sm-6 col-lg-4">
                      <label
                        htmlFor="sales"
                        className="form-label"
                        style={{ fontSize: "0.9375rem" }}
                      >
                        Sales Contract: <span className="text-danger">*</span>
                      </label>
                      <select
                        name="sales_contract_id"
                        className="form-select"
                        id="sales_contract_id"
                        value={values.sales_contract_id}
                        onChange={(e: any) => {
                          handleChange(e);
                          handleChangeSalesContract(
                            e.target.value,
                            values.relation_id ?? values.relation_id
                          );
                          resetDynamicFieldValues(setFieldValue, e.target.name);
                        }}
                      >
                        <option defaultChecked value={""}>
                          Select Sales Contract
                        </option>
                        {salesList.map((e: any) => {
                          return (
                            <option value={e.id} key={e.id}>
                              {e.contract_no}
                            </option>
                          );
                        })}
                      </select>
                      <ErrorMessage
                        name="sales_contract_id"
                        className="text-danger"
                        component="div"
                      />
                    </div>
                  )}

                  {checkAccessSalesField("SALES_INVOICE") && (
                    <div className="col-12 col-sm-6 col-lg-4">
                      <label
                        htmlFor="sales_order"
                        className="form-label"
                        style={{ fontSize: "0.9375rem" }}
                      >
                        Sales Order: <span className="text-danger">*</span>
                      </label>
                      <select
                        name="sales_invoice_id"
                        className="form-select"
                        id="sales_invoice_id"
                        value={values.sales_invoice_id}
                        onChange={handleChange}
                      >
                        <option defaultChecked value={""}>
                          Select Sales Invoice No
                        </option>
                        {salesOrder.map((e: any) => {
                          return (
                            <option value={e.id} key={e.id}>
                              {e.invoice_no}
                            </option>
                          );
                        })}
                      </select>
                      <ErrorMessage
                        name="sales_invoice_id"
                        className="text-danger"
                        component="div"
                      />
                    </div>
                  )}

                  {checkAccessSalesField("MISCELLANEOUS_SALES") && (
                    <div className="col-12 col-sm-6 col-lg-4">
                      <label
                        htmlFor="miscellaneous_sales_id"
                        className="form-label"
                        style={{ fontSize: "0.9375rem" }}
                      >
                        Select Miscellaneous Sales :
                        <span className="text-danger">*</span>
                      </label>
                      <select
                        name="miscellaneous_sales_id"
                        className="form-select"
                        id="miscellaneous_sales_id"
                        value={values.miscellaneous_sales_id}
                        onChange={(e: any) => {
                          handleChange(e);
                        }}
                      >
                        <option value="">Select Miscellaneous Sales</option>
                        {miscellaneousSalesList.map((e: any) => {
                          return (
                            <option value={e.id} key={e.id}>
                              {e.invoice_no}
                            </option>
                          );
                        })}
                      </select>
                      <ErrorMessage
                        name="miscellaneous_sales_id"
                        className="text-danger"
                        component="div"
                      />
                    </div>
                  )}

                  {isFieldAccess("PURCHASE_INVOICE") && (
                    <div className="col-12 col-sm-6 col-lg-4">
                      <label
                        htmlFor="sales"
                        className="form-label"
                        style={{ fontSize: "0.9375rem" }}
                      >
                        Purchase: <span className="text-danger">*</span>
                      </label>
                      <select
                        name="purchase_id"
                        className="form-select"
                        id="purchase_id"
                        value={values.purchase_id}
                        onChange={handleChange}
                      >
                        <option defaultChecked value={""}>
                          Select Purchase
                        </option>
                        {purchaseList &&
                          purchaseList.length > 0 &&
                          purchaseList.map((e: any) => {
                            return (
                              <option value={e.id} key={e.id}>
                                {e.invoice_no}
                              </option>
                            );
                          })}
                      </select>
                      <ErrorMessage
                        name="purchase_id"
                        className="text-danger"
                        component="div"
                      />
                    </div>
                  )}

                  <div className="col-12 col-sm-6 col-lg-4">
                    <label
                      htmlFor="amount"
                      className="form-label"
                      style={{ fontSize: "0.9375rem" }}
                    >
                      Amount ({CURRENCY.INR.SYMBOL}):{" "}
                      <span className="text-danger">*</span>
                    </label>
                    <Field
                      id="amount"
                      name="amount"
                      type="number"
                      className="form-control"
                      placeholder="Enter amount"
                      min={0}
                      value={values.amount}
                      onChange={handleChange}
                    />
                    <ErrorMessage
                      name="amount"
                      className="text-danger"
                      component="div"
                    />
                  </div>

                  {isFieldAccess("CURRENCY_EXCHANGE") && (
                    <>
                      <div className="col-12 col-sm-6 col-lg-4">
                        <label
                          htmlFor="exchange_currency"
                          className="form-label"
                          style={{ fontSize: "0.9375rem" }}
                        >
                          Exchange Currency:
                        </label>
                        <select
                          id="exchange_currency"
                          name="exchange_currency"
                          className="form-select"
                          value={values.exchange_currency || ""}
                          onChange={handleChange}
                          disabled={true}
                        >
                          <option selected value={CURRENCY.USD.NAME}>
                            {CURRENCY.USD.NAME}
                          </option>
                        </select>
                      </div>
                      <div className="col-12 col-sm-6 col-lg-4">
                        <label
                          htmlFor="exchange_amount"
                          className="form-label"
                          style={{ fontSize: "0.9375rem" }}
                        >
                          Exchange Amount ({CURRENCY.USD.SYMBOL}):
                        </label>
                        <Field
                          id="exchange_amount"
                          name="exchange_amount"
                          type="number"
                          className="form-control"
                          placeholder="Enter exchange amount"
                          value={values.exchange_amount}
                          onChange={handleChange}
                        />
                        <ErrorMessage
                          name="exchange_amount"
                          className="text-danger"
                          component="div"
                        />
                      </div>
                      <div className="col-12 col-sm-6 col-lg-4">
                        <label
                          htmlFor="exchange_rate"
                          className="form-label"
                          style={{ fontSize: "0.9375rem" }}
                        >
                          Exchange Rate ({CURRENCY.INR.SYMBOL}):
                        </label>
                        <Field
                          id="exchange_rate"
                          name="exchange_rate"
                          type="number"
                          className="form-control"
                          placeholder="Enter exchange rate"
                          value={values.exchange_rate}
                          onChange={handleChange}
                        />
                        <ErrorMessage
                          name="exchange_rate"
                          className="text-danger"
                          component="div"
                        />
                      </div>
                    </>
                  )}

                  <div className="col-12 col-sm-6 col-lg-4">
                    <label
                      htmlFor="payment_method"
                      className="form-label"
                      style={{ fontSize: "0.9375rem" }}
                    >
                      Payment Method: <span className="text-danger">*</span>
                    </label>
                    <select
                      id="payment_method"
                      name="payment_method"
                      className="form-select"
                      value={values.payment_method}
                      onChange={handleChange}
                    >
                      <option defaultChecked value={""}>
                        Select Payment Method
                      </option>
                      {[
                        PaymentMethods.BANK_TRANSFER,
                        PaymentMethods.CASH,
                        PaymentMethods.CHEQUE,
                        PaymentMethods.UPI,
                      ].map((item) => (
                        <option value={item} key={item}>
                          {item.replace("_", " ")}
                        </option>
                      ))}
                    </select>
                    <ErrorMessage
                      name="payment_method"
                      className="text-danger"
                      component="div"
                    />
                  </div>

                  <div className="col-12 col-sm-6 col-lg-4">
                    <label
                      htmlFor="transaction_no"
                      className="form-label"
                      style={{ fontSize: "0.9375rem" }}
                    >
                      Transaction No.:{" "}
                    </label>
                    <Field
                      id="transaction_no"
                      name="transaction_no"
                      type="text"
                      className="form-control"
                      placeholder="Enter transaction number"
                      value={values.transaction_no}
                      onChange={handleChange}
                    />
                    <ErrorMessage
                      name="transaction_no"
                      className="text-danger"
                      component="div"
                    />
                  </div>

                  <div className="col-12 col-sm-6 col-lg-4 d-flex flex-column">
                    <label
                      htmlFor="transaction_date"
                      className="form-label"
                      style={{ fontSize: "0.9375rem" }}
                    >
                      Transaction Date: <span className="text-danger">*</span>
                    </label>
                    <DatePicker
                      id="transaction_date"
                      name="transaction_date"
                      format="dd-MM-yyyy"
                      className="form-control"
                      placeholder="Select Transaction Date"
                      value={values.transaction_date}
                      onChange={(value: any) => {
                        setFieldValue("transaction_date", value);
                      }}
                    />
                    <ErrorMessage
                      name="date"
                      className="text-danger"
                      component="div"
                    />
                  </div>

                  <div className="col-12 col-sm-6 col-lg-4">
                    <label
                      htmlFor="payment_status"
                      className="form-label"
                      style={{ fontSize: "0.9375rem" }}
                    >
                      Payment Status: <span className="text-danger">*</span>
                    </label>
                    <select
                      id="payment_status"
                      name="payment_status"
                      className="form-select"
                      value={values.payment_status}
                      onChange={(e) => {
                        setFieldValue(
                          "payment_status",
                          e.target.value.toUpperCase()
                        );
                      }}
                    >
                      <option defaultChecked value={""}>
                        Select Payment Status
                      </option>
                      {[
                        PaymentStatus.PENDING,
                        PaymentStatus.ADVANCE,
                        PaymentStatus.PARTIALLY_PAID,
                        PaymentStatus.COMPLETED,
                      ].map((item) => (
                        <option value={item} key={item}>
                          {formatString(item)}
                        </option>
                      ))}
                    </select>
                    <ErrorMessage
                      name="payment_status"
                      className="text-danger"
                      component="div"
                    />
                  </div>

                  <div className="col-12 col-sm-6 col-lg-4">
                    <label
                      htmlFor="remark"
                      className="form-label"
                      style={{ fontSize: "0.9375rem" }}
                    >
                      Remark:
                    </label>
                    <Field
                      id="remark"
                      name="remark"
                      type="text"
                      className="form-control"
                      placeholder="Enter remark"
                      value={values.remark}
                      onChange={handleChange}
                    />
                  </div>

                  <div className="col-12">
                    <button
                      type="submit"
                      className="btn btn-primary"
                      disabled={submitLoader}
                    >
                      {submitLoader && (
                        <span
                          className="spinner-border spinner-border-sm me-2"
                          role="status"
                          aria-hidden="true"
                        ></span>
                      )}
                      Submit
                    </button>
                    <Link
                      to={"/payment-list"}
                      type="button"
                      className="btn btn-label-github ms-5"
                    >
                      Back
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

export default PaymentForm;
