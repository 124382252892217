import { Link } from "react-router-dom";
import { COMPANY_NAME } from "../components/Constants";

const Footer = () => {
  return (
    <footer className="content-footer footer bg-footer-theme">
      <div className="container-xxl">
        <div className="footer-container d-flex align-items-center justify-content-between py-4 flex-md-row flex-column">
          <div className="text-body">
            © 2024{" "}
            <Link className="footer-link" to="#">
              {COMPANY_NAME}
            </Link>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
