import {
  formatDate,
  formatAmount,
  formatString,
  formatDateTime,
  getFileNameFromUrl,
} from "../../../components/Helper";
import { pdf } from "@react-pdf/renderer";
import { useEffect, useState } from "react";
import Spinner from "../../../theme/Spinner";
import { CURRENCY } from "../../../components/Constants";
import { getSettingsApi } from "../../../services/_settings";
import { Link, useNavigate, useParams } from "react-router-dom";
import { getSalesInvoiceDetailsByIdApi } from "../../../services/_sales";
import { DocumentType, SelfDocuments } from "../../../components/Enums";
import PhytoSanitaryCertificate from "./invoices/PhytoSanitaryCertificate";
import AnnexureInvoice from "./invoices/AnnexureInvoice";
import CommercialInvoice from "./invoices/CommercialInvoice";
import ExportInvoiceCumPackingList from "./invoices/ExportInvoiceCumPackingList";
import ProformaInvoice from "./invoices/ProformaInvoice";
import BlDraftInvoice from "./invoices/BlDraftInvoice";
import HealthAndQualityCertificate from "./invoices/HealthAndQualityCertificate";
import CertificateOfNonGmo from "./invoices/CertificateOfNonGmo";
import WeightAndQualityCertificate from "./invoices/WeightAndQualityCertificate";
import CertificateOfOrigin from "./invoices/CertificateOfOrigin";

const ViewSalesInvoice: React.FC = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [salesInvoiceData, setSalesInvoiceData] = useState<any>({});
  const [loading, setLoading] = useState<boolean>(false);
  const [settingData, setSettingData] = useState<any>({});
  const [isReadyDownload, setIsReadyDownload] = useState(false);

  const [phytoCertificatePreviewLoading, setPhytoCertificatePreviewLoading] =
    useState<boolean>(false);
  const [phytoCertificateDownloadLoading, setPhytoCertificateDownloadLoading] =
    useState<boolean>(false);
  const [annexureInvoicePreviewLoading, setAnnexureInvoicePreviewLoading] =
    useState<boolean>(false);
  const [annexureInvoiceDownloadLoading, setAnnexureInvoiceDownloadLoading] =
    useState<boolean>(false);
  const [commercialInvoicePreviewLoading, setCommercialInvoicePreviewLoading] =
    useState<boolean>(false);
  const [
    commercialInvoiceDownloadLoading,
    setCommercialInvoiceDownloadLoading,
  ] = useState<boolean>(false);
  const [packingListPreviewLoading, setPackingListPreviewLoading] =
    useState<boolean>(false);
  const [packingListDownloadLoading, setPackingListDownloadLoading] =
    useState<boolean>(false);
  const [proformaInvoicePreviewLoading, setProformaInvoicePreviewLoading] =
    useState<boolean>(false);
  const [proformaInvoiceDownloadLoading, setProformaInvoiceDownloadLoading] =
    useState<boolean>(false);
  const [billOfLadingPreviewLoading, setBillOfLadingPreviewLoading] =
    useState<boolean>(false);
  const [billOfLadingDownloadLoading, setBillOfLadingDownloadLoading] =
    useState<boolean>(false);
  const [healthAndQualityPreviewLoading, setHealthAndQualityPreviewLoading] =
    useState<boolean>(false);
  const [healthAndQualityDownloadLoading, setHealthAndQualityDownloadLoading] =
    useState<boolean>(false);
  const [
    certificateOfNonGmoPreviewLoading,
    setCertificateOfNonGmoPreviewLoading,
  ] = useState<boolean>(false);
  const [
    certificateOfNonGmoDownloadLoading,
    setCertificateOfNonGmoDownloadLoading,
  ] = useState<boolean>(false);
  const [weightAndQualityPreviewLoading, setWeightAndQualityPreviewLoading] =
    useState<boolean>(false);
  const [weightAndQualityDownloadLoading, setWeightAndQualityDownloadLoading] =
    useState<boolean>(false);
  const [
    certificateOfOriginPreviewLoading,
    setCertificateOfOriginPreviewLoading,
  ] = useState<boolean>(false);
  const [
    certificateOfOriginDownloadLoading,
    setCertificateOfOriginDownloadLoading,
  ] = useState<boolean>(false);

  useEffect(() => {
    if (!id) {
      navigate(`sales-invoice-list`);
    }
  });

  useEffect(() => {
    const fetchInitialData = async () => {
      setLoading(true);
      const result: any = await getSalesInvoiceDetailsByIdApi(id as string);
      if (result.isSuccess) {
        setSalesInvoiceData(result.data);
        setTimeout(async () => {
          const shipperDetails: any = await getSettingsApi();
          if (shipperDetails.isSuccess) {
            const data = Object.entries(shipperDetails.data).reduce(
              (acc: any, setting: any) => {
                acc[setting[0]] = setting[1];
                return acc;
              },
              {}
            );
            setSettingData(data);
          }
          setIsReadyDownload(true);
        }, 500);
      }
      setLoading(false);
    };
    fetchInitialData();
  }, [id]);

  const previewPhytoSanitaryCertificate = async () => {
    setPhytoCertificatePreviewLoading(true);
    try {
      if (isReadyDownload) {
        const pdfDoc = await pdf(
          <PhytoSanitaryCertificate
            settingData={settingData}
            salesInvoiceData={salesInvoiceData}
          />
        ).toBlob();
        const pdfUrl = URL.createObjectURL(pdfDoc);
        window.open(pdfUrl, "_blank");
        setPhytoCertificatePreviewLoading(false);
      }
    } catch (err) {
      console.error(`Error in previewing PhytoSanitary Certificate:`, err);
    }
  };

  const downloadPhytoSanitaryCertificate = () => {
    setPhytoCertificateDownloadLoading(true);
    const pdfDoc = pdf(
      <PhytoSanitaryCertificate
        settingData={settingData}
        salesInvoiceData={salesInvoiceData}
      />
    );
    pdfDoc.toBlob().then((blob) => {
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = `${
        salesInvoiceData?.invoice_no
      }-${SelfDocuments.PHYTOSANITARY_CERTIFICATE.replace("_", "-")}.pdf`;
      link.click();
      setPhytoCertificateDownloadLoading(false);
    });
  };

  const previewAnnexureC = async () => {
    setAnnexureInvoicePreviewLoading(true);
    try {
      if (isReadyDownload) {
        const pdfDoc = await pdf(
          <AnnexureInvoice
            settingData={settingData}
            salesInvoiceData={salesInvoiceData}
          />
        ).toBlob();
        const pdfUrl = URL.createObjectURL(pdfDoc);
        window.open(pdfUrl, "_blank");
        setAnnexureInvoicePreviewLoading(false);
      }
    } catch (err) {
      console.error(`Error in previewing Annexure-C:`, err);
    }
  };

  const downloadAnnexureC = () => {
    setAnnexureInvoiceDownloadLoading(true);
    const pdfDoc = pdf(
      <AnnexureInvoice
        settingData={settingData}
        salesInvoiceData={salesInvoiceData}
      />
    );
    pdfDoc.toBlob().then((blob) => {
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = `${
        salesInvoiceData?.invoice_no
      }-${SelfDocuments.ANNEXURE_C.replace("_", "-")}.pdf`;
      link.click();
      setAnnexureInvoiceDownloadLoading(false);
    });
  };

  const previewCommercialInvoice = async () => {
    setCommercialInvoicePreviewLoading(true);
    try {
      if (isReadyDownload) {
        const pdfDoc = await pdf(
          <CommercialInvoice
            settingData={settingData}
            salesInvoiceData={salesInvoiceData}
          />
        ).toBlob();
        const pdfUrl = URL.createObjectURL(pdfDoc);
        window.open(pdfUrl, "_blank");
        setCommercialInvoicePreviewLoading(false);
      }
    } catch (err) {
      console.error(`Error in previewing Commercial Invoice:`, err);
    }
  };

  const downloadCommercialInvoice = () => {
    setCommercialInvoiceDownloadLoading(true);
    const pdfDoc = pdf(
      <CommercialInvoice
        settingData={settingData}
        salesInvoiceData={salesInvoiceData}
      />
    );
    pdfDoc.toBlob().then((blob) => {
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = `${
        salesInvoiceData?.invoice_no
      }-${SelfDocuments.COMMERCIAL_INVOICE.replace("_", "-")}.pdf`;
      link.click();
      setCommercialInvoiceDownloadLoading(false);
    });
  };

  const previewExportInvoiceCumPackingList = async () => {
    setPackingListPreviewLoading(true);
    try {
      if (isReadyDownload) {
        const pdfDoc = await pdf(
          <ExportInvoiceCumPackingList
            settingData={settingData}
            salesInvoiceData={salesInvoiceData}
          />
        ).toBlob();
        const pdfUrl = URL.createObjectURL(pdfDoc);
        window.open(pdfUrl, "_blank");
        setPackingListPreviewLoading(false);
      }
    } catch (err) {
      console.error(
        `Error in previewing Export Invoice Cum Packing List:`,
        err
      );
    }
  };

  const downloadExportInvoiceCumPackingList = () => {
    setPackingListDownloadLoading(true);
    const pdfDoc = pdf(
      <ExportInvoiceCumPackingList
        settingData={settingData}
        salesInvoiceData={salesInvoiceData}
      />
    );
    pdfDoc.toBlob().then((blob) => {
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = `${
        salesInvoiceData?.invoice_no
      }-${SelfDocuments.EXPORT_INVOICE_CUM_PACKING_LIST.replace("_", "-")}.pdf`;
      link.click();
      setPackingListDownloadLoading(false);
    });
  };

  const previewProformaInvoice = async () => {
    setProformaInvoicePreviewLoading(true);
    try {
      if (isReadyDownload) {
        const pdfDoc = await pdf(
          <ProformaInvoice
            settingData={settingData}
            salesInvoiceData={salesInvoiceData}
          />
        ).toBlob();
        const pdfUrl = URL.createObjectURL(pdfDoc);
        window.open(pdfUrl, "_blank");
        setProformaInvoicePreviewLoading(false);
      }
    } catch (err) {
      console.error(`Error in previewing Proforma Invoice:`, err);
    }
  };

  const downloadProformaInvoice = () => {
    setProformaInvoiceDownloadLoading(true);
    const pdfDoc = pdf(
      <ProformaInvoice
        settingData={settingData}
        salesInvoiceData={salesInvoiceData}
      />
    );
    pdfDoc.toBlob().then((blob) => {
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = `${
        salesInvoiceData?.invoice_no
      }-${SelfDocuments.PROFORMA_INVOICE.replace("_", "-")}.pdf`;
      link.click();
      setProformaInvoiceDownloadLoading(false);
    });
  };

  const previewBillOfLadingInvoice = async () => {
    setBillOfLadingPreviewLoading(true);
    try {
      if (isReadyDownload) {
        const pdfDoc = await pdf(
          <BlDraftInvoice
            settingData={settingData}
            salesInvoiceData={salesInvoiceData}
          />
        ).toBlob();
        const pdfUrl = URL.createObjectURL(pdfDoc);
        window.open(pdfUrl, "_blank");
        setBillOfLadingPreviewLoading(false);
      }
    } catch (err) {
      console.error(`Error in previewing Bill Of Lading Invoice:`, err);
    }
  };

  const downloadBillOfLadingInvoice = () => {
    setBillOfLadingDownloadLoading(true);
    const pdfDoc = pdf(
      <BlDraftInvoice
        settingData={settingData}
        salesInvoiceData={salesInvoiceData}
      />
    );
    pdfDoc.toBlob().then((blob) => {
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = `${
        salesInvoiceData?.invoice_no
      }-${SelfDocuments.BILL_OF_LADING.replace("_", "-")}.pdf`;
      link.click();
      setBillOfLadingDownloadLoading(false);
    });
  };

  const previewHealthAndQualityCertificate = async () => {
    setHealthAndQualityPreviewLoading(true);
    try {
      if (isReadyDownload) {
        const pdfDoc = await pdf(
          <HealthAndQualityCertificate
            salesInvoiceData={salesInvoiceData}
            settingsData={settingData}
          />
        ).toBlob();
        const pdfUrl = URL.createObjectURL(pdfDoc);
        window.open(pdfUrl, "_blank");
        setHealthAndQualityPreviewLoading(false);
      }
    } catch (err) {
      console.error(`Error in previewing Bill Of Lading Invoice:`, err);
    }
  };

  const downloadHealthAndQualityCertificate = () => {
    setHealthAndQualityDownloadLoading(true);
    const pdfDoc = pdf(
      <HealthAndQualityCertificate
        settingsData={settingData}
        salesInvoiceData={salesInvoiceData}
      />
    );
    pdfDoc.toBlob().then((blob) => {
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = `${
        salesInvoiceData?.invoice_no
      }-${SelfDocuments.HEALTH_AND_QUALITY_CERTIFICATE.replace("_", "-")}.pdf`;
      link.click();
      setHealthAndQualityDownloadLoading(false);
    });
  };

  const previewCertificateOfNonGmo = async () => {
    setCertificateOfNonGmoPreviewLoading(true);
    try {
      if (isReadyDownload) {
        const pdfDoc = await pdf(
          <CertificateOfNonGmo
            salesInvoiceData={salesInvoiceData}
            settingsData={settingData}
          />
        ).toBlob();
        const pdfUrl = URL.createObjectURL(pdfDoc);
        window.open(pdfUrl, "_blank");
        setCertificateOfNonGmoPreviewLoading(false);
      }
    } catch (err) {
      console.error(`Error in previewing Bill Of Lading Invoice:`, err);
    }
  };

  const downloadCertificateOfNonGmo = () => {
    setCertificateOfNonGmoDownloadLoading(true);
    const pdfDoc = pdf(
      <CertificateOfNonGmo
        salesInvoiceData={salesInvoiceData}
        settingsData={settingData}
      />
    );
    pdfDoc.toBlob().then((blob) => {
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = `${
        salesInvoiceData?.invoice_no
      }-${SelfDocuments.CERTIFICATE_OF_NON_GMO.replace("_", "-")}.pdf`;
      link.click();
      setCertificateOfNonGmoDownloadLoading(false);
    });
  };

  const previewWeightAndQualityCertificate = async () => {
    setWeightAndQualityPreviewLoading(true);
    try {
      if (isReadyDownload) {
        const pdfDoc = await pdf(
          <WeightAndQualityCertificate
            salesInvoiceData={salesInvoiceData}
            settingsData={settingData}
          />
        ).toBlob();
        const pdfUrl = URL.createObjectURL(pdfDoc);
        window.open(pdfUrl, "_blank");
        setWeightAndQualityPreviewLoading(false);
      }
    } catch (err) {
      console.error(`Error in previewing Bill Of Lading Invoice:`, err);
    }
  };

  const downloadWeightAndQualityCertificate = () => {
    setWeightAndQualityDownloadLoading(true);
    const pdfDoc = pdf(
      <WeightAndQualityCertificate
        salesInvoiceData={salesInvoiceData}
        settingsData={settingData}
      />
    );
    pdfDoc.toBlob().then((blob) => {
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = `${
        salesInvoiceData?.invoice_no
      }-${SelfDocuments.WEIGHT_AND_QUALITY_CERTIFICATE.replace("_", "-")}.pdf`;
      link.click();
      setWeightAndQualityDownloadLoading(false);
    });
  };

  const previewCertificateOfOrigin = async () => {
    setCertificateOfOriginPreviewLoading(true);
    try {
      if (isReadyDownload) {
        const pdfDoc = await pdf(
          <CertificateOfOrigin
            salesInvoiceData={salesInvoiceData}
            settingsData={settingData}
          />
        ).toBlob();
        const pdfUrl = URL.createObjectURL(pdfDoc);
        window.open(pdfUrl, "_blank");
        setCertificateOfOriginPreviewLoading(false);
      }
    } catch (err) {
      console.error(`Error in previewing Bill Of Lading Invoice:`, err);
    }
  };

  const downloadCertificateOfOrigin = () => {
    setCertificateOfOriginDownloadLoading(true);
    const pdfDoc = pdf(
      <CertificateOfOrigin
        salesInvoiceData={salesInvoiceData}
        settingsData={settingData}
      />
    );
    pdfDoc.toBlob().then((blob) => {
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = `${
        salesInvoiceData?.invoice_no
      }-${SelfDocuments.CERTIFICATE_OF_ORIGIN.replace("_", "-")}.pdf`;
      link.click();
      setCertificateOfOriginDownloadLoading(false);
    });
  };

  return (
    <>
      <div className="card">
        <div className="card-header pb-0 d-flex justify-content-between">
          <div>
            <h5>Sales Invoice Details :</h5>
          </div>
          <div className="d-flex" style={{ gap: "10px" }}>
            <Link
              to={`/sales-invoice-list`}
              type="button"
              className="btn btn-label-github"
            >
              Back
            </Link>
          </div>
        </div>
        <div className="card-body">
          {loading && <Spinner isFullScreen={false} />}
          <div>
            <div
              className="col-12 col-sm-6 col-md-4 col-lg-3 row m-1 p-2 d-flex info-container text-capitalize"
              style={{
                border: "1px solid #d1d0d4",
                borderRadius: "0.375rem",
                width: "100%",
              }}
            >
              <div className="col-12 col-lg-4 col-md-4 mb-3">
                <span className="h6 me-1">Invoice No :</span>
                {salesInvoiceData?.invoice_no || "N/A"}
              </div>
              <div className="col-12 col-lg-4 col-md-4 mb-3">
                <span className="h6 me-1">Contract No :</span>
                {salesInvoiceData?.sales_contract?.contract_no || "N/A"}
              </div>
              <div className="col-12 col-lg-4 col-md-4 mb-3">
                <span className="h6 me-1">Buyer :</span>
                {salesInvoiceData?.buyer?.name || "N/A"}
              </div>
              <div className="col-12 col-lg-4 col-md-4 mb-3">
                <span className="h6 me-1">Product :</span>
                {salesInvoiceData?.product?.name || "N/A"}
              </div>
              <div className="col-12 col-lg-4 col-md-4 mb-3">
                <span className="h6 me-1">Invoice Date:</span>
                {formatDate(salesInvoiceData.invoice_date)}
              </div>
              <div className="col-12 col-lg-4 col-md-4 mb-3">
                <span className="h6 me-1">Status:</span>
                {formatString(salesInvoiceData.status)}
              </div>
              <div className="col-12 col-lg-4 col-md-4 mb-3">
                <span className="h6 me-1">Logistic :</span>
                {salesInvoiceData?.logistic?.name || "N/A"}
              </div>
              <div className="col-12 col-lg-4 col-md-4 mb-3">
                <span className="h6 me-1">CHA :</span>
                {salesInvoiceData?.cha?.name || "N/A"}
              </div>
              <div className="col-12 col-lg-4 col-md-4 mb-3">
                <span className="h6 me-1">Origin Port :</span>
                {salesInvoiceData?.origin_port?.name || "N/A"}
              </div>
              <div className="col-12 col-lg-4 col-md-4 mb-3">
                <span className="h6 me-1">ETD:</span>
                {formatDateTime(salesInvoiceData.etd)}
              </div>
              <div className="col-12 col-lg-4 col-md-4 mb-3">
                <span className="h6 me-1">Destination Port :</span>
                {salesInvoiceData?.destination_port?.name || "N/A"}
              </div>
              <div className="col-12 col-lg-4 col-md-4 mb-3">
                <span className="h6 me-1">ETA:</span>
                {formatDateTime(salesInvoiceData.eta)}
              </div>
              <div className="col-12 col-lg-4 col-md-4 mb-3">
                <span className="h6 me-1">price :</span>
                {CURRENCY.USD.SYMBOL}
                {formatAmount(salesInvoiceData.price)}
              </div>
              <div className="col-12 col-lg-4 col-md-4 mb-3">
                <span className="h6 me-1">Quantity :</span>
                {salesInvoiceData?.quantity || "N/A"}
              </div>
              <div className="col-12 col-lg-4 col-md-4 mb-3">
                <span className="h6 me-1">Total Amount :</span>
                {CURRENCY.USD.SYMBOL}
                {formatAmount(salesInvoiceData.total_amount)}
              </div>
              <div className="col-12 col-lg-4 col-md-4 mb-3">
                <span className="h6 me-1">IGST Percentage:</span>
                {salesInvoiceData?.igst_percentage
                  ? `${salesInvoiceData?.igst_percentage}%`
                  : ""}
              </div>
              <div className="col-12 col-lg-4 col-md-4 mb-3">
                <span className="h6 me-1">Exchange Rate :</span>
                {CURRENCY.INR.SYMBOL}
                {formatAmount(salesInvoiceData.exchange_rate)}
              </div>
              <div className="col-12 col-lg-4 col-md-4 mb-3">
                <span className="h6 me-1">Total Taxable Amount :</span>
                {CURRENCY.INR.SYMBOL}
                {formatAmount(salesInvoiceData.total_taxable_amount_inr)}
              </div>
              <div className="col-12 col-lg-4 col-md-4 mb-3">
                <span className="h6 me-1">Total IGST Amount :</span>
                {CURRENCY.INR.SYMBOL}
                {formatAmount(salesInvoiceData.total_igst_amount_inr)}
              </div>
              <div className="col-12 col-lg-4 col-md-4 mb-3">
                <span className="h6 me-1">Commission Rate:</span>
                {salesInvoiceData?.commission_rate
                  ? `${salesInvoiceData?.commission_rate}%`
                  : ""}
              </div>
              <div className="col-12 col-lg-4 col-md-4 mb-3">
                <span className="h6 me-1">Commission Amount :</span>
                {CURRENCY.USD.SYMBOL}
                {formatAmount(salesInvoiceData.commission_amount)}
              </div>
              <div className="col-12 col-lg-4 col-md-4 mb-3">
                <span className="h6 me-1">Quantity Unit :</span>
                {salesInvoiceData?.quantity_unit?.name || "N/A"}
              </div>
              <div className="col-12 col-lg-4 col-md-4 mb-3">
                <span className="h6 me-1">Packing Type :</span>
                {salesInvoiceData?.packing_type?.name || "N/A"}
              </div>
              <div className="col-12 col-lg-4 col-md-4 mb-3">
                <span className="h6 me-1">Container Size :</span>
                {salesInvoiceData?.container_size || "N/A"}
              </div>
              <div className="col-12 col-lg-4 col-md-4 mb-3">
                <span className="h6 me-1">Packing Net Weight :</span>
                {formatAmount(salesInvoiceData?.packing_net_weight)}
              </div>
              <div className="col-12 col-lg-4 col-md-4 mb-3">
                <span className="h6 me-1">Net Weight Unit :</span>
                {salesInvoiceData?.net_weight_unit?.name || "N/A"}
              </div>
              <div className="col-12 col-lg-4 col-md-4 mb-3">
                <span className="h6 me-1">Packing Gross Weight :</span>
                {formatAmount(salesInvoiceData?.packing_gross_weight)}
              </div>
              <div className="col-12 col-lg-4 col-md-4 mb-3">
                <span className="h6 me-1">Gross Weight Unit :</span>
                {salesInvoiceData?.gross_weight_unit?.name || "N/A"}
              </div>
              <div className="col-12 col-lg-4 col-md-4 mb-3">
                <span className="h6 me-1">No of Bags :</span>
                {salesInvoiceData?.no_of_bags || "N/A"}
              </div>
              <div className="col-12 col-lg-4 col-md-4 mb-3">
                <span className="h6 me-1">Total Net Weight :</span>
                {formatAmount(salesInvoiceData?.total_net_weight)}
              </div>
              <div className="col-12 col-lg-4 col-md-4 mb-3">
                <span className="h6 me-1">Total Gross Weight :</span>
                {formatAmount(salesInvoiceData?.total_gross_weight)}
              </div>
              <div className="col-12 col-lg-4 col-md-4 mb-3">
                <span className="h6 me-1">Pre Carriage By :</span>
                {salesInvoiceData?.pre_carriage_by || "N/A"}
              </div>
              <div className="col-12 col-lg-4 col-md-4 mb-3">
                <span className="h6 me-1">Vessel No :</span>
                {salesInvoiceData?.vessel_no || "N/A"}
              </div>
              <div className="col-12 col-lg-4 col-md-4 mb-3">
                <span className="h6 me-1">BL No :</span>
                {salesInvoiceData?.bl_no || "N/A"}
              </div>
              <div className="col-12 col-lg-4 col-md-4 mb-3">
                <span className="h6 me-1">Delivery Term :</span>
                {salesInvoiceData?.delivery_term || "N/A"}
              </div>
              <div className="col-12 col-lg-4 col-md-4 mb-3">
                <span className="h6 me-1">Payment Term :</span>
                {salesInvoiceData?.payment_term || "N/A"}
              </div>
            </div>
          </div>

          {salesInvoiceData?.containers?.length > 0 && (
            <>
              <div className="mt-7 py-2 pb-0 d-flex justify-content-start align-items-center">
                <h5>Container Details :</h5>
              </div>
              <div className="card-datatable table-responsive">
                <table className="dt-advanced-search table table-nowrap">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Container No</th>
                      <th>Lot No</th>
                      <th>Lot Label Seal</th>
                      <th>Line Seal No</th>
                      <th>Self Seal No</th>
                      <th>Sticker No</th>
                      <th>Vehicle No</th>
                      <th>Size</th>
                      <th>No of Bags</th>
                      <th>Total Net Weight</th>
                      <th>Total Gross Weight</th>
                      <th>Remark</th>
                    </tr>
                  </thead>
                  <tbody>
                    {salesInvoiceData?.containers?.map(
                      (row: any, index: number) => {
                        return (
                          <tr key={row.id}>
                            <td>{index + 1}</td>
                            <td>{row?.container_no || "-"}</td>
                            <td>{row?.lot_no || "-"}</td>
                            <td>{row?.lot_label_seal || "-"}</td>
                            <td>{row?.line_seal_no || "-"}</td>
                            <td>{row?.self_seal?.seal_no || "-"}</td>
                            <td>{row?.sticker_no || "-"}</td>
                            <td>{row?.vehicle_no || "-"}</td>
                            <td>{row?.size || "-"}</td>
                            <td>
                              {row?.no_of_bags || ""}{" "}
                              {row?.packing_type?.name || ""}
                            </td>
                            <td>
                              {formatAmount(row?.total_net_weight) || ""} KG
                            </td>
                            <td>
                              {formatAmount(row?.total_gross_weight) || ""} KG
                            </td>
                            <td>{row?.remark || "-"}</td>
                          </tr>
                        );
                      }
                    )}
                  </tbody>
                </table>
              </div>
            </>
          )}

          {salesInvoiceData?.my_documents?.length > 0 && (
            <>
              <div className="mt-7 py-2 pb-0 d-flex justify-content-start align-items-center">
                <h5>My Documents (Self) :</h5>
              </div>
              <div className="card-datatable table-responsive">
                <table className="dt-advanced-search table table-nowrap">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Document</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {salesInvoiceData?.my_documents?.map(
                      (row: any, index: number) => {
                        return (
                          <tr key={row.id}>
                            <td>{index + 1}</td>
                            <td>{row?.document?.name || "-"}</td>
                            <td>
                              {row?.document?.type === DocumentType.SELF &&
                              row?.document?.slug ===
                                SelfDocuments.PHYTOSANITARY_CERTIFICATE ? (
                                <>
                                  <button
                                    className="btn btn-primary btn-sm me-2"
                                    type="button"
                                    title={`Preview ${formatString(
                                      SelfDocuments.PHYTOSANITARY_CERTIFICATE
                                    )}`}
                                    onClick={() =>
                                      previewPhytoSanitaryCertificate()
                                    }
                                    disabled={phytoCertificatePreviewLoading}
                                  >
                                    <span>
                                      <span className="d-none d-sm-inline-block">
                                        {phytoCertificatePreviewLoading && (
                                          <span
                                            className="spinner-border spinner-border-sm me-2"
                                            role="status"
                                            aria-hidden="true"
                                          ></span>
                                        )}
                                        Preview Invoice
                                      </span>
                                    </span>
                                  </button>
                                  <button
                                    className="btn btn-primary btn-sm"
                                    type="button"
                                    title={`Download ${formatString(
                                      SelfDocuments.PHYTOSANITARY_CERTIFICATE
                                    )}`}
                                    onClick={() =>
                                      downloadPhytoSanitaryCertificate()
                                    }
                                    disabled={phytoCertificateDownloadLoading}
                                  >
                                    <span>
                                      <span className="d-none d-sm-inline-block">
                                        {phytoCertificateDownloadLoading && (
                                          <span
                                            className="spinner-border spinner-border-sm me-2"
                                            role="status"
                                            aria-hidden="true"
                                          ></span>
                                        )}
                                        Download Invoice
                                      </span>
                                    </span>
                                  </button>
                                </>
                              ) : row?.document?.type === DocumentType.SELF &&
                                row?.document?.slug ===
                                  SelfDocuments.ANNEXURE_C ? (
                                <>
                                  <button
                                    className="btn btn-primary btn-sm me-2"
                                    type="button"
                                    title={`Preview ${formatString(
                                      SelfDocuments.ANNEXURE_C
                                    )}`}
                                    onClick={() => previewAnnexureC()}
                                    disabled={annexureInvoicePreviewLoading}
                                  >
                                    <span>
                                      <span className="d-none d-sm-inline-block">
                                        {annexureInvoicePreviewLoading && (
                                          <span
                                            className="spinner-border spinner-border-sm me-2"
                                            role="status"
                                            aria-hidden="true"
                                          ></span>
                                        )}
                                        Preview Invoice
                                      </span>
                                    </span>
                                  </button>
                                  <button
                                    className="btn btn-primary btn-sm"
                                    type="button"
                                    title={`Download ${formatString(
                                      SelfDocuments.ANNEXURE_C
                                    )}`}
                                    onClick={() => downloadAnnexureC()}
                                    disabled={annexureInvoiceDownloadLoading}
                                  >
                                    <span>
                                      <span className="d-none d-sm-inline-block">
                                        {annexureInvoiceDownloadLoading && (
                                          <span
                                            className="spinner-border spinner-border-sm me-2"
                                            role="status"
                                            aria-hidden="true"
                                          ></span>
                                        )}
                                        Download Invoice
                                      </span>
                                    </span>
                                  </button>
                                </>
                              ) : row?.document?.type === DocumentType.SELF &&
                                row?.document?.slug ===
                                  SelfDocuments.COMMERCIAL_INVOICE ? (
                                <>
                                  <button
                                    className="btn btn-primary btn-sm me-2"
                                    type="button"
                                    title={`Preview ${formatString(
                                      SelfDocuments.COMMERCIAL_INVOICE
                                    )}`}
                                    onClick={() => previewCommercialInvoice()}
                                    disabled={commercialInvoicePreviewLoading}
                                  >
                                    <span>
                                      <span className="d-none d-sm-inline-block">
                                        {commercialInvoicePreviewLoading && (
                                          <span
                                            className="spinner-border spinner-border-sm me-2"
                                            role="status"
                                            aria-hidden="true"
                                          ></span>
                                        )}
                                        Preview Invoice
                                      </span>
                                    </span>
                                  </button>
                                  <button
                                    className="btn btn-primary btn-sm"
                                    type="button"
                                    title={`Download ${formatString(
                                      SelfDocuments.COMMERCIAL_INVOICE
                                    )}`}
                                    onClick={() => downloadCommercialInvoice()}
                                    disabled={commercialInvoiceDownloadLoading}
                                  >
                                    <span>
                                      <span className="d-none d-sm-inline-block">
                                        {commercialInvoiceDownloadLoading && (
                                          <span
                                            className="spinner-border spinner-border-sm me-2"
                                            role="status"
                                            aria-hidden="true"
                                          ></span>
                                        )}
                                        Download Invoice
                                      </span>
                                    </span>
                                  </button>
                                </>
                              ) : row?.document?.type === DocumentType.SELF &&
                                row?.document?.slug ===
                                  SelfDocuments.EXPORT_INVOICE_CUM_PACKING_LIST ? (
                                <>
                                  <button
                                    className="btn btn-primary btn-sm me-2"
                                    type="button"
                                    title={`Preview ${formatString(
                                      SelfDocuments.EXPORT_INVOICE_CUM_PACKING_LIST
                                    )}`}
                                    onClick={() =>
                                      previewExportInvoiceCumPackingList()
                                    }
                                    disabled={packingListPreviewLoading}
                                  >
                                    <span>
                                      <span className="d-none d-sm-inline-block">
                                        {packingListPreviewLoading && (
                                          <span
                                            className="spinner-border spinner-border-sm me-2"
                                            role="status"
                                            aria-hidden="true"
                                          ></span>
                                        )}
                                        Preview Invoice
                                      </span>
                                    </span>
                                  </button>
                                  <button
                                    className="btn btn-primary btn-sm"
                                    type="button"
                                    title={`Download ${formatString(
                                      SelfDocuments.EXPORT_INVOICE_CUM_PACKING_LIST
                                    )}`}
                                    onClick={() =>
                                      downloadExportInvoiceCumPackingList()
                                    }
                                    disabled={packingListDownloadLoading}
                                  >
                                    <span>
                                      <span className="d-none d-sm-inline-block">
                                        {packingListDownloadLoading && (
                                          <span
                                            className="spinner-border spinner-border-sm me-2"
                                            role="status"
                                            aria-hidden="true"
                                          ></span>
                                        )}
                                        Download Invoice
                                      </span>
                                    </span>
                                  </button>
                                </>
                              ) : row?.document?.type === DocumentType.SELF &&
                                row?.document?.slug ===
                                  SelfDocuments.PROFORMA_INVOICE ? (
                                <>
                                  <button
                                    className="btn btn-primary btn-sm me-2"
                                    type="button"
                                    title={`Preview ${formatString(
                                      SelfDocuments.PROFORMA_INVOICE
                                    )}`}
                                    onClick={() => previewProformaInvoice()}
                                    disabled={proformaInvoicePreviewLoading}
                                  >
                                    <span>
                                      <span className="d-none d-sm-inline-block">
                                        {proformaInvoicePreviewLoading && (
                                          <span
                                            className="spinner-border spinner-border-sm me-2"
                                            role="status"
                                            aria-hidden="true"
                                          ></span>
                                        )}
                                        Preview Invoice
                                      </span>
                                    </span>
                                  </button>
                                  <button
                                    className="btn btn-primary btn-sm"
                                    type="button"
                                    title={`Download ${formatString(
                                      SelfDocuments.PROFORMA_INVOICE
                                    )}`}
                                    onClick={() => downloadProformaInvoice()}
                                    disabled={proformaInvoiceDownloadLoading}
                                  >
                                    <span>
                                      <span className="d-none d-sm-inline-block">
                                        {proformaInvoiceDownloadLoading && (
                                          <span
                                            className="spinner-border spinner-border-sm me-2"
                                            role="status"
                                            aria-hidden="true"
                                          ></span>
                                        )}
                                        Download Invoice
                                      </span>
                                    </span>
                                  </button>
                                </>
                              ) : row?.document?.type === DocumentType.SELF &&
                                row?.document?.slug ===
                                  SelfDocuments.BILL_OF_LADING ? (
                                <>
                                  <button
                                    className="btn btn-primary btn-sm me-2"
                                    type="button"
                                    title={`Preview ${formatString(
                                      SelfDocuments.BILL_OF_LADING
                                    )}`}
                                    onClick={() => previewBillOfLadingInvoice()}
                                    disabled={billOfLadingPreviewLoading}
                                  >
                                    <span>
                                      <span className="d-none d-sm-inline-block">
                                        {billOfLadingPreviewLoading && (
                                          <span
                                            className="spinner-border spinner-border-sm me-2"
                                            role="status"
                                            aria-hidden="true"
                                          ></span>
                                        )}
                                        Preview Invoice
                                      </span>
                                    </span>
                                  </button>
                                  <button
                                    className="btn btn-primary btn-sm"
                                    type="button"
                                    title={`Download ${formatString(
                                      SelfDocuments.BILL_OF_LADING
                                    )}`}
                                    onClick={() =>
                                      downloadBillOfLadingInvoice()
                                    }
                                    disabled={billOfLadingDownloadLoading}
                                  >
                                    <span>
                                      <span className="d-none d-sm-inline-block">
                                        {billOfLadingDownloadLoading && (
                                          <span
                                            className="spinner-border spinner-border-sm me-2"
                                            role="status"
                                            aria-hidden="true"
                                          ></span>
                                        )}
                                        Download Invoice
                                      </span>
                                    </span>
                                  </button>
                                </>
                              ) : row?.document?.type === DocumentType.SELF &&
                                row?.document?.slug ===
                                  SelfDocuments.HEALTH_AND_QUALITY_CERTIFICATE ? (
                                <>
                                  <button
                                    className="btn btn-primary btn-sm me-2"
                                    type="button"
                                    title={`Preview ${formatString(
                                      SelfDocuments.HEALTH_AND_QUALITY_CERTIFICATE
                                    )}`}
                                    onClick={() =>
                                      previewHealthAndQualityCertificate()
                                    }
                                    disabled={healthAndQualityPreviewLoading}
                                  >
                                    <span>
                                      <span className="d-none d-sm-inline-block">
                                        {healthAndQualityPreviewLoading && (
                                          <span
                                            className="spinner-border spinner-border-sm me-2"
                                            role="status"
                                            aria-hidden="true"
                                          ></span>
                                        )}
                                        Preview Invoice
                                      </span>
                                    </span>
                                  </button>
                                  <button
                                    className="btn btn-primary btn-sm"
                                    type="button"
                                    title={`Download ${formatString(
                                      SelfDocuments.HEALTH_AND_QUALITY_CERTIFICATE
                                    )}`}
                                    onClick={() =>
                                      downloadHealthAndQualityCertificate()
                                    }
                                    disabled={healthAndQualityDownloadLoading}
                                  >
                                    <span>
                                      <span className="d-none d-sm-inline-block">
                                        {healthAndQualityDownloadLoading && (
                                          <span
                                            className="spinner-border spinner-border-sm me-2"
                                            role="status"
                                            aria-hidden="true"
                                          ></span>
                                        )}
                                        Download Invoice
                                      </span>
                                    </span>
                                  </button>
                                </>
                              ) : row?.document?.type === DocumentType.SELF &&
                                row?.document?.slug ===
                                  SelfDocuments.CERTIFICATE_OF_NON_GMO ? (
                                <>
                                  <button
                                    className="btn btn-primary btn-sm me-2"
                                    type="button"
                                    title={`Preview ${formatString(
                                      SelfDocuments.CERTIFICATE_OF_NON_GMO
                                    )}`}
                                    onClick={() => previewCertificateOfNonGmo()}
                                    disabled={certificateOfNonGmoPreviewLoading}
                                  >
                                    <span>
                                      <span className="d-none d-sm-inline-block">
                                        {certificateOfNonGmoPreviewLoading && (
                                          <span
                                            className="spinner-border spinner-border-sm me-2"
                                            role="status"
                                            aria-hidden="true"
                                          ></span>
                                        )}
                                        Preview Invoice
                                      </span>
                                    </span>
                                  </button>
                                  <button
                                    className="btn btn-primary btn-sm"
                                    type="button"
                                    title={`Download ${formatString(
                                      SelfDocuments.CERTIFICATE_OF_NON_GMO
                                    )}`}
                                    onClick={() =>
                                      downloadCertificateOfNonGmo()
                                    }
                                    disabled={
                                      certificateOfNonGmoDownloadLoading
                                    }
                                  >
                                    <span>
                                      <span className="d-none d-sm-inline-block">
                                        {certificateOfNonGmoDownloadLoading && (
                                          <span
                                            className="spinner-border spinner-border-sm me-2"
                                            role="status"
                                            aria-hidden="true"
                                          ></span>
                                        )}
                                        Download Invoice
                                      </span>
                                    </span>
                                  </button>
                                </>
                              ) : row?.document?.type === DocumentType.SELF &&
                                row?.document?.slug ===
                                  SelfDocuments.WEIGHT_AND_QUALITY_CERTIFICATE ? (
                                <>
                                  <button
                                    className="btn btn-primary btn-sm me-2"
                                    type="button"
                                    title={`Preview ${formatString(
                                      SelfDocuments.WEIGHT_AND_QUALITY_CERTIFICATE
                                    )}`}
                                    onClick={() =>
                                      previewWeightAndQualityCertificate()
                                    }
                                    disabled={weightAndQualityPreviewLoading}
                                  >
                                    <span>
                                      <span className="d-none d-sm-inline-block">
                                        {weightAndQualityPreviewLoading && (
                                          <span
                                            className="spinner-border spinner-border-sm me-2"
                                            role="status"
                                            aria-hidden="true"
                                          ></span>
                                        )}
                                        Preview Invoice
                                      </span>
                                    </span>
                                  </button>
                                  <button
                                    className="btn btn-primary btn-sm"
                                    type="button"
                                    title={`Download ${formatString(
                                      SelfDocuments.WEIGHT_AND_QUALITY_CERTIFICATE
                                    )}`}
                                    onClick={() =>
                                      downloadWeightAndQualityCertificate()
                                    }
                                    disabled={weightAndQualityDownloadLoading}
                                  >
                                    <span>
                                      <span className="d-none d-sm-inline-block">
                                        {weightAndQualityDownloadLoading && (
                                          <span
                                            className="spinner-border spinner-border-sm me-2"
                                            role="status"
                                            aria-hidden="true"
                                          ></span>
                                        )}
                                        Download Invoice
                                      </span>
                                    </span>
                                  </button>
                                </>
                              ) : row?.document?.type === DocumentType.SELF &&
                                row?.document?.slug ===
                                  SelfDocuments.CERTIFICATE_OF_ORIGIN ? (
                                <>
                                  <button
                                    className="btn btn-primary btn-sm me-2"
                                    type="button"
                                    title={`Preview ${formatString(
                                      SelfDocuments.CERTIFICATE_OF_ORIGIN
                                    )}`}
                                    onClick={() => previewCertificateOfOrigin()}
                                    disabled={certificateOfOriginPreviewLoading}
                                  >
                                    <span>
                                      <span className="d-none d-sm-inline-block">
                                        {certificateOfOriginPreviewLoading && (
                                          <span
                                            className="spinner-border spinner-border-sm me-2"
                                            role="status"
                                            aria-hidden="true"
                                          ></span>
                                        )}
                                        Preview Invoice
                                      </span>
                                    </span>
                                  </button>
                                  <button
                                    className="btn btn-primary btn-sm"
                                    type="button"
                                    title={`Download ${formatString(
                                      SelfDocuments.CERTIFICATE_OF_ORIGIN
                                    )}`}
                                    onClick={() =>
                                      downloadCertificateOfOrigin()
                                    }
                                    disabled={
                                      certificateOfOriginDownloadLoading
                                    }
                                  >
                                    <span>
                                      <span className="d-none d-sm-inline-block">
                                        {certificateOfOriginDownloadLoading && (
                                          <span
                                            className="spinner-border spinner-border-sm me-2"
                                            role="status"
                                            aria-hidden="true"
                                          ></span>
                                        )}
                                        Download Invoice
                                      </span>
                                    </span>
                                  </button>
                                </>
                              ) : (
                                <button
                                  className="btn btn-primary btn-sm"
                                  type="button"
                                  disabled
                                  title="Generate Invoice"
                                >
                                  <span>
                                    <span className="d-none d-sm-inline-block">
                                      Generate Invoice
                                    </span>
                                  </span>
                                </button>
                              )}
                            </td>
                          </tr>
                        );
                      }
                    )}
                  </tbody>
                </table>
              </div>
            </>
          )}

          {salesInvoiceData?.other_documents?.length > 0 && (
            <>
              <div className="mt-7 py-2 pb-0 d-flex justify-content-start align-items-center">
                <h5>Other Document Details :</h5>
              </div>
              <div className="card-datatable table-responsive">
                <table className="dt-advanced-search table table-nowrap">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Document</th>
                      <th>Files</th>
                    </tr>
                  </thead>
                  <tbody>
                    {salesInvoiceData?.other_documents?.map(
                      (row: any, index: number) => {
                        return (
                          <tr key={row.document_id}>
                            <td>{index + 1}</td>
                            <td> {row?.document?.name || "N/A"}</td>
                            <td className="align-items-center justify-content-left d-flex">
                              <div
                                className="d-flex"
                                style={{
                                  height: "100%",
                                  alignItems: "center",
                                }}
                              >
                                {row?.files?.map((e: any) => {
                                  const imageUrl = `${process.env.REACT_APP_BACKEND_URL}${e}`;
                                  return (
                                    <div className="row p-0">
                                      <div
                                        className="col-md mb-md-0"
                                        style={{
                                          width: "170px",
                                          height: "100%",
                                          textWrap: "wrap",
                                        }}
                                      >
                                        <div className="form-check custom-option custom-option-icon">
                                          <label
                                            className="form-check-label custom-option-content"
                                            onClick={() =>
                                              window.open(imageUrl, "_blank")
                                            }
                                          >
                                            <span className="custom-option-body">
                                              <small>
                                                {getFileNameFromUrl(imageUrl)}
                                              </small>
                                            </span>
                                          </label>
                                        </div>
                                      </div>
                                    </div>
                                  );
                                })}
                              </div>
                            </td>
                          </tr>
                        );
                      }
                    )}
                  </tbody>
                </table>
              </div>
            </>
          )}

          {salesInvoiceData?.bills?.length > 0 && (
            <>
              <div className="mt-7 py-2 pb-0 d-flex justify-content-start align-items-center">
                <h5>Bill Details :</h5>
              </div>
              <div className="card-datatable table-responsive">
                <table className="dt-advanced-search table table-nowrap">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Bill Type</th>
                      <th>Files</th>
                    </tr>
                  </thead>
                  <tbody>
                    {salesInvoiceData?.bills?.map((row: any, index: number) => {
                      return (
                        <tr key={row.bill_id}>
                          <td>{index + 1}</td>
                          <td> {row?.bill?.name || "N/A"}</td>
                          <td className="align-items-center justify-content-left d-flex">
                            <div
                              className="d-flex"
                              style={{
                                height: "100%",
                                alignItems: "center",
                              }}
                            >
                              {row?.files?.map((e: any) => {
                                const imageUrl = `${process.env.REACT_APP_BACKEND_URL}${e}`;
                                return (
                                  <div className="row p-0">
                                    <div
                                      className="col-md mb-md-0"
                                      style={{
                                        width: "170px",
                                        height: "100%",
                                        textWrap: "wrap",
                                      }}
                                    >
                                      <div className="form-check custom-option custom-option-icon">
                                        <label
                                          className="form-check-label custom-option-content"
                                          onClick={() =>
                                            window.open(imageUrl, "_blank")
                                          }
                                        >
                                          <span className="custom-option-body">
                                            <small>
                                              {getFileNameFromUrl(imageUrl)}
                                            </small>
                                          </span>
                                        </label>
                                      </div>
                                    </div>
                                  </div>
                                );
                              })}
                            </div>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default ViewSalesInvoice;
