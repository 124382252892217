import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  PDFViewer,
} from "@react-pdf/renderer";

export const styles = StyleSheet.create({
  boldText: {
    fontWeight: "bold",
    fontSize: 8,
  },
  smallText: {
    fontSize: 6,
  },
  mediumText: {
    fontSize: 6.5,
  },
  contentPadding: {
    padding: 1,
  },
  page: {
    paddingHorizontal: 30,
    paddingVertical: 15,
    fontFamily: "HostGrotesk",
    backgroundColor: "white",
  },
  fullpage: {
    margin: 7,
    borderStyle: "solid",
    borderWidth: 1,
    borderColor: "#000000",
  },
  leftSection: {
    flex: 1,
    padding: 10,
    paddingLeft: 0,
  },
  rightSection: {
    flex: 3,
    padding: 10,
  },
  centerSection: {
    flex: 7,
  },
  header: {
    fontSize: 10,
    textAlign: "center",
    padding: 1,
    marginBottom: 0,
    fontWeight: "bold",
    borderBottomWidth: 1,
    borderBottomColor: "black",
    borderBottomStyle: "solid",
  },
  sideBySideContainer: { flexDirection: "row" },
});

const PhytoCertificate = () => {
  return (
    <PDFViewer width="100%" height="600px">
      <Document>
        <Page style={styles.page} size="A4">
          <View style={styles.fullpage}>
            {/* title */}
            <View
              style={[
                styles.sideBySideContainer,
                {
                  height: 70,
                  flexDirection: "row",
                },
              ]}
            >
              {/* Left Section */}
              <View
                style={[
                  styles.leftSection,
                  {
                    borderRightWidth: 1,
                    borderRightColor: "black",
                    borderRightStyle: "solid",
                  },
                ]}
              >
                {/* Left content */}
              </View>

              {/* Center Section */}
              <View
                style={[
                  styles.centerSection,
                  {
                    justifyContent: "center",
                    paddingHorizontal: 10,
                    borderRightWidth: 1,
                    borderRightColor: "black",
                    borderRightStyle: "solid",
                  },
                ]}
              >
                <Text
                  style={[
                    styles.header,
                    { borderBottom: 0, textAlign: "center" },
                  ]}
                >
                  Government of India
                </Text>
                <Text
                  style={[
                    {
                      borderBottom: 0,
                      fontWeight: "normal",
                      fontSize: 10,
                      textAlign: "center",
                    },
                  ]}
                >
                  Ministry of Agriculture
                </Text>
                <Text
                  style={[
                    { borderBottom: 0, fontSize: 10, textAlign: "center" },
                  ]}
                >
                  Department of Agriculture and Cooperation
                </Text>
                <Text
                  style={[
                    { borderBottom: 0, fontSize: 10, textAlign: "center" },
                  ]}
                >
                  Directorate of Plant Protection, Qurantine & Storage
                </Text>
                <Text style={[styles.header, { borderBottom: 0 }]}>
                  PYTHOSANITARY CERTIFICATE
                </Text>
              </View>

              {/* Right Section */}
              <View style={[styles.rightSection]}>
                <Text
                  style={[
                    styles.mediumText,
                    {
                      width: 148,
                      marginLeft: -7,
                      height: 20,
                    },
                  ]}
                >
                  PSC NO.
                </Text>
                <Text
                  style={[
                    {
                      width: 148,
                      marginLeft: -10,
                      borderBottom: 1,
                    },
                  ]}
                ></Text>
                <Text
                  style={[
                    styles.mediumText,
                    {
                      borderTop: 0,
                      width: 148,
                      marginLeft: -7,
                      height: 10,
                      marginTop: 5,
                    },
                  ]}
                >
                  E. Reg No./Date:
                </Text>
                <Text
                  style={[
                    styles.boldText,
                    { borderTop: 0, width: 148, marginLeft: -7, height: 20 },
                  ]}
                >
                  ABCDE
                </Text>
              </View>
            </View>

            {/* section 2*/}
            <View
              style={[
                {
                  flexDirection: "row",
                  borderTopWidth: 1,
                  borderTopColor: "black",
                  borderTopStyle: "solid",
                },
              ]}
            >
              {/* Left Section */}
              <View
                style={[
                  {
                    width: "100%",
                    paddingLeft: 5,
                    paddingTop: 6,
                    paddingBottom: 0,
                    borderLeftWidth: 0,
                    borderRightWidth: 1,
                  },
                ]}
              >
                <Text
                  style={[
                    {
                      fontSize: 8,
                      fontWeight: "light",
                      verticalAlign: "super",
                    },
                  ]}
                >
                  1. FROM: PLANT PROTECTION ORGANISATION OF INDIA
                </Text>
              </View>

              {/* Right Section */}
              <View
                style={[
                  {
                    width: "100%",
                    paddingLeft: 5,
                    paddingTop: 6,
                    paddingBottom: 0,
                    borderLeftWidth: 0,
                  },
                ]}
              >
                <Text
                  style={[
                    {
                      fontSize: 8,
                      fontWeight: "light",
                      verticalAlign: "super",
                    },
                  ]}
                >
                  2. TO: THE PLANT PROTECTION ORGANISATION OF SRI LANKA
                </Text>
              </View>
            </View>

            {/*  section 3*/}
            <View style={styles.sideBySideContainer}>
              <View style={styles.centerSection}>
                <Text
                  style={[
                    styles.boldText,
                    { borderTop: 1, textAlign: "center" },
                  ]}
                >
                  DESCRIPTION OF CONSIGNMENT
                </Text>
              </View>
            </View>
            {/* 3 address */}
            <View
              style={[
                styles.sideBySideContainer,
                {
                  flexDirection: "row",
                  height: 50,
                  borderTopWidth: 1,
                  borderTopColor: "black",
                  borderTopStyle: "solid",
                },
              ]}
            >
              {/* Left Section */}
              <View
                style={[
                  styles.leftSection,
                  {
                    borderLeftWidth: 0,
                    flex: 1,
                    borderRightWidth: 1,
                    justifyContent: "center",
                    alignItems: "flex-start",
                    paddingLeft: 5,
                  },
                ]}
              >
                <Text
                  style={{ fontWeight: "light", fontSize: 9, marginTop: -5 }}
                >
                  NAME & ADDRESS OF EXPORTER:-
                </Text>
                <Text style={{ fontWeight: "heavy", fontSize: 9 }}>
                  MS LAXMI ENTERPRISE PLOT NO. 612, NAGOR ROAD,GIDC BHUJ,
                  KACHCHH,GUJARAT, 370001 (INDIA)
                </Text>
              </View>

              {/* Right Section */}
              <View
                style={[
                  styles.rightSection,
                  {
                    borderLeftWidth: 0,
                    flex: 1,
                    borderRightWidth: 0,
                    justifyContent: "center",
                    alignItems: "flex-start",
                    paddingLeft: 5,
                  },
                ]}
              >
                <Text
                  style={{ fontWeight: "light", fontSize: 9, marginTop: -5 }}
                >
                  Declared name & address of consignee:
                </Text>
                <Text style={{ fontWeight: "heavy", fontSize: 9 }}>
                  GLOBAL TRADING & COMPANY (PVT) LTD.NO: 136/F, DAM
                  STREET,COLOMBO – 12, SRI LANKA.
                </Text>
              </View>
            </View>

            {/* 5 6 7*/}

            <View
              style={[
                styles.sideBySideContainer,
                {
                  flexDirection: "row",
                  height: 35,
                  borderTopWidth: 1,
                  borderTopColor: "black",
                  borderTopStyle: "solid",
                },
              ]}
            >
              {/* Left Section */}
              <View
                style={[
                  styles.leftSection,
                  {
                    borderLeftWidth: 0,
                    flex: 1,
                    borderRightWidth: 1,
                    justifyContent: "center",

                    paddingLeft: 5,
                  },
                ]}
              >
                <Text
                  style={{
                    fontWeight: "medium",
                    fontSize: 8,
                    marginTop: -9,
                    alignItems: "flex-start",
                  }}
                >
                  Declared means of conveyance
                </Text>

                <Text
                  style={{
                    fontWeight: "medium",
                    fontSize: 9,
                    paddingLeft: 120,
                  }}
                >
                  BY SEA
                </Text>
              </View>

              {/* Right Section */}
              <View
                style={[
                  styles.rightSection,
                  {
                    borderLeftWidth: 0,
                    flex: 1,
                    borderRightWidth: 0,
                    justifyContent: "center",
                    paddingLeft: 5,
                    flexDirection: "row",
                  },
                ]}
              >
                {/* First Column */}
                <View style={{ flex: 1 }}>
                  <Text
                    style={{
                      fontWeight: "medium",
                      fontSize: 8,
                      marginTop: -9,
                      alignItems: "flex-start",
                    }}
                  >
                    Place of origin
                  </Text>

                  <Text
                    style={{
                      fontWeight: "medium",
                      fontSize: 9,
                      paddingLeft: 40,
                    }}
                  >
                    India
                  </Text>
                </View>

                {/* Second Column */}
                <View
                  style={{
                    flex: 1,
                    borderLeftWidth: 1,
                    height: 35,
                    marginTop: -10,
                  }}
                >
                  <Text
                    style={{
                      fontWeight: "medium",
                      fontSize: 8,
                      alignItems: "flex-start",
                      paddingTop: 2,
                      paddingLeft: 5,
                    }}
                  >
                    Declared point of entry
                  </Text>

                  <Text
                    style={{
                      fontWeight: "medium",
                      fontSize: 9,
                      paddingLeft: 20,
                    }}
                  >
                    COLOMBO, SRI LANKA
                  </Text>
                </View>
              </View>
            </View>

            {/* 8 9 */}
            <View
              style={[
                styles.sideBySideContainer,
                {
                  flexDirection: "row",
                  height: 35,
                  borderTopWidth: 1,
                  borderTopColor: "black",
                  borderTopStyle: "solid",
                },
              ]}
            >
              {/* Left Section */}
              <View
                style={[
                  styles.leftSection,
                  {
                    borderLeftWidth: 0,
                    flex: 1,
                    borderRightWidth: 1,
                    justifyContent: "center",

                    paddingLeft: 5,
                  },
                ]}
              >
                <Text
                  style={{
                    fontWeight: "medium",
                    fontSize: 8,
                    marginTop: -9,
                    alignItems: "flex-start",
                  }}
                >
                  Distinguish marks
                </Text>

                <Text
                  style={{
                    fontWeight: "medium",
                    fontSize: 9,
                    paddingLeft: 120,
                  }}
                >
                  AS PER INVOICE
                </Text>
              </View>
              <View
                style={[
                  styles.rightSection,
                  {
                    borderLeftWidth: 0,
                    flex: 1,
                    borderRightWidth: 0,
                    justifyContent: "center",
                    paddingLeft: 5,
                    flexDirection: "row",
                  },
                ]}
              >
                {/* Second Column */}
                <View style={{ flex: 1 }}>
                  <Text
                    style={{
                      fontWeight: "medium",
                      fontSize: 8,
                      marginTop: -9,
                      alignItems: "flex-start",
                    }}
                  >
                    Number & description of packages
                  </Text>

                  <Text
                    style={{
                      fontWeight: "medium",
                      fontSize: 9,
                      paddingLeft: 120,
                    }}
                  >
                    2700 BAGS
                  </Text>
                </View>
              </View>
            </View>

            {/*10 11 */}

            <View
              style={[
                styles.sideBySideContainer,
                {
                  flexDirection: "row",
                  height: 45,
                  borderTopWidth: 1,
                  borderTopColor: "black",
                  borderTopStyle: "solid",
                },
              ]}
            >
              {/* Left Section */}
              <View
                style={[
                  styles.leftSection,
                  {
                    borderLeftWidth: 0,
                    flex: 1,
                    borderRightWidth: 1,
                    justifyContent: "center",

                    paddingLeft: 5,
                  },
                ]}
              >
                <Text
                  style={{
                    fontWeight: "medium",
                    fontSize: 8,
                    marginTop: -9,
                    alignItems: "flex-start",
                  }}
                >
                  Name of produce / Botanical name of plants
                </Text>

                <Text
                  style={{
                    fontWeight: "medium",
                    fontSize: 10,
                  }}
                >
                  RICE,GRAIN-PARBOILED RICE GR 11-5% BROKEN
                </Text>

                <Text
                  style={{
                    fontWeight: "medium",
                    fontSize: 9,
                    paddingLeft: 120,
                  }}
                >
                  (Oryza sativa)
                </Text>
              </View>
              <View
                style={[
                  styles.rightSection,
                  {
                    borderLeftWidth: 0,
                    flex: 1,
                    borderRightWidth: 0,
                    justifyContent: "center",
                    paddingLeft: 5,
                    flexDirection: "row",
                  },
                ]}
              >
                {/* Second Column */}
                <View style={{ flex: 1 }}>
                  <Text
                    style={{
                      fontWeight: "medium",
                      fontSize: 8,
                      marginTop: -9,
                      alignItems: "flex-start",
                    }}
                  >
                    Quantity declared
                  </Text>

                  <Text
                    style={{
                      fontWeight: "medium",
                      fontSize: 9,
                      paddingLeft: 120,
                    }}
                  >
                    81.000 MTS
                  </Text>
                </View>
              </View>
            </View>

            {/*text*/}
            <View
              style={[
                styles.sideBySideContainer,
                {
                  flexDirection: "row",
                  height: 45,
                  borderTopWidth: 1,
                  borderTopColor: "black",
                  borderTopStyle: "solid",
                },
              ]}
            >
              {/* Left Section */}
              <View
                style={[
                  styles.leftSection,
                  {
                    borderLeftWidth: 0,
                    flex: 1,
                    borderRightWidth: 0,
                    justifyContent: "center",

                    paddingLeft: 5,
                  },
                ]}
              >
                <Text
                  style={{
                    fontWeight: "medium",
                    fontSize: 8,
                    marginBottom: 30,
                    justifyContent: "space-around",
                    alignItems: "flex-start",
                  }}
                >
                  This is to certify that the plants or plant products described
                  above have been inspected according to appropriate procedures
                  and are considered to be free from quarantine pests and
                  practically free from other injurious pests and they are
                  considered to conform with the current phytosanitary
                  regulations of the importing country
                </Text>
              </View>
            </View>

            <View
              style={[
                styles.sideBySideContainer,
                {
                  flexDirection: "row",
                  height: 20,
                  borderTopWidth: 1,
                  borderTopColor: "black",
                  borderTopStyle: "solid",
                },
              ]}
            >
              {/* Left Section */}
              <View
                style={[
                  styles.leftSection,
                  {
                    borderLeftWidth: 0,
                    flex: 1,
                    borderRightWidth: 0,
                    justifyContent: "center",

                    paddingLeft: 5,
                  },
                ]}
              >
                <Text
                  style={{
                    fontWeight: "bold",
                    fontSize: 7,
                    marginBottom: 10,
                    justifyContent: "space-around",
                    textAlign: "center",
                  }}
                >
                  DISINFESTATION AND / ORDISINFECTION TREATMENT
                </Text>
              </View>
            </View>

            {/*12 13*/}
            <View
              style={[
                styles.sideBySideContainer,
                {
                  flexDirection: "row",
                  height: 33,
                  borderTopWidth: 1,
                  borderTopColor: "black",
                  borderTopStyle: "solid",
                },
              ]}
            >
              {/* Left Section */}
              <View
                style={[
                  styles.leftSection,
                  {
                    borderLeftWidth: 0,
                    flex: 1,
                    borderRightWidth: 1,
                    justifyContent: "center",

                    paddingLeft: 5,
                  },
                ]}
              >
                <Text
                  style={{
                    fontWeight: "medium",
                    fontSize: 8,
                    marginTop: -9,
                    alignItems: "flex-start",
                  }}
                >
                  Date:
                </Text>

                <Text
                  style={{
                    fontWeight: "medium",
                    fontSize: 9,
                  }}
                >
                  NIL
                </Text>
              </View>
              <View
                style={[
                  styles.rightSection,
                  {
                    borderLeftWidth: 0,
                    flex: 1,
                    borderRightWidth: 0,
                    justifyContent: "center",
                    paddingLeft: 5,
                    flexDirection: "row",
                  },
                ]}
              >
                {/* Second Column */}
                <View style={{ flex: 1 }}>
                  <Text
                    style={{
                      fontWeight: "medium",
                      fontSize: 8,
                      marginTop: -9,
                      alignItems: "flex-start",
                    }}
                  >
                    Treatment:
                  </Text>

                  <Text
                    style={{
                      fontWeight: "medium",
                      fontSize: 9,
                    }}
                  >
                    NIL
                  </Text>
                </View>
              </View>
            </View>

            {/*14-15*/}
            <View
              style={[
                styles.sideBySideContainer,
                {
                  flexDirection: "row",
                  height: 33,
                  borderTopWidth: 1,
                  borderTopColor: "black",
                  borderTopStyle: "solid",
                },
              ]}
            >
              {/* Left Section */}
              <View
                style={[
                  styles.leftSection,
                  {
                    borderLeftWidth: 0,
                    flex: 1,
                    borderRightWidth: 1,
                    justifyContent: "center",

                    paddingLeft: 5,
                  },
                ]}
              >
                <Text
                  style={{
                    fontWeight: "medium",
                    fontSize: 8,
                    marginTop: -9,
                    alignItems: "flex-start",
                  }}
                >
                  Chemical (Active ingredients):
                </Text>

                <Text
                  style={{
                    fontWeight: "medium",
                    fontSize: 9,
                  }}
                >
                  NIL
                </Text>
              </View>
              <View
                style={[
                  styles.rightSection,
                  {
                    borderLeftWidth: 0,
                    flex: 1,
                    borderRightWidth: 0,
                    justifyContent: "center",
                    paddingLeft: 5,
                    flexDirection: "row",
                  },
                ]}
              >
                {/* Second Column */}
                <View style={{ flex: 1 }}>
                  <Text
                    style={{
                      fontWeight: "medium",
                      fontSize: 8,
                      marginTop: -9,
                      alignItems: "flex-start",
                    }}
                  >
                    Duration & Temparature
                  </Text>

                  <Text
                    style={{
                      fontWeight: "medium",
                      fontSize: 9,
                    }}
                  >
                    NIL
                  </Text>
                </View>
              </View>
            </View>

            {/* 16 -17 */}
            <View
              style={[
                styles.sideBySideContainer,
                {
                  flexDirection: "row",
                  height: 33,
                  borderTopWidth: 1,
                  borderTopColor: "black",
                  borderTopStyle: "solid",
                },
              ]}
            >
              {/* Left Section */}
              <View
                style={[
                  styles.leftSection,
                  {
                    borderLeftWidth: 0,
                    flex: 1,
                    borderRightWidth: 1,
                    justifyContent: "center",

                    paddingLeft: 5,
                  },
                ]}
              >
                <Text
                  style={{
                    fontWeight: "medium",
                    fontSize: 8,
                    marginTop: -9,
                    alignItems: "flex-start",
                  }}
                >
                  Concentration.
                </Text>

                <Text
                  style={{
                    fontWeight: "medium",
                    fontSize: 9,
                  }}
                >
                  NIL
                </Text>
              </View>
              <View
                style={[
                  styles.rightSection,
                  {
                    borderLeftWidth: 0,
                    flex: 1,
                    borderRightWidth: 0,
                    justifyContent: "center",
                    paddingLeft: 5,
                    flexDirection: "row",
                  },
                ]}
              >
                {/* Second Column */}
                <View style={{ flex: 1 }}>
                  <Text
                    style={{
                      fontWeight: "medium",
                      fontSize: 8,
                      marginTop: -9,
                      alignItems: "flex-start",
                    }}
                  >
                    Additional information:
                  </Text>

                  <Text
                    style={{
                      fontWeight: "medium",
                      fontSize: 9,
                    }}
                  >
                    NIL
                  </Text>
                </View>
              </View>
            </View>

            {/* 18 */}
            <View
              style={[
                styles.sideBySideContainer,
                {
                  borderBottom: 1,
                  flexDirection: "row",
                  height: 45,
                  borderTopWidth: 1,
                  borderTopColor: "black",
                  borderTopStyle: "solid",
                },
              ]}
            >
              <View
                style={[
                  styles.leftSection,
                  {
                    borderLeftWidth: 0,
                    flex: 1,
                    justifyContent: "center",
                    paddingLeft: 5,
                  },
                ]}
              >
                <Text
                  style={{
                    fontWeight: "medium",
                    fontSize: 8,
                    marginTop: -9,
                    alignItems: "flex-start",
                  }}
                >
                  Additional Declaration:
                </Text>

                <Text
                  style={{
                    fontWeight: "bold",
                    fontSize: 9,
                  }}
                >
                  Invoice no - LE/EXP/24-25/073 Invoice date -10.12.2024
                </Text>
              </View>
            </View>

            {/* last 3 section */}

            <View
              style={[
                styles.sideBySideContainer,
                {
                  height: 100,
                  flexDirection: "row",
                },
              ]}
            >
              {/* Left Section */}
              <View
                style={[
                  styles.rightSection,
                  {
                    borderRight: 1,
                    paddingHorizontal: 10,
                    paddingVertical: 5,
                  },
                ]}
              >
                <Text
                  style={[
                    styles.mediumText,
                    {
                      borderBottomWidth: 1,
                      width: 123,
                      marginBottom: 10,
                      height: 20,
                      fontSize: 8,
                      marginLeft: -10,
                      paddingLeft: 5,
                    },
                  ]}
                >
                  Date:
                </Text>
                <Text
                  style={{
                    fontWeight: "medium",
                    borderTopWidth: 0,
                    width: "100%",
                    marginTop: 0,
                    paddingLeft: 5,
                    fontSize: 8,
                    marginLeft: -10,
                  }}
                >
                  Place of Issue
                </Text>
                <Text
                  style={[
                    {
                      paddingLeft: 5,
                      fontWeight: "heavy",
                      borderTopWidth: 0,
                      width: "100%",
                      marginBottom: 0,
                      fontSize: 9,
                      marginLeft: -10,
                    },
                  ]}
                >
                  CHENNAI
                </Text>
                <Text
                  style={[
                    styles.boldText,
                    {
                      width: 123,
                      paddingLeft: 5,
                      borderTopWidth: 1,
                      marginTop: 15,
                      marginLeft: -10,
                    },
                  ]}
                ></Text>
                <Text
                  style={[
                    styles.boldText,
                    {
                      width: 123,
                      paddingLeft: 5,
                      height: 20,
                      fontSize: 8,
                      marginTop: 2,
                      marginLeft: -10,
                    },
                  ]}
                >
                  Code No.
                </Text>
              </View>

              {/* Stamp Section */}
              <View
                style={[
                  styles.centerSection,
                  {
                    paddingTop: 85,
                    justifyContent: "flex-start",
                    paddingHorizontal: 10,
                    borderRightWidth: 1,
                    borderRightColor: "black",
                    borderRightStyle: "solid",
                  },
                ]}
              >
                <Text style={[{ borderBottom: 0, fontSize: 10 }]}>
                  Stamp of Organisation
                </Text>
              </View>

              {/* Right Section */}
              <View
                style={[
                  styles.rightSection,
                  {
                    paddingHorizontal: 16,
                    paddingVertical: 14,
                  },
                ]}
              >
                {/* Name of Authorised Officer */}
                <Text
                  style={{
                    fontWeight: "light",
                    fontSize: 8,
                    width: "100%",
                    marginBottom: 8,
                  }}
                >
                  Name of Authorised Officer:
                </Text>
                <Text
                  style={{
                    fontWeight: "light",
                    fontSize: 8,
                    width: "100%",
                    marginBottom: 5,
                  }}
                >
                  {/*name */}
                </Text>
                {/* Signature & Stamp */}
                <Text
                  style={{
                    fontWeight: "light",
                    fontSize: 8,
                    width: "100%",
                    marginTop: 5,
                    textAlign: "left",
                    justifyContent: "space-around",
                  }}
                >
                  Signature & Stamp of Authorised Officer:-
                </Text>
                <Text
                  style={{
                    fontWeight: "light",
                    fontSize: 8,
                    width: "100%",
                    marginTop: 8,
                    textAlign: "left",
                  }}
                >
                  {/* sign*/}
                </Text>
              </View>
            </View>
          </View>
          <Text
            style={[
              {
                justifyContent: "space-around",
                paddingLeft: 10,
                fontWeight: "medium",
                fontSize: 9,
              },
            ]}
          >
            No financial liability with respect of this certificate shall attach
            to the Ministry of Agriculture (Department of Agriculture
            cooperation),
          </Text>
          <Text
            style={[
              {
                justifyContent: "space-around",
                paddingLeft: 10,
                fontWeight: "medium",
                fontSize: 9,
              },
            ]}
          >
            Government of India Or to any officers of its officers or
            representative.
          </Text>
        </Page>
      </Document>
    </PDFViewer>
  );
};
export default PhytoCertificate;
