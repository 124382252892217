export enum SalesContractStatus {
  PENDING = "PENDING",
  IN_PROGRESS = "IN_PROGRESS",
  COMPLETED = "COMPLETED",
}

export enum SalesInvoiceStatus {
  PENDING = "PENDING",
  IN_TRANSIT = "IN_TRANSIT",
  COMPLETED = "COMPLETED",
}

export enum ShipmentType {
  ALLOWED = "ALLOWED",
  NOT_ALLOWED = "NOT ALLOWED",
}

export enum WeightUnits {
  MT = "MT",
  KG = "KG",
}

export enum PackingTypes {
  BAGS = "BAGS",
}

export enum PaymentRole {
  BUYER = "BUYER",
  SUPPLIER = "SUPPLIER",
  CHA = "CHA",
  LOGISTIC = "LOGISTIC",
  EXPENSE = "EXPENSE",
  BROKER = "BROKER",
}

export enum PaymentStatus {
  PENDING = "PENDING",
  ADVANCE = "ADVANCE",
  PARTIALLY_PAID = "PARTIALLY_PAID",
  COMPLETED = "COMPLETED",
}

export enum PaymentMethods {
  BANK_TRANSFER = "BANK_TRANSFER",
  CHEQUE = "CHEQUE",
  UPI = "UPI",
  CASH = "CASH",
}

export enum TransactionTypes {
  CREDIT = "CREDIT",
  DEBIT = "DEBIT",
}

export enum BankType {
  EXPORTER = "EXPORTER",
  BUYER = "BUYER",
  SUPPLIER = "SUPPLIER",
  LOGISTIC = "LOGISTIC",
  CHA = "CHA",
  BROKER = "BROKER",
}

export enum ContainerSize {
  TWENTY = "20",
  FORTY = "40",
}

export enum DocumentType {
  SELF = "SELF",
}

export enum SelfDocuments {
  PHYTOSANITARY_CERTIFICATE = "PHYTOSANITARY_CERTIFICATE",
  ANNEXURE_C = "ANNEXURE_C",
  COMMERCIAL_INVOICE = "COMMERCIAL_INVOICE",
  EXPORT_INVOICE_CUM_PACKING_LIST = "EXPORT_INVOICE_CUM_PACKING_LIST",
  PROFORMA_INVOICE = "PROFORMA_INVOICE",
  BILL_OF_LADING = "BILL_OF_LADING",
  HEALTH_AND_QUALITY_CERTIFICATE = "HEALTH_AND_QUALITY_CERTIFICATE",
  CERTIFICATE_OF_NON_GMO = "CERTIFICATE_OF_NON_GMO",
  WEIGHT_AND_QUALITY_CERTIFICATE = "WEIGHT_AND_QUALITY_CERTIFICATE",
  CERTIFICATE_OF_ORIGIN = "CERTIFICATE_OF_ORIGIN",
  FORM_AI = "FORM_AI",
  VERIFIED_GROSS_MASS = "VERIFIED_GROSS_MASS",
  PACKING_LIST = "PACKING_LIST",
}
