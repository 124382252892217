import { GET, POST, PUT } from "./_config";

export const packingTypesListApi = async (query: string) =>
  await GET(`/packing-types?${query || ""}`, true);

export const getAllPackingTypesApi = async () =>
  await GET(`/packing-types/list`, true);

export const getPackingTypeByIdApi = async (packingTypeId: string) =>
  await GET(`/packing-types/id/${packingTypeId}`, true);

export const createPackingTypeApi = async (body: any) =>
  await POST(`/packing-types`, body, true);

export const updatePackingTypeApi = async (packingTypesId: string, body: any) =>
  await PUT(`/packing-types?id=${packingTypesId}`, body, true);

export const updatePackingTypeStatusApi = async (body: any) =>
  await POST(`/packing-types/update-status`, body, true);

const PackingTypeService = {
  packingTypesListApi,
  getAllPackingTypesApi,
  getPackingTypeByIdApi,
  createPackingTypeApi,
  updatePackingTypeApi,
  updatePackingTypeStatusApi,
};

export default PackingTypeService;
