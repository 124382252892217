import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  PDFViewer,
  Font,
  Image,
} from "@react-pdf/renderer";

Font.register({
  family: "Times New Roman",
  fonts: [{ src: "/fonts/Times-New-Roman.ttf" }],
});

Font.register({
  family: "Times New Roman",
  fonts: [{ src: "/fonts/Times-New-Roman-Bold.ttf" }],
});

export const styles = StyleSheet.create({
  smallFontSize: {
    fontSize: 6,
  },
  mediumFontSize: {
    fontSize: 6.5,
  },
  regularFontSize: {
    fontSize: 8,
  },
  largeFontSize: {
    fontSize: 9,
  },
  boldFont: {
    fontWeight: "bold",
  },
  textUnderline: {
    textDecoration: "underline",
  },
  header: {
    fontSize: 8,
    textAlign: "center",
    padding: 1,
    fontWeight: "bold",
    borderBottomWidth: 1,
  },
  contentPadding: {
    padding: 1,
  },
  page: {
    paddingHorizontal: 30,
    paddingVertical: 15,
    fontFamily: "Times New Roman",
    backgroundColor: "white",
  },
  fullpage: {
    margin: 7,
    borderStyle: "solid",
    borderWidth: 1,
    borderColor: "#000000",
  },
  leftSection: {
    flex: 1,
    padding: 10,
    paddingLeft: 0,
  },
  rightSection: {
    flex: 3,
    padding: 10,
  },
  section: {
    borderBottomWidth: 1,
    borderbottomStyle: "solid",
  },
  sideBySideContainer: { flexDirection: "row" },
  halfWidthRightSection: {
    width: "50%",
    borderBottomWidth: 1,
    borderbottomStyle: "solid",
    borderRightWidth: 1,
    textAlign: "left",
  },
  halfWidthleftSection: {
    width: "50%",
    borderBottomWidth: 1,
    borderbottomStyle: "solid",
  },
  tableContainer: {
    borderBottomWidth: 1,
  },
  tableRow: {
    flexDirection: "row",
    flexGrow: 1,
  },
  tableHeader: {
    fontSize: 7,
    width: "50%",
  },
  tableData: {
    fontSize: 7,
    width: "70%",
  },
  tableCell: {
    padding: 5,
    fontSize: 7,
    textAlign: "center",
    borderRightWidth: 1,
  },
  addressBlock: { fontSize: 7, marginBottom: 1.5 },
});

const ExportInvoiceCumPackingList = () => {
  return (
    <PDFViewer width="100%" height="2000px">
      <Document>
        <Page style={styles.page} size="A4">
          <View style={[styles.fullpage, { marginTop: 40 }]}>
            {/* HEADER */}
            <Text style={[styles.header]}>EXPORT INVOICE CUM PACKING LIST</Text>

            {/* BLANK SECTION */}
            <Text style={[styles.header]}>
              SUPPLY MEANT FOR EXPORT WITH PAYMENT OF INTEGRATED TAX (IGST)
            </Text>

            {/* EXPORTER | INVOICE NO | IEC NO | BUYER SECTION */}
            <View style={styles.sideBySideContainer}>
              <View
                style={[styles.halfWidthRightSection, styles.contentPadding]}
              >
                <Text style={styles.addressBlock}>
                  <Text style={[styles.regularFontSize, styles.boldFont]}>
                    EXPORTER:
                  </Text>
                </Text>
                <Text style={[styles.addressBlock, styles.regularFontSize]}>
                  MS LAXMI ENTERPRISE
                </Text>
                <Text style={[styles.addressBlock, styles.regularFontSize]}>
                  PLOT NO. 612, NAGOR ROAD, GUJARAT, 370001 (INDIA)
                </Text>
                <Text style={[styles.addressBlock, styles.regularFontSize]}>
                  GSTIN: 24AALFM4290M1ZU
                </Text>
                <Text style={[styles.addressBlock, styles.regularFontSize]}>
                  PAN NO: AALFM4290M
                </Text>
                <Text style={[styles.addressBlock, styles.regularFontSize]}>
                  AEO REG. NO. INAALFM4290M1F219 (T1)
                </Text>
              </View>
              <View style={[styles.halfWidthleftSection]}>
                <View style={[styles.sideBySideContainer]}>
                  <View
                    style={[
                      {
                        borderBottom: 1,
                        borderRight: 1,
                        flex: 1.5,
                      },
                    ]}
                  >
                    <Text
                      style={[
                        styles.regularFontSize,
                        {
                          paddingLeft: 1,
                          borderBottom: 1,
                        },
                      ]}
                    >
                      INVOICE NO & DATE:
                    </Text>

                    <Text
                      style={[
                        styles.regularFontSize,
                        styles.boldFont,
                        { paddingLeft: 1 },
                      ]}
                    >
                      LE/EXP/24-25/036, 01/10/2024
                    </Text>
                  </View>
                  <View
                    style={[
                      {
                        borderBottom: 1,
                        flex: 1.5,
                      },
                    ]}
                  >
                    <Text
                      style={[
                        styles.regularFontSize,
                        {
                          borderBottom: 1,
                          textAlign: "center",
                        },
                      ]}
                    >
                      IEC NO.
                    </Text>

                    <Text
                      style={[
                        styles.regularFontSize,
                        styles.boldFont,
                        { textAlign: "center" },
                      ]}
                    >
                      3712001126
                    </Text>
                  </View>
                </View>
                <View style={styles.section}>
                  <Text style={[styles.mediumFontSize, styles.contentPadding]}>
                    SALES CONTRACT NO. SC/2425/LE14 DATE: 20/07/2024
                  </Text>
                </View>
                <View style={styles.contentPadding}>
                  <Text style={styles.addressBlock}>
                    <Text
                      style={[
                        styles.largeFontSize,
                        styles.boldFont,
                        styles.textUnderline,
                      ]}
                    >
                      NOTIFY PARTY (BUYER) :
                    </Text>
                  </Text>
                  <Text style={[styles.regularFontSize]}>
                    SAME AS CONSIGNEE
                  </Text>
                </View>
              </View>
            </View>

            {/* CONSIGNEE | BANK DETAILS */}
            <View style={styles.sideBySideContainer}>
              {/* CONSIGNEE */}
              <View
                style={[styles.halfWidthRightSection, styles.contentPadding]}
              >
                <Text style={styles.addressBlock}>
                  <Text
                    style={[
                      styles.regularFontSize,
                      styles.textUnderline,
                      styles.boldFont,
                    ]}
                  >
                    CONSIGNEE:
                  </Text>
                </Text>
                <Text style={[styles.addressBlock, styles.regularFontSize]}>
                  PT. ASIAN AGRO COMMODITIES
                </Text>
                <Text style={[styles.addressBlock, styles.regularFontSize]}>
                  CIPUTRA CITRA TOWERS (NORTH TOWER) 7TH FLOOR UNIT L,
                </Text>
                <Text style={[styles.addressBlock, styles.regularFontSize]}>
                  L. CASA NO. 170, JL. BENYAMIN SUAEB KAV. A6,
                </Text>
                <Text style={[styles.addressBlock, styles.regularFontSize]}>
                  GSTIN: 24AALFM4290M1ZU
                </Text>
                <Text style={[styles.addressBlock, styles.regularFontSize]}>
                  KEBON KOSONG, KEMAYORAN, JAKARTA PUSAT 10630, INDONESIA
                </Text>
                <Text style={[styles.addressBlock, styles.regularFontSize]}>
                  NPWP: 082.443.235.5-614.000
                </Text>
              </View>
              {/* BANK DETAILS */}
              <View
                style={[styles.halfWidthleftSection, styles.contentPadding]}
              >
                <Text style={styles.addressBlock}>
                  <Text
                    style={[
                      styles.addressBlock,
                      styles.regularFontSize,
                      styles.boldFont,
                      { textAlign: "center" },
                    ]}
                  >
                    BANK DETAILS
                  </Text>
                </Text>

                <View style={[styles.tableRow, styles.regularFontSize]}>
                  <Text style={styles.tableHeader}>BANK NAME</Text>
                  <Text style={styles.tableData}>ICICI BANK</Text>
                </View>

                <View style={[styles.tableRow, styles.regularFontSize]}>
                  <Text style={styles.tableHeader}>BANK ACCOUNT NO</Text>
                  <Text style={styles.tableData}>348705500409</Text>
                </View>

                <View style={[styles.tableRow, styles.regularFontSize]}>
                  <Text style={styles.tableHeader}>BANK IFS CODE</Text>
                  <Text style={styles.tableData}>ICIC0003487</Text>
                </View>

                <View style={[styles.tableRow, styles.regularFontSize]}>
                  <Text style={styles.tableHeader}>BANK SWIFT CODE</Text>
                  <Text style={styles.tableData}>ICICINBBCTS</Text>
                </View>

                <View style={[styles.tableRow, styles.regularFontSize]}>
                  <Text style={styles.tableHeader}>BANK BRANCH ADDRESS</Text>
                  <Text style={styles.tableData}>
                    BHUJ-ATMARAM CIRCLE BRANCH
                  </Text>
                </View>

                <View style={[styles.tableRow, styles.regularFontSize]}>
                  <Text style={styles.tableHeader}>BANK AD CODE</Text>
                  <Text style={styles.tableData}>6393867-5860006</Text>
                </View>
              </View>
            </View>

            {/* PORT DETAILS | DELIVERY TERM | PAYMENT TERM */}
            <View style={[styles.sideBySideContainer]}>
              <View style={[styles.halfWidthRightSection]}>
                <View style={[styles.sideBySideContainer]}>
                  <View
                    style={[
                      styles.contentPadding,
                      {
                        borderBottomWidth: 1,
                        borderRightWidth: 1,
                        flex: 2,
                      },
                    ]}
                  >
                    <Text style={[styles.regularFontSize]}>Pre-Carrige by</Text>
                    <Text
                      style={[
                        styles.regularFontSize,
                        styles.boldFont,
                        { textAlign: "center" },
                      ]}
                    >
                      SEA
                    </Text>
                  </View>

                  <View
                    style={[
                      styles.contentPadding,
                      {
                        borderBottomWidth: 1,
                        flex: 2,
                      },
                    ]}
                  >
                    <Text style={[styles.regularFontSize]}>
                      Place of Receipt by Pre-carrier
                    </Text>
                    <Text
                      style={[
                        styles.regularFontSize,
                        styles.boldFont,
                        { textAlign: "center", borderRightWidth: 0 },
                      ]}
                    >
                      MUNDRA, INDIA
                    </Text>
                  </View>
                </View>

                {/* 2 */}
                <View style={[styles.sideBySideContainer]}>
                  <View
                    style={[
                      styles.contentPadding,
                      {
                        borderBottomWidth: 1,
                        borderRightWidth: 1,
                        flex: 2,
                      },
                    ]}
                  >
                    <Text style={[styles.regularFontSize]}>
                      Vessel/ Flight No
                    </Text>
                    <Text
                      style={[
                        styles.regularFontSize,
                        styles.boldFont,
                        { textAlign: "center" },
                      ]}
                    >
                      INTERASIA PROGRESS / E091
                    </Text>
                  </View>

                  <View
                    style={[
                      styles.contentPadding,
                      {
                        borderBottomWidth: 1,
                        flex: 2,
                      },
                    ]}
                  >
                    <Text style={[styles.regularFontSize]}>
                      Port of Loading
                    </Text>

                    <Text
                      style={[
                        styles.regularFontSize,
                        styles.boldFont,
                        { textAlign: "center" },
                      ]}
                    >
                      MUNDRA, INDIA
                    </Text>
                  </View>
                </View>

                {/* 3*/}
                <View style={[styles.sideBySideContainer]}>
                  <View
                    style={[
                      styles.contentPadding,
                      {
                        borderRightWidth: 1,
                        flex: 2,
                      },
                    ]}
                  >
                    <Text style={[styles.regularFontSize]}>
                      Port of Discharge
                    </Text>
                    <Text
                      style={[
                        styles.regularFontSize,
                        styles.boldFont,
                        { textAlign: "center" },
                      ]}
                    >
                      JAKARTA, INDONESIA
                    </Text>
                  </View>

                  <View
                    style={[
                      styles.contentPadding,
                      {
                        flex: 2,
                      },
                    ]}
                  >
                    <Text style={[styles.regularFontSize]}>
                      Final Destination
                    </Text>

                    <Text
                      style={[
                        styles.regularFontSize,
                        styles.boldFont,
                        { textAlign: "center" },
                      ]}
                    >
                      JAKARTA, INDONESIA
                    </Text>
                  </View>
                </View>
              </View>

              <View style={[styles.halfWidthleftSection]}>
                <View style={[styles.sideBySideContainer]}>
                  <View
                    style={[
                      styles.contentPadding,
                      {
                        borderBottomWidth: 1,
                        flex: 2,
                      },
                    ]}
                  >
                    <Text style={[styles.regularFontSize]}>
                      Delivery Term:{" "}
                      <Text style={[styles.boldFont]}>
                        CIF JAKARTA, INDONESIA
                      </Text>
                    </Text>
                  </View>
                </View>
                <View style={[styles.sideBySideContainer]}>
                  <View
                    style={[
                      {
                        borderBottomWidth: 1,
                        flex: 2,
                      },
                    ]}
                  >
                    <Text style={[styles.regularFontSize, { paddingLeft: 1 }]}>
                      Payment Terms:{" "}
                      <Text style={[styles.boldFont]}>
                        90% AGAINST SCAN COPY OF DOCUMENTS & 10 % AFTER 7 DAYS
                        OF ARRIVAL.
                      </Text>
                    </Text>
                  </View>
                </View>

                <View style={[styles.sideBySideContainer]}>
                  <View
                    style={[
                      styles.contentPadding,
                      {
                        borderBottomWidth: 1,
                        borderRightWidth: 1,
                        flex: 2,
                      },
                    ]}
                  >
                    <Text style={[styles.regularFontSize]}>
                      Country of Origin of Goods
                    </Text>
                  </View>
                  <View
                    style={[
                      styles.contentPadding,
                      {
                        borderBottomWidth: 1,
                        flex: 2,
                      },
                    ]}
                  >
                    <Text style={[styles.regularFontSize]}>
                      Country of Final Destination
                    </Text>
                  </View>
                </View>
                <View style={[styles.sideBySideContainer, { borderBottom: 1 }]}>
                  <View
                    style={[
                      styles.contentPadding,
                      {
                        borderRightWidth: 1,
                        flex: 2,
                      },
                    ]}
                  >
                    <Text
                      style={[
                        styles.regularFontSize,
                        styles.boldFont,
                        { textAlign: "center" },
                      ]}
                    >
                      INDIA
                    </Text>
                  </View>
                  <View
                    style={[
                      styles.contentPadding,
                      {
                        flex: 2,
                      },
                    ]}
                  >
                    <Text
                      style={[
                        styles.regularFontSize,
                        styles.boldFont,
                        { textAlign: "center" },
                      ]}
                    >
                      {" "}
                      INDONESIA
                    </Text>
                  </View>
                </View>
              </View>
            </View>

            {/* MAIN TABLE */}
            <View>
              {/* TABLE HEADER */}
              <View
                style={[
                  styles.tableRow,
                  {
                    borderBottom: 1,
                  },
                ]}
              >
                <Text
                  style={[
                    {
                      fontSize: 8,
                      paddingLeft: 1,
                      flex: 5,
                    },
                  ]}
                >
                  Marks & Nos./ Container No.
                </Text>

                <Text
                  style={[
                    {
                      fontSize: 8,
                      flex: 9,
                      paddingLeft: 30,
                      verticalAlign: "sub",
                      textAlign: "center",
                    },
                  ]}
                >
                  No. & Kind of Pkgs
                </Text>

                <Text
                  style={[
                    {
                      fontSize: 8,
                      flex: 9,
                      paddingLeft: 40,
                      borderRightWidth: 1,
                      verticalAlign: "sub",
                      textAlign: "center",
                    },
                  ]}
                >
                  Description of Goods
                </Text>

                <Text
                  style={[
                    styles.mediumFontSize,
                    {
                      fontSize: 8,
                      borderRight: 1,
                      textAlign: "center",
                      flexWrap: "wrap",
                      width: 60,
                    },
                  ]}
                >
                  QUANTITY{"  "}MTS
                </Text>

                <Text
                  style={[
                    styles.mediumFontSize,
                    {
                      fontSize: 8,
                      borderRight: 1,
                      textAlign: "center",
                      flexWrap: "wrap",
                      width: 60,
                    },
                  ]}
                >
                  Rate (USD) Per MTS
                </Text>

                <Text
                  style={[
                    styles.mediumFontSize,
                    {
                      fontSize: 8,
                      borderRight: 0,
                      textAlign: "center",
                      flexWrap: "wrap",
                      width: 60,
                      verticalAlign: "sub",
                    },
                  ]}
                >
                  Total Value USD
                </Text>
              </View>

              {/* TABLE ROWS */}
              <View>
                {/* MAIN ROW */}
                <View
                  style={[
                    styles.tableRow,
                    {
                      height: 180,
                      borderBottom: 0,
                    },
                  ]}
                >
                  <Text
                    style={[
                      styles.regularFontSize,
                      styles.mediumFontSize,
                      {
                        fontSize: 7,
                        paddingLeft: 5,
                        flex: 4,
                        paddingTop: 15,
                        paddingBottom: 15,
                        textAlign: "center",
                      },
                    ]}
                  >
                    02 X 20' FCL
                  </Text>

                  <Text
                    style={[
                      styles.regularFontSize,
                      styles.mediumFontSize,
                      {
                        fontSize: 7,
                        flex: 11,
                        paddingLeft: 30,
                        paddingTop: 15,
                        paddingBottom: 15,
                        textAlign: "center",
                      },
                    ]}
                  >
                    (PACKED IN NEW JUTE BAGS) NET 50 KGS & GROSS 51 KGS EACH
                    BAG.
                  </Text>

                  <Text
                    style={[
                      styles.regularFontSize,
                      styles.mediumFontSize,
                      {
                        fontSize: 7,
                        flex: 7,
                        paddingLeft: 30,
                        paddingTop: 15,
                        paddingBottom: 15,
                        borderRightWidth: 1,
                        textAlign: "center",
                      },
                    ]}
                  >
                    INDIAN GROUNDNUT KERNELS {"\n"} (HS CODE: 12024210)
                  </Text>

                  <Text
                    style={[
                      styles.tableCell,
                      styles.mediumFontSize,
                      {
                        fontSize: 8,
                        fontWeight: "medium",
                        borderRight: 1,
                        textAlign: "center",
                        flexWrap: "wrap",
                        width: 60,
                        paddingTop: 15,
                      },
                    ]}
                  >
                    39.000
                  </Text>

                  <Text
                    style={[
                      styles.tableCell,
                      styles.mediumFontSize,
                      {
                        fontSize: 8,
                        fontWeight: "medium",
                        borderRight: 1,
                        textAlign: "center",
                        flexWrap: "wrap",
                        width: 60,
                        paddingTop: 15,
                      },
                    ]}
                  >
                    985.00
                  </Text>

                  <Text
                    style={[
                      styles.tableCell,
                      styles.mediumFontSize,
                      {
                        fontSize: 8,
                        fontWeight: "medium",
                        borderRight: 0,
                        textAlign: "center",
                        flexWrap: "wrap",
                        width: 60,
                        paddingTop: 15,
                      },
                    ]}
                  >
                    980000000
                  </Text>
                </View>

                {/* IGST ROW */}
                <View
                  style={[
                    styles.tableRow,
                    {
                      height: 60,
                      borderBottom: 0,
                    },
                  ]}
                >
                  <Text
                    style={[
                      styles.regularFontSize,
                      styles.mediumFontSize,
                      {
                        fontSize: 7,
                        paddingLeft: 5,
                        flex: 15,
                        paddingTop: 15,
                        paddingBottom: 15,
                        textAlign: "center",
                      },
                    ]}
                  >
                    SUPPLY MEANT FOR EXPORT WITH PAYMENT OF INTEGRATED {"\n"}{" "}
                    TAX (IGST)
                  </Text>

                  <Text
                    style={[
                      styles.regularFontSize,
                      styles.mediumFontSize,
                      {
                        fontSize: 7,
                        flex: 7,
                        paddingLeft: 30,
                        paddingTop: 15,
                        paddingBottom: 15,
                        borderRightWidth: 1,
                        textAlign: "center",
                      },
                    ]}
                  >
                    IGST PAYMENT: P (PAID) & IGST RATE : 5%
                  </Text>

                  <Text
                    style={[
                      styles.tableCell,
                      styles.mediumFontSize,
                      {
                        fontSize: 8,
                        fontWeight: "medium",
                        borderRight: 1,
                        textAlign: "center",
                        flexWrap: "wrap",
                        width: 60,
                        paddingTop: 15,
                      },
                    ]}
                  ></Text>

                  <Text
                    style={[
                      styles.tableCell,
                      styles.mediumFontSize,
                      {
                        fontSize: 8,
                        fontWeight: "medium",
                        borderRight: 1,
                        textAlign: "center",
                        flexWrap: "wrap",
                        width: 60,
                        paddingTop: 15,
                      },
                    ]}
                  ></Text>

                  <Text
                    style={[
                      styles.tableCell,
                      styles.mediumFontSize,
                      {
                        fontSize: 8,
                        fontWeight: "medium",
                        borderRight: 0,
                        textAlign: "center",
                        flexWrap: "wrap",
                        width: 60,
                        paddingTop: 15,
                      },
                    ]}
                  ></Text>
                </View>

                {/* IGST CALCULATION ROW */}
                <View
                  style={[
                    styles.tableRow,
                    {
                      height: 60,
                      borderBottom: 0,
                    },
                  ]}
                >
                  <Text
                    style={[
                      styles.regularFontSize,
                      styles.mediumFontSize,
                      {
                        fontSize: 7,
                        paddingLeft: 5,
                        flex: 4,
                        paddingTop: 15,
                        paddingBottom: 15,
                        textAlign: "center",
                      },
                    ]}
                  >
                    EX. RATE {"\n"} 1$ = 82.80 INR
                  </Text>

                  <Text
                    style={[
                      styles.regularFontSize,
                      styles.mediumFontSize,
                      {
                        fontSize: 7,
                        flex: 11,
                        paddingLeft: 30,
                        paddingTop: 15,
                        paddingBottom: 15,
                        textAlign: "center",
                      },
                    ]}
                  >
                    TOTAL TAXABLE / INVOICE VALUE {"\n"} RS. 3858480.00 INR
                  </Text>

                  <Text
                    style={[
                      styles.regularFontSize,
                      styles.mediumFontSize,
                      {
                        fontSize: 7,
                        flex: 7,
                        paddingLeft: 30,
                        paddingTop: 15,
                        paddingBottom: 15,
                        borderRightWidth: 1,
                        textAlign: "center",
                      },
                    ]}
                  >
                    TOTAL IGST VALUE {"\n"} RS. 192924.00 INR
                  </Text>

                  <Text
                    style={[
                      styles.tableCell,
                      styles.mediumFontSize,
                      {
                        fontSize: 8,
                        fontWeight: "medium",
                        borderRight: 1,
                        textAlign: "center",
                        flexWrap: "wrap",
                        width: 60,
                        paddingTop: 15,
                      },
                    ]}
                  ></Text>

                  <Text
                    style={[
                      styles.tableCell,
                      styles.mediumFontSize,
                      {
                        fontSize: 8,
                        fontWeight: "medium",
                        borderRight: 1,
                        textAlign: "center",
                        flexWrap: "wrap",
                        width: 60,
                        paddingTop: 15,
                      },
                    ]}
                  ></Text>

                  <Text
                    style={[
                      styles.tableCell,
                      styles.mediumFontSize,
                      {
                        fontSize: 8,
                        fontWeight: "medium",
                        borderRight: 0,
                        textAlign: "center",
                        flexWrap: "wrap",
                        width: 60,
                        paddingTop: 15,
                      },
                    ]}
                  ></Text>
                </View>

                {/* SUMMARY */}
                <View
                  style={[
                    styles.tableRow,
                    {
                      borderBottom: 0,
                    },
                  ]}
                >
                  <Text
                    style={[
                      {
                        paddingLeft: 5,
                        flex: 4,
                      },
                    ]}
                  ></Text>

                  <Text
                    style={[
                      styles.boldFont,
                      {
                        fontSize: 7,
                        flex: 18,
                        paddingLeft: 30,
                        paddingTop: 3,
                        textAlign: "center",
                        borderRight: 1,
                        borderTop: 1,
                        borderLeft: 1,
                        borderBottom: 1,
                      },
                    ]}
                  >
                    SUMMARY
                  </Text>

                  <Text
                    style={[
                      styles.tableCell,
                      {
                        borderRight: 1,
                        flexWrap: "wrap",
                        width: 60,
                      },
                    ]}
                  ></Text>

                  <Text
                    style={[
                      styles.tableCell,
                      {
                        borderRight: 1,
                        flexWrap: "wrap",
                        width: 60,
                      },
                    ]}
                  ></Text>

                  <Text
                    style={[
                      styles.tableCell,
                      {
                        fontSize: 8,
                        fontWeight: "medium",
                        borderRight: 0,
                        textAlign: "center",
                        flexWrap: "wrap",
                        width: 60,
                      },
                    ]}
                  ></Text>
                </View>

                {/* TOTAL NO. OF PACKAGES */}
                <View
                  style={[
                    styles.tableRow,
                    {
                      borderBottom: 0,
                    },
                  ]}
                >
                  <Text
                    style={[
                      {
                        paddingLeft: 5,
                        flex: 4,
                      },
                    ]}
                  ></Text>

                  <Text
                    style={[
                      {
                        fontSize: 7,
                        flex: 9,
                        paddingLeft: 29,
                        paddingTop: 2,
                        borderRight: 1,
                        borderLeft: 1,
                        borderBottom: 1,
                      },
                    ]}
                  >
                    TOTAL NO. OF PACKAGES
                  </Text>

                  <Text
                    style={[
                      {
                        fontSize: 7,
                        flex: 9,
                        paddingTop: 2,
                        textAlign: "center",
                        borderRight: 1,
                        borderBottom: 1,
                      },
                    ]}
                  >
                    780 BAGS
                  </Text>

                  <Text
                    style={[
                      styles.tableCell,
                      {
                        borderRight: 1,
                        flexWrap: "wrap",
                        width: 60,
                      },
                    ]}
                  ></Text>

                  <Text
                    style={[
                      styles.tableCell,
                      {
                        borderRight: 1,
                        flexWrap: "wrap",
                        width: 60,
                      },
                    ]}
                  ></Text>

                  <Text
                    style={[
                      styles.tableCell,
                      {
                        fontSize: 8,
                        fontWeight: "medium",
                        borderRight: 0,
                        textAlign: "center",
                        flexWrap: "wrap",
                        width: 60,
                      },
                    ]}
                  ></Text>
                </View>

                {/* NET WEIGHT (MTS) */}
                <View
                  style={[
                    styles.tableRow,
                    {
                      borderBottom: 0,
                    },
                  ]}
                >
                  <Text
                    style={[
                      {
                        paddingLeft: 5,
                        flex: 4,
                      },
                    ]}
                  ></Text>

                  <Text
                    style={[
                      {
                        fontSize: 7,
                        flex: 9,
                        paddingLeft: 29,
                        paddingTop: 2,
                        borderRight: 1,
                        borderLeft: 1,
                        borderBottom: 1,
                      },
                    ]}
                  >
                    NET WEIGHT (MTS)
                  </Text>

                  <Text
                    style={[
                      {
                        fontSize: 7,
                        flex: 9,
                        paddingTop: 2,
                        textAlign: "center",
                        borderRight: 1,
                        borderBottom: 1,
                      },
                    ]}
                  >
                    39.000
                  </Text>

                  <Text
                    style={[
                      styles.tableCell,
                      {
                        borderRight: 1,
                        borderBottom: 1,
                        flexWrap: "wrap",
                        width: 60,
                      },
                    ]}
                  ></Text>

                  <Text
                    style={[
                      styles.tableCell,
                      {
                        borderRight: 1,
                        borderBottom: 1,
                        flexWrap: "wrap",
                        width: 60,
                      },
                    ]}
                  ></Text>

                  <Text
                    style={[
                      styles.tableCell,
                      {
                        fontSize: 8,
                        fontWeight: "medium",
                        borderRight: 0,
                        borderBottom: 1,
                        textAlign: "center",
                        flexWrap: "wrap",
                        width: 60,
                      },
                    ]}
                  ></Text>
                </View>

                {/* GROSS WEIGHT (MTS) */}
                <View
                  style={[
                    styles.tableRow,
                    {
                      borderBottom: 0,
                    },
                  ]}
                >
                  <Text
                    style={[
                      {
                        paddingLeft: 5,
                        flex: 4,
                        borderBottom: 1,
                      },
                    ]}
                  ></Text>

                  <Text
                    style={[
                      {
                        fontSize: 7,
                        flex: 9,
                        paddingLeft: 29,
                        paddingTop: 2,
                        borderRight: 1,
                        borderLeft: 1,
                        borderBottom: 1,
                      },
                    ]}
                  >
                    GROSS WEIGHT (MTS)
                  </Text>

                  <Text
                    style={[
                      {
                        fontSize: 7,
                        flex: 9,
                        paddingTop: 2,
                        textAlign: "center",
                        borderRight: 1,
                        borderBottom: 1,
                      },
                    ]}
                  >
                    39.780
                  </Text>

                  <Text
                    style={[
                      {
                        borderRight: 1,
                        fontSize: 8,
                        paddingTop: 1,
                        textAlign: "center",
                        borderBottom: 1,
                        flexWrap: "wrap",
                        width: 120,
                      },
                    ]}
                  >
                    TOTAL CIF VALUE (IN USD)
                  </Text>

                  <Text
                    style={[
                      {
                        fontSize: 8,
                        paddingTop: 1,
                        fontWeight: "medium",
                        borderRight: 0,
                        borderBottom: 1,
                        textAlign: "center",
                        flexWrap: "wrap",
                        width: 60,
                      },
                    ]}
                  >
                    38415.00
                  </Text>
                </View>
              </View>

              {/* FOOTER */}
              <View style={styles.sideBySideContainer}>
                <View
                  style={[
                    {
                      borderBottomWidth: 0,
                      width: "70%",
                      textAlign: "left",
                    },
                  ]}
                >
                  <View style={{ padding: 2 }}>
                    <Text style={[styles.regularFontSize]}>
                      TOTAL VALUE US $ (IN WORDS) :-
                    </Text>
                    <View>
                      <Text style={[styles.regularFontSize, styles.boldFont]}>
                        THIRTY EIGHT THOUSAND FOUR HUNDRED FIFTEEN.
                      </Text>
                    </View>
                    <View style={{ paddingTop: 5 }}>
                      <View>
                        <Text style={[styles.regularFontSize, styles.boldFont]}>
                          Declaration:-
                        </Text>
                      </View>
                      <View>
                        <Text style={[styles.regularFontSize]}>
                          1) Export under Drawback Scheme & We intend to claim
                          RoDTEP as applicable. {"\n"} 2) We declare that this
                          Invoice shows the actual price of the goods described
                          and that all particulars are true and correct.
                        </Text>
                      </View>
                    </View>
                  </View>
                </View>
                <View
                  style={[
                    {
                      width: "30%",
                      display: "flex",
                      alignItems: "flex-end",
                    },
                  ]}
                >
                  <View style={styles.sideBySideContainer}>
                    <Text
                      style={[
                        styles.regularFontSize,
                        { width: 120, textAlign: "center", paddingTop: 2 },
                      ]}
                    >
                      COMMISSION (USD)
                    </Text>
                    <Text
                      style={[
                        styles.regularFontSize,
                        {
                          width: 60,
                          textAlign: "center",
                          paddingTop: 3,
                          fontWeight: "medium",
                        },
                      ]}
                    >
                      2330.00
                    </Text>
                  </View>
                  <Image
                    src="/img/logo/ee-main.png"
                    style={{ height: "60px", width: "60px" }}
                  />
                </View>
              </View>
            </View>
          </View>
        </Page>
      </Document>
    </PDFViewer>
  );
};

export default ExportInvoiceCumPackingList;
