import { useEffect, useState } from "react";
import PDFInvoice from "./invoice";
import { pdf } from "@react-pdf/renderer";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import * as pdfjs from "pdfjs-dist";
import { useLocation } from "react-router-dom";
import Spinner from "../../theme/Spinner";
pdfjs.GlobalWorkerOptions.workerSrc = "/vendor/js/pdf.worker.mjs";

const GenerateInvoice: React.FC = () => {
  const location = useLocation();
  const purchaseData = location.state;
  const [preview, setPreview] = useState<any>(null);

  useEffect(() => {
    const generatePdfPreview = async (data: any) => {
      try {
        const pdfDoc = await pdf(<PDFInvoice data={data} />).toBlob();
        const pdfUrl = URL.createObjectURL(pdfDoc);

        const loadingTask = pdfjs.getDocument(pdfUrl);
        const pdfDocument = await loadingTask.promise;

        const previews: string[] = []; 
        const scale = 1.5;
        for (let pageNum = 1; pageNum <= pdfDocument.numPages; pageNum++) {
          const page = await pdfDocument.getPage(pageNum);
          const viewport = page.getViewport({ scale });

          const canvas = document.createElement("canvas");
          const context = canvas.getContext("2d");

          if (context) {
            canvas.width = viewport.width;
            canvas.height = viewport.height;

            await page.render({
              canvasContext: context,
              viewport: viewport,
            }).promise;

            const imageSrc = canvas.toDataURL("image/png");
            previews.push(imageSrc); 
          } else {
            console.error("Canvas context is not available for page", pageNum);
          }
        }
        setPreview(previews);
      } catch (err) {
        console.log(err);
      }
    };
    if (purchaseData) {
      generatePdfPreview(purchaseData);
    }
  }, [purchaseData]);

  const generatePDF = (data: any) => {
    const pdfDoc = pdf(<PDFInvoice data={data} />);
    pdfDoc.toBlob().then((blob) => {
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = `invoice_${data.id}.pdf`;
      link.click();
    });
  };
  return (
    <>
      <div className="card">
        <div className="card-header pb-0 d-flex justify-content-end">
          {preview && (
            <button
              className="btn btn-primary waves-effect waves-light"
              type="button"
              onClick={() => generatePDF(purchaseData)}
              style={{ width: 150, alignSelf: "flex-end" }}
            >
              <span>
                <i className="ti ti-download me-sm-1"></i>{" "}
                <span className="d-none d-sm-inline-block">Download</span>
              </span>
            </button>
          )}
        </div>
        <div className="card-body">
          {preview ? (
            <div className="d-flex flex-wrap m-2">
              {preview.map((imageSrc: string, index: number) => (
                <img
                  key={index}
                  src={imageSrc}
                  alt={`Page ${index + 1}`}
                  style={{
                    width: "100%",
                    maxWidth: "1000px",
                    marginBottom: "10px",
                  }}
                />
              ))}
            </div>
          ) : (
            <Spinner isFullScreen={true} />
          )}
        </div>
      </div>
    </>
  );
};
export default GenerateInvoice;
