import { GET, POST, PUT } from "./_config";

export const userListApi = async (query: string) =>
  await GET(`/user?${query || ""}`, true);

export const getUserNameListApi = async () => await GET(`/user/list`, true);

export const createUserApi = async (body: any) =>
  await POST(`/user`, body, true);

export const getUserByIdApi = async (userId: string) =>
  await GET(`/user/id/${userId}`, true);

export const updateUserApi = async (userId: string, body: any) =>
  await PUT(`/user?id=${userId}`, body, true);

export const updateUserStatusApi = async (body: any) =>
  await POST(`/user/update-status`, body, true);

export const updatePasswordApi = async (body: any) =>
  await POST(`/user/update-password`, body, true);

export const changePasswordApi = async (userId: string, body: any) =>
  await POST(`/user/change-password/${userId}`, body, true);

const UserService = {
  userListApi,
  getUserNameListApi,
  createUserApi,
  getUserByIdApi,
  updateUserApi,
  updateUserStatusApi,
  updatePasswordApi,
  changePasswordApi,
};

export default UserService;
