/* eslint-disable react-hooks/exhaustive-deps */
import moment from "moment";
import { useEffect, useState } from "react";
import { getDocumentByIdApi } from "../../services/_document";

interface Props {
  documentId: string;
}

const ViewDocument: React.FC<Props> = ({ documentId }) => {
  const [document, setDocument] = useState<any>({});

  useEffect(() => {
    if (documentId) {
      setDocument({});
      getDocumentDetails();
    }
  }, [documentId]);

  const getDocumentDetails = async () => {
    const result: any = await getDocumentByIdApi(documentId as string);
    if (result.isSuccess) {
      setDocument(result?.data);
    }
  };

  return (
    <div
      className="modal fade"
      id="viewDocument"
      tabIndex={-1}
      aria-hidden="true"
    >
      <div className="modal-dialog modal-simple modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-body pt-0 pb-0">
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
            <div className="text-center">
              <h4 className="mb-0">Document Details</h4>
            </div>
            <div className="info-container">
              <div className="pb-4 border-bottom text-capitalize mb-4"></div>
              <ul className="list-unstyled mb-6">
                <li className="mb-2">
                  <span className="h6 me-1">Name:</span>
                  <span>{document?.name}</span>
                </li>
                <li className="mb-2">
                  <span className="h6 me-1">Status:</span>
                  <span
                    className={`badge ${
                        document?.status ? "bg-label-success" : "bg-label-danger"
                    }`}
                  >
                    {document?.status ? "Active" : "Inactive"}
                  </span>
                </li>
                <li className="mb-2">
                  <span className="h6 me-1">Created At:</span>
                  <span>
                    {document?.created_at
                      ? moment(document?.created_at).format(
                          "YYYY-MM-DD HH:mm:ss"
                        )
                      : ""}
                  </span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewDocument;
