import { Link } from "react-router-dom";

const NotFound = () => {
  return (
    <div className="container-xxl container-p-y">
      <div className="misc-wrapper">
        <h1
          className="mb-2 mx-2"
          style={{ lineHeight: "6rem", fontSize: "6rem" }}
        >
          404
        </h1>
        <h4 className="mb-2 mx-2">Page Not Found️ ⚠️</h4>
        <p className="mb-6 mx-2">
          we couldn't find the page you are looking for
        </p>
        <Link to={"/dashboard"} className="btn btn-primary mb-10">
          Back to home
        </Link>
      </div>
    </div>
  );
};

export default NotFound;
