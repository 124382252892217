import {
  formatDate,
  formatString,
  formatAmount,
  getFileNameFromUrl,
} from "../../../components/Helper";
import { pdf } from "@react-pdf/renderer";
import { useEffect, useState } from "react";
import Spinner from "../../../theme/Spinner";
import { CURRENCY } from "../../../components/Constants";
import SalesContractInvoice from "./SalesContractInvoice";
import { getBuyerByIdApi } from "../../../services/_buyer";
import { getSettingsApi } from "../../../services/_settings";
import { Link, useNavigate, useParams } from "react-router-dom";
import { getSalesContractDetailsByIdApi } from "../../../services/_sales";

const ViewSalesContract: React.FC = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [salesContractData, setSalesContractData] = useState<any>({});
  const [buyerData, setBuyerData] = useState({});
  const [loading, setLoading] = useState<boolean>(false);
  const [pdfLoading, setPdfLoading] = useState<boolean>(false);
  const [previewInvoiceLoading, setPreviewInvoiceLoading] =
    useState<boolean>(false);
  const [settingData, setSettingData] = useState<any>({});
  const [isReadyDownload, setIsReadyDownload] = useState(false);

  useEffect(() => {
    if (!id) {
      navigate(`sales-contract-list`);
    }
  });

  useEffect(() => {
    const fetchInitialData = async () => {
      setLoading(true);
      const result: any = await getSalesContractDetailsByIdApi(id as string);
      if (result.isSuccess) {
        setSalesContractData(result.data);
        setTimeout(async () => {
          const buyerDetail: any = await getBuyerByIdApi(result.data.buyer_id);
          setBuyerData(buyerDetail?.data);
          const shipperDetails: any = await getSettingsApi();
          if (shipperDetails.isSuccess) {
            const data = Object.entries(shipperDetails.data).reduce(
              (acc: any, setting: any) => {
                acc[setting[0]] = setting[1];
                return acc;
              },
              {}
            );
            setSettingData(data);
          }
          setIsReadyDownload(true);
        }, 500);
      }
      setLoading(false);
    };
    fetchInitialData();
  }, [id]);

  const generatePdfPreview = async () => {
    setPreviewInvoiceLoading(true);
    try {
      if (isReadyDownload) {
        const pdfDoc = await pdf(
          <SalesContractInvoice
            settingData={settingData}
            buyerData={buyerData}
            salesContractData={salesContractData}
          />
        ).toBlob();
        const pdfUrl = URL.createObjectURL(pdfDoc);
        window.open(pdfUrl, "_blank");
      }
      setPreviewInvoiceLoading(false);
    } catch (err) {
      console.error("Error generating PDF:", err);
    }
  };

  const generatePDF = () => {
    setPdfLoading(true);
    const pdfDoc = pdf(
      <SalesContractInvoice
        settingData={settingData}
        buyerData={buyerData}
        salesContractData={salesContractData}
      />
    );
    setPdfLoading(false);
    pdfDoc.toBlob().then((blob) => {
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = `${salesContractData.contract_no}-sales-contract.pdf`;
      link.click();
    });
  };

  return (
    <>
      <div className="card">
        <div className="card-header pb-0 d-flex justify-content-between">
          <div>
            <h5>Sales Contract Details :</h5>
          </div>
          <div className="d-flex" style={{ gap: "10px" }}>
            <button
              className="btn btn-primary waves-effect waves-light"
              type="button"
              onClick={() => generatePdfPreview()}
              disabled={previewInvoiceLoading}
            >
              <span>
                <i className="ti ti-file me-sm-1"></i>{" "}
                <span className="d-none d-sm-inline-block">
                  {previewInvoiceLoading && (
                    <span
                      className="spinner-border spinner-border-sm me-2"
                      role="status"
                      aria-hidden="true"
                    ></span>
                  )}
                  Preview Invoice
                </span>
              </span>
            </button>
            <button
              className="btn btn-primary waves-effect waves-light"
              type="button"
              onClick={() => generatePDF()}
              disabled={pdfLoading}
            >
              <span>
                <i className="ti ti-download me-sm-1"></i>{" "}
                <span className="d-none d-sm-inline-block">
                  {pdfLoading && (
                    <span
                      className="spinner-border spinner-border-sm me-2"
                      role="status"
                      aria-hidden="true"
                    ></span>
                  )}
                  Download
                </span>
              </span>
            </button>
            <Link
              to={`/sales-contract-list`}
              type="button"
              className="btn btn-label-github"
            >
              Back
            </Link>
          </div>
        </div>
        <div className="card-body">
          {loading && <Spinner isFullScreen={false} />}
          <div
            className="col-12 col-sm-6 col-md-4 col-lg-3 row m-1 p-2 d-flex info-container text-capitalize"
            style={{
              border: "1px solid #d1d0d4",
              borderRadius: "0.375rem",
              width: "100%",
            }}
          >
            <div className="col-12 col-md-6 mb-3">
              <span className="h6 me-1">Contract No :</span>
              {salesContractData?.contract_no || "N/A"}
            </div>
            <div className="col-12 col-md-6 mb-3">
              <span className="h6 me-1">Reference No :</span>
              {salesContractData?.reference_no || "N/A"}
            </div>
            <div className="col-12 col-md-6 mb-3">
              <span className="h6 me-1">Buyer :</span>
              {salesContractData?.buyer?.name || "N/A"}
            </div>
            <div className="col-12 col-md-6 mb-3">
              <span className="h6 me-1">Product :</span>
              {salesContractData?.product?.name || "N/A"}
            </div>
            <div className="col-12 col-md-6 mb-3">
              <span className="h6 me-1">price :</span>
              {CURRENCY.USD.SYMBOL}
              {formatAmount(salesContractData.price)}
            </div>
            <div className="col-12 col-md-6 mb-3">
              <span className="h6 me-1">Quantity :</span>
              {salesContractData?.quantity || "N/A"}
            </div>
            <div className="col-12 col-md-6 mb-3">
              <span className="h6 me-1">Total Amount :</span>
              {CURRENCY.USD.SYMBOL}
              {formatAmount(salesContractData.total_amount)}
            </div>
            <div className="col-12 col-md-6 mb-3">
              <span className="h6 me-1">Quantity Unit :</span>
              {salesContractData?.quantity_unit?.name || "N/A"}
            </div>
            <div className="col-12 col-md-6 mb-3">
              <span className="h6 me-1">Container Size :</span>
              {salesContractData?.container_load || "N/A"}
            </div>
            <div className="col-12 col-md-6 mb-3">
              <span className="h6 me-1">Packing Type :</span>
              {salesContractData?.packing_type?.name || "N/A"}
            </div>
            <div className="col-12 col-md-6 mb-3">
              <span className="h6 me-1">Packing Net Weight :</span>
              {salesContractData?.packing_net_weight || "N/A"}
            </div>
            <div className="col-12 col-md-6 mb-3">
              <span className="h6 me-1">Packing Gross Weight :</span>
              {salesContractData?.packing_gross_weight || "N/A"}
            </div>
            <div className="col-12 col-md-6 mb-3">
              <span className="h6 me-1">Packing Unit :</span>
              {salesContractData?.packing_unit?.name || "N/A"}
            </div>
            <div className="col-12 col-md-6 mb-3">
              <span className="h6 me-1">Marking :</span>
              {salesContractData?.marking || "N/A"}
            </div>
            <div className="col-12 col-md-6 mb-3">
              <span className="h6 me-1">Final Destination :</span>
              {salesContractData?.final_destination || "N/A"}
            </div>
            <div className="col-12 col-md-6 mb-3">
              <span className="h6 me-1">Port of Loading :</span>
              {salesContractData?.port_of_loading || "N/A"}
            </div>
            <div className="col-12 col-md-6 mb-3">
              <span className="h6 me-1">Port of Discharge :</span>
              {salesContractData?.port_of_discharge || "N/A"}
            </div>
            <div className="col-12 col-md-6 mb-3">
              <span className="h6 me-1">Shipment :</span>
              {salesContractData?.shipment || "N/A"}
            </div>
            <div className="col-12 col-md-6 mb-3">
              <span className="h6 me-1">Trans Shipment :</span>
              {salesContractData?.trans_shipment || "N/A"}
            </div>
            <div className="col-12 col-md-6 mb-3">
              <span className="h6 me-1">Partial Shipment :</span>
              {salesContractData?.partial_shipment || "N/A"}
            </div>
            <div className="col-12 col-md-6 mb-3">
              <span className="h6 me-1">Payment Terms :</span>
              {salesContractData?.payment_terms || "N/A"}
            </div>
            {/* <div className="col-12 col-md-6 mb-3">
              <span className="h6 me-1">Allow Digital Signature :</span>
              {salesContractData?.is_digital_signature ? "Yes" : "No"}
            </div> */}
            <div className="col-12 col-md-6 mb-3">
              <span className="h6 me-1">status :</span>
              {formatString(salesContractData.status)}
            </div>
            <div className="col-12 col-md-6 mb-3">
              <span className="h6 me-1">Contract Date:</span>
              {formatDate(salesContractData.contract_date)}
            </div>
            <div className="col-12 mb-3">
              <span className="h6 me-1">Weight & Quality :</span>
              {salesContractData?.weight_and_quality || "N/A"}
            </div>
            <div className="col-12 mb-3">
              <span className="h6 me-1">Arbitration :</span>
              {salesContractData?.arbitration || "N/A"}
            </div>
            {salesContractData?.marking_files?.length > 0 && (
              <div className="col-12">
                <div
                  className="d-flex"
                  style={{
                    height: "100%",
                    alignItems: "center",
                  }}
                >
                  <label className="h6 me-1">Marking Files :</label>
                  {salesContractData?.marking_files?.map((e: any) => {
                    const imageUrl = `${process.env.REACT_APP_BACKEND_URL}${e}`;
                    return (
                      <div className="row p-0">
                        <div
                          className="col-md mb-md-0"
                          style={{
                            width: "170px",
                            height: "100%",
                            textWrap: "wrap",
                          }}
                        >
                          <div className="form-check custom-option custom-option-icon">
                            <label
                              className="form-check-label custom-option-content"
                              onClick={() => window.open(imageUrl, "_blank")}
                            >
                              <span className="custom-option-body">
                                <small>{getFileNameFromUrl(imageUrl)}</small>
                              </span>
                            </label>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            )}
          </div>

          {salesContractData?.quality_specifications?.length > 0 && (
            <>
              <div className="mt-7 py-2 pb-0 d-flex justify-content-start align-items-center">
                <h5>Quality Specifications Details :</h5>
              </div>
              <div className="card-datatable table-responsive">
                <table className="dt-advanced-search table table-nowrap">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Quality Specifications</th>
                      <th>value</th>
                    </tr>
                  </thead>
                  <tbody>
                    {salesContractData ? (
                      salesContractData?.quality_specifications?.map(
                        (row: any, index: number) => {
                          return (
                            <tr key={index}>
                              <td>{index + 1}</td>
                              <td>{row.quality_specification?.name}</td>
                              <td>{row.value}</td>
                            </tr>
                          );
                        }
                      )
                    ) : (
                      <></>
                    )}
                  </tbody>
                </table>
              </div>
            </>
          )}

          {salesContractData?.documents?.length > 0 && (
            <>
              <div className="mt-7 py-2 pb-0 d-flex justify-content-start align-items-center">
                <h5>Documents Details :</h5>
              </div>
              <div className="card-datatable table-responsive">
                <table className="dt-advanced-search table table-nowrap">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Document</th>
                      <th>value</th>
                    </tr>
                  </thead>
                  <tbody>
                    {salesContractData ? (
                      salesContractData?.documents?.map(
                        (row: any, index: number) => {
                          return (
                            <tr key={index}>
                              <td>{index + 1}</td>
                              <td>{row.document?.name}</td>
                              <td>{row.value}</td>
                            </tr>
                          );
                        }
                      )
                    ) : (
                      <></>
                    )}
                  </tbody>
                </table>
              </div>
            </>
          )}

          {(salesContractData?.exporter_bank ||
            salesContractData?.buyer_bank) && (
            <>
              <div className="mt-7 py-2 pb-0 d-flex justify-content-start align-items-center">
                <h5>Bank Details :</h5>
              </div>
              <div
                className="col-12 col-sm-6 col-md-4 col-lg-3 row m-1 mt-0 p-2 d-flex info-container text-capitalize"
                style={{
                  border: "1px solid #d1d0d4",
                  borderRadius: "0.375rem",
                  width: "100%",
                }}
              >
                {salesContractData?.exporter_bank && (
                  <div className="col-12 col-md-6 mb-2">
                    <h5>Exporter Bank Details :-</h5>
                    <div className="mb-2">
                      <span className="h6">Bank Name : </span>
                      {salesContractData?.exporter_bank?.bank_name || "N/A"}
                    </div>
                    <div className="mb-2">
                      <span className="h6">Account No : </span>
                      {salesContractData?.exporter_bank?.account_number ||
                        "N/A"}
                    </div>
                    <div className="mb-2">
                      <span className="h6">IFSC Code : </span>
                      {salesContractData?.exporter_bank?.ifsc_code || "N/A"}
                    </div>
                    <div className="mb-2">
                      <span className="h6">Branch Name : </span>
                      {salesContractData?.exporter_bank?.branch_name || "N/A"}
                    </div>
                    <div className="mb-2">
                      <span className="h6">SWIFT Code : </span>
                      {salesContractData?.exporter_bank?.swift_code || "N/A"}
                    </div>
                    <div className="mb-2">
                      <span className="h6">AD Code : </span>
                      {salesContractData?.exporter_bank?.ad_code || "N/A"}
                    </div>
                    <div className="mb-2">
                      <span className="h6">Address : </span>
                      {salesContractData?.exporter_bank?.address || "N/A"}
                    </div>
                  </div>
                )}

                {salesContractData?.buyer_bank && (
                  <div className="col-12 col-md-6 mb-2">
                    <h5>Buyer Bank Details :-</h5>
                    <div className="mb-2">
                      <span className="h6">Bank Name : </span>
                      {salesContractData?.buyer_bank?.bank_name || "N/A"}
                    </div>
                    <div className="mb-2">
                      <span className="h6">Account No : </span>
                      {salesContractData?.buyer_bank?.account_number || "N/A"}
                    </div>
                    <div className="mb-2">
                      <span className="h6">IFSC Code : </span>
                      {salesContractData?.buyer_bank?.ifsc_code || "N/A"}
                    </div>
                    <div className="mb-2">
                      <span className="h6">Branch Name : </span>
                      {salesContractData?.buyer_bank?.branch_name || "N/A"}
                    </div>
                    <div className="mb-2">
                      <span className="h6">SWIFT Code : </span>
                      {salesContractData?.buyer_bank?.swift_code || "N/A"}
                    </div>
                    <div className="mb-2">
                      <span className="h6">AD Code : </span>
                      {salesContractData?.buyer_bank?.ad_code || "N/A"}
                    </div>
                    <div className="mb-2">
                      <span className="h6">Address : </span>
                      {salesContractData?.buyer_bank?.address || "N/A"}
                    </div>
                  </div>
                )}
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default ViewSalesContract;
