/* eslint-disable react-hooks/exhaustive-deps */
import * as Yup from "yup";
import { useEffect, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { Link, useNavigate, useParams } from "react-router-dom";
import { errorToast, successToast } from "../../components/Toast";
import { getRoleDetailsByIdApi, updateRole } from "../../services/_role";

export const EditRole = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [role, setRole] = useState<any>({});
  const [allPermissions, setAllPermissions] = useState<any[]>([]);
  const [rolePermissions, setRolePermissions] = useState<any[]>([]);
  const [updateLoading, setUpdateLoading] = useState<boolean>(false);

  useEffect(() => {
    if (!id) {
      navigate("/role-list");
    } else {
      getRoleDetails();
    }
  }, []);

  const getRoleDetails = async () => {
    const result: any = await getRoleDetailsByIdApi(id as string);
    if (result.isSuccess) {
      setRole(result.data.role);
      setAllPermissions(result.data.permissions);
      setRolePermissions(result.data.role.permissions);
    }
  };

  const createRoleInitialValues = {
    role_name: role.name || "",
    status: role?.status?.toString() || "",
    permissions: rolePermissions || [],
  };

  const createRoleValidationSchema = Yup.object().shape({
    role_name: Yup.string().required("Name is required"),
    status: Yup.string().required("Status is required"),
    permissions: Yup.array()
      .min(1, "Please select at least one permission")
      .required("Permissions are required"),
  });

  const handleSubmit = async (values: any) => {
    setUpdateLoading(true);
    values.status = values.status === "true" ? true : false;
    const result: any = await updateRole(id as string, values);
    if (result.isSuccess) {
      setUpdateLoading(false);
      successToast(result.message);
      navigate("/role-list");
    } else {
      setUpdateLoading(false);
      errorToast(result.message);
    }
    return;
  };

  return (
    <>
      <div className="card">
        <div className="card-header pb-0">
          <div>
            <h5>Update Role</h5>
          </div>
        </div>
        <div className="card-body">
          <Formik
            initialValues={createRoleInitialValues}
            validationSchema={createRoleValidationSchema}
            onSubmit={handleSubmit}
            enableReinitialize={true}
          >
            {({ handleChange, values, setFieldValue }) => (
              <Form className="dt_adv_search">
                <div className="row">
                  <div className="col-12">
                    <div className="row g-3">
                      <div className="col-12 col-sm-6 col-lg-4">
                        <label
                          htmlFor="role_name"
                          className="form-label"
                          style={{ fontSize: "0.9375rem" }}
                        >
                          Name: <span className="text-danger">*</span>
                        </label>
                        <Field
                          id="role_name"
                          name="role_name"
                          type="text"
                          className="form-control"
                          placeholder="Enter name"
                          onChange={handleChange}
                        />
                        <ErrorMessage
                          name="role_name"
                          className="text-danger"
                          component="div"
                        />
                      </div>

                      <div className="col-12 col-sm-6 col-lg-4">
                        <label
                          htmlFor="status"
                          className="form-label"
                          style={{ fontSize: "0.9375rem" }}
                        >
                          Status: <span className="text-danger">*</span>
                        </label>
                        <select
                          id="status"
                          name="status"
                          className="form-select"
                          onChange={handleChange}
                          value={values?.status}
                        >
                          <option value="">Select Status</option>
                          <option value="true">Active</option>
                          <option value="false">Inactive</option>
                        </select>
                        <ErrorMessage
                          name="status"
                          className="text-danger"
                          component="div"
                        />
                      </div>
                      <div className="col-12 col-sm-12 col-lg-12">
                        <label
                          htmlFor="permissions"
                          className="form-label"
                          style={{
                            fontSize: "0.9375rem",
                            marginBottom: "10px",
                          }}
                        >
                          Permissions: <span className="text-danger">*</span>
                        </label>
                        <div
                          role="group"
                          aria-labelledby="checkbox-group"
                          className="row m-1 p-2"
                          style={{
                            border: "1px solid #d1d0d4",
                            borderRadius: "0.375rem",
                          }}
                        >
                          {allPermissions.map((permission) => (
                            <div
                              key={permission.id}
                              className="col-12 col-sm-6 col-md-4 col-lg-3"
                            >
                              <div className="form-check mb-3">
                                <Field
                                  type="checkbox"
                                  name="permissions"
                                  value={permission.id.toString()}
                                  className="form-check-input"
                                  id={`permission-${permission.id}`}
                                  checked={values.permissions.includes(
                                    permission.id
                                  )}
                                  onChange={() => {
                                    const newPermissions =
                                      values.permissions.includes(permission.id)
                                        ? values.permissions.filter(
                                            (p: any) => p !== permission.id
                                          )
                                        : [
                                            ...values.permissions,
                                            permission.id,
                                          ];
                                    setFieldValue(
                                      "permissions",
                                      newPermissions
                                    );
                                  }}
                                />
                                <label
                                  htmlFor={`permission-${permission.id}`}
                                  className="form-check-label"
                                >
                                  {permission.name}
                                </label>
                              </div>
                            </div>
                          ))}
                        </div>
                        <ErrorMessage
                          name="permissions"
                          className="text-danger"
                          component="div"
                        />
                      </div>
                      <div className="col-12">
                        <button
                          type="submit"
                          className="btn btn-primary"
                          disabled={updateLoading}
                        >
                          {updateLoading && (
                            <span
                              className="spinner-border spinner-border-sm me-2"
                              role="status"
                              aria-hidden="true"
                            ></span>
                          )}
                          Submit
                        </button>
                        <Link
                          to={"/role-list"}
                          type="button"
                          className="btn btn-label-github ms-5"
                        >
                          Back
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </>
  );
};

export default EditRole;
