import { GET, POST, PUT } from "./_config";

export const unitListApi = async (query: string) =>
  await GET(`/unit?${query || ""}`, true);

export const getAllUnitsApi = async () => await GET(`/unit/list`, true);

export const getUnitByIdApi = async (portId: string) =>
  await GET(`/unit/id/${portId}`, true);

export const createUnitApi = async (body: any) =>
  await POST(`/unit`, body, true);

export const updateUnitApi = async (unitId: string, body: any) =>
  await PUT(`/unit?id=${unitId}`, body, true);

export const updateUnitStatusApi = async (body: any) =>
  await POST(`/unit/update-status`, body, true);

const UnitService = {
  unitListApi,
  getAllUnitsApi,
  getUnitByIdApi,
  createUnitApi,
  updateUnitApi,
  updateUnitStatusApi,
};

export default UnitService;
