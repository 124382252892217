import {
  Document,
  Page,
  PDFViewer,
  StyleSheet,
  Text,
  View,
} from "@react-pdf/renderer";

export const styles = StyleSheet.create({
  boldText: {
    fontWeight: "bold",
    fontSize: 8,
  },
  smallText: {
    fontSize: 6,
  },
  mediumText: {
    fontSize: 6.5,
  },

  page: {
    paddingHorizontal: 30,
    paddingVertical: 15,
    fontFamily: "HostGrotesk",
    backgroundColor: "white",
  },
  fullpage: {
    margin: 7,
    borderStyle: "solid",
    borderWidth: 1,
    borderColor: "#000000",
  },

  sideBySideContainer: { flexDirection: "row" },

  tableContainer: {
    borderBottomWidth: 1,
    borderBottomColor: "black",
  },
  tableRow: {
    flexDirection: "row",
    flexGrow: 1,
    alignItems: "stretch",
  },
  tableCell: {
    padding: 5,
    fontSize: 7,
    textAlign: "center",
    borderRightWidth: 1,
    borderRightColor: "black",
    borderRightStyle: "solid",
  },
});

const AnnexureInvoice = () => {
  return (
    <PDFViewer width="100%" height="600px">
      <Document>
        <Page style={[styles.page, { paddingTop: 25 }]} size="A4">
          <View style={[styles.fullpage]}>
            {/* heading */}
            <View>
              <Text
                style={[
                  {
                    textAlign: "center",
                    fontWeight: "bold",
                    fontSize: 14,
                    paddingBottom: 10,
                    borderBottom: 1,
                    paddingTop: 10,
                  },
                ]}
              >
                EXPORT GOODS UNDER SELF SEALING
              </Text>
            </View>
            {/* shipping  bill no */}
            <View
              style={[styles.sideBySideContainer, { flexDirection: "row" }]}
            >
              <View style={[{ flex: 1 }]}>
                <Text
                  style={[
                    styles.boldText,
                    {
                      fontWeight: "bold",
                      fontSize: 8,
                      textAlign: "left",
                      paddingBottom: 10,
                      borderBottom: 0,
                      padding: 2,
                    },
                  ]}
                >
                  C. NO.
                </Text>
              </View>
              <View style={[{ flex: 1 }]}>
                <Text
                  style={[
                    {
                      fontWeight: "bold",
                      fontSize: 8,
                      paddingBottom: 10,
                      borderBottom: 0,
                      paddingLeft: -10,
                    },
                  ]}
                >
                  SHIPPING BILL NO.
                </Text>
              </View>
              <View style={[{ flex: 1 }]}>
                <Text
                  style={[
                    {
                      fontWeight: "bold",
                      fontSize: 8,
                      paddingBottom: 10,
                      borderBottom: 0,
                      paddingLeft: 50,
                    },
                  ]}
                >
                  DATE:-
                </Text>
              </View>
            </View>

            <View
              style={[
                styles.sideBySideContainer,
                { justifyContent: "center", borderBottom: 1 },
              ]}
            >
              <Text
                style={[
                  {
                    fontWeight: "bold",
                    alignItems: "center",
                    fontSize: 8,
                    paddingTop: 0,
                  },
                ]}
              >
                EXAMINATION REPOPRT FOR SELF SEALED PACKAGES / CONTAINERS
              </Text>
            </View>

            {/* table */}
            <View style={[styles.sideBySideContainer]}>
              <View
                style={{
                  flexDirection: "column",
                  paddingLeft: 0,
                  marginTop: 5,
                  fontWeight: "bold",
                  fontSize: 7,
                }}
              >
                {/* 1 */}
                <View
                  style={{
                    flexDirection: "row",
                  }}
                >
                  <Text
                    style={{
                      fontSize: 8,
                      width: "5%",
                      justifyContent: "flex-start",
                      paddingLeft: 20,
                    }}
                  >
                    1
                  </Text>
                  <Text
                    style={{
                      fontSize: 8,
                      width: "25%",
                      justifyContent: "flex-start",
                      paddingLeft: 15,
                    }}
                  >
                    NAME OF EXPORTER
                  </Text>
                  <Text
                    style={{
                      fontSize: 8,
                      width: "60%",
                      justifyContent: "flex-end",
                      paddingLeft: 200,
                    }}
                  >
                    MS LAXMI ENTERPRISE PLOT NO. 612, NAGOR ROAD, GIDC BHUJ,
                    KACHCHH, GUJARAT, 370001
                  </Text>
                </View>

                {/* 2 */}
                <View
                  style={{
                    flexDirection: "row",
                  }}
                >
                  <Text
                    style={{
                      fontSize: 8,
                      width: "5%",
                      justifyContent: "flex-start",
                      paddingLeft: 20,
                    }}
                  >
                    2
                  </Text>
                  <Text
                    style={{
                      fontSize: 8,
                      width: "25%",
                      justifyContent: "flex-start",
                      paddingLeft: 15,
                    }}
                  >
                    A) IEC NO.
                  </Text>
                  <Text
                    style={{
                      fontSize: 8,
                      width: "60%",
                      justifyContent: "flex-end",
                      paddingLeft: 200,
                    }}
                  >
                    3712001126
                  </Text>
                </View>
                <View
                  style={{
                    flexDirection: "row",
                  }}
                >
                  <Text
                    style={{
                      fontSize: 8,
                      width: "5%",
                      justifyContent: "flex-start",
                      paddingLeft: 20,
                    }}
                  ></Text>
                  <Text
                    style={{
                      fontSize: 8,
                      width: "25%",
                      justifyContent: "flex-start",
                      paddingLeft: 15,
                    }}
                  >
                    B) BRANCH CODE
                  </Text>
                  <Text
                    style={{
                      fontSize: 8,
                      width: "60%",
                      justifyContent: "flex-end",
                      paddingLeft: 200,
                    }}
                  >
                    0
                  </Text>
                </View>
                <View
                  style={{
                    flexDirection: "row",
                  }}
                >
                  <Text
                    style={{
                      fontSize: 8,
                      width: "5%",
                      justifyContent: "flex-start",
                      paddingLeft: 20,
                    }}
                  ></Text>
                  <Text
                    style={{
                      fontSize: 8,
                      width: "40%",
                      justifyContent: "flex-start",
                      paddingLeft: 15,
                    }}
                  >
                    C) BIN (PAN BASED BUISNESS INDENTIFICATION)
                  </Text>
                  <Text
                    style={{
                      fontSize: 8,
                      width: "60%",
                      justifyContent: "flex-end",
                      paddingLeft: 132,
                    }}
                  >
                    AALFM4290M
                  </Text>
                </View>
                {/* 3 */}
                <View
                  style={{
                    flexDirection: "row",
                  }}
                >
                  <Text
                    style={{
                      fontSize: 8,
                      width: "5%",
                      justifyContent: "flex-start",
                      paddingLeft: 20,
                    }}
                  >
                    3
                  </Text>
                  <Text
                    style={{
                      fontSize: 8,
                      width: "25%",
                      justifyContent: "flex-start",
                      paddingLeft: 15,
                    }}
                  >
                    NAME OF THE MANUFACTURER (IF DIFFERENCE FROM THE EXPORTERS)
                  </Text>
                  <Text
                    style={{
                      fontSize: 8,
                      width: "60%",
                      justifyContent: "flex-end",
                      paddingLeft: 200,
                    }}
                  >
                    N.A.
                  </Text>
                </View>
                {/* 4 */}
                <View
                  style={{
                    flexDirection: "row",
                  }}
                >
                  <Text
                    style={{
                      fontSize: 8,
                      width: "5%",
                      justifyContent: "flex-start",
                      paddingLeft: 20,
                    }}
                  >
                    4
                  </Text>
                  <Text
                    style={{
                      fontSize: 8,
                      width: "25%",
                      justifyContent: "flex-start",
                      paddingLeft: 15,
                    }}
                  >
                    FACTORY / GODOWN
                  </Text>
                  <Text
                    style={{
                      fontSize: 8,
                      width: "60%",
                      justifyContent: "flex-end",
                      paddingLeft: 200,
                    }}
                  >
                    SAME AS EXPORTER DETAILS
                  </Text>
                </View>
                {/* 5 */}
                <View
                  style={{
                    flexDirection: "row",
                    marginTop: 10,
                  }}
                >
                  <Text
                    style={{
                      fontSize: 8,
                      width: "5%",
                      justifyContent: "flex-start",
                      paddingLeft: 20,
                    }}
                  >
                    5
                  </Text>
                  <Text
                    style={{
                      fontSize: 8,
                      width: "25%",
                      justifyContent: "flex-start",
                      paddingLeft: 15,
                    }}
                  >
                    DATE OF EXAMINATION
                  </Text>
                  <Text
                    style={{
                      fontSize: 8,
                      width: "60%",
                      justifyContent: "flex-end",
                      paddingLeft: 200,
                    }}
                  >
                    13.08.2024
                  </Text>
                </View>
                {/* 6 */}
                <View
                  style={{
                    flexDirection: "row",
                  }}
                >
                  <Text
                    style={{
                      fontSize: 8,
                      width: "5%",
                      justifyContent: "flex-start",
                      paddingLeft: 20,
                    }}
                  >
                    6
                  </Text>
                  <Text
                    style={{
                      fontSize: 8,
                      width: "35%",
                      justifyContent: "flex-start",
                      paddingLeft: 15,
                    }}
                  >
                    NAME AND DESIGNATION OF THE EXAMINING OFFICE INSPECTOR
                  </Text>
                  <Text
                    style={{
                      fontSize: 8,
                      width: "55%",
                      justifyContent: "flex-end",
                      paddingLeft: 148,
                    }}
                  >
                    SELF-SEAL & SELF-CERTIFICATION SELF EXAMINATION
                  </Text>
                </View>
                {/* 7 A*/}
                <View
                  style={{
                    flexDirection: "row",
                  }}
                >
                  <Text
                    style={{
                      fontSize: 8,
                      width: "5%",
                      justifyContent: "flex-start",
                      paddingLeft: 20,
                    }}
                  >
                    7
                  </Text>
                  <Text
                    style={{
                      fontSize: 8,
                      width: "35%",
                      justifyContent: "flex-start",
                      paddingLeft: 15,
                    }}
                  >
                    A)NAME OF COMMISSIONARATE / DIVISION OFFICER
                  </Text>
                  <Text
                    style={{
                      fontSize: 8,
                      width: "55%",
                      justifyContent: "flex-end",
                      paddingLeft: 148,
                    }}
                  >
                    COMM. GANDHIDHAM (KUTCH), DIV.- BHUJ
                  </Text>
                </View>
                {/* 7 B */}
                <View
                  style={{
                    flexDirection: "row",
                  }}
                >
                  <Text
                    style={{
                      fontSize: 8,
                      width: "5%",
                      justifyContent: "flex-start",
                      paddingLeft: 20,
                    }}
                  ></Text>
                  <Text
                    style={{
                      fontSize: 8,
                      width: "35%",
                      justifyContent: "flex-start",
                      paddingLeft: 15,
                    }}
                  >
                    B)LOCATION CODE & RANGE
                  </Text>
                  <Text
                    style={{
                      fontSize: 8,
                      width: "55%",
                      justifyContent: "flex-end",
                      paddingLeft: 148,
                    }}
                  >
                    RANGE- BHUJ
                  </Text>
                </View>
                {/* 8 */}
                <View
                  style={{
                    flexDirection: "row",
                    marginTop: 10,
                  }}
                >
                  <Text
                    style={{
                      fontSize: 8,
                      width: "5%",
                      justifyContent: "flex-start",
                      paddingLeft: 20,
                    }}
                  >
                    8
                  </Text>
                  <Text
                    style={{
                      fontSize: 8,
                      width: "35%",
                      justifyContent: "flex-start",
                      paddingLeft: 15,
                    }}
                  >
                    PARTICULARS OF EXPORT INVOICE
                  </Text>
                  <Text
                    style={{
                      fontSize: 8,
                      width: "55%",
                      justifyContent: "flex-end",
                      paddingLeft: 148,
                    }}
                  ></Text>
                </View>
                {/* 8A */}
                <View
                  style={{
                    flexDirection: "row",
                  }}
                >
                  <Text
                    style={{
                      fontSize: 8,
                      width: "5%",
                      justifyContent: "flex-start",
                      paddingLeft: 20,
                    }}
                  ></Text>
                  <Text
                    style={{
                      fontSize: 8,
                      width: "20%",
                      justifyContent: "flex-start",
                      paddingLeft: 15,
                    }}
                  >
                    A)EXPORT INVOICE NO.
                  </Text>
                  <Text
                    style={{
                      fontSize: 8,
                      width: "75%",
                      justifyContent: "flex-end",
                      paddingLeft: 225,
                    }}
                  >
                    LE/EXP/24-25/019 DATE: 07/08/2024
                  </Text>
                </View>
                {/* 8B */}
                <View
                  style={{
                    flexDirection: "row",
                  }}
                >
                  <Text
                    style={{
                      fontSize: 8,
                      width: "5%",
                      justifyContent: "flex-start",
                      paddingLeft: 20,
                    }}
                  ></Text>
                  <Text
                    style={{
                      fontSize: 8,
                      width: "25%",
                      justifyContent: "flex-start",
                      paddingLeft: 15,
                    }}
                  >
                    B)TOTAL NO. OF PACKAGES
                  </Text>
                  <Text
                    style={{
                      fontSize: 8,
                      width: "60%",
                      justifyContent: "flex-end",
                      paddingLeft: 200,
                    }}
                  >
                    2000 BAGS
                  </Text>
                </View>
                {/* 9A */}
                <View
                  style={{
                    flexDirection: "row",
                    marginTop: 10,
                  }}
                >
                  <Text
                    style={{
                      fontSize: 8,
                      width: "5%",
                      justifyContent: "flex-start",
                      paddingLeft: 20,
                    }}
                  >
                    9
                  </Text>
                  <Text
                    style={{
                      fontSize: 8,
                      width: "35%",
                      justifyContent: "flex-start",
                      paddingLeft: 15,
                    }}
                  >
                    A) IS THE DESCRIPTION OF THE GOODS, THE QUANTITY AND THEIR
                    VALUE AS PER PARTICULARS FURNISHED IN THE EXPORT INVOICE ?
                  </Text>
                  <Text
                    style={{
                      fontSize: 8,
                      width: "55%",
                      justifyContent: "flex-end",
                      paddingLeft: 148,
                    }}
                  >
                    YES
                  </Text>
                </View>
                {/* 9B */}
                <View
                  style={{
                    flexDirection: "row",
                  }}
                >
                  <Text
                    style={{
                      fontSize: 8,
                      width: "5%",
                      justifyContent: "flex-start",
                    }}
                  ></Text>
                  <Text
                    style={{
                      fontSize: 8,
                      width: "35%",
                      justifyContent: "flex-start",
                      paddingLeft: 15,
                    }}
                  >
                    B) WHETHER SAPMLE IS DRAWN BEING FORWARDED TO PORT OF EXPORT
                    ?
                  </Text>
                  <Text
                    style={{
                      fontSize: 8,
                      width: "55%",
                      justifyContent: "flex-end",
                      paddingLeft: 148,
                    }}
                  >
                    NO
                  </Text>
                </View>
                {/* 9C */}
                <View
                  style={{
                    flexDirection: "row",
                  }}
                >
                  <Text
                    style={{
                      fontSize: 8,
                      width: "5%",
                      justifyContent: "flex-start",
                      paddingLeft: 20,
                    }}
                  ></Text>
                  <Text
                    style={{
                      fontSize: 8,
                      width: "35%",
                      justifyContent: "flex-start",
                      paddingLeft: 15,
                    }}
                  >
                    C) IF YES, THE NUMBER OF THE SEAL OF THE PACKAGE CONTAINING
                    THE SAMPLE.
                  </Text>
                  <Text
                    style={{
                      fontSize: 8,
                      width: "55%",
                      justifyContent: "flex-end",
                      paddingLeft: 148,
                    }}
                  ></Text>
                </View>
                {/* 10 */}
                <View
                  style={{
                    flexDirection: "row",
                    marginTop: 10,
                  }}
                >
                  <Text
                    style={{
                      fontSize: 8,
                      width: "5%",
                      justifyContent: "flex-start",
                      paddingLeft: 20,
                    }}
                  >
                    10
                  </Text>
                  <Text
                    style={{
                      fontSize: 8,
                      width: "35%",
                      justifyContent: "flex-start",
                      paddingLeft: 15,
                    }}
                  >
                    CENTRAL EXCISE SEAL NOS./PACKAGES ETC
                  </Text>
                  <Text
                    style={{
                      fontSize: 8,
                      width: "55%",
                      justifyContent: "flex-end",
                      paddingLeft: 148,
                    }}
                  ></Text>
                </View>
                {/* 10a */}
                <View
                  style={{
                    flexDirection: "row",
                  }}
                >
                  <Text
                    style={{
                      fontSize: 8,
                      width: "5%",
                      justifyContent: "flex-start",
                      paddingLeft: 20,
                    }}
                  >
                    A
                  </Text>
                  <Text
                    style={{
                      fontSize: 8,
                      width: "35%",
                      justifyContent: "flex-start",
                      paddingLeft: 15,
                    }}
                  >
                    FOR NON- CONTAINERSED CARGO
                  </Text>
                  <Text
                    style={{
                      fontSize: 8,
                      width: "55%",
                      justifyContent: "flex-end",
                      paddingLeft: 148,
                    }}
                  >
                    N.A.
                  </Text>
                </View>
                {/* 10b */}
                <View
                  style={{
                    flexDirection: "row",
                  }}
                >
                  <Text
                    style={{
                      fontSize: 8,
                      width: "5%",
                      justifyContent: "flex-start",
                      paddingLeft: 20,
                    }}
                  >
                    B
                  </Text>
                  <Text
                    style={{
                      fontSize: 8,
                      width: "35%",
                      justifyContent: "flex-start",
                      paddingLeft: 15,
                    }}
                  >
                    B) FOR - CONTAINERSED CARGO
                  </Text>
                  <Text
                    style={{
                      fontSize: 8,
                      width: "55%",
                      justifyContent: "flex-end",
                      paddingLeft: 148,
                    }}
                  >
                    DETAILS AS UNDER
                  </Text>
                </View>
                {/* 10c */}
                <View
                  style={{
                    flexDirection: "row",
                  }}
                >
                  <Text
                    style={{
                      fontSize: 8,
                      width: "5%",
                      justifyContent: "flex-start",
                      paddingLeft: 20,
                    }}
                  >
                    C
                  </Text>
                  <Text
                    style={{
                      fontSize: 8,
                      width: "35%",
                      justifyContent: "flex-start",
                      paddingLeft: 15,
                    }}
                  >
                    SELF SELING PERMMISSION NO:
                  </Text>
                </View>
                <View
                  style={[
                    styles.tableContainer,
                    { height: "4%", paddingLeft: 20, borderBottom: 0 },
                  ]}
                >
                  <View style={[styles.tableRow, { width: "100%" }]}>
                    <Text
                      style={[
                        styles.tableCell,
                        {
                          textAlign: "center",
                          fontSize: 10,
                          borderLeft: 1,
                          borderTop: 1,
                          borderBottom: 1,
                          paddingRight: 50,
                          paddingTop: 2,
                          marginVertical: 0,
                        },
                      ]}
                    >
                      F. NO. S/13-613/EXP/SS/2018-19{" "}
                    </Text>
                    <Text
                      style={[
                        styles.tableCell,
                        {
                          borderBottom: 1,
                          textAlign: "center",
                          fontSize: 10,
                          paddingTop: 2,
                          borderTop: 1,
                          paddingRight: 50,
                        },
                      ]}
                    >
                      19.02.2019
                    </Text>
                    <Text
                      style={[
                        styles.tableCell,
                        {
                          borderBottom: 1,
                          textAlign: "right",
                          fontSize: 10,
                          borderTop: 1,
                          paddingTop: 2,
                        },
                      ]}
                    >
                      VALIDITY-ONE TIME
                    </Text>
                  </View>
                </View>
                {/* 11 */}
                <View
                  style={{
                    flexDirection: "row",
                  }}
                >
                  <Text
                    style={{
                      fontSize: 8,
                      width: "5%",
                      justifyContent: "flex-start",
                      paddingLeft: 20,
                    }}
                  >
                    11
                  </Text>
                  <Text
                    style={{
                      fontSize: 10,
                      width: "35%",
                      justifyContent: "flex-start",
                      paddingLeft: 15,
                    }}
                  >
                    GSTIN : 24AALFM4290M1ZU
                  </Text>
                </View>
              </View>
            </View>
            {/* table  */}

            <View
              style={[
                styles.tableContainer,
                { borderBottom: 0, height: 90, fontWeight: "bold" },
              ]}
            >
              {/* table lables */}
              <View
                style={[styles.tableRow, { borderTop: 1, borderBottom: 0 }]}
              >
                <Text
                  style={[
                    styles.tableCell,
                    {
                      paddingTop: -0.5,
                      fontWeight: "bold",
                      fontSize: 10,
                      borderBottom: 1,
                      width: 20,
                      paddingLeft: 2,
                    },
                  ]}
                >
                  SR.
                </Text>
                <Text
                  style={[
                    styles.tableCell,
                    {
                      fontSize: 10,
                      paddingTop: -0.5,
                      borderTop: 0,
                      borderBottom: 1,
                      width: 89,
                    },
                  ]}
                >
                  CONTAINER NO.
                </Text>
                <Text
                  style={[
                    styles.tableCell,
                    {
                      fontSize: 10,
                      borderTop: 0,
                      paddingTop: -0.5,
                      borderBottom: 1,
                      width: 30,
                      textAlign: "center",
                    },
                  ]}
                >
                  SIZE
                </Text>
                <Text
                  style={[
                    styles.tableCell,
                    {
                      fontSize: 10,
                      paddingTop: -0.5,
                      borderTop: 0,
                      borderBottom: 1,
                      width: 79,
                    },
                  ]}
                >
                  LINE SEAL NO.
                </Text>
                <Text
                  style={[
                    styles.tableCell,
                    {
                      paddingTop: -0.5,
                      fontSize: 10,
                      borderTop: 0,
                      borderBottom: 1,
                      width: 100,
                    },
                  ]}
                >
                  SELF SEAL NO.
                </Text>
                <Text
                  style={[
                    styles.tableCell,
                    {
                      paddingTop: -0.5,
                      fontSize: 10,
                      borderTop: 0,
                      borderBottom: 1,
                      width: 87,
                    },
                  ]}
                >
                  STUFFING PKGS
                </Text>
                <Text
                  style={[
                    styles.tableCell,
                    {
                      paddingTop: -0.5,
                      fontSize: 10,
                      borderTop: 0,
                      borderBottom: 1,
                      width: 65,
                    },
                  ]}
                >
                  NWT (KGS)
                </Text>
                <Text
                  style={[
                    styles.tableCell,
                    {
                      fontSize: 10,
                      borderTop: 0,
                      paddingTop: -0.5,
                      borderBottom: 1,
                      borderRight: 0,
                      width: 65,
                      margin: 0,
                    },
                  ]}
                >
                  GWT (KGS)
                </Text>
              </View>
              {/* table data 1 */}
              <View>
                <View style={[styles.tableRow, { borderBottom: 0 }]}>
                  <Text
                    style={[
                      styles.tableCell,
                      {
                        fontSize: 10,
                        borderBottom: 1,
                        width: 20,
                        paddingTop: -0.5,
                      },
                    ]}
                  >
                    1
                  </Text>
                  <Text
                    style={[
                      styles.tableCell,
                      {
                        fontSize: 10,
                        borderTop: 0,
                        paddingTop: -0.5,
                        borderBottom: 1,
                        width: 89,
                      },
                    ]}
                  >
                    TGBU3649940
                  </Text>
                  <Text
                    style={[
                      styles.tableCell,
                      {
                        fontSize: 10,
                        paddingTop: -0.5,
                        borderTop: 0,
                        borderBottom: 1,
                        width: 30,
                      },
                    ]}
                  >
                    20
                  </Text>
                  <Text
                    style={[
                      styles.tableCell,
                      {
                        fontSize: 10,
                        borderTop: 0,
                        paddingTop: -0.5,
                        borderBottom: 1,
                        width: 79,
                      },
                    ]}
                  >
                    IN0281094
                  </Text>
                  <Text
                    style={[
                      styles.tableCell,
                      {
                        fontSize: 10,
                        borderTop: 0,
                        paddingTop: -0.5,
                        borderBottom: 1,
                        width: 100,
                      },
                    ]}
                  >
                    WIND02266728
                  </Text>
                  <Text
                    style={[
                      styles.tableCell,
                      {
                        fontSize: 10,
                        paddingTop: -0.5,
                        borderTop: 0,
                        borderBottom: 1,
                        width: 87,
                      },
                    ]}
                  >
                    400 BAGS
                  </Text>
                  <Text
                    style={[
                      styles.tableCell,
                      {
                        fontSize: 10,
                        borderTop: 0,
                        paddingTop: -0.5,
                        borderBottom: 1,
                        width: 65,
                      },
                    ]}
                  >
                    19000.00
                  </Text>
                  <Text
                    style={[
                      styles.tableCell,
                      {
                        fontSize: 10,
                        paddingTop: -0.5,
                        borderTop: 0,
                        borderBottom: 1,
                        borderRight: 0,
                        width: 65,
                      },
                    ]}
                  >
                    19200.00
                  </Text>
                </View>
              </View>
              {/* table data 2 */}
              <View>
                <View style={[styles.tableRow, { borderBottom: 0 }]}>
                  <Text
                    style={[
                      styles.tableCell,
                      {
                        fontSize: 10,
                        borderBottom: 1,
                        width: 20,
                        paddingTop: -0.5,
                      },
                    ]}
                  >
                    2
                  </Text>
                  <Text
                    style={[
                      styles.tableCell,
                      {
                        fontSize: 10,
                        paddingTop: -0.5,
                        borderTop: 0,
                        borderBottom: 1,
                        width: 89,
                      },
                    ]}
                  >
                    PCIU1854560
                  </Text>
                  <Text
                    style={[
                      styles.tableCell,
                      {
                        fontSize: 10,
                        paddingTop: -0.5,
                        borderTop: 0,
                        borderBottom: 1,
                        width: 30,
                      },
                    ]}
                  >
                    20
                  </Text>
                  <Text
                    style={[
                      styles.tableCell,
                      {
                        fontSize: 10,
                        paddingTop: -0.5,
                        borderTop: 0,
                        borderBottom: 1,
                        width: 79,
                      },
                    ]}
                  >
                    IN0281094
                  </Text>
                  <Text
                    style={[
                      styles.tableCell,
                      {
                        fontSize: 10,
                        borderTop: 0,
                        borderBottom: 1,
                        paddingTop: -0.5,
                        width: 100,
                      },
                    ]}
                  >
                    WIND02266728
                  </Text>
                  <Text
                    style={[
                      styles.tableCell,
                      {
                        fontSize: 10,
                        borderTop: 0,
                        paddingTop: -0.5,
                        borderBottom: 1,
                        width: 87,
                      },
                    ]}
                  >
                    400 BAGS
                  </Text>
                  <Text
                    style={[
                      styles.tableCell,
                      {
                        fontSize: 10,
                        borderTop: 0,
                        borderBottom: 1,
                        paddingTop: -0.5,
                        width: 65,
                      },
                    ]}
                  >
                    19000.00
                  </Text>
                  <Text
                    style={[
                      styles.tableCell,
                      {
                        fontSize: 10,
                        paddingTop: -0.5,
                        borderTop: 0,
                        borderBottom: 1,
                        borderRight: 0,
                        width: 65,
                      },
                    ]}
                  >
                    19200.00
                  </Text>
                </View>
              </View>
              {/* table data 3 */}
              <View>
                <View style={[styles.tableRow, { borderBottom: 0 }]}>
                  <Text
                    style={[
                      styles.tableCell,
                      {
                        fontSize: 10,
                        borderBottom: 1,
                        width: 20,
                        paddingTop: -0.5,
                      },
                    ]}
                  >
                    2
                  </Text>
                  <Text
                    style={[
                      styles.tableCell,
                      {
                        fontSize: 10,
                        paddingTop: -0.5,
                        borderTop: 0,
                        borderBottom: 1,
                        width: 89,
                      },
                    ]}
                  >
                    PCIU1854560
                  </Text>
                  <Text
                    style={[
                      styles.tableCell,
                      {
                        fontSize: 10,
                        paddingTop: -0.5,
                        borderTop: 0,
                        borderBottom: 1,
                        width: 30,
                      },
                    ]}
                  >
                    20
                  </Text>
                  <Text
                    style={[
                      styles.tableCell,
                      {
                        fontSize: 10,
                        paddingTop: -0.5,
                        borderTop: 0,
                        borderBottom: 1,
                        width: 79,
                      },
                    ]}
                  >
                    IN0281094
                  </Text>
                  <Text
                    style={[
                      styles.tableCell,
                      {
                        fontSize: 10,
                        borderTop: 0,
                        borderBottom: 1,
                        paddingTop: -0.5,
                        width: 100,
                      },
                    ]}
                  >
                    WIND02266728
                  </Text>
                  <Text
                    style={[
                      styles.tableCell,
                      {
                        fontSize: 10,
                        borderTop: 0,
                        paddingTop: -0.5,
                        borderBottom: 1,
                        width: 87,
                      },
                    ]}
                  >
                    400 BAGS
                  </Text>
                  <Text
                    style={[
                      styles.tableCell,
                      {
                        fontSize: 10,
                        borderTop: 0,
                        borderBottom: 1,
                        paddingTop: -0.5,
                        width: 65,
                      },
                    ]}
                  >
                    19000.00
                  </Text>
                  <Text
                    style={[
                      styles.tableCell,
                      {
                        fontSize: 10,
                        paddingTop: -0.5,
                        borderTop: 0,
                        borderBottom: 1,
                        borderRight: 0,
                        width: 65,
                      },
                    ]}
                  >
                    19200.00
                  </Text>
                </View>
              </View>
              {/* table data 4 */}
              <View>
                <View style={[styles.tableRow, { borderBottom: 0 }]}>
                  <Text
                    style={[
                      styles.tableCell,
                      {
                        fontSize: 10,
                        borderBottom: 1,
                        width: 20,
                        paddingTop: -0.5,
                      },
                    ]}
                  >
                    2
                  </Text>
                  <Text
                    style={[
                      styles.tableCell,
                      {
                        fontSize: 10,
                        paddingTop: -0.5,
                        borderTop: 0,
                        borderBottom: 1,
                        width: 89,
                      },
                    ]}
                  >
                    PCIU1854560
                  </Text>
                  <Text
                    style={[
                      styles.tableCell,
                      {
                        fontSize: 10,
                        paddingTop: -0.5,
                        borderTop: 0,
                        borderBottom: 1,
                        width: 30,
                      },
                    ]}
                  >
                    20
                  </Text>
                  <Text
                    style={[
                      styles.tableCell,
                      {
                        fontSize: 10,
                        paddingTop: -0.5,
                        borderTop: 0,
                        borderBottom: 1,
                        width: 79,
                      },
                    ]}
                  >
                    IN0281094
                  </Text>
                  <Text
                    style={[
                      styles.tableCell,
                      {
                        fontSize: 10,
                        borderTop: 0,
                        borderBottom: 1,
                        paddingTop: -0.5,
                        width: 100,
                      },
                    ]}
                  >
                    WIND02266728
                  </Text>
                  <Text
                    style={[
                      styles.tableCell,
                      {
                        fontSize: 10,
                        borderTop: 0,
                        paddingTop: -0.5,
                        borderBottom: 1,
                        width: 87,
                      },
                    ]}
                  >
                    400 BAGS
                  </Text>
                  <Text
                    style={[
                      styles.tableCell,
                      {
                        fontSize: 10,
                        borderTop: 0,
                        borderBottom: 1,
                        paddingTop: -0.5,
                        width: 65,
                      },
                    ]}
                  >
                    19000.00
                  </Text>
                  <Text
                    style={[
                      styles.tableCell,
                      {
                        fontSize: 10,
                        paddingTop: -0.5,
                        borderTop: 0,
                        borderBottom: 1,
                        borderRight: 0,
                        width: 65,
                      },
                    ]}
                  >
                    19200.00
                  </Text>
                </View>
              </View>
              {/* table data 5 */}
              <View>
                <View style={[styles.tableRow, { borderBottom: 0 }]}>
                  <Text
                    style={[
                      styles.tableCell,
                      {
                        fontSize: 10,
                        borderBottom: 1,
                        width: 20,
                        paddingTop: -0.5,
                      },
                    ]}
                  >
                    2
                  </Text>
                  <Text
                    style={[
                      styles.tableCell,
                      {
                        fontSize: 10,
                        paddingTop: -0.5,
                        borderTop: 0,
                        borderBottom: 1,
                        width: 89,
                      },
                    ]}
                  >
                    PCIU1854560
                  </Text>
                  <Text
                    style={[
                      styles.tableCell,
                      {
                        fontSize: 10,
                        paddingTop: -0.5,
                        borderTop: 0,
                        borderBottom: 1,
                        width: 30,
                      },
                    ]}
                  >
                    20
                  </Text>
                  <Text
                    style={[
                      styles.tableCell,
                      {
                        fontSize: 10,
                        paddingTop: -0.5,
                        borderTop: 0,
                        borderBottom: 1,
                        width: 79,
                      },
                    ]}
                  >
                    IN0281094
                  </Text>
                  <Text
                    style={[
                      styles.tableCell,
                      {
                        fontSize: 10,
                        borderTop: 0,
                        borderBottom: 1,
                        paddingTop: -0.5,
                        width: 100,
                      },
                    ]}
                  >
                    WIND02266728
                  </Text>
                  <Text
                    style={[
                      styles.tableCell,
                      {
                        fontSize: 10,
                        borderTop: 0,
                        paddingTop: -0.5,
                        borderBottom: 1,
                        width: 87,
                      },
                    ]}
                  >
                    400 BAGS
                  </Text>
                  <Text
                    style={[
                      styles.tableCell,
                      {
                        fontSize: 10,
                        borderTop: 0,
                        borderBottom: 1,
                        paddingTop: -0.5,
                        width: 65,
                      },
                    ]}
                  >
                    19000.00
                  </Text>
                  <Text
                    style={[
                      styles.tableCell,
                      {
                        fontSize: 10,
                        paddingTop: -0.5,
                        borderTop: 0,
                        borderBottom: 1,
                        borderRight: 0,
                        width: 65,
                      },
                    ]}
                  >
                    19200.00
                  </Text>
                </View>
              </View>
              {/* table data 6 */}
              <View>
                <View style={[styles.tableRow, { borderBottom: 0 }]}>
                  <Text
                    style={[
                      styles.tableCell,
                      {
                        fontSize: 10,
                        borderBottom: 1,
                        width: 20,
                        paddingTop: -0.5,
                      },
                    ]}
                  >
                    2
                  </Text>
                  <Text
                    style={[
                      styles.tableCell,
                      {
                        fontSize: 10,
                        paddingTop: -0.5,
                        borderTop: 0,
                        borderBottom: 1,
                        width: 89,
                      },
                    ]}
                  >
                    PCIU1854560
                  </Text>
                  <Text
                    style={[
                      styles.tableCell,
                      {
                        fontSize: 10,
                        paddingTop: -0.5,
                        borderTop: 0,
                        borderBottom: 1,
                        width: 30,
                      },
                    ]}
                  >
                    20
                  </Text>
                  <Text
                    style={[
                      styles.tableCell,
                      {
                        fontSize: 10,
                        paddingTop: -0.5,
                        borderTop: 0,
                        borderBottom: 1,
                        width: 79,
                      },
                    ]}
                  >
                    IN0281094
                  </Text>
                  <Text
                    style={[
                      styles.tableCell,
                      {
                        fontSize: 10,
                        borderTop: 0,
                        borderBottom: 1,
                        paddingTop: -0.5,
                        width: 100,
                      },
                    ]}
                  >
                    WIND02266728
                  </Text>
                  <Text
                    style={[
                      styles.tableCell,
                      {
                        fontSize: 10,
                        borderTop: 0,
                        paddingTop: -0.5,
                        borderBottom: 1,
                        width: 87,
                      },
                    ]}
                  >
                    400 BAGS
                  </Text>
                  <Text
                    style={[
                      styles.tableCell,
                      {
                        fontSize: 10,
                        borderTop: 0,
                        borderBottom: 1,
                        paddingTop: -0.5,
                        width: 65,
                      },
                    ]}
                  >
                    19000.00
                  </Text>
                  <Text
                    style={[
                      styles.tableCell,
                      {
                        fontSize: 10,
                        paddingTop: -0.5,
                        borderTop: 0,
                        borderBottom: 1,
                        borderRight: 0,
                        width: 65,
                      },
                    ]}
                  >
                    19200.00
                  </Text>
                </View>
              </View>
            </View>

            <View style={[styles.sideBySideContainer]}>
              <Text
                style={[
                  styles.mediumText,
                  { textAlign: "center", padding: 5, paddingLeft: 15 },
                ]}
              >
                CERTIFIED THAT THE DESCRIPTION, WEIGHT, SIZE, QUANTITY & VALUE
                OF THE GOODS COVERED BY THIS INVOICE HAVE BEEN CHECKED BY ME AND
                THE GOODS HAVE BEEN PACKED AND SEALED WITH ONE TIME SEAL HAVING
                NUMBERS AS DETAILS MENTIONED ABOVE UNDER MY SUPERVISION
              </Text>
            </View>

            <View
              style={[styles.sideBySideContainer, { flexDirection: "row" }]}
            >
              <View style={[{}]}>
                <Text
                  style={[
                    {
                      textAlign: "center",
                      fontWeight: "bold",
                      fontSize: 8,
                      width: 150,
                      padding: 20,
                      paddingBottom: 10,
                      borderBottom: 0,
                      paddingLeft: 30,
                    },
                  ]}
                >
                  DATE OF SEALING
                </Text>
              </View>
              <View style={[{ flex: 1 }]}>
                <Text
                  style={[
                    {
                      textAlign: "center",
                      fontWeight: "bold",
                      fontSize: 8,
                      padding: 20,
                      paddingBottom: 10,
                      borderBottom: 0,
                      paddingRight: 180,
                    },
                  ]}
                >
                  13.08.2024
                </Text>
              </View>
            </View>

            <View
              style={[
                styles.tableContainer,
                { height: "2%", paddingLeft: 20, borderBottom: 0,fontWeight:'bold',fontSize:8 ,top:8},
              ]}
            >
              <View style={[styles.tableRow,]}>
                <Text
                  style={[
                    styles.tableCell,
                    {
                      width:175,
                      textAlign: "center",
                      fontSize: 10,
                      borderLeft: 1,
                      borderTop: 1,
                      borderBottom: 0,
                      paddingRight: 50,
                      paddingTop: 1,
                      marginVertical: 0,
                    },
                  ]}
                >
                 MR. GOPAL VANPARIYA
                </Text>
                <Text
                  style={[
                    styles.tableCell,
                    {
                      width:130,
                      borderBottom: 0,
                      textAlign: "center",
                      fontSize: 10,
                      paddingTop: 1,
                      borderTop: 1,
                      paddingRight: 50,
                    },
                  ]}
                >
                 PARTNER
                </Text>
                <Text
                  style={[
                    styles.tableCell,
                    {
                      borderRight:0,
                      borderTop:0,
                      borderLeft:0,
                      borderBottom: 0,
                      textAlign: "center",
                      fontSize: 10,
                      paddingTop: 2,
                      paddingLeft:35
                    },
                  ]}
                >
                SIGNATURE AND COMPANY SEAL
                </Text>
              </View>
            </View>
          </View>
        </Page>
      </Document>
    </PDFViewer>
  );
};
export default AnnexureInvoice;
