import { createContext, useState, useContext, ReactNode } from "react";

interface AuthContextType {
  isAuthenticated: boolean;
  permissions: string[];
  login: (token: string, permissions: string[], user: object) => void;
  logout: () => void;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const AuthProvider = ({ children }: { children: ReactNode }) => {
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
  const [permissions, setPermissions] = useState<string[]>([]);

  const login = (token: string, userPermissions: string[], user: object) => {
    localStorage.setItem("token", token);
    localStorage.setItem("permissions", JSON.stringify(userPermissions));
    localStorage.setItem("me", JSON.stringify(user));
    setIsAuthenticated(true);
    setPermissions(userPermissions);
  };

  const logout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("permissions");
    localStorage.removeItem("me");
    setIsAuthenticated(false);
    setPermissions([]);
  };

  return (
    <AuthContext.Provider
      value={{ isAuthenticated, permissions, login, logout }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) throw new Error("useAuth must be used within an AuthProvider");
  return context;
};

export const getToken = () => {
  return localStorage.getItem("token") || "";
};

export const getMe = () => {
  const userDetails = localStorage.getItem("me");
  if (userDetails) {
    return JSON.parse(userDetails);
  } else {
    return {};
  }
};

export const getPermissions = () => {
  const permissions = localStorage.getItem("permissions");
  if (permissions) {
    return JSON.parse(permissions);
  } else {
    return [];
  }
};
