import * as Yup from "yup";
import React, { useState } from "react";
import { errorToast, successToast } from "../Toast";
import { Field, Form, Formik, ErrorMessage } from "formik";
import { createBillType } from "../../services/_sales";

const createBillTypeInitialValues = {
  name: "",
  status: "",
};

interface AddBillTypeModalProps {
  addBillTypeModal: boolean;
  closeBillTypeModal: () => void;
  onBillTypeCreated: any;
}

const createBillTypeValidationSchema = Yup.object().shape({
  name: Yup.string().required("Name is required"),
  status: Yup.string().required("Status is required"),
});

const AddBillTypeModal: React.FC<AddBillTypeModalProps> = ({
  addBillTypeModal,
  closeBillTypeModal,
  onBillTypeCreated,
}) => {
  const [submitLoader, setSubmitLoader] = useState(false);

  const handleSubmit = async (values: any, { resetForm }: any) => {
    setSubmitLoader(true);
    values.status = values.status === "true" ? true : false;

    try {
      const result: any = await createBillType(values);
      if (result && result.data) {
        setSubmitLoader(false);
        resetForm({ values: createBillTypeInitialValues });
        successToast(result.message);
        closeBillTypeModal();
        onBillTypeCreated(result.data);
      } else {
        setSubmitLoader(false);
        errorToast(result.message);
      }
    } catch (error) {
      setSubmitLoader(false);
      errorToast("An unexpected error occurred.");
    }

    return;
  };

  return (
    <>
      {addBillTypeModal && (
        <>
          <div className="modal-backdrop fade show"></div>
          <div
            className="modal fade show"
            id="addBillTypeModal"
            tabIndex={-3}
            aria-hidden={!addBillTypeModal}
            style={{ display: addBillTypeModal ? "block" : "none" }}
          >
            <div
              className="modal fade show"
              id="addBillTypeModal"
              tabIndex={-3}
              aria-hidden={!addBillTypeModal}
              style={{ display: addBillTypeModal ? "block" : "none" }}
            >
              <div
                className="modal fade show"
                id="addBillTypeModal"
                tabIndex={-3}
                aria-hidden={!addBillTypeModal}
                style={{ display: addBillTypeModal ? "block" : "none" }}
              >
                <div className="modal-dialog modal-lg modal-simple modal-dialog-centered">
                  <div className="modal-content">
                    <div className="modal-body p-4 d-flex align-items-center justify-content-center">
                      <button
                        type="button"
                        className="btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                        onClick={closeBillTypeModal}
                      ></button>
                      <div className="w-100">
                        <div className="text-center mb-4">
                          <h4 className="mb-2">Add Bill Type</h4>
                        </div>

                        <Formik
                          initialValues={createBillTypeInitialValues}
                          validationSchema={createBillTypeValidationSchema}
                          onSubmit={handleSubmit}
                        >
                          {({ values, handleChange }) => (
                            <Form className="dt_adv_search w-100">
                              <div className="row justify-content-center">
                                <div className="col-12 col-lg-12 col-lg-4">
                                  <div className="row g-3">
                                    <div className="col-12 col-lg-6">
                                      <label
                                        htmlFor="name"
                                        className="form-label"
                                        style={{ fontSize: "0.9375rem" }}
                                      >
                                        Name:{" "}
                                        <span className="text-danger">*</span>
                                      </label>
                                      <Field
                                        id="name"
                                        name="name"
                                        type="text"
                                        className="form-control"
                                        placeholder="Enter name"
                                        value={values.name}
                                        onChange={handleChange}
                                      />
                                      <ErrorMessage
                                        name="name"
                                        className="text-danger"
                                        component="div"
                                      />
                                    </div>
                                    <div className="col-12 col-md-6">
                                      <label
                                        htmlFor="status"
                                        className="form-label"
                                        style={{ fontSize: "0.9375rem" }}
                                      >
                                        Status:{" "}
                                        <span className="text-danger">*</span>
                                      </label>
                                      <select
                                        id="status"
                                        name="status"
                                        className="form-select"
                                        value={values.status}
                                        onChange={handleChange}
                                      >
                                        <option value="">Select Status</option>
                                        <option value="true">Active</option>
                                        <option value="false">Inactive</option>
                                      </select>
                                      <ErrorMessage
                                        name="status"
                                        className="text-danger"
                                        component="div"
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="text-center mt-4">
                                <button
                                  type="submit"
                                  className="btn btn-primary me-3"
                                  disabled={submitLoader}
                                >
                                  {submitLoader && (
                                    <span
                                      className="spinner-border spinner-border-sm me-2"
                                      role="status"
                                      aria-hidden="true"
                                    ></span>
                                  )}
                                  Add Bill Type
                                </button>
                                <button
                                  type="button"
                                  className="btn btn-label-secondary"
                                  onClick={closeBillTypeModal}
                                >
                                  Cancel
                                </button>
                              </div>
                            </Form>
                          )}
                        </Formik>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default AddBillTypeModal;
