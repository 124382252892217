/* eslint-disable react-hooks/exhaustive-deps */
import moment from "moment";
import { CSVLink } from "react-csv";
import { Link } from "react-router-dom";
import { DateRangePicker } from "rsuite";
import Spinner from "../../theme/Spinner";
import { useEffect, useRef, useState } from "react";
import Pagination from "../../components/Pagination";
import { CURRENCY } from "../../components/Constants";
import { getAllPurchasesApi } from "../../services/_purchase";
import { getSupplierNameListApi } from "../../services/_supplier";
import {
  formatAmount,
  formatDate,
  formatString,
} from "../../components/Helper";
import { supplierWisePaymentReportApi } from "../../services/_report";
import { PaymentStatus, TransactionTypes } from "../../components/Enums";

interface CustomCSVLink extends HTMLAnchorElement {
  link: HTMLAnchorElement;
}

const SupplierWisePaymentReport = () => {
  const [record, setRecord] = useState<any[]>([]);
  const [supplierList, setSupplierList] = useState<any[]>([]);
  const [purchaseOrderList, setPurchaseOrderList] = useState<any[]>([]);
  const [filteredPurchaseOrder, setFilteredPurchaseOrder] = useState<any>([]);

  const [filterSupplier, setFilterSupplier] = useState<string>("");
  const [filterPurchaseNo, setFilterPurchaseNo] = useState<string>("");
  const [dateRange, setDateRange] = useState(null);
  const [page, setPage] = useState<number>(1);
  const [perPage] = useState<number>(10);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [totalRecords, setTotalRecords] = useState<number>(0);
  const [applyFilter, setApplyFilter] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [displayFilter, setDisplayFilter] = useState(false);
  const [totalCreditAmount, setTotalCreditAmount] = useState(0);
  const [totalDebitAmount, setTotalDebitAmount] = useState(0);
  const [totalAmount, setTotalAmount] = useState(0);
  const csvLinkRef = useRef<CustomCSVLink>(null);
  const [exportData, setExportData] = useState([]);
  const [sortKey, setSortKey] = useState<string>("");
  const [sortType, setSortType] = useState<string>("");

  const toggleFilter = async () => {
    setDisplayFilter(!displayFilter);
  };

  const getSupplierNameList = async () => {
    try {
      const result: any = await getSupplierNameListApi();
      setSupplierList(result?.data);
    } catch (error) {
      console.error("Error fetching logistic list", error);
    }
  };

  const getPurchaseOrderList = async () => {
    try {
      const result: any = await getAllPurchasesApi();
      setPurchaseOrderList(result?.data);
      setFilteredPurchaseOrder(result?.data);
    } catch (error) {
      console.error("Error fetching SaleOrder list", error);
    }
  };

  const getSupplierReportList = async (
    page: number,
    perPage: number,
    isFilter?: boolean,
    sortKey?: string,
    sortType?: string
  ) => {
    setLoading(true);
    let query = `page=${page}&per_page=${perPage}`;
    if (isFilter) {
      if (filterSupplier) {
        query += `&supplier_id=${filterSupplier}`;
      }
      if (filterPurchaseNo) {
        query += `&purchase_order_id=${filterPurchaseNo}`;
      }
      if (dateRange) {
        query += `&from_date=${moment(dateRange[0]).format("YYYY-MM-DD")}`;
        query += `&to_date=${moment(dateRange[1]).format("YYYY-MM-DD")}`;
      }
      if (sortKey && sortType) {
        query += `&sort_key=${sortKey}&sort_type=${sortType}`;
      }
    }
    const result: any = await supplierWisePaymentReportApi(query);

    if (result.isSuccess) {
      const totalPagesCount = Math.ceil(result.data.total_records / perPage);
      setRecord(result?.data.records);
      setTotalPages(totalPagesCount);
      setTotalRecords(result.data.total_records);
      setTotalCreditAmount(result?.data?.total_credit || 0);
      setTotalDebitAmount(result?.data?.total_debit || 0);
      setTotalAmount(result?.data?.total_amount || 0);
    }
    setLoading(false);
  };

  const filterList = async () => {
    setApplyFilter(true);
    getSupplierReportList(page, perPage, true);
  };

  const resetList = async () => {
    setPage(1);
    setApplyFilter(false);
    setFilterSupplier("");
    setFilterPurchaseNo("");
    setDateRange(null);
    setFilteredPurchaseOrder(purchaseOrderList);
    getSupplierReportList(page, perPage);
  };

  const prevButton = async () => {
    if (page > 1) {
      setPage(page - 1);
    }
  };

  const nextButton = async () => {
    if (totalPages > page) {
      setPage(page + 1);
    }
  };

  const getFileName = () => {
    const filerName = "supplier-wise-payment-report";
    return `${filerName}-${moment().format("MM-DD-YYYY-HH-mm-ss")}.csv`;
  };

  const exportSupplierRecords = async () => {
    setExportData([]);
    let csvHeader: any;
    let csvData: any;
    let csvFooter: any;

    let query = `export=true`;
    if (filterSupplier) {
      query += `&supplier_id=${filterSupplier}`;
    }
    if (filterPurchaseNo) {
      query += `&purchase_order_id=${filterPurchaseNo}`;
    }
    if (dateRange) {
      query += `&from_date=${moment(dateRange[0]).format("YYYY-MM-DD")}`;
      query += `&to_date=${moment(dateRange[1]).format("YYYY-MM-DD")}`;
    }
    if (sortKey && sortType) {
      query += `&sort_key=${sortKey}&sort_type=${sortType}`;
    }

    const result: any = await supplierWisePaymentReportApi(query);
    if (result.isSuccess) {
      if (result.data.records) {
        csvHeader = [
          "Sr No.",
          "Supplier",
          "Purchase Order No",
          "Amount",
          "Transaction Type",
          "Payment Method",
          "Transaction No",
          "Payment Status",
          "Remark",
          "Transaction Date",
        ];
        csvData = result.data.records.map((item: any, index: number) => [
          (index + 1).toString(),
          item?.supplier?.name || "",
          item?.purchase?.invoice_no || "",
          `${CURRENCY.INR.SYMBOL}${formatAmount(item.amount)}`,
          item?.transaction_type || "",
          item?.payment_method.replace("_", " ") || "",
          item?.transaction_no || "",
          formatString(item?.payment_status) || "",
          item?.remark || "",
          formatDate(item.transaction_date),
        ]);
        csvFooter = [
          [
            "",
            "",
            "Total Credit :",
            `${CURRENCY.INR.SYMBOL}${formatAmount(totalCreditAmount)}`,
            "",
            "",
            "",
            "",
            "",
            "",
          ],
          [
            "",
            "",
            "Total Debit :",
            `${CURRENCY.INR.SYMBOL}${formatAmount(totalDebitAmount)}`,
            "",
            "",
            "",
            "",
            "",
            "",
          ],
          [
            "",
            "",
            totalAmount > 0 ? "Profit :" : "Loss :",
            `${CURRENCY.INR.SYMBOL}${formatAmount(totalAmount)}`,
            "",
            "",
            "",
            "",
            "",
            "",
          ],
        ];

        const exportData: any = [csvHeader, ...csvData, ...csvFooter];
        setExportData(exportData);

        if (csvLinkRef.current?.link) {
          setTimeout(() => {
            csvLinkRef.current!.link.click();
          }, 0);
        }
      }
    }
  };

  const sortTable = async (key: string) => {
    let type = sortType === "asc" ? "desc" : "asc";
    if (sortKey !== key) {
      type = "asc";
    }
    setSortKey(key);
    setSortType(type);
    await getSupplierReportList(page, perPage, true, key, type);
  };

  const filterSuggestions = (e: any) => {
    const name = e.target.name;
    const value = e.target.value;

    if (name === "supplier_id") {
      const data = value
        ? purchaseOrderList.filter((item: any) => item.supplier_id === value)
        : purchaseOrderList;
      setFilterPurchaseNo("");
      setFilteredPurchaseOrder(data);
    }
  };

  useEffect(() => {
    getSupplierNameList();
    getPurchaseOrderList();
  }, []);

  useEffect(() => {
    if (page && perPage) {
      getSupplierReportList(page, perPage, applyFilter);
    }
  }, [page, perPage, applyFilter]);

  return (
    <div className="card">
      <div className="card-header pb-0 d-flex justify-content-between">
        <div>
          <h5>Supplier Wise Payment Report</h5>
        </div>
        <div>
          <Link
            to="#"
            type="button"
            className="btn btn-label-github"
            style={{ marginRight: "15px" }}
            onClick={toggleFilter}
          >
            <span>
              <i className="ti ti-filter me-sm-1"></i>{" "}
              <span className="d-none d-sm-inline-block">Filter</span>
            </span>
          </Link>
          <Link
            to={"#"}
            className="btn btn-primary waves-effect waves-light"
            type="button"
            onClick={() => {
              exportSupplierRecords();
            }}
          >
            <span>
              <i className="ti ti-download me-sm-1"></i>{" "}
              <span className="d-none d-sm-inline-block">Download Excel</span>
            </span>
          </Link>
          <CSVLink
            data={exportData}
            filename={getFileName()}
            className="d-none"
            ref={csvLinkRef as any}
          >
            <span>
              <i className="ti ti-download me-sm-1"></i>{" "}
              <span className="d-none d-sm-inline-block">Download Excel</span>
            </span>
          </CSVLink>
        </div>
      </div>

      {displayFilter && (
        <div className="card-body">
          <form className="dt_adv_search">
            <div className="row">
              <div className="col-12">
                <div className="row g-3">
                  <div className="col-12 col-sm-6 col-lg-3">
                    <label
                      htmlFor="date"
                      className="form-label"
                      style={{ fontSize: "0.9375rem" }}
                    >
                      Transaction Date:
                    </label>
                    <DateRangePicker
                      format="dd-MM-yyyy"
                      className="form-control"
                      placeholder="From Date ~ To Date"
                      value={dateRange}
                      onChange={(values: any) => {
                        setDateRange(values);
                      }}
                    />
                  </div>
                  <div className="col-12 col-sm-6 col-lg-3">
                    <label
                      htmlFor="supplier_id"
                      className="form-label"
                      style={{ fontSize: "0.9375rem" }}
                    >
                      Supplier:
                    </label>
                    <select
                      className="form-select"
                      name="supplier_id"
                      id="supplier_id"
                      value={filterSupplier}
                      onChange={(e) => {
                        setFilterSupplier(e.target.value);
                        filterSuggestions(e);
                      }}
                    >
                      <option value="">Select Supplier</option>
                      {supplierList.map((supplier) => (
                        <option value={supplier.id} key={supplier.id}>
                          {supplier.name}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="col-12 col-sm-6 col-lg-3">
                    <label
                      htmlFor="purchase_order_id"
                      className="form-label"
                      style={{ fontSize: "0.9375rem" }}
                    >
                      Purchase Order No:
                    </label>
                    <select
                      className="form-select"
                      name="purchase_order_id"
                      id="purchase_order_id"
                      value={filterPurchaseNo}
                      onChange={(e) => {
                        setFilterPurchaseNo(e.target.value);
                      }}
                    >
                      <option value="">Select Purchase Order No</option>
                      {filteredPurchaseOrder.map((order: any) => (
                        <option value={order.id} key={order.id}>
                          {order.invoice_no}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="col-12">
                    <button
                      type="button"
                      className="btn btn-primary"
                      onClick={filterList}
                    >
                      Filter
                    </button>
                    <button
                      type="button"
                      className="btn btn-label-github ms-5"
                      onClick={resetList}
                    >
                      Reset
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      )}

      <div className="card-datatable table-responsive">
        {loading && <Spinner isFullScreen={false} />}
        <table className="dt-advanced-search table table-nowrap">
          <thead>
            <tr>
              <th>#</th>
              <th
                className="cursor-pointer"
                onClick={() => sortTable("supplier")}
              >
                Supplier
                <span>
                  <i className="ti ti-arrows-down-up sorting-icon"></i>
                </span>
              </th>
              <th
                className="cursor-pointer"
                onClick={() => sortTable("invoice_no")}
              >
                Purchase Order No
                <span>
                  <i className="ti ti-arrows-down-up sorting-icon"></i>
                </span>
              </th>
              <th
                className="cursor-pointer"
                onClick={() => sortTable("amount")}
              >
                Amount
                <span>
                  <i className="ti ti-arrows-down-up sorting-icon"></i>
                </span>
              </th>
              <th
                className="cursor-pointer"
                onClick={() => sortTable("transaction_type")}
              >
                Transaction Type
                <span>
                  <i className="ti ti-arrows-down-up sorting-icon"></i>
                </span>
              </th>
              <th
                className="cursor-pointer"
                onClick={() => sortTable("payment_method")}
              >
                Payment Method
                <span>
                  <i className="ti ti-arrows-down-up sorting-icon"></i>
                </span>
              </th>
              <th
                className="cursor-pointer"
                onClick={() => sortTable("transaction_no")}
              >
                Transaction No
                <span>
                  <i className="ti ti-arrows-down-up sorting-icon"></i>
                </span>
              </th>
              <th
                className="cursor-pointer"
                onClick={() => sortTable("payment_status")}
              >
                Payment Status
                <span>
                  <i className="ti ti-arrows-down-up sorting-icon"></i>
                </span>
              </th>
              <th
                className="cursor-pointer"
                onClick={() => sortTable("remark")}
              >
                Remark
                <span>
                  <i className="ti ti-arrows-down-up sorting-icon"></i>
                </span>
              </th>
              <th
                className="cursor-pointer"
                onClick={() => sortTable("transaction_date")}
              >
                Transaction Date
                <span>
                  <i className="ti ti-arrows-down-up sorting-icon"></i>
                </span>
              </th>
            </tr>
          </thead>
          <tbody>
            {record.length > 0 ? (
              <>
                {record.map((item: any, i: number) => {
                  let transactionTypeClass = "";
                  if (item.transaction_type === TransactionTypes.CREDIT) {
                    transactionTypeClass = `bg-label-success`;
                  } else {
                    transactionTypeClass = `bg-label-danger`;
                  }
                  let statusClass = "";
                  if (item.payment_status === PaymentStatus.PENDING) {
                    statusClass = `bg-label-warning`;
                  } else if (item.payment_status === PaymentStatus.COMPLETED) {
                    statusClass = `bg-label-success`;
                  } else if (item.payment_status === PaymentStatus.ADVANCE) {
                    statusClass = `bg-label-info`;
                  } else {
                    statusClass = `bg-label-secondary`;
                  }
                  return (
                    <tr key={item.id}>
                      <td>{i + 1}</td>
                      <td>{item?.supplier?.name || ""}</td>
                      <td>{item?.purchase?.invoice_no || ""}</td>
                      <td>
                        {CURRENCY.INR.SYMBOL}
                        {formatAmount(item.amount)}
                      </td>
                      <td>
                        <span className={`badge ${transactionTypeClass} me-1`}>
                          {item.transaction_type}
                        </span>
                      </td>
                      <td>{item?.payment_method.replace("_", " ") || ""}</td>
                      <td>{item?.transaction_no || ""}</td>
                      <td>
                        <td>
                          <span className={`badge ${statusClass} me-1`}>
                            {formatString(item.payment_status)}
                          </span>
                        </td>
                      </td>
                      <td>{item?.remark || ""}</td>
                      <td>{formatDate(item.transaction_date)}</td>
                    </tr>
                  );
                })}
              </>
            ) : (
              <tr>
                <td colSpan={10} className="text-center">
                  No records found
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>

      <div className="row mt-5 mx-0 align-items-center">
        <div className="col-sm-4 text-sm-center">
          <div>
            Total Credit:{" "}
            <span
              className="fw-bold"
              style={{ fontWeight: 500, color: "#444050" }}
            >
              {CURRENCY.INR.SYMBOL}
              {formatAmount(totalCreditAmount)}
            </span>
          </div>
        </div>
        <div className="col-sm-4 text-sm-center">
          <div>
            Total Debit:{" "}
            <span
              className="fw-bold"
              style={{ fontWeight: 500, color: "#444050" }}
            >
              {CURRENCY.INR.SYMBOL}
              {formatAmount(totalDebitAmount)}
            </span>
          </div>
        </div>
        <div className="col-sm-4">
          <div className="d-flex justify-content-sm-center">
            <p
              className="p-0 m-0"
              style={{
                color: totalAmount > 0 ? "green" : "red",
              }}
            >
              {totalAmount > 0 ? "Profit:" : "Loss:"}
            </p>
            &nbsp;
            <span
              className="fw-bold"
              style={{ fontWeight: 500, color: "#444050" }}
            >
              {CURRENCY.INR.SYMBOL}
              {formatAmount(totalAmount)}
            </span>
          </div>
        </div>
      </div>

      <Pagination
        page={page}
        perPage={perPage}
        totalRecords={totalRecords}
        totalPages={totalPages}
        records={record}
        prevButton={prevButton}
        nextButton={nextButton}
      />
    </div>
  );
};

export default SupplierWisePaymentReport;
