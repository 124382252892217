import AppRoutes from "./routes/AppRoutes";
import { AuthProvider } from "./context/AuthContext";
import "./App.css";
import { Suspense } from "react";
import Spinner from "./theme/Spinner";

function App() {
  return (
    <Suspense fallback={<Spinner isFullScreen={true} />}>
      <AuthProvider>
        <AppRoutes />
      </AuthProvider>
    </Suspense>
  );
}

export default App;
