/* eslint-disable react-hooks/exhaustive-deps */
import moment from "moment";
import { useEffect, useState } from "react";
import { getPortByIdApi } from "../../services/_port";

interface Props {
  portId: string;
}

const ViewPort: React.FC<Props> = ({ portId }) => {
  const [port, setPort] = useState<any>({});

  useEffect(() => {
    if (portId) {
      setPort({});
      getPortDetails();
    }
  }, [portId]);

  const getPortDetails = async () => {
    const result: any = await getPortByIdApi(portId as string);
    if (result.isSuccess) {
      setPort(result?.data);
    }
  };

  return (
    <div className="modal fade" id="viewPort" tabIndex={-1} aria-hidden="true">
      <div className="modal-dialog modal-simple modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-body pt-0 pb-0">
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
            <div className="text-center">
              <h4 className="mb-0">Port Details</h4>
            </div>
            <div className="info-container">
              <div className="pb-4 border-bottom text-capitalize mb-4"></div>
              <ul className="list-unstyled mb-6">
                <li className="mb-2">
                  <span className="h6 me-1">Name:</span>
                  <span>{port?.name}</span>
                </li>
                <li className="mb-2">
                  <span className="h6 me-1">City:</span>
                  <span>{port?.city}</span>
                </li>
                <li className="mb-2">
                  <span className="h6 me-1">State:</span>
                  <span>{port?.state}</span>
                </li>
                <li className="mb-2">
                  <span className="h6 me-1">Country:</span>
                  <span>{port?.country}</span>
                </li>
                <li className="mb-2">
                  <span className="h6 me-1">Status:</span>
                  <span
                    className={`badge ${
                      port?.status ? "bg-label-success" : "bg-label-danger"
                    }`}
                  >
                    {port?.status ? "Active" : "Inactive"}
                  </span>
                </li>
                <li className="mb-2">
                  <span className="h6 me-1">Created At:</span>
                  <span>
                    {port?.created_at
                      ? moment(port?.created_at).format("YYYY-MM-DD HH:mm:ss")
                      : ""}
                  </span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewPort;
