/* eslint-disable react-hooks/exhaustive-deps */
import moment from "moment";
import { useEffect, useState } from "react";
import { getQualitySpecificationsByIdApi } from "../../services/_qualitySpecifications";

interface Props {
  qualitySpecificationId: string;
}

const ViewQualitySpecification: React.FC<Props> = ({
  qualitySpecificationId,
}) => {
  const [qualitySpecification, setQualitySpecification] = useState<any>({});

  useEffect(() => {
    if (qualitySpecificationId) {
      setQualitySpecification({});
      getQualitySpecificationDetails();
    }
  }, [qualitySpecificationId]);

  const getQualitySpecificationDetails = async () => {
    const result: any = await getQualitySpecificationsByIdApi(
      qualitySpecificationId as string
    );
    if (result.isSuccess) {
      setQualitySpecification(result?.data);
    }
  };

  return (
    <div
      className="modal fade"
      id="viewQualitySpecification"
      tabIndex={-1}
      aria-hidden="true"
    >
      <div className="modal-dialog modal-simple modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-body pt-0 pb-0">
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
            <div className="text-center">
              <h4 className="mb-0">Quality Specification Details</h4>
            </div>
            <div className="info-container">
              <div className="pb-4 border-bottom text-capitalize mb-4"></div>
              <ul className="list-unstyled mb-6">
                <li className="mb-2">
                  <span className="h6 me-1">Name:</span>
                  <span>{qualitySpecification?.name}</span>
                </li>
                <li className="mb-2">
                  <span className="h6 me-1">Product:</span>
                  <span>{qualitySpecification?.product?.name}</span>
                </li>
                <li className="mb-2">
                  <span className="h6 me-1">Description:</span>
                  <span>{qualitySpecification?.description}</span>
                </li>
                <li className="mb-2">
                  <span className="h6 me-1">Status:</span>
                  <span
                    className={`badge ${
                      qualitySpecification?.status
                        ? "bg-label-success"
                        : "bg-label-danger"
                    }`}
                  >
                    {qualitySpecification?.status ? "Active" : "Inactive"}
                  </span>
                </li>
                <li className="mb-2">
                  <span className="h6 me-1">Created At:</span>
                  <span>
                    {qualitySpecification?.created_at
                      ? moment(qualitySpecification?.created_at).format(
                          "YYYY-MM-DD HH:mm:ss"
                        )
                      : ""}
                  </span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewQualitySpecification;
