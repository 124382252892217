import * as Yup from "yup";
import { CURRENCY } from "../../../../components/Constants";

export const containerInitialValues = {
  container_no: "",
  lot_no: "",
  lot_label_seal: "",
  line_seal_no: "",
  self_seal_id: "",
  sticker_no: "",
  vehicle_no: "",
  size: "",
  quantity: 0,
  no_of_bags: 0,
  packing_type_id: "",
  total_net_weight: 0,
  net_weight_unit_id: "",
  total_gross_weight: 0,
  gross_weight_unit_id: "",
  remark: "",
};

export const otherDocumentInitialValues = {
  document_id: "",
  files: [],
  remove_files: [],
};

export const salesInvoiceInitialValues = {
  sales_contract_id: "",
  buyer_id: "",
  product_id: "",
  invoice_no: "",
  invoice_date: null,
  status: "",
  logistic_id: "",
  cha_id: "",
  origin_port_id: "",
  etd: null,
  destination_port_id: "",
  eta: null,
  currency: CURRENCY.USD.NAME,
  price: 0,
  quantity: 0,
  quantity_unit_id: "",
  total_amount: 0,
  igst_percentage: "",
  exchange_rate: 0,
  total_taxable_amount_inr: 0,
  total_igst_amount_inr: 0,
  commission_rate: 0,
  commission_amount: 0,
  packing_type_id: "",
  container_size: "",
  packing_net_weight: 0,
  net_weight_unit_id: "",
  packing_gross_weight: 0,
  gross_weight_unit_id: "",
  no_of_bags: 0,
  total_net_weight: 0,
  total_gross_weight: 0,
  pre_carriage_by: "",
  vessel_no: "",
  delivery_term: "",
  payment_term: "",
  bl_no: "",
  container: [],
  my_documents: [],
  other_documents: [],
  bills: [],
};

export const containerValidationSchema = Yup.object().shape({
  container_no: Yup.string().required("Container no is required"),
  lot_no: Yup.string().nullable(),
  lot_label_seal: Yup.string().nullable(),
  line_seal_no: Yup.string().nullable(),
  self_seal_id: Yup.string().nullable(),
  sticker_no: Yup.string().nullable(),
  vehicle_no: Yup.string().nullable(),
  size: Yup.string().nullable(),
  quantity: Yup.string()
    .nullable()
    .test(
      "is-valid-decimal",
      "Quantity must be a positive value",
      function (value: any) {
        return !(value < 0);
      }
    ),
  no_of_bags: Yup.string()
    .nullable()
    .test(
      "is-valid-decimal",
      "No. of bags must be a positive value",
      function (value: any) {
        return !(value < 0);
      }
    ),
  packing_type_id: Yup.string().nullable(),
  total_net_weight: Yup.string()
    .nullable()
    .test(
      "is-valid-decimal",
      "Total net weight must be a positive value",
      function (value: any) {
        return !(value < 0);
      }
    ),
  net_weight_unit_id: Yup.string().nullable(),
  total_gross_weight: Yup.string()
    .nullable()
    .test(
      "is-valid-decimal",
      "Total gross weight must be a positive value",
      function (value: any) {
        return !(value < 0);
      }
    ),
  gross_weight_unit_id: Yup.string().nullable(),
  remark: Yup.string().nullable(),
});

export const otherDocumentValidationSchema = Yup.object().shape({
  document_id: Yup.string().required("Document is required"),
  files: Yup.array().optional(),
  remove_files: Yup.array().optional(),
});

export const billValidationSchema = Yup.object().shape({
  bill_id: Yup.string().required("Bill is required"),
  files: Yup.array().optional(),
  remove_files: Yup.array().optional(),
});

export const salesInvoiceValidationSchema = Yup.object().shape({
  sales_contract_id: Yup.string().required("Sales contract is required"),
  buyer_id: Yup.string().required("Buyer is required"),
  product_id: Yup.string().required("Product is required"),
  invoice_no: Yup.string().required("Invoice no is required"),
  invoice_date: Yup.date().required("Invoice date is required"),
  status: Yup.string().required("Status is required"),
  logistic_id: Yup.string(),
  cha_id: Yup.string(),
  origin_port_id: Yup.string().optional().nullable(),
  etd: Yup.date().optional().nullable(),
  destination_port_id: Yup.string()
    .optional()
    .nullable()
    .test(
      "origin-destination-different",
      "Origin port and destination port cannot be the same",
      function (value) {
        if (value && this.parent.origin_port_id) {
          return value !== this.parent.origin_port_id;
        }
        return true;
      }
    ),
  eta: Yup.date().optional().nullable(),
  currency: Yup.string().required("Currency is required"),
  price: Yup.number()
    .required("Price is required")
    .test("price-required", "Price is required", function (value: any) {
      return !(value <= 0);
    }),
  quantity: Yup.number()
    .required("Quantity is required")
    .test("quantity-required", "Quantity is required", function (value: any) {
      return !(value <= 0);
    }),
  quantity_unit_id: Yup.string(),
  total_amount: Yup.number().required("Total amount is required"),
  packing_type_id: Yup.string(),
  container_size: Yup.string(),
  packing_net_weight: Yup.string()
    .nullable()
    .test(
      "is-valid-decimal",
      "Packing net weight must be a positive value",
      function (value: any) {
        return !(value < 0);
      }
    ),
  igst_percentage: Yup.string()
    .nullable()
    .test(
      "igst-percentage-required",
      "IGST percentage must be a positive value",
      function (value: any) {
        return !(value < 0);
      }
    ),
  exchange_rate: Yup.string()
    .nullable()
    .test(
      "is-valid-decimal",
      "Exchange rate must be a positive value",
      function (value: any) {
        return !(value < 0);
      }
    ),
  total_taxable_amount_inr: Yup.number(),
  total_igst_amount_inr: Yup.number(),
  commission_rate: Yup.number()
    .nullable()
    .test(
      "is-valid-decimal",
      "Commission rate must be a positive value",
      function (value: any) {
        return !(value < 0);
      }
    ),
  commission_amount: Yup.number(),
  net_weight_unit_id: Yup.string(),
  packing_gross_weight: Yup.string()
    .nullable()
    .test(
      "is-valid-decimal",
      "Packing gross weight must be a positive value",
      function (value: any) {
        return !(value < 0);
      }
    ),
  gross_weight_unit_id: Yup.string(),
  total_net_weight: Yup.string()
    .nullable()
    .test(
      "is-valid-decimal",
      "Total net weight must be a positive value",
      function (value: any) {
        return !(value < 0);
      }
    ),
  total_gross_weight: Yup.string()
    .nullable()
    .test(
      "is-valid-decimal",
      "Total gross weight must be a positive value",
      function (value: any) {
        return !(value < 0);
      }
    ),
  no_of_bags: Yup.string()
    .nullable()
    .test(
      "is-valid-decimal",
      "No. of bags must be a positive value",
      function (value: any) {
        return !(value < 0);
      }
    ),
  pre_carriage_by: Yup.string(),
  vessel_no: Yup.string(),
  delivery_term: Yup.string(),
  payment_term: Yup.string(),
  bl_no: Yup.string(),
  container: Yup.array().of(containerValidationSchema),
  my_documents: Yup.array()
    .of(Yup.string().required("Invalid document selection!"))
    .nullable()
    .optional(),
  other_documents: Yup.array().of(otherDocumentValidationSchema),
  bills: Yup.array().of(billValidationSchema),
});
