import { POST } from "./_config";

export const uploadSingleFileApi = async (body: any) =>
  await POST(`/other/upload-single-file`, body, true);

export const uploadMultipleFileApi = async (body: any) =>
  await POST(`/other/upload-multiple-file`, body, true);

const OtherService = {
  uploadSingleFileApi,
  uploadMultipleFileApi,
};

export default OtherService;
