import { GET, POST, PUT } from "./_config";

export const chaListApi = async (query: string) =>
  await GET(`/cha?${query || ""}`, true);

export const getAllChaApi = async () => await GET(`/cha/list`, true);

export const createChaApi = async (body: any) => await POST(`/cha`, body, true);

export const getChaByIdApi = async (chaId: string) =>
  await GET(`/cha/id/${chaId}`, true);

export const updateChaApi = async (chaId: string, body: any) =>
  await PUT(`/cha?id=${chaId}`, body, true);

export const updateChaStatusApi = async (body: any) =>
  await POST(`/cha/update-status`, body, true);

const ChaService = {
  chaListApi,
  getAllChaApi,
  createChaApi,
  getChaByIdApi,
  updateChaApi,
  updateChaStatusApi,
};

export default ChaService;
