/* eslint-disable react-hooks/exhaustive-deps */
import {
  updateLogisticApi,
  getLogisticByIdApi,
} from "../../services/_logistic";
import * as Yup from "yup";
import { useEffect, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { Link, useNavigate, useParams } from "react-router-dom";
import { errorToast, successToast } from "../../components/Toast";
import {
  getCities,
  getCountries,
  getStateCityList,
  getStates,
} from "../../components/Helper";
import CountryStateCityField from "../other/CountryStateCityField";

const EditLogistic = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [updateLoading, setUpdateLoading] = useState<boolean>(false);
  const countryList = getCountries();
  const [stateList, setStateList] = useState<any>([]);
  const [cityList, setCityList] = useState<any>([]);

  const handleCountryChange = (country: any) => {
    setStateList([]);
    setCityList([]);
    const allStates = getStates(country);
    setStateList(allStates);
  };

  const handleStateChange = (state: any) => {
    setCityList([]);
    const cities = getCities(state);
    setCityList(cities);
  };

  const setStateCityList = async (countryName: any, stateName: any) => {
    const { allStates, allCities } = await getStateCityList(
      countryName,
      stateName
    );
    setStateList(allStates);
    setCityList(allCities);
  };

  useEffect(() => {
    if (!id) {
      navigate("/logistic-list");
    } else {
      getLogisticDetails();
    }
  }, []);

  const getLogisticDetails = async () => {
    const result: any = await getLogisticByIdApi(id as string);
    setStateCityList(result?.data?.country, result?.data?.state);

    if (result.isSuccess) {
      setInitialValues({
        name: result?.data?.name,
        email: result?.data?.email,
        phone_no: result?.data?.phone_no,
        address: result?.data?.address,
        country: result?.data?.country,
        state: result?.data?.state,
        state_code: result?.data?.state_code,
        city: result?.data?.city,
        pin_code: result?.data?.pin_code,
        gst_no: result?.data?.gst_no,
        tan_no: result?.data?.tan_no,
        udhyam_no: result?.data?.udhyam_no,
        pan_no: result?.data?.pan_no,
        status: result?.data?.status?.toString(),
      });
    }
  };

  const [initialValues, setInitialValues] = useState({
    name: "",
    email: "",
    phone_no: "",
    address: "",
    country: "",
    state: "",
    state_code: "",
    city: "",
    pin_code: "",
    gst_no: "",
    tan_no: "",
    udhyam_no: "",
    pan_no: "",
    status: undefined,
  });

  const createLogisticValidationSchema = Yup.object().shape({
    name: Yup.string().required("Name is required"),
    email: Yup.string().required("Email is required"),
    status: Yup.string().required("Status is required"),
  });

  const handleSubmit = async (values: any) => {
    setUpdateLoading(true);
    values.status = values.status === "true" ? true : false;
    const result: any = await updateLogisticApi(id as string, values);
    if (result.isSuccess) {
      setUpdateLoading(false);
      successToast(result.message);
      navigate("/logistic-list");
    } else {
      setUpdateLoading(false);
      errorToast(result.message);
    }
    return;
  };

  return (
    <>
      <div className="card">
        <div className="card-header pb-0">
          <div>
            <h5>Edit Logistic</h5>
          </div>
        </div>
        <div className="card-body">
          <Formik
            enableReinitialize={true}
            initialValues={initialValues}
            validationSchema={createLogisticValidationSchema}
            onSubmit={handleSubmit}
          >
            {({ values, handleChange, setFieldValue }) => {
              return (
                <Form className="dt_adv_search">
                  <div className="row">
                    <div className="col-12">
                      <div className="row g-3">
                        <div className="col-12 col-sm-6 col-lg-4">
                          <label
                            htmlFor="name"
                            className="form-label"
                            style={{ fontSize: "0.9375rem" }}
                          >
                            Name: <span className="text-danger">*</span>
                          </label>
                          <Field
                            id="name"
                            name="name"
                            type="text"
                            className="form-control"
                            placeholder="Enter name"
                            onChange={handleChange}
                          />
                          <ErrorMessage
                            name="name"
                            className="text-danger"
                            component="div"
                          />
                        </div>
                        <div className="col-12 col-sm-6 col-lg-4">
                          <label
                            htmlFor="email"
                            className="form-label"
                            style={{ fontSize: "0.9375rem" }}
                          >
                            Email: <span className="text-danger">*</span>
                          </label>
                          <Field
                            id="email"
                            name="email"
                            type="email"
                            className="form-control"
                            placeholder="Enter email"
                            onChange={handleChange}
                          />
                          <ErrorMessage
                            name="email"
                            className="text-danger"
                            component="div"
                          />
                        </div>
                        <div className="col-12 col-sm-6 col-lg-4">
                          <label
                            htmlFor="phone_no"
                            className="form-label"
                            style={{ fontSize: "0.9375rem" }}
                          >
                            Phone No:
                          </label>
                          <Field
                            id="phone_no"
                            name="phone_no"
                            type="text"
                            className="form-control"
                            placeholder="Enter phone No"
                            onChange={handleChange}
                          />
                        </div>
                        <div className="col-12 col-sm-6 col-lg-4">
                          <label
                            htmlFor="pan_no"
                            className="form-label"
                            style={{ fontSize: "0.9375rem" }}
                          >
                            PAN No:
                          </label>
                          <Field
                            id="pan_no"
                            name="pan_no"
                            type="text"
                            className="form-control"
                            placeholder="Enter PAN No"
                            onChange={handleChange}
                          />
                        </div>
                        <div className="col-12 col-sm-6 col-lg-4">
                          <label
                            htmlFor="gst_no"
                            className="form-label"
                            style={{ fontSize: "0.9375rem" }}
                          >
                            GST No:
                          </label>
                          <Field
                            id="gst_no"
                            name="gst_no"
                            type="text"
                            className="form-control"
                            placeholder="Enter GST No"
                            onChange={handleChange}
                          />
                        </div>
                        <div className="col-12 col-sm-6 col-lg-4">
                          <label
                            htmlFor="tan_no"
                            className="form-label"
                            style={{ fontSize: "0.9375rem" }}
                          >
                            TAN No:
                          </label>
                          <Field
                            id="tan_no"
                            name="tan_no"
                            type="text"
                            className="form-control"
                            placeholder="Enter TAN No"
                            onChange={handleChange}
                          />
                        </div>
                        <div className="col-12 col-sm-6 col-lg-4">
                          <label
                            htmlFor="udhyam_no"
                            className="form-label"
                            style={{ fontSize: "0.9375rem" }}
                          >
                            UDHYAM No:
                          </label>
                          <Field
                            id="udhyam_no"
                            name="udhyam_no"
                            type="text"
                            className="form-control"
                            placeholder="Enter UDHYAM No"
                            onChange={handleChange}
                          />
                        </div>

                        <CountryStateCityField
                          countryList={countryList}
                          stateList={stateList}
                          cityList={cityList}
                          values={values}
                          setFieldValue={setFieldValue}
                          handleCountryChange={handleCountryChange}
                          handleStateChange={handleStateChange}
                        />

                        <div className="col-12 col-sm-6 col-lg-4">
                          <label
                            htmlFor="state_code"
                            className="form-label"
                            style={{ fontSize: "0.9375rem" }}
                          >
                            State Code:
                          </label>
                          <Field
                            id="state_code"
                            name="state_code"
                            type="text"
                            className="form-control"
                            placeholder="Enter State Code"
                            onChange={handleChange}
                          />
                        </div>
                        <div className="col-12 col-sm-6 col-lg-4">
                          <label
                            htmlFor="pin_code"
                            className="form-label"
                            style={{ fontSize: "0.9375rem" }}
                          >
                            Pin Code:
                          </label>
                          <Field
                            id="pin_code"
                            name="pin_code"
                            type="text"
                            className="form-control"
                            placeholder="Enter Pin Code"
                            onChange={handleChange}
                          />
                        </div>
                        <div className="col-12 col-sm-6 col-lg-4">
                          <label
                            htmlFor="status"
                            className="form-label"
                            style={{ fontSize: "0.9375rem" }}
                          >
                            Status: <span className="text-danger">*</span>
                          </label>
                          <select
                            id="status"
                            name="status"
                            className="form-select"
                            onChange={handleChange}
                            value={values?.status}
                          >
                            <option value="">Select Status</option>
                            <option value="true">Active</option>
                            <option value="false">Inactive</option>
                          </select>
                          <ErrorMessage
                            name="status"
                            className="text-danger"
                            component="div"
                          />
                        </div>
                        <div className="col-12">
                          <label
                            htmlFor="address"
                            className="form-label"
                            style={{ fontSize: "0.9375rem" }}
                          >
                            Address:
                          </label>
                          <Field
                            id="address"
                            name="address"
                            type="text"
                            className="form-control"
                            placeholder="Enter Address"
                            onChange={handleChange}
                            as={"textarea"}
                            rows={2}
                          />
                        </div>
                        <div className="col-12">
                          <button
                            type="submit"
                            className="btn btn-primary"
                            disabled={updateLoading}
                          >
                            {updateLoading && (
                              <span
                                className="spinner-border spinner-border-sm me-2"
                                role="status"
                                aria-hidden="true"
                              ></span>
                            )}
                            Submit
                          </button>
                          <Link
                            to={"/logistic-list"}
                            type="button"
                            className="btn btn-label-github ms-5"
                          >
                            Back
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </Form>
              );
            }}
          </Formik>
        </div>
      </div>
    </>
  );
};

export default EditLogistic;
