import * as Yup from "yup";
import { CURRENCY } from "../../../../components/Constants";

export const qualitySpecificationsInitialValues = {
  quality_specification_id: "",
  value: "",
};

export const documentsInitialValues = {
  document_id: "",
  value: "",
};

export const salesContractInitialValues = {
  contract_no: "",
  reference_no: "",
  contract_date: null,
  buyer_id: "",
  broker_id: "",
  status: "",
  product_id: "",
  currency: CURRENCY.USD.NAME,
  price: 0,
  quantity: 0,
  quantity_unit_id: "",
  total_amount: 0,
  container_load: "",
  packing_type_id: "",
  packing_net_weight: 0,
  packing_gross_weight: 0,
  packing_unit_id: "",
  marking: "",
  marking_files: [],
  final_destination: "",
  port_of_loading: "",
  port_of_discharge: "",
  shipment: "",
  trans_shipment: "",
  partial_shipment: "",
  payment_terms: "",
  weight_and_quality: "",
  arbitration: "",
  is_digital_signature: null,
  quality_specification: [],
  document: [],
  exporter_bank_id: "",
  buyer_bank_id: "",
};

export const qualitySpecificationsValidationSchema = Yup.object().shape({
  quality_specification_id: Yup.string().required(
    "Quality specification is required"
  ),
  value: Yup.string().required("Value is required"),
});

export const documentsValidationSchema = Yup.object().shape({
  document_id: Yup.string().required("Document is required"),
  value: Yup.string().required("Value is required"),
});

export const salesContractValidationSchema = Yup.object().shape({
  contract_no: Yup.string().required("Contract no is required"),
  reference_no: Yup.string(),
  contract_date: Yup.date().required("Contract date is required"),
  buyer_id: Yup.string().required("Buyer is required"),
  broker_id: Yup.string(),
  status: Yup.string().required("Status is required"),
  product_id: Yup.string().required("Product is required"),
  currency: Yup.string().required("Currency is required"),
  price: Yup.number()
    .required("Price is required")
    .test("price-required", "Price is required", function (value: any) {
      return !(value <= 0);
    }),
  quantity: Yup.number()
    .required("Quantity is required")
    .test("quantity-required", "Quantity is required", function (value: any) {
      return !(value <= 0);
    }),
  quantity_unit_id: Yup.string(),
  total_amount: Yup.number().required("Total amount is required"),
  container_load: Yup.string(),
  packing_type_id: Yup.string(),
  packing_net_weight: Yup.string()
    .nullable()
    .test(
      "is-valid-decimal",
      "Packing net weight must be a valid decimal with up to 4 decimal places",
      (value) => {
        if (!value) return false;
        return /^\d+(\.\d{1,4})?$/.test(value);
      }
    ),
  packing_gross_weight: Yup.string()
    .nullable()
    .test(
      "is-valid-decimal",
      "Packing gross weight must be a valid decimal with up to 4 decimal places",
      (value) => {
        if (!value) return false;
        return /^\d+(\.\d{1,4})?$/.test(value);
      }
    ),
  packing_unit_id: Yup.string(),
  marking: Yup.string(),
  marking_files: Yup.array().optional(),
  final_destination: Yup.string(),
  port_of_loading: Yup.string(),
  port_of_discharge: Yup.string(),
  shipment: Yup.string(),
  trans_shipment: Yup.string(),
  partial_shipment: Yup.string(),
  payment_terms: Yup.string(),
  weight_and_quality: Yup.string(),
  arbitration: Yup.string(),
  is_digital_signature: Yup.string().nullable(),
  quality_specification: Yup.array().of(qualitySpecificationsValidationSchema),
  document: Yup.array().of(documentsValidationSchema),
  exporter_bank_id: Yup.string(),
  buyer_bank_id: Yup.string(),
});
