export const handleMouseEnter = () => {
  const elements = document.getElementsByClassName("my-html");
  for (let i = 0; i < elements.length; i++) {
    if (elements[i].classList.contains("layout-menu-collapsed")) {
      elements[i].classList.add("layout-menu-hover");
    }
  }
};

export const handleMouseLeave = () => {
  const elements = document.getElementsByClassName("my-html");
  for (let i = 0; i < elements.length; i++) {
    if (elements[i].classList.contains("layout-menu-collapsed")) {
      elements[i].classList.remove("layout-menu-hover");
    }
  }
};

export const handleSidebarClick = (e: any) => {
  const elements = document.getElementsByClassName("my-html");
  for (let i = 0; i < elements.length; i++) {
    if (elements[i].classList.contains("layout-menu-collapsed")) {
      elements[i].classList.remove("layout-menu-collapsed");
    } else {
      elements[i].classList.add("layout-menu-collapsed");
    }
  }
};

export const handleNavbarClick = (e: any) => {
  const elements = document.getElementsByClassName("my-html");
  for (let i = 0; i < elements.length; i++) {
    if (elements[i].classList.contains("layout-menu-expanded")) {
      elements[i].classList.remove("layout-menu-expanded");
    } else {
      elements[i].classList.add("layout-menu-expanded");
    }
  }
};

export const allBankRoutes = [
  "bank-list",
  "create-bank",
  "edit-bank",
  "my-bank-list",
  "create-my-bank",
  "edit-my-bank",
];

export const allReportsRouts = [
  "buyer-wise-sales-report",
  "product-wise-sales-report",
  "supplier-wise-purchase-report",
  "product-wise-purchase-report",
  "profit-and-loss-report",
  "buyer-wise-payment-report",
  "supplier-wise-payment-report",
  "logistic-wise-payment-report",
  "cha-wise-payment-report",
  "expense-report",
  "broker-wise-payment-report",
];

export const allSalesRoutes = [
  "sales-contract-list",
  "create-sales-contract",
  "edit-sales-contract",
  "view-sales-contract",
  "sales-invoice-list",
  "create-sales-invoice",
  "edit-sales-invoice",
  "view-sales-invoice",
  "miscellaneous-sales-list",
  "view-miscellaneous-sales",
  "create-miscellaneous-sales",
  "edit-miscellaneous-sales",
];
