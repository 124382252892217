/* eslint-disable react-hooks/exhaustive-deps */
import { Link, useNavigate, useParams } from "react-router-dom";
import * as Yup from "yup";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { useEffect, useState } from "react";
import {
  getSealNumberByIdApi,
  updateSealNumberApi,
} from "../../services/_sealNumber";
import { errorToast, successToast } from "../../components/Toast";

const createSealNumberInitialValues = {
  prefix: "",
  suffix: "",
};

const EditSealNumber = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [updateLoading, setUpdateLoading] = useState<boolean>(false);
  const [initialValues, setInitialValues] = useState(
    createSealNumberInitialValues
  );

  useEffect(() => {
    if (!id) {
      navigate("/seal-number-list");
    } else {
      getSealNumberDetails();
    }
  }, []);

  const getSealNumberDetails = async () => {
    const result: any = await getSealNumberByIdApi(id as string);
    if (result.isSuccess) {
      setInitialValues({
        prefix: result?.data?.prefix,
        suffix: result?.data?.suffix,
      });
    }
  };

  const createSealNumberValidationSchema = Yup.object().shape({
    prefix: Yup.string().required("Prefix is required"),
    suffix: Yup.number()
      .typeError("Enter a valid seal number")
      .required("Seal Number is required"),
  });

  const handleSubmit = async (values: any, { resetForm }: any) => {
    setUpdateLoading(true);
    values.prefix = values.prefix.toUpperCase();

    const result: any = await updateSealNumberApi(id as string, values);
    if (result.isSuccess) {
      successToast(result.message);
      resetForm({ values: createSealNumberInitialValues });
      navigate("/seal-number-list");
    } else {
      errorToast(result.message);
    }
    setUpdateLoading(false);
    return;
  };

  return (
    <>
      <div className="card">
        <div className="card-header pb-0">
          <div>
            <h5>Edit Seal Number</h5>
          </div>
        </div>
        <div className="card-body">
          <Formik
            enableReinitialize={true}
            initialValues={initialValues}
            validationSchema={createSealNumberValidationSchema}
            onSubmit={handleSubmit}
          >
            {({ handleChange }) => {
              return (
                <Form className="dt_adv_search">
                  <div className="row">
                    <div className="col-12">
                      <div className="row g-3">
                        <div className="col-12 col-sm-6 col-lg-4">
                          <label
                            htmlFor="prefix"
                            className="form-label"
                            style={{ fontSize: "0.9375rem" }}
                          >
                            prefix: <span className="text-danger">*</span>
                          </label>
                          <Field
                            id="prefix"
                            name="prefix"
                            type="text"
                            className="form-control"
                            placeholder="Enter Prefix No"
                            onChange={(e: any) => {
                              e.target.value = e.target.value.toUpperCase();
                              handleChange(e);
                            }}
                          />
                          <ErrorMessage
                            name="prefix"
                            className="text-danger"
                            component="div"
                          />
                        </div>
                        <div className="col-12 col-sm-6 col-lg-4">
                          <label
                            htmlFor="suffix"
                            className="form-label"
                            style={{ fontSize: "0.9375rem" }}
                          >
                            Seal No:
                          </label>
                          <Field
                            id="suffix"
                            name="suffix"
                            type="text"
                            className="form-control"
                            placeholder="Enter Seal No"
                            onChange={handleChange}
                          />
                          <ErrorMessage
                            name="suffix"
                            className="text-danger"
                            component="div"
                          />
                        </div>
                        <div className="col-12">
                          <button
                            type="submit"
                            className="btn btn-primary"
                            disabled={updateLoading}
                          >
                            {updateLoading && (
                              <span
                                className="spinner-border spinner-border-sm me-2"
                                role="status"
                                aria-hidden="true"
                              ></span>
                            )}
                            Submit
                          </button>
                          <Link
                            to={"/seal-number-list"}
                            type="button"
                            className="btn btn-label-github ms-5"
                          >
                            Back
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </Form>
              );
            }}
          </Formik>
        </div>
      </div>
    </>
  );
};

export default EditSealNumber;
