import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  PDFViewer,
  Font,
} from "@react-pdf/renderer";

Font.register({
  family: "Times New Roman",
  fonts: [
    { src: "/fonts/Times-New-Roman.ttf" },
    { src: "/fonts/Times-New-Roman-Bold.ttf", fontWeight: "bold" },
  ],
});

const styles = StyleSheet.create({
  mainContainer: {
    flexDirection: "row",
  },
  page: {
    paddingHorizontal: 30,
    paddingVertical: 15,
    fontFamily: "Times New Roman",
    // borderWidth: 1,
  },
  fullpage: {
    margin: 7,
    // borderStyle: "solid",
    // borderWidth: 1,
    // borderColor: "#000000",
    padding: 5,
  },
  section: {
    marginBottom: 10,
  },
  header: {
    fontSize: 11,
    textAlign: "center",
    fontWeight: "bold",
    marginBottom: 20,
    textDecoration: "underline",
  },
  regularFontSize: {
    fontSize: 10,
  },
  boldText: {
    fontWeight: "bold",
  },
  tableRow: {
    flexDirection: "row",
  },
});

const NonGmoCertificateInvoice = () => (
  <PDFViewer width="100%" height="900">
    <Document>
      <Page style={styles.page} size="A4">
        <View style={[styles.fullpage]}>
          {/* HEADER */}
          <View style={styles.mainContainer}>
            <Text
              style={[
                styles.header,
                styles.boldText,
                {
                  flex: 2,
                  width: 200,
                },
              ]}
            >
              CERTIFICATE OF NON-GMO
            </Text>
          </View>

          {/* REPORT No and Date */}
          <View style={styles.section}>
            <View style={styles.mainContainer}>
              <Text
                style={[styles.boldText, styles.regularFontSize, { flex: 1 }]}
              >
                REPORT NO. <Text style={styles.boldText}>ALR/ML/XXXX</Text>
              </Text>
              <Text style={[styles.boldText, styles.regularFontSize]}>
                DATE: <Text style={styles.boldText}>XX.12.2024</Text>
              </Text>
            </View>
          </View>

          {/* CERTIFICATION */}
          <View style={styles.section}>
            <Text
              style={[
                styles.regularFontSize,
                styles.boldText,
                { marginTop: 10 },
              ]}
            >
              IT IS HEREBY CERTIFIED THAT THE PRODUCT DESCRIBED BELOW DOES NOT
              CONTAIN GENETICALLY MODIFIED ORGANISM (GMO)”
            </Text>
          </View>

          {/* TABLE */}
          <View>
            {/* TABLE ROWS */}
            <View style={[styles.tableRow, { border: 1 }]}>
              <Text
                style={[
                  styles.regularFontSize,
                  {
                    padding: 5,
                    // paddingLeft: 5,
                    width: 200,
                    borderRight: 1,
                  },
                  styles.boldText,
                ]}
              >
                INV NO. & DATE
              </Text>
              <Text
                style={[
                  styles.regularFontSize,
                  {
                    padding: 4,
                    width: 20,
                    borderRight: 1,
                    textAlign: "center",
                  },
                  styles.boldText,
                ]}
              >
                :
              </Text>
              <Text
                style={[
                  styles.regularFontSize,
                  {
                    padding: 4,
                    width: 300,
                  },
                ]}
              >
                LE/EXP/24-25/081 DATE : 28/12/2024
              </Text>
            </View>
            {/* 2 */}
            <View style={[styles.tableRow, { borderBottom: 1 }]}>
              <Text
                style={[
                  styles.regularFontSize,
                  {
                    padding: 5,
                    width: 200,
                    borderRight: 1,
                    borderLeft: 1,
                  },
                  styles.boldText,
                ]}
              >
                B/L NOS.
              </Text>
              <Text
                style={[
                  styles.regularFontSize,
                  {
                    padding: 4,
                    width: 20,
                    borderRight: 1,
                    textAlign: "center",
                  },
                  styles.boldText,
                ]}
              >
                :
              </Text>
              <Text
                style={[
                  styles.regularFontSize,
                  {
                    padding: 4,
                    width: 300,
                    borderRight: 1,
                  },
                ]}
              >
                XXX
              </Text>
            </View>
            {/* 3 */}
            <View style={[styles.tableRow, { borderBottom: 1 }]}>
              <Text
                style={[
                  styles.regularFontSize,
                  {
                    padding: 5,
                    width: 200,
                    borderRight: 1,
                    borderLeft: 1,
                    paddingTop: 21,
                  },
                  styles.boldText,
                ]}
              >
                SHIPPER
              </Text>

              <Text
                style={[
                  styles.regularFontSize,
                  {
                    padding: 4,
                    width: 20,
                    borderRight: 1,
                    textAlign: "center",
                    paddingTop: 20,
                  },
                  styles.boldText,
                ]}
              >
                :
              </Text>

              <View
                style={[
                  {
                    flexDirection: "column",
                    width: 300,
                    borderRight: 1,
                    paddingBottom: 4,
                    paddingTop: 4,
                  },
                  styles.regularFontSize,
                ]}
              >
                <Text style={{ paddingLeft: 4 }}>MS LAXMI ENTERPRISE</Text>
                <Text style={{ paddingLeft: 4 }}>
                  PLOT NO. 612, NAGOR ROAD,
                </Text>
                <Text style={{ paddingLeft: 4 }}>GIDC BHUJ, KACHCHH, </Text>
                <Text style={{ paddingLeft: 4 }}>GUJARAT, 370001 (INDIA)</Text>
              </View>
            </View>
            {/* 4 */}
            <View style={[styles.tableRow, { borderBottom: 1 }]}>
              <Text
                style={[
                  styles.regularFontSize,
                  {
                    padding: 5,
                    width: 200,
                    borderRight: 1,
                    borderLeft: 1,

                    paddingTop: 15,
                  },
                  styles.boldText,
                ]}
              >
                CONSIGNEE
              </Text>
              <Text
                style={[
                  styles.regularFontSize,
                  {
                    padding: 4,
                    width: 20,
                    borderRight: 1,
                    textAlign: "center",
                    paddingTop: 14,
                  },
                  styles.boldText,
                ]}
              >
                :
              </Text>
              <View
                style={[
                  {
                    flexDirection: "column",
                    width: 300,
                    borderRight: 1,
                    paddingBottom: 4,
                    paddingTop: 4,
                  },
                  styles.regularFontSize,
                ]}
              >
                <Text style={{ paddingLeft: 4 }}>
                  THE VIGNA TRADERS (PVT) LTD.
                </Text>
                <Text style={{ paddingLeft: 4 }}>NO 52, 4TH CROSS STREET,</Text>
                <Text style={{ paddingLeft: 4 }}>COLOMBO 11, SRI LANKA</Text>
              </View>
            </View>
            {/* 5 */}
            <View style={[styles.tableRow, { borderBottom: 1 }]}>
              <Text
                style={[
                  styles.regularFontSize,
                  {
                    padding: 5,
                    width: 200,
                    borderRight: 1,
                    borderLeft: 1,

                    paddingTop: 15,
                  },
                  styles.boldText,
                ]}
              >
                NOTIFY PARTY
              </Text>
              <Text
                style={[
                  styles.regularFontSize,
                  {
                    padding: 4,
                    width: 20,
                    borderRight: 1,
                    textAlign: "center",
                    paddingTop: 14,
                  },
                  styles.boldText,
                ]}
              >
                :
              </Text>
              <View
                style={[
                  {
                    flexDirection: "column",
                    width: 300,
                    borderRight: 1,
                    paddingBottom: 4,
                    paddingTop: 4,
                  },
                  styles.regularFontSize,
                ]}
              >
                <Text style={{ paddingLeft: 4 }}>
                  THE VIGNA TRADERS (PVT) LTD.
                </Text>
                <Text style={{ paddingLeft: 4 }}>NO 52, 4TH CROSS STREET,</Text>
                <Text style={{ paddingLeft: 4 }}>COLOMBO 11, SRI LANKA</Text>
              </View>
            </View>
            {/* 6 */}
            <View style={[styles.tableRow, { borderBottom: 1 }]}>
              <Text
                style={[
                  styles.regularFontSize,
                  {
                    padding: 5,
                    width: 200,
                    borderRight: 1,
                    borderLeft: 1,
                  },
                  styles.boldText,
                ]}
              >
                PORT OF LOADING
              </Text>
              <Text
                style={[
                  styles.regularFontSize,
                  {
                    padding: 4,
                    width: 20,
                    borderRight: 1,
                    textAlign: "center",
                  },
                  styles.boldText,
                ]}
              >
                :
              </Text>
              <Text
                style={[
                  styles.regularFontSize,
                  {
                    padding: 4,
                    width: 300,
                    borderRight: 1,
                  },
                ]}
              >
                CHENNAI, INDIA
              </Text>
            </View>
            {/* 7 */}
            <View style={[styles.tableRow, { borderBottom: 1 }]}>
              <Text
                style={[
                  styles.regularFontSize,
                  {
                    padding: 5,
                    width: 200,
                    borderRight: 1,
                    borderLeft: 1,
                  },
                  styles.boldText,
                ]}
              >
                PORT OF DESTINATION
              </Text>
              <Text
                style={[
                  styles.regularFontSize,
                  {
                    padding: 4,
                    width: 20,
                    borderRight: 1,
                    textAlign: "center",
                  },
                  styles.boldText,
                ]}
              >
                :
              </Text>
              <Text
                style={[
                  styles.regularFontSize,
                  {
                    padding: 4,
                    width: 300,
                    borderRight: 1,
                  },
                ]}
              >
                COLOMBO, SRI LANKA
              </Text>
            </View>
            {/*8 */}
            <View style={[styles.tableRow, { borderBottom: 1 }]}>
              <Text
                style={[
                  styles.regularFontSize,
                  {
                    padding: 5,
                    width: 200,
                    borderRight: 1,
                    borderLeft: 1,
                  },
                  styles.boldText,
                ]}
              >
                VESSEL NAME
              </Text>
              <Text
                style={[
                  styles.regularFontSize,
                  {
                    padding: 4,
                    width: 20,
                    borderRight: 1,
                    textAlign: "center",
                  },
                  styles.boldText,
                ]}
              >
                :
              </Text>
              <Text
                style={[
                  styles.regularFontSize,
                  {
                    padding: 4,
                    width: 300,
                    borderRight: 1,
                  },
                ]}
              >
                XXX
              </Text>
            </View>
            {/* 9 */}
            <View style={[styles.tableRow, { borderBottom: 1 }]}>
              <Text
                style={[
                  styles.regularFontSize,
                  {
                    padding: 5,
                    width: 200,
                    borderRight: 1,
                    borderLeft: 1,
                  },
                  styles.boldText,
                ]}
              >
                CARGO DESCRIPTION
              </Text>
              <Text
                style={[
                  styles.regularFontSize,
                  {
                    padding: 4,
                    width: 20,
                    borderRight: 1,
                    textAlign: "center",
                  },
                  styles.boldText,
                ]}
              >
                :
              </Text>
              <Text
                style={[
                  styles.regularFontSize,
                  {
                    padding: 4,
                    width: 300,
                    borderRight: 1,
                  },
                ]}
              >
                INDIAN PARBOILED RICE, GR11
              </Text>
            </View>
            {/* 10 */}
            <View style={[styles.tableRow, { borderBottom: 1 }]}>
              <Text
                style={[
                  styles.regularFontSize,
                  {
                    padding: 5,
                    width: 200,
                    borderRight: 1,
                    borderLeft: 1,

                    paddingTop: 15,
                  },
                  styles.boldText,
                ]}
              >
                QUANTITY
              </Text>
              <Text
                style={[
                  styles.regularFontSize,
                  {
                    padding: 4,
                    width: 20,
                    borderRight: 1,
                    textAlign: "center",
                    paddingTop: 14,
                  },
                  styles.boldText,
                ]}
              >
                :
              </Text>
              <View
                style={[
                  {
                    flexDirection: "column",
                    width: 300,
                    borderRight: 1,
                    paddingBottom: 4,
                    paddingTop: 4,
                  },
                  styles.regularFontSize,
                ]}
              >
                <Text style={{ paddingLeft: 4 }}>4420 BAGS</Text>
                <Text style={{ paddingLeft: 4 }}>NET WEIGHT: 132.600 MTS</Text>
                <Text style={{ paddingLeft: 4 }}>
                  GROSS WEIGHT: 133.042 MTS
                </Text>
              </View>
            </View>
            {/* 11 */}
            <View style={[styles.tableRow, { borderBottom: 1 }]}>
              <Text
                style={[
                  styles.regularFontSize,
                  {
                    padding: 5,
                    width: 200,
                    borderRight: 1,
                    borderLeft: 1,

                    paddingTop: 9,
                  },
                  styles.boldText,
                ]}
              >
                PACKING
              </Text>
              <Text
                style={[
                  styles.regularFontSize,
                  {
                    padding: 4,
                    width: 20,
                    borderRight: 1,
                    textAlign: "center",
                    paddingTop: 8,
                  },
                  styles.boldText,
                ]}
              >
                :
              </Text>
              <Text
                style={[
                  styles.regularFontSize,
                  {
                    padding: 4,
                    width: 300,
                    borderRight: 1,
                  },
                ]}
              >
                (PACKED IN NEW PP BAGS) NET 30 KGS & GROSS 30.1 KGS EACH BAG
              </Text>
            </View>
            {/* 12 */}
            <View style={[styles.tableRow, { borderBottom: 1 }]}>
              <Text
                style={[
                  styles.regularFontSize,
                  {
                    padding: 5,
                    width: 200,
                    borderRight: 1,
                    borderLeft: 1,

                    paddingTop: 5,
                  },
                  styles.boldText,
                ]}
              >
                HS CODE
              </Text>
              <Text
                style={[
                  styles.regularFontSize,
                  {
                    padding: 4,
                    width: 20,
                    borderRight: 1,
                    textAlign: "center",
                  },
                  styles.boldText,
                ]}
              >
                :
              </Text>
              <Text
                style={[
                  styles.regularFontSize,
                  {
                    padding: 5,
                    width: 300,
                    borderRight: 1,
                  },
                ]}
              >
                1006.30.29
              </Text>
            </View>
            {/* 13 */}
            <View style={[styles.tableRow, { borderBottom: 1 }]}>
              <Text
                style={[
                  styles.regularFontSize,
                  {
                    padding: 5,
                    width: 200,
                    borderRight: 1,
                    borderLeft: 1,

                    paddingTop: 29,
                  },
                  styles.boldText,
                ]}
              >
                CONTAINER NOS
              </Text>
              <Text
                style={[
                  styles.regularFontSize,
                  {
                    padding: 4,
                    width: 20,
                    borderRight: 1,
                    textAlign: "center",
                    paddingTop: 28,
                  },
                  styles.boldText,
                ]}
              >
                :
              </Text>
              <View
                style={[
                  {
                    flexDirection: "column",
                    width: 300,
                    borderRight: 1,
                    padding: 4,
                  },
                  styles.regularFontSize,
                ]}
              >
                <Text style={{ paddingLeft: 4, paddingBottom: 2 }}>
                  {`\u2022`} X
                </Text>
                <Text style={{ paddingLeft: 4, paddingBottom: 2 }}>
                  {`\u2022`} X
                </Text>
                <Text style={{ paddingLeft: 4, paddingBottom: 2 }}>
                  {`\u2022`} X
                </Text>
                <Text style={{ paddingLeft: 4, paddingBottom: 2 }}>
                  {`\u2022`} X
                </Text>
                <Text style={{ paddingLeft: 4, paddingBottom: 2 }}>
                  {`\u2022`} X
                </Text>
              </View>
            </View>
          </View>

          {/* FINAL CERTIFICATION */}
          <View style={(styles.section, { marginTop: 10, marginBottom: 20 })}>
            <Text style={[styles.regularFontSize]}>
              THIS CERTIFICATE REFLECTS OUR FINDINGS AT THE TIME, DATE AND PLACE
              OF INSPECTION ONLY AND DOES NOT REFER TO ANY OTHER MATTER.
            </Text>
            <Text style={[styles.regularFontSize, styles.boldText]}>
              “ISSUED WITHOUT PREJUDICE”
            </Text>
          </View>

          {/* SIGNATURE */}
          <View style={styles.section}>
            <Text
              style={[
                styles.regularFontSize,
                styles.boldText,
                { paddingLeft: 192, marginBottom: 40, marginTop: 20 },
              ]}
            >
              For, ACCURATE UNIVERSAL LABORATORIES PRIVATE LIMITED
            </Text>
            <Text
              style={[
                styles.regularFontSize,
                styles.boldText,
                { paddingLeft: 380 },
              ]}
            >
              AUTHORISED SIGNATORY
            </Text>
          </View>
        </View>
      </Page>
    </Document>
  </PDFViewer>
);

export default NonGmoCertificateInvoice;
