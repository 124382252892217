import React from "react";
import "spinkit/spinkit.css";

const Spinner: React.FC<{ isFullScreen?: boolean }> = ({ isFullScreen }) => {
  return (
    <div
      className={`d-flex justify-content-center align-items-center `}
      style={{
        position: isFullScreen ? "fixed" : "absolute",
        height: isFullScreen ? "100vh" : "100%",
        width: "100%",
        top: 0,
        left: 0,
        backgroundColor: "rgba(255, 255, 255, 0.8)",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        zIndex: 10,
      }}
    >
      <div className={`sk-circle sk-primary`}>
        <div className="sk-circle-dot"></div>
        <div className="sk-circle-dot"></div>
        <div className="sk-circle-dot"></div>
        <div className="sk-circle-dot"></div>
        <div className="sk-circle-dot"></div>
        <div className="sk-circle-dot"></div>
        <div className="sk-circle-dot"></div>
        <div className="sk-circle-dot"></div>
        <div className="sk-circle-dot"></div>
        <div className="sk-circle-dot"></div>
        <div className="sk-circle-dot"></div>
        <div className="sk-circle-dot"></div>
      </div>
    </div>
  );
};

export default Spinner;
