import { getToken, useAuth } from "../../context/AuthContext";
import { Link, useNavigate } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { loginApi } from "../../services/_auth";
import { errorToast } from "../../components/Toast";
import { useEffect, useState } from "react";
import { COMPANY_NAME } from "../../components/Constants";
import Spinner from "../../theme/Spinner";

const Login = () => {
  const { login } = useAuth();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const token = getToken();
    if (token) {
      navigate("/dashboard");
    }
  });

  const loginValidationSchema = Yup.object().shape({
    email: Yup.string()
      .email("Invalid email format")
      .required("Email is required"),
    password: Yup.string().required("Password is required"),
  });

  const loginInitialValues = {
    email: "",
    password: "",
  };

  const handleSubmit = async (values: any) => {
    setLoading(true);
    const result: any = await loginApi(values);
    if (!result.isSuccess) {
      errorToast(result.message);
      setLoading(false);
      return;
    } else {
      const mockToken = result?.data?.token;
      login(mockToken, result?.data?.permissions || [], result?.data?.user);
      navigate("/dashboard");
      setLoading(false);
    }
    setLoading(false);
  };

  return (
    <div className="container-xxl">
      <div className="authentication-wrapper authentication-basic container-p-y">
        <div className="authentication-inner py-6">
          <div className="card">
            {loading && <Spinner isFullScreen={true} />}

            <div className="card-body">
              <div className="app-brand justify-content-center mb-6">
                <Link to="#" className="app-brand-link">
                  <span
                    className="app-brand-logo demo"
                    style={{ alignItems: "unset !important", height: "44px" }}
                  >
                    <img src="/img/logo/ee-main.svg" alt={COMPANY_NAME} />
                  </span>
                  <span className="app-brand-text demo text-heading fw-bold">
                    {COMPANY_NAME}
                  </span>
                </Link>
              </div>

              <Formik
                initialValues={loginInitialValues}
                validationSchema={loginValidationSchema}
                onSubmit={handleSubmit}
              >
                {({ handleChange }) => {
                  return (
                    <Form className="mb-4">
                      <div className="mb-6">
                        <label htmlFor="email" className="form-label">
                          Email
                        </label>
                        <Field
                          type="email"
                          onChange={handleChange}
                          className="form-control"
                          id="email"
                          name="email"
                          placeholder="Enter your email"
                        />
                        <ErrorMessage
                          name="email"
                          className="text-danger"
                          component="div"
                        />
                      </div>
                      <div className="mb-6 form-password-toggle">
                        <label className="form-label" htmlFor="password">
                          Password
                        </label>
                        <div className="input-group input-group-merge">
                          <Field
                            type="password"
                            onChange={handleChange}
                            id="password"
                            className="form-control"
                            name="password"
                            placeholder="&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;"
                            aria-describedby="password"
                          />
                          <span className="input-group-text cursor-pointer">
                            <i className="ti ti-eye-off"></i>
                          </span>
                        </div>
                        <ErrorMessage
                          name="password"
                          className="text-danger"
                          component="div"
                        />
                      </div>
                      <div className="my-8">
                        <button
                          className="btn btn-primary d-grid w-100"
                          type="submit"
                        >
                          Login
                        </button>
                      </div>

                      <div className="text-center">
                        <Link
                          to={"/forget-password"}
                          className="d-flex align-items-center justify-content-center"
                        >
                          <i className="ti ti-chevron-left scaleX-n1-rtl me-1_5"></i>
                          Forget Password?
                        </Link>
                      </div>
                    </Form>
                  );
                }}
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
