import * as Yup from "yup";
import React, { useState } from "react";
import { errorToast, successToast } from "../Toast";
import { Field, Form, Formik, ErrorMessage } from "formik";
import CountryStateCityField from "../../pages/other/CountryStateCityField";
import { getCities, getCountries, getStates } from "../../components/Helper";
import { createPortApi } from "../../services/_port";

const createPortInitialValues = {
  name: "",
  country: "",
  state: "",
  city: "",
  status: "",
};

interface AddPortModalProps {
  addPortModal: boolean;
  closeAddPortModal: () => void;
  onPortCreated: any;
}
const createPortValidationSchema = Yup.object().shape({
  name: Yup.string().required("Name is required"),
  country: Yup.string(),
  state: Yup.string(),
  city: Yup.string(),
  status: Yup.string().required("Status is required"),
});

const AddPortModal: React.FC<AddPortModalProps> = ({
  addPortModal,
  closeAddPortModal,
  onPortCreated,
}) => {
  const [submitLoader, setSubmitLoader] = useState(false);
  const countryList = getCountries();
  const [stateList, setStateList] = useState<any>([]);
  const [cityList, setCityList] = useState<any>([]);

  const handleCountryChange = (country: any) => {
    setStateList([]);
    setCityList([]);
    const allStates = getStates(country);
    setStateList(allStates);
  };
  const handleStateChange = (state: any) => {
    setCityList([]);
    const cities = getCities(state);
    setCityList(cities);
  };

  const handleSubmit = async (values: any, { resetForm }: any) => {
    setSubmitLoader(true);
    values.status = values.status === "true" ? true : false;
    const result: any = await createPortApi(values);
    if (result.isSuccess) {
      setSubmitLoader(false);
      resetForm({ values: createPortInitialValues });
      successToast(result.message);
      closeAddPortModal();
      onPortCreated(result.data);
    } else {
      setSubmitLoader(false);
      errorToast(result.message);
    }
    return;
  };

  return (
    <>
      {addPortModal && (
        <>
          <div className="modal-backdrop fade show"></div>
          <div
            className="modal fade show"
            id="addPortModal"
            tabIndex={-3}
            aria-hidden={!addPortModal}
            style={{ display: addPortModal ? "block" : "none" }}
          >
            <div className="modal-dialog modal-lg modal-simple modal-dialog-centered">
              <div className="modal-content">
                <div className="modal-body p-4">
                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                    onClick={closeAddPortModal}
                  ></button>
                  <div className="text-center mb-2">
                    <h4 className="mb-2">Add Port</h4>
                  </div>
                  <div className="modal-body p-0">
                    <Formik
                      initialValues={createPortInitialValues}
                      validationSchema={createPortValidationSchema}
                      onSubmit={handleSubmit}
                    >
                      {({ values, handleChange, setFieldValue }) => (
                        <Form className="dt_adv_search">
                          <div className="row">
                            <div className="col-12">
                              <div className="row g-3">
                                <div className="col-12 col-sm-6 col-lg-4">
                                  <label
                                    htmlFor="name"
                                    className="form-label"
                                    style={{ fontSize: "0.9375rem" }}
                                  >
                                    Name: <span className="text-danger">*</span>
                                  </label>
                                  <Field
                                    id="name"
                                    name="name"
                                    type="text"
                                    className="form-control"
                                    placeholder="Enter name"
                                    onChange={handleChange}
                                  />
                                  <ErrorMessage
                                    name="name"
                                    className="text-danger"
                                    component="div"
                                  />
                                </div>
                                <CountryStateCityField
                                  countryList={countryList}
                                  stateList={stateList}
                                  cityList={cityList}
                                  values={values}
                                  setFieldValue={setFieldValue}
                                  handleCountryChange={handleCountryChange}
                                  handleStateChange={handleStateChange}
                                />
                                <div className="col-12 col-sm-6 col-lg-4">
                                  <label
                                    htmlFor="status"
                                    className="form-label"
                                    style={{ fontSize: "0.9375rem" }}
                                  >
                                    Status:{" "}
                                    <span className="text-danger">*</span>
                                  </label>
                                  <select
                                    id="status"
                                    name="status"
                                    className="form-select"
                                    value={values.status}
                                    onChange={handleChange}
                                  >
                                    <option value="">Select Status</option>
                                    <option value="true">Active</option>
                                    <option value="false">Inactive</option>
                                  </select>
                                  <ErrorMessage
                                    name="status"
                                    className="text-danger"
                                    component="div"
                                  />
                                </div>
                                <div className="col-12 text-center mt-4">
                                  <button
                                    type="submit"
                                    className="btn btn-primary me-3"
                                    disabled={submitLoader}
                                  >
                                    {submitLoader && (
                                      <span
                                        className="spinner-border spinner-border-sm me-2"
                                        role="status"
                                        aria-hidden="true"
                                      ></span>
                                    )}{" "}
                                    Add Port
                                  </button>
                                  <button
                                    type="button"
                                    className="btn btn-label-secondary"
                                    onClick={closeAddPortModal}
                                  >
                                    Cancel
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </Form>
                      )}
                    </Formik>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default AddPortModal;
