import { GET, POST, PUT } from "./_config";

export const getBrokerListApi = async (query: string) =>
  await GET(`/broker/?${query || ""}`, true);

export const getAllBrokerApi = async () => await GET(`/broker/list`, true);

export const getBrokerByIdApi = async (brokerId: string) =>
  await GET(`/broker/id/${brokerId}`, true);

export const createBrokerApi = async (body: any) =>
  await POST(`/broker`, body, true);

export const updateBrokerApi = async (brokerId: string, body: any) =>
  await PUT(`/broker?id=${brokerId}`, body, true);

export const updateBrokerStatusApi = async (body: any) =>
  await POST(`/broker/update-status`, body, true);

const BrokerService = {
  getBrokerListApi,
  getAllBrokerApi,
  createBrokerApi,
  updateBrokerStatusApi,
  updateBrokerApi,
  getBrokerByIdApi,
};

export default BrokerService;
