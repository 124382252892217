import axios from "axios";
import { getToken } from "../context/AuthContext";
const API_URL = process.env.REACT_APP_API_URL;

const axiosInstance = axios.create({
  baseURL: API_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

export const GET = async (url: string, isAuthorized?: boolean) => {
  try {
    const headers: any = {};
    if (isAuthorized) {
      const token = getToken();
      headers["Authorization"] = `Bearer ${token}`;
    }
    const response = await axiosInstance.get(url, { headers });
    return {
      isSuccess: true,
      message: response.data.message || "Success!",
      data: response.data.data,
    };
  } catch (error) {
    return handleError(error);
  }
};

export const POST = async (url: string, data: any, isAuthorized?: boolean) => {
  try {
    const headers: any = {};
    if (isAuthorized) {
      const token = getToken();
      headers["Authorization"] = `Bearer ${token}`;
    }
    const response = await axiosInstance.post(url, data, { headers });
    return {
      isSuccess: true,
      message: response.data.message || "Success!",
      data: response.data.data,
    };
  } catch (error) {
    return handleError(error);
  }
};

export const PUT = async (url: string, data: any, isAuthorized?: boolean) => {
  try {
    const headers: any = {};
    if (isAuthorized) {
      const token = getToken();
      headers["Authorization"] = `Bearer ${token}`;
    }
    const response = await axiosInstance.put(url, data, { headers });
    return {
      isSuccess: true,
      message: response.data.message || "Success!",
      data: response.data.data,
    };
  } catch (error) {
    return handleError(error);
  }
};

export const DELETE = async (url: string, isAuthorized?: boolean) => {
  const headers: any = {};
  if (isAuthorized) {
    const token = getToken();
    headers["Authorization"] = `Bearer ${token}`;
  }
  try {
    const response = await axiosInstance.delete(url, { headers });
    return {
      isSuccess: true,
      message: response.data.message || "Success!",
      data: response.data,
    };
  } catch (error) {
    return handleError(error);
  }
};

const handleLogout = () => {
  localStorage.removeItem("token");
  localStorage.removeItem("permissions");
  localStorage.removeItem("me");
  window.location.href = "/login";
  window.location.reload();
};

const handleError = (error: any) => {
  if (error.response.status === 400) {
    return {
      isSuccess: false,
      message: error.response.data.message || "Bad Request!",
    };
  }
  if (error.response.status === 401) {
    handleLogout();
    return {
      isSuccess: false,
      message: error.response.data.message || "Unauthorized!",
    };
  }
  if (error.response.status === 403) {
    window.location.href = "/403";
    return {
      isSuccess: false,
      message: error.response.data.message || "Access Denied!",
    };
  }
  return {
    isSuccess: false,
    message: error.response.data.message || error.message || "Bad Request!",
  };
};
