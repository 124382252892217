import { Link, useNavigate, useParams } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { resetPasswordApi } from "../../services/_auth";
import { errorToast, successToast } from "../../components/Toast";
import { COMPANY_NAME } from "../../components/Constants";
import { useState } from "react";

const ResetPassword = () => {
  const { email } = useParams();
  const navigate = useNavigate();
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [confirmPasswordVisible, setConfirmPasswordVisible] = useState(false);

  const ResetPasswordValidationSchema = Yup.object().shape({
    email: Yup.string()
      .email("Invalid email format")
      .required("Email is required"),
    password: Yup.string().required("New password is required"),
    confirm_password: Yup.string()
      .required("Confirm password is required")
      .oneOf(
        [Yup.ref("password")],
        "New password and confirm password must match"
      ),
  });

  const resetPasswordInitialValues = {
    email: email || "",
    password: "",
    confirm_password: "",
  };

  const handleSubmit = async (values: any) => {
    const result: any = await resetPasswordApi(values);
    if (!result.isSuccess) {
      errorToast(result.message);
    } else {
      successToast(`Now you can login with new password!`);
      navigate("/login");
    }
  };

  return (
    <div className="container-xxl">
      <div className="authentication-wrapper authentication-basic container-p-y">
        <div className="authentication-inner py-6">
          <div className="card">
            <div className="card-body">
              <div className="app-brand justify-content-center mb-6">
                <Link to="#" className="app-brand-link">
                  <span
                    className="app-brand-logo demo"
                    style={{ alignItems: "unset !important", height: "44px" }}
                  >
                    <img src="/img/logo/ee-main.svg" alt={COMPANY_NAME} />
                  </span>
                  <span className="app-brand-text demo text-heading fw-bold">
                    {COMPANY_NAME}
                  </span>
                </Link>
              </div>

              <h4 className="mb-6">Reset Password 🔒</h4>

              <Formik
                initialValues={resetPasswordInitialValues}
                validationSchema={ResetPasswordValidationSchema}
                onSubmit={handleSubmit}
              >
                {({ handleChange }) => {
                  return (
                    <Form className="mb-4">
                      <div className="mb-6 form-password-toggle">
                        <label className="form-label" htmlFor="password">
                          New Password
                        </label>
                        <div className="input-group input-group-merge">
                          <Field
                            type={passwordVisible ? "text" : "password"}
                            onChange={handleChange}
                            id="password"
                            className="form-control"
                            name="password"
                            placeholder="&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;"
                            aria-describedby="password"
                          />
                          <span
                            className="input-group-text cursor-pointer"
                            onClick={() => setPasswordVisible(!passwordVisible)}
                          >
                            <i
                              className={
                                passwordVisible ? "ti ti-eye-off" : "ti ti-eye"
                              }
                            ></i>
                          </span>
                        </div>
                        <ErrorMessage
                          name="password"
                          className="text-danger"
                          component="div"
                        />
                      </div>
                      <div className="mb-6 form-password-toggle">
                        <label
                          className="form-label"
                          htmlFor="confirm_password"
                        >
                          Confirm Password
                        </label>
                        <div className="input-group input-group-merge">
                          <Field
                            type={confirmPasswordVisible ? "text" : "password"}
                            onChange={handleChange}
                            id="confirm_password"
                            className="form-control"
                            name="confirm_password"
                            placeholder="&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;"
                            aria-describedby="confirm_password"
                          />
                          <span
                            className="input-group-text cursor-pointer"
                            onClick={() =>
                              setConfirmPasswordVisible(!confirmPasswordVisible)
                            }
                          >
                            <i
                              className={
                                confirmPasswordVisible
                                  ? "ti ti-eye-off"
                                  : "ti ti-eye"
                              }
                            ></i>
                          </span>
                        </div>
                        <ErrorMessage
                          name="confirm_password"
                          className="text-danger"
                          component="div"
                        />
                      </div>
                      <div className="mb-6">
                        <button
                          className="btn btn-primary d-grid w-100"
                          type="submit"
                        >
                          Set new password
                        </button>
                      </div>
                      <div className="text-center">
                        <Link
                          to={"/login"}
                          className="d-flex align-items-center justify-content-center"
                        >
                          <i className="ti ti-chevron-left scaleX-n1-rtl me-1_5"></i>
                          Forget Password?
                        </Link>
                      </div>
                    </Form>
                  );
                }}
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
