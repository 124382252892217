import { Field, FormikErrors, FormikValues } from "formik";
import { ShipmentType } from "../../../../components/Enums";

interface ShipmentFieldsProps {
  values: FormikValues;
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean
  ) => Promise<void | FormikErrors<FormikValues>>;
  handleChange: (e: React.ChangeEvent<any>) => void;
}

const ShipmentFields: React.FC<ShipmentFieldsProps> = ({
  values,
  setFieldValue,
  handleChange,
}) => {
  return (
    <div className="card my-card">
      <div className="card-header my-card-header bg-lighter">
        <div>
          <h6 className="my-card-header-head">Shipment Details</h6>
        </div>
      </div>
      <div className="card-body my-card-body">
        <div className="row g-3 m-0">
          <div className="col-12 col-sm-6 col-md-4 col-lg-3">
            <label
              htmlFor="final_destination"
              className="form-label"
              style={{ fontSize: "0.9375rem" }}
            >
              Final Destination:{" "}
            </label>
            <Field
              id="final_destination"
              name="final_destination"
              type="text"
              className="form-control"
              placeholder="Enter Final Destination"
              value={values.final_destination}
              onChange={handleChange}
            />
          </div>
          <div className="col-12 col-sm-6 col-md-4 col-lg-3">
            <label
              htmlFor="port_of_loading"
              className="form-label"
              style={{ fontSize: "0.9375rem" }}
            >
              Port of Loading:{" "}
            </label>
            <Field
              id="port_of_loading"
              name="port_of_loading"
              type="text"
              className="form-control"
              placeholder="Enter Port of Loading"
              value={values.port_of_loading}
              onChange={handleChange}
            />
          </div>
          <div className="col-12 col-sm-6 col-md-4 col-lg-3">
            <label
              htmlFor="port_of_discharge"
              className="form-label"
              style={{ fontSize: "0.9375rem" }}
            >
              Port of Discharge:{" "}
            </label>
            <Field
              id="port_of_discharge"
              name="port_of_discharge"
              type="text"
              className="form-control"
              placeholder="Enter Port of Discharge"
              value={values.port_of_discharge}
              onChange={handleChange}
            />
          </div>
          <div className="col-12 col-sm-6 col-md-4 col-lg-3">
            <label
              htmlFor="shipment"
              className="form-label"
              style={{ fontSize: "0.9375rem" }}
            >
              Shipment:{" "}
            </label>
            <Field
              id="shipment"
              name="shipment"
              type="text"
              className="form-control"
              placeholder="Enter Shipment"
              value={values.shipment}
              onChange={handleChange}
            />
          </div>
          <div className="col-12 col-sm-6 col-md-4 col-lg-3">
            <label
              htmlFor="trans_shipment"
              className="form-label"
              style={{ fontSize: "0.9375rem" }}
            >
              Trans Shipment:
            </label>
            <select
              name="trans_shipment"
              className="form-select"
              id="trans_shipment"
              value={values.trans_shipment}
              onChange={handleChange}
            >
              <option defaultChecked value={""}>
                Select Trans Shipment
              </option>
              <option value={ShipmentType.ALLOWED}>
                {ShipmentType.ALLOWED}
              </option>
              <option value={ShipmentType.NOT_ALLOWED}>
                {ShipmentType.NOT_ALLOWED}
              </option>
            </select>
          </div>
          <div className="col-12 col-sm-6 col-md-4 col-lg-3">
            <label
              htmlFor="partial_shipment"
              className="form-label"
              style={{ fontSize: "0.9375rem" }}
            >
              Partial Shipment:
            </label>
            <select
              name="partial_shipment"
              className="form-select"
              id="partial_shipment"
              value={values.partial_shipment}
              onChange={handleChange}
            >
              <option defaultChecked value={""}>
                Select Partial Shipment
              </option>
              <option value={ShipmentType.ALLOWED}>
                {ShipmentType.ALLOWED}
              </option>
              <option value={ShipmentType.NOT_ALLOWED}>
                {ShipmentType.NOT_ALLOWED}
              </option>
            </select>
          </div>
          <div className="col-12 col-sm-6 col-md-4 col-lg-3">
            <label
              htmlFor="payment_terms"
              className="form-label"
              style={{ fontSize: "0.9375rem" }}
            >
              Payment Terms:{" "}
            </label>
            <Field
              id="payment_terms"
              name="payment_terms"
              type="text"
              className="form-control"
              placeholder="Enter Payment Terms"
              value={values.payment_terms}
              onChange={handleChange}
            />
          </div>
          <div className="col-12 col-sm-6 col-md-4 col-lg-3">
            <label
              htmlFor="weight_and_quality"
              className="form-label"
              style={{ fontSize: "0.9375rem" }}
            >
              Weight & Quality:{" "}
            </label>
            <Field
              id="weight_and_quality"
              name="weight_and_quality"
              type="text"
              className="form-control"
              placeholder="Enter Weight & Quality"
              value={values.weight_and_quality}
              onChange={handleChange}
            />
          </div>
          <div className="col-12 col-sm-6 col-md-4 col-lg-3">
            <label
              htmlFor="arbitration"
              className="form-label"
              style={{ fontSize: "0.9375rem" }}
            >
              Arbitration:{" "}
            </label>
            <Field
              id="arbitration"
              name="arbitration"
              type="text"
              className="form-control"
              placeholder="Enter Arbitration"
              value={values.arbitration}
              onChange={handleChange}
            />
          </div>
          {/* <div className="col-12 col-sm-6 col-md-4 col-lg-3">
            <label
              htmlFor="is_digital_signature"
              className="form-label d-block"
              style={{ fontSize: "0.9375rem" }}
            >
              Allow Digital Signature:{" "}
            </label>
            <div className="form-control custom-radio-form-control">
              <div className="form-check form-check-inline custom-inline-radio">
                <input
                  className="form-check-input"
                  type="radio"
                  name="is_digital_signature"
                  id="is_digital_signature_yes"
                  checked={values.is_digital_signature === 1}
                  onChange={(e: any) => {
                    setFieldValue("is_digital_signature", 1);
                  }}
                />
                <label
                  className="form-check-label"
                  htmlFor="is_digital_signature_yes"
                >
                  Yes
                </label>
              </div>
              <div className="form-check form-check-inline custom-inline-radio">
                <input
                  className="form-check-input"
                  type="radio"
                  name="is_digital_signature"
                  id="is_digital_signature_no"
                  checked={values.is_digital_signature === 0}
                  onChange={(e: any) => {
                    setFieldValue("is_digital_signature", 0);
                  }}
                />
                <label
                  className="form-check-label"
                  htmlFor="is_digital_signature_no"
                >
                  No
                </label>
              </div>
            </div>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default ShipmentFields;
