/* eslint-disable react-hooks/exhaustive-deps */
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { createPaymentApi } from "../../services/_payment";
import { errorToast, successToast } from "../../components/Toast";
import PaymentForm from "./PaymentForm";

const CreatePayment = () => {
  const navigate = useNavigate();
  const [submitLoader, setSubmitLoader] = useState<boolean>(false);

  const createPaymentInitialsValue = {
    relation_id: "",
    payment_role: "",
    purchase_id: "",
    sales_type: "",
    sales_contract_id: "",
    sales_invoice_id: "",
    miscellaneous_sales_id: "",
    transaction_type: "",
    payment_method: "",
    payment_status: "",
    transaction_no: "",
    amount: 0,
    transaction_date: new Date(),
    remark: "",
    exchange_currency: null,
    exchange_rate: 0,
    exchange_amount: 0,
  };

  const handleSubmit = async (values: any) => {
    setSubmitLoader(true);
    const result: any = await createPaymentApi(values);
    if (result.isSuccess) {
      setSubmitLoader(false);
      successToast(result.message);
      navigate("/payment-list");
    } else {
      setSubmitLoader(false);
      errorToast(result.message);
    }
    return;
  };

  return (
    <>
      <div className="card">
        <div className="card-header pb-0">
          <div>
            <h5>Create Payments</h5>
          </div>
        </div>
        <div className="card-body">
          <PaymentForm
            initialValues={createPaymentInitialsValue}
            handleSubmit={handleSubmit}
            submitLoader={submitLoader}
          />
        </div>
      </div>
    </>
  );
};

export default CreatePayment;
