import { ErrorMessage, Field } from "formik";
import "rsuite/DateRangePicker/styles/index.css";

interface MyDocumentsFieldsProps {
  documentList: any;
  myDocumentList: any;
  isShowMyDocumentButton: any;
  setIsShowMyDocumentButton: any;
}

const MyDocumentsFields: React.FC<MyDocumentsFieldsProps> = ({
  documentList,
  myDocumentList,
  isShowMyDocumentButton,
  setIsShowMyDocumentButton,
}) => {
  return (
    <div className="card my-card">
      <div className="card-header my-card-header bg-lighter d-flex justify-content-between">
        <div>
          <h6 className="my-card-header-head">My Documents (Self)</h6>
        </div>
        {isShowMyDocumentButton && (
          <div>
            <button
              title="Add My Documents"
              className="btn btn-sm btn-primary waves-effect waves-light p-0"
              type="button"
              style={{ margin: "5px" }}
              onClick={() => {
                setIsShowMyDocumentButton(false);
              }}
            >
              <span>
                <i className="ti ti-plus my-card-icon-button"></i>
              </span>
            </button>
          </div>
        )}
      </div>
      {!isShowMyDocumentButton && (
        <div className="card-body my-card-body">
          <div className="row g-3 m-0">
            <div className="col-12 col-sm-12 col-lg-12">
              <div
                role="group"
                aria-labelledby="checkbox-group"
                className="row pt-3 m-0"
                style={{
                  border: "1px solid #d1d0d4",
                  borderRadius: "0.375rem",
                }}
              >
                {myDocumentList.map((document: any) => (
                  <div key={document.id} className="col-12 col-sm-6 col-md-4">
                    <div className="form-check mb-3">
                      <Field
                        type="checkbox"
                        name="my_documents"
                        value={document.id.toString()}
                        className="form-check-input"
                        id={`permission-${document.id}`}
                      />
                      <label
                        htmlFor={`permission-${document.id}`}
                        className="form-check-label"
                      >
                        {document.name}
                      </label>
                    </div>
                  </div>
                ))}
              </div>
              <ErrorMessage
                name="my_documents"
                className="text-danger"
                component="div"
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default MyDocumentsFields;
