/* eslint-disable react-hooks/exhaustive-deps */
import * as Yup from "yup";
import { useEffect, useState } from "react";
import { getRoleListApi } from "../../services/_role";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { Link, useNavigate, useParams } from "react-router-dom";
import { errorToast, successToast } from "../../components/Toast";
import { getUserByIdApi, updateUserApi } from "../../services/_user";

const EditUser = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [roles, setRoles] = useState([]);
  const [updateLoader, setUpdateLoader] = useState<boolean>(false);
  useEffect(() => {
    if (!id) {
      navigate("/user-list");
    } else {
      getUserDetails();
    }
  }, []);

  const getUserDetails = async () => {
    const result: any = await getUserByIdApi(id as string);
    if (result.isSuccess) {
      setInitialValues({
        role_id: result?.data?.role_id,
        name: result?.data?.name,
        email: result?.data?.email,
        phone_no: result?.data?.phone_no,
        status: result?.data?.status?.toString(),
      });
    }
  };

  const [initialValues, setInitialValues] = useState({
    role_id: "",
    name: "",
    email: "",
    phone_no: "",
    status: undefined,
  });

  const createUserValidationSchema = Yup.object().shape({
    role_id: Yup.string().required("Role is required"),
    name: Yup.string().required("Name is required"),
    email: Yup.string().required("Email is required"),
    status: Yup.string().required("Status is required"),
  });

  const handleSubmit = async (values: any) => {
    setUpdateLoader(true);
    values.status = values.status === "true" ? true : false;
    const result: any = await updateUserApi(id as string, values);
    if (result.isSuccess) {
      successToast(result.message);
      setUpdateLoader(false);
      navigate("/user-list");
    } else {
      setUpdateLoader(false);
      errorToast(result.message);
    }
    return;
  };

  const getRoles = async () => {
    const result: any = await getRoleListApi();
    if (result?.isSuccess) {
      setRoles(result.data);
    }
  };

  useEffect(() => {
    getRoles();
  }, []);

  return (
    <>
      <div className="card">
        <div className="card-header pb-0">
          <div>
            <h5>Edit User</h5>
          </div>
        </div>
        <div className="card-body">
          <Formik
            enableReinitialize={true}
            initialValues={initialValues}
            validationSchema={createUserValidationSchema}
            onSubmit={handleSubmit}
          >
            {({ values, handleChange }) => {
              return (
                <Form className="dt_adv_search">
                  <div className="row">
                    <div className="col-12">
                      <div className="row g-3">
                        <div className="col-12 col-sm-6 col-lg-4">
                          <label
                            htmlFor="name"
                            className="form-label"
                            style={{ fontSize: "0.9375rem" }}
                          >
                            Name: <span className="text-danger">*</span>
                          </label>
                          <Field
                            id="name"
                            name="name"
                            type="text"
                            className="form-control"
                            placeholder="Enter name"
                            onChange={handleChange}
                          />
                          <ErrorMessage
                            name="name"
                            className="text-danger"
                            component="div"
                          />
                        </div>
                        <div className="col-12 col-sm-6 col-lg-4">
                          <label
                            htmlFor="email"
                            className="form-label"
                            style={{ fontSize: "0.9375rem" }}
                          >
                            Email: <span className="text-danger">*</span>
                          </label>
                          <Field
                            id="email"
                            name="email"
                            type="email"
                            className="form-control"
                            placeholder="Enter email"
                            onChange={handleChange}
                          />
                          <ErrorMessage
                            name="email"
                            className="text-danger"
                            component="div"
                          />
                        </div>
                        <div className="col-12 col-sm-6 col-lg-4">
                          <label
                            htmlFor="phone_no"
                            className="form-label"
                            style={{ fontSize: "0.9375rem" }}
                          >
                            Phone No:
                          </label>
                          <Field
                            id="phone_no"
                            name="phone_no"
                            type="text"
                            className="form-control"
                            placeholder="Enter phone no"
                            onChange={handleChange}
                          />
                        </div>
                        <div className="col-12 col-sm-6 col-lg-4">
                          <label
                            htmlFor="role_id"
                            className="form-label"
                            style={{ fontSize: "0.9375rem" }}
                          >
                            Role: <span className="text-danger">*</span>
                          </label>
                          <select
                            id="role_id"
                            name="role_id"
                            className="form-select"
                            onChange={handleChange}
                            value={values?.role_id}
                          >
                            <option value="">Select Role</option>
                            {roles?.length &&
                              roles.map((role: any) => (
                                <option key={role.id} value={role.id}>
                                  {role.name}
                                </option>
                              ))}
                          </select>
                          <ErrorMessage
                            name="role_id"
                            className="text-danger"
                            component="div"
                          />
                        </div>
                        <div className="col-12 col-sm-6 col-lg-4">
                          <label
                            htmlFor="status"
                            className="form-label"
                            style={{ fontSize: "0.9375rem" }}
                          >
                            Status: <span className="text-danger">*</span>
                          </label>
                          <select
                            id="status"
                            name="status"
                            className="form-select"
                            onChange={handleChange}
                            value={values?.status}
                          >
                            <option value="">Select Status</option>
                            <option value="true">Active</option>
                            <option value="false">Inactive</option>
                          </select>
                          <ErrorMessage
                            name="status"
                            className="text-danger"
                            component="div"
                          />
                        </div>
                        <div className="col-12">
                          <button
                            type="submit"
                            className="btn btn-primary"
                            disabled={updateLoader}
                          >
                            {updateLoader && (
                              <span
                                className="spinner-border spinner-border-sm me-2"
                                role="status"
                                aria-hidden="true"
                              ></span>
                            )}
                            Submit
                          </button>
                          <Link
                            to={"/user-list"}
                            type="button"
                            className="btn btn-label-github ms-5"
                          >
                            Back
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </Form>
              );
            }}
          </Formik>
        </div>
      </div>
    </>
  );
};

export default EditUser;
