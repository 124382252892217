import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { Formik, Form } from "formik";
import { createBankApi } from "../../services/_bank";
import { errorToast, successToast } from "../../components/Toast";
import { useState } from "react";
import { BankType } from "../../components/Enums";
import { getSupplierNameListApi } from "../../services/_supplier";
import { getAllLogisticApi } from "../../services/_logistic";
import { getBuyerNameListApi } from "../../services/_buyer";
import { getAllChaApi } from "../../services/_cha";
import { getAllBrokerApi } from "../../services/_broker";
import BankForm from "./BankForm";

const CreateBank = () => {
  const [relationList, setRelationList] = useState([]);
  const navigate = useNavigate();
  const createBankInitialValues = {
    type: "",
    relation_id: "",
    bank_name: "",
    account_number: "",
    ifsc_code: "",
    branch_name: "",
    swift_code: "",
    ad_code: "",
    status: "",
    address: "",
  };
  const createBankValidationSchema = Yup.object().shape({
    type: Yup.string().required("Type is required"),
    relation_id: Yup.string().test(
      "relation-id-required",
      "Name is required",
      function (value) {
        const { type } = this.parent;
        if (type === "exporter") {
          return false;
        }
        return true;
      }
    ),
    bank_name: Yup.string().required("Bank Name is required"),
    account_number: Yup.string().required("Account Number is required"),
    ifsc_code: Yup.string().required("IFSC is required"),
    branch_name: Yup.string().required("Branch Name is required"),
    swift_code: Yup.string().nullable(),
    ad_code: Yup.string().nullable(),
    status: Yup.string().required("Status is required"),
    address: Yup.string().nullable(),
  });

  const handleTypeChange = async (filterType: BankType | string) => {
    if (filterType === BankType.EXPORTER) {
      setRelationList([]);
    }
    if (filterType === BankType.BUYER) {
      const result: any = await getBuyerNameListApi();
      if (result.isSuccess) {
        setRelationList(result?.data);
      }
    }
    if (filterType === BankType.SUPPLIER) {
      const result: any = await getSupplierNameListApi();
      if (result.isSuccess) {
        setRelationList(result?.data);
      }
    }
    if (filterType === BankType.LOGISTIC) {
      const result: any = await getAllLogisticApi();
      if (result.isSuccess) {
        setRelationList(result?.data);
      }
    }
    if (filterType === BankType.CHA) {
      const result: any = await getAllChaApi();
      if (result.isSuccess) {
        setRelationList(result?.data);
      }
    }
    if (filterType === BankType.BROKER) {
      const result: any = await getAllBrokerApi();
      if (result.isSuccess) {
        setRelationList(result?.data);
      }
    }
  };

  const handleSubmit = async (values: any, { resetForm }: any) => {
    values.status = values.status === "true" ? true : false;
    const result: any = await createBankApi(values);
    if (result.isSuccess) {
      successToast(result.message);
      resetForm({ values: createBankInitialValues });
      navigate("/bank-list");
    } else {
      errorToast(result.message);
    }
    return;
  };

  return (
    <>
      <div className="card">
        <div className="card-header pb-0">
          <div>
            <h5>Create Bank</h5>
          </div>
        </div>
        <div className="card-body">
          <Formik
            initialValues={createBankInitialValues}
            validationSchema={createBankValidationSchema}
            onSubmit={handleSubmit}
          >
            {({ values, handleChange, setFieldValue }) => (
              <Form className="dt_adv_search">
                <BankForm
                  relationList={relationList}
                  handleTypeChange={handleTypeChange}
                  values={values}
                  handleChange={handleChange}
                  setFieldValue={setFieldValue}
                />
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </>
  );
};

export default CreateBank;
