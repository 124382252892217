import { GET, POST, PUT } from "./_config";

export const buyerListApi = async (query: string) =>
  await GET(`/buyer?${query || ""}`, true);

export const createBuyerApi = async (body: any) =>
  await POST(`/buyer`, body, true);

export const getBuyerByIdApi = async (buyerId: string) =>
  await GET(`/buyer/id/${buyerId}`, true);

export const updateBuyerApi = async (buyerId: string, body: any) =>
  await PUT(`/buyer?id=${buyerId}`, body, true);

export const updateBuyerStatusApi = async (body: any) =>
  await POST(`/buyer/update-status`, body, true);

export const getBuyerNameListApi = async ()=> await GET(`buyer/list`,true);

const BuyerService = {
  buyerListApi,
  createBuyerApi,
  getBuyerByIdApi,
  updateBuyerApi,
  updateBuyerStatusApi,
  getBuyerNameListApi
};

export default BuyerService;
