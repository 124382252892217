import {
  Document,
  Page,
  PDFViewer,
  StyleSheet,
  Text,
  View,
} from "@react-pdf/renderer";

export const styles = StyleSheet.create({
  container: {
    paddingTop: 10,
    width: "100%",
    paddingLeft: 5,
  },
  boldText: {
    fontWeight: "bold",
    fontSize: 10,
  },
  smallFontSize: {
    fontSize: 6,
  },
  mediumFontSize: {
    fontSize: 8,
  },
  regularFontSize: {
    fontSize: 9,
  },
  largeFontSize: {
    fontSize: 10,
  },
  page: {
    paddingHorizontal: 30,
    paddingVertical: 15,
    fontFamily: "HostGrotesk",
    backgroundColor: "white",
  },
  fullpage: {
    margin: 7,
    borderStyle: "solid",
    borderWidth: 1,
    borderColor: "#000000",
  },
  sideBySideContainer: { flexDirection: "row" },
  content: {
    flexDirection: "row",
  },
  ContentHeading: {
    width: "45%",
    justifyContent: "flex-start",
    paddingLeft: 20,
    paddingTop: 0,
  },
  subContent: {
    width: "20%",
    justifyContent: "center",
    paddingTop: 0,
    paddingLeft: 45,
  },
  paragraph: {
    paddingLeft: 20,
    paddingRight: 5,
    paddingTop: 2,
  },
  addressContainer: {
    width: "50%",
    flexDirection: "column",
    justifyContent: "space-between",
    paddingTop: 0,
    paddingRight: 1,
  },
  tableContainer: {
    borderBottomWidth: 1,
    borderBottomColor: "black",
    borderTopWidth: 1,
    justifyContent: "center",
    borderTop: 1,
    borderRight: 1,
    borderLeft: 1,
  },
  tableRow: {
    flexDirection: "row",
    flexGrow: 1,
    paddingLeft: 5,
    paddingRight: 5,
    textAlign: "center",
  },
  tableContent: {
    paddingTop: 1,
    borderRightWidth: 1,
    textAlign: "center",
    justifyContent: "space-between",
  },
});

const WeightAndQualityCertificate = () => {
  return (
    <PDFViewer width="100%" height="800px">
      <Document>
        <Page style={styles.page} size="A4">
          <Text style={[styles.boldText, { textAlign: "center" }]}>
            WEIGHT & QUALITY CERTIFICATE
          </Text>
          <View style={[styles.sideBySideContainer]}>
            <View style={[styles.container]}>
              <View style={styles.content}>
                <Text style={[styles.boldText, styles.ContentHeading]}>
                  REPORT .NO.:ALR/NF/0160301202
                </Text>{" "}
                <Text
                  style={[
                    styles.boldText,
                    { justifyContent: "flex-end", paddingLeft: 150 },
                  ]}
                >
                  DATE: 03.01.2024
                </Text>
              </View>
              <View style={styles.content}>
                <Text style={[styles.boldText, styles.ContentHeading]}>
                  EXPORTER
                </Text>
                <Text style={[styles.subContent, styles.regularFontSize]}>
                  :
                </Text>
                <View style={styles.addressContainer}>
                  <Text style={[styles.regularFontSize]}>ENFINY EXIM LLP</Text>
                  <Text style={[styles.regularFontSize]}>
                    PLOT NO-36, GROUND FLOOR, SHUBHAM INDUSTRY-2, SANIYA HEMED,
                    SURAT-395006. INDIA
                  </Text>
                </View>
              </View>

              {/* CONSIGNEE | NOTIFY */}
              <View style={styles.content}>
                <Text style={[styles.boldText, styles.ContentHeading]}>
                  CONSIGNEE/NOTIFY
                </Text>
                <Text style={[styles.subContent, styles.regularFontSize]}>
                  :
                </Text>
                <View style={styles.addressContainer}>
                  <Text style={[styles.regularFontSize]}>
                    DAC THANH FOOD COMPANY LIMITED
                  </Text>
                  <Text style={[styles.regularFontSize]}>
                    BINH TINH QUARTER, BINH PHU TOWNLET, CAI LAY DISTRICT, TIEN
                    GIANG PROVINCE, VIETNAM,TAX ID: 1201545414
                  </Text>
                </View>
              </View>

              <View style={styles.content}>
                <Text style={[styles.boldText, styles.ContentHeading]}>
                  GOODS DESCRIBED AS
                </Text>
                <Text style={[styles.subContent, styles.regularFontSize]}>
                  :
                </Text>
                <Text style={[styles.regularFontSize, styles.addressContainer]}>
                  INDIAN SONA MASOORI WHITE RICE
                </Text>
              </View>

              <View style={styles.content}>
                <Text style={[styles.boldText, styles.ContentHeading]}>
                  VESSEL NAME & VOYAGE NUMBER
                </Text>
                <Text style={[styles.subContent, styles.regularFontSize]}>
                  :
                </Text>
                <Text style={[styles.regularFontSize, styles.addressContainer]}>
                  X PRESS KAILASH-1889E
                </Text>
              </View>

              <View style={styles.content}>
                <Text style={[styles.boldText, styles.ContentHeading]}>
                  PORT OF LOADING
                </Text>
                <Text style={[styles.subContent, styles.regularFontSize]}>
                  :
                </Text>
                <Text style={[styles.regularFontSize, styles.addressContainer]}>
                  CHENNAI,INDIA
                </Text>
              </View>

              <View style={styles.content}>
                <Text style={[styles.boldText, styles.ContentHeading]}>
                  PORT OF DISCHARGE
                </Text>
                <Text style={[styles.subContent, styles.regularFontSize]}>
                  :
                </Text>
                <Text style={[styles.regularFontSize, styles.addressContainer]}>
                  HOCHIMINH,VIETNAM
                </Text>
              </View>

              <View style={styles.content}>
                <Text style={[styles.boldText, styles.ContentHeading]}>
                  PSCKING
                </Text>
                <Text style={[styles.subContent, styles.regularFontSize]}>
                  :
                </Text>
                <Text style={[styles.regularFontSize, styles.addressContainer]}>
                  IN PP BAGS OF 50 KGS NET EACH
                </Text>
              </View>

              <View style={styles.content}>
                <Text style={[styles.boldText, styles.ContentHeading]}>
                  BL NO.
                </Text>
                <Text style={[styles.subContent, styles.regularFontSize]}>
                  :
                </Text>
                <Text style={[styles.regularFontSize, styles.addressContainer]}>
                  NSSLMAHCC2400032
                </Text>
              </View>

              <View style={styles.content}>
                <Text style={[styles.boldText, styles.ContentHeading]}>
                  INVOICE NO.
                </Text>
                <Text style={[styles.subContent, styles.regularFontSize]}>
                  :
                </Text>
                <Text style={[styles.regularFontSize, styles.addressContainer]}>
                  EXP/24-25/011 DATE: 17.12.2024
                </Text>
              </View>

              <View style={styles.content}>
                <Text style={[styles.boldText, styles.ContentHeading]}>
                  S/B NO.
                </Text>
                <Text style={[styles.subContent, styles.regularFontSize]}>
                  :
                </Text>
                <Text style={[styles.regularFontSize, styles.addressContainer]}>
                  6608448 DATE: 20.12.2024
                </Text>
              </View>

              <View style={styles.content}>
                <Text style={[styles.boldText, styles.ContentHeading]}>
                  DATE & PLACE OF ATTENDANCE
                </Text>
                <Text style={[styles.subContent, styles.regularFontSize]}>
                  :
                </Text>
                <Text style={[styles.regularFontSize, styles.addressContainer]}>
                  AT SHIPPER'S FACTORY DATE:00.00.000
                </Text>
              </View>

              <View style={styles.content}>
                <Text style={[styles.boldText, styles.ContentHeading]}>
                  DECLARED QUANTITY
                </Text>
                <Text style={[styles.subContent, styles.regularFontSize]}>
                  :
                </Text>
                <View style={styles.addressContainer}>
                  <Text style={[styles.regularFontSize]}>
                    DECLARED QUANTITY
                  </Text>
                  <Text style={[styles.regularFontSize]}>2700 PP BAGS</Text>
                  <Text style={[styles.regularFontSize]}>
                    NET WEIGHT: 135.000
                  </Text>
                  <Text style={[styles.regularFontSize]}>
                    GROSS WEIGHT: 135.270 MT
                  </Text>
                </View>
              </View>
            </View>
          </View>

          <View style={[styles.sideBySideContainer]}>
            <View style={[styles.content]}>
              <Text style={[styles.ContentHeading, styles.boldText]}>
                STUFFING:-
              </Text>
            </View>
          </View>

          <View style={[styles.sideBySideContainer]}>
            <View style={[styles.content]}>
              <Text style={[styles.regularFontSize, styles.paragraph]}>
                AFTER INSPECTION SAMPLING, WEIGHING THE GOODS/BAGS WERE LOADED
                INTO CONTAINER. PRIOR TO STUFFING, KRAFT PAPER WAS SPREAD ON
                BOTTOM & ALL THREE SIDE OF THE CONTAINER. THE GOODS WERE STUFFED
                INTO THE FOLLOWING CONTAINERS UNDER OUR SUPERVISION.
              </Text>
            </View>
          </View>

          {/* table 1 */}
          <View style={{ justifyContent: "center", paddingLeft: 150, top: 5 }}>
            <View
              style={[
                styles.tableContainer,
                styles.mediumFontSize,
                {
                  width: "65%",
                },
              ]}
            >
              <View style={[styles.tableRow, styles.boldText]}>
                <Text
                  style={[
                    styles.boldText,
                    styles.tableContent,
                    {
                      width: "35%",
                    },
                  ]}
                >
                  CONTAINER NO.
                </Text>
                <Text
                  style={[
                    styles.boldText,
                    styles.tableContent,
                    {
                      width: "30%",
                    },
                  ]}
                >
                  SEAL NO.
                </Text>
                <Text
                  style={[
                    styles.tableContent,
                    {
                      borderRightWidth: 0,
                      width: "30%",
                    },
                  ]}
                >
                  NO. OF BAGS
                </Text>
              </View>
            </View>

            {/* table data */}
            <View
              style={[
                styles.tableContainer,
                styles.mediumFontSize,
                {
                  width: "65%",
                  marginTop: 0,
                  borderTop: 0,
                },
              ]}
            >
              <View style={[styles.tableRow, styles.regularFontSize]}>
                <Text
                  style={[
                    styles.tableContent,
                    {
                      width: "35%",
                    },
                  ]}
                >
                  DYLU2134495
                </Text>
                <Text
                  style={[
                    styles.tableContent,
                    {
                      width: "30%",
                    },
                  ]}
                >
                  000220
                </Text>
                <Text
                  style={[
                    styles.tableContent,
                    {
                      borderRightWidth: 0,
                      width: "30%",
                    },
                  ]}
                >
                  540
                </Text>
              </View>
            </View>

            {/* data 2 */}
            <View
              style={[
                styles.tableContainer,
                styles.mediumFontSize,
                {
                  width: "65%",
                  marginTop: 0,
                  borderTop: 0,
                },
              ]}
            >
              <View style={[styles.tableRow, styles.regularFontSize]}>
                <Text
                  style={[
                    styles.tableContent,
                    {
                      width: "35%",
                    },
                  ]}
                >
                  DYLU2134495
                </Text>
                <Text
                  style={[
                    styles.tableContent,
                    {
                      width: "30%",
                    },
                  ]}
                >
                  000220
                </Text>
                <Text
                  style={[
                    styles.tableContent,
                    {
                      borderRightWidth: 0,
                      width: "30%",
                    },
                  ]}
                >
                  540
                </Text>
              </View>
            </View>

            {/* data 3 */}
            <View
              style={[
                styles.tableContainer,
                styles.mediumFontSize,
                {
                  width: "65%",
                  marginTop: 0,
                  borderTop: 0,
                },
              ]}
            >
              <View style={[styles.tableRow, styles.regularFontSize]}>
                <Text
                  style={[
                    styles.tableContent,
                    {
                      width: "35%",
                    },
                  ]}
                >
                  FTAU1639640
                </Text>
                <Text
                  style={[
                    styles.tableContent,
                    {
                      width: "30%",
                    },
                  ]}
                >
                  000201
                </Text>
                <Text
                  style={[
                    styles.tableContent,
                    {
                      borderRightWidth: 0,
                      width: "30%",
                    },
                  ]}
                >
                  540
                </Text>
              </View>
            </View>

            {/* data 4 */}
            <View
              style={[
                styles.tableContainer,
                styles.mediumFontSize,
                {
                  width: "65%",
                  marginTop: 0,
                  borderTop: 0,
                },
              ]}
            >
              <View style={[styles.tableRow, styles.regularFontSize]}>
                <Text
                  style={[
                    styles.tableContent,
                    {
                      width: "35%",
                    },
                  ]}
                >
                  DYLU2134495
                </Text>
                <Text
                  style={[
                    styles.tableContent,
                    {
                      width: "30%",
                    },
                  ]}
                >
                  000220
                </Text>
                <Text
                  style={[
                    styles.tableContent,
                    {
                      borderRightWidth: 0,
                      width: "30%",
                    },
                  ]}
                >
                  540
                </Text>
              </View>
            </View>

            {/* data 5 */}
            <View
              style={[
                styles.tableContainer,
                styles.mediumFontSize,
                {
                  width: "65%",
                  marginTop: 0,
                  borderTop: 0,
                },
              ]}
            >
              <View style={[styles.tableRow, styles.regularFontSize]}>
                <Text
                  style={[
                    styles.tableContent,
                    {
                      width: "35%",
                    },
                  ]}
                >
                  DYLU2134495
                </Text>
                <Text
                  style={[
                    styles.tableContent,
                    {
                      width: "30%",
                    },
                  ]}
                >
                  000220
                </Text>
                <Text
                  style={[
                    styles.tableContent,
                    {
                      borderRightWidth: 0,
                      width: "30%",
                    },
                  ]}
                >
                  540
                </Text>
              </View>
            </View>
          </View>

          <View style={[styles.sideBySideContainer]}>
            <View style={[styles.content]}>
              <Text style={[styles.ContentHeading, styles.boldText]}>
                SAMPLING:-
              </Text>
            </View>
          </View>

          <View style={[styles.sideBySideContainer]}>
            <View style={[styles.content]}>
              <Text style={[styles.regularFontSize, styles.paragraph]}>
                SAMPLES WERE DRAWN FROM 10% OF THE BAGS SELECTED AT RANDOM. THUS
                DRAWN SAMPLES WERE THOROUGHLY MIXED, MADE UP INTO COMPOSITE
                SAMPLES AND SEALED WITH OUR MONOGRAM SEAL.ONE SEALED SAMPLE WAS
                SUBMITTED TO OUR LABORATORY FOR ANALYSIS. THE OTHER SAMPLE WILL
                BE RETAINED BY US FOR A PERIOD OF THREE MONTHS ONLY.
              </Text>
            </View>
          </View>

          {/* TABLE 2 */}

          <View style={{ justifyContent: "center", paddingLeft: 50 }}>
            <View
              style={[
                styles.tableContainer,
                styles.mediumFontSize,
                {
                  width: "85%",
                },
              ]}
            >
              <View style={[styles.tableRow, styles.boldText]}>
                <Text
                  style={[
                    styles.boldText,
                    styles.tableContent,
                    {
                      width: "45%",
                    },
                  ]}
                >
                  PARAMETER
                </Text>
                <Text
                  style={[
                    styles.boldText,
                    styles.tableContent,
                    {
                      width: "45%",
                    },
                  ]}
                >
                  TEST METHOD
                </Text>
                <Text
                  style={[
                    styles.tableContent,
                    {
                      borderRightWidth: 0,
                      width: "35%",
                    },
                  ]}
                >
                  TEST RESULT
                </Text>
              </View>
            </View>

            {/* table data */}
            <View
              style={[
                styles.tableContainer,
                styles.mediumFontSize,
                {
                  width: "85%",
                  marginTop: 0,
                  borderTop: 0,
                },
              ]}
            >
              <View style={[styles.tableRow, styles.regularFontSize]}>
                <Text
                  style={[
                    styles.tableContent,
                    {
                      width: "45%",
                    },
                  ]}
                >
                  40-DEGREE KET
                </Text>
                <Text
                  style={[
                    styles.tableContent,
                    {
                      width: "45%",
                    },
                  ]}
                >
                  AL/SOP/6.4/C-157
                </Text>
                <Text
                  style={[
                    styles.tableContent,
                    {
                      borderRightWidth: 0,
                      width: "35%",
                    },
                  ]}
                >
                  42
                </Text>
              </View>
            </View>

            {/* data 2 */}
            <View
              style={[
                styles.tableContainer,
                styles.mediumFontSize,
                {
                  width: "85%",
                  marginTop: 0,
                  borderTop: 0,
                },
              ]}
            >
              <View style={[styles.tableRow, styles.regularFontSize]}>
                <Text
                  style={[
                    styles.tableContent,
                    {
                      width: "45%",
                    },
                  ]}
                >
                  AFLATOXIN B1
                </Text>
                <Text
                  style={[
                    styles.tableContent,
                    {
                      width: "45%",
                    },
                  ]}
                >
                  AOAC: 2005.08
                </Text>
                <Text
                  style={[
                    styles.tableContent,
                    {
                      borderRightWidth: 0,
                      width: "35%",
                    },
                  ]}
                >
                  BLQ
                </Text>
              </View>
            </View>

            {/* data 3 */}
            <View
              style={[
                styles.tableContainer,
                styles.mediumFontSize,
                {
                  width: "85%",
                  marginTop: 0,
                  borderTop: 0,
                },
              ]}
            >
              <View style={[styles.tableRow, styles.regularFontSize]}>
                <Text
                  style={[
                    styles.tableContent,
                    {
                      width: "45%",
                    },
                  ]}
                >
                  AFLATOXIN TOTAL
                </Text>
                <Text
                  style={[
                    styles.tableContent,
                    {
                      width: "45%",
                    },
                  ]}
                >
                  AOAC: 2005.0
                </Text>
                <Text
                  style={[
                    styles.tableContent,
                    {
                      borderRightWidth: 0,
                      width: "35%",
                    },
                  ]}
                >
                  BLQ
                </Text>
              </View>
            </View>

            {/* data 4 */}
            <View
              style={[
                styles.tableContainer,
                styles.mediumFontSize,
                {
                  width: "85%",
                  marginTop: 0,
                  borderTop: 0,
                },
              ]}
            >
              <View style={[styles.tableRow, styles.regularFontSize]}>
                <Text
                  style={[
                    styles.tableContent,
                    {
                      width: "45%",
                    },
                  ]}
                >
                  ASEN (AS)
                </Text>
                <Text
                  style={[
                    styles.tableContent,
                    {
                      width: "45%",
                    },
                  ]}
                >
                  AL/SOP/6.4/C-157
                </Text>
                <Text
                  style={[
                    styles.tableContent,
                    {
                      borderRightWidth: 0,
                      width: "35%",
                    },
                  ]}
                >
                  BLQ(Q.L.=0.05)
                </Text>
              </View>
            </View>

            {/* data 5 */}
            <View
              style={[
                styles.tableContainer,
                styles.mediumFontSize,
                {
                  width: "85%",
                  marginTop: 0,
                  borderTop: 0,
                },
              ]}
            >
              <View style={[styles.tableRow, styles.regularFontSize]}>
                <Text
                  style={[
                    styles.tableContent,
                    {
                      width: "45%",
                    },
                  ]}
                >
                  CADMIUM(CD)
                </Text>
                <Text
                  style={[
                    styles.tableContent,
                    {
                      width: "45%",
                    },
                  ]}
                >
                  AL/SOP/6.4/C-157
                </Text>
                <Text
                  style={[
                    styles.tableContent,
                    {
                      borderRightWidth: 0,
                      width: "35%",
                    },
                  ]}
                >
                  BLQ(Q.L.=0.05)
                </Text>
              </View>
            </View>
            {/* data 6 */}
            <View
              style={[
                styles.tableContainer,
                styles.mediumFontSize,
                {
                  width: "85%",
                  marginTop: 0,
                  borderTop: 0,
                },
              ]}
            >
              <View style={[styles.tableRow, styles.regularFontSize]}>
                <Text
                  style={[
                    styles.tableContent,
                    {
                      width: "45%",
                    },
                  ]}
                >
                  LEAD(PD)
                </Text>
                <Text
                  style={[
                    styles.tableContent,
                    {
                      width: "45%",
                    },
                  ]}
                >
                  AL/SOP/6.4/C-157
                </Text>
                <Text
                  style={[
                    styles.tableContent,
                    {
                      borderRightWidth: 0,
                      width: "35%",
                    },
                  ]}
                >
                  BLQ(Q.L.=0.05)
                </Text>
              </View>
            </View>

            {/* data 7 */}
            <View
              style={[
                styles.tableContainer,
                styles.mediumFontSize,
                {
                  width: "85%",
                  marginTop: 0,
                  borderTop: 0,
                },
              ]}
            >
              <View style={[styles.tableRow, styles.regularFontSize]}>
                <Text
                  style={[
                    styles.tableContent,
                    {
                      width: "45%",
                    },
                  ]}
                >
                  SALMONELLA SPP
                </Text>
                <Text
                  style={[
                    styles.tableContent,
                    {
                      width: "45%",
                    },
                  ]}
                >
                  IS 5887 P-3 SEC-1: 2020
                </Text>
                <Text
                  style={[
                    styles.tableContent,
                    {
                      borderRightWidth: 0,
                      width: "35%",
                    },
                  ]}
                >
                  ABSENT
                </Text>
              </View>
            </View>

            {/* data 8 */}
            <View
              style={[
                styles.tableContainer,
                styles.mediumFontSize,
                {
                  width: "85%",
                  marginTop: 0,
                  borderTop: 0,
                },
              ]}
            >
              <View style={[styles.tableRow, styles.regularFontSize]}>
                <Text
                  style={[
                    styles.tableContent,
                    {
                      width: "45%",
                    },
                  ]}
                >
                  NEW CROP
                </Text>
                <Text
                  style={[
                    styles.tableContent,
                    {
                      width: "45%",
                    },
                  ]}
                >
                  IS 4333(P-I) : 1996
                </Text>
                <Text
                  style={[
                    styles.tableContent,
                    {
                      borderRightWidth: 0,
                      width: "35%",
                    },
                  ]}
                >
                  ABSENT
                </Text>
              </View>
            </View>

            {/* data 9 */}
            <View
              style={[
                styles.tableContainer,
                styles.mediumFontSize,
                {
                  width: "85%",
                  marginTop: 0,
                  borderTop: 0,
                },
              ]}
            >
              <View style={[styles.tableRow, styles.regularFontSize]}>
                <Text
                  style={[
                    styles.tableContent,
                    {
                      width: "45%",
                    },
                  ]}
                >
                  FREE FROM ANY LIVE OR DEAD WEEVILS AND INSECT, NO ODD SMELLS,
                  PURE SONS MASOORI WHITE RICE, NO SWARNA MIXING INDIAN ORIGIN,
                  WELL FRESHLY FUMIGATED FREE FROM FUNGUS
                </Text>
                <Text
                  style={[
                    styles.tableContent,
                    {
                      width: "45%",
                      paddingTop: 25,
                    },
                  ]}
                >
                  AL/SOP/6.4/C-157
                </Text>
                <Text
                  style={[
                    styles.tableContent,
                    {
                      borderRightWidth: 0,
                      width: "35%",
                      paddingTop: 25,
                    },
                  ]}
                >
                  ABSENT
                </Text>
              </View>
            </View>
          </View>

          <View style={[styles.sideBySideContainer]}>
            <View style={[styles.content]}>
              <Text style={[styles.boldText, styles.paragraph]}>
                OUR CERTIFICATE IS BASED ON INSPECTION CARRIED OUT AT THE TIME
                AND PLACE OF STUFFING OF CONTAINERS PRIOR SHIPMENT. NO
                RESPONSIBILITY CAN BE ASCRIBED TO OR ASSUMED BY US FOR ANY
                DEVIATION, QUALITATIVE OR QUANTITATIVE, ARISING OUT OF INHERENT
                VICE OF THE PRODUCT AND/OR EFFECTS/LOSS OCCURRING IN TRANSIT
                INCLUDING SWEATING OF CARGO. ISSUED WITHOUT PREJUDICE.
              </Text>
            </View>
          </View>
          <View style={styles.content}>
            <Text
              style={[styles.boldText, { paddingLeft: 250, paddingTop: 10 }]}
            >
              For, ACCURATE UNIVERSAL LABORATORIES PRIVATE LIMITED
            </Text>
          </View>

          <View style={styles.content}>
            <Text
              style={[styles.boldText, { paddingLeft: 350, paddingTop: 30 }]}
            >
              AUTHORISED SIGNATOR
            </Text>
          </View>
        </Page>
      </Document>
    </PDFViewer>
  );
};

export default WeightAndQualityCertificate;
