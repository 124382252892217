/* eslint-disable react-hooks/exhaustive-deps */
import * as Yup from "yup";
import { useEffect, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { Link, useNavigate, useParams } from "react-router-dom";
import { errorToast, successToast } from "../../components/Toast";
import { getProductByIdApi, updateProductApi } from "../../services/_product";

const EditProduct = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [updateLoading, setUpdateLoading] = useState<boolean>(false);
  useEffect(() => {
    if (!id) {
      navigate("/product-list");
    } else {
      getProductDetails();
    }
  }, []);

  const createProductInitialValues = {
    name: "",
    description: "",
    hsn_no: "",
    status: "",
  };

  const getProductDetails = async () => {
    const result: any = await getProductByIdApi(id as string);
    if (result.isSuccess) {
      setInitialValues({
        name: result?.data?.name,
        description: result?.data?.description,
        hsn_no: result?.data?.hsn_no,
        status: result?.data?.status?.toString(),
      });
    }
  };

  const [initialValues, setInitialValues] = useState(
    createProductInitialValues
  );

  const createProductValidationSchema = Yup.object().shape({
    name: Yup.string().required("Name is required"),
    description: Yup.string(),
    hsn_no: Yup.string(),
    status: Yup.string().required("Status is required"),
  });

  const handleSubmit = async (values: any, { resetForm }: any) => {
    setUpdateLoading(true);
    values.status = values.status === "true" ? true : false;
    const result: any = await updateProductApi(id as string, values);
    if (result.isSuccess) {
      setUpdateLoading(false);
      resetForm({ values: createProductInitialValues });
      successToast(result.message);
      navigate("/product-list");
    } else {
      setUpdateLoading(false);
      errorToast(result.message);
    }
    return;
  };

  return (
    <>
      <div className="card">
        <div className="card-header pb-0">
          <div>
            <h5>Edit Product</h5>
          </div>
        </div>
        <div className="card-body">
          <Formik
            enableReinitialize={true}
            initialValues={initialValues}
            validationSchema={createProductValidationSchema}
            onSubmit={handleSubmit}
          >
            {({ values, handleChange }) => {
              return (
                <Form className="dt_adv_search">
                  <div className="row">
                    <div className="col-12">
                      <div className="row g-3">
                        <div className="col-12 col-sm-6 col-lg-4">
                          <label
                            htmlFor="name"
                            className="form-label"
                            style={{ fontSize: "0.9375rem" }}
                          >
                            Name: <span className="text-danger">*</span>
                          </label>
                          <Field
                            id="name"
                            name="name"
                            type="text"
                            className="form-control"
                            placeholder="Enter name"
                            onChange={handleChange}
                          />
                          <ErrorMessage
                            name="name"
                            className="text-danger"
                            component="div"
                          />
                        </div>
                        <div className="col-12 col-sm-6 col-lg-4">
                          <label
                            htmlFor="hsn_no"
                            className="form-label"
                            style={{ fontSize: "0.9375rem" }}
                          >
                            Hsn No:
                          </label>
                          <Field
                            id="hsn_no"
                            name="hsn_no"
                            type="text"
                            className="form-control"
                            placeholder="Enter hsn no"
                            onChange={handleChange}
                          />
                          <ErrorMessage
                            name="hsn_no"
                            className="text-danger"
                            component="div"
                          />
                        </div>
                        <div className="col-12 col-sm-6 col-lg-4">
                          <label
                            htmlFor="status"
                            className="form-label"
                            style={{ fontSize: "0.9375rem" }}
                          >
                            Status: <span className="text-danger">*</span>
                          </label>
                          <select
                            id="status"
                            name="status"
                            className="form-select"
                            onChange={handleChange}
                            value={values?.status}
                          >
                            <option value="">Select Status</option>
                            <option value="true">Active</option>
                            <option value="false">Inactive</option>
                          </select>
                          <ErrorMessage
                            name="status"
                            className="text-danger"
                            component="div"
                          />
                        </div>
                        <div className="col-12">
                          <label
                            htmlFor="description"
                            className="form-label"
                            style={{ fontSize: "0.9375rem" }}
                          >
                            Description:
                          </label>
                          <Field
                            id="description"
                            name="description"
                            className="form-control"
                            placeholder="Enter description"
                            onChange={handleChange}
                            as={"textarea"}
                            rows={2}
                          ></Field>
                        </div>
                        <div className="col-12">
                          <button
                            type="submit"
                            className="btn btn-primary"
                            disabled={updateLoading}
                          >
                            {updateLoading && (
                              <span
                                className="spinner-border spinner-border-sm me-2"
                                role="status"
                                aria-hidden="true"
                              ></span>
                            )}
                            Submit
                          </button>
                          <Link
                            to={"/product-list"}
                            type="button"
                            className="btn btn-label-github ms-5"
                          >
                            Back
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </Form>
              );
            }}
          </Formik>
        </div>
      </div>
    </>
  );
};

export default EditProduct;
