import { ErrorMessage, FormikValues } from "formik";
import { BankType } from "../../../../components/Enums";
interface BankFieldsProps {
  exporterBankList: any;
  buyerBankList: any;
  values: FormikValues;
  openAddBankModal: (show: boolean, type: string) => void;
  handleChange: (e: React.ChangeEvent<any>) => void;
}

const BankFields: React.FC<BankFieldsProps> = ({
  exporterBankList,
  buyerBankList,
  openAddBankModal,
  values,
  handleChange,
}) => {
  return (
    <div className="card my-card">
      <div className="card-header my-card-header bg-lighter">
        <div>
          <h6 className="my-card-header-head">Bank Details</h6>
        </div>
      </div>
      <div className="card-body my-card-body">
        <div className="row g-3 m-0">
          <div className="col-12 col-sm-6 col-md-4 col-lg-4">
            <div className="d-flex justify-content-between">
              <div>
                <label
                  htmlFor="exporter_bank_id"
                  className="form-label"
                  style={{ fontSize: "0.9375rem" }}
                >
                  Exporter Bank:
                </label>
              </div>
              <div>
                <button
                  title="Add Bank"
                  className="btn btn-sm btn-primary waves-effect waves-light p-0"
                  type="button"
                  onClick={() => openAddBankModal(true, BankType.EXPORTER)}
                >
                  <span>
                    <i className="ti ti-plus my-card-icon-button"></i>
                  </span>
                </button>
              </div>
            </div>

            <select
              name="exporter_bank_id"
              className="form-select"
              id="exporter_bank_id"
              value={values.exporter_bank_id}
              onChange={handleChange}
            >
              <option defaultChecked value={""}>
                Select Exporter Bank
              </option>
              {exporterBankList.map((e: any) => {
                return (
                  <option value={e.id} key={e.id}>
                    {e.bank_name} - {e.account_number}
                  </option>
                );
              })}
            </select>
            <ErrorMessage
              name="exporter_bank_id"
              className="text-danger"
              component="div"
            />
          </div>
          <div className="col-12 col-sm-6 col-md-4 col-lg-4">
            <div className="d-flex justify-content-between">
              <div>
                <label
                  htmlFor="buyer_bank_id"
                  className="form-label"
                  style={{ fontSize: "0.9375rem" }}
                >
                  Buyer Bank:
                </label>
              </div>
              <div>
                <button
                  title="Add Bank"
                  className="btn btn-sm btn-primary waves-effect waves-light p-0"
                  type="button"
                  onClick={() => openAddBankModal(true, BankType.BUYER)}
                >
                  <span>
                    <i className="ti ti-plus my-card-icon-button"></i>
                  </span>
                </button>
              </div>
            </div>

            <select
              name="buyer_bank_id"
              className="form-select"
              id="buyer_bank_id"
              value={values.buyer_bank_id}
              onChange={handleChange}
            >
              <option defaultChecked value={""}>
                Select Buyer Bank
              </option>
              {buyerBankList.map((e: any) => {
                return (
                  <option value={e.id} key={e.id}>
                    {e.bank_name} - {e.account_number}
                  </option>
                );
              })}
            </select>
            <ErrorMessage
              name="buyer_bank_id"
              className="text-danger"
              component="div"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default BankFields;
