import { GET, POST, PUT } from "./_config";

export const documentListApi = async (query: string) =>
  await GET(`/document?${query || ""}`, true);

export const getAllDocumentApi = async () => await GET(`/document/list`, true);

export const createDocumentApi = async (body: any) =>
  await POST(`/document`, body, true);

export const getDocumentByIdApi = async (documentId: string) =>
  await GET(`/document/id/${documentId}`, true);

export const updateDocumentApi = async (documentId: string, body: any) =>
  await PUT(`/document?id=${documentId}`, body, true);

export const updateDocumentStatusApi = async (body: any) =>
  await POST(`/document/update-status`, body, true);

const DocumentService = {
  documentListApi,
  createDocumentApi,
  getDocumentByIdApi,
  updateDocumentApi,
  updateDocumentStatusApi,
  getAllDocumentApi
};

export default DocumentService;
