import { Link, useNavigate } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { forgetPasswordApi } from "../../services/_auth";
import { errorToast, successToast } from "../../components/Toast";
import { COMPANY_NAME } from "../../components/Constants";
import { useState } from "react";
import Spinner from "../../theme/Spinner";

const ForgetPassword = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const forgetPasswordValidationSchema = Yup.object().shape({
    email: Yup.string()
      .email("Invalid email format")
      .required("Email is required"),
  });
  const loginInitialValues = {
    email: "",
  };

  const handleSubmit = async (values: any) => {
    setLoading(true);

    const result: any = await forgetPasswordApi(values);
    if (!result.isSuccess) {
      errorToast(result.message);
      setLoading(false);
      return;
    } else {
      successToast(`OTP sent successfully to your email id!`);
      navigate(`/verify-otp/${values.email}`);
      setLoading(false);
    }
    setLoading(false);
  };

  return (
    <div className="container-xxl">
      <div className="authentication-wrapper authentication-basic container-p-y">
        <div className="authentication-inner py-6">
          <div className="card">
            {loading && <Spinner isFullScreen={true} />}

            <div className="card-body">
              <div className="app-brand justify-content-center mb-6">
                <Link to="#" className="app-brand-link">
                  <span
                    className="app-brand-logo demo"
                    style={{ alignItems: "unset !important", height: "44px" }}
                  >
                    <img src="/img/logo/ee-main.svg" alt={COMPANY_NAME} />
                  </span>
                  <span className="app-brand-text demo text-heading fw-bold">
                    {COMPANY_NAME}
                  </span>
                </Link>
              </div>
              <h4 className="mb-1">Forgot Password? 🔒</h4>
              <p className="mb-6">
                Enter your email and we'll send you an OTP to reset your
                password
              </p>

              <Formik
                initialValues={loginInitialValues}
                validationSchema={forgetPasswordValidationSchema}
                onSubmit={handleSubmit}
              >
                {({ handleChange }) => {
                  return (
                    <Form className="mb-4">
                      <div className="mb-6">
                        <label htmlFor="email" className="form-label">
                          Email
                        </label>
                        <Field
                          type="email"
                          onChange={handleChange}
                          className="form-control"
                          id="email"
                          name="email"
                          placeholder="Enter your email"
                        />
                        <ErrorMessage
                          name="email"
                          className="text-danger"
                          component="div"
                        />
                      </div>
                      <div className="mb-6">
                        <button
                          className="btn btn-primary d-grid w-100"
                          type="submit"
                        >
                          Send OTP
                        </button>
                      </div>
                      <div className="text-center">
                        <Link
                          to={"/login"}
                          className="d-flex align-items-center justify-content-center"
                        >
                          <i className="ti ti-chevron-left scaleX-n1-rtl me-1_5"></i>
                          Back to login
                        </Link>
                      </div>
                    </Form>
                  );
                }}
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgetPassword;
