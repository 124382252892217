import * as Yup from "yup";
import moment from "moment";
import { useState } from "react";
import { DateRangePicker, DatePicker } from "rsuite";
import { ErrorMessage, Formik, Form } from "formik";
import "rsuite/DateRangePicker/styles/index.css";

const DatePickers = () => {
  const [selectedDate1, setSelectedDate1] = useState(null);
  const [selectedDate2, setSelectedDate2] = useState(null);
  const [selectedDate3, setSelectedDate3] = useState(null);

  const dateValidationSchema = Yup.object().shape({
    date1: Yup.array().required("Date is required"),
    date2: Yup.string().required("Date is required"),
    date3: Yup.string().required("Date is required"),
  });

  return (
    <div className="card mb-6">
      <div className="card-header pb-0 d-flex justify-content-between">
        <div>
          <h5>Date Picker</h5>
        </div>
      </div>
      <div className="card-body">
        <Formik
          validationSchema={dateValidationSchema}
          initialValues={{
            date1: "",
            date2: "",
            date3: "",
          }}
          onSubmit={async (values) => {
            const dateRange = {
              from: moment(values.date1[0]).format("YYYY-MM-DD"),
              to: moment(values.date1[1]).format("YYYY-MM-DD"),
            };
            const date2 = moment(values.date2).format("YYYY-MM-DD");
            const date3 = moment(values.date3).format("YYYY-MM-DD HH:mm:ss");
            console.log("date1", dateRange);
            console.log("date2", date2);
            console.log("date3", date3);
          }}
          onReset={() => {
            setSelectedDate1(null);
            setSelectedDate2(null);
            setSelectedDate3(null);
          }}
        >
          {({ handleSubmit, setFieldValue, resetForm }) => {
            return (
              <Form onSubmit={handleSubmit}>
                <div className="row g-3">
                  <div className="col-12 col-sm-6 col-lg-4">
                    <label
                      className="form-label"
                      style={{ fontSize: "0.9375rem" }}
                    >
                      Select Date Range:
                    </label>
                    <DateRangePicker
                      id="date1"
                      name="date1"
                      format="dd-MM-yyyy"
                      className="form-control"
                      placeholder="From Date ~ To Date"
                      value={selectedDate1}
                      onChange={(values: any) => {
                        setFieldValue("date1", values);
                        setSelectedDate1(values);
                      }}
                    />
                    <ErrorMessage
                      name="date1"
                      className="text-danger"
                      component="div"
                    />
                  </div>
                  <div className="col-12 col-sm-6 col-lg-4">
                    <label
                      className="form-label"
                      style={{ fontSize: "0.9375rem" }}
                    >
                      Select Date:
                    </label>
                    <DatePicker
                      id="date2"
                      name="date2"
                      format="dd-MM-yyyy"
                      className="form-control"
                      placeholder="Select Date"
                      value={selectedDate2}
                      onChange={(values: any) => {
                        setFieldValue("date2", values);
                        setSelectedDate2(values);
                      }}
                    />
                    <ErrorMessage
                      name="date2"
                      className="text-danger"
                      component="div"
                    />
                  </div>
                  <div className="col-12 col-sm-6 col-lg-4">
                    <label
                      className="form-label"
                      style={{ fontSize: "0.9375rem" }}
                    >
                      Select Date-Time:
                    </label>
                    <DatePicker
                      id="date3"
                      name="date3"
                      format="dd-MM-yyyy HH:mm:ss"
                      className="form-control"
                      placeholder="Select Date-Time"
                      value={selectedDate3}
                      onChange={(values: any) => {
                        setFieldValue("date3", values);
                        setSelectedDate3(values);
                      }}
                    />
                    <ErrorMessage
                      name="date3"
                      className="text-danger"
                      component="div"
                    />
                  </div>
                  <div className="col-12">
                    <button type="submit" className="btn btn-primary">
                      Submit
                    </button>
                    <button
                      type="button"
                      className="btn btn-label-github ms-5"
                      onClick={() => {
                        resetForm();
                      }}
                    >
                      Reset
                    </button>
                  </div>
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
    </div>
  );
};

export default DatePickers;
