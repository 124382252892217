import { TextField } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";

const CountryStateCityField = ({
  countryList,
  stateList,
  cityList,
  values,
  setFieldValue,
  handleCountryChange,
  handleStateChange,
}: any) => {
  return (
    <>
      <div className="col-12 col-sm-6 col-lg-4">
        <label
          htmlFor="country"
          className="form-label"
          style={{ fontSize: "0.9375rem" }}
        >
          Country:
        </label>
        <Autocomplete
          disablePortal
          options={countryList.map((country: any) => ({
            name: country.name,
            isoCode: country.isoCode,
          }))}
          getOptionLabel={(option: any) => option.name || ""}
          onChange={(e, newValue) => {
            handleCountryChange(newValue);
            setFieldValue("country", newValue?.name);
            setFieldValue("state", "");
            setFieldValue("city", "");
          }}
          value={
            countryList?.find(
              (country: any) => country.name === values.country
            ) || { name: values.country, isoCode: "" }
          }
          renderInput={(params: any) => (
            <TextField
              {...params}
              className="form-control"
              type="text"
              placeholder="Enter Country"
              value={values.country}
              onChange={(e) => {
                handleCountryChange(e.target.value);
                setFieldValue("country", e.target.value);
                setFieldValue("state", "");
                setFieldValue("city", "");
              }}
            />
          )}
        />
      </div>
      <div className="col-12 col-sm-6 col-lg-4">
        <label
          htmlFor="state"
          className="form-label"
          style={{ fontSize: "0.9375rem" }}
        >
          State:
        </label>
        <Autocomplete
          disablePortal
          options={stateList.map((state: any) => ({
            name: state.name,
            isoCode: state.isoCode,
            countryCode: state.countryCode,
          }))}
          getOptionLabel={(option: any) => option.name || ""}
          onChange={(e, newValue) => {
            handleStateChange(newValue);
            setFieldValue("state", newValue?.name);
            setFieldValue("city", "");
          }}
          value={
            stateList?.find((state: any) => state.name === values.state) || {
              name: values.state,
              isoCode: "",
              countryCode: "",
            }
          }
          renderInput={(params: any) => (
            <TextField
              {...params}
              className="form-control"
              type="text"
              placeholder="Enter State"
              value={values.state}
              onChange={(e) => {
                handleStateChange(e.target.value);
                setFieldValue("state", e.target.value);
                setFieldValue("city", "");
              }}
            />
          )}
        />
      </div>
      <div className="col-12 col-sm-6 col-lg-4">
        <label
          htmlFor="city"
          className="form-label"
          style={{ fontSize: "0.9375rem" }}
        >
          City:
        </label>
        <Autocomplete
          disablePortal
          options={cityList.map((city: any) => city.name)}
          onChange={(e, newValue) => {
            setFieldValue("city", newValue);
          }}
          value={values.city}
          renderInput={(params: any) => (
            <TextField
              {...params}
              className="form-control"
              type="text"
              placeholder="Enter State"
              value={values.city}
              onChange={(e) => {
                setFieldValue("city", e.target.value);
              }}
            />
          )}
        />
      </div>
    </>
  );
};

export default CountryStateCityField;
