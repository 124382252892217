import { Field, FieldArray, ErrorMessage } from "formik";
import { FilePond } from "react-filepond";
import { getFileNameFromUrl } from "../../../../components/Helper";

interface BillFieldsProps {
  actionType: string;
  values: any;
  billList: any[];
  filePondBills: any;
  setFilePondBills: (files: any) => void;
  setAddBillTypeModalStatus: (show: boolean) => void;
  handleFileChange: (
    fileItems: any,
    setFieldValue: any,
    inputKey: string
  ) => void;
  setFieldValue: any;
}

const BillFields: React.FC<BillFieldsProps> = ({
  actionType,
  values,
  billList,
  filePondBills,
  setFilePondBills,
  setAddBillTypeModalStatus,
  handleFileChange,
  setFieldValue,
}) => {
  return (
    <div className="card my-card">
      <FieldArray
        key={"bills"}
        name="bills"
        render={({ push, remove }) => (
          <>
            <div className="card-header my-card-header bg-lighter d-flex justify-content-between">
              <div>
                <h6 className="my-card-header-head">Bills</h6>
              </div>
              <div>
                <button
                  title="Add Bill"
                  className="btn btn-sm btn-primary waves-effect waves-light p-0"
                  type="button"
                  style={{ margin: "5px" }}
                  onClick={() => {
                    push({ bill_id: "", files: [] });
                  }}
                >
                  <span>
                    <i className="ti ti-plus my-card-icon-button"></i>
                  </span>
                </button>
              </div>
            </div>
            {actionType === "create" ? (
              <>
                {values.bills.map((doc: any, index: number) => (
                  <div
                    key={index}
                    className="card-body my-card-body"
                    style={{ paddingBottom: 0, paddingTop: 0 }}
                  >
                    <div className="sales_section_card" key={index}>
                      <div className="row g-3 m-0">
                        <div className="col-12 col-sm-5 col-md-4">
                          <div className="d-flex justify-content-between">
                            <div>
                              <label
                                htmlFor={`bills[${index}].bill_id`}
                                className="form-label"
                                style={{ fontSize: "0.9375rem" }}
                              >
                                Bill Type:{" "}
                                <span className="text-danger">*</span>
                              </label>
                            </div>
                            <div>
                              <button
                                title="Add Document"
                                className="btn btn-sm btn-primary waves-effect waves-light p-0"
                                type="button"
                                onClick={() => setAddBillTypeModalStatus(true)}
                              >
                                <span>
                                  <i className="ti ti-plus my-card-icon-button"></i>
                                </span>
                              </button>
                            </div>
                          </div>
                          <Field
                            as="select"
                            name={`bills[${index}].bill_id`}
                            className="form-select"
                          >
                            <option value="">Select Bill Type</option>
                            {billList.map((data) => {
                              const isOptionDisabled = values.bills.some(
                                (doc: any, docIndex: number) =>
                                  doc.bill_id === data.id && docIndex !== index
                              );

                              return (
                                <option
                                  key={data.id}
                                  value={data.id}
                                  disabled={isOptionDisabled}
                                >
                                  {data.name}
                                </option>
                              );
                            })}
                          </Field>
                          <ErrorMessage
                            name={`bills[${index}].bill_id`}
                            component="div"
                            className="text-danger"
                          />
                        </div>
                        <div className="col-12 col-sm-7 col-md-8">
                          <div className="file-pond-main-block">
                            <FilePond
                              files={filePondBills[index]}
                              onupdatefiles={(fileItems) => {
                                const newData = filePondBills;
                                newData[index] = fileItems;
                                setFilePondBills(newData);
                                handleFileChange(
                                  fileItems,
                                  setFieldValue,
                                  `bills[${index}].files`
                                );
                              }}
                              allowMultiple
                              name={`bills[${index}].files`}
                              labelIdle="Drag & drop files or click to browse"
                            />
                          </div>
                          <ErrorMessage
                            name={`bills[${index}].files`}
                            component="div"
                            className="text-danger"
                          />
                        </div>
                        <i
                          className="fa-solid fa-xmark sales_document_btn"
                          onClick={() => remove(index)}
                        ></i>
                      </div>
                    </div>
                  </div>
                ))}
              </>
            ) : (
              <>
                {values.bills.map((row: any, index: number) => (
                  <div
                    className="card-body my-card-body"
                    style={{ paddingBottom: 0, paddingTop: 0 }}
                  >
                    <div className="sales_section_card" key={index}>
                      <div className="row g-3 m-0">
                        <div className="col-12 col-md-5 col-sm-5">
                          <div className="d-flex justify-content-between">
                            <div>
                              <label
                                htmlFor={`bills[${index}].bill_id`}
                                className="form-label"
                                style={{ fontSize: "0.9375rem" }}
                              >
                                Bill Type:{" "}
                                <span className="text-danger">*</span>
                              </label>
                            </div>
                            <div>
                              <button
                                title="Add Document"
                                className="btn btn-sm btn-primary waves-effect waves-light p-0"
                                type="button"
                                onClick={() => setAddBillTypeModalStatus(true)}
                              >
                                <span>
                                  <i className="ti ti-plus my-card-icon-button"></i>
                                </span>
                              </button>
                            </div>
                          </div>
                          <div>
                            <Field
                              as="select"
                              name={`bills[${index}].bill_id`}
                              className="form-select"
                            >
                              <option value="">Select Bill Type</option>
                              {billList.map((data) => {
                                const isOptionDisabled = values.bills.some(
                                  (doc: any, docIndex: number) =>
                                    doc.bill_id === data.id &&
                                    docIndex !== index
                                );

                                return (
                                  <option
                                    key={data.id}
                                    value={data.id}
                                    disabled={isOptionDisabled}
                                  >
                                    {data.name}
                                  </option>
                                );
                              })}
                            </Field>
                            <ErrorMessage
                              name={`bills[${index}].bill_id`}
                              component="div"
                              className="text-danger"
                            />
                          </div>
                          <div className="mt-3">
                            <div className="file-pond-main-block">
                              <FilePond
                                files={filePondBills[index]}
                                onupdatefiles={(fileItems) => {
                                  const newData = filePondBills;
                                  newData[index] = fileItems;
                                  setFilePondBills(newData);
                                  handleFileChange(
                                    fileItems,
                                    setFieldValue,
                                    `bills[${index}].files`
                                  );
                                }}
                                allowMultiple
                                name={`bills[${index}].files`}
                                labelIdle="Drag & drop files or click to browse"
                              />
                            </div>
                            <ErrorMessage
                              name={`bills[${index}].files`}
                              component="div"
                              className="text-danger"
                            />
                          </div>
                        </div>
                        <div className="col-12 col-sm-7 col-md-7">
                          <div
                            className="file-pond-main-block d-flex justify-content-left"
                            style={{
                              height: "100%",
                              alignItems: "center",
                              padding: "15px",
                            }}
                          >
                            <div className="row p-0">
                              {row.existing_files &&
                                row.existing_files.map((file: any) => (
                                  <>
                                    <div
                                      className="col-md mb-md-0"
                                      style={{
                                        width: "165px",
                                        height: "100%",
                                        position: "relative",
                                      }}
                                    >
                                      <div className="form-check custom-option custom-option-icon">
                                        <label
                                          className="form-check-label custom-option-content"
                                          onClick={() =>
                                            window.open(
                                              `${process.env.REACT_APP_BACKEND_URL}${file}`,
                                              "_blank"
                                            )
                                          }
                                        >
                                          <span className="custom-option-body">
                                            <small>
                                              {getFileNameFromUrl(
                                                `${process.env.REACT_APP_BACKEND_URL}${file}`
                                              )}
                                            </small>
                                          </span>
                                        </label>
                                      </div>
                                      <i
                                        className="fa-solid fa-xmark remove_document_file_btn"
                                        onClick={() => {
                                          row.remove_files.push(file);
                                          const newFiles =
                                            row?.existing_files?.filter(
                                              (x: string) => x !== file
                                            );
                                          row.existing_files = newFiles;
                                          setFieldValue(`bills[${index}]`, row);
                                        }}
                                      ></i>
                                    </div>
                                  </>
                                ))}
                            </div>
                          </div>
                        </div>
                        <i
                          className="fa-solid fa-xmark sales_document_btn"
                          onClick={() => remove(index)}
                        ></i>
                      </div>
                    </div>
                  </div>
                ))}
              </>
            )}
          </>
        )}
      />
    </div>
  );
};

export default BillFields;
