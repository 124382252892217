/* eslint-disable react-hooks/exhaustive-deps */
import { pdf } from "@react-pdf/renderer";
import Spinner from "../../theme/Spinner";
import PurchaseInvoice from "./PurchaseInvoice";
import { useEffect, useState } from "react";
import { CURRENCY } from "../../components/Constants";
import { Link, useNavigate, useParams } from "react-router-dom";
import { getPurchaseByIdApi } from "../../services/_purchase";
import { getSettingsApi } from "../../services/_settings";
import {
  formatAmount,
  formatDate,
  stringToUpperCase,
} from "../../components/Helper";

const ViewPurchase: React.FC = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [purchaseDetails, setPurchaseDetails] = useState<any>({});
  const [purchaseProduct, setPurchaseProduct] = useState<any[]>([]);
  const [hsnWiseProductData, setHsnWiseProductData] = useState<any>([]);
  const [settingData, setSettingData] = useState<any>({});
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    if (!id) {
      navigate(`/purchase-list`);
    } else {
      getPurchaseDetails();
      getSettingDetails();
    }
  }, []);

  const getPurchaseDetails = async () => {
    setLoading(true);
    const result: any = await getPurchaseByIdApi(id as string);
    if (result.isSuccess) {
      setPurchaseDetails(result.data);
      setPurchaseProduct(result.data.purchase_item);
      hsnWiseProductGrouping(result.data.purchase_item);
    }
    setLoading(false);
  };

  const getSettingDetails = async () => {
    const result: any = await getSettingsApi();
    if (result.isSuccess) {
      setSettingData(result.data);
    }
  };

  const hsnWiseProductGrouping = (data: any) => {
    const groupedData = data.reduce((acc: any, item: any) => {
      const hsn = item.product.hsn_no;
      const total = parseFloat(item.sub_total);
      const cgst = parseFloat(item.cgst);
      const sgst = parseFloat(item.sgst);
      const gst = parseFloat(item.gst_amount);
      const gst_percentage = Number(item.gst_percentage);

      if (!acc[hsn]) {
        acc[hsn] = {
          hsn_no: hsn,
          total: 0,
          cgst: 0,
          sgst: 0,
          gst: 0,
          gst_percentage: gst_percentage,
          items: [],
        };
      }

      acc[hsn].total += total;
      acc[hsn].cgst += cgst;
      acc[hsn].sgst += sgst;
      acc[hsn].gst += gst;
      acc[hsn].items.push(item);

      return acc;
    }, {});

    const result = Object.values(groupedData);
    setHsnWiseProductData(result);
  };

  const calculateTotal = (items: any, keyName: string) => {
    let total = 0;
    items.forEach((item: any) => {
      total += Number(item[keyName]);
    });
    return total;
  };

  const previewPurchaseInvoice = async (purchaseData: any) => {
    try {
      const pdfDoc = await pdf(
        <PurchaseInvoice
          purchaseData={purchaseData}
          settingData={settingData}
          hsnWiseProductData={hsnWiseProductData}
        />
      ).toBlob();
      const pdfUrl = URL.createObjectURL(pdfDoc);
      window.open(pdfUrl, "_blank");
    } catch (err) {
      console.error("Error previewing purchase invoice:", err);
    }
  };

  const downloadPurchaseInvoice = (purchaseData: any) => {
    const pdfDoc = pdf(
      <PurchaseInvoice
        purchaseData={purchaseData}
        settingData={settingData}
        hsnWiseProductData={hsnWiseProductData}
      />
    );
    pdfDoc.toBlob().then((blob) => {
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = `${purchaseData.invoice_no}-purchase-order.pdf`;
      link.click();
    });
  };

  return (
    <>
      <div className="card">
        <div className="card-header pb-0 d-flex justify-content-between">
          <div>
            <h5>Purchase Details :</h5>
          </div>
          <div className="d-flex" style={{ gap: "10px" }}>
            <button
              className="btn btn-primary waves-effect waves-light"
              type="button"
              onClick={() => previewPurchaseInvoice(purchaseDetails)}
            >
              <span>
                <i className="ti ti-file me-sm-1"></i>{" "}
                <span className="d-none d-sm-inline-block">
                  Preview Invoice
                </span>
              </span>
            </button>
            <button
              className="btn btn-primary waves-effect waves-light"
              type="button"
              onClick={() => downloadPurchaseInvoice(purchaseDetails)}
            >
              <span>
                <i className="ti ti-download me-sm-1"></i>{" "}
                <span className="d-none d-sm-inline-block">Download</span>
              </span>
            </button>
            <Link
              to={"/purchase-list"}
              type="button"
              className="btn btn-label-github"
            >
              Back
            </Link>
          </div>
        </div>
        <div className="card-body">
          {loading && <Spinner isFullScreen={false} />}
          <div>
            <div
              className="col-12 col-sm-6 col-md-4 col-lg-3 row m-1 p-2 d-flex info-container text-capitalize"
              style={{
                border: "1px solid #d1d0d4",
                borderRadius: "0.375rem",
                width: "100%",
              }}
            >
              <div className="col-12 col-md-6 mb-3">
                <span className="h6 me-1">Invoice No :</span>
                {purchaseDetails?.invoice_no || ""}
              </div>
              <div className="col-12 col-md-6 mb-3">
                <span className="h6 me-1">Purchase Date:</span>
                {formatDate(purchaseDetails.purchase_date)}
              </div>
              <div className="col-12 col-md-6 mb-3">
                <span className="h6 me-1">Supplier:</span>
                {purchaseDetails?.supplier?.name || ""}
              </div>
              <div className="col-12 col-md-6 mb-3">
                <span className="h6 me-1">Sub Total :</span>
                {CURRENCY.INR.SYMBOL}
                {formatAmount(purchaseDetails?.sub_total)}
              </div>
              <div className="col-12 col-md-6 mb-3">
                <span className="h6 me-1">GST Amount :</span>
                {CURRENCY.INR.SYMBOL}
                {formatAmount(purchaseDetails?.gst_amount)}
              </div>
              {(purchaseDetails?.round_of > 0 ||
                purchaseDetails?.round_of < 0) && (
                <div className="col-12 col-md-6 mb-3">
                  <span className="h6 me-1">Round Of Amount:</span>
                  {CURRENCY.INR.SYMBOL}
                  {formatAmount(purchaseDetails?.round_of)}
                </div>
              )}
              <div className="col-12 col-md-6 mb-3">
                <span className="h6 me-1">Total Amount:</span>
                {CURRENCY.INR.SYMBOL}
                {formatAmount(purchaseDetails?.total)}
              </div>
              <div className="col-12 col-md-6 mb-3">
                <span className="h6 me-1">Packing Details :</span>
                {purchaseDetails?.packing_details || ""}
              </div>
              <div className="col-12 col-md-6 mb-3">
                <span className="h6 me-1">Terms of Payment :</span>
                {purchaseDetails?.terms_of_payment || "-"}
              </div>
            </div>
          </div>

          <div className="mt-7 py-2 pb-0 d-flex justify-content-start align-items-center">
            <h5>Product Details :</h5>
          </div>

          <div className="card-datatable table-responsive">
            <table className="dt-advanced-search table table-nowrap">
              <thead>
                <tr>
                  <th>#</th>
                  <th>Product Name</th>
                  <th>Price</th>
                  <th>Quantity</th>
                  <th>No Of Bags</th>
                  <th>Sub Total</th>
                  <th>GST (%)</th>
                  <th>GST Amount</th>
                  <th>total</th>
                </tr>
              </thead>
              <tbody>
                {purchaseProduct ? (
                  purchaseProduct.map((row: any, index: number) => {
                    return (
                      <tr key={row.id}>
                        <td>{index + 1}</td>
                        <td>{row?.product?.name || ""}</td>
                        <td>
                          {CURRENCY.INR.SYMBOL}
                          {formatAmount(row?.price)}
                        </td>
                        <td>
                          {row?.quantity} {stringToUpperCase(row?.unit?.name)}
                        </td>
                        <td>{row?.no_of_bags || ""}</td>
                        <td>
                          {CURRENCY.INR.SYMBOL}
                          {formatAmount(row?.sub_total)}
                        </td>
                        <td>
                          {row?.gst_percentage ? `${row?.gst_percentage}%` : ""}
                        </td>
                        <td>
                          {CURRENCY.INR.SYMBOL}
                          {formatAmount(row?.gst_amount)}
                        </td>
                        <td>
                          {CURRENCY.INR.SYMBOL}
                          {formatAmount(row.total)}
                        </td>
                      </tr>
                    );
                  })
                ) : (
                  <></>
                )}
                <tr>
                  <th></th>
                  <th></th>
                  <th></th>
                  <th>{calculateTotal(purchaseProduct, "quantity")}</th>
                  <th>{calculateTotal(purchaseProduct, "no_of_bags") || ""}</th>
                  <th>
                    {CURRENCY.INR.SYMBOL}
                    {formatAmount(purchaseDetails?.sub_total)}
                  </th>
                  <th></th>
                  <th>
                    {CURRENCY.INR.SYMBOL}
                    {formatAmount(purchaseDetails?.gst_amount)}
                  </th>
                  <th>
                    {CURRENCY.INR.SYMBOL}
                    {formatAmount(purchaseDetails?.total)}
                  </th>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
};

export default ViewPurchase;
