import { GET, POST, PUT } from "./_config";

export const supplierListApi = async (query: string) =>
  await GET(`/supplier?${query || ""}`, true);

export const createSupplierApi = async (body: any) =>
  await POST(`/supplier`, body, true);

export const getSupplierByIdApi = async (supplierId: string) =>
  await GET(`/supplier/id/${supplierId}`, true);

export const getSupplierNameListApi = async () =>
  await GET(`/supplier/list`, true);

export const updateSupplierApi = async (supplierId: string, body: any) =>
  await PUT(`/supplier?id=${supplierId}`, body, true);

export const updateSupplierStatusApi = async (body: any) =>
  await POST(`/supplier/update-status`, body, true);

const SupplierService = {
  supplierListApi,
  createSupplierApi,
  getSupplierByIdApi,
  updateSupplierApi,
  updateSupplierStatusApi,
};

export default SupplierService;
