import * as Yup from "yup";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { changePasswordApi } from "../../services/_user";
import { errorToast, successToast } from "../../components/Toast";
import { useState } from "react";

interface Props {
  id: string;
}

interface visibilityProps {
  old_password: boolean;
  password: boolean;
  confirm_password: boolean;
}

const ChangePassword: React.FC<Props> = ({ id }) => {
  const [updateLoading, setUpdateLoading] = useState<boolean>(false);
  const [visibility, setVisibility] = useState<visibilityProps>({
    old_password: false,
    password: false,
    confirm_password: false,
  });

  const handleVisibility = (
    field: "old_password" | "password" | "confirm_password"
  ) => {
    setVisibility((prevVisibility) => ({
      ...prevVisibility,
      [field]: !prevVisibility[field], // Toggle the specific field's visibility
    }));
  };
  const ChangePasswordValidationSchema = Yup.object().shape({
    old_password: Yup.string().required("Old password is required"),
    password: Yup.string().required("New password is required"),
    confirm_password: Yup.string()
      .required("Confirm password is required")
      .oneOf(
        [Yup.ref("password")],
        "New password and confirm password must match"
      ),
  });

  const ChangePasswordInitialValues = {
    old_password: "",
    password: "",
    confirm_password: "",
  };

  const handleSubmit = async (
    values: any,
    { resetForm }: { resetForm: () => void }
  ) => {
    setUpdateLoading(true);
    const result: any = await changePasswordApi(id as string, values);
    if (result.isSuccess) {
      resetForm();
      successToast(result.message);
      setUpdateLoading(false);
    } else {
      errorToast(result.message);
    }
    setUpdateLoading(false);
    return;
  };

  return (
    <div
      className="modal fade"
      id="changePassword"
      tabIndex={-1}
      aria-hidden="true"
    >
      <div className="modal-dialog modal-lg modal-simple modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-body">
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
            <div className="text-center mb-6">
              <h4 className="mb-2">Change Password</h4>
            </div>
            <Formik
              initialValues={ChangePasswordInitialValues}
              validationSchema={ChangePasswordValidationSchema}
              onSubmit={handleSubmit}
            >
              {({ handleChange }) => {
                return (
                  <Form className="row g-6">
                    <div className="col-12 col-md-6">
                      <label className="form-label" htmlFor="old_password">
                        Old Password: <span className="text-danger">*</span>
                      </label>
                      <div className="input-group input-group-merge">
                        <Field
                          type={visibility.old_password ? "text" : "password"}
                          onChange={handleChange}
                          id="old_password"
                          className="form-control"
                          name="old_password"
                          placeholder="Enter Old Password"
                        />
                        <span
                          className="input-group-text cursor-pointer"
                          onClick={() => handleVisibility("old_password")}
                        >
                          <i
                            className={
                              visibility.old_password
                                ? "ti ti-eye-off"
                                : "ti ti-eye"
                            }
                          ></i>
                        </span>
                      </div>
                      <ErrorMessage
                        name="old_password"
                        className="text-danger"
                        component="div"
                      />
                    </div>
                    <div className="col-12 col-md-6">
                      <label className="form-label" htmlFor="password">
                        New Password: <span className="text-danger">*</span>
                      </label>
                      <div className="input-group input-group-merge">
                        <Field
                          type={visibility.password ? "text" : "password"}
                          onChange={handleChange}
                          id="password"
                          className="form-control"
                          name="password"
                          placeholder="Enter New Password"
                        />
                        <span
                          className="input-group-text cursor-pointer"
                          onClick={() => handleVisibility("password")}
                        >
                          <i
                            className={
                              visibility.password
                                ? "ti ti-eye-off"
                                : "ti ti-eye"
                            }
                          ></i>
                        </span>
                      </div>
                      <ErrorMessage
                        name="password"
                        className="text-danger"
                        component="div"
                      />
                    </div>
                    <div className="col-12 col-md-6">
                      <label className="form-label" htmlFor="confirm_password">
                        Confirm Password: <span className="text-danger">*</span>
                      </label>
                      <div className="input-group input-group-merge">
                        <Field
                          type={
                            visibility.confirm_password ? "text" : "password"
                          }
                          onChange={handleChange}
                          id="confirm_password"
                          className="form-control"
                          name="confirm_password"
                          placeholder="Enter Confirm Password"
                        />
                        <span
                          className="input-group-text cursor-pointer"
                          onClick={() => handleVisibility("confirm_password")}
                        >
                          <i
                            className={
                              visibility.confirm_password
                                ? "ti ti-eye-off"
                                : "ti ti-eye"
                            }
                          ></i>
                        </span>
                      </div>
                      <ErrorMessage
                        name="confirm_password"
                        className="text-danger"
                        component="div"
                      />
                    </div>
                    <div className="col-12 text-center">
                      <button
                        type="submit"
                        className="btn btn-primary me-3"
                        data-bs-dismiss={!updateLoading}
                        aria-label="Close"
                        disabled={updateLoading}
                      >
                        {updateLoading && (
                          <span
                            className="spinner-border spinner-border-sm me-2"
                            role="status"
                            aria-hidden="true"
                          ></span>
                        )}
                        Submit
                      </button>

                      <button
                        type="reset"
                        className="btn btn-label-secondary"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                      >
                        Cancel
                      </button>
                    </div>
                  </Form>
                );
              }}
            </Formik>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChangePassword;
