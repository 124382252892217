import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  Font,
} from "@react-pdf/renderer";
import moment from "moment";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";

Font.register({
  family: "HostGrotesk",
  fonts: [
    { src: "/fonts/HostGrotesk-Regular.ttf" },
    { src: "/fonts/HostGrotesk-Bold.ttf", fontWeight: "bold" },
  ],
});



// empty data
const emptyData = {
  product: {
    name: undefined,
    hsn_no: undefined,
    description: undefined,
  },
  quantity: undefined,
  unit: undefined,
  price: undefined,
  total: undefined,
};

// Define styles
export const styles = StyleSheet.create({
  boldText: {
    fontWeight: "bold",
    fontSize: 8,
  },
  smallText: {
    fontSize: 6,
  },
  mediumText: {
    fontSize: 6.5,
  },
  contentPadding: {
    padding: 1,
  },
  purchaseDetails: {
    fontSize: 7,
  },
  page: {
    paddingHorizontal: 30,
    paddingVertical: 15,
    fontFamily: "HostGrotesk",
    backgroundColor: "white",
  },
  fullpage: {
    margin: 7,
    borderStyle: "solid",
    borderWidth: 1,
    borderColor: "#000000",
  },
  header: {
    fontSize: 10,
    textAlign: "center",
    padding: 1,
    marginBottom: 0,
    fontWeight: "bold",
    borderBottomWidth: 1,
    borderBottomColor: "black",
    borderBottomStyle: "solid",
  },
  section: {
    borderBottomWidth: 1,
    borderBottomColor: "black",
    borderbottomStyle: "solid",
  },
  sideBySideContainer: { flexDirection: "row" },
  halfWidthRightSection: {
    width: "50%",
    borderBottomWidth: 1,
    borderBottomColor: "black",
    borderbottomStyle: "solid",
    borderRightWidth: 1,
    borderRightColor: "black",
    borderRightStyle: "solid",
  },
  halfWidthleftSection: {
    width: "50%",
    borderBottomWidth: 1,
    borderBottomColor: "black",
    borderbottomStyle: "solid",
  },
  tableContainer: {
    borderBottomWidth: 1,
    borderBottomColor: "black",
  },
  tableRow: {
    flexDirection: "row",
    flexGrow: 1,
    alignItems: "stretch",
  },
  tableCell: {
    padding: 5,
    fontSize: 7,
    textAlign: "center",
    borderRightWidth: 1,
    borderRightColor: "black",
    borderRightStyle: "solid",
  },
  expandableDescriptionCell: {
    flex: 8,
    flexDirection: "column",
    alignItems: "flex-start",
    textAlign: "left",
  },
  descriptionText: {
    alignSelf: "flex-start",
    fontSize: 6.5,
    flexWrap: "wrap",
    paddingVertical: 1,
  },
  emptyTableRow: {
    minHeight: 50,
  },
  thirdWidthSection: {
    flex: 1,
    height: 100,
    padding: 2,
    borderTopWidth: 1,
    borderTopColor: "black",
    borderTopStyle: "solid",
    borderLeftWidth: 1,
    borderLeftColor: "black",
    borderLeftStyle: "solid",
  },
  signatoryContainer: {
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "center",
  },
  addressBlock: { fontSize: 7, marginBottom: 1.5 },
  footer: {
    borderTopWidth: 1,
    borderTopColor: "black",
    borderTopStyle: "solid",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    fontSize: 8,
  },
});

const PDFInvoice = ({ data }: { data: any }) => {
  const minRows = 8;
  const rows = data.purchase_item || [];

  const paddedRows = [...rows];
  while (paddedRows.length < minRows) {
    paddedRows.push(emptyData);
  }
  return (
    <Document>
      <Page style={styles.page} size="A4">
        <View style={styles.fullpage}>
          <Text style={[styles.header]}>PURCHASE ORDER</Text>

          {/* First 2 row section */}
          <View style={styles.sideBySideContainer}>
            {/* invoice section */}
            <View style={[styles.halfWidthRightSection, styles.contentPadding]}>
              <Text style={styles.addressBlock}>
                <Text style={styles.boldText}>INVOICE TO:</Text> Enfiny Exim
              </Text>
              <Text style={styles.addressBlock}>GUJARAT,INDIA</Text>
              <Text style={styles.addressBlock}>GSTIN/UIN : GST NO</Text>
            </View>

            {/* Order details */}
            <View style={[styles.halfWidthleftSection, styles.purchaseDetails]}>
              {/* purchase and date */}
              <View style={styles.sideBySideContainer}>
                <View
                  style={[styles.halfWidthRightSection, styles.contentPadding]}
                >
                  <Text style={styles.boldText}>PURCHASE ORDER NO :</Text>
                  <Text style={styles.smallText}>{data.id}</Text>
                </View>
                <View
                  style={[styles.halfWidthleftSection, styles.contentPadding]}
                >
                  <Text style={styles.boldText}>DATED :</Text>
                  <Text style={styles.smallText}>
                    {moment(data.created_at).format("DD-MM-YYYY")}
                  </Text>
                </View>
              </View>
              {/* reference */}
              <View style={styles.sideBySideContainer}>
                <View style={styles.halfWidthRightSection}>
                  <Text style={[styles.boldText, styles.contentPadding]}>
                    OTHER REFERENCES:
                  </Text>
                </View>
                <View style={styles.halfWidthleftSection}>
                  <Text style={[styles.boldText, styles.contentPadding]}>
                    {" "}
                    DESTINATION{" "}
                  </Text>
                </View>
              </View>
              {/* terms section */}
              <View style={styles.section}>
                <Text style={[styles.boldText, styles.contentPadding]}>
                  MODE/TERMS OF PAYMENT :
                  <Text style={styles.smallText}>{data.terms_of_payment}</Text>
                </Text>
              </View>

              <Text style={[styles.boldText, styles.contentPadding]}>
                TERMS OF DELIVERY :<Text style={styles.smallText}> 123</Text>
              </Text>
            </View>
          </View>

          {/* Second 2 row section */}
          <View style={styles.sideBySideContainer}>
            {/* Shipping Details */}
            <View style={styles.halfWidthRightSection}>
              <Text style={styles.addressBlock}>
                <Text style={styles.boldText}>CONSIGNEE (SHIP TO)</Text>
              </Text>
              <Text style={styles.addressBlock}>
                {" "}
                {data.supplier?.address || ""}
              </Text>
            </View>

            {/* Supplier Details */}
            <View style={[styles.halfWidthleftSection, styles.contentPadding]}>
              <Text style={styles.addressBlock}>
                <Text style={[styles.boldText, styles.mediumText]}>
                  SUPPLIER (BILL FROM):
                </Text>{" "}
              </Text>
              <Text style={styles.boldText}>{data.supplier.name}</Text>
              <Text style={styles.addressBlock}>
                {data.supplier?.address || ""}
              </Text>
              <Text style={styles.addressBlock}>
                GSTIN/UIN: {data.supplier.gst_no}
              </Text>
              <Text style={styles.addressBlock}>
                STATE NAME: {data.supplier.state}
              </Text>
            </View>
          </View>

          {/* Purchase List */}
          <View style={styles.tableContainer}>
            {/* Table Header */}
            <View
              style={[
                styles.tableRow,
                {
                  height: 30,
                  borderBottomWidth: 1,
                  borderBottomColor: "black",
                },
              ]}
            >
              <Text
                style={[
                  styles.tableCell,
                  styles.boldText,
                  styles.mediumText,
                  { flex: 0.5 },
                ]}
              >
                SR NO
              </Text>
              <Text
                style={[
                  styles.tableCell,
                  styles.boldText,
                  styles.mediumText,
                  { flex: 8 },
                ]}
              >
                DESCRIPTION OF GOODS
              </Text>
              <Text
                style={[
                  styles.tableCell,
                  styles.boldText,
                  styles.mediumText,
                  { flex: 1 },
                ]}
              >
                HSN/SAC
              </Text>
              <Text
                style={[
                  styles.tableCell,
                  styles.boldText,
                  styles.mediumText,
                  { flex: 1 },
                ]}
              >
                DUE ON
              </Text>
              <Text
                style={[
                  styles.tableCell,
                  styles.boldText,
                  styles.mediumText,
                  { flex: 1.2 },
                ]}
              >
                QUANTITY
              </Text>
              <Text
                style={[
                  styles.tableCell,
                  styles.boldText,
                  styles.mediumText,
                  { flex: 1.3 },
                ]}
              >
                RATE
              </Text>
              <Text
                style={[
                  styles.tableCell,
                  styles.boldText,
                  styles.mediumText,
                  { flex: 1, borderRight: "none" },
                ]}
              >
                AMOUNT (INR)
              </Text>
            </View>

            {/* Table Rows */}
            {paddedRows.map((item, index) => (
              <View style={[styles.tableRow, { minHeight: 50 }]} key={index}>
                <Text style={[styles.tableCell, { flex: 0.5 }]}>
                  {index + 1}
                </Text>
                <View
                  style={[styles.tableCell, styles.expandableDescriptionCell]}
                >
                  <Text style={[styles.mediumText, { alignSelf: "center" }]}>
                    {item.product.name}
                  </Text>
                  <View>
                    {/* <Text style={styles.descriptionText}>
                    {`TOTAL_NET_WEIGHT_${item.unit}`} : {item.quantity}
                    </Text>
                    <Text style={styles.descriptionText}>
                    TOTAL : {item.total}
                    </Text>
                    <Text style={styles.descriptionText}>
                    PACKING : {item.product?.hsn_no || ""}
                    </Text>
                    <Text style={styles.descriptionText}>
                    DESCRIPTION : {item.product?.description || ""}
                    </Text> */}

                    {item.unit && item.quantity ? (
                      <Text style={styles.descriptionText}>
                        {`TOTAL_NET_WEIGHT_${item.unit}`} : {item.quantity}
                      </Text>
                    ):null}
                    {item.total ? (
                      <Text style={styles.descriptionText}>
                        TOTAL : {item.total}
                      </Text>
                    ):null}
                    {item.product?.hsn_no ? (
                      <Text style={styles.descriptionText}>
                        PACKING : {item.product.hsn_no}
                      </Text>
                    ):null}
                    {item.product?.description ? (
                      <Text style={styles.descriptionText}>
                        DESCRIPTION : {item.product.description}
                      </Text>
                    ):null}
                  </View>
                </View>
                <Text style={[styles.tableCell, { flex: 1 }]}>
                  {item.product?.hsn_no || ""}
                </Text>
                <Text style={[styles.tableCell, { flex: 1 }]}>{""}</Text>
                <Text style={[styles.tableCell, { flex: 1.2 }]}>
                  {item.quantity}
                  {item.unit}
                </Text>
                <Text style={[styles.tableCell, { flex: 1.3 }]}>
                  {item.price}
                </Text>
                <Text
                  style={[styles.tableCell, { flex: 1, borderRight: "none" }]}
                >
                  {item.total}
                </Text>
              </View>
            ))}

            {/* total */}
            <View
              style={[
                styles.tableRow,
                { borderTop: "1px solid black", height: 35 },
              ]}
            >
              <Text
                style={[styles.tableCell, { flex: 0.5, borderRight: "none" }]}
              ></Text>
              <View
                style={[
                  styles.tableCell,
                  {
                    flex: 8,
                    borderRight: "none",
                  },
                ]}
              ></View>
              <Text
                style={[styles.tableCell, { flex: 1, borderRight: "none" }]}
              ></Text>
              <Text
                style={[styles.tableCell, { flex: 1, borderRight: "none" }]}
              ></Text>

              <Text style={[styles.tableCell, { flex: 1 }]}></Text>
              <View
                style={[
                  styles.tableCell,
                  {
                    flex: 1.56,
                    flexDirection: "column",
                    alignItems: "center",
                    padding: 0,
                  },
                ]}
              >
                <Text style={styles.mediumText}>SUB TOTAL</Text>
                <Text style={[styles.mediumText]}>
                  CGST {data.gst_percentage}
                </Text>
                <Text style={[styles.mediumText]}>
                  SGST {data.gst_percentage}
                </Text>
                <Text style={[styles.mediumText]}>TOTAL VALUE</Text>
              </View>
              <View
                style={[
                  styles.tableCell,
                  {
                    flex: 1.28,
                    flexDirection: "column",
                    alignItems: "flex-end",
                    borderRight: "none",
                    padding: 0,
                  },
                ]}
              >
                <Text style={styles.mediumText}>{data.sub_total}</Text>
                <Text style={[styles.mediumText]}>{data.cgst}</Text>
                <Text style={[styles.mediumText]}>{data.sgst}</Text>
                <Text style={[styles.mediumText]}>{data.total}</Text>
              </View>
            </View>
          </View>

          {/* Company Policy */}
          <View style={[{ padding: 2, paddingBottom: 10 }]}>
            <View
              style={[
                {
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                },
              ]}
            >
              <Text style={styles.boldText}>AMOUNT CHARGEABLE (IN WORDS)</Text>
              <Text style={styles.boldText}>E. & O.E</Text>
            </View>
            <Text style={styles.mediumText}>
              FOUR THOUSAND TWO HUNDRED RUPEES ONLY
            </Text>
          </View>

          {/* SIGNATORY */}
          <View style={styles.sideBySideContainer}>
            <View style={[styles.thirdWidthSection, { border: "none" }]}>
              <Text style={styles.boldText}>COMPANY’S PAN :</Text>
              <Text style={styles.boldText}>REMARKS:</Text>
            </View>
            <View style={[styles.thirdWidthSection, styles.signatoryContainer]}>
              <Text style={styles.boldText}>FOR ENFINY EXIM</Text>
              <Text style={styles.mediumText}>AUTHORISED SIGNATURE</Text>
            </View>
            <View style={[styles.thirdWidthSection, styles.signatoryContainer]}>
              <Text style={styles.boldText}>FOR SUPPLIER 1</Text>
              <Text style={styles.mediumText}>AUTHORISED SIGNATURE</Text>
            </View>
          </View>

          {/* Footer */}
          <View style={styles.footer}>
            <Text style={[styles.boldText, { padding: 5 }]}>
              Customer Care :<Text style={styles.boldText}> 1234567890</Text>
            </Text>
            <Text style={[styles.boldText, { padding: 5 }]}>xyz@gmail.com</Text>
          </View>
        </View>
      </Page>
    </Document>
  );
};

export default PDFInvoice;
