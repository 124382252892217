import {
  createSalesInvoiceApi,
  getAllSalesContractApi,
  getAllSalesInvoiceBillApi,
  getSalesContractDetailsByIdApi,
} from "../../../services/_sales";
import {
  salesInvoiceInitialValues,
  salesInvoiceValidationSchema,
} from "./helpers/SalesInvoiceHelper";
import moment from "moment";
import { Formik, Form } from "formik";
import { useNavigate } from "react-router-dom";
import { getAllChaApi } from "../../../services/_cha";
import { useCallback, useEffect, useState } from "react";
import { getAllPortsApi } from "../../../services/_port";
import BasicFields from "./sales-invoice-form/BasicFields";
import ActionFields from "./sales-invoice-form/ActionFields";
import { getBuyerNameListApi } from "../../../services/_buyer";
import ProductFields from "./sales-invoice-form/ProductFields";
import { getAllLogisticApi } from "../../../services/_logistic";
import { getAllDocumentApi } from "../../../services/_document";
import ShipmentFields from "./sales-invoice-form/ShipmentFields";
import ContainerFields from "./sales-invoice-form/ContainerFields";
import { getProductNameListApi } from "../../../services/_product";
import { errorToast, successToast } from "../../../components/Toast";
import MyDocumentsFields from "./sales-invoice-form/MyDocumentsFields";
import OtherDocumentsFields from "./sales-invoice-form/OtherDocumentsFields";
import { DocumentType } from "../../../components/Enums";
import { getAllUnitsApi } from "../../../services/_unit";
import { getAllPackingTypesApi } from "../../../services/_packingTypes";
import PackingFields from "./sales-invoice-form/PackingFields";
import AddUnitModal from "../../../components/modal/AddUnit";
import AddPackingType from "../../../components/modal/AddPackingType";
import BillFields from "./sales-invoice-form/BillFields";
import { getAllSealNumberNameListApi } from "../../../services/_sealNumber";
import AddProductModal from "../../../components/modal/AddProduct";
import AddDocumentModal from "../../../components/modal/AddDocument";
import AddLogisticModal from "../../../components/modal/AddLogistics";
import AddChaModal from "../../../components/modal/AddCha";
import AddPortModal from "../../../components/modal/AddPort";
import AddBillTypeModal from "../../../components/modal/AddBillType";
import { formatAmount } from "../../../components/Helper";
import { getSettingsApi } from "../../../services/_settings";

const CreateSalesInvoice = () => {
  const navigate = useNavigate();
  const [noOfContainers, setNoOfContainers] = useState("");
  const [isShowContainerButton, setIsShowContainerButton] = useState(true);
  const [isShowMyDocumentButton, setIsShowMyDocumentButton] = useState(true);
  const [salesContractList, setSalesContractList] = useState([]);
  const [billList, setBillList] = useState([]);
  const [buyerList, setBuyerList] = useState([]);
  const [productList, setProductList] = useState([]);
  const [logisticList, setLogisticList] = useState([]);
  const [chaList, setChaList] = useState([]);
  const [portList, setPortList] = useState([]);
  const [sealNumbersList, setSealNumbersList] = useState([]);
  const [myDocumentList, setMyDocumentList] = useState([]);
  const [documentList, setDocumentList] = useState([]);
  const [submitLoader, setSubmitLoader] = useState<boolean>(false);
  const [initialValues] = useState<any>(salesInvoiceInitialValues);
  const [filePondDocuments, setFilePondDocuments] = useState([]);
  const [filePondBills, setFilePondBills] = useState([]);
  const [packingType, setPackingType] = useState<any[]>([]);
  const [unitList, setUnits] = useState<any[]>([]);
  const [addUnitModalStatus, setAddUnitModalStatus] = useState(false);
  const [addPackingTypeModalStatus, setAddPackingTypeModalStatus] =
    useState(false);
  const [addProductModalStatus, setAddProductModalStatus] = useState(false);
  const [addDocumentModalStatus, setAddDocumentModalStatus] = useState(false);
  const [addLogisticModalStatus, setAddLogisticModalStatus] = useState(false);
  const [addChaModalStatus, setAddChaModalStatus] = useState(false);
  const [addPortModalStatus, setAddPortModalStatus] = useState(false);
  const [addBillTypeModalStatus, setAddBillTypeModalStatus] = useState(false);
  const [prefix, setPrefix] = useState("");

  const fetchInitialData = async () => {
    const allSalesContract: any = await getAllSalesContractApi();
    if (allSalesContract.isSuccess) {
      setSalesContractList(allSalesContract.data);
    }
    const allBills: any = await getAllSalesInvoiceBillApi();
    if (allBills.isSuccess) {
      setBillList(allBills.data);
    }
    const allBuyers: any = await getBuyerNameListApi();
    if (allBuyers.isSuccess) {
      setBuyerList(allBuyers.data);
    }
    const allProducts: any = await getProductNameListApi();
    if (allProducts.isSuccess) {
      setProductList(allProducts.data);
    }
    const allLogistics: any = await getAllLogisticApi();
    if (allLogistics.isSuccess) {
      setLogisticList(allLogistics.data);
    }
    const allCha: any = await getAllChaApi();
    if (allCha.isSuccess) {
      setChaList(allCha.data);
    }
    const allPorts: any = await getAllPortsApi();
    if (allPorts.isSuccess) {
      setPortList(allPorts.data);
    }
    const sealNumbersList: any = await getAllSealNumberNameListApi(
      `Unused_seal_numbers=true`
    );
    if (sealNumbersList.isSuccess) {
      setSealNumbersList(sealNumbersList.data);
    }
    const allDocuments: any = await getAllDocumentApi();
    if (allDocuments.isSuccess) {
      setDocumentList(allDocuments.data);
      const myDocuments = allDocuments.data.filter(
        (x: any) => x.type === DocumentType.SELF
      );
      setMyDocumentList(myDocuments);
    }
    const fetchUnits: any = await getAllUnitsApi();
    if (fetchUnits.isSuccess) {
      setUnits(fetchUnits.data);
    }
    const getPackingType: any = await getAllPackingTypesApi();
    if (getPackingType.isSuccess) {
      setPackingType(getPackingType.data);
    }

    const fetchSalesContractPrefix: any = await getSettingsApi();
    if (fetchSalesContractPrefix.isSuccess) {
      const { sales_invoice_prefix } = fetchSalesContractPrefix.data;
      setPrefix(sales_invoice_prefix);
    }
  };

  useEffect(() => {
    fetchInitialData();
  }, []);

  const closeAddUnitModal = () => {
    setAddUnitModalStatus(false);
  };

  const closePackingTypeModal = () => {
    setAddPackingTypeModalStatus(false);
  };

  const closeAddProductModal = () => {
    setAddProductModalStatus(false);
  };

  const closeAddDocumentModal = () => {
    setAddDocumentModalStatus(false);
  };

  const closeAddLogisticModal = () => {
    setAddLogisticModalStatus(false);
  };

  const closeAddChaModal = () => {
    setAddChaModalStatus(false);
  };

  const closeAddPortModal = () => {
    setAddPortModalStatus(false);
  };

  const closeAddBillTypeModal = () => {
    setAddBillTypeModalStatus(false);
  };

  const handleSalesContractChange = useCallback(
    async (id: string, setFieldValue: any) => {
      const result: any = await getSalesContractDetailsByIdApi(id);
      if (result?.isSuccess) {
        setFieldValue("sales_contract_id", result?.data?.id || "");
        setFieldValue("buyer_id", result?.data?.buyer_id || "");
        setFieldValue("product_id", result?.data?.product_id || "");
        setFieldValue(
          "price",
          result?.data?.price ? parseFloat(result?.data?.price) : 0
        );
        setFieldValue(
          "quantity",
          result?.data?.quantity ? parseFloat(result?.data?.quantity) : 0
        );
        setFieldValue("quantity_unit_id", result?.data?.quantity_unit_id || "");
        setFieldValue(
          "total_amount",
          result?.data?.total_amount
            ? parseFloat(result?.data?.total_amount)
            : 0
        );
        setFieldValue("container_size", result?.data?.container_load || "");
        setFieldValue("packing_type_id", result?.data?.packing_type_id || "");
        setFieldValue(
          "packing_net_weight",
          result?.data?.packing_net_weight
            ? parseFloat(result?.data?.packing_net_weight)
            : 0
        );
        setFieldValue(
          "net_weight_unit_id",
          result?.data?.packing_unit_id || ""
        );
        setFieldValue(
          "packing_gross_weight",
          result?.data?.packing_gross_weight
            ? parseFloat(result?.data?.packing_gross_weight)
            : 0
        );
        setFieldValue(
          "gross_weight_unit_id",
          result?.data?.packing_unit_id || ""
        );
      }
    },
    []
  );

  const calculateTotalAmount = useCallback(
    (
      price: any,
      quantity: any,
      igst_percentage: any,
      exchange_rate: any,
      commission_rate: any,
      setFieldValue: any
    ) => {
      if (price && quantity) {
        const calculatedAmount = parseFloat(price) * parseFloat(quantity);
        setFieldValue("total_amount", calculatedAmount.toFixed(2));

        if (
          igst_percentage &&
          parseFloat(igst_percentage) > 0 &&
          exchange_rate &&
          parseFloat(exchange_rate) > 0
        ) {
          const totalTaxableAmount =
            calculatedAmount * parseFloat(exchange_rate);
          const totalIgstAmount =
            (totalTaxableAmount * parseFloat(igst_percentage)) / 100;

          setFieldValue(
            "total_taxable_amount_inr",
            totalTaxableAmount.toFixed(2)
          );
          setFieldValue("total_igst_amount_inr", totalIgstAmount.toFixed(2));
        } else {
          setFieldValue("total_taxable_amount_inr", 0);
          setFieldValue("total_igst_amount_inr", 0);
        }

        if (commission_rate && parseFloat(commission_rate) > 0) {
          const totalCommissionAmount =
            (calculatedAmount * parseFloat(commission_rate)) / 100;

          setFieldValue("commission_amount", totalCommissionAmount.toFixed(2));
        } else {
          setFieldValue("commission_amount", 0);
        }
      } else {
        setFieldValue("total_amount", 0);
        setFieldValue("total_taxable_amount_inr", 0);
        setFieldValue("total_igst_amount_inr", 0);
        setFieldValue("commission_amount", 0);
      }
    },
    []
  );

  const calculateWeight = useCallback(
    (values: any, setFieldValue: any) => {
      const netWeightUnit = unitList.filter(
        (x) => x.id === values.net_weight_unit_id
      );
      if (netWeightUnit?.length > 0) {
        if (
          (netWeightUnit[0].name === "KG" || netWeightUnit[0].name === "KGS") &&
          parseFloat(values?.no_of_bags) > 0 &&
          parseFloat(values?.packing_net_weight) > 0
        ) {
          const totalNetWeight =
            (parseFloat(values?.no_of_bags) *
              parseFloat(values?.packing_net_weight)) /
            1000;
          setFieldValue("total_net_weight", formatAmount(totalNetWeight, 3));
        }
      }

      const grossWeightUnit = unitList.filter(
        (x) => x.id === values.gross_weight_unit_id
      );
      if (grossWeightUnit?.length > 0) {
        if (
          (grossWeightUnit[0].name === "KG" ||
            grossWeightUnit[0].name === "KGS") &&
          parseFloat(values?.no_of_bags) > 0 &&
          parseFloat(values?.packing_gross_weight) > 0
        ) {
          const totalGrossWeight =
            (parseFloat(values?.no_of_bags) *
              parseFloat(values?.packing_gross_weight)) /
            1000;
          setFieldValue(
            "total_gross_weight",
            formatAmount(totalGrossWeight, 3)
          );
        }
      }

      if (values?.container?.length > 0) {
        values?.container.map((x: any) => {
          if (parseFloat(x?.no_of_bags) > 0) {
            if (netWeightUnit?.length > 0) {
              if (
                (netWeightUnit[0].name === "KG" ||
                  netWeightUnit[0].name === "KGS") &&
                parseFloat(values?.packing_net_weight) > 0
              ) {
                const totalNetWeight =
                  parseFloat(x?.no_of_bags) *
                  parseFloat(values?.packing_net_weight);
                x.total_net_weight = formatAmount(totalNetWeight);
              }
            }
            if (
              (grossWeightUnit[0].name === "KG" ||
                grossWeightUnit[0].name === "KGS") &&
              parseFloat(values?.packing_gross_weight) > 0
            ) {
              const totalGrossWeight =
                parseFloat(x?.no_of_bags) *
                parseFloat(values?.packing_gross_weight);
              x.total_gross_weight = formatAmount(totalGrossWeight);
            }
          }
          return x;
        });
        setFieldValue("container", values.container);
      }
    },
    [unitList]
  );

  const handleFileChange = useCallback(
    (fileItems: any, setFieldValue: any, inputKey: string) => {
      const files = fileItems
        .map((fileItem: any) => {
          if (fileItem?.file) {
            return {
              fileName: fileItem.file.name,
              fileData: fileItem.getFileEncodeDataURL(),
            };
          }
          return null;
        })
        .filter(Boolean);
      setFieldValue(inputKey, files);
    },
    []
  );

  const handleSubmit = async (values: any, { resetForm }: any) => {
    values.invoice_date = moment(values.invoice_date).format("YYYY-MM-DD");
    values.etd = values?.etd
      ? moment(values.etd).format("YYYY-MM-DD HH:mm:ss")
      : null;
    values.eta = values?.eta
      ? moment(values.eta).format("YYYY-MM-DD HH:mm:ss")
      : null;
    values.total_amount = parseFloat(values.total_amount);
    setSubmitLoader(true);
    const result: any = await createSalesInvoiceApi(values);
    if (result.isSuccess) {
      setSubmitLoader(false);
      setIsShowContainerButton(false);
      setIsShowMyDocumentButton(false);
      successToast(result.message);
      resetForm({ values: initialValues });
      navigate(`/sales-invoice-list`);
    } else {
      setSubmitLoader(false);
      errorToast(result.message);
    }
    return;
  };

  return (
    <>
      <div className="card">
        <div className="card-header pb-0 d-flex justify-content-between">
          <div>
            <h5>Create Sales Invoice</h5>
          </div>
        </div>
        <div className="card-body">
          <Formik
            enableReinitialize={true}
            initialValues={{
              ...initialValues,
              invoice_no: prefix || "",
            }}
            validationSchema={salesInvoiceValidationSchema}
            onSubmit={handleSubmit}
          >
            {({ values, handleChange, setFieldValue }) => {
              return (
                <Form className="dt_adv_search">
                  <div className="row g-3">
                    <BasicFields
                      salesContractList={salesContractList}
                      buyerList={buyerList}
                      logisticList={logisticList}
                      chaList={chaList}
                      setAddLogisticModalStatus={setAddLogisticModalStatus}
                      setAddChaModalStatus={setAddChaModalStatus}
                      values={values}
                      setFieldValue={setFieldValue}
                      handleChange={handleChange}
                      handleSalesContractChange={handleSalesContractChange}
                    />

                    <ProductFields
                      unitList={unitList}
                      productList={productList}
                      setAddUnitModalStatus={setAddUnitModalStatus}
                      setAddProductModalStatus={setAddProductModalStatus}
                      values={values}
                      setFieldValue={setFieldValue}
                      handleChange={handleChange}
                      calculateTotalAmount={calculateTotalAmount}
                    />

                    <PackingFields
                      unitList={unitList}
                      packingType={packingType}
                      values={values}
                      handleChange={handleChange}
                      setAddUnitModalStatus={setAddUnitModalStatus}
                      setAddPackingTypeModalStatus={
                        setAddPackingTypeModalStatus
                      }
                      setFieldValue={setFieldValue}
                      calculateWeight={calculateWeight}
                    />

                    <ShipmentFields
                      portList={portList}
                      setAddPortModalStatus={setAddPortModalStatus}
                      values={values}
                      setFieldValue={setFieldValue}
                      handleChange={handleChange}
                    />

                    <ContainerFields
                      values={values}
                      setFieldValue={setFieldValue}
                      sealNumbersList={sealNumbersList}
                      unitList={unitList}
                      packingType={packingType}
                      noOfContainers={noOfContainers}
                      setNoOfContainers={setNoOfContainers}
                      isShowContainerButton={isShowContainerButton}
                      handleChange={handleChange}
                      setIsShowContainerButton={setIsShowContainerButton}
                      setAddUnitModalStatus={setAddUnitModalStatus}
                      setAddPackingTypeModalStatus={
                        setAddPackingTypeModalStatus
                      }
                      calculateWeight={calculateWeight}
                    />

                    <MyDocumentsFields
                      documentList={documentList}
                      myDocumentList={myDocumentList}
                      isShowMyDocumentButton={isShowMyDocumentButton}
                      setIsShowMyDocumentButton={setIsShowMyDocumentButton}
                    />

                    <OtherDocumentsFields
                      actionType={"create"}
                      values={values}
                      documentList={documentList}
                      filePondDocuments={filePondDocuments}
                      setAddDocumentModalStatus={setAddDocumentModalStatus}
                      setFilePondDocuments={setFilePondDocuments}
                      handleFileChange={handleFileChange}
                      setFieldValue={setFieldValue}
                    />

                    <BillFields
                      actionType={"create"}
                      values={values}
                      billList={billList}
                      filePondBills={filePondBills}
                      setAddBillTypeModalStatus={setAddBillTypeModalStatus}
                      setFilePondBills={setFilePondBills}
                      handleFileChange={handleFileChange}
                      setFieldValue={setFieldValue}
                    />

                    <ActionFields submitLoader={submitLoader} />
                  </div>
                </Form>
              );
            }}
          </Formik>
        </div>
      </div>
      <AddUnitModal
        addUnitModalStatus={addUnitModalStatus}
        closeAddUnitModal={closeAddUnitModal}
        onUnitCreated={fetchInitialData}
      />
      <AddPackingType
        addPackingTypeModalStatus={addPackingTypeModalStatus}
        closePackingTypeModal={closePackingTypeModal}
        onPackingTypeCreated={fetchInitialData}
      />
      <AddProductModal
        addProductModalStatus={addProductModalStatus}
        closeAddProductModal={closeAddProductModal}
        onProductCreated={fetchInitialData}
      />
      <AddDocumentModal
        addDocumentModal={addDocumentModalStatus}
        closeAddDocumentModal={closeAddDocumentModal}
        onDocumentCreated={fetchInitialData}
      />
      <AddLogisticModal
        addLogisticModalStatus={addLogisticModalStatus}
        closeAddLogisticModal={closeAddLogisticModal}
        onLogisticCreated={fetchInitialData}
      />
      <AddChaModal
        addChaModalStatus={addChaModalStatus}
        closeAddChaModal={closeAddChaModal}
        onChaCreated={fetchInitialData}
      />
      <AddPortModal
        addPortModal={addPortModalStatus}
        closeAddPortModal={closeAddPortModal}
        onPortCreated={fetchInitialData}
      />
      <AddBillTypeModal
        addBillTypeModal={addBillTypeModalStatus}
        closeBillTypeModal={closeAddBillTypeModal}
        onBillTypeCreated={fetchInitialData}
      />
    </>
  );
};

export default CreateSalesInvoice;
