import { GET, POST, PUT } from "./_config";

export const productListApi = async (query: string) =>
  await GET(`/product?${query || ""}`, true);

export const createProductApi = async (body: any) =>
  await POST(`/product`, body, true);

export const getProductNameListApi = async () =>
  await GET(`/product/list`, true);

export const getProductByIdApi = async (productId: string) =>
  await GET(`/product/id/${productId}`, true);

export const updateProductApi = async (productId: string, body: any) =>
  await PUT(`/product?id=${productId}`, body, true);

export const updateProductStatusApi = async (body: any) =>
  await POST(`/product/update-status`, body, true);

const ProductService = {
  productListApi,
  createProductApi,
  getProductByIdApi,
  updateProductApi,
  updateProductStatusApi,
};

export default ProductService;
