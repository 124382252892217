/* eslint-disable react-hooks/exhaustive-deps */
import moment from "moment";
import { CSVLink } from "react-csv";
import { Link } from "react-router-dom";
import { DateRangePicker } from "rsuite";
import Spinner from "../../theme/Spinner";
import { getAllChaApi } from "../../services/_cha";
import { errorToast } from "../../components/Toast";
import { useEffect, useRef, useState } from "react";
import Pagination from "../../components/Pagination";
import { CURRENCY } from "../../components/Constants";
import { getBuyerNameListApi } from "../../services/_buyer";
import { getAllExpensesApi } from "../../services/_expenses";
import { getAllLogisticApi } from "../../services/_logistic";
import { profitAndLossReportApi } from "../../services/_report";
import { getSupplierNameListApi } from "../../services/_supplier";
import {
  formatAmount,
  formatDate,
  formatString,
} from "../../components/Helper";
import {
  PaymentRole,
  PaymentStatus,
  TransactionTypes,
} from "../../components/Enums";
import { getAllBrokerApi } from "../../services/_broker";

interface CustomCSVLink extends HTMLAnchorElement {
  link: HTMLAnchorElement;
}

const ProfitAndLossReport = () => {
  const [records, setRecords] = useState<any[]>([]);
  const [perPage] = useState<number>(10);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [totalRecords, setTotalRecords] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);
  const [applyFilter, setApplyFilter] = useState<boolean>(false);
  const [transactionType, setTransactionType] = useState<string>("");
  const [page, setPage] = useState<number>(1);
  const [dateRange, setDateRange] = useState(null);
  const [displayFilter, setDisplayFilter] = useState(false);
  const [paymentRole, setPaymentRole] = useState<string | null>(null);
  const [filterType, setFilterType] = useState<string>("");
  const [relationID, setRelationID] = useState<string>("");
  const [relationList, setRelationList] = useState([]);
  const [totalCreditAmount, setTotalCreditAmount] = useState(0);
  const [totalDebitAmount, setTotalDebitAmount] = useState(0);
  const [totalAmount, setTotalAmount] = useState(0);
  const csvLinkRef = useRef<CustomCSVLink>(null);
  const [exportData, setExportData] = useState([]);
  const [sortKey, setSortKey] = useState<string>("");
  const [sortType, setSortType] = useState<string>("");

  const toggleFilter = async () => {
    setDisplayFilter(!displayFilter);
  };

  const getProfitAndLossReport = async (
    page: number,
    perPage: number,
    isFilter?: boolean,
    sortKey?: string,
    sortType?: string
  ) => {
    setLoading(true);

    let query = `page=${page}&per_page=${perPage}`;
    if (isFilter) {
      if (transactionType) {
        query += `&transaction_type=${transactionType}`;
      }
      if (paymentRole) {
        query += `&paymentRole=${paymentRole.toUpperCase()}`;
      }
      if (relationID) {
        query += `&relationID=${relationID}`;
      }
      if (dateRange) {
        query += `&from_date=${moment(dateRange[0]).format("YYYY-MM-DD")}`;
        query += `&to_date=${moment(dateRange[1]).format("YYYY-MM-DD")}`;
      }
      if (sortKey && sortType) {
        query += `&sort_key=${sortKey}&sort_type=${sortType}`;
      }
    }

    const result: any = await profitAndLossReportApi(query);
    if (result.isSuccess) {
      const totalPagesCount = Math.ceil(result.data.total_records / perPage);
      setRecords(result?.data.records);
      setTotalPages(totalPagesCount);
      setTotalRecords(result.data.total_records);
      setTotalCreditAmount(result.data.total_credit);
      setTotalDebitAmount(result.data.total_debit);
      setTotalAmount(result.data.total_amount);
    }
    setLoading(false);
  };

  const filterList = async () => {
    setApplyFilter(true);
    getProfitAndLossReport(page, perPage, true);
  };

  const resetList = async () => {
    setPage(1);
    setApplyFilter(false);
    setTransactionType("");
    setPaymentRole("");
    setDateRange(null);
    setRelationID("");
    setRelationList([]);
    getProfitAndLossReport(page, perPage);
  };

  const prevButton = async () => {
    if (page > 1) {
      setPage(page - 1);
    }
  };

  const nextButton = async () => {
    if (totalPages > page) {
      setPage(page + 1);
    }
  };

  const getFileName = () => {
    const filerName = "profit-and-loss-report";
    return `${filerName}-${moment().format("MM-DD-YYYY-HH-mm-ss")}.csv`;
  };

  const exportProfitAndLossReport = async () => {
    setExportData([]);
    let csvHeader: any;
    let csvData: any;
    let csvFooter: any;

    let query = `export=true`;
    if (transactionType) {
      query += `&transaction_type=${transactionType}`;
    }
    if (paymentRole) {
      query += `&paymentRole=${paymentRole.toUpperCase()}`;
    }
    if (relationID) {
      query += `&relationID=${relationID}`;
    }
    if (dateRange) {
      query += `&from_date=${moment(dateRange[0]).format("YYYY-MM-DD")}`;
      query += `&to_date=${moment(dateRange[1]).format("YYYY-MM-DD")}`;
    }
    if (sortKey && sortType) {
      query += `&sort_key=${sortKey}&sort_type=${sortType}`;
    }

    const result: any = await profitAndLossReportApi(query);
    if (result.isSuccess) {
      if (result.data.records) {
        csvHeader = [
          "Sr No.",
          "Payment Role",
          "Stackholder Name",
          "Amount",
          "Transaction Type",
          "Payment Method",
          "Transaction No",
          "Payment Status",
          "Remark",
          "Transaction Date",
        ];
        csvData = result.data.records.map((item: any, index: number) => [
          (index + 1).toString(),
          item.payment_role,
          getStackHolderName(item),
          `${CURRENCY.INR.SYMBOL}${parseFloat(item.amount).toFixed(2)}`,
          item.transaction_type,
          item?.payment_method?.replace("_", " ") || "",
          item?.transaction_no || "",
          formatString(item?.payment_status) || "",
          item?.remark || "",
          formatDate(item.transaction_date),
        ]);
        csvFooter = [
          [
            "",
            "",
            "Total Credit :",
            `${CURRENCY.INR.SYMBOL}${formatAmount(totalCreditAmount)}`,
          ],
          [
            "",
            "",
            "Total Debit :",
            `${CURRENCY.INR.SYMBOL}${formatAmount(totalDebitAmount)}`,
          ],
          [
            "",
            "",
            totalAmount > 0 ? "Profit :" : "Loss :",
            `${CURRENCY.INR.SYMBOL}${formatAmount(totalAmount)}`,
          ],
        ];

        const exportData: any = [csvHeader, ...csvData, ...csvFooter];
        setExportData(exportData);

        if (csvLinkRef.current?.link) {
          setTimeout(() => {
            csvLinkRef.current!.link.click();
          }, 0);
        }
      }
    }
  };

  const getStackHolderName = (item: any) => {
    if (
      item?.payment_role === PaymentRole.SUPPLIER &&
      item?.supplier &&
      item?.supplier?.name
    ) {
      return item.supplier.name;
    }

    if (
      item?.payment_role === PaymentRole.BUYER &&
      item?.buyer &&
      item?.buyer?.name
    ) {
      return item.buyer.name;
    }

    if (
      item?.payment_role === PaymentRole.EXPENSE &&
      item?.expense &&
      item?.expense?.name
    ) {
      return item.expense.name;
    }

    if (
      item?.payment_role === PaymentRole.LOGISTIC &&
      item?.logistic &&
      item?.logistic?.name
    ) {
      return item.logistic.name;
    }

    if (
      item?.payment_role === PaymentRole.CHA &&
      item?.cha &&
      item?.cha?.name
    ) {
      return item.cha.name;
    }
    if (
      item?.payment_role === PaymentRole.BROKER &&
      item?.broker &&
      item?.broker?.name
    ) {
      return item.broker.name;
    }
    return "N/A";
  };

  const sortTable = async (key: string) => {
    let type = sortType === "asc" ? "desc" : "asc";
    if (sortKey !== key) {
      type = "asc";
    }
    setSortKey(key);
    setSortType(type);
    await getProfitAndLossReport(page, perPage, true, key, type);
  };

  useEffect(() => {
    const fetchTypeData = async () => {
      if (filterType === PaymentRole.SUPPLIER) {
        const result: any = await getSupplierNameListApi();
        if (result.isSuccess) {
          setRelationList(result?.data);
        } else {
          errorToast(result.message);
        }
        return;
      }
      if (filterType === PaymentRole.LOGISTIC) {
        const result: any = await getAllLogisticApi();
        if (result.isSuccess) {
          setRelationList(result?.data);
        } else {
          errorToast(result.message);
        }
        return;
      }
      if (filterType === PaymentRole.EXPENSE) {
        const result: any = await getAllExpensesApi();

        if (result.isSuccess) {
          setRelationList(result?.data);
        } else {
          errorToast(result.message);
        }
        return;
      }
      if (filterType === PaymentRole.BUYER) {
        const result: any = await getBuyerNameListApi();

        if (result.isSuccess) {
          setRelationList(result?.data);
        } else {
          errorToast(result.message);
        }
        return;
      }
      if (filterType === PaymentRole.CHA) {
        const result: any = await getAllChaApi();

        if (result.isSuccess) {
          setRelationList(result?.data);
        } else {
          errorToast(result.message);
        }
        return;
      }

      if (filterType === PaymentRole.BROKER) {
        const result: any = await getAllBrokerApi();

        if (result.isSuccess) {
          setRelationList(result?.data);
        } else {
          errorToast(result.message);
        }
        return;
      }

      setRelationList([]);
      setRelationID("");
    };
    fetchTypeData();
  }, [filterType]);

  useEffect(() => {
    if (page && perPage) {
      getProfitAndLossReport(page, perPage, applyFilter);
    }
  }, [page, perPage, applyFilter]);

  return (
    <>
      <div className="card">
        <div className="card-header pb-0 d-flex justify-content-between">
          <div>
            <h5>Profit & Loss Report</h5>
          </div>
          <div>
            <Link
              to="#"
              type="button"
              className="btn btn-label-github"
              style={{ marginRight: "15px" }}
              onClick={toggleFilter}
            >
              <span>
                <i className="ti ti-filter me-sm-1"></i>{" "}
                <span className="d-none d-sm-inline-block">Filter</span>
              </span>
            </Link>
            <Link
              to={"#"}
              className="btn btn-primary waves-effect waves-light"
              type="button"
              onClick={() => {
                exportProfitAndLossReport();
              }}
            >
              <span>
                <i className="ti ti-download me-sm-1"></i>{" "}
                <span className="d-none d-sm-inline-block">Download Excel</span>
              </span>
            </Link>
            <CSVLink
              data={exportData}
              filename={getFileName()}
              className="d-none"
              ref={csvLinkRef as any}
            >
              <span>
                <i className="ti ti-download me-sm-1"></i>{" "}
                <span className="d-none d-sm-inline-block">Download Excel</span>
              </span>
            </CSVLink>
          </div>
        </div>

        {displayFilter && (
          <div className="card-body">
            <form className="dt_adv_search">
              <div className="row">
                <div className="col-12">
                  <div className="row g-3">
                    <div className="col-12 col-sm-6 col-lg-3">
                      <label
                        htmlFor="date"
                        className="form-label"
                        style={{ fontSize: "0.9375rem" }}
                      >
                        Transaction Date:
                      </label>
                      <DateRangePicker
                        format="dd-MM-yyyy"
                        className="form-control"
                        placeholder="From Date ~ To Date"
                        value={dateRange}
                        onChange={(values: any) => {
                          setDateRange(values);
                        }}
                      />
                    </div>
                    <div className="col-12 col-sm-6 col-lg-3">
                      <label
                        htmlFor="payment_role"
                        className="form-label"
                        style={{ fontSize: "0.9375rem" }}
                      >
                        Payment Role:
                      </label>
                      <select
                        id="payment_role"
                        name="payment_role"
                        className="form-select"
                        value={paymentRole || ""}
                        onChange={(e) => {
                          const value = e.target.value;
                          setPaymentRole(value);
                          setFilterType(value);
                        }}
                      >
                        <option defaultChecked value={""}>
                          Select Payment Role
                        </option>
                        {[
                          PaymentRole.BUYER,
                          PaymentRole.SUPPLIER,
                          PaymentRole.CHA,
                          PaymentRole.LOGISTIC,
                          PaymentRole.EXPENSE,
                          PaymentRole.BROKER,
                        ].map((item) => (
                          <option value={item} key={item}>
                            {item}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="col-12 col-sm-6 col-lg-3">
                      <label
                        className="form-label"
                        style={{ fontSize: "0.9375rem" }}
                      >
                        {paymentRole
                          ? formatString(paymentRole)
                          : "Select Stackholder"}
                        :
                      </label>
                      <select
                        id="relation_id"
                        name="relation_id"
                        className="form-select"
                        value={relationID}
                        onChange={(e) => {
                          setRelationID(e.target.value);
                        }}
                      >
                        <option defaultChecked value={""}>
                          Select{" "}
                          {paymentRole
                            ? formatString(paymentRole)
                            : "Stackholder"}
                        </option>
                        {relationList.length > 0 &&
                          relationList.map((data: any) => (
                            <option key={data.id} value={data.id}>
                              {data.name}
                            </option>
                          ))}
                      </select>
                    </div>
                    <div className="col-12 col-sm-6 col-lg-3">
                      <label
                        className="form-label"
                        style={{ fontSize: "0.9375rem" }}
                      >
                        Transaction Type:
                      </label>
                      <select
                        id="transaction_type"
                        name="transaction_type"
                        className="form-select"
                        value={transactionType || ""}
                        onChange={(e) => {
                          setTransactionType(e.target.value);
                        }}
                      >
                        <option defaultChecked value={""}>
                          Select Transaction Type
                        </option>
                        {[TransactionTypes.CREDIT, TransactionTypes.DEBIT].map(
                          (item) => (
                            <option value={item} key={item}>
                              {item}
                            </option>
                          )
                        )}
                      </select>
                    </div>
                    <div className="col-12">
                      <button
                        type="button"
                        className="btn btn-primary"
                        onClick={filterList}
                      >
                        Filter
                      </button>
                      <button
                        type="button"
                        className="btn btn-label-github ms-5"
                        onClick={resetList}
                      >
                        Reset
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        )}

        <div className="card-datatable table-responsive">
          {loading && <Spinner isFullScreen={false} />}
          <table className="dt-advanced-search table table-nowrap">
            <thead>
              <tr>
                <th>#</th>
                <th>Payment Role</th>
                <th>Stackholder Name</th>
                <th
                  className="cursor-pointer"
                  onClick={() => sortTable("amount")}
                >
                  Amount
                  <span>
                    <i className="ti ti-arrows-down-up sorting-icon"></i>
                  </span>
                </th>
                <th
                  className="cursor-pointer"
                  onClick={() => sortTable("transaction_type")}
                >
                  Transaction Type
                  <span>
                    <i className="ti ti-arrows-down-up sorting-icon"></i>
                  </span>
                </th>
                <th
                  className="cursor-pointer"
                  onClick={() => sortTable("payment_method")}
                >
                  Payment Method
                  <span>
                    <i className="ti ti-arrows-down-up sorting-icon"></i>
                  </span>
                </th>
                <th
                  className="cursor-pointer"
                  onClick={() => sortTable("transaction_no")}
                >
                  Transaction No
                  <span>
                    <i className="ti ti-arrows-down-up sorting-icon"></i>
                  </span>
                </th>
                <th
                  className="cursor-pointer"
                  onClick={() => sortTable("payment_status")}
                >
                  Payment Status
                  <span>
                    <i className="ti ti-arrows-down-up sorting-icon"></i>
                  </span>
                </th>
                <th
                  className="cursor-pointer"
                  onClick={() => sortTable("remark")}
                >
                  Remark
                  <span>
                    <i className="ti ti-arrows-down-up sorting-icon"></i>
                  </span>
                </th>
                <th
                  className="cursor-pointer"
                  onClick={() => sortTable("transaction_date")}
                >
                  Transaction Date
                  <span>
                    <i className="ti ti-arrows-down-up sorting-icon"></i>
                  </span>
                </th>
              </tr>
            </thead>
            <tbody>
              {records.length > 0 ? (
                <>
                  {records.map((item: any, i: number) => {
                    let transactionTypeClass = "";
                    if (item.transaction_type === TransactionTypes.CREDIT) {
                      transactionTypeClass = `bg-label-success`;
                    } else {
                      transactionTypeClass = `bg-label-danger`;
                    }
                    let statusClass = "";
                    if (item.payment_status === PaymentStatus.PENDING) {
                      statusClass = `bg-label-warning`;
                    } else if (
                      item.payment_status === PaymentStatus.COMPLETED
                    ) {
                      statusClass = `bg-label-success`;
                    } else if (item.payment_status === PaymentStatus.ADVANCE) {
                      statusClass = `bg-label-info`;
                    } else {
                      statusClass = `bg-label-secondary`;
                    }
                    return (
                      <tr key={item.id}>
                        <td>{(page - 1) * perPage + 1 + i}</td>
                        <td>{item?.payment_role || ""}</td>
                        <td>{getStackHolderName(item)}</td>
                        <td>
                          {CURRENCY.INR.SYMBOL}
                          {formatAmount(item.amount)}
                        </td>
                        <td>
                          <span
                            className={`badge ${transactionTypeClass} me-1`}
                          >
                            {item.transaction_type}
                          </span>
                        </td>
                        <td>{item?.payment_method?.replace("_", " ") || ""}</td>
                        <td>{item.transaction_no}</td>
                        <td>
                          <td>
                            <span className={`badge ${statusClass} me-1`}>
                              {formatString(item.payment_status)}
                            </span>
                          </td>
                        </td>
                        <td>{item?.remark || ""}</td>
                        <td>{formatDate(item.transaction_date)}</td>
                      </tr>
                    );
                  })}
                </>
              ) : (
                <tr>
                  <td colSpan={10} className="text-center">
                    No records found
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        <div className="row mt-5 mx-0 align-items-center">
          <div className="col-sm-4 text-sm-center">
            <div>
              Total Credit:{" "}
              <span
                className="fw-bold"
                style={{ fontWeight: 500, color: "#444050" }}
              >
                {CURRENCY.INR.SYMBOL}
                {formatAmount(totalCreditAmount)}
              </span>
            </div>
          </div>
          <div className="col-sm-4 text-sm-center">
            <div>
              Total Debit:{" "}
              <span
                className="fw-bold"
                style={{ fontWeight: 500, color: "#444050" }}
              >
                {CURRENCY.INR.SYMBOL}
                {formatAmount(totalDebitAmount)}
              </span>
            </div>
          </div>
          <div className="col-sm-4">
            <div className="d-flex justify-content-sm-center">
              <p
                className="p-0 m-0"
                style={{
                  color: totalAmount > 0 ? "green" : "red",
                }}
              >
                {totalAmount > 0 ? "Profit:" : "Loss:"}
              </p>
              &nbsp;
              <span
                className="fw-bold"
                style={{ fontWeight: 500, color: "#444050" }}
              >
                {CURRENCY.INR.SYMBOL}
                {formatAmount(totalAmount)}
              </span>
            </div>
          </div>
        </div>

        <Pagination
          page={page}
          perPage={perPage}
          totalRecords={totalRecords}
          totalPages={totalPages}
          records={records}
          prevButton={prevButton}
          nextButton={nextButton}
        />
      </div>
    </>
  );
};

export default ProfitAndLossReport;
