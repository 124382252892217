import {
  allReportsRouts,
  allSalesRoutes,
  handleMouseEnter,
  handleMouseLeave,
  handleNavbarClick,
  handleSidebarClick,
} from "./ThemeHelper";
import { useEffect, useState } from "react";
import { COMPANY_NAME } from "../components/Constants";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { getPermissions, getToken } from "../context/AuthContext";

const Sidebar = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const permissions = getPermissions();
  const [saleToggle, setSaleToggle] = useState(0);
  const [reportToggle, setReportToggle] = useState(0);

  useEffect(() => {
    const token = getToken();
    if (!token && location.pathname !== "/login") {
      navigate("/login");
    }
    if (allSalesRoutes.includes(location?.pathname?.split("/")?.[1])) {
      setSaleToggle(1);
    } else {
      setSaleToggle(0);
    }
    if (allReportsRouts.includes(location?.pathname?.split("/")?.[1])) {
      setReportToggle(1);
    } else {
      setReportToggle(0);
    }
  }, [location.pathname, navigate]);

  const handleSaleToggle = () => {
    const newToggle = saleToggle ? 0 : 1;
    setSaleToggle(newToggle);
  };

  const handleReportToggle = () => {
    const newToggle = reportToggle ? 0 : 1;
    setReportToggle(newToggle);
  };

  return (
    <aside
      id="layout-menu"
      className="layout-menu menu-vertical menu bg-menu-theme"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <div className="app-brand demo">
        <Link to="/dashboard" className="app-brand-link">
          <span
            className="app-brand-logo demo"
            style={{ alignItems: "unset !important", height: "44px" }}
          >
            <img src="/img/logo/ee-main.svg" alt={COMPANY_NAME} />
          </span>
          <span className="app-brand-text demo menu-text fw-bold">
            {COMPANY_NAME}
          </span>
        </Link>

        <Link
          to="#"
          className="layout-menu-toggle menu-link text-large ms-auto"
          onClick={(e: any) => handleSidebarClick(e)}
        >
          <i className="ti menu-toggle-icon d-none d-xl-block align-middle"></i>
          <i
            className="ti ti-x d-block d-xl-none ti-md align-middle"
            onClick={(e: any) => handleNavbarClick(e)}
          ></i>
        </Link>
      </div>

      <div className="menu-inner-shadow"></div>

      <ul className="menu-inner py-1 overflow-auto">
        {/* Dashboard */}
        <li
          className={`menu-item ${
            ["dashboard"].includes(location?.pathname?.split("/")?.[1])
              ? "active"
              : ""
          }`}
        >
          <Link
            to="/dashboard"
            className="menu-link"
            onClick={(e: any) => handleNavbarClick(e)}
          >
            <i className="menu-icon tf-icons ti ti-smart-home"></i>
            <div data-i18n="Dashboard">Dashboard</div>
          </Link>
        </li>

        {/* Users */}
        {permissions.includes("view-user") && (
          <li
            className={`menu-item ${
              ["user-list", "create-user", "edit-user"].includes(
                location?.pathname?.split("/")?.[1]
              )
                ? "active"
                : ""
            }`}
          >
            <Link
              to="/user-list"
              className="menu-link"
              onClick={(e: any) => handleNavbarClick(e)}
            >
              <i className="menu-icon tf-icons ti ti-users"></i>
              <div data-i18n="Users">Users</div>
            </Link>
          </li>
        )}

        {/* Role */}
        {permissions.includes("view-role") && (
          <li
            className={`menu-item ${
              ["role-list", "create-role", "edit-role"].includes(
                location?.pathname?.split("/")?.[1]
              )
                ? "active"
                : ""
            }`}
          >
            <Link to="/role-list" className="menu-link">
              <i className="menu-icon tf-icons ti ti-key"></i>
              <div data-i18n="Roles">Roles</div>
            </Link>
          </li>
        )}

        {/* Logistics */}
        {permissions.includes("view-logistic") && (
          <li
            className={`menu-item ${
              ["logistic-list", "create-logistic", "edit-logistic"].includes(
                location?.pathname?.split("/")?.[1]
              )
                ? "active"
                : ""
            }`}
          >
            <Link
              to="/logistic-list"
              className="menu-link"
              onClick={(e: any) => handleNavbarClick(e)}
            >
              <i className="menu-icon tf-icons ti ti-truck-loading"></i>
              <div data-i18n="Logistics">Logistics</div>
            </Link>
          </li>
        )}

        {/* CHA */}
        {permissions.includes("view-cha") && (
          <li
            className={`menu-item ${
              ["cha-list", "create-cha", "edit-cha"].includes(
                location?.pathname?.split("/")?.[1]
              )
                ? "active"
                : ""
            }`}
          >
            <Link
              to="/cha-list"
              className="menu-link"
              onClick={(e: any) => handleNavbarClick(e)}
            >
              <i className="menu-icon tf-icons ti ti-briefcase"></i>
              <div data-i18n="CHA">CHA</div>
            </Link>
          </li>
        )}

        {/* Suppliers */}
        {permissions.includes("view-supplier") && (
          <li
            className={`menu-item ${
              ["supplier-list", "create-supplier", "edit-supplier"].includes(
                location?.pathname?.split("/")?.[1]
              )
                ? "active"
                : ""
            }`}
          >
            <Link
              to="/supplier-list"
              className="menu-link"
              onClick={(e: any) => handleNavbarClick(e)}
            >
              <i className="menu-icon tf-icons ti ti-truck-return"></i>
              <div data-i18n="Suppliers">Suppliers</div>
            </Link>
          </li>
        )}

        {/* Purchase */}
        {permissions.includes("view-purchase") && (
          <li
            className={`menu-item ${
              [
                "purchase-list",
                "view-purchase",
                "create-purchase",
                "edit-purchase",
              ].includes(location?.pathname?.split("/")?.[1])
                ? "active"
                : ""
            }`}
          >
            <Link
              to="/purchase-list"
              className="menu-link"
              onClick={(e: any) => handleNavbarClick(e)}
            >
              <i className="menu-icon tf-icons ti ti-package-import"></i>
              <div data-i18n="Purchase">Purchase</div>
            </Link>
          </li>
        )}

        {/* Buyer */}
        {permissions.includes("view-buyer") && (
          <li
            className={`menu-item ${
              ["buyer-list", "create-buyer", "edit-buyer"].includes(
                location?.pathname?.split("/")?.[1]
              )
                ? "active"
                : ""
            }`}
          >
            <Link
              to="/buyer-list"
              className="menu-link"
              onClick={(e: any) => handleNavbarClick(e)}
            >
              <i className="menu-icon tf-icons ti ti-shopping-cart"></i>
              <div data-i18n="Buyers">Buyers</div>
            </Link>
          </li>
        )}

        {permissions.includes("view-broker") && (
          <li
            className={`menu-item ${
              ["broker-list", "create-broker", "edit-broker"].includes(
                location?.pathname?.split("/")?.[1]
              )
                ? "active"
                : ""
            }`}
          >
            <Link
              to="/broker-list"
              className="menu-link"
              onClick={(e: any) => handleNavbarClick(e)}
            >
              <i className="menu-icon tf-icons ti ti-user-dollar"></i>
              <div data-i18n="Brokers">Brokers</div>
            </Link>
          </li>
        )}

        {/* Sales */}
        {(permissions.includes("view-sales-contract") ||
          permissions.includes("view-sales-invoice")) && (
          <li
            className={`menu-item ${
              saleToggle ||
              allSalesRoutes.includes(location?.pathname?.split("/")?.[1])
                ? "open"
                : ""
            }`}
            onClick={handleSaleToggle}
          >
            <Link to="#" className="menu-link menu-toggle">
              <i className="menu-icon tf-icons ti ti-report"></i>
              <div data-i18n="Sales">Sales</div>
            </Link>
            <ul className="menu-sub">
              {permissions.includes("view-sales-contract") && (
                <li
                  className={`menu-item ${
                    [
                      "sales-contract-list",
                      "create-sales-contract",
                      "edit-sales-contract",
                      "view-sales-contract",
                    ].includes(location?.pathname?.split("/")?.[1])
                      ? "active"
                      : ""
                  }`}
                >
                  <Link
                    to="/sales-contract-list"
                    className="menu-link"
                    onClick={(e: any) => handleNavbarClick(e)}
                  >
                    <div data-i18n="Sales Contract">Sales Contract</div>
                  </Link>
                </li>
              )}
              {permissions.includes("view-sales-invoice") && (
                <li
                  className={`menu-item ${
                    [
                      "sales-invoice-list",
                      "create-sales-invoice",
                      "edit-sales-invoice",
                      "view-sales-invoice",
                    ].includes(location?.pathname?.split("/")?.[1])
                      ? "active"
                      : ""
                  }`}
                >
                  <Link
                    to="/sales-invoice-list"
                    className="menu-link"
                    onClick={(e: any) => handleNavbarClick(e)}
                  >
                    <div data-i18n="Sales Invoice">Sales Invoice</div>
                  </Link>
                </li>
              )}
              {permissions.includes("view-miscellaneous-sales") && (
                <li
                  className={`menu-item ${
                    [
                      "miscellaneous-sales-list",
                      "view-miscellaneous-sales",
                      "create-miscellaneous-sales",
                      "edit-miscellaneous-sales",
                    ].includes(location?.pathname?.split("/")?.[1])
                      ? "active"
                      : ""
                  }`}
                >
                  <Link
                    to="/miscellaneous-sales-list"
                    className="menu-link"
                    onClick={(e: any) => handleNavbarClick(e)}
                  >
                    <i className="menu-icon tf-icons ti ti-package-import"></i>
                    <div data-i18n="Miscellaneous Sales">
                      Miscellaneous Sales
                    </div>
                  </Link>
                </li>
              )}
            </ul>
          </li>
        )}

        {/* Seal Number */}
        {permissions.includes("view-seal-number") && (
          <li
            className={`menu-item ${
              [
                "seal-number-list",
                "create-seal-number",
                "edit-seal-number",
              ].includes(location?.pathname?.split("/")?.[1])
                ? "active"
                : ""
            }`}
          >
            <Link
              to="/seal-number-list"
              className="menu-link"
              onClick={(e: any) => handleNavbarClick(e)}
            >
              <i className="menu-icon tf-icons ti ti-tag"></i>
              <div data-i18n="Seal Numbers">Seal Numbers</div>
            </Link>
          </li>
        )}

        {/* Products */}
        {permissions.includes("view-product") && (
          <li
            className={`menu-item ${
              ["product-list", "create-product", "edit-product"].includes(
                location?.pathname?.split("/")?.[1]
              )
                ? "active"
                : ""
            }`}
          >
            <Link
              to="/product-list"
              className="menu-link"
              onClick={(e: any) => handleNavbarClick(e)}
            >
              <i className="menu-icon tf-icons ti ti-package"></i>
              <div data-i18n="Products">Products</div>
            </Link>
          </li>
        )}

        {/* Documents */}
        {permissions.includes("view-document") && (
          <li
            className={`menu-item ${
              ["document-list", "create-document", "edit-document"].includes(
                location?.pathname?.split("/")?.[1]
              )
                ? "active"
                : ""
            }`}
          >
            <Link
              to="/document-list"
              className="menu-link"
              onClick={(e: any) => handleNavbarClick(e)}
            >
              <i className="menu-icon tf-icons ti ti-paperclip"></i>
              <div data-i18n="Documents">Documents</div>
            </Link>
          </li>
        )}

        {/* Quality Specifications */}
        {permissions.includes("view-quality-specification") && (
          <li
            className={`menu-item ${
              [
                "quality-specification-list",
                "create-quality-specification",
                "edit-quality-specification",
              ].includes(location?.pathname?.split("/")?.[1])
                ? "active"
                : ""
            }`}
          >
            <Link
              to="/quality-specification-list"
              className="menu-link"
              onClick={(e: any) => handleNavbarClick(e)}
            >
              <i className="menu-icon tf-icons ti ti-checkbox"></i>
              <div data-i18n="Quality Specifications">
                Quality Specifications
              </div>
            </Link>
          </li>
        )}

        {/* Port */}
        {permissions.includes("view-port") && (
          <li
            className={`menu-item ${
              ["port-list", "create-port", "edit-port"].includes(
                location?.pathname?.split("/")?.[1]
              )
                ? "active"
                : ""
            }`}
          >
            <Link
              to="/port-list"
              className="menu-link"
              onClick={(e: any) => handleNavbarClick(e)}
            >
              <i className="menu-icon tf-icons ti ti-ship"></i>
              <div data-i18n="Port">Port</div>
            </Link>
          </li>
        )}

        {/* Unit */}
        {permissions.includes("view-unit") && (
          <li
            className={`menu-item ${
              ["unit-list", "create-unit", "edit-unit"].includes(
                location?.pathname?.split("/")?.[1]
              )
                ? "active"
                : ""
            }`}
          >
            <Link
              to="/unit-list"
              className="menu-link"
              onClick={(e: any) => handleNavbarClick(e)}
            >
              <i className="menu-icon tf-icons ti ti-scale"></i>
              <div data-i18n="Units">Units</div>
            </Link>
          </li>
        )}

        {/* Packing Type */}
        {permissions.includes("view-packing-type") && (
          <li
            className={`menu-item ${
              [
                "packing-type-list",
                "create-packing-type",
                "edit-packing-type",
              ].includes(location?.pathname?.split("/")?.[1])
                ? "active"
                : ""
            }`}
          >
            <Link
              to="/packing-type-list"
              className="menu-link"
              onClick={(e: any) => handleNavbarClick(e)}
            >
              <i className="menu-icon tf-icons ti ti-packages"></i>
              <div data-i18n="Packing Types">Packing Types</div>
            </Link>
          </li>
        )}

        {/* Expenses */}
        {permissions.includes("view-expenses") && (
          <li
            className={`menu-item ${
              ["expenses-list", "create-expenses", "edit-expenses"].includes(
                location?.pathname?.split("/")?.[1]
              )
                ? "active"
                : ""
            }`}
          >
            <Link
              to="/expenses-list"
              className="menu-link"
              onClick={(e: any) => handleNavbarClick(e)}
            >
              <i className="menu-icon tf-icons ti ti-credit-card-pay"></i>
              <div data-i18n="Expenses">Expenses</div>
            </Link>
          </li>
        )}

        {/* Payments */}
        {permissions.includes("view-payments") && (
          <li
            className={`menu-item ${
              ["payment-list", "create-payment", "edit-payment"].includes(
                location?.pathname?.split("/")?.[1]
              )
                ? "active"
                : ""
            }`}
          >
            <Link
              to="/payment-list"
              className="menu-link"
              onClick={(e: any) => handleNavbarClick(e)}
            >
              <i className="menu-icon tf-icons ti ti-currency-rupee"></i>
              <div data-i18n="Payments">Payments</div>
            </Link>
          </li>
        )}

        {/* My Banks */}
        {/* {permissions.includes("view-my-bank") && (
          <li
            className={`menu-item ${
              ["my-bank-list", "create-my-bank", "edit-my-bank"].includes(
                location?.pathname?.split("/")?.[1]
              )
                ? "active"
                : ""
            }`}
          >
            <Link
              to="/my-bank-list"
              className="menu-link"
              onClick={(e: any) => handleNavbarClick(e)}
            >
              <i className="menu-icon tf-icons ti ti-building-bank"></i>
              <div data-i18n="My Banks">My Banks</div>
            </Link>
          </li>
        )} */}

        {/* Bank */}
        {permissions.includes("view-bank") && (
          <li
            className={`menu-item ${
              ["bank-list", "create-bank", "edit-bank"].includes(
                location?.pathname?.split("/")?.[1]
              )
                ? "active"
                : ""
            }`}
          >
            <Link
              to="/bank-list"
              className="menu-link"
              onClick={(e: any) => handleNavbarClick(e)}
            >
              <i className="menu-icon tf-icons ti ti-building-bank"></i>
              <div data-i18n="Banks">Banks</div>
            </Link>
          </li>
        )}

        {/* Reports */}
        {(permissions.includes("view-buyer-wise-sales-report") ||
          permissions.includes("view-product-wise-sales-report") ||
          permissions.includes("view-supplier-wise-purchase-report") ||
          permissions.includes("view-product-wise-purchase-report") ||
          permissions.includes("view-profit-and-loss-report") ||
          permissions.includes("view-buyer-wise-sales-payment-report") ||
          permissions.includes("view-broker-wise-payment-report") ||
          permissions.includes("view-supplier-wise-payment-report") ||
          permissions.includes("view-logistic-wise-payment-report") ||
          permissions.includes("view-cha-wise-payment-report") ||
          permissions.includes("view-expense-report")) && (
          <li
            className={`menu-item ${
              reportToggle ||
              allReportsRouts.includes(location?.pathname?.split("/")?.[1])
                ? "open"
                : ""
            }`}
            onClick={handleReportToggle}
          >
            <Link to="#" className="menu-link menu-toggle">
              <i className="menu-icon tf-icons ti ti-report"></i>
              <div data-i18n="Reports">Reports</div>
            </Link>
            <ul className="menu-sub">
              {permissions.includes("view-buyer-wise-sales-report") && (
                <li
                  className={`menu-item ${
                    ["buyer-wise-sales-report"].includes(
                      location?.pathname?.split("/")?.[1]
                    )
                      ? "active"
                      : ""
                  }`}
                >
                  <Link
                    to="/buyer-wise-sales-report"
                    className="menu-link"
                    onClick={(e: any) => handleNavbarClick(e)}
                  >
                    <div data-i18n="Buyer Wise Sales">Buyer Wise Sales</div>
                  </Link>
                </li>
              )}
              {permissions.includes("view-product-wise-sales-report") && (
                <li
                  className={`menu-item ${
                    ["product-wise-sales-report"].includes(
                      location?.pathname?.split("/")?.[1]
                    )
                      ? "active"
                      : ""
                  }`}
                >
                  <Link
                    to="/product-wise-sales-report"
                    className="menu-link"
                    onClick={(e: any) => handleNavbarClick(e)}
                  >
                    <div data-i18n="Product Wise Sales">Product Wise Sales</div>
                  </Link>
                </li>
              )}
              {permissions.includes("view-supplier-wise-purchase-report") && (
                <li
                  className={`menu-item ${
                    ["supplier-wise-purchase-report"].includes(
                      location?.pathname?.split("/")?.[1]
                    )
                      ? "active"
                      : ""
                  }`}
                >
                  <Link
                    to="/supplier-wise-purchase-report"
                    className="menu-link"
                    onClick={(e: any) => handleNavbarClick(e)}
                  >
                    <div data-i18n="Supplier Wise Purchase">
                      Supplier Wise Purchase
                    </div>
                  </Link>
                </li>
              )}
              {permissions.includes("view-product-wise-purchase-report") && (
                <li
                  className={`menu-item ${
                    ["product-wise-purchase-report"].includes(
                      location?.pathname?.split("/")?.[1]
                    )
                      ? "active"
                      : ""
                  }`}
                >
                  <Link
                    to="/product-wise-purchase-report"
                    className="menu-link"
                    onClick={(e: any) => handleNavbarClick(e)}
                  >
                    <div data-i18n="Product Wise Purchase">
                      Product Wise Purchase
                    </div>
                  </Link>
                </li>
              )}
              {permissions.includes("view-profit-and-loss-report") && (
                <li
                  className={`menu-item ${
                    ["profit-and-loss-report"].includes(
                      location?.pathname?.split("/")?.[1]
                    )
                      ? "active"
                      : ""
                  }`}
                >
                  <Link
                    to="/profit-and-loss-report"
                    className="menu-link"
                    onClick={(e: any) => handleNavbarClick(e)}
                  >
                    <div data-i18n="Profit & Loss Report">
                      Profit & Loss Report
                    </div>
                  </Link>
                </li>
              )}
              {permissions.includes("view-buyer-wise-sales-payment-report") && (
                <li
                  className={`menu-item ${
                    ["buyer-wise-payment-report"].includes(
                      location?.pathname?.split("/")?.[1]
                    )
                      ? "active"
                      : ""
                  }`}
                >
                  <Link
                    to="/buyer-wise-payment-report"
                    className="menu-link"
                    onClick={(e: any) => handleNavbarClick(e)}
                  >
                    <div data-i18n="Buyer Wise Sales Payment">
                      Buyer Wise Sales Payment
                    </div>
                  </Link>
                </li>
              )}
              {permissions.includes("view-broker-wise-payment-report") && (
                <li
                  className={`menu-item ${
                    ["broker-wise-payment-report"].includes(
                      location?.pathname?.split("/")?.[1]
                    )
                      ? "active"
                      : ""
                  }`}
                >
                  <Link
                    to="/broker-wise-payment-report"
                    className="menu-link"
                    onClick={(e: any) => handleNavbarClick(e)}
                  >
                    <div data-i18n="Broker Wise Payments">
                      Broker Wise Payments
                    </div>
                  </Link>
                </li>
              )}
              {permissions.includes("view-supplier-wise-payment-report") && (
                <li
                  className={`menu-item ${
                    ["supplier-wise-payment-report"].includes(
                      location?.pathname?.split("/")?.[1]
                    )
                      ? "active"
                      : ""
                  }`}
                >
                  <Link
                    to="/supplier-wise-payment-report"
                    className="menu-link"
                    onClick={(e: any) => handleNavbarClick(e)}
                  >
                    <div data-i18n="Supplier Wise Payments">
                      Supplier Wise Payments
                    </div>
                  </Link>
                </li>
              )}
              {permissions.includes("view-logistic-wise-payment-report") && (
                <li
                  className={`menu-item ${
                    ["logistic-wise-payment-report"].includes(
                      location?.pathname?.split("/")?.[1]
                    )
                      ? "active"
                      : ""
                  }`}
                >
                  <Link
                    to="/logistic-wise-payment-report"
                    className="menu-link"
                    onClick={(e: any) => handleNavbarClick(e)}
                  >
                    <div data-i18n="Logistic Wise Payments">
                      Logistic Wise Payments
                    </div>
                  </Link>
                </li>
              )}
              {permissions.includes("view-cha-wise-payment-report") && (
                <li
                  className={`menu-item ${
                    ["cha-wise-payment-report"].includes(
                      location?.pathname?.split("/")?.[1]
                    )
                      ? "active"
                      : ""
                  }`}
                >
                  <Link
                    to="/cha-wise-payment-report"
                    className="menu-link"
                    onClick={(e: any) => handleNavbarClick(e)}
                  >
                    <div data-i18n="CHA Wise Payments">CHA Wise Payments</div>
                  </Link>
                </li>
              )}
              {permissions.includes("view-expense-report") && (
                <li
                  className={`menu-item ${
                    ["expense-report"].includes(
                      location?.pathname?.split("/")?.[1]
                    )
                      ? "active"
                      : ""
                  }`}
                >
                  <Link
                    to="/expense-report"
                    className="menu-link"
                    onClick={(e: any) => handleNavbarClick(e)}
                  >
                    <div data-i18n="Expense Report">Expense Report</div>
                  </Link>
                </li>
              )}
            </ul>
          </li>
        )}

        {/* Settings */}
        {permissions.includes("settings") && (
          <li
            className={`menu-item ${
              ["settings", "/update-settings"].includes(
                location?.pathname?.split("/")?.[1]
              )
                ? "active"
                : ""
            }`}
          >
            <Link
              to="/settings"
              className="menu-link"
              onClick={(e: any) => handleNavbarClick(e)}
            >
              <i
                className="menu-icon tf-icons ti ti-settings"
                style={{ fontSize: "18px" }}
              ></i>
              <div data-i18n="Settings">Settings</div>
            </Link>
          </li>
        )}

        {/* {(permissions.includes("view-bank") ||
          permissions.includes("view-my-bank")) && (
          <li
            className={`menu-item ${bankToggle ? "open" : ""} ${
              allBankRoutes.includes(location?.pathname?.split("/")?.[1])
                ? "open"
                : ""
            }`}
            onClick={handleBankToggle}
          >
            <Link to="#" className="menu-link menu-toggle">
              <i className="menu-icon tf-icons ti ti-building-bank"></i>
              <div data-i18n="Banks">Banks</div>
            </Link>
            <ul className="menu-sub">
              {permissions.includes("view-my-bank") && (
                <li
                  className={`menu-item ${
                    [
                      "my-bank-list",
                      "create-my-bank",
                      "edit-my-bank",
                    ].includes(location?.pathname?.split("/")?.[1])
                      ? "active"
                      : ""
                  }`}
                >
                  <Link
                    to="/my-bank-list"
                    className="menu-link"
                    onClick={(e: any) => handleNavbarClick(e)}
                  >
                    <div data-i18n="Company Banks">Company Banks</div>
                  </Link>
                </li>
              )}
              {permissions.includes("view-bank") && (
                <li
                  className={`menu-item ${
                    ["bank-list", "create-bank", "edit-bank"].includes(
                      location?.pathname?.split("/")?.[1]
                    )
                      ? "active"
                      : ""
                  }`}
                >
                  <Link
                    to="/bank-list"
                    className="menu-link"
                    onClick={(e: any) => handleNavbarClick(e)}
                  >
                    <div data-i18n="Stakeholder Banks">Stakeholder Banks</div>
                  </Link>
                </li>
              )}
            </ul>
          </li>
        )} */}
      </ul>
    </aside>
  );
};

export default Sidebar;
