/* eslint-disable react-hooks/exhaustive-deps */
import {
  getAllSalesContractApi,
  getAllSalesInvoiceApi,
} from "../../services/_sales";
import moment from "moment";
import { CSVLink } from "react-csv";
import { Link } from "react-router-dom";
import { DateRangePicker } from "rsuite";
import Spinner from "../../theme/Spinner";
import { useEffect, useRef, useState } from "react";
import Pagination from "../../components/Pagination";
import { CURRENCY } from "../../components/Constants";
import { getAllLogisticApi } from "../../services/_logistic";
import {
  formatAmount,
  formatDate,
  formatString,
} from "../../components/Helper";
import { logisticWisePaymentReportApi } from "../../services/_report";
import { PaymentStatus, TransactionTypes } from "../../components/Enums";

interface CustomCSVLink extends HTMLAnchorElement {
  link: HTMLAnchorElement;
}

const LogisticWisePaymentReport = () => {
  const [record, setRecord] = useState<any[]>([]);
  const [logisticList, setLogisticList] = useState<any[]>([]);
  const [saleList, setSaleList] = useState<any[]>([]);
  const [saleOrderList, setSaleOrderList] = useState<any[]>([]);
  const [filterLogistic, setFilterLogistic] = useState<string>("");
  const [filterSale, setFilterSale] = useState<string>("");
  const [filterSaleOrder, setFilterSaleOrder] = useState<string>("");
  const [dateRange, setDateRange] = useState(null);
  const [page, setPage] = useState<number>(1);
  const [perPage] = useState<number>(10);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [totalRecords, setTotalRecords] = useState<number>(0);
  const [applyFilter, setApplyFilter] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [displayFilter, setDisplayFilter] = useState(false);
  const [totalCreditAmount, setTotalCreditAmount] = useState(0);
  const [totalDebitAmount, setTotalDebitAmount] = useState(0);
  const [totalAmount, setTotalAmount] = useState(0);
  const csvLinkRef = useRef<CustomCSVLink>(null);
  const [exportData, setExportData] = useState([]);
  const [sortKey, setSortKey] = useState<string>("");
  const [sortType, setSortType] = useState<string>("");

  const toggleFilter = async () => {
    setDisplayFilter(!displayFilter);
  };

  const LogisticNameList = async () => {
    try {
      const result: any = await getAllLogisticApi();
      setLogisticList(result?.data);
    } catch (error) {
      console.error("Error fetching logistic list", error);
    }
  };

  const getSales = async (query?: string) => {
    try {
      const result: any = await getAllSalesContractApi(query && query);
      setSaleList(result?.data);
    } catch (error) {
      console.error("Error fetching Sale list", error);
    }
  };

  const getSaleOrderList = async (query?: string) => {
    try {
      const result: any = await getAllSalesInvoiceApi(query && query);
      setSaleOrderList(result?.data);
    } catch (error) {
      console.error("Error fetching SaleOrder list", error);
    }
  };

  const getLogisticReportList = async (
    page: number,
    perPage: number,
    isFilter?: boolean,
    sortKey?: string,
    sortType?: string
  ) => {
    setLoading(true);
    let query = `page=${page}&per_page=${perPage}`;
    if (isFilter) {
      if (filterLogistic) {
        query += `&logistic_id=${filterLogistic}`;
      }
      if (filterSale) {
        query += `&sales_contract_id=${filterSale}`;
      }
      if (filterSaleOrder) {
        query += `&sales_invoice_id=${filterSaleOrder}`;
      }
      if (dateRange) {
        query += `&from_date=${moment(dateRange[0]).format("YYYY-MM-DD")}`;
        query += `&to_date=${moment(dateRange[1]).format("YYYY-MM-DD")}`;
      }
      if (sortKey && sortType) {
        query += `&sort_key=${sortKey}&sort_type=${sortType}`;
      }
    }
    const result: any = await logisticWisePaymentReportApi(query);

    if (result.isSuccess) {
      const totalPagesCount = Math.ceil(result.data.total_records / perPage);
      setRecord(result?.data.records);
      setTotalPages(totalPagesCount);
      setTotalRecords(result.data.total_records);
      setTotalCreditAmount(result?.data?.total_credit || 0);
      setTotalDebitAmount(result?.data?.total_debit || 0);
      setTotalAmount(result?.data?.total_amount || 0);
    }
    setLoading(false);
  };

  const filterList = async () => {
    setApplyFilter(true);
    getLogisticReportList(page, perPage, true);
  };

  const resetList = async () => {
    setPage(1);
    setApplyFilter(false);
    setFilterLogistic("");
    setFilterSale("");
    setFilterSaleOrder("");
    setDateRange(null);
    getSales();
    getSaleOrderList();
    getLogisticReportList(page, perPage);
  };

  const prevButton = async () => {
    if (page > 1) {
      setPage(page - 1);
    }
  };

  const nextButton = async () => {
    if (totalPages > page) {
      setPage(page + 1);
    }
  };

  const getFileName = () => {
    const filerName = "logistic-wise-payment-report";
    return `${filerName}-${moment().format("MM-DD-YYYY-HH-mm-ss")}.csv`;
  };

  const exportLogisticRecords = async () => {
    setExportData([]);
    let csvHeader: any;
    let csvData: any;
    let csvFooter: any;

    let query = `export=true`;
    if (filterLogistic) {
      query += `&logistic_id=${filterLogistic}`;
    }
    if (filterSale) {
      query += `&sales_contract_id=${filterSale}`;
    }
    if (filterSaleOrder) {
      query += `&sales_invoice_id=${filterSaleOrder}`;
    }
    if (dateRange) {
      query += `&from_date=${moment(dateRange[0]).format("YYYY-MM-DD")}`;
      query += `&to_date=${moment(dateRange[1]).format("YYYY-MM-DD")}`;
    }
    if (sortKey && sortType) {
      query += `&sort_key=${sortKey}&sort_type=${sortType}`;
    }

    const result: any = await logisticWisePaymentReportApi(query);
    if (result.isSuccess) {
      if (result.data.records) {
        csvHeader = [
          "Sr No.",
          "Logistic",
          "Sales Contract No",
          "Sales Invoice No",
          "Amount",
          "Transaction Type",
          "Payment Method",
          "Transaction No",
          "Payment Status",
          "Remark",
          "Transaction Date",
        ];
        csvData = result.data.records.map((item: any, index: number) => [
          (index + 1).toString(),
          item?.logistic?.name || "",
          item?.sales_contract?.contract_no || "",
          item?.sales_invoice?.invoice_no || "",
          `${CURRENCY.INR.SYMBOL}${formatAmount(item.amount)}`,
          item?.transaction_type || "",
          item?.payment_method.replace("_", " ") || "",
          item?.transaction_no || "",
          formatString(item?.payment_status) || "",
          item?.remark || "",
          formatDate(item.transaction_date),
        ]);
        csvFooter = [
          [
            "",
            "",
            "",
            "Total Credit :",
            `${CURRENCY.INR.SYMBOL}${formatAmount(totalCreditAmount)}`,
            "",
            "",
            "",
            "",
            "",
            "",
          ],
          [
            "",
            "",
            "",
            "Total Debit :",
            `${CURRENCY.INR.SYMBOL}${formatAmount(totalDebitAmount)}`,
            "",
            "",
            "",
            "",
            "",
            "",
          ],
          [
            "",
            "",
            "",
            totalAmount > 0 ? "Profit :" : "Loss :",
            `${CURRENCY.INR.SYMBOL}${formatAmount(totalAmount)}`,
            "",
            "",
            "",
            "",
            "",
            "",
          ],
        ];

        const exportData: any = [csvHeader, ...csvData, ...csvFooter];
        setExportData(exportData);

        if (csvLinkRef.current?.link) {
          setTimeout(() => {
            csvLinkRef.current!.link.click();
          }, 0);
        }
      }
    }
  };

  const sortTable = async (key: string) => {
    let type = sortType === "asc" ? "desc" : "asc";
    if (sortKey !== key) {
      type = "asc";
    }
    setSortKey(key);
    setSortType(type);
    await getLogisticReportList(page, perPage, true, key, type);
  };

  const filterSuggestion = ({ target: { name, value } }: any) => {
    const query = value && `${name}=${value}`;
    if (name === "logistic_id") {
      getSales(query);
      getSaleOrderList(query);
    }
    if (name === "sales_contract_id") {
      const queryString = filterLogistic
        ? `${query}&&logistic_id=${filterLogistic}`
        : query;

      getSaleOrderList(queryString);
    }
  };

  useEffect(() => {
    LogisticNameList();
    getSales();
    getSaleOrderList();
  }, []);

  useEffect(() => {
    if (page && perPage) {
      getLogisticReportList(page, perPage, applyFilter);
    }
  }, [page, perPage, applyFilter]);

  return (
    <>
      <div className="card">
        <div className="card-header pb-0 d-flex justify-content-between">
          <div>
            <h5>Logistic Wise Payment Report</h5>
          </div>
          <div>
            <Link
              to="#"
              type="button"
              className="btn btn-label-github"
              style={{ marginRight: "15px" }}
              onClick={toggleFilter}
            >
              <span>
                <i className="ti ti-filter me-sm-1"></i>{" "}
                <span className="d-none d-sm-inline-block">Filter</span>
              </span>
            </Link>
            <Link
              to={"#"}
              className="btn btn-primary waves-effect waves-light"
              type="button"
              onClick={() => {
                exportLogisticRecords();
              }}
            >
              <span>
                <i className="ti ti-download me-sm-1"></i>{" "}
                <span className="d-none d-sm-inline-block">Download Excel</span>
              </span>
            </Link>
            <CSVLink
              data={exportData}
              filename={getFileName()}
              className="d-none"
              ref={csvLinkRef as any}
            >
              <span>
                <i className="ti ti-download me-sm-1"></i>{" "}
                <span className="d-none d-sm-inline-block">Download Excel</span>
              </span>
            </CSVLink>
          </div>
        </div>

        {displayFilter && (
          <div className="card-body">
            <form className="dt_adv_search">
              <div className="row">
                <div className="col-12">
                  <div className="row g-3">
                    <div className="col-12 col-sm-6 col-lg-3">
                      <label
                        htmlFor="date"
                        className="form-label"
                        style={{ fontSize: "0.9375rem" }}
                      >
                        Transaction Date:
                      </label>
                      <DateRangePicker
                        format="dd-MM-yyyy"
                        className="form-control"
                        placeholder="From Date ~ To Date"
                        value={dateRange}
                        onChange={(values: any) => {
                          setDateRange(values);
                        }}
                      />
                    </div>
                    <div className="col-12 col-sm-6 col-lg-3">
                      <label
                        htmlFor="logistic_id"
                        className="form-label"
                        style={{ fontSize: "0.9375rem" }}
                      >
                        Logistic:
                      </label>
                      <select
                        className="form-select"
                        name="logistic_id"
                        id="logistic_id"
                        value={filterLogistic}
                        onChange={(e) => {
                          setFilterLogistic(e.target.value);
                          filterSuggestion(e);
                        }}
                      >
                        <option value="">Select Logistic</option>
                        {logisticList.map((logistic) => (
                          <option value={logistic.id} key={logistic.id}>
                            {logistic.name}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="col-12 col-sm-6 col-lg-3">
                      <label
                        htmlFor="sales_contract_id"
                        className="form-label"
                        style={{ fontSize: "0.9375rem" }}
                      >
                        Sales Contract No:
                      </label>
                      <select
                        className="form-select"
                        name="sales_contract_id"
                        id="sales_contract_id"
                        value={filterSale}
                        onChange={(e) => {
                          setFilterSale(e.target.value);
                          filterSuggestion(e);
                        }}
                      >
                        <option value="">Select Sales Contract No</option>
                        {saleList.map((sale) => (
                          <option value={sale.id} key={sale.id}>
                            {sale.contract_no}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="col-12 col-sm-6 col-lg-3">
                      <label
                        htmlFor="sales_invoice"
                        className="form-label"
                        style={{ fontSize: "0.9375rem" }}
                      >
                        Sales Invoice No:
                      </label>
                      <select
                        className="form-select"
                        id="sales_invoice"
                        value={filterSaleOrder}
                        onChange={(e) => {
                          setFilterSaleOrder(e.target.value);
                        }}
                      >
                        <option value="">Select Sales Invoice No</option>
                        {saleOrderList.map((order) => (
                          <option value={order.id} key={order.id}>
                            {order.invoice_no}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="col-12">
                      <button
                        type="button"
                        className="btn btn-primary"
                        onClick={filterList}
                      >
                        Filter
                      </button>
                      <button
                        type="button"
                        className="btn btn-label-github ms-5"
                        onClick={resetList}
                      >
                        Reset
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        )}

        <div className="card-datatable table-responsive">
          {loading && <Spinner isFullScreen={false} />}
          <table className="dt-advanced-search table table-nowrap">
            <thead>
              <tr>
                <th>#</th>
                <th
                  className="cursor-pointer"
                  onClick={() => sortTable("logistic")}
                >
                  Logistic
                  <span>
                    <i className="ti ti-arrows-down-up sorting-icon"></i>
                  </span>
                </th>
                <th
                  className="cursor-pointer"
                  onClick={() => sortTable("contract_no")}
                >
                  Sales Contract No
                  <span>
                    <i className="ti ti-arrows-down-up sorting-icon"></i>
                  </span>
                </th>
                <th
                  className="cursor-pointer"
                  onClick={() => sortTable("invoice_no")}
                >
                  Sales Invoice No
                  <span>
                    <i className="ti ti-arrows-down-up sorting-icon"></i>
                  </span>
                </th>
                <th
                  className="cursor-pointer"
                  onClick={() => sortTable("amount")}
                >
                  Amount
                  <span>
                    <i className="ti ti-arrows-down-up sorting-icon"></i>
                  </span>
                </th>
                <th
                  className="cursor-pointer"
                  onClick={() => sortTable("transaction_type")}
                >
                  Transaction Type
                  <span>
                    <i className="ti ti-arrows-down-up sorting-icon"></i>
                  </span>
                </th>
                <th
                  className="cursor-pointer"
                  onClick={() => sortTable("payment_method")}
                >
                  Payment Method
                  <span>
                    <i className="ti ti-arrows-down-up sorting-icon"></i>
                  </span>
                </th>
                <th
                  className="cursor-pointer"
                  onClick={() => sortTable("transaction_no")}
                >
                  Transaction No
                  <span>
                    <i className="ti ti-arrows-down-up sorting-icon"></i>
                  </span>
                </th>
                <th
                  className="cursor-pointer"
                  onClick={() => sortTable("payment_status")}
                >
                  Payment Status
                  <span>
                    <i className="ti ti-arrows-down-up sorting-icon"></i>
                  </span>
                </th>
                <th
                  className="cursor-pointer"
                  onClick={() => sortTable("remark")}
                >
                  Remark
                  <span>
                    <i className="ti ti-arrows-down-up sorting-icon"></i>
                  </span>
                </th>
                <th
                  className="cursor-pointer"
                  onClick={() => sortTable("transaction_date")}
                >
                  Transaction Date
                  <span>
                    <i className="ti ti-arrows-down-up sorting-icon"></i>
                  </span>
                </th>
              </tr>
            </thead>
            <tbody>
              {record.length > 0 ? (
                <>
                  {record.map((item: any, i: number) => {
                    let transactionTypeClass = "";
                    if (item.transaction_type === TransactionTypes.CREDIT) {
                      transactionTypeClass = `bg-label-success`;
                    } else {
                      transactionTypeClass = `bg-label-danger`;
                    }
                    let statusClass = "";
                    if (item.payment_status === PaymentStatus.PENDING) {
                      statusClass = `bg-label-warning`;
                    } else if (
                      item.payment_status === PaymentStatus.COMPLETED
                    ) {
                      statusClass = `bg-label-success`;
                    } else if (item.payment_status === PaymentStatus.ADVANCE) {
                      statusClass = `bg-label-info`;
                    } else {
                      statusClass = `bg-label-secondary`;
                    }
                    return (
                      <tr key={item.id}>
                        <td>{i + 1}</td>
                        <td>{item?.logistic?.name || ""}</td>
                        <td>{item?.sales_contract?.contract_no || ""}</td>
                        <td>{item?.sales_invoice?.invoice_no || ""}</td>
                        <td>
                          {CURRENCY.INR.SYMBOL}
                          {formatAmount(item.amount)}
                        </td>
                        <td>
                          <span
                            className={`badge ${transactionTypeClass} me-1`}
                          >
                            {item.transaction_type}
                          </span>
                        </td>
                        <td>{item?.payment_method.replace("_", " ") || ""}</td>
                        <td>{item?.transaction_no || ""}</td>
                        <td>
                          <td>
                            <span className={`badge ${statusClass} me-1`}>
                              {formatString(item.payment_status)}
                            </span>
                          </td>
                        </td>
                        <td>{item?.remark || ""}</td>
                        <td>{formatDate(item.transaction_date)}</td>
                      </tr>
                    );
                  })}
                </>
              ) : (
                <tr>
                  <td colSpan={11} className="text-center">
                    No records found
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>

        <div className="row mt-5 mx-0 align-items-center">
          <div className="col-sm-4 text-sm-center">
            <div>
              Total Credit:{" "}
              <span
                className="fw-bold"
                style={{ fontWeight: 500, color: "#444050" }}
              >
                {CURRENCY.INR.SYMBOL}
                {formatAmount(totalCreditAmount)}
              </span>
            </div>
          </div>
          <div className="col-sm-4 text-sm-center">
            <div>
              Total Debit:{" "}
              <span
                className="fw-bold"
                style={{ fontWeight: 500, color: "#444050" }}
              >
                {CURRENCY.INR.SYMBOL}
                {formatAmount(totalDebitAmount)}
              </span>
            </div>
          </div>
          <div className="col-sm-4">
            <div className="d-flex justify-content-sm-center">
              <p
                className="p-0 m-0"
                style={{
                  color: totalAmount > 0 ? "green" : "red",
                }}
              >
                {totalAmount > 0 ? "Profit:" : "Loss:"}
              </p>
              &nbsp;
              <span
                className="fw-bold"
                style={{ fontWeight: 500, color: "#444050" }}
              >
                {CURRENCY.INR.SYMBOL}
                {formatAmount(totalAmount)}
              </span>
            </div>
          </div>
        </div>

        <Pagination
          page={page}
          perPage={perPage}
          totalRecords={totalRecords}
          totalPages={totalPages}
          records={record}
          prevButton={prevButton}
          nextButton={nextButton}
        />
      </div>
    </>
  );
};

export default LogisticWisePaymentReport;
