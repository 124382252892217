/* eslint-disable react-hooks/exhaustive-deps */
import moment from "moment";
import React, { useEffect, useState } from "react";
import { getMyBankDetailsApi } from "../../services/_myBank";

interface Props {
  myBankId: string;
}

const ViewMyBankDetails: React.FC<Props> = ({ myBankId }) => {
  const [myBank, setMyBank] = useState<any>({});

  useEffect(() => {
    if (myBankId) {
      setMyBank({});
      getMyBankDetails();
    }
  }, [myBankId]);

  const getMyBankDetails = async () => {
    const result: any = await getMyBankDetailsApi(myBankId as string);
    if (result.isSuccess) {
      setMyBank(result?.data);
    }
  };

  return (
    <div
      className="modal fade"
      id="viewMyBank"
      tabIndex={-1}
      aria-hidden="true"
    >
      <div className="modal-dialog modal-simple modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-body pt-0 pb-0">
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
            <div className="text-center">
              <h4 className="mb-0">My Bank Details</h4>
            </div>
            <div className="info-container">
              <div className="pb-4 border-bottom text-capitalize mb-4"></div>
              <ul className="list-unstyled mb-6">
                <li className="mb-2">
                  <span className="h6 me-1">Bank Name:</span>
                  <span>{myBank?.bank_name}</span>
                </li>
                <li className="mb-2">
                  <span className="h6 me-1">Account Number:</span>
                  <span>{myBank?.account_number}</span>
                </li>
                <li className="mb-2">
                  <span className="h6 me-1">IFSC Code:</span>
                  <span>{myBank?.ifsc_code}</span>
                </li>
                <li className="mb-2">
                  <span className="h6 me-1">Branch Name:</span>
                  <span>{myBank?.branch_name}</span>
                </li>
                <li className="mb-2">
                  <span className="h6 me-1">Swift Code:</span>
                  <span>{myBank?.swift_code}</span>
                </li>
                <li className="mb-2">
                  <span className="h6 me-1">AD Code:</span>
                  <span>{myBank?.ad_code}</span>
                </li>
                <li className="mb-2">
                  <span className="h6 me-1">Address:</span>
                  <span>{myBank?.address}</span>
                </li>
                <li className="mb-2">
                  <span className="h6 me-1">Status:</span>
                  <span
                    className={`badge ${
                      myBank?.status ? "bg-label-success" : "bg-label-danger"
                    }`}
                  >
                    {myBank?.status ? "Active" : "Inactive"}
                  </span>
                </li>
                <li className="mb-2">
                  <span className="h6 me-1">Created At:</span>
                  <span>
                    {myBank?.created_at
                      ? moment(myBank?.created_at).format("YYYY-MM-DD HH:mm:ss")
                      : ""}
                  </span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ViewMyBankDetails;
