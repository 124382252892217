import { Link } from "react-router-dom";

interface ActionFieldsProps {
  submitLoader: any;
}

const ActionFields: React.FC<ActionFieldsProps> = ({ submitLoader }) => {
  return (
    <>
      <div className="col-12 mt-5">
        <button
          type="submit"
          className="btn btn-primary"
          disabled={submitLoader}
        >
          {submitLoader && (
            <span
              className="spinner-border spinner-border-sm me-2"
              role="status"
              aria-hidden="true"
            ></span>
          )}
          Submit
        </button>
        <Link
          to={`/sales-invoice-list`}
          type="button"
          className="btn btn-label-github ms-5"
        >
          Back
        </Link>
      </div>
    </>
  );
};

export default ActionFields;
