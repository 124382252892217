/* eslint-disable react-hooks/exhaustive-deps */
import moment from "moment";
import { useEffect, useState } from "react";
import { getSupplierByIdApi } from "../../services/_supplier";

interface Props {
  supplierId: string;
}

const ViewSupplier: React.FC<Props> = ({ supplierId }) => {
  const [supplier, setSupplier] = useState<any>({});

  useEffect(() => {
    if (supplierId) {
      setSupplier({});
      getSupplierDetails();
    }
  }, [supplierId]);

  const getSupplierDetails = async () => {
    const result: any = await getSupplierByIdApi(supplierId as string);
    if (result.isSuccess) {
      setSupplier(result?.data);
    }
  };

  return (
    <div
      className="modal fade"
      id="viewProduct"
      tabIndex={-1}
      aria-hidden="true"
    >
      <div className="modal-dialog modal-simple modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-body pt-0 pb-0">
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
            <div className="text-center">
              <h4 className="mb-0">Supplier Details</h4>
            </div>
            <div className="info-container">
              <div className="pb-4 border-bottom text-capitalize mb-4"></div>
              <ul className="list-unstyled mb-6">
                <li className="mb-2">
                  <span className="h6 me-1">Name:</span>
                  <span>{supplier?.name}</span>
                </li>
                <li className="mb-2">
                  <span className="h6 me-1">Email:</span>
                  <span>{supplier?.email}</span>
                </li>
                <li className="mb-2">
                  <span className="h6 me-1">Phone No:</span>
                  <span>{supplier?.phone_no}</span>
                </li>
                <li className="mb-2">
                  <span className="h6 me-1">PAN No:</span>
                  <span>{supplier?.pan_no}</span>
                </li>
                <li className="mb-2">
                  <span className="h6 me-1">GST No:</span>
                  <span>{supplier?.gst_no}</span>
                </li>
                <li className="mb-2">
                  <span className="h6 me-1">TAN No:</span>
                  <span>{supplier?.tan_no}</span>
                </li>
                <li className="mb-2">
                  <span className="h6 me-1">UDHYAM No:</span>
                  <span>{supplier?.udhyam_no}</span>
                </li>
                <li className="mb-2">
                  <span className="h6 me-1">Address:</span>
                  <span>{supplier?.address}</span>
                </li>
                <li className="mb-2">
                  <span className="h6 me-1">City:</span>
                  <span>{supplier?.city}</span>
                </li>
                <li className="mb-2">
                  <span className="h6 me-1">State:</span>
                  <span>{supplier?.state}</span>
                </li>
                <li className="mb-2">
                  <span className="h6 me-1">Country:</span>
                  <span>{supplier?.country}</span>
                </li>
                <li className="mb-2">
                  <span className="h6 me-1">Pin Code:</span>
                  <span>{supplier?.pin_code}</span>
                </li>
                <li className="mb-2">
                  <span className="h6 me-1">Status:</span>
                  <span
                    className={`badge ${
                      supplier?.status ? "bg-label-success" : "bg-label-danger"
                    }`}
                  >
                    {supplier?.status ? "Active" : "Inactive"}
                  </span>
                </li>
                <li className="mb-2">
                  <span className="h6 me-1">Created At:</span>
                  <span>
                    {supplier?.created_at
                      ? moment(supplier?.created_at).format(
                          "YYYY-MM-DD HH:mm:ss"
                        )
                      : ""}
                  </span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewSupplier;
