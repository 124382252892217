/* eslint-disable react-hooks/exhaustive-deps */
import moment from "moment";
import { useEffect, useState } from "react";
import { getBrokerByIdApi } from "../../services/_broker";

interface Props {
  brokerId: string;
}

const ViewBroker: React.FC<Props> = ({ brokerId }) => {
  const [broker, setBroker] = useState<any>({});

  useEffect(() => {
    if (brokerId) {
      setBroker({});
      getSupplierDetails();
    }
  }, [brokerId]);

  const getSupplierDetails = async () => {
    const result: any = await getBrokerByIdApi(brokerId as string);
    if (result.isSuccess) {
      setBroker(result?.data);
    }
  };

  return (
    <div
      className="modal fade"
      id="viewProduct"
      tabIndex={-1}
      aria-hidden="true"
    >
      <div className="modal-dialog modal-simple modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-body pt-0 pb-0">
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
            <div className="text-center">
              <h4 className="mb-0">broker Details</h4>
            </div>
            <div className="info-container">
              <div className="pb-4 border-bottom text-capitalize mb-4"></div>
              <ul className="list-unstyled mb-6">
                <li className="mb-2">
                  <span className="h6 me-1">Name:</span>
                  <span>{broker?.name}</span>
                </li>
                <li className="mb-2">
                  <span className="h6 me-1">Email:</span>
                  <span>{broker?.email}</span>
                </li>
                <li className="mb-2">
                  <span className="h6 me-1">Phone No:</span>
                  <span>{broker?.phone_no}</span>
                </li>
                <li className="mb-2">
                  <span className="h6 me-1">PAN No:</span>
                  <span>{broker?.pan_no}</span>
                </li>
                <li className="mb-2">
                  <span className="h6 me-1">GST No:</span>
                  <span>{broker?.gst_no}</span>
                </li>
                <li className="mb-2">
                  <span className="h6 me-1">TAN No:</span>
                  <span>{broker?.tan_no}</span>
                </li>
                <li className="mb-2">
                  <span className="h6 me-1">UDHYAM No:</span>
                  <span>{broker?.udhyam_no}</span>
                </li>
                <li className="mb-2">
                  <span className="h6 me-1">Address:</span>
                  <span>{broker?.address}</span>
                </li>
                <li className="mb-2">
                  <span className="h6 me-1">City:</span>
                  <span>{broker?.city}</span>
                </li>
                <li className="mb-2">
                  <span className="h6 me-1">State:</span>
                  <span>{broker?.state}</span>
                </li>
                <li className="mb-2">
                  <span className="h6 me-1">Country:</span>
                  <span>{broker?.country}</span>
                </li>
                <li className="mb-2">
                  <span className="h6 me-1">Pin Code:</span>
                  <span>{broker?.pin_code}</span>
                </li>
                <li className="mb-2">
                  <span className="h6 me-1">Status:</span>
                  <span
                    className={`badge ${
                      broker?.status ? "bg-label-success" : "bg-label-danger"
                    }`}
                  >
                    {broker?.status ? "Active" : "Inactive"}
                  </span>
                </li>
                <li className="mb-2">
                  <span className="h6 me-1">Created At:</span>
                  <span>
                    {broker?.created_at
                      ? moment(broker?.created_at).format("YYYY-MM-DD HH:mm:ss")
                      : ""}
                  </span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewBroker;
