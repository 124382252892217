/* eslint-disable react-hooks/exhaustive-deps */
import {
  Product,
  initialMiscellaneousSaleItem,
  MiscellaneousSaleInitialValues,
  MiscellaneousSaleValidationSchema,
} from "./helpers/MiscellaneousSalesHelper";
import { DatePicker } from "rsuite";
import ProductRow from "./ProductRow";
import { CURRENCY } from "../../../components/Constants";
import { useCallback, useEffect, useState } from "react";
import { getProductNameListApi } from "../../../services/_product";
import { Link, useNavigate, useParams } from "react-router-dom";
import { errorToast, successToast } from "../../../components/Toast";
import { Formik, Form, Field, ErrorMessage, FieldArray } from "formik";
import "rsuite/DateRangePicker/styles/index.css";
import { getBuyerNameListApi } from "../../../services/_buyer";
import {
  getMiscellaneousSalesByIdApi,
  updateMiscellaneousSalesApi,
} from "../../../services/_sales";
import { getAllUnitsApi } from "../../../services/_unit";
import AddProductModal from "../../../components/modal/AddProduct";
import AddBuyerModal from "../../../components/modal/AddBuyer";
import AddUnitModal from "../../../components/modal/AddUnit";

const EditMiscellaneousSales = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [record, setRecord] = useState(MiscellaneousSaleInitialValues);
  const [productList, setProductList] = useState<Product[]>([]);
  const [buyerOptions, setBuyerOptions] = useState<Product[]>([]);
  const [unitList, setUnitList] = useState<any>([]);
  const [addBuyerModalStatus, setAddBuyerModalStatus] = useState(false);
  const [updateLoading, setUpdateLoading] = useState<boolean>(false);
  const [addProductModalStatus, setAddProductModalStatus] = useState(false);
  const [addUnitModalStatus, setAddUnitModalStatus] = useState(false);

  const getProductNameList = async () => {
    try {
      const result: any = await getProductNameListApi();
      setProductList(result.data);
    } catch (error) {
      console.error("Error fetching product list", error);
    }
  };

  const getBuyerNameList = async () => {
    try {
      const result: any = await getBuyerNameListApi();
      if (result.isSuccess) {
        setBuyerOptions(result.data);
      }
    } catch (error) {
      console.error("Error fetching Buyer Name list", error);
    }
  };

  const getUnitList = async () => {
    const fetchUnits: any = await getAllUnitsApi();
    if (fetchUnits.isSuccess) {
      setUnitList(fetchUnits.data);
    }
  };

  const getMiscellaneousSaleById = async () => {
    try {
      const result: any = await getMiscellaneousSalesByIdApi(id as string);
      if (result.isSuccess) {
        setRecord({
          invoice_no: result.data.invoice_no,
          buyer_id: result.data.buyer_id,
          sale_date: result?.data?.sale_date,
          sub_total: parseFloat(result.data.sub_total),
          cgst: result.data.cgst,
          sgst: result.data.sgst,
          gst_amount: result.data.gst_amount,
          round_of: result.data.round_of ?? 0,
          total: result.data.total,
          sale_items: result.data.miscellaneous_sales_items,
          terms_of_payment: result?.data?.terms_of_payment || "",
        });
      }
    } catch (error) {
      console.error("Error fetching Miscellaneous Sale list", error);
    }
  };

  const handleCalculation = useCallback((values: any, setFieldValue: any) => {
    const finalTotal =
      Number(values?.sub_total) +
      Number(values?.gst_amount) +
      Number(values?.round_of);
    setFieldValue(`total`, finalTotal);
  }, []);

  const handleSubmit = async (values: any, { resetForm }: any) => {
    setUpdateLoading(true);
    if (values?.terms_and_condition) {
      values.terms_and_condition = JSON.stringify(values.terms_and_condition);
    }
    const result: any = await updateMiscellaneousSalesApi(id as string, values);
    if (result.isSuccess) {
      getMiscellaneousSaleById();
      setUpdateLoading(false);
      successToast(result.message);
      navigate(`/miscellaneous-sales-list`);
    } else {
      setUpdateLoading(false);
      errorToast(result.message);
    }
  };
  const closeAddBuyerModal = () => {
    setAddBuyerModalStatus(false);
  };
  const closeAddProductModal = () => {
    setAddProductModalStatus(false);
  };
  const closeAddUnitModal = () => {
    setAddUnitModalStatus(false);
  };
  useEffect(() => {
    if (!id) {
      navigate(`/miscellaneous-sales-list`);
    } else {
      getProductNameList();
      getBuyerNameList();
      getMiscellaneousSaleById();
      getUnitList();
    }
  }, [id]);

  return (
    <>
      <div className="card">
        <div className="card-header pb-0">
          <div>
            <h5>Edit Miscellaneous Sales </h5>
          </div>
        </div>
        <div className="card-body">
          <Formik
            initialValues={record}
            validationSchema={MiscellaneousSaleValidationSchema}
            onSubmit={handleSubmit}
            enableReinitialize={true}
          >
            {({ errors, touched, handleChange, values, setFieldValue }) => {
              return (
                <Form className="dt_adv_search">
                  <div className="row">
                    <div className="col-12">
                      <div className="row g-3">
                        <div className="col-12 col-sm-6 col-lg-4">
                          <label
                            htmlFor="invoice_no"
                            className="form-label"
                            style={{ fontSize: "0.9375rem" }}
                          >
                            Invoice No: <span className="text-danger">*</span>
                          </label>
                          <Field
                            id="invoice_no"
                            name="invoice_no"
                            type="text"
                            className="form-control"
                            placeholder="Enter Invoice No"
                            onChange={handleChange}
                          />
                          <ErrorMessage
                            name="invoice_no"
                            className="text-danger"
                            component="div"
                          />
                        </div>
                        <div className="col-12 col-sm-6 col-lg-4">
                          <div className="d-flex justify-content-between">
                            <div>
                              <label
                                htmlFor="buyer_id"
                                className="form-label"
                                style={{ fontSize: "0.9375rem" }}
                              >
                                Buyer: <span className="text-danger">*</span>
                              </label>
                            </div>
                            <div>
                              <button
                                title="Add Product"
                                className="btn btn-sm btn-primary waves-effect waves-light p-0"
                                type="button"
                                onClick={() => setAddBuyerModalStatus(true)}
                              >
                                <span>
                                  <i className="ti ti-plus my-card-icon-button"></i>
                                </span>
                              </button>
                            </div>
                          </div>
                          <Field
                            as="select"
                            id="buyer_id"
                            name="buyer_id"
                            className="form-control"
                            onChange={handleChange}
                          >
                            <option value="">Enter Buyer Name</option>
                            {buyerOptions.map((buyer) => (
                              <option key={buyer.id} value={buyer.id}>
                                {buyer.name}
                              </option>
                            ))}
                          </Field>
                          <ErrorMessage
                            name="buyer_id"
                            className="text-danger"
                            component="div"
                          />
                        </div>
                        <div className="col-12 col-sm-6 col-lg-4">
                          <label
                            htmlFor="sale_date"
                            className="form-label"
                            style={{ fontSize: "0.9375rem" }}
                          >
                            Sale Date:
                            <span className="text-danger">*</span>
                          </label>
                          <DatePicker
                            id="sale_date"
                            name="sale_date"
                            format="dd-MM-yyyy"
                            className="form-control"
                            placeholder="Select Sale Date"
                            value={
                              values.sale_date
                                ? new Date(values.sale_date)
                                : null
                            }
                            onChange={(value: any) => {
                              setFieldValue("sale_date", value);
                            }}
                          />
                          <ErrorMessage
                            name="sale_date"
                            className="text-danger"
                            component="div"
                          />
                        </div>
                        <div>
                          <label
                            htmlFor="products"
                            className="form-label"
                            style={{ fontSize: "0.9375rem" }}
                          >
                            Add Products: <span className="text-danger">*</span>
                          </label>

                          <div>
                            <FieldArray name="sale_items">
                              {({ remove }) => (
                                <>
                                  {values.sale_items.map(
                                    (item: any, index: number) => (
                                      <ProductRow
                                        key={index}
                                        index={index}
                                        item={item}
                                        productList={productList}
                                        unitList={unitList}
                                        values={values}
                                        setFieldValue={setFieldValue}
                                        remove={remove}
                                        handleChange={handleChange}
                                        setAddProductModalStatus={
                                          setAddProductModalStatus
                                        }
                                        setAddUnitModalStatus={
                                          setAddUnitModalStatus
                                        }
                                      />
                                    )
                                  )}

                                  <div className="row mb-1 g-1 px-3">
                                    <button
                                      type="button"
                                      className="btn btn-primary col-lg-3"
                                      onClick={() => {
                                        const newItems = [
                                          ...values.sale_items,
                                          initialMiscellaneousSaleItem,
                                        ];
                                        setFieldValue("sale_items", newItems);
                                      }}
                                    >
                                      Add Product
                                    </button>
                                  </div>
                                </>
                              )}
                            </FieldArray>

                            {errors.sale_items &&
                              touched.sale_items &&
                              typeof errors.sale_items === "string" && (
                                <div className="text-danger">
                                  {errors.sale_items}
                                </div>
                              )}
                          </div>
                        </div>
                        <div className="col-12 col-sm-6 col-lg-3 mt-4">
                          <label
                            htmlFor="sub_total"
                            className="form-label"
                            style={{ fontSize: "0.9375rem" }}
                          >
                            Sub Total ({CURRENCY.INR.SYMBOL}):
                          </label>
                          <Field
                            name="sub_total"
                            type="number"
                            className="form-control"
                            disabled
                            value={values.sub_total}
                          />
                          <ErrorMessage
                            name="sub_total"
                            component="div"
                            className="text-danger"
                          />
                        </div>

                        <div className="col-12 col-sm-6 col-lg-3">
                          <label
                            htmlFor="cgst"
                            className="form-label"
                            style={{ fontSize: "0.9375rem" }}
                          >
                            CGST ({CURRENCY.INR.SYMBOL}):
                          </label>
                          <Field
                            name="cgst"
                            type="number"
                            className="form-control"
                            value={values.cgst}
                            disabled
                          />
                          <ErrorMessage
                            name="cgst"
                            component="div"
                            className="text-danger"
                          />
                        </div>

                        <div className="col-12 col-sm-6 col-lg-3">
                          <label
                            htmlFor="sgst"
                            className="form-label"
                            style={{ fontSize: "0.9375rem" }}
                          >
                            SGST ({CURRENCY.INR.SYMBOL}):
                          </label>
                          <Field
                            name="sgst"
                            type="number"
                            className="form-control"
                            value={values.sgst}
                            disabled
                          />
                          <ErrorMessage
                            name="sgst"
                            component="div"
                            className="text-danger"
                          />
                        </div>

                        <div className="col-12 col-sm-6 col-lg-3">
                          <label
                            htmlFor="gst_amount"
                            className="form-label"
                            style={{ fontSize: "0.9375rem" }}
                          >
                            GST Amount ({CURRENCY.INR.SYMBOL}):
                          </label>
                          <Field
                            name="gst_amount"
                            type="number"
                            className="form-control"
                            value={values.gst_amount}
                            disabled
                          />
                          <ErrorMessage
                            name="gst_amount"
                            component="div"
                            className="text-danger"
                          />
                        </div>

                        <div className="col-12 col-sm-6 col-lg-3">
                          <label
                            htmlFor="round_of"
                            className="form-label"
                            style={{ fontSize: "0.9375rem" }}
                          >
                            Round Of ({CURRENCY.INR.SYMBOL}):
                          </label>
                          <Field
                            name="round_of"
                            type="number"
                            className="form-control"
                            step="0.1"
                            value={values.round_of}
                            onInput={(e: any) => {
                              values.round_of = parseFloat(e.target.value) || 0;
                              handleCalculation(values, setFieldValue);
                            }}
                          />
                        </div>

                        <div className="col-12 col-sm-6 col-lg-3">
                          <label
                            htmlFor="total"
                            className="form-label"
                            style={{ fontSize: "0.9375rem" }}
                          >
                            Total Amount ({CURRENCY.INR.SYMBOL}):
                            <span className="text-danger">*</span>
                          </label>
                          <Field
                            name="total"
                            type="number"
                            className="form-control"
                            value={values.total}
                            disabled
                          />
                          <ErrorMessage
                            name="total"
                            component="div"
                            className="text-danger"
                          />
                        </div>
                        <div className="col-12 col-sm-6 col-lg-3">
                          <label
                            htmlFor="terms_of_payment"
                            className="form-label"
                            style={{ fontSize: "0.9375rem" }}
                          >
                            Terms of Payment:
                          </label>
                          <Field
                            id="terms_of_payment"
                            name="terms_of_payment"
                            type="text"
                            className="form-control"
                            placeholder="Enter Terms of Payment"
                            onChange={handleChange}
                          />
                        </div>

                        <div className="col-12">
                          <button
                            type="submit"
                            className="btn btn-primary"
                            disabled={updateLoading}
                          >
                            {updateLoading && (
                              <span
                                className="spinner-border spinner-border-sm me-2"
                                role="status"
                                aria-hidden="true"
                              ></span>
                            )}
                            Submit
                          </button>
                          <Link
                            to={"/miscellaneous-sales-list"}
                            type="button"
                            className="btn btn-label-github ms-5"
                          >
                            Back
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </Form>
              );
            }}
          </Formik>
        </div>
      </div>
      <AddBuyerModal
        addBuyerModal={addBuyerModalStatus}
        closeAddBuyerModal={closeAddBuyerModal}
        onBuyerCreated={getBuyerNameList}
      />
      <AddProductModal
        addProductModalStatus={addProductModalStatus}
        closeAddProductModal={closeAddProductModal}
        onProductCreated={getProductNameList}
      />
      <AddUnitModal
        addUnitModalStatus={addUnitModalStatus}
        closeAddUnitModal={closeAddUnitModal}
        onUnitCreated={getUnitList}
      />
    </>
  );
};

export default EditMiscellaneousSales;
