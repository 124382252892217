import { GET, POST, PUT } from "./_config";

export const logisticListApi = async (query: string) =>
  await GET(`/logistic?${query || ""}`, true);

export const getAllLogisticApi = async () => await GET(`/logistic/list`, true);

export const createLogisticApi = async (body: any) =>
  await POST(`/logistic`, body, true);

export const getLogisticByIdApi = async (logisticId: string) =>
  await GET(`/logistic/id/${logisticId}`, true);

export const updateLogisticApi = async (logisticId: string, body: any) =>
  await PUT(`/logistic?id=${logisticId}`, body, true);

export const updateLogisticStatusApi = async (body: any) =>
  await POST(`/logistic/update-status`, body, true);

const LogisticService = {
  logisticListApi,
  getAllLogisticApi,
  createLogisticApi,
  getLogisticByIdApi,
  updateLogisticApi,
  updateLogisticStatusApi,
};

export default LogisticService;
