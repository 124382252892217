import {
  Document,
  Image,
  Page,
  PDFViewer,
  StyleSheet,
  Text,
  View,
} from "@react-pdf/renderer";

export const styles = StyleSheet.create({
  page: {
    paddingHorizontal: 30,
    paddingVertical: 15,
    fontFamily: "HostGrotesk",
    backgroundColor: "white",
  },
  fullpage: {
    margin: 7,
    borderStyle: "solid",
    borderWidth: 1,
    borderColor: "#000000",
  },

  sideBySideContainer: { flexDirection: "row" },
  halfWidthRightSection: {
    width: "50%",
    borderBottomWidth: 1,
    borderbottomStyle: "solid",
    borderRightWidth: 1,
    textAlign: "left",
  },
  halfWidthLeftSection: {
    width: "50%",
    borderBottomWidth: 1,
    borderbottomStyle: "solid",
  },
  tableContainer: {},
  tableRow: {
    flexDirection: "row",
    flexGrow: 1,
    paddingLeft: 5,
    paddingRight: 5,
    textAlign: "center",
  },
  tableContent: {
    paddingTop: 1,
    borderRightWidth: 1,
    textAlign: "center",
    justifyContent: "space-between",
  },
  contentPadding: {
    paddingLeft: 5,
    marginRight: 4,
    paddingTop: -1,
  },
  row: {
    flexDirection: "row",
  },
  squareBullet: {
    width: 5,
    height: 5,
    backgroundColor: "black",
    marginLeft: 10,
    marginTop: 5,
  },
  container: {
    paddingTop: 10,
    width: "100%",
    paddingLeft: 5,
  },
  boldText: {
    fontWeight: "bold",
    fontSize: 9,
  },
  smallFontSize: {
    fontSize: 6,
  },
  mediumFontSize: {
    fontSize: 8,
  },
  regularFontSize: {
    fontSize: 9,
  },
  largeFontSize: {
    fontSize: 10,
  },
  subContent: {
    paddingTop: 5,
    paddingLeft: 25,
    paddingRight: 25,
  },
  checkbox: {
    width: 10,
    height: 10,
    borderWidth: 1,
    borderColor: "black",
    marginRight: 10,
  },
  checkMark: {
    fontSize: 18,
    color: "black",
  },
  header: {
    position: "absolute",
    top: -70,
    left: 0,
    right: 0,
    height: 200,
    alignItems: "center",
    fontSize: 12,
    padding: 5,
  },
  footer: {
    position: "absolute",
    bottom: 20,
    left: 0,
    right: 0,
    height: 80,
    alignItems: "center",
    fontSize: 10,
    padding: 5,
  },
  footerText: {
    textAlign: "center",
  },
  footerImage: {
    width: "80%",
    height: "80%",
  },
  headerImage: {
    width: "90%",
    height: "100%",
  },
});

const VgmCertificate = () => {
  return (
    <PDFViewer width="100%" height="800px">
      <Document>
        <Page style={[styles.page]} size="A4">
          <View style={styles.header}>
            <Image
              src="/img/Invoice/header.png"
              style={[styles.headerImage]}
            />
          </View>
          <View
            style={[styles.sideBySideContainer, { justifyContent: "center" }]}
          >
            <Text style={[styles.boldText, { top: 65 }]}>Annexure - I</Text>
          </View>
          <View
            style={[styles.sideBySideContainer, { justifyContent: "center" }]}
          >
            <Text
              style={[
                styles.boldText,
                { textDecoration: "underline", top: 70 },
              ]}
            >
              INFORMATION ABOUT VERIFIED GROSS MASS OF CONTAINER{" "}
            </Text>
          </View>

          {/* table */}

          {/* table */}
          <View style={{ marginLeft: 10, marginRight: 30, marginTop: 5 }}>
            {/* table header */}
            <View
              style={[
                styles.tableContainer,
                {
                  textAlign: "left",
                  height: "auto",
                  borderBottom: 1,
                  borderTop: 1,
                  marginTop: -5,
                  marginLeft: 30,
                  marginRight: 5,
                  borderRight: 1,
                  borderLeft: 1,
                  top: 70,
                },
              ]}
            >
              <View
                style={[
                  styles.tableRow,
                  {
                    textAlign: "left",
                    paddingLeft: 5,
                    paddingRight: 5,
                    flexDirection: "row",
                  },
                ]}
              >
                <Text
                  style={[
                    {
                      paddingTop: -1,
                      borderRightWidth: 1,
                      width: "10%",
                      justifyContent: "space-between",
                    },
                  ]}
                ></Text>
                <Text
                  style={[
                    styles.largeFontSize,
                    {
                      paddingTop: -1,
                      borderRightWidth: 1,
                      width: "90%",
                      justifyContent: "space-between",
                      paddingLeft: 5,
                      paddingBottom: 2,
                    },
                  ]}
                >
                  Details of Information
                </Text>
                <Text
                  style={[
                    styles.largeFontSize,
                    {
                      textAlign: "left",
                      paddingRight: 5,
                      paddingTop: -1,
                      paddingLeft: 5,
                      width: "85%",
                      justifyContent: "space-between",
                    },
                  ]}
                >
                  Particulars
                </Text>
              </View>
            </View>
            {/* data 1 */}
            <View
              style={[
                styles.tableContainer,
                styles.regularFontSize,
                {
                  textAlign: "left",
                  height: "auto",
                  borderBottom: 1,
                  marginLeft: 30,
                  marginRight: 5,
                  borderRight: 1,
                  borderLeft: 1,
                  top: 70,
                },
              ]}
            >
              <View
                style={[
                  styles.tableRow,
                  {
                    paddingLeft: 5,
                    paddingRight: 5,
                    flexDirection: "row",
                  },
                ]}
              >
                <Text
                  style={[
                    {
                      paddingTop: -1,
                      borderRightWidth: 1,
                      width: "10%",
                      justifyContent: "space-between",
                    },
                  ]}
                >
                  1*
                </Text>
                <Text
                  style={[
                    {
                      textAlign: "left",
                      paddingTop: -1,
                      borderRightWidth: 1,
                      width: "90%",
                      justifyContent: "space-between",
                      paddingBottom: 2,
                      paddingLeft: 5,
                    },
                  ]}
                >
                  Booking No.
                </Text>
                <Text
                  style={[
                    styles.boldText,
                    {
                      paddingRight: 5,
                      paddingTop: -1,
                      paddingLeft: 5,
                      width: "85%",
                      justifyContent: "space-between",
                      paddingBottom: 2,
                      textAlign: "left",
                    },
                  ]}
                >
                  SHMD250008OL
                </Text>
              </View>
            </View>
            {/* data 2 */}
            <View
              style={[
                styles.tableContainer,
                styles.regularFontSize,
                {
                  textAlign: "left",
                  height: "auto",
                  borderBottom: 1,
                  marginLeft: 30,
                  marginRight: 5,
                  borderRight: 1,
                  borderLeft: 1,
                  top: 70,
                },
              ]}
            >
              <View
                style={[
                  styles.tableRow,
                  {
                    paddingLeft: 5,
                    paddingRight: 5,
                    flexDirection: "row",
                  },
                ]}
              >
                <Text
                  style={[
                    {
                      paddingTop: -1,
                      borderRightWidth: 1,
                      width: "10%",
                      justifyContent: "space-between",
                    },
                  ]}
                >
                  2*
                </Text>
                <Text
                  style={[
                    {
                      textAlign: "left",
                      paddingTop: -1,
                      borderRightWidth: 1,
                      width: "90%",
                      justifyContent: "space-between",
                      paddingBottom: 2,
                      paddingLeft: 5,
                    },
                  ]}
                >
                  Name of the shipper
                </Text>
                <Text
                  style={[
                    styles.boldText,
                    {
                      paddingRight: 5,
                      paddingTop: -1,
                      paddingLeft: 5,
                      width: "85%",
                      justifyContent: "space-between",
                      paddingBottom: 2,
                      textAlign: "left",
                    },
                  ]}
                >
                  MS LAXMI ENTERPRISE
                </Text>
              </View>
            </View>
            {/* data 3 */}
            <View
              style={[
                styles.tableContainer,
                styles.regularFontSize,
                {
                  textAlign: "left",
                  height: "auto",
                  borderBottom: 1,
                  marginLeft: 30,
                  marginRight: 5,
                  borderRight: 1,
                  borderLeft: 1,
                  top: 70,
                },
              ]}
            >
              <View
                style={[
                  styles.tableRow,
                  {
                    paddingLeft: 5,
                    paddingRight: 5,
                    flexDirection: "row",
                  },
                ]}
              >
                <Text
                  style={[
                    {
                      paddingTop: -1,
                      borderRightWidth: 1,
                      width: "10%",
                      justifyContent: "space-between",
                    },
                  ]}
                >
                  3*
                </Text>
                <Text
                  style={[
                    {
                      textAlign: "left",
                      paddingTop: -1,
                      borderRightWidth: 1,
                      width: "90%",
                      justifyContent: "space-between",
                      paddingBottom: 2,
                      paddingLeft: 5,
                    },
                  ]}
                >
                  Shipper Registration/License No. (IEC No/CIN No)
                </Text>
                <Text
                  style={[
                    styles.boldText,
                    {
                      paddingRight: 5,
                      paddingTop: -1,
                      paddingLeft: 5,
                      width: "85%",
                      justifyContent: "space-between",
                      paddingBottom: 2,
                      textAlign: "left",
                    },
                  ]}
                >
                  EC No. 3712001126
                </Text>
              </View>
            </View>
            {/* data 4 */}
            <View
              style={[
                styles.tableContainer,
                styles.regularFontSize,
                {
                  textAlign: "left",
                  height: "auto",
                  borderBottom: 1,
                  marginLeft: 30,
                  marginRight: 5,
                  borderRight: 1,
                  borderLeft: 1,
                  top: 70,
                },
              ]}
            >
              <View
                style={[
                  styles.tableRow,
                  {
                    paddingLeft: 5,
                    paddingRight: 5,
                    flexDirection: "row",
                  },
                ]}
              >
                <Text
                  style={[
                    {
                      paddingTop: -1,
                      borderRightWidth: 1,
                      width: "10%",
                      justifyContent: "space-between",
                    },
                  ]}
                >
                  4*
                </Text>
                <Text
                  style={[
                    {
                      textAlign: "left",
                      paddingTop: -1,
                      borderRightWidth: 1,
                      width: "90%",
                      justifyContent: "space-between",
                      paddingBottom: 4,
                      paddingLeft: 5,
                    },
                  ]}
                >
                  Name and designation of official of the shipper authorised to
                  sign document
                </Text>
                <Text
                  style={[
                    styles.boldText,
                    {
                      paddingRight: 5,
                      paddingTop: -1,
                      paddingLeft: 5,
                      paddingBottom: 2,
                      width: "85%",
                      justifyContent: "space-between",
                      textAlign: "left",
                    },
                  ]}
                >
                  GOPAL VANPARIYA (PARTNER)
                </Text>
              </View>
            </View>
            {/* data 5 */}
            <View
              style={[
                styles.tableContainer,
                styles.regularFontSize,
                {
                  textAlign: "left",
                  height: "auto",
                  borderBottom: 1,
                  marginLeft: 30,
                  marginRight: 5,
                  borderRight: 1,
                  borderLeft: 1,
                  top: 70,
                },
              ]}
            >
              <View
                style={[
                  styles.tableRow,
                  {
                    paddingLeft: 5,
                    paddingRight: 5,
                    flexDirection: "row",
                  },
                ]}
              >
                <Text
                  style={[
                    {
                      paddingTop: -1,
                      borderRightWidth: 1,
                      width: "10%",
                      justifyContent: "space-between",
                    },
                  ]}
                >
                  5*
                </Text>
                <Text
                  style={[
                    {
                      textAlign: "left",
                      paddingTop: -1,
                      borderRightWidth: 1,
                      width: "90%",
                      justifyContent: "space-between",
                      paddingBottom: 2,
                      paddingLeft: 5,
                    },
                  ]}
                >
                  24 x 7 contact details of authorised official of shipper
                </Text>
                <Text
                  style={[
                    styles.boldText,
                    {
                      paddingRight: 5,
                      paddingBottom: 2,
                      paddingTop: -1,
                      paddingLeft: 5,
                      width: "85%",
                      justifyContent: "space-between",
                      textAlign: "left",
                    },
                  ]}
                >
                  +91 - 84600 11801
                </Text>
              </View>
            </View>

            {/* data 6 */}
            <View
              style={[
                styles.tableContainer,
                styles.regularFontSize,
                {
                  textAlign: "left",
                  height: "auto",
                  borderBottom: 1,
                  marginLeft: 30,
                  marginRight: 5,
                  borderRight: 1,
                  borderLeft: 1,
                  top: 70,
                },
              ]}
            >
              <View
                style={[
                  styles.tableRow,
                  {
                    paddingLeft: 5,
                    paddingRight: 5,
                    flexDirection: "row",
                  },
                ]}
              >
                <Text
                  style={[
                    {
                      paddingTop: -1,
                      borderRightWidth: 1,
                      width: "10%",
                      justifyContent: "space-between",
                    },
                  ]}
                >
                  6*
                </Text>
                <Text
                  style={[
                    {
                      textAlign: "left",
                      paddingTop: -1,
                      borderRightWidth: 1,
                      width: "90%",
                      paddingBottom: 2,
                      justifyContent: "space-between",
                      paddingLeft: 5,
                    },
                  ]}
                >
                  Container No
                </Text>
                <Text
                  style={[
                    styles.boldText,
                    {
                      textAlign: "left",
                      paddingRight: 5,
                      paddingTop: -1,
                      paddingLeft: 5,
                      width: "85%",
                      paddingBottom: 2,
                      justifyContent: "space-between",
                    },
                  ]}
                >
                  AS PER BELOW
                </Text>
              </View>
            </View>

            {/* data 7 */}
            <View
              style={[
                styles.tableContainer,
                styles.regularFontSize,
                {
                  textAlign: "left",
                  height: "auto",
                  borderBottom: 1,
                  marginLeft: 30,
                  marginRight: 5,
                  borderRight: 1,
                  borderLeft: 1,
                  top: 70,
                },
              ]}
            >
              <View
                style={[
                  styles.tableRow,
                  {
                    paddingLeft: 5,
                    paddingRight: 5,
                    flexDirection: "row",
                  },
                ]}
              >
                <Text
                  style={[
                    {
                      paddingTop: -1,
                      borderRightWidth: 1,
                      width: "10%",
                      justifyContent: "space-between",
                    },
                  ]}
                >
                  7*
                </Text>
                <Text
                  style={[
                    {
                      textAlign: "left",
                      paddingTop: -1,
                      borderRightWidth: 1,
                      width: "90%",
                      justifyContent: "space-between",
                      paddingLeft: 5,
                      paddingBottom: 2,
                    },
                  ]}
                >
                  Container Size (TEU/FFU/other)
                </Text>
                <Text
                  style={[
                    styles.boldText,
                    {
                      textAlign: "left",
                      paddingRight: 5,
                      paddingTop: -1,
                      paddingLeft: 5,
                      width: "85%",
                      paddingBottom: 2,
                      justifyContent: "space-between",
                    },
                  ]}
                >
                  AS PER BELOW
                </Text>
              </View>
            </View>

            {/* data 8 */}
            <View
              style={[
                styles.tableContainer,
                styles.regularFontSize,
                {
                  textAlign: "left",
                  height: "auto",
                  borderBottom: 1,
                  marginLeft: 30,
                  marginRight: 5,
                  borderRight: 1,
                  borderLeft: 1,
                  top: 70,
                },
              ]}
            >
              <View
                style={[
                  styles.tableRow,
                  {
                    paddingLeft: 5,
                    paddingRight: 5,
                    flexDirection: "row",
                  },
                ]}
              >
                <Text
                  style={[
                    {
                      paddingTop: -1,
                      borderRightWidth: 1,
                      width: "10%",
                      justifyContent: "space-between",
                    },
                  ]}
                >
                  8*
                </Text>
                <Text
                  style={[
                    {
                      textAlign: "left",
                      paddingTop: -1,
                      borderRightWidth: 1,
                      width: "90%",
                      justifyContent: "space-between",
                      paddingLeft: 5,
                      paddingRight: 5,
                      paddingBottom: 2,
                    },
                  ]}
                >
                  Maximum permissible weight of container as per the CSC plate
                </Text>
                <Text
                  style={[
                    styles.boldText,
                    {
                      textAlign: "left",
                      paddingRight: 5,
                      paddingTop: -1,
                      paddingLeft: 5,
                      width: "85%",
                      justifyContent: "space-between",
                    },
                  ]}
                >
                  AS PER BELOW
                </Text>
              </View>
            </View>

            {/* data 9  */}
            <View
              style={[
                styles.tableContainer,
                styles.regularFontSize,
                {
                  textAlign: "left",
                  height: "auto",
                  borderBottom: 1,
                  marginLeft: 30,
                  marginRight: 5,
                  borderRight: 1,
                  borderLeft: 1,
                  top: 70,
                },
              ]}
            >
              <View
                style={[
                  styles.tableRow,
                  {
                    paddingLeft: 5,
                    paddingRight: 5,
                    flexDirection: "row",
                  },
                ]}
              >
                <Text
                  style={[
                    {
                      paddingTop: -1,
                      borderRightWidth: 1,
                      width: "10%",
                      justifyContent: "space-between",
                    },
                  ]}
                >
                  9*
                </Text>
                <Text
                  style={[
                    {
                      textAlign: "left",
                      paddingTop: -1,
                      borderRightWidth: 1,
                      width: "90%",
                      justifyContent: "space-between",
                      paddingLeft: 5,
                      paddingRight: 5,
                    },
                  ]}
                >
                  Weighbridge registration no. & address of weighbridge
                </Text>
                <Text
                  style={[
                    styles.boldText,
                    {
                      textAlign: "left",
                      paddingRight: 5,
                      paddingTop: -1,
                      paddingLeft: 5,
                      width: "85%",
                      justifyContent: "space-between",
                      paddingBottom: 2,
                    },
                  ]}
                >
                  REG. NO. APMC BHUJ {"\n"} DHARMADA WEIGH BRIDGE, {"\n"} BHUJ,
                  KACHCHH, GUJARAT
                </Text>
              </View>
            </View>

            {/* data 10 */}
            <View
              style={[
                styles.tableContainer,
                styles.regularFontSize,
                {
                  textAlign: "left",
                  height: "auto",
                  borderBottom: 1,
                  marginLeft: 30,
                  marginRight: 5,
                  borderRight: 1,
                  borderLeft: 1,
                  top: 70,
                },
              ]}
            >
              <View
                style={[
                  styles.tableRow,
                  {
                    paddingLeft: 5,
                    paddingRight: 5,
                    flexDirection: "row",
                  },
                ]}
              >
                <Text
                  style={[
                    {
                      paddingTop: -1,
                      borderRightWidth: 1,
                      width: "10%",
                      justifyContent: "space-between",
                    },
                  ]}
                >
                  10*
                </Text>
                <Text
                  style={[
                    {
                      textAlign: "left",
                      paddingTop: -1,
                      borderRightWidth: 1,
                      width: "90%",
                      justifyContent: "space-between",
                      paddingLeft: 5,
                      paddingBottom: 2,
                      paddingRight: 5,
                    },
                  ]}
                >
                  Weighing Method (Method-1/Method-2)
                </Text>
                <Text
                  style={[
                    styles.boldText,
                    {
                      textAlign: "left",
                      paddingBottom: 2,
                      paddingRight: 5,
                      paddingTop: -1,
                      paddingLeft: 5,
                      width: "85%",
                      justifyContent: "space-between",
                    },
                  ]}
                >
                  SM-1
                </Text>
              </View>
            </View>

            {/* data 11 */}
            <View
              style={[
                styles.tableContainer,
                styles.regularFontSize,
                {
                  textAlign: "left",
                  height: "auto",
                  borderBottom: 1,
                  marginLeft: 30,
                  marginRight: 5,
                  borderRight: 1,
                  borderLeft: 1,
                  top: 70,
                },
              ]}
            >
              <View
                style={[
                  styles.tableRow,
                  {
                    paddingLeft: 5,
                    paddingRight: 5,
                    flexDirection: "row",
                  },
                ]}
              >
                <Text
                  style={[
                    {
                      paddingTop: -1,
                      borderRightWidth: 1,
                      width: "10%",
                      justifyContent: "space-between",
                    },
                  ]}
                >
                  11*
                </Text>
                <Text
                  style={[
                    {
                      textAlign: "left",
                      paddingTop: -1,
                      borderRightWidth: 1,
                      width: "90%",
                      justifyContent: "space-between",
                      paddingLeft: 5,
                      paddingBottom: 2,
                      paddingRight: 5,
                    },
                  ]}
                >
                  Verified Gross Mass of the Container
                </Text>
                <Text
                  style={[
                    styles.boldText,
                    {
                      textAlign: "left",
                      paddingRight: 5,
                      paddingTop: -1,
                      paddingLeft: 5,
                      paddingBottom: 2,
                      width: "85%",
                      justifyContent: "space-between",
                    },
                  ]}
                >
                  AS PER BELOW
                </Text>
              </View>
            </View>

            {/* data 12 */}
            <View
              style={[
                styles.tableContainer,
                styles.regularFontSize,
                {
                  textAlign: "left",
                  height: "auto",
                  borderBottom: 1,
                  marginLeft: 30,
                  marginRight: 5,
                  borderRight: 1,
                  borderLeft: 1,
                  top: 70,
                },
              ]}
            >
              <View
                style={[
                  styles.tableRow,
                  {
                    paddingLeft: 5,
                    paddingRight: 5,
                    flexDirection: "row",
                  },
                ]}
              >
                <Text
                  style={[
                    {
                      paddingTop: -1,
                      borderRightWidth: 1,
                      width: "10%",
                      justifyContent: "space-between",
                    },
                  ]}
                >
                  12*
                </Text>
                <Text
                  style={[
                    {
                      textAlign: "left",
                      paddingTop: -1,
                      paddingBottom: 2,
                      borderRightWidth: 1,
                      width: "90%",
                      justifyContent: "space-between",
                      paddingLeft: 5,
                      paddingRight: 5,
                    },
                  ]}
                >
                  Unit of Measure (KG/MT/LBS)
                </Text>
                <Text
                  style={[
                    styles.boldText,
                    {
                      textAlign: "left",
                      paddingBottom: 2,
                      paddingRight: 5,
                      paddingTop: -1,
                      paddingLeft: 5,
                      width: "85%",
                      justifyContent: "space-between",
                    },
                  ]}
                >
                  KG
                </Text>
              </View>
            </View>

            {/* data 13 */}
            <View
              style={[
                styles.tableContainer,
                styles.regularFontSize,
                {
                  textAlign: "left",
                  height: "auto",
                  borderBottom: 1,
                  marginLeft: 30,
                  marginRight: 5,
                  borderRight: 1,
                  borderLeft: 1,
                  top: 70,
                },
              ]}
            >
              <View
                style={[
                  styles.tableRow,
                  {
                    paddingLeft: 5,
                    paddingRight: 5,
                    flexDirection: "row",
                  },
                ]}
              >
                <Text
                  style={[
                    {
                      paddingTop: -1,
                      borderRightWidth: 1,
                      width: "10%",
                      justifyContent: "space-between",
                    },
                  ]}
                >
                  13*
                </Text>
                <Text
                  style={[
                    {
                      textAlign: "left",
                      paddingTop: -1,
                      borderRightWidth: 1,
                      width: "90%",
                      justifyContent: "space-between",
                      paddingLeft: 5,
                      paddingBottom: 2,
                      paddingRight: 5,
                    },
                  ]}
                >
                  Date and time of weighing
                </Text>
                <Text
                  style={[
                    styles.boldText,
                    {
                      textAlign: "left",
                      paddingRight: 5,
                      paddingTop: -1,
                      paddingBottom: 2,
                      paddingLeft: 5,
                      width: "85%",
                      justifyContent: "space-between",
                    },
                  ]}
                >
                  AS PER BELOW
                </Text>
              </View>
            </View>

            {/* data 14 */}
            <View
              style={[
                styles.tableContainer,
                styles.regularFontSize,
                {
                  textAlign: "left",
                  height: "auto",
                  borderBottom: 1,
                  marginLeft: 30,
                  marginRight: 5,
                  borderRight: 1,
                  borderLeft: 1,
                  top: 70,
                },
              ]}
            >
              <View
                style={[
                  styles.tableRow,
                  {
                    paddingLeft: 5,
                    paddingRight: 5,
                    flexDirection: "row",
                  },
                ]}
              >
                <Text
                  style={[
                    {
                      paddingTop: -1,
                      borderRightWidth: 1,
                      width: "10%",
                      justifyContent: "space-between",
                    },
                  ]}
                >
                  14*
                </Text>
                <Text
                  style={[
                    {
                      textAlign: "left",
                      paddingTop: -1,
                      borderRightWidth: 1,
                      paddingBottom: 2,
                      width: "90%",
                      justifyContent: "space-between",
                      paddingLeft: 5,
                      paddingRight: 5,
                    },
                  ]}
                >
                  Weighing Slip No
                </Text>
                <Text
                  style={[
                    styles.boldText,
                    {
                      textAlign: "left",
                      paddingRight: 5,
                      paddingBottom: 2,
                      paddingTop: -1,
                      paddingLeft: 5,
                      width: "85%",
                      justifyContent: "space-between",
                    },
                  ]}
                >
                  AS PER BELOW
                </Text>
              </View>
            </View>

            {/* data 15 */}
            <View
              style={[
                styles.tableContainer,
                styles.regularFontSize,
                {
                  textAlign: "left",
                  height: "auto",
                  borderBottom: 1,
                  marginLeft: 30,
                  marginRight: 5,
                  borderRight: 1,
                  borderLeft: 1,
                  top: 70,
                },
              ]}
            >
              <View
                style={[
                  styles.tableRow,
                  {
                    paddingLeft: 5,
                    paddingRight: 5,
                    flexDirection: "row",
                  },
                ]}
              >
                <Text
                  style={[
                    {
                      paddingTop: -1,
                      borderRightWidth: 1,
                      width: "10%",
                      justifyContent: "space-between",
                    },
                  ]}
                >
                  15*
                </Text>
                <Text
                  style={[
                    {
                      textAlign: "left",
                      paddingBottom: 2,
                      paddingTop: -1,
                      borderRightWidth: 1,
                      width: "90%",
                      justifyContent: "space-between",
                      paddingLeft: 5,
                      paddingRight: 5,
                    },
                  ]}
                >
                  Type (Normal/Reefer/Hazardous others)
                </Text>
                <Text
                  style={[
                    styles.boldText,
                    {
                      textAlign: "left",
                      paddingRight: 5,
                      paddingBottom: 2,
                      paddingTop: -1,
                      paddingLeft: 5,
                      width: "85%",
                      justifyContent: "space-between",
                    },
                  ]}
                >
                  NORMAL
                </Text>
              </View>
            </View>

            {/* data 16 */}
            <View
              style={[
                styles.tableContainer,
                styles.regularFontSize,
                {
                  textAlign: "left",
                  height: "auto",
                  borderBottom: 1,
                  marginLeft: 30,
                  marginRight: 5,
                  borderRight: 1,
                  borderLeft: 1,
                  top: 70,
                },
              ]}
            >
              <View
                style={[
                  styles.tableRow,
                  {
                    paddingLeft: 5,
                    paddingRight: 5,
                    flexDirection: "row",
                  },
                ]}
              >
                <Text
                  style={[
                    {
                      paddingTop: -1,
                      borderRightWidth: 1,
                      width: "10%",
                      justifyContent: "space-between",
                    },
                  ]}
                >
                  16*
                </Text>
                <Text
                  style={[
                    {
                      textAlign: "left",
                      borderRightWidth: 1,
                      width: "90%",
                      justifyContent: "space-between",
                      paddingLeft: 5,
                      paddingRight: 5,
                      paddingBottom: 2,
                    },
                  ]}
                >
                  If Hazardous, UN No, IMDG class
                </Text>
                <Text
                  style={[
                    styles.boldText,
                    {
                      textAlign: "left",
                      paddingRight: 5,
                      paddingBottom: 2,
                      paddingLeft: 5,
                      width: "85%",
                      justifyContent: "space-between",
                    },
                  ]}
                >
                  NON-HAZARDOUS
                </Text>
              </View>
            </View>

            {/* table-2 */}
          </View>

          <View
            style={[
              styles.tableContainer,
              styles.regularFontSize,
              {
                textAlign: "left",
                height: "auto",
                borderTop: 1,
                borderBottom: 1,
                marginLeft: 40,
                marginRight: 30,
                marginTop: -15,
                borderRight: 1,
                borderLeft: 1,
                top: 100,
              },
            ]}
          >
            <View
              style={[
                styles.tableRow,
                {
                  paddingLeft: 2,
                  paddingRight: 2,
                  flexDirection: "row",
                },
              ]}
            >
              <Text
                style={[
                  styles.boldText,
                  {
                    paddingTop: -1,
                    borderRightWidth: 1,
                    width: "15%",
                    paddingBottom: 2,
                    justifyContent: "space-between",
                  },
                ]}
              >
                SR NO.
              </Text>
              <Text
                style={[
                  styles.boldText,
                  {
                    textAlign: "left",
                    borderRightWidth: 1,
                    width: "60%",
                    justifyContent: "space-between",
                    paddingLeft: 5,
                    paddingRight: 5,
                    paddingBottom: 10,
                  },
                ]}
              >
                CONTAINER NO
              </Text>
              <Text
                style={[
                  styles.boldText,
                  {
                    textAlign: "left",
                    paddingRight: 5,
                    paddingBottom: 2,

                    paddingLeft: 5,
                    width: "65%",
                    justifyContent: "space-between",
                  },
                ]}
              >
                SIZE (TEU/FEU)
              </Text>
              <Text
                style={[
                  styles.boldText,
                  {
                    textAlign: "left",
                    paddingRight: 5,
                    paddingBottom: 2,
                    paddingLeft: 5,
                    width: "55%",
                    borderLeft: 1,
                    justifyContent: "space-between",
                  },
                ]}
              >
                MAX GROSS
              </Text>
              <Text
                style={[
                  styles.boldText,
                  {
                    textAlign: "left",
                    paddingRight: 5,
                    paddingBottom: 2,

                    paddingLeft: 5,
                    width: "60%",
                    justifyContent: "space-between",
                    borderLeft: 1,
                  },
                ]}
              >
                VGM WT. (KG)
              </Text>
              <Text
                style={[
                  styles.boldText,
                  {
                    borderLeft: 1,
                    textAlign: "left",
                    paddingRight: 5,
                    paddingBottom: 2,

                    paddingLeft: 5,
                    width: "50%",
                    justifyContent: "space-between",
                  },
                ]}
              >
                DATE
              </Text>
              <Text
                style={[
                  styles.boldText,
                  {
                    borderLeft: 1,
                    textAlign: "left",
                    paddingRight: 5,
                    paddingBottom: 2,
                    paddingLeft: 5,
                    width: "50%",
                    justifyContent: "space-between",
                  },
                ]}
              >
                WT. SLIIP NO.
              </Text>
            </View>
          </View>

          {/* table-2 data */}
          <View
            style={[
              styles.tableContainer,
              styles.regularFontSize,
              {
                textAlign: "left",
                height: "auto",
                borderBottom: 1,
                marginLeft: 40,
                marginRight: 30,
                borderRight: 1,
                borderLeft: 1,
                top: 100,
              },
            ]}
          >
            <View
              style={[
                styles.tableRow,
                {
                  paddingLeft: 2,
                  paddingRight: 2,
                  flexDirection: "row",
                },
              ]}
            >
              <Text
                style={[
                  styles.boldText,
                  {
                    paddingTop: -1,
                    borderRightWidth: 1,
                    width: "15%",
                    justifyContent: "space-between",
                  },
                ]}
              >
                1
              </Text>
              <Text
                style={[
                  styles.boldText,
                  {
                    textAlign: "left",
                    borderRightWidth: 1,
                    width: "60%",
                    justifyContent: "space-between",
                    paddingLeft: 5,
                    paddingRight: 5,
                    paddingBottom: 2,
                  },
                ]}
              >
                RFCU2362832
              </Text>
              <Text
                style={[
                  styles.boldText,
                  {
                    textAlign: "left",
                    paddingRight: 5,
                    paddingBottom: 2,

                    paddingLeft: 5,
                    width: "65%",
                    justifyContent: "space-between",
                  },
                ]}
              >
                20’ DV (TEU)
              </Text>
              <Text
                style={[
                  styles.boldText,
                  {
                    textAlign: "left",
                    paddingRight: 5,
                    paddingBottom: 2,
                    paddingLeft: 5,
                    width: "55%",
                    borderLeft: 1,
                    justifyContent: "space-between",
                  },
                ]}
              >
                30480.00
              </Text>
              <Text
                style={[
                  styles.boldText,
                  {
                    textAlign: "left",
                    paddingRight: 5,
                    paddingBottom: 2,

                    paddingLeft: 5,
                    width: "60%",
                    justifyContent: "space-between",
                    borderLeft: 1,
                  },
                ]}
              >
                22240.00
              </Text>
              <Text
                style={[
                  styles.boldText,
                  {
                    borderLeft: 1,
                    textAlign: "left",
                    paddingRight: 5,
                    paddingBottom: 2,

                    paddingLeft: 5,
                    width: "50%",
                    justifyContent: "space-between",
                  },
                ]}
              >
                06/01/2025
              </Text>
              <Text
                style={[
                  styles.boldText,
                  {
                    borderLeft: 1,
                    textAlign: "left",
                    paddingRight: 5,
                    paddingBottom: 2,

                    paddingLeft: 5,
                    width: "50%",
                    justifyContent: "space-between",
                  },
                ]}
              >
                LE/084
              </Text>
            </View>
          </View>

          <View
            style={[
              styles.tableContainer,
              { top: 110, paddingLeft: 25, paddingRight: 25 },
            ]}
          >
            <Text style={[styles.subContent, styles.boldText]}>
              We hereby confirm and certify that the Weights mentioned for the
              containers given in VGM Declaration is as per amendments to
              Regulation 2 of Chapter VI of the SOLAS convention issued by D G
              Shipping date 11th May 2016 F. No. 75-NT (4)/ 2015, M.S. Notice No
              04 of 2016.
            </Text>
          </View>

          <View
            style={[
              styles.tableContainer,
              { top: 110, paddingLeft: 370, paddingRight: 5 },
            ]}
          >
            <Text style={[styles.subContent, styles.boldText]}>
              Name:GOPAL VANPARIYA
            </Text>
          </View>

          <View
            style={[
              styles.tableContainer,
              { top: 110, paddingLeft: 395, paddingRight: 5 },
            ]}
          >
            <Text style={[styles.subContent, styles.boldText]}>
              DATE: 06/01/2025
            </Text>
          </View>

          <View style={styles.footer}>
            <Text style={styles.footerText}></Text>
            <Image
              src="/img/Invoice/footer.png"
              style={[styles.footerImage]}
            />
          </View>
        </Page>
      </Document>
    </PDFViewer>
  );
};
export default VgmCertificate;
