/* eslint-disable react-hooks/exhaustive-deps */
import { pdf } from "@react-pdf/renderer";
import Spinner from "../../../theme/Spinner";
import MiscellaneousSalesInvoice from "./MiscellaneousSalesInvoice";
import { useEffect, useState } from "react";
import { CURRENCY } from "../../../components/Constants";
import { Link, useNavigate, useParams } from "react-router-dom";
import { getSettingsApi } from "../../../services/_settings";
import { getMiscellaneousSalesByIdApi } from "../../../services/_sales";
import {
  formatAmount,
  formatDate,
  stringToUpperCase,
} from "../../../components/Helper";

const ViewMiscellaneousSales: React.FC = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [miscellaneousSalesDetails, setMiscellaneousSalesDetails] =
    useState<any>({});
  const [hsnWiseProductData, setHsnWiseProductData] = useState<any>([]);
  const [saleProducts, setSaleProducts] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [settingData, setSettingData] = useState({});
  const [pdfLoading, setPdfLoading] = useState<boolean>(false);
  const [previewInvoiceLoading, setPreviewInvoiceLoading] =
    useState<boolean>(false);

  useEffect(() => {
    if (!id) {
      navigate(`/miscellaneous-sales-list`);
    } else {
      getMiscellaneousSalesDetails();
      fetchSettingsData();
    }
  }, []);

  const getMiscellaneousSalesDetails = async () => {
    setLoading(true);
    const result: any = await getMiscellaneousSalesByIdApi(id as string);
    if (result.isSuccess) {
      setMiscellaneousSalesDetails(result.data);
      setSaleProducts(result.data.miscellaneous_sales_items);
      hsnWiseProductGrouping(result.data.miscellaneous_sales_items);
    }
    setLoading(false);
  };

  const fetchSettingsData = async () => {
    const result: any = await getSettingsApi();
    if (result.isSuccess) {
      setSettingData(result.data);
    }
  };

  const calculateTotal = (items: any, keyName: string) => {
    let total = 0;
    items.forEach((item: any) => {
      total += Number(item[keyName]);
    });
    return total;
  };

  const hsnWiseProductGrouping = (data: any) => {
    const groupedData = data.reduce((acc: any, item: any) => {
      const hsn = item.product.hsn_no;
      const total = parseFloat(item.sub_total);
      const cgst = parseFloat(item.cgst);
      const sgst = parseFloat(item.sgst);
      const gst = parseFloat(item.gst_amount);
      const gst_percentage = Number(item.gst_percentage);

      if (!acc[hsn]) {
        acc[hsn] = {
          hsn_no: hsn,
          total: 0,
          cgst: 0,
          sgst: 0,
          gst: 0,
          gst_percentage: gst_percentage,
          items: [],
        };
      }

      acc[hsn].total += total;
      acc[hsn].cgst += cgst;
      acc[hsn].sgst += sgst;
      acc[hsn].gst += gst;
      acc[hsn].items.push(item);

      return acc;
    }, {});

    const result = Object.values(groupedData);
    setHsnWiseProductData(result);
  };

  const previewMiscellaneousSalesInvoice = async (data: any) => {
    setPreviewInvoiceLoading(true);
    try {
      const pdfDoc = await pdf(
        <MiscellaneousSalesInvoice
          salesData={data}
          hsnWiseProductData={hsnWiseProductData}
          settingData={settingData}
        />
      ).toBlob();
      const pdfUrl = URL.createObjectURL(pdfDoc);
      window.open(pdfUrl, "_blank");
      setPreviewInvoiceLoading(false);
    } catch (err) {
      console.error("Error generating PDF:", err);
    }
  };

  const downloadMiscellaneousSalesInvoice = (data: any) => {
    setPdfLoading(true);
    const pdfDoc = pdf(
      <MiscellaneousSalesInvoice
        salesData={data}
        hsnWiseProductData={hsnWiseProductData}
        settingData={settingData}
      />
    );
    pdfDoc.toBlob().then((blob) => {
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = `${data.invoice_no}-miscellaneous-sales-invoice.pdf`;
      link.click();
      setPdfLoading(false);
    });
  };

  return (
    <>
      <div className="card">
        <div className="card-header pb-0 d-flex justify-content-between">
          <div>
            <h5>Miscellaneous Sales Details :</h5>
          </div>
          <div className="d-flex" style={{ gap: "10px" }}>
            <button
              className="btn btn-primary waves-effect waves-light"
              type="button"
              onClick={() =>
                previewMiscellaneousSalesInvoice(miscellaneousSalesDetails)
              }
              disabled={previewInvoiceLoading}
            >
              <span>
                <i className="ti ti-file me-sm-1"></i>{" "}
                <span className="d-none d-sm-inline-block">
                  {previewInvoiceLoading && (
                    <span
                      className="spinner-border spinner-border-sm me-2"
                      role="status"
                      aria-hidden="true"
                    ></span>
                  )}
                  Preview Invoice
                </span>
              </span>
            </button>
            <button
              className="btn btn-primary waves-effect waves-light"
              type="button"
              onClick={() =>
                downloadMiscellaneousSalesInvoice(miscellaneousSalesDetails)
              }
              disabled={pdfLoading}
            >
              <span>
                <i className="ti ti-download me-sm-1"></i>{" "}
                <span className="d-none d-sm-inline-block">
                  {pdfLoading && (
                    <span
                      className="spinner-border spinner-border-sm me-2"
                      role="status"
                      aria-hidden="true"
                    ></span>
                  )}
                  Download
                </span>
              </span>
            </button>
            <Link
              to={"/miscellaneous-sales-list"}
              type="button"
              className="btn btn-label-github"
            >
              Back
            </Link>
          </div>
        </div>
        <div className="card-body">
          {loading && <Spinner isFullScreen={false} />}
          <div>
            <div
              className="col-12 col-sm-6 col-md-4 col-lg-3 row m-1 p-2 d-flex info-container text-capitalize"
              style={{
                border: "1px solid #d1d0d4",
                borderRadius: "0.375rem",
                width: "100%",
              }}
            >
              <div className="col-12 col-md-6 mb-3">
                <span className="h6 me-1">Invoice No :</span>
                {miscellaneousSalesDetails?.invoice_no || ""}
              </div>
              <div className="col-12 col-md-6 mb-3">
                <span className="h6 me-1">Sale Date:</span>
                {formatDate(miscellaneousSalesDetails.sale_date)}
              </div>
              <div className="col-12 col-md-6 mb-3">
                <span className="h6 me-1">Buyer:</span>
                {miscellaneousSalesDetails?.buyer?.name || ""}
              </div>
              <div className="col-12 col-md-6 mb-3">
                <span className="h6 me-1">Sub Total :</span>
                {CURRENCY.INR.SYMBOL}
                {formatAmount(miscellaneousSalesDetails?.sub_total)}
              </div>
              <div className="col-12 col-md-6 mb-3">
                <span className="h6 me-1">GST Amount :</span>
                {CURRENCY.INR.SYMBOL}
                {formatAmount(miscellaneousSalesDetails?.gst_amount)}
              </div>
              {(miscellaneousSalesDetails?.round_of > 0 ||
                miscellaneousSalesDetails?.round_of < 0) && (
                <div className="col-12 col-md-6 mb-3">
                  <span className="h6 me-1">Round Of Amount:</span>
                  {CURRENCY.INR.SYMBOL}
                  {formatAmount(miscellaneousSalesDetails?.round_of)}
                </div>
              )}
              <div className="col-12 col-md-6 mb-3">
                <span className="h6 me-1">Total Amount:</span>
                {CURRENCY.INR.SYMBOL}
                {formatAmount(miscellaneousSalesDetails?.total)}
              </div>
              <div className="col-12 col-md-6 mb-3">
                <span className="h6 me-1">Terms of Payment :</span>
                {miscellaneousSalesDetails?.terms_of_payment || ""}
              </div>
            </div>
          </div>

          <div className="mt-7 py-2 pb-0 d-flex justify-content-start align-items-center">
            <h5>Product Details :</h5>
          </div>

          <div className="card-datatable table-responsive">
            <table className="dt-advanced-search table table-nowrap">
              <thead>
                <tr>
                  <th>#</th>
                  <th>Product Name</th>
                  <th>Price</th>
                  <th>Quantity</th>
                  <th>No Of Bags</th>
                  <th>Sub Total</th>
                  <th>GST (%)</th>
                  <th>GST Amount</th>
                  <th>Total</th>
                </tr>
              </thead>
              <tbody>
                {saleProducts ? (
                  saleProducts.map((row: any, index: number) => {
                    return (
                      <tr key={row.id}>
                        <td>{index + 1}</td>
                        <td>{row?.product?.name || ""}</td>
                        <td>
                          {CURRENCY.INR.SYMBOL}
                          {formatAmount(row?.price)}
                        </td>
                        <td>
                          {row?.quantity} {stringToUpperCase(row?.unit?.name)}
                        </td>
                        <td>{row?.no_of_bags || ""}</td>
                        <td>
                          {CURRENCY.INR.SYMBOL}
                          {formatAmount(row?.sub_total)}
                        </td>
                        <td>
                          {row?.gst_percentage ? `${row?.gst_percentage}%` : ""}
                        </td>
                        <td>
                          {CURRENCY.INR.SYMBOL}
                          {formatAmount(row?.gst_amount)}
                        </td>
                        <td>
                          {CURRENCY.INR.SYMBOL}
                          {formatAmount(row.total)}
                        </td>
                      </tr>
                    );
                  })
                ) : (
                  <></>
                )}
                <tr>
                  <th></th>
                  <th></th>
                  <th></th>
                  <th>{calculateTotal(saleProducts, "quantity")}</th>
                  <th>{calculateTotal(saleProducts, "no_of_bags")}</th>
                  <th>
                    <th>
                      {CURRENCY.INR.SYMBOL}
                      {formatAmount(miscellaneousSalesDetails?.sub_total)}
                    </th>
                  </th>
                  <th></th>
                  <th>
                    {CURRENCY.INR.SYMBOL}
                    {formatAmount(miscellaneousSalesDetails?.gst_amount)}
                  </th>
                  <th>
                    {CURRENCY.INR.SYMBOL}
                    {formatAmount(miscellaneousSalesDetails?.total)}
                  </th>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
};

export default ViewMiscellaneousSales;
