import * as Yup from "yup";
import React, { useState } from "react";
import { errorToast, successToast } from "../Toast";
import { createUnitApi } from "../../services/_unit";
import { Field, Form, Formik, ErrorMessage } from "formik";

const createUnitInitialValues = {
  name: "",
  description: "",
  status: "",
};

interface AddUnitModalProps {
  addUnitModalStatus: boolean;
  closeAddUnitModal: () => void;
  onUnitCreated: any;
}

const createUnitValidationSchema = Yup.object().shape({
  name: Yup.string().required("Name is required"),
  description: Yup.string(),
  status: Yup.string().required("Status is required"),
});

const AddUnitModal: React.FC<AddUnitModalProps> = ({
  addUnitModalStatus,
  closeAddUnitModal,
  onUnitCreated,
}) => {
  const [submitLoader, setSubmitLoader] = useState(false);

  const handleSubmit = async (values: any, { resetForm }: any) => {
    setSubmitLoader(true);
    values.status = values.status === "true" ? true : false;
    values.name = values.name.toUpperCase();
    try {
      const result = await createUnitApi(values);
      if (result.isSuccess) {
        setSubmitLoader(false);
        resetForm({ values: createUnitInitialValues });
        successToast(result.message);
        closeAddUnitModal();
        onUnitCreated();
      } else {
        errorToast(result.message);
        setSubmitLoader(false);
      }
    } catch (error) {
      errorToast("An error occurred while creating the unit");
    }
    setSubmitLoader(false);
  };

  return (
    <>
      {addUnitModalStatus && (
        <>
          <div className="modal-backdrop fade show"></div>
          <div
            className="modal fade show"
            id="unitModal"
            tabIndex={-3}
            aria-hidden={!addUnitModalStatus}
            style={{ display: addUnitModalStatus ? "block" : "none" }}
          >
            <div className="modal-dialog modal-lg modal-simple modal-dialog-centered">
              <div className="modal-content">
                <div className="modal-body p-4">
                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                    onClick={closeAddUnitModal}
                  ></button>
                  <div className="text-center mb-2">
                    <h4 className="mb-2">Add Unit</h4>
                  </div>
                  <div className="modal-body p-0">
                    <Formik
                      validationSchema={createUnitValidationSchema}
                      initialValues={createUnitInitialValues}
                      onSubmit={handleSubmit}
                    >
                      {({ handleChange, values }) => (
                        <Form className="row g-4">
                          <div className="col-12 col-md-6">
                            <label htmlFor="name" className="form-label">
                              Name <span className="text-danger">*</span>
                            </label>
                            <Field
                              id="name"
                              name="name"
                              type="text"
                              className="form-control"
                              placeholder="Enter unit name"
                              onChange={handleChange}
                            />
                            <ErrorMessage
                              name="name"
                              component="div"
                              className="text-danger"
                            />
                          </div>
                          <div className="col-12 col-sm-6 ">
                            <label
                              htmlFor="status"
                              className="form-label"
                              style={{ fontSize: "0.9375rem" }}
                            >
                              Status: <span className="text-danger">*</span>
                            </label>
                            <select
                              id="status"
                              name="status"
                              className="form-select"
                              value={values.status}
                              onChange={handleChange}
                            >
                              <option value="">Select Status</option>
                              <option value="true">Active</option>
                              <option value="false">Inactive</option>
                            </select>
                            <ErrorMessage
                              name="status"
                              className="text-danger"
                              component="div"
                            />
                          </div>
                          <div className="mb-3">
                            <label htmlFor="description" className="form-label">
                              Description
                            </label>
                            <Field
                              id="description"
                              name="description"
                              type="text"
                              className="form-control"
                              placeholder="Enter unit description"
                              onChange={handleChange}
                            />
                            <ErrorMessage
                              name="description"
                              component="div"
                              className="text-danger"
                            />
                          </div>
                          <div className="col-12 text-center mt-4">
                            <button
                              type="submit"
                              className="btn btn-primary me-3"
                              disabled={submitLoader}
                            >
                              {submitLoader && (
                                <span
                                  className="spinner-border spinner-border-sm me-2"
                                  role="status"
                                  aria-hidden="true"
                                ></span>
                              )}{" "}
                              Add Unit
                            </button>
                            <button
                              type="button"
                              className="btn btn-label-secondary"
                              onClick={closeAddUnitModal}
                            >
                              Cancel
                            </button>
                          </div>
                        </Form>
                      )}
                    </Formik>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default AddUnitModal;
