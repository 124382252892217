import moment from "moment";
import { htmlToText } from "html-to-text";
import { Text } from "@react-pdf/renderer";
import { City, Country, State } from "country-state-city";

export const getCountries = () => {
  return Country.getAllCountries();
};

export const getStates = (country: any) => {
  if (country) {
    const states = State.getStatesOfCountry(country.isoCode);
    return states || [];
  }
  return [];
};

export const getCities = (state: any) => {
  if (state) {
    const cities = City.getCitiesOfState(state.countryCode, state.isoCode);
    return cities || [];
  }
  return [];
};

export const getStateCityList = async (countryName: any, stateName: any) => {
  const country = await getCountries().find(
    (country) => country.name === countryName
  );
  const allStates = country ? getStates(country) : [];
  const state = allStates.find((state: any) => state.name === stateName);
  const allCities = state ? getCities(state) : [];
  return { allStates, allCities };
};

export const formatAmount = (amount: any, decimal?: number) => {
  if (amount) {
    return parseFloat(amount).toFixed(decimal || 2);
  }
  return "0.00";
};

export const formatDate = (date: any) => {
  if (date) {
    return moment(date).format("DD-MM-YYYY");
  }
  return "N/A";
};

export const formatDateTime = (date: any) => {
  if (date) {
    return moment(date).format("DD-MM-YYYY HH:mm:ss");
  }
  return "N/A";
};

export const formatString = (input: string) => {
  if (!input) return "";
  if (input === "CHA") return input;
  return input
    .replace(/_/g, " ") // Replace underscores with spaces
    .toLowerCase()
    .replace(/\b\w/g, (char: any) => char.toUpperCase()); // Capitalize the first letter of each word
};

export const stringToUpperCase = (input: string) => {
  if (!input) return "";
  return input.toUpperCase();
};

export const getFileNameFromUrl = (url: string) => {
  try {
    const parsedUrl = new URL(url);
    return parsedUrl.pathname.split("/").pop();
  } catch (error) {
    console.error("Invalid URL", error);
    return null;
  }
};

export const numberToWords = (number: number) => {
  const units = [
    "",
    "ONE",
    "TWO",
    "THREE",
    "FOUR",
    "FIVE",
    "SIX",
    "SEVEN",
    "EIGHT",
    "NINE",
    "TEN",
    "ELEVEN",
    "TWELVE",
    "THIRTEEN",
    "FOURTEEN",
    "FIFTEEN",
    "SIXTEEN",
    "SEVENTEEN",
    "EIGHTEEN",
    "NINETEEN",
  ];
  const teens = [
    "",
    "",
    "TWENTY",
    "THIRTY",
    "FORTY",
    "FIFTY",
    "SIXTY",
    "SEVENTY",
    "EIGHTY",
    "NINETY",
  ];
  const numberScales = ["HUNDRED", "THOUSAND", "LAKH", "CRORE"];

  let words = "";

  if (number >= 10000000) {
    words += `${numberToWords(Math.floor(number / 10000000))} ${
      numberScales[3]
    } `;
    number %= 10000000;
  }

  if (number >= 100000) {
    words += `${numberToWords(Math.floor(number / 100000))} ${
      numberScales[2]
    } `;
    number %= 100000;
  }

  if (number >= 1000) {
    words += `${numberToWords(Math.floor(number / 1000))} ${numberScales[1]} `;
    number %= 1000;
  }

  if (number >= 100) {
    words += `${units[Math.floor(number / 100)]} ${numberScales[0]} `;
    number %= 100;
  }

  if (number >= 20) {
    words += `${teens[Math.floor(number / 10)]} `;
    number %= 10;
  }
  if (number > 0) {
    words += `${units[number]} `;
  }

  return words.trim();
};

export const convertAmountToWords = (total: number, currency?: string) => {
  const totalNumber = Math.floor(total);
  let words = `TOTAL ${currency || "RUPEES"} ${numberToWords(totalNumber)}`;
  return `${words} ONLY`;
};

export const htmlToReactPdf = (htmlJson: any) => {
  const htmlJsonParsed = JSON.parse(htmlJson);
  const plainText = htmlToText(htmlJsonParsed, {
    wordwrap: false,
    selectors: [
      {
        selector: "strong",
        format: "inline",
        options: { bold: true },
      },
      {
        selector: "i",
        format: "inline",
        options: { italic: true },
      },
      {
        selector: "u",
        format: "inline",
        options: { underline: true },
      },
    ],
  });

  return <Text>{plainText}</Text>;
};
