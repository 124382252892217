import React from "react";
import { ErrorMessage, Field, FormikErrors, FormikValues } from "formik";
import { BankType } from "../../components/Enums";
import { Link } from "react-router-dom";

interface BankFormProps {
  relationList: any;
  handleTypeChange: (filterType: string) => void;
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean
  ) => Promise<void | FormikErrors<FormikValues>>;
  values: FormikValues;
  handleChange: (e: React.ChangeEvent<any>) => void;
}

const BankForm: React.FC<BankFormProps> = ({
  relationList,
  handleTypeChange,
  setFieldValue,
  values,
  handleChange,
}: any) => {
  return (
    <div className="row">
      <div className="col-12">
        <div className="row g-3">
          <div className="col-12 col-sm-6 col-lg-4">
            <label
              htmlFor="type"
              className="form-label"
              style={{ fontSize: "0.9375rem" }}
            >
              Type: <span className="text-danger">*</span>
            </label>
            <select
              id="type"
              name="type"
              className="form-select"
              value={values.type}
              onChange={(e) => {
                setFieldValue("type", e.target.value);
                setFieldValue("relation_id", "");
                handleTypeChange(e.target.value);
              }}
            >
              <option value="">Select Type</option>
              {[
                BankType.EXPORTER,
                BankType.BUYER,
                // BankType.SUPPLIER,
                // BankType.LOGISTIC,
                // BankType.CHA,
                // BankType.BROKER,
              ].map((item: any) => (
                <option value={item} key={item}>
                  {item}
                </option>
              ))}
            </select>
            <ErrorMessage name="type" className="text-danger" component="div" />
          </div>
          {values.type && values.type !== BankType.EXPORTER && (
            <div className="col-12 col-sm-6 col-lg-4">
              <label
                htmlFor="relation_id"
                className="form-label"
                style={{ fontSize: "0.9375rem" }}
              >
                {values.type}:<span className="text-danger">*</span>
              </label>
              <select
                id="relation_id"
                name="relation_id"
                className="form-select"
                value={values.relation_id}
                onChange={handleChange}
              >
                <option value="">Select Type</option>

                {relationList.length > 0 &&
                  relationList.map((data: any) => (
                    <option key={data.id} value={data.id}>
                      {data.name}
                    </option>
                  ))}
              </select>
              <ErrorMessage
                name="relation_id"
                className="text-danger"
                component="div"
              />
            </div>
          )}
          <div className="col-12 col-sm-6 col-lg-4">
            <label
              htmlFor="bank_name"
              className="form-label"
              style={{ fontSize: "0.9375rem" }}
            >
              Bank Name: <span className="text-danger">*</span>
            </label>
            <Field
              id="bank_name"
              name="bank_name"
              type="text"
              className="form-control"
              placeholder="Enter Bank Name"
              value={values.bank_name}
              onChange={handleChange}
            />
            <ErrorMessage
              name="bank_name"
              className="text-danger"
              component="div"
            />
          </div>
          <div className="col-12 col-sm-6 col-lg-4">
            <label
              htmlFor="account_number"
              className="form-label"
              style={{ fontSize: "0.9375rem" }}
            >
              Account Number: <span className="text-danger">*</span>
            </label>
            <Field
              id="account_number"
              name="account_number"
              type="text"
              className="form-control"
              placeholder="Enter Account Name"
              value={values.account_number}
              onChange={handleChange}
            />
            <ErrorMessage
              name="account_number"
              className="text-danger"
              component="div"
            />
          </div>
          <div className="col-12 col-sm-6 col-lg-4">
            <label
              htmlFor="ifsc_code"
              className="form-label"
              style={{ fontSize: "0.9375rem" }}
            >
              IFSC Code: <span className="text-danger">*</span>
            </label>
            <Field
              id="ifsc_code"
              name="ifsc_code"
              type="text"
              className="form-control"
              placeholder="Enter IFSC Code"
              value={values.ifsc_code}
              onChange={handleChange}
            />
            <ErrorMessage
              name="ifsc_code"
              className="text-danger"
              component="div"
            />
          </div>
          <div className="col-12 col-sm-6 col-lg-4">
            <label
              htmlFor="branch_name"
              className="form-label"
              style={{ fontSize: "0.9375rem" }}
            >
              Branch Name: <span className="text-danger">*</span>
            </label>
            <Field
              id="branch_name"
              name="branch_name"
              type="text"
              className="form-control"
              placeholder="Enter Branch Name"
              value={values.branch_name}
              onChange={handleChange}
            />
            <ErrorMessage
              name="branch_name"
              className="text-danger"
              component="div"
            />
          </div>
          <div className="col-12 col-sm-6 col-lg-4">
            <label
              htmlFor="swift_code"
              className="form-label"
              style={{ fontSize: "0.9375rem" }}
            >
              Swift Code:
            </label>
            <Field
              id="swift_code"
              name="swift_code"
              type="text"
              className="form-control"
              placeholder="Enter Swift Code"
              value={values.swift_code}
              onChange={handleChange}
            />
            <ErrorMessage
              name="swift_code"
              className="text-danger"
              component="div"
            />
          </div>
          <div className="col-12 col-sm-6 col-lg-4">
            <label
              htmlFor="ad_code"
              className="form-label"
              style={{ fontSize: "0.9375rem" }}
            >
              AD Code:
            </label>
            <Field
              id="ad_code"
              name="ad_code"
              type="text"
              className="form-control"
              placeholder="Enter AD Code"
              value={values.ad_code}
              onChange={handleChange}
            />
            <ErrorMessage
              name="ad_code"
              className="text-danger"
              component="div"
            />
          </div>
          <div className="col-12 col-sm-6 col-lg-4">
            <label
              htmlFor="status"
              className="form-label"
              style={{ fontSize: "0.9375rem" }}
            >
              Status: <span className="text-danger">*</span>
            </label>
            <select
              id="status"
              name="status"
              className="form-select"
              value={values.status}
              onChange={handleChange}
            >
              <option value="">Select Status</option>
              <option value="true">Active</option>
              <option value="false">Inactive</option>
            </select>
            <ErrorMessage
              name="status"
              className="text-danger"
              component="div"
            />
          </div>
          <div className="col-12">
            <label
              htmlFor="address"
              className="form-label"
              style={{ fontSize: "0.9375rem" }}
            >
              Address:
            </label>
            <Field
              id="address"
              name="address"
              type="text"
              className="form-control"
              placeholder="Enter Address"
              onChange={handleChange}
              as={"textarea"}
              rows={2}
            />
          </div>
          <div className="col-12">
            <button type="submit" className="btn btn-primary">
              Submit
            </button>
            <Link
              to={"/bank-list"}
              type="button"
              className="btn btn-label-github ms-5"
            >
              Back
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BankForm;
