export const COMPANY_NAME = "Enfiny Exim";
export const ADMIN_ID = "fd09c49a-7913-4af0-ad66-c40d1da39e6e";
export const ADMIN_ROLE_ID = "c5482c77-9c67-4fb3-8029-535bf92e8ad2";

export const CURRENCY = {
  INR: {
    NAME: "INR",
    SYMBOL: "₹",
  },
  USD: {
    NAME: "USD",
    SYMBOL: "$",
  },
};

export const THEME = [
  {
    main: "#7367f0",
    gradient: "rgba(115, 103, 240, 0.7)",
    hover: "#685dd8",
    is_active: false,
  },
  {
    main: "#1532c7",
    gradient: "#0082ff",
    hover: "rgba(0,130,255,.9)",
    is_active: true,
  },
];
