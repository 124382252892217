import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Layout from "../theme/Layout";
import Login from "../pages/auth/Login";
import PermissionRoute from "./PermissionRoute";
import CreateUser from "../pages/users/CreateUser";
import EditUser from "../pages/users/EditUser";
import SupplierList from "../pages/suppliers/SupplierList";
import CreateSupplier from "../pages/suppliers/CreateSupplier";
import EditSupplier from "../pages/suppliers/EditSupplier";
import ProductList from "../pages/product/ProductList";
import CreateProduct from "../pages/product/CreateProduct";
import EditProduct from "../pages/product/EditProduct";
import DocumentList from "../pages/document/DocumentList";
import CreateDocument from "../pages/document/CreateDocument";
import EditDocument from "../pages/document/EditDocument";
import CreateRole from "../pages/role/CreateRole";
import RoleList from "../pages/role/RoleList";
import EditRole from "../pages/role/EditRole";
import EditPort from "../pages/port/EditPort";
import CreatePort from "../pages/port/CreatePort";
import PortList from "../pages/port/PortList";
import QualitySpecificationsList from "../pages/qualitySpecifications/QualitySpecificationsList";
import CreateQualitySpecifications from "../pages/qualitySpecifications/CreateQualitySpecifications";
import EditQualitySpecifications from "../pages/qualitySpecifications/EditQualitySpecifications";
import EditCha from "../pages/cha/EditCha";
import CreateCha from "../pages/cha/CreateCha";
import ChaList from "../pages/cha/ChaList";
import ForgetPassword from "../pages/auth/ForgetPassword";
import VerifyOtp from "../pages/auth/VerifyOtp";
import ResetPassword from "../pages/auth/ResetPassword";
import EditBuyer from "../pages/buyer/EditBuyer";
import CreateBuyer from "../pages/buyer/CreateBuyer";
import BuyerList from "../pages/buyer/BuyerList";
import SealNumberList from "../pages/sealNumber/SealNumberList";
import CreateSealNumber from "../pages/sealNumber/CreateSealNumber";
import EditSealNumber from "../pages/sealNumber/EditSealNumber";
import LogisticList from "../pages/logistics/LogisticList";
import EditLogistic from "../pages/logistics/EditLogistic";
import CreateLogistic from "../pages/logistics/CreateLogistic";
import PurchaseList from "../pages/purchase/PurchaseList";
import CreatePurchase from "../pages/purchase/CreatePurchase";
import EditPurchase from "../pages/purchase/EditPurchase";
import AccessDenied from "../pages/other/AccessDenied";
import NotFound from "../pages/other/NotFound";
import EditBank from "../pages/bank/EditBank";
import CreateBank from "../pages/bank/CreateBank";
import BankList from "../pages/bank/BankList";
import EditExpenses from "../pages/expenses/EditExpenses";
import CreateExpenses from "../pages/expenses/CreateExpenses";
import ExpensesList from "../pages/expenses/ExpensesList";
import { lazy } from "react";
import SalesContractList from "../pages/sales/sales-contract/SalesContractList";
import ViewSalesContract from "../pages/sales/sales-contract/ViewSalesContract";
import CreateSalesContract from "../pages/sales/sales-contract/CreateSalesContract";
import EditSalesContract from "../pages/sales/sales-contract/EditSalesContract";
import SalesInvoiceList from "../pages/sales/sales-invoice/SalesInvoiceList";
import ViewSalesInvoice from "../pages/sales/sales-invoice/ViewSalesInvoice";
import CreateSalesInvoice from "../pages/sales/sales-invoice/CreateSalesInvoice";
import EditSalesInvoice from "../pages/sales/sales-invoice/EditSalesInvoice";
import MyBankList from "../pages/my-bank/MyBankList";
import CreateMyBank from "../pages/my-bank/CreateMyBank";
import EditMyBank from "../pages/my-bank/EditMyBank";
import Other from "../pages/other/Other";
import CreatePayment from "../pages/payments/CreatePayment";
import PaymentList from "../pages/payments/PaymentList";
import GenerateInvoice from "../pages/invoice/GenerateInvoice";
import ViewPurchase from "../pages/purchase/ViewPurchase";
import EditPayment from "../pages/payments/EditPayment";
import Settings from "../pages/settings/Settings";
import BuyerWiseSalesReport from "../pages/reports/BuyerWiseSalesReport";
import ProductWiseSalesReport from "../pages/reports/ProductWiseSalesReport";
import SupplierWisePurchaseReport from "../pages/reports/SupplierWisePurchaseReport";
import ProductWisePurchaseReport from "../pages/reports/ProductWisePurchaseReport";
import ProfitAndLossReport from "../pages/reports/ProfitAndLossReport";
import BuyerWiseSalesPaymentReport from "../pages/reports/BuyerWiseSalesPaymentReport";
import BrokerWisePaymentReport from "../pages/reports/BrokerWisePaymentReport";
import SupplierWisePaymentReport from "../pages/reports/SupplierWisePaymentReport";
import LogisticWisePaymentReport from "../pages/reports/LogisticWisePaymentReport";
import ChaWisePaymentReport from "../pages/reports/ChaWisePaymentReport";
import ExpenseReport from "../pages/reports/ExpenseReport";
import BrokerList from "../pages/broker/BrokerList";
import CreateBroker from "../pages/broker/CreateBroker";
import EditBroker from "../pages/broker/EditBroker";
import UnitList from "../pages/unit/UnitList";
import CreateUnit from "../pages/unit/CreateUnit";
import EditUnit from "../pages/unit/EditUnit";
import PackingTypeList from "../pages/packingTypes/PackingTypesList";
import CreatePackingType from "../pages/packingTypes/CreatePackingTypes";
import EditPackingType from "../pages/packingTypes/EditPackingTypes";
import MiscellaneousSalesList from "../pages/sales/miscellaneous-sales/MiscellaneousSalesList";
import CreateMiscellaneousSales from "../pages/sales/miscellaneous-sales/CreateMiscellaneousSales";
import EditMiscellaneousSales from "../pages/sales/miscellaneous-sales/EditMiscellaneousSales";
import ViewMiscellaneousSales from "../pages/sales/miscellaneous-sales/ViewMiscellaneousSales";

import CommercialInvoice from "../components/CommercialInvoice";
import PhytoCertificate from "../components/PhytoCertificate";
import BrokerInvoice from "../components/BrokerInvoice";
import AnnexureInvoice from "../components/Annexure_Invoice";
import ExportInvoiceCumPackingList from "../components/ExportInvoiceCumPackingList";
import BlDraftInvoice from "../components/BlDraftInvoice";
import CooDraftInvoice from "../components/CooDraftInvoice";
import WeightAndQualityCertificate from "../components/WeightAndQualityCertificate";
import HealthAndQualityCertificate from "../components/HealthAndQualityCertificate";
import FormAiDraft from "../components/FormAiDraft";
import NonGmoCertificateInvoice from "../components/non-gmo-certificate-invoice";
import VgmCertificate from "../components/VGM_Certificate";
import SalesContract from "../components/SalesContract";

const UserList = lazy(() => import("../pages/users/UserList"));
const Dashboard = lazy(() => import("../pages/dashboard/Dashboard"));

const AppRoutes = () => (
  <Router>
    <ToastContainer />

    <Routes>
      {/* Public Routes */}
      <Route path="/" element={<Login />} />
      <Route path="/login" element={<Login />} />
      <Route path="/forget-password" element={<ForgetPassword />} />
      <Route path="/verify-otp/:email" element={<VerifyOtp />} />
      <Route path="/reset-password/:email" element={<ResetPassword />} />
      <Route path="/403" element={<AccessDenied />} />
      <Route path="/404" element={<NotFound />} />

      {/* Authenticated Routes */}
      <Route path="/" element={<Layout />}>
        <Route path="/sales-contract" element={<SalesContract />} />
        <Route path="/commercial-invoice" element={<CommercialInvoice />} />

        <Route
          path="/health-and-quality-invoice"
          element={<HealthAndQualityCertificate />}
        />
        <Route
          path="/non-gmo-certificate-invoice"
          element={<NonGmoCertificateInvoice />}
        />

        <Route path="/phyto-certificate" element={<PhytoCertificate />} />
        <Route path="/broker-invoice" element={<BrokerInvoice />} />
        <Route path="/annexure-invoice" element={<AnnexureInvoice />} />
        <Route path="/bl-draft-invoice" element={<BlDraftInvoice />} />
        <Route path="/coo-draft-invoice" element={<CooDraftInvoice />} />
        <Route
          path="/weight-and-quality-certificate"
          element={<WeightAndQualityCertificate />}
        />
        <Route
          path="/export-invoice-cum-packing-list"
          element={<ExportInvoiceCumPackingList />}
        />
        <Route path="/form-ai-draft" element={<FormAiDraft />} />

        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/other" element={<Other />} />
        <Route path="/invoice" element={<GenerateInvoice />} />
        <Route path="/vgm-certificate" element={<VgmCertificate />} />
        <Route
          path="/user-list"
          element={
            <PermissionRoute permission="view-user">
              <UserList />
            </PermissionRoute>
          }
        />

        <Route
          path="/create-user"
          element={
            <PermissionRoute permission="create-user">
              <CreateUser />
            </PermissionRoute>
          }
        />
        <Route
          path="/edit-user/:id"
          element={
            <PermissionRoute permission="update-user">
              <EditUser />
            </PermissionRoute>
          }
        />

        <Route
          path="/create-payment"
          element={
            <PermissionRoute permission="create-payments">
              <CreatePayment />
            </PermissionRoute>
          }
        />

        <Route
          path="/edit-payment/:id"
          element={
            <PermissionRoute permission="update-payments">
              <EditPayment />
            </PermissionRoute>
          }
        />

        <Route
          path="/payment-list"
          element={
            <PermissionRoute permission="view-payments">
              <PaymentList />
            </PermissionRoute>
          }
        />

        <Route
          path="/supplier-list"
          element={
            <PermissionRoute permission="view-supplier">
              <SupplierList />
            </PermissionRoute>
          }
        />

        <Route
          path="/create-supplier"
          element={
            <PermissionRoute permission="create-supplier">
              <CreateSupplier />
            </PermissionRoute>
          }
        />
        <Route
          path="/edit-supplier/:id"
          element={
            <PermissionRoute permission="update-supplier">
              <EditSupplier />
            </PermissionRoute>
          }
        />

        <Route
          path="/purchase-list"
          element={
            <PermissionRoute permission="view-purchase">
              <PurchaseList />
            </PermissionRoute>
          }
        />
        <Route
          path="/view-purchase/:id"
          element={
            <PermissionRoute permission="view-purchase">
              <ViewPurchase />
            </PermissionRoute>
          }
        />

        <Route
          path="/create-purchase"
          element={
            <PermissionRoute permission="create-purchase">
              <CreatePurchase />
            </PermissionRoute>
          }
        />
        <Route
          path="/edit-purchase/:id"
          element={
            <PermissionRoute permission="update-purchase">
              <EditPurchase />
            </PermissionRoute>
          }
        />

        <Route
          path="/logistic-list"
          element={
            <PermissionRoute permission="view-logistic">
              <LogisticList />
            </PermissionRoute>
          }
        />

        <Route
          path="/create-logistic"
          element={
            <PermissionRoute permission="create-logistic">
              <CreateLogistic />
            </PermissionRoute>
          }
        />
        <Route
          path="/edit-logistic/:id"
          element={
            <PermissionRoute permission="update-logistic">
              <EditLogistic />
            </PermissionRoute>
          }
        />

        <Route
          path="/product-list"
          element={
            <PermissionRoute permission="view-product">
              <ProductList />
            </PermissionRoute>
          }
        />

        <Route
          path="/create-product"
          element={
            <PermissionRoute permission="create-product">
              <CreateProduct />
            </PermissionRoute>
          }
        />
        <Route
          path="/edit-product/:id"
          element={
            <PermissionRoute permission="update-product">
              <EditProduct />
            </PermissionRoute>
          }
        />
        <Route
          path="/document-list"
          element={
            <PermissionRoute permission="view-document">
              <DocumentList />
            </PermissionRoute>
          }
        />

        <Route
          path="/create-document"
          element={
            <PermissionRoute permission="create-document">
              <CreateDocument />
            </PermissionRoute>
          }
        />
        <Route
          path="/edit-document/:id"
          element={
            <PermissionRoute permission="update-document">
              <EditDocument />
            </PermissionRoute>
          }
        />
        <Route
          path="/role-list"
          element={
            <PermissionRoute permission="view-role">
              <RoleList />
            </PermissionRoute>
          }
        ></Route>
        <Route
          path="/create-role"
          element={
            <PermissionRoute permission="create-role">
              <CreateRole />
            </PermissionRoute>
          }
        ></Route>
        <Route
          path="/edit-role/:id"
          element={
            <PermissionRoute permission="update-role">
              <EditRole />
            </PermissionRoute>
          }
        ></Route>

        <Route
          path="/port-list"
          element={
            <PermissionRoute permission="view-port">
              <PortList />
            </PermissionRoute>
          }
        />

        <Route
          path="/create-port"
          element={
            <PermissionRoute permission="create-port">
              <CreatePort />
            </PermissionRoute>
          }
        />
        <Route
          path="/edit-port/:id"
          element={
            <PermissionRoute permission="update-port">
              <EditPort />
            </PermissionRoute>
          }
        />

        <Route
          path="/unit-list"
          element={
            <PermissionRoute permission="view-unit">
              <UnitList />
            </PermissionRoute>
          }
        />
        <Route
          path="/create-unit"
          element={
            <PermissionRoute permission="create-unit">
              <CreateUnit />
            </PermissionRoute>
          }
        />
        <Route
          path="/edit-unit/:id"
          element={
            <PermissionRoute permission="update-unit">
              <EditUnit />
            </PermissionRoute>
          }
        />

        <Route
          path="/packing-type-list"
          element={
            <PermissionRoute permission="view-packing-type">
              <PackingTypeList />
            </PermissionRoute>
          }
        />
        <Route
          path="/create-packing-type"
          element={
            <PermissionRoute permission="create-packing-type">
              <CreatePackingType />
            </PermissionRoute>
          }
        />
        <Route
          path="/edit-packing-type/:id"
          element={
            <PermissionRoute permission="update-packing-type">
              <EditPackingType />
            </PermissionRoute>
          }
        />

        <Route
          path="/quality-specification-list"
          element={
            <PermissionRoute permission="view-quality-specification">
              <QualitySpecificationsList />
            </PermissionRoute>
          }
        />

        <Route
          path="/create-quality-specification"
          element={
            <PermissionRoute permission="create-quality-specification">
              <CreateQualitySpecifications />
            </PermissionRoute>
          }
        />
        <Route
          path="/edit-quality-specification/:id"
          element={
            <PermissionRoute permission="update-quality-specification">
              <EditQualitySpecifications />
            </PermissionRoute>
          }
        />
        <Route
          path="/cha-list"
          element={
            <PermissionRoute permission="view-cha">
              <ChaList />
            </PermissionRoute>
          }
        />

        <Route
          path="/create-cha"
          element={
            <PermissionRoute permission="create-cha">
              <CreateCha />
            </PermissionRoute>
          }
        />
        <Route
          path="/edit-cha/:id"
          element={
            <PermissionRoute permission="update-cha">
              <EditCha />
            </PermissionRoute>
          }
        />
        <Route
          path="/buyer-list"
          element={
            <PermissionRoute permission="view-buyer">
              <BuyerList />
            </PermissionRoute>
          }
        />

        <Route
          path="/create-buyer"
          element={
            <PermissionRoute permission="create-buyer">
              <CreateBuyer />
            </PermissionRoute>
          }
        />
        <Route
          path="/edit-buyer/:id"
          element={
            <PermissionRoute permission="update-buyer">
              <EditBuyer />
            </PermissionRoute>
          }
        />

        <Route
          path="/broker-list"
          element={
            <PermissionRoute permission="view-broker">
              <BrokerList />
            </PermissionRoute>
          }
        />

        <Route
          path="/create-broker"
          element={
            <PermissionRoute permission="create-broker">
              <CreateBroker />
            </PermissionRoute>
          }
        />

        <Route
          path="/edit-broker/:id"
          element={
            <PermissionRoute permission="update-broker">
              <EditBroker />
            </PermissionRoute>
          }
        />

        <Route
          path="/seal-number-list"
          element={
            <PermissionRoute permission="view-seal-number">
              <SealNumberList />
            </PermissionRoute>
          }
        />

        <Route
          path="/create-seal-number"
          element={
            <PermissionRoute permission="create-seal-number">
              <CreateSealNumber />
            </PermissionRoute>
          }
        />
        <Route
          path="/edit-seal-number/:id"
          element={
            <PermissionRoute permission="update-seal-number">
              <EditSealNumber />
            </PermissionRoute>
          }
        />

        <Route
          path="/expenses-list"
          element={
            <PermissionRoute permission="view-expenses">
              <ExpensesList />
            </PermissionRoute>
          }
        />

        <Route
          path="/settings"
          element={
            <PermissionRoute permission="settings">
              <Settings />
            </PermissionRoute>
          }
        />
        <Route
          path="/create-expenses"
          element={
            <PermissionRoute permission="create-expenses">
              <CreateExpenses />
            </PermissionRoute>
          }
        />
        <Route
          path="/edit-expenses/:id"
          element={
            <PermissionRoute permission="update-expenses">
              <EditExpenses />
            </PermissionRoute>
          }
        />

        <Route
          path="/bank-list"
          element={
            <PermissionRoute permission="view-bank">
              <BankList />
            </PermissionRoute>
          }
        />
        <Route
          path="/create-bank"
          element={
            <PermissionRoute permission="create-bank">
              <CreateBank />
            </PermissionRoute>
          }
        />
        <Route
          path="/edit-bank/:id"
          element={
            <PermissionRoute permission="update-bank">
              <EditBank />
            </PermissionRoute>
          }
        />

        <Route
          path="/sales-contract-list"
          element={
            <PermissionRoute permission="view-sales-contract">
              <SalesContractList />
            </PermissionRoute>
          }
        />
        <Route
          path="/view-sales-contract/:id"
          element={
            <PermissionRoute permission="view-sales-contract">
              <ViewSalesContract />
            </PermissionRoute>
          }
        />
        <Route
          path="/create-sales-contract"
          element={
            <PermissionRoute permission="create-sales-contract">
              <CreateSalesContract />
            </PermissionRoute>
          }
        />
        <Route
          path="/edit-sales-contract/:id"
          element={
            <PermissionRoute permission="update-sales-contract">
              <EditSalesContract />
            </PermissionRoute>
          }
        />
        <Route
          path="/sales-invoice-list"
          element={
            <PermissionRoute permission="view-sales-invoice">
              <SalesInvoiceList />
            </PermissionRoute>
          }
        />
        <Route
          path="/view-sales-invoice/:id"
          element={
            <PermissionRoute permission="view-sales-invoice">
              <ViewSalesInvoice />
            </PermissionRoute>
          }
        />
        <Route
          path="/create-sales-invoice"
          element={
            <PermissionRoute permission="create-sales-invoice">
              <CreateSalesInvoice />
            </PermissionRoute>
          }
        />
        <Route
          path="/edit-sales-invoice/:id"
          element={
            <PermissionRoute permission="update-sales-invoice">
              <EditSalesInvoice />
            </PermissionRoute>
          }
        />
        <Route
          path="/miscellaneous-sales-list"
          element={
            <PermissionRoute permission="view-miscellaneous-sales">
              <MiscellaneousSalesList />
            </PermissionRoute>
          }
        />
        <Route
          path="/view-miscellaneous-sales/:id"
          element={
            <PermissionRoute permission="view-miscellaneous-sales">
              <ViewMiscellaneousSales />
            </PermissionRoute>
          }
        />
        <Route
          path="/create-miscellaneous-sales"
          element={
            <PermissionRoute permission="create-miscellaneous-sales">
              <CreateMiscellaneousSales />
            </PermissionRoute>
          }
        />
        <Route
          path="/edit-miscellaneous-sales/:id"
          element={
            <PermissionRoute permission="update-miscellaneous-sales">
              <EditMiscellaneousSales />
            </PermissionRoute>
          }
        />

        <Route
          path="/my-bank-list"
          element={
            <PermissionRoute permission="view-my-bank">
              <MyBankList />
            </PermissionRoute>
          }
        />
        <Route
          path="/create-my-bank"
          element={
            <PermissionRoute permission="create-my-bank">
              <CreateMyBank />
            </PermissionRoute>
          }
        />
        <Route
          path="/edit-my-bank/:id"
          element={
            <PermissionRoute permission="update-my-bank">
              <EditMyBank />
            </PermissionRoute>
          }
        />
        <Route
          path="/buyer-wise-sales-report"
          element={
            <PermissionRoute permission="view-buyer-wise-sales-report">
              <BuyerWiseSalesReport />
            </PermissionRoute>
          }
        />
        <Route
          path="/product-wise-sales-report"
          element={
            <PermissionRoute permission="view-product-wise-sales-report">
              <ProductWiseSalesReport />
            </PermissionRoute>
          }
        />
        <Route
          path="/supplier-wise-purchase-report"
          element={
            <PermissionRoute permission="view-supplier-wise-purchase-report">
              <SupplierWisePurchaseReport />
            </PermissionRoute>
          }
        />
        <Route
          path="/product-wise-purchase-report"
          element={
            <PermissionRoute permission="view-product-wise-purchase-report">
              <ProductWisePurchaseReport />
            </PermissionRoute>
          }
        />
        <Route
          path="/profit-and-loss-report"
          element={
            <PermissionRoute permission="view-profit-and-loss-report">
              <ProfitAndLossReport />
            </PermissionRoute>
          }
        />
        <Route
          path="/buyer-wise-payment-report"
          element={
            <PermissionRoute permission="view-buyer-wise-sales-payment-report">
              <BuyerWiseSalesPaymentReport />
            </PermissionRoute>
          }
        />
        <Route
          path="/broker-wise-payment-report"
          element={
            <PermissionRoute permission="view-broker-wise-payment-report">
              <BrokerWisePaymentReport />
            </PermissionRoute>
          }
        />
        <Route
          path="/supplier-wise-payment-report"
          element={
            <PermissionRoute permission="view-supplier-wise-payment-report">
              <SupplierWisePaymentReport />
            </PermissionRoute>
          }
        />
        <Route
          path="/logistic-wise-payment-report"
          element={
            <PermissionRoute permission="view-logistic-wise-payment-report">
              <LogisticWisePaymentReport />
            </PermissionRoute>
          }
        />
        <Route
          path="/cha-wise-payment-report"
          element={
            <PermissionRoute permission="view-cha-wise-payment-report">
              <ChaWisePaymentReport />
            </PermissionRoute>
          }
        />
        <Route
          path="/expense-report"
          element={
            <PermissionRoute permission="view-expense-report">
              <ExpenseReport />
            </PermissionRoute>
          }
        />
      </Route>
    </Routes>
  </Router>
);

export default AppRoutes;
