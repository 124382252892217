import * as Yup from "yup";
import React, { useState } from "react";
import { errorToast, successToast } from "../Toast";
import { Field, Form, Formik, ErrorMessage } from "formik";
import CountryStateCityField from "../../pages/other/CountryStateCityField";
import { getCities, getCountries, getStates } from "../../components/Helper";
import { createBuyerApi } from "../../services/_buyer";

const createBuyerInitialValues = {
  name: "",
  email: "",
  phone_no: "",
  tan_no: "",
  gst_no: "",
  pan_no: "",
  udhyam_no: "",
  address: "",
  country: "",
  state: "",
  city: "",
  pin_code: "",
  status: "",
};

interface AddBuyerModalProps {
  addBuyerModal: boolean;
  closeAddBuyerModal: () => void;
  onBuyerCreated: any;
}

const createBuyerValidationSchema = Yup.object().shape({
  name: Yup.string().required("Name is required"),
  email: Yup.string().required("Email is required"),
  status: Yup.string().required("Status is required"),
});

const AddBuyerModal: React.FC<AddBuyerModalProps> = ({
  addBuyerModal,
  closeAddBuyerModal,
  onBuyerCreated,
}) => {
  const [submitLoader, setSubmitLoader] = useState(false);
  const countryList = getCountries();
  const [stateList, setStateList] = useState<any>([]);
  const [cityList, setCityList] = useState<any>([]);

  const handleCountryChange = (country: any) => {
    setStateList([]);
    setCityList([]);
    const allStates = getStates(country);
    setStateList(allStates);
  };
  const handleStateChange = (state: any) => {
    setCityList([]);
    const cities = getCities(state);
    setCityList(cities);
  };

  const handleSubmit = async (values: any, { resetForm }: any) => {
    setSubmitLoader(true);
    values.status = values.status === "true" ? true : false;
    const result: any = await createBuyerApi(values);
    if (result.isSuccess) {
      setSubmitLoader(false);
      resetForm({ values: createBuyerInitialValues });
      successToast(result.message);
      closeAddBuyerModal();
      onBuyerCreated(result.data);
    } else {
      setSubmitLoader(false);
      errorToast(result.message);
    }
    return;
  };

  return (
    <>
      {addBuyerModal && (
        <>
          <div className="modal-backdrop fade show"></div>
          <div
            className="modal fade show"
            id="addBuyerModal"
            tabIndex={-3}
            aria-hidden={!addBuyerModal}
            style={{ display: addBuyerModal ? "block" : "none" }}
          >
            <div className="modal-dialog modal-lg modal-simple modal-dialog-centered">
              <div className="modal-content">
                <div className="modal-body p-4">
                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                    onClick={closeAddBuyerModal}
                  ></button>
                  <div className="text-center mb-2">
                    <h4 className="mb-2">Add Buyer</h4>
                  </div>
                  <div className="modal-body p-0">
                    <Formik
                      initialValues={createBuyerInitialValues}
                      validationSchema={createBuyerValidationSchema}
                      onSubmit={handleSubmit}
                    >
                      {({ handleChange, values, setFieldValue }) => (
                        <Form className="dt_adv_search">
                          <div className="row">
                            <div className="col-12">
                              <div className="row g-3">
                                <div className="col-12 col-sm-6 col-lg-4">
                                  <label
                                    htmlFor="name"
                                    className="form-label"
                                    style={{ fontSize: "0.9375rem" }}
                                  >
                                    Name: <span className="text-danger">*</span>
                                  </label>
                                  <Field
                                    id="name"
                                    name="name"
                                    type="text"
                                    className="form-control"
                                    placeholder="Enter name"
                                    onChange={handleChange}
                                  />
                                  <ErrorMessage
                                    name="name"
                                    className="text-danger"
                                    component="div"
                                  />
                                </div>
                                <div className="col-12 col-sm-6 col-lg-4">
                                  <label
                                    htmlFor="email"
                                    className="form-label"
                                    style={{ fontSize: "0.9375rem" }}
                                  >
                                    Email:{" "}
                                    <span className="text-danger">*</span>
                                  </label>
                                  <Field
                                    id="email"
                                    name="email"
                                    type="email"
                                    className="form-control"
                                    placeholder="Enter email"
                                    onChange={handleChange}
                                  />
                                  <ErrorMessage
                                    name="email"
                                    className="text-danger"
                                    component="div"
                                  />
                                </div>
                                <div className="col-12 col-sm-6 col-lg-4">
                                  <label
                                    htmlFor="phone_no"
                                    className="form-label"
                                    style={{ fontSize: "0.9375rem" }}
                                  >
                                    Phone No:
                                  </label>
                                  <Field
                                    id="phone_no"
                                    name="phone_no"
                                    type="text"
                                    className="form-control"
                                    placeholder="Enter phone no"
                                    onChange={handleChange}
                                  />
                                </div>
                                <div className="col-12 col-sm-6 col-lg-4">
                                  <label
                                    htmlFor="pan_no"
                                    className="form-label"
                                    style={{ fontSize: "0.9375rem" }}
                                  >
                                    PAN No:
                                  </label>
                                  <Field
                                    id="pan_no"
                                    name="pan_no"
                                    type="text"
                                    className="form-control"
                                    placeholder="Enter PAN No"
                                    onChange={handleChange}
                                  />
                                </div>
                                <div className="col-12 col-sm-6 col-lg-4">
                                  <label
                                    htmlFor="gst_no"
                                    className="form-label"
                                    style={{ fontSize: "0.9375rem" }}
                                  >
                                    GST No:
                                  </label>
                                  <Field
                                    id="gst_no"
                                    name="gst_no"
                                    type="text"
                                    className="form-control"
                                    placeholder="Enter GST No"
                                    onChange={handleChange}
                                  />
                                </div>
                                <div className="col-12 col-sm-6 col-lg-4">
                                  <label
                                    htmlFor="tan_no"
                                    className="form-label"
                                    style={{ fontSize: "0.9375rem" }}
                                  >
                                    TAN No:
                                  </label>
                                  <Field
                                    id="tan_no"
                                    name="tan_no"
                                    type="text"
                                    className="form-control"
                                    placeholder="Enter TAN No"
                                    onChange={handleChange}
                                  />
                                </div>
                                <div className="col-12 col-sm-6 col-lg-4">
                                  <label
                                    htmlFor="udhyam_no"
                                    className="form-label"
                                    style={{ fontSize: "0.9375rem" }}
                                  >
                                    UDHYAM No:
                                  </label>
                                  <Field
                                    id="udhyam_no"
                                    name="udhyam_no"
                                    type="text"
                                    className="form-control"
                                    placeholder="Enter UDHYAM No"
                                    onChange={handleChange}
                                  />
                                </div>
                                <CountryStateCityField
                                  countryList={countryList}
                                  stateList={stateList}
                                  cityList={cityList}
                                  values={values}
                                  setFieldValue={setFieldValue}
                                  handleCountryChange={handleCountryChange}
                                  handleStateChange={handleStateChange}
                                />
                                <div className="col-12 col-sm-6 col-lg-4">
                                  <label
                                    htmlFor="pin_code"
                                    className="form-label"
                                    style={{ fontSize: "0.9375rem" }}
                                  >
                                    Pin Code:
                                  </label>
                                  <Field
                                    id="pin_code"
                                    name="pin_code"
                                    type="text"
                                    className="form-control"
                                    placeholder="Enter Pin Code"
                                    onChange={handleChange}
                                  />
                                </div>
                                <div className="col-12 col-sm-6 col-lg-4">
                                  <label
                                    htmlFor="status"
                                    className="form-label"
                                    style={{ fontSize: "0.9375rem" }}
                                  >
                                    Status:{" "}
                                    <span className="text-danger">*</span>
                                  </label>
                                  <select
                                    id="status"
                                    name="status"
                                    className="form-select"
                                    value={values.status}
                                    onChange={handleChange}
                                  >
                                    <option value="">Select Status</option>
                                    <option value="true">Active</option>
                                    <option value="false">Inactive</option>
                                  </select>
                                  <ErrorMessage
                                    name="status"
                                    className="text-danger"
                                    component="div"
                                  />
                                </div>
                                <div className="col-12">
                                  <label
                                    htmlFor="address"
                                    className="form-label"
                                    style={{ fontSize: "0.9375rem" }}
                                  >
                                    Address:
                                  </label>
                                  <Field
                                    id="address"
                                    name="address"
                                    type="text"
                                    className="form-control"
                                    placeholder="Enter Address"
                                    onChange={handleChange}
                                    as={"textarea"}
                                    rows={2}
                                  />
                                </div>

                                <div className="col-12 text-center mt-4">
                                  <button
                                    type="submit"
                                    className="btn btn-primary me-3"
                                    disabled={submitLoader}
                                  >
                                    {submitLoader && (
                                      <span
                                        className="spinner-border spinner-border-sm me-2"
                                        role="status"
                                        aria-hidden="true"
                                      ></span>
                                    )}{" "}
                                    Add Buyer
                                  </button>
                                  <button
                                    type="button"
                                    className="btn btn-label-secondary"
                                    onClick={closeAddBuyerModal}
                                  >
                                    Cancel
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </Form>
                      )}
                    </Formik>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default AddBuyerModal;
