import * as Yup from "yup";
import { useState } from "react";
import { createPortApi } from "../../services/_port";
import { Link, useNavigate } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { errorToast, successToast } from "../../components/Toast";
import CountryStateCityField from "../other/CountryStateCityField";
import { getCities, getCountries, getStates } from "../../components/Helper";

const createPortInitialValues = {
  name: "",
  country: "",
  state: "",
  city: "",
  status: "",
};

const CreatePort = () => {
  const navigate = useNavigate();
  const [submitLoader, setSubmitLoader] = useState<boolean>(false);
  const countryList = getCountries();
  const [stateList, setStateList] = useState<any>([]);
  const [cityList, setCityList] = useState<any>([]);
  const handleCountryChange = (country: any) => {
    setStateList([]);
    setCityList([]);
    const allStates = getStates(country);
    setStateList(allStates);
  };
  const handleStateChange = (state: any) => {
    setCityList([]);
    const cities = getCities(state);
    setCityList(cities);
  };

  const createPortValidationSchema = Yup.object().shape({
    name: Yup.string().required("Name is required"),
    country: Yup.string(),
    state: Yup.string(),
    city: Yup.string(),
    status: Yup.string().required("Status is required"),
  });

  const handleSubmit = async (values: any, { resetForm }: any) => {
    setSubmitLoader(true);
    values.status = values.status === "true" ? true : false;
    const result: any = await createPortApi(values);
    if (result.isSuccess) {
      setSubmitLoader(false);
      resetForm({ values: createPortInitialValues });
      successToast(result.message);
      navigate("/port-list");
    } else {
      setSubmitLoader(false);
      errorToast(result.message);
    }
    return;
  };

  return (
    <>
      <div className="card">
        <div className="card-header pb-0">
          <div>
            <h5>Create Port</h5>
          </div>
        </div>
        <div className="card-body">
          <Formik
            initialValues={createPortInitialValues}
            validationSchema={createPortValidationSchema}
            onSubmit={handleSubmit}
          >
            {({ values, handleChange, setFieldValue }) => (
              <Form className="dt_adv_search">
                <div className="row">
                  <div className="col-12">
                    <div className="row g-3">
                      <div className="col-12 col-sm-6 col-lg-4">
                        <label
                          htmlFor="name"
                          className="form-label"
                          style={{ fontSize: "0.9375rem" }}
                        >
                          Name: <span className="text-danger">*</span>
                        </label>
                        <Field
                          id="name"
                          name="name"
                          type="text"
                          className="form-control"
                          placeholder="Enter name"
                          onChange={handleChange}
                        />
                        <ErrorMessage
                          name="name"
                          className="text-danger"
                          component="div"
                        />
                      </div>
                      <CountryStateCityField
                        countryList={countryList}
                        stateList={stateList}
                        cityList={cityList}
                        values={values}
                        setFieldValue={setFieldValue}
                        handleCountryChange={handleCountryChange}
                        handleStateChange={handleStateChange}
                      />
                      <div className="col-12 col-sm-6 col-lg-4">
                        <label
                          htmlFor="status"
                          className="form-label"
                          style={{ fontSize: "0.9375rem" }}
                        >
                          Status: <span className="text-danger">*</span>
                        </label>
                        <select
                          id="status"
                          name="status"
                          className="form-select"
                          value={values.status}
                          onChange={handleChange}
                        >
                          <option value="">Select Status</option>
                          <option value="true">Active</option>
                          <option value="false">Inactive</option>
                        </select>
                        <ErrorMessage
                          name="status"
                          className="text-danger"
                          component="div"
                        />
                      </div>
                      <div className="col-12">
                        <button
                          type="submit"
                          className="btn btn-primary"
                          disabled={submitLoader}
                        >
                          {submitLoader && (
                            <span
                              className="spinner-border spinner-border-sm me-2"
                              role="status"
                              aria-hidden="true"
                            ></span>
                          )}
                          Submit
                        </button>
                        <Link
                          to={"/port-list"}
                          type="button"
                          className="btn btn-label-github ms-5"
                        >
                          Back
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </>
  );
};

export default CreatePort;
