/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import moment from "moment";
import Pagination from "../../components/Pagination";
import { getPermissions } from "../../context/AuthContext";
import {
  getSupplierNameListApi,
  supplierListApi,
  updateSupplierStatusApi,
} from "../../services/_supplier";
import ViewSupplier from "./ViewSupplier";
import Spinner from "../../theme/Spinner";
import { Autocomplete, TextField } from "@mui/material";

const SupplierList = () => {
  const permissions = getPermissions();
  const [records, setRecords] = useState([]);
  const [page, setPage] = useState<number>(1);
  const [perPage] = useState<number>(10);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [totalRecords, setTotalRecords] = useState<number>(0);
  const [applyFilter, setApplyFilter] = useState<boolean>(false);
  const [filterName, setFilterName] = useState<string>("");
  const [filterEmail, setFilterEmail] = useState<string>("");
  const [filterPhoneNo, setFilterPhoneNo] = useState<string>("");
  const [filterTanNo, setFilterTanNo] = useState<string>("");
  const [filterGstNo, setFilterGstNo] = useState<string>("");
  const [filterPanNo, setFilterPanNo] = useState<string>("");
  const [filterUdhyamNo, setFilterUdhyamNo] = useState<string>("");
  const [supplierId, setSupplierId] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [displayFilter, setDisplayFilter] = useState(false);
  const [filteredNameSuggestion, setFilteredNameSuggestion] = useState([]);
  const [filteredEmailSuggestion, setFilteredEmailSuggestion] = useState([]);
  const [filteredPhoneNoSuggestion, setFilteredPhoneNoSuggestion] = useState(
    []
  );
  const [filteredPanNoSuggestion, setFilteredPanNoSuggestion] = useState([]);
  const [filteredGStNoSuggestion, setFilteredGstNoSuggestion] = useState([]);
  const [filteredTanNoSuggestion, setFilteredTanNoSuggestion] = useState([]);
  const [filteredUdhyamNoSuggestion, setFilteredUdhyamNoSuggestion] = useState(
    []
  );
  const [userSuggestion, setUserSuggestion] = useState([]);
  const [sortKey, setSortKey] = useState<string>("");
  const [sortType, setSortType] = useState<string>("");

  const toggleFilter = async () => {
    setDisplayFilter(!displayFilter);
  };

  const getSupplierList = async (
    page: number,
    perPage: number,
    isFilter?: boolean,
    sortKey?: string,
    sortType?: string
  ) => {
    setLoading(true);
    let query = `page=${page}&per_page=${perPage}`;
    if (isFilter) {
      if (filterName) {
        query += `&name=${filterName}`;
      }
      if (filterEmail) {
        query += `&email=${filterEmail}`;
      }
      if (filterPhoneNo) {
        query += `&phone_no=${filterPhoneNo}`;
      }
      if (filterTanNo) {
        query += `&tan_no=${filterTanNo}`;
      }
      if (filterGstNo) {
        query += `&gst_no=${filterGstNo}`;
      }
      if (filterPanNo) {
        query += `&pan_no=${filterPanNo}`;
      }
      if (filterUdhyamNo) {
        query += `&udhyam_no=${filterUdhyamNo}`;
      }
      if (sortKey && sortType) {
        query += `&sort_key=${sortKey}&sort_type=${sortType}`;
      }
    }
    const result: any = await supplierListApi(query);
    if (result.isSuccess) {
      const totalPagesCount = Math.ceil(result.data.total_records / perPage);
      setTotalRecords(result.data.total_records);
      setTotalPages(totalPagesCount);
      setRecords(result.data.records);
    }
    setLoading(false);
  };

  const handleAutoCompleteFilter = (
    filterType:
      | "name"
      | "email"
      | "phone_no"
      | "pan_no"
      | "gst_no"
      | "tan_no"
      | "udhyam_no",
    newValue: string | null
  ) => {
    const filterData = userSuggestion.filter((suggestion: any) =>
      suggestion[filterType]
        ?.toLowerCase()
        .includes((newValue || "").toLowerCase())
    );
    if (filterType === "name") {
      setFilterName(newValue || "");
      setFilteredNameSuggestion(filterData);
    }
    if (filterType === "email") {
      setFilterEmail(newValue || "");
      setFilteredEmailSuggestion(filterData);
    }
    if (filterType === "phone_no") {
      setFilterPhoneNo(newValue || "");
      setFilteredPhoneNoSuggestion(filterData);
    }
    if (filterType === "pan_no") {
      setFilterPanNo(newValue || "");
      setFilteredPanNoSuggestion(filterData);
    }
    if (filterType === "gst_no") {
      setFilterGstNo(newValue || "");
      setFilteredGstNoSuggestion(filterData);
    }
    if (filterType === "tan_no") {
      setFilterTanNo(newValue || "");
      setFilteredTanNoSuggestion(filterData);
    }
    if (filterType === "udhyam_no") {
      setFilterUdhyamNo(newValue || "");
      setFilteredUdhyamNoSuggestion(filterData);
    }
  };

  useEffect(() => {
    const fetchSuggestions = async () => {
      const result: any = await getSupplierNameListApi();
      if (result.isSuccess) {
        setFilteredNameSuggestion(result?.data || []);
        setFilteredEmailSuggestion(result?.data || []);
        setFilteredPhoneNoSuggestion(result?.data || []);
        setFilteredPanNoSuggestion(result?.data || []);
        setFilteredGstNoSuggestion(result?.data || []);
        setFilteredTanNoSuggestion(result?.data || []);
        setFilteredUdhyamNoSuggestion(result?.data || []);
        setUserSuggestion(result?.data || "");
      }
    };
    fetchSuggestions();
  }, []);

  const filterList = async () => {
    setApplyFilter(true);
    setFilterName(filterName);
    setFilterEmail(filterEmail);
    setFilterPhoneNo(filterPhoneNo);
    setFilterPanNo(filterPanNo);
    setFilterGstNo(filterGstNo);
    setFilterTanNo(filterTanNo);
    setFilterUdhyamNo(filterUdhyamNo);
    getSupplierList(page, perPage, true);
  };

  const resetList = async () => {
    setPage(1);
    setApplyFilter(false);
    setFilterName("");
    setFilterEmail("");
    setFilterTanNo("");
    setFilterPhoneNo("");
    setFilterGstNo("");
    setFilterPanNo("");
    setFilterUdhyamNo("");
    setFilteredNameSuggestion(userSuggestion);
    setFilteredEmailSuggestion(userSuggestion);
    setFilteredPhoneNoSuggestion(userSuggestion);
    setFilteredPanNoSuggestion(userSuggestion);
    setFilteredGstNoSuggestion(userSuggestion);
    setFilteredTanNoSuggestion(userSuggestion);
    setFilteredUdhyamNoSuggestion(userSuggestion);
    getSupplierList(page, perPage);
  };

  const prevButton = async () => {
    if (page > 1) {
      setPage(page - 1);
    }
  };

  const nextButton = async () => {
    if (totalPages > page) {
      setPage(page + 1);
    }
  };

  const updateSupplierStatus = async (id: string, status: boolean) => {
    setLoading(true);
    await updateSupplierStatusApi({
      id: id,
      status: !status,
    });
    getSupplierList(page, perPage, true);
    setLoading(false);
  };

  const sortTable = async (key: string) => {
    let type = sortType === "asc" ? "desc" : "asc";
    if (sortKey !== key) {
      type = "asc";
    }
    setSortKey(key);
    setSortType(type);
    await getSupplierList(page, perPage, true, key, type);
  };

  useEffect(() => {
    if (page && perPage) {
      getSupplierList(page, perPage, applyFilter);
    }
  }, [page, perPage, applyFilter]);

  return (
    <>
      <div className="card">
        <div className="card-header pb-0 d-flex justify-content-between">
          <div>
            <h5>Supplier List</h5>
          </div>

          <div>
            <Link
              to="#"
              type="button"
              className="btn btn-label-github"
              style={{ marginRight: "15px" }}
              onClick={toggleFilter}
            >
              <span>
                <i className="ti ti-filter me-sm-1"></i>{" "}
                <span className="d-none d-sm-inline-block">Filter</span>
              </span>
            </Link>
            {permissions.includes("create-supplier") && (
              <Link
                className="btn btn-primary waves-effect waves-light"
                type="button"
                to="/create-supplier"
              >
                <span>
                  <i className="ti ti-plus me-sm-1"></i>{" "}
                  <span className="d-none d-sm-inline-block">Add Supplier</span>
                </span>
              </Link>
            )}
          </div>
        </div>

        {displayFilter && (
          <div className="card-body">
            <form className="dt_adv_search">
              <div className="row">
                <div className="col-12">
                  <div className="row g-3">
                    <div className="col-12 col-sm-6 col-lg-3">
                      <label
                        className="form-label"
                        style={{ fontSize: "0.9375rem" }}
                      >
                        Name:
                      </label>
                      <Autocomplete
                        disablePortal
                        options={filteredNameSuggestion.map(
                          (suggestion: any) => suggestion.name
                        )}
                        onChange={(event, newValue) =>
                          handleAutoCompleteFilter("name", newValue)
                        }
                        value={filterName}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            className="form-control"
                            type="text"
                            placeholder="Enter Name"
                            value={filterName}
                            onChange={(e) => setFilterName(e.target.value)}
                          />
                        )}
                      />
                    </div>
                    <div className="col-12 col-sm-6 col-lg-3">
                      <label
                        className="form-label"
                        style={{ fontSize: "0.9375rem" }}
                      >
                        Email:
                      </label>
                      <Autocomplete
                        disablePortal
                        options={filteredEmailSuggestion.map(
                          (suggestion: any) => suggestion.email || ""
                        )}
                        onChange={(event, newValue) =>
                          handleAutoCompleteFilter("email", newValue)
                        }
                        value={filterEmail}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            className="form-control"
                            type="text"
                            placeholder="Enter Email"
                            value={filterEmail}
                            onChange={(e) => setFilterEmail(e.target.value)}
                          />
                        )}
                      />
                    </div>
                    <div className="col-12 col-sm-6 col-lg-3">
                      <label
                        className="form-label"
                        style={{ fontSize: "0.9375rem" }}
                      >
                        Phone No:
                      </label>
                      <Autocomplete
                        disablePortal
                        options={filteredPhoneNoSuggestion
                          .filter(
                            (suggestion: any) => suggestion.phone_no || ""
                          )
                          .map((suggestion: any) => suggestion.phone_no || "")}
                        onChange={(event, newValue) =>
                          handleAutoCompleteFilter("phone_no", newValue)
                        }
                        value={filterPhoneNo}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            className="form-control"
                            type="text"
                            placeholder="Enter Phone No."
                            value={filterPhoneNo}
                            onChange={(e) => setFilterPhoneNo(e.target.value)}
                          />
                        )}
                      />
                    </div>
                    <div className="col-12 col-sm-6 col-lg-3">
                      <label
                        className="form-label"
                        style={{ fontSize: "0.9375rem" }}
                      >
                        PAN No:
                      </label>
                      <Autocomplete
                        disablePortal
                        options={filteredPanNoSuggestion
                          .filter((suggestion: any) => suggestion.pan_no || "")
                          .map((suggestion: any) => suggestion.pan_no || "")}
                        onChange={(event, newValue) =>
                          handleAutoCompleteFilter("pan_no", newValue)
                        }
                        value={filterPanNo}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            className="form-control"
                            type="text"
                            placeholder="Enter Pan No."
                            value={filterPanNo}
                            onChange={(e) => setFilterPanNo(e.target.value)}
                          />
                        )}
                      />
                    </div>
                    <div className="col-12 col-sm-6 col-lg-3">
                      <label
                        className="form-label"
                        style={{ fontSize: "0.9375rem" }}
                      >
                        GST No:
                      </label>
                      <Autocomplete
                        disablePortal
                        options={filteredGStNoSuggestion
                          .filter((suggestion: any) => suggestion.gst_no || "")
                          .map((suggestion: any) => suggestion.gst_no || "")}
                        onChange={(event, newValue) =>
                          handleAutoCompleteFilter("gst_no", newValue)
                        }
                        value={filterGstNo}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            className="form-control"
                            type="text"
                            placeholder="Enter GST No."
                            value={filterGstNo}
                            onChange={(e) => setFilterGstNo(e.target.value)}
                          />
                        )}
                      />
                    </div>
                    <div className="col-12 col-sm-6 col-lg-3">
                      <label
                        className="form-label"
                        style={{ fontSize: "0.9375rem" }}
                      >
                        TAN No:
                      </label>
                      <Autocomplete
                        disablePortal
                        options={filteredTanNoSuggestion
                          .filter((suggestion: any) => suggestion.tan_no || "")
                          .map((suggestion: any) => suggestion.tan_no || "")}
                        onChange={(event, newValue) =>
                          handleAutoCompleteFilter("tan_no", newValue)
                        }
                        value={filterTanNo}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            className="form-control"
                            type="text"
                            placeholder="Enter Tan No."
                            value={filterTanNo}
                            onChange={(e) => setFilterTanNo(e.target.value)}
                          />
                        )}
                      />
                    </div>
                    <div className="col-12 col-sm-6 col-lg-3">
                      <label
                        className="form-label"
                        style={{ fontSize: "0.9375rem" }}
                      >
                        UDHYAM No:
                      </label>
                      <Autocomplete
                        disablePortal
                        options={filteredUdhyamNoSuggestion
                          .filter(
                            (suggestion: any) => suggestion.udhyam_no || ""
                          )
                          .map((suggestion: any) => suggestion.udhyam_no || "")}
                        onChange={(event, newValue) =>
                          handleAutoCompleteFilter("udhyam_no", newValue)
                        }
                        value={filterUdhyamNo}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            className="form-control"
                            type="text"
                            placeholder="Enter UDHYAM No."
                            value={filterUdhyamNo}
                            onChange={(e) => setFilterUdhyamNo(e.target.value)}
                          />
                        )}
                      />
                    </div>
                    <div className="col-12">
                      <button
                        type="button"
                        className="btn btn-primary"
                        onClick={filterList}
                      >
                        Filter
                      </button>
                      <button
                        type="button"
                        className="btn btn-label-github ms-5"
                        onClick={resetList}
                      >
                        Reset
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        )}

        <div className="card-datatable table-responsive">
          {loading && <Spinner isFullScreen={false} />}
          <table className="dt-advanced-search table table-nowrap">
            <thead>
              <tr>
                <th>#</th>
                <th
                  className="cursor-pointer"
                  onClick={() => sortTable("name")}
                >
                  Name
                  <span>
                    <i className="ti ti-arrows-down-up sorting-icon"></i>
                  </span>
                </th>
                <th
                  className="cursor-pointer"
                  onClick={() => sortTable("email")}
                >
                  Email
                  <span>
                    <i className="ti ti-arrows-down-up sorting-icon"></i>
                  </span>
                </th>
                <th
                  className="cursor-pointer"
                  onClick={() => sortTable("phone_no")}
                >
                  Phone No
                  <span>
                    <i className="ti ti-arrows-down-up sorting-icon"></i>
                  </span>
                </th>
                <th
                  className="cursor-pointer"
                  onClick={() => sortTable("pan_no")}
                >
                  Pan No
                  <span>
                    <i className="ti ti-arrows-down-up sorting-icon"></i>
                  </span>
                </th>
                <th
                  className="cursor-pointer"
                  onClick={() => sortTable("gst_no")}
                >
                  Gst No
                  <span>
                    <i className="ti ti-arrows-down-up sorting-icon"></i>
                  </span>
                </th>
                <th
                  className="cursor-pointer"
                  onClick={() => sortTable("tan_no")}
                >
                  Tan No
                  <span>
                    <i className="ti ti-arrows-down-up sorting-icon"></i>
                  </span>
                </th>
                <th
                  className="cursor-pointer"
                  onClick={() => sortTable("udhyam_no")}
                >
                  Udhyam No
                  <span>
                    <i className="ti ti-arrows-down-up sorting-icon"></i>
                  </span>
                </th>
                {permissions.includes("update-supplier") && (
                  <th
                    className="cursor-pointer"
                    onClick={() => sortTable("status")}
                  >
                    Status
                    <span>
                      <i className="ti ti-arrows-down-up sorting-icon"></i>
                    </span>
                  </th>
                )}
                <th
                  className="cursor-pointer"
                  onClick={() => sortTable("created_at")}
                >
                  Created At
                  <span>
                    <i className="ti ti-arrows-down-up sorting-icon"></i>
                  </span>
                </th>
                {permissions.includes("update-supplier") && <th>Action</th>}
              </tr>
            </thead>
            <tbody>
              {records.length > 0 ? (
                records.map((item: any, i: number) => (
                  <tr key={item.id}>
                    <td>{(page - 1) * perPage + 1 + i}</td>
                    <td>
                      {
                        <Link
                          to={"#"}
                          onClick={() => {
                            setSupplierId(item.id);
                          }}
                          title="View Product Details"
                          data-bs-toggle="modal"
                          data-bs-target="#viewProduct"
                        >
                          {item.name}
                        </Link>
                      }
                    </td>
                    <td>{item.email}</td>
                    <td>{item.phone_no}</td>
                    <td>{item.pan_no}</td>
                    <td>{item.gst_no}</td>
                    <td>{item.tan_no}</td>
                    <td>{item.udhyam_no}</td>
                    {permissions.includes("update-supplier") && (
                      <td>
                        <div className="form-check form-switch">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            id="status"
                            defaultChecked={item.status ? true : false}
                            onClick={() => {
                              updateSupplierStatus(item.id, item.status);
                            }}
                          />
                        </div>
                      </td>
                    )}
                    <td>
                      {moment(item.created_at).format("YYYY-MM-DD HH:mm:ss")}
                    </td>
                    {permissions.includes("update-supplier") && (
                      <td>
                        <>
                          <Link
                            to={`/edit-supplier/${item.id}`}
                            className="btn btn-sm btn-text-secondary rounded-pill btn-icon"
                            title="Edit supplier"
                          >
                            <i className="ti ti-pencil ti-md"></i>
                          </Link>
                        </>
                      </td>
                    )}
                  </tr>
                ))
              ) : (
                <tr>
                  <td
                    colSpan={permissions.includes("update-supplier") ? 11 : 9}
                    className="text-center"
                  >
                    No records found
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>

        <Pagination
          page={page}
          perPage={perPage}
          totalRecords={totalRecords}
          totalPages={totalPages}
          records={records}
          prevButton={prevButton}
          nextButton={nextButton}
        />

        <ViewSupplier supplierId={supplierId} />
      </div>
    </>
  );
};

export default SupplierList;
