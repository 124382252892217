import {
  Document,
  Page,
  Text,
  View,
  Font,
  Image,
  StyleSheet,
  PDFViewer,
} from "@react-pdf/renderer";
import moment from "moment";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import {
  convertAmountToWords,
  formatAmount,
  stringToUpperCase,
} from "../components/Helper";
import { CURRENCY } from "../components/Constants";

Font.register({
  family: "HostGrotesk",
  fonts: [
    { src: "/fonts/HostGrotesk-Regular.ttf" },
    { src: "/fonts/HostGrotesk-Bold.ttf", fontWeight: "bold" },
  ],
});

export const styles = StyleSheet.create({
  boldText: {
    fontWeight: "bold",
    fontSize: 8,
  },
  borderTop: {
    borderTopWidth: 1,
    borderTopColor: "black",
    borderTopStyle: "solid",
  },
  borderBottom: {
    borderBottomWidth: 1,
    borderBottomColor: "black",
    borderbottomStyle: "solid",
  },
  smallText: {
    fontSize: 6,
  },
  mediumText: {
    fontSize: 6.5,
  },
  contentPadding: {
    padding: 1,
  },
  purchaseDetails: {
    fontSize: 7,
  },
  page: {
    paddingHorizontal: 30,
    paddingVertical: 15,
    fontFamily: "HostGrotesk",
    backgroundColor: "white",
  },
  fullpage: {
    margin: 7,
    borderStyle: "solid",
    borderWidth: 1,
    borderColor: "#000000",
  },
  header: {
    display: "flex",
    justifyContent: "flex-start",
    padding: 1,
    fontWeight: "bold",
    borderBottomWidth: 1,
    borderBottomColor: "black",
    borderBottomStyle: "solid",
  },
  subheader: {
    display: "flex",
    fontSize: 10,
    textAlign: "center",
    padding: 1,
    marginBottom: 0,
    marginTop: 10,
    fontWeight: "bold",
  },
  subheaderText: {
    marginTop: 5,
    display: "flex",
    justifyContent: "center",
    borderBottomWidth: 1,
    borderBottomColor: "black",
    borderBottomStyle: "solid",
  },

  section: {
    borderBottomWidth: 1,
    borderBottomColor: "black",
    borderbottomStyle: "solid",
  },
  sideBySideContainer: { flexDirection: "row" },
  halfWidthRightSection: {
    width: "50%",
  },
  halfWidthleftSection: {
    width: "50%",
    borderLeftWidth: 1,
    borderBottomColor: "black",
    borderbottomStyle: "solid",
  },
  tableContainer: {
    borderBottomWidth: 1,
    borderBottomColor: "black",
  },
  tableRow: {
    flexDirection: "row",
    flexGrow: 1,
    alignItems: "stretch",
  },
  tableCell: {
    padding: 5,
    fontSize: 7,
    textAlign: "center",
    borderRightWidth: 1,
    borderRightColor: "black",
    borderRightStyle: "solid",
  },
  expandableDescriptionCell: {
    flex: 8,
    flexDirection: "column",
    alignItems: "flex-start",
    textAlign: "left",
  },
  descriptionText: {
    alignSelf: "flex-start",
    fontSize: 6.5,
    flexWrap: "wrap",
    paddingVertical: 1,
  },
  emptyTableRow: {
    minHeight: 50,
  },
  thirdWidthSection: {
    flex: 1,
    height: 100,
    padding: 2,
    borderTopWidth: 1,
    borderTopColor: "black",
    borderTopStyle: "solid",
    borderLeftWidth: 1,
    borderLeftColor: "black",
    borderLeftStyle: "solid",
  },
  signatoryContainer: {
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "center",
  },
  addressBlock: { fontSize: 8, marginBottom: 1.5, paddingHorizontal: 3 },
  subComponentHeading: {
    paddingHorizontal: 3,
    fontSize: 8,
    marginBottom: 1.5,
    borderBottomWidth: 1,
    borderBottomColor: "black",
    borderbottomStyle: "solid",
  },
  subComponentText: {
    fontSize: 7,
    marginBottom: 1.5,
    fontWeight: "bold",
  },
  footer: {
    display: "flex",
    justifyContent: "center",
    textAlign: "center",
    fontSize: 8,
    marginTop: -120,
  },
  logo: {
    height: "60px",
    width: "60px",
  },
  footerInline: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
  },
  footerLogo: {
    left: 0,
    right: 0,
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    borderTopWidth: 1,
    top: -15,
    border: 0,
  },
});

const SalesContract = () => {
  const salesContractData: any = {
    id: "420756ae-4a8f-4578-8a1b-374a6746475a",
    contract_no: "SLC-001",
    reference_no: "REF-001",
    buyer_id: "a7ac2f00-efa6-4d02-a96b-6d1f25ca88d0",
    broker_id: "4019ebac-b25f-44d3-85cd-fda49c7c178b",
    product_id: "ecad30c5-0125-4233-bfef-09cb47b821ea",
    currency: "USD",
    price: "100",
    quantity: "10",
    quantity_unit_id: "2d2d01eb-50d3-49d3-82c0-733402ef4b4b",
    total_amount: "1000",
    container_load: "20X 40’ FCL",
    packing_type_id: "213b116d-f9be-4cba-834a-763c81e8352b",
    packing_type: {
      name: "Plain PP Bags",
    },
    packing_net_weight: "50",
    packing_gross_weight: "51",
    packing_unit_id: "3a3c438d-832c-4e77-ae05-47d6bb9e0e08",
    packing_unit: {
      name: "KG",
    },
    exporter_bank_id: "f9a0044a-66ed-47e0-b632-4fc2f5babf52",
    exporter_bank: {
      id: "f9a0044a-66ed-47e0-b632-4fc2f5babf52",
      bank_name: "ICICI BANK LTD",
      account_number: "348705500409",
      ifsc_code: "IFSC1CODESP",
      branch_name: "My Branch 1",
      swift_code: "ICICINBBCTS",
      address: "BHUJ-ATMARAM CIRCLE BRANCH",
      ad_code: "6393867-5860006",
    },
    buyer_bank_id: "2f5cc7ff-d1e9-4e45-9957-0f84faf26c32",
    buyer_bank: {
      id: "2f5cc7ff-d1e9-4e45-9957-0f84faf26c32",
      bank_name: "MY BANK LTD",
      account_number: "586952656895",
      ifsc_code: "IFSC2GTDEDE",
      branch_name: "BRANCH 2",
      swift_code: "MYBANK01250",
      address: "STAR HOTEL BRANCH",
      ad_code: null,
    },
    marking: "Marking-001",
    marking_files: [
      "/uploads/annx-le-019_1735541388848.pdf",
      "/uploads/ci-le-036_1735541561163.pdf",
    ],
    final_destination: "Vietnam",
    port_of_loading: "Any port of India",
    port_of_discharge: "30 FCL CIF HCMC & 10 FCL HAIPHONG",
    shipment: "SEPTEMBER 2024",
    trans_shipment: "ALLOWED",
    partial_shipment: "ALLOWED",
    payment_terms: "100 % TT",
    weight_and_quality:
      "TOLERANCE IN WEIGHT UPTO FIVE PERCENT (5%) MORE OR LESS ALLOWED",
    arbitration:
      "ABOVE CONTRACT AS PER FOSFA NO. 13, CONTRACT TERMS & CONDITION & APPLICABLE FOR THIS. COMMODITY AS ONDATE.",
    is_digital_signature: true,
    status: "PENDING",
    contract_date: "2024-11-30T00:00:00.000Z",
    created_at: "2024-12-11T12:46:47.980Z",
    created_by_id: null,
    buyer: {
      id: "a7ac2f00-efa6-4d02-a96b-6d1f25ca88d0",
      name: "Buyer 1",
    },
    quantity_unit: {
      name: "MT",
    },
    broker: {
      id: "4019ebac-b25f-44d3-85cd-fda49c7c178b",
      name: "Broker 1",
    },
    product: {
      id: "ecad30c5-0125-4233-bfef-09cb47b821ea",
      name: "Indian Swarna Brown Rice",
    },
    quality_specifications: [
      {
        quality_specification_id: "f649d98b-1cc1-450f-8bd8-371d965c8fa8",
        value: "14% MAX",
        quality_specification: {
          name: "MOISTURE",
        },
      },
      {
        quality_specification_id: "56814dd4-e799-43e0-a61b-1145eb1d3635",
        value: "BROKEN (2/3)",
        quality_specification: {
          name: "BROKEN (2/3)",
        },
      },
      {
        quality_specification_id: "aa34cae9-4180-4f13-873b-7eee52ca3667",
        value: "5.3 MAX",
        quality_specification: {
          name: "AVERAGE LENGTH",
        },
      },
      {
        quality_specification_id: "88a61df8-71ec-45da-9a63-194ccb9b76ec",
        value: "0.2% MAX",
        quality_specification: {
          name: "FOREIGN MATTER",
        },
      },
      {
        quality_specification_id: "ceddb0c2-4956-4d58-89db-00e8adb92cce",
        value: "6% MAX",
        quality_specification: {
          name: "GREEN GRAINS",
        },
      },
      {
        quality_specification_id: "01238705-0ca4-4d41-9965-60b7c28673af",
        value: "2% MAX",
        quality_specification: {
          name: "DAMAGE & DISCOLORED & DOTTED",
        },
      },
      {
        quality_specification_id: "94015224-b10d-455b-942c-0c10d2fc4cd4",
        value: "YES",
        quality_specification: {
          name: "FREE OF MOLDY, PADDY AND WEEVIL",
        },
      },
    ],
    documents: [
      {
        document_id: "6bf77199-21ca-46b3-922e-06222cb4c60e",
        value: "1 ORIGINAL (AS PER VIETNAM QURANTINE REQUIREMENT)",
        document: {
          name: "PHYTOSANITARY CERTIFICATE",
        },
      },
      {
        document_id: "6c98e5c7-3e5c-498f-8a93-cd709b44cb4c",
        value: "3 ORIGINALS (SIGNED AND SEALED)",
        document: {
          name: "COMMERCIAL INVOICE",
        },
      },
      {
        document_id: "55fbc455-5a4c-49ef-b2d5-7d11781a66f0",
        value: "FULL SET OF BILLS OF LADING – TRIPLICATE",
        document: {
          name: "BILL OF LADING",
        },
      },
      {
        document_id: "4341e6ab-0aba-4711-a181-50b37a076d3f",
        value:
          "1 ORIGINAL (ASEAN – INDIA FREE TRADE AREA PREFERENTIAL TARIFF CERTIFICATEOF ORIGIN)",
        document: {
          name: "CERTIFICATE OF ORIGIN",
        },
      },
      {
        document_id: "fde528fd-c82d-4fe8-bc29-7a401db62b3b",
        value: "3 ORIGINALS (SIGNED AND SEALED)",
        document: {
          name: "PACKING LIST",
        },
      },
      {
        document_id: "bdd4bf74-a6e7-47cf-9a0b-ebf9fd5f44d1",
        value: "1 ORIGINAL (AS PER VIETNAM QURANTINE REQUIREMENT)",
        document: {
          name: "FUMIGATION CERTIFICATE",
        },
      },
      {
        document_id: "f9ef2f86-7c44-4eeb-8557-0af691f8f7a0",
        value: "1 ORIGINAL (BY INDEPENDENT SURVAYOR)",
        document: {
          name: "WEIGHT & QUALITY CERTIFICATE",
        },
      },
      {
        document_id: "ef4174c1-b3fa-4fd5-9767-482143e5fc02",
        value: "1 ORIGINAL",
        document: {
          name: "INSURANCE CERTIFICATE",
        },
      },
    ],
  };
  const qualitySpecifications = salesContractData.quality_specifications || [];
  const middleIndex = Math.ceil(qualitySpecifications.length / 2);

  const qsLeftData = qualitySpecifications.slice(0, middleIndex);
  const qsRightData = qualitySpecifications.slice(middleIndex);

  return (
    <PDFViewer width="100%" height="2000px">
      <Document>
        <Page style={styles.page} size="A4">
          <View style={styles.header}>
            <Image src="/img/logo/ee-main.png" style={styles.logo} />
          </View>
          <View style={styles.header}>
            <Text style={[styles.addressBlock, { fontSize: 9 }]}>
              Start Export House And Authorized Economic Operator, Govt.Of India
            </Text>
          </View>
          <View style={[styles.subheader]}>
            <Text
              style={[
                styles.borderBottom,
                {
                  width: "20%",
                  margin: "auto",
                },
              ]}
            >
              SALES CONTRACT
            </Text>
          </View>

          {/* Shipper/Exporter & Buyer/Consignee Details */}
          <View style={styles.fullpage}>
            <View style={styles.sideBySideContainer}>
              <View
                style={[styles.halfWidthRightSection, styles.contentPadding]}
              >
                <Text style={styles.subComponentHeading}>
                  <Text style={styles.boldText}>DATE : </Text>
                  {moment(salesContractData?.contract_date).format(
                    "DD/MM/YYYY"
                  )}
                </Text>
                <Text
                  style={[
                    styles.borderBottom,
                    styles.addressBlock,
                    {
                      width: "33%",
                    },
                  ]}
                >
                  SHIPPER EXPORTER :
                </Text>
                <Text
                  style={[
                    styles.boldText,
                    { fontSize: 9, paddingHorizontal: 3 },
                  ]}
                >
                  MS LAXMI ENTERPRISE
                </Text>

                <Text style={styles.addressBlock}>MS LAXMI ENTERPRISE</Text>
              </View>

              <View
                style={[styles.halfWidthleftSection, styles.contentPadding]}
              >
                <Text style={styles.subComponentHeading}>
                  <Text style={styles.boldText}>CONTRACT NO :</Text>
                  SC/2425/LE26 (REF-001)
                </Text>
                <Text
                  style={[
                    styles.borderBottom,
                    styles.addressBlock,
                    {
                      width: "35%",
                    },
                  ]}
                >
                  BUYER / CONSIGNEE :
                </Text>
                <Text
                  style={[
                    styles.boldText,
                    { fontSize: 9, paddingHorizontal: 3 },
                  ]}
                >
                  PENTAGON GROUP JOINT STOCK COMPANY
                </Text>
                <Text style={styles.addressBlock}>
                  4TH FLOOR, TRUNGYEN PLAZA, NO.1, TRUNGHOA STREET, TRUNGHOA
                  WARD, CAU GIAY DITRICT, HANOI, VIETNAM.
                </Text>
              </View>
            </View>
          </View>

          {/* Particulars & Shipment Term */}
          <View style={styles.fullpage}>
            <View style={styles.sideBySideContainer}>
              <View
                style={[styles.halfWidthRightSection, styles.contentPadding]}
              >
                <Text style={styles.subComponentHeading}>
                  <Text style={styles.boldText}>PARTICULAR : </Text>
                </Text>
                <Text style={styles.addressBlock}>
                  <Text style={styles.boldText}>COMMODITY : </Text>
                  {stringToUpperCase(salesContractData?.product?.name)}
                </Text>
                <Text style={styles.addressBlock}>
                  <Text style={styles.boldText}>QUANTITY : </Text>
                  {formatAmount(salesContractData.quantity)}{" "}
                  {stringToUpperCase(salesContractData?.quantity_unit?.name)}{" "}
                  {salesContractData?.container_load
                    ? `(${salesContractData?.container_load})`
                    : ""}
                </Text>
                <Text style={styles.addressBlock}>
                  <Text style={styles.boldText}>PRICE : </Text>
                  {CURRENCY.USD.NAME} {formatAmount(salesContractData.price)}
                  {salesContractData?.quantity_unit?.name
                    ? `/${stringToUpperCase(
                        salesContractData?.quantity_unit?.name
                      )}`
                    : ""}
                </Text>
                <Text style={styles.addressBlock}>
                  <Text style={styles.boldText}>VALUE : </Text>
                  {CURRENCY.USD.NAME}{" "}
                  {formatAmount(salesContractData?.total_amount)}
                </Text>
                <Text style={styles.addressBlock}>
                  <Text style={styles.boldText}>VALUE IN WORDS : </Text>
                  {convertAmountToWords(
                    Number(salesContractData?.total_amount),
                    CURRENCY.USD.NAME
                  )}
                </Text>
                <Text style={styles.addressBlock}>
                  <Text style={styles.boldText}>PACKING : </Text>
                  NET {salesContractData.packing_net_weight}
                  {stringToUpperCase(
                    salesContractData?.packing_unit?.name
                  )}{" "}
                  {stringToUpperCase(salesContractData?.packing_type?.name)}
                </Text>
                <Text style={styles.addressBlock}>
                  <Text style={styles.boldText}>MARKING : </Text>
                  {stringToUpperCase(salesContractData?.marking)}
                </Text>
              </View>

              <View
                style={[styles.halfWidthleftSection, styles.contentPadding]}
              >
                <Text style={styles.subComponentHeading}>
                  <Text style={styles.boldText}>SHIPMENT TERM : </Text>
                </Text>
                <Text style={styles.addressBlock}>
                  <Text style={styles.boldText}>FINAL DESTINATION : </Text>
                  {stringToUpperCase(salesContractData?.final_destination)}
                </Text>
                <Text style={styles.addressBlock}>
                  <Text style={styles.boldText}>PORT OF LOADING : </Text>
                  {stringToUpperCase(salesContractData?.port_of_loading)}
                </Text>
                <Text style={styles.addressBlock}>
                  <Text style={styles.boldText}>PORT OF DISCHARGE : </Text>
                  {stringToUpperCase(salesContractData?.port_of_discharge)}
                </Text>
                <Text style={styles.addressBlock}>
                  <Text style={styles.boldText}>SHIPMENT : </Text>
                  {stringToUpperCase(salesContractData?.shipment)}
                </Text>
                {salesContractData.trans_shipment && (
                  <Text style={styles.addressBlock}>
                    <Text style={styles.boldText}>TRANS SHIPMENT : </Text>
                    {stringToUpperCase(salesContractData?.trans_shipment)}
                  </Text>
                )}
                {salesContractData.partial_shipment && (
                  <Text style={styles.addressBlock}>
                    <Text style={styles.boldText}>PARTIAL SHIPMENT : </Text>
                    {stringToUpperCase(salesContractData?.partial_shipment)}
                  </Text>
                )}
                <Text style={styles.addressBlock}>
                  <Text style={styles.boldText}>PAYMENT TERMS : </Text>
                  {stringToUpperCase(salesContractData?.payment_terms)}
                </Text>
              </View>
            </View>
          </View>

          {/* Quality Specifications */}
          {salesContractData.quality_specifications.length > 0 && (
            <View style={styles.fullpage}>
              <Text style={[styles.subComponentHeading]}>
                <Text style={[styles.boldText]}>QUALITY SPECIFICATION :</Text>
              </Text>
              <View style={styles.sideBySideContainer}>
                <View
                  style={[styles.halfWidthRightSection, styles.contentPadding]}
                >
                  {qsLeftData.map((element: any, index: number) => {
                    return (
                      <Text style={styles.addressBlock} key={index}>
                        <Text style={styles.boldText}>
                          {index + 1}.{" "}
                          {stringToUpperCase(
                            element?.quality_specification?.name
                          )}{" "}
                          :
                        </Text>{" "}
                        {stringToUpperCase(element?.value)}
                      </Text>
                    );
                  })}
                </View>

                {qsRightData && (
                  <View
                    style={[styles.halfWidthleftSection, styles.contentPadding]}
                  >
                    {qsRightData.map((element: any, index: number) => {
                      return (
                        <Text style={styles.addressBlock} key={index}>
                          <Text style={styles.boldText}>
                            {index + 1 + middleIndex}.{" "}
                            {stringToUpperCase(
                              element?.quality_specification?.name
                            )}{" "}
                            :
                          </Text>{" "}
                          {stringToUpperCase(element?.value)}
                        </Text>
                      );
                    })}
                  </View>
                )}
              </View>
            </View>
          )}

          <View style={styles.fullpage}>
            {salesContractData.documents?.length > 0 && (
              <>
                <Text break style={[styles.subComponentHeading]}>
                  <Text style={styles.boldText}>REQUIRED DOCUMENTS :</Text>
                </Text>
                <View style={[styles.contentPadding]}>
                  {salesContractData.documents.map(
                    (element: any, index: number) => {
                      return (
                        <Text style={styles.addressBlock} key={index}>
                          <Text style={styles.boldText}>
                            {index + 1}.{" "}
                            {stringToUpperCase(element?.document?.name)} :
                          </Text>{" "}
                          {stringToUpperCase(element?.value)}
                        </Text>
                      );
                    }
                  )}
                </View>
              </>
            )}
          </View>
          <View style={styles.fullpage}>
            {(salesContractData?.exporter_bank ||
              salesContractData?.buyer_bank) && (
              <View style={[styles.contentPadding]}>
                <View break style={styles.sideBySideContainer}>
                  {salesContractData?.exporter_bank && (
                    <View
                      style={[
                        styles.halfWidthRightSection,
                        styles.contentPadding,
                      ]}
                    >
                      <Text
                        style={[
                          styles.borderBottom,
                          styles.addressBlock,
                          styles.boldText,
                          styles.contentPadding,
                          {
                            width: "39%",
                          },
                        ]}
                      >
                        EXPORTER BANK DETAILS :
                      </Text>
                      <Text style={styles.addressBlock}>
                        <Text>BANK NAME :</Text>{" "}
                        {stringToUpperCase(
                          salesContractData?.exporter_bank?.bank_name
                        )}
                      </Text>
                      <Text style={styles.addressBlock}>
                        <Text>ADDRESS : </Text>{" "}
                        {stringToUpperCase(
                          salesContractData?.exporter_bank?.address
                        )}
                      </Text>
                      <Text style={styles.addressBlock}>
                        <Text>OUR A/C NO : </Text>{" "}
                        {stringToUpperCase(
                          salesContractData?.exporter_bank?.account_number
                        )}
                      </Text>
                      <Text style={styles.addressBlock}>
                        <Text>SWIFT CODE : </Text>{" "}
                        {stringToUpperCase(
                          salesContractData?.exporter_bank?.swift_code
                        )}
                      </Text>
                    </View>
                  )}

                  {salesContractData?.buyer_bank && (
                    <View
                      style={[
                        styles.halfWidthleftSection,
                        styles.contentPadding,
                        {
                          borderLeftWidth: salesContractData?.exporter_bank
                            ? 1
                            : 0,
                        },
                      ]}
                    >
                      <Text
                        style={[
                          styles.borderBottom,
                          styles.addressBlock,
                          styles.boldText,
                          styles.contentPadding,
                          {
                            width: "33%",
                          },
                        ]}
                      >
                        BUYER BANK DETAILS :
                      </Text>
                      <Text style={styles.addressBlock}>
                        <Text>BANK NAME :</Text>{" "}
                        {stringToUpperCase(
                          salesContractData?.buyer_bank?.bank_name
                        )}
                      </Text>
                      <Text style={styles.addressBlock}>
                        <Text>ADDRESS : </Text>{" "}
                        {stringToUpperCase(
                          salesContractData?.buyer_bank?.address
                        )}
                      </Text>
                      <Text style={styles.addressBlock}>
                        <Text>OUR A/C NO : </Text>{" "}
                        {stringToUpperCase(
                          salesContractData?.buyer_bank?.account_number
                        )}
                      </Text>
                      <Text style={styles.addressBlock}>
                        <Text>SWIFT CODE : </Text>{" "}
                        {stringToUpperCase(
                          salesContractData?.buyer_bank?.swift_code
                        )}
                      </Text>
                    </View>
                  )}
                </View>
              </View>
            )}
          </View>

          <View style={styles.fullpage}>
            <Text break style={[styles.subComponentHeading]}>
              <Text style={styles.boldText}>GENERAL TERMS :</Text>
            </Text>

            <View style={[styles.contentPadding]}>
              <Text style={styles.addressBlock}>
                ALL THE DOCUMENT SHALL BE PREPARED IN ENGLISH LANGUAGE
              </Text>
              <Text style={styles.addressBlock}>
                ALL THE DOCUMENT SHALL BE PREPARED IN ENGLISH LANGUAGE
              </Text>
              <Text style={styles.addressBlock}>
                ALL THE DOCUMENT SHALL BE PREPARED IN ENGLISH LANGUAGE
              </Text>
              <Text style={styles.addressBlock}>
                ALL THE DOCUMENT SHALL BE PREPARED IN ENGLISH LANGUAGE
              </Text>
              <Text style={styles.addressBlock}>
                ALL THE DOCUMENT SHALL BE PREPARED IN ENGLISH LANGUAGE
              </Text>
            </View>
          </View>

          <View style={[styles.footerLogo]}>
            {/* Shipper Section */}
            <View
              style={[
                {
                  width: "45%",
                  marginBottom: 5,
                  height: 180,
                },
              ]}
            >
              <View style={{ alignItems: "flex-start" }}>
                <View
                  style={{
                    height: 100,
                    justifyContent: "center",
                  }}
                >
                  <Image
                    break
                    src="/img/logo/ee-main.png"
                    style={{ width: 60, height: 60 }}
                  />
                  <Text
                    style={[
                      styles.addressBlock,
                      { textAlign: "center", marginTop: 5 },
                    ]}
                  >
                    SHIPPER / EXPORTER
                  </Text>
                </View>
              </View>
            </View>

            {/* Buyer Section */}
            <View
              style={[
                {
                  width: "45%",
                  marginBottom: 5,
                  height: 180,
                },
              ]}
            >
              <View style={{ alignItems: "flex-end" }}>
                <View
                  style={{
                    height: 100,
                    justifyContent: "center",
                    flexShrink: 0,
                  }}
                >
                  <Image
                    break
                    src="/img/logo/ee-main.png"
                    style={{ width: 60, height: 60 }}
                  />
                  <Text
                    style={[
                      styles.addressBlock,
                      { textAlign: "center", marginTop: 5 },
                    ]}
                  >
                    BUYER / IMPORTER
                  </Text>
                </View>
              </View>
            </View>
          </View>

          <View style={styles.footer}>
            <Text style={[styles.boldText]}>MS LAXMI ENTERPRISE</Text>
            <Text style={[styles.addressBlock]}>
              PLOT NO. 612, NAGOR ROAD, GIDC, BHUJ-KACHCHH, GUJARAT, 370001
              (INDIA)
            </Text>
            <View style={styles.footerInline}>
              <Text style={[styles.addressBlock]}>
                <Text style={styles.boldText}>Email : </Text>
                laxmienterprise@gmail.com
              </Text>
              <Text style={[styles.addressBlock, { marginLeft: 5 }]}>
                <Text style={styles.boldText}>Phone No. : </Text>
                7777995599 / 7777994499
              </Text>
            </View>
            <View style={styles.footerInline}>
              <Text style={[styles.addressBlock]}>
                <Text style={styles.boldText}>Subject : </Text>
                Subject to Bhuj Jurisdisction
              </Text>
              <Text style={[styles.addressBlock, { marginLeft: 5 }]}>
                <Text style={styles.boldText}>GSTIN No. : </Text>
                24AALFM4290M1ZU
              </Text>
            </View>
          </View>
        </Page>
      </Document>
    </PDFViewer>
  );
};

export default SalesContract;
