import { DatePicker } from "rsuite";
import { formatString } from "../../../../components/Helper";
import { SalesInvoiceStatus } from "../../../../components/Enums";
import { ErrorMessage, Field, FormikErrors, FormikValues } from "formik";
import "rsuite/DateRangePicker/styles/index.css";

interface BasicFieldsProps {
  salesContractList: any;
  buyerList: any;
  logisticList: any;
  chaList: any;
  setAddLogisticModalStatus: (show: boolean) => void;
  setAddChaModalStatus: (show: boolean) => void;
  values: FormikValues;
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean
  ) => Promise<void | FormikErrors<FormikValues>>;
  handleChange: (e: React.ChangeEvent<any>) => void;
  handleSalesContractChange: any;
}

const BasicFields: React.FC<BasicFieldsProps> = ({
  salesContractList,
  buyerList,
  logisticList,
  chaList,
  setAddLogisticModalStatus,
  setAddChaModalStatus,
  values,
  setFieldValue,
  handleChange,
  handleSalesContractChange,
}) => {
  return (
    <div className="card my-card">
      <div className="card-header my-card-header bg-lighter">
        <div>
          <h6 className="my-card-header-head">Basic Details</h6>
        </div>
      </div>
      <div className="card-body my-card-body">
        <div className="row g-3 m-0">
          <div className="col-12 col-sm-6 col-md-4 col-lg-3">
            <label
              htmlFor="sales_contract_id"
              className="form-label"
              style={{ fontSize: "0.9375rem" }}
            >
              Sales Contract: <span className="text-danger">*</span>
            </label>
            <select
              name="sales_contract_id"
              className="form-select"
              id="sales_contract_id"
              value={values.sales_contract_id}
              onChange={(e: any) => {
                handleChange(e);
                handleSalesContractChange(e.target.value, setFieldValue);
              }}
            >
              <option defaultChecked value={""}>
                Select Sales Contract
              </option>
              {salesContractList.map((e: any) => {
                return (
                  <option value={e.id} key={e.id}>
                    {e.contract_no}
                  </option>
                );
              })}
            </select>
            <ErrorMessage
              name="sales_contract_id"
              className="text-danger"
              component="div"
            />
          </div>
          <div className="col-12 col-sm-6 col-md-4 col-lg-3">
            <label
              htmlFor="buyer_id"
              className="form-label"
              style={{ fontSize: "0.9375rem" }}
            >
              Buyer: <span className="text-danger">*</span>
            </label>
            <select
              name="buyer_id"
              className="form-select"
              id="buyer_id"
              value={values.buyer_id}
              onChange={handleChange}
              disabled
            >
              <option defaultChecked value={""}>
                Select Buyer
              </option>
              {buyerList.map((e: any) => {
                return (
                  <option value={e.id} key={e.id}>
                    {e.name}
                  </option>
                );
              })}
            </select>
          </div>
          <div className="col-12 col-sm-6 col-md-4 col-lg-3">
            <label
              htmlFor="invoice_no"
              className="form-label"
              style={{ fontSize: "0.9375rem" }}
            >
              Invoice No: <span className="text-danger">*</span>
            </label>
            <Field
              id="invoice_no"
              name="invoice_no"
              type="text"
              className="form-control"
              placeholder="Enter Invoice No"
              value={values.invoice_no}
              onChange={handleChange}
            />
            <ErrorMessage
              name="invoice_no"
              className="text-danger"
              component="div"
            />
          </div>
          <div className="col-12 col-sm-6 col-md-4 col-lg-3">
            <label
              htmlFor="invoice_date"
              className="form-label"
              style={{ fontSize: "0.9375rem" }}
            >
              Invoice Date: <span className="text-danger">*</span>
            </label>
            <DatePicker
              id="invoice_date"
              name="invoice_date"
              format="dd-MM-yyyy"
              className="form-control"
              placeholder="Select Invoice Date"
              value={values.invoice_date}
              onChange={(value: any) => {
                setFieldValue("invoice_date", value);
              }}
            />
            <ErrorMessage
              name="invoice_date"
              className="text-danger"
              component="div"
            />
          </div>
          <div className="col-12 col-sm-6 col-md-4 col-lg-3">
            <div className="d-flex justify-content-between">
              <div>
                <label
                  htmlFor="logistic_id"
                  className="form-label"
                  style={{ fontSize: "0.9375rem" }}
                >
                  Logistic:
                </label>
              </div>
              <div>
                <button
                  title="Add Product"
                  className="btn btn-sm btn-primary waves-effect waves-light p-0"
                  type="button"
                  onClick={() => setAddLogisticModalStatus(true)}
                >
                  <span>
                    <i className="ti ti-plus my-card-icon-button"></i>
                  </span>
                </button>
              </div>
            </div>
            <select
              name="logistic_id"
              className="form-select"
              id="logistic_id"
              value={values.logistic_id}
              onChange={handleChange}
            >
              <option defaultChecked value={""}>
                Select Logistic
              </option>
              {logisticList.map((e: any) => {
                return (
                  <option value={e.id} key={e.id}>
                    {e.name}
                  </option>
                );
              })}
            </select>
          </div>
          <div className="col-12 col-sm-6 col-md-4 col-lg-3">
            <div className="d-flex justify-content-between">
              <div>
                <label
                  htmlFor="cha_id"
                  className="form-label"
                  style={{ fontSize: "0.9375rem" }}
                >
                  CHA:
                </label>
              </div>
              <div>
                <button
                  title="Add Product"
                  className="btn btn-sm btn-primary waves-effect waves-light p-0"
                  type="button"
                  onClick={() => setAddChaModalStatus(true)}
                >
                  <span>
                    <i className="ti ti-plus my-card-icon-button"></i>
                  </span>
                </button>
              </div>
            </div>
            <select
              name="cha_id"
              className="form-select"
              id="cha_id"
              value={values.cha_id}
              onChange={handleChange}
            >
              <option defaultChecked value={""}>
                Select CHA
              </option>
              {chaList.map((e: any) => {
                return (
                  <option value={e.id} key={e.id}>
                    {e.name}
                  </option>
                );
              })}
            </select>
          </div>
          <div className="col-12 col-sm-6 col-md-4 col-lg-3">
            <label
              htmlFor="status"
              className="form-label"
              style={{ fontSize: "0.9375rem" }}
            >
              Status: <span className="text-danger">*</span>
            </label>
            <select
              id="status"
              name="status"
              className="form-select"
              value={values.status}
              onChange={handleChange}
            >
              <option defaultChecked value={""}>
                Select Status
              </option>
              <option
                key={SalesInvoiceStatus.PENDING}
                value={SalesInvoiceStatus.PENDING}
              >
                {formatString(SalesInvoiceStatus.PENDING)}
              </option>
              <option
                key={SalesInvoiceStatus.IN_TRANSIT}
                value={SalesInvoiceStatus.IN_TRANSIT}
              >
                {formatString(SalesInvoiceStatus.IN_TRANSIT)}
              </option>
              <option
                key={SalesInvoiceStatus.COMPLETED}
                value={SalesInvoiceStatus.COMPLETED}
              >
                {formatString(SalesInvoiceStatus.COMPLETED)}
              </option>
            </select>
            <ErrorMessage
              name="status"
              className="text-danger"
              component="div"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default BasicFields;
