/* eslint-disable react-hooks/exhaustive-deps */
import * as Yup from "yup";
import { useEffect, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { Link, useNavigate, useParams } from "react-router-dom";
import { errorToast, successToast } from "../../components/Toast";
import { getPortByIdApi, updatePortApi } from "../../services/_port";
import CountryStateCityField from "../other/CountryStateCityField";
import {
  getCities,
  getCountries,
  getStateCityList,
  getStates,
} from "../../components/Helper";

const EditPort = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [updateLoading, setUpdateLoading] = useState<boolean>(false);
  const countryList = getCountries();
  const [stateList, setStateList] = useState<any>([]);
  const [cityList, setCityList] = useState<any>([]);

  const handleCountryChange = (country: any) => {
    setStateList([]);
    setCityList([]);
    const allStates = getStates(country);
    setStateList(allStates);
  };
  const handleStateChange = (state: any) => {
    setCityList([]);
    const cities = getCities(state);
    setCityList(cities);
  };
  const setStateCityList = async (countryName: any, stateName: any) => {
    const { allStates, allCities } = await getStateCityList(
      countryName,
      stateName
    );
    setStateList(allStates);
    setCityList(allCities);
  };

  useEffect(() => {
    if (!id) {
      navigate("/port-list");
    } else {
      getPortDetails();
    }
  }, []);

  const createPortInitialValues = {
    name: "",
    country: "",
    state: "",
    city: "",
    status: "",
  };

  const getPortDetails = async () => {
    const result: any = await getPortByIdApi(id as string);
    setStateCityList(result?.data?.country, result?.data?.state);
    if (result.isSuccess) {
      setInitialValues({
        name: result?.data?.name,
        country: result?.data?.country,
        state: result?.data?.state,
        city: result?.data?.city,
        status: result?.data?.status?.toString(),
      });
    }
  };

  const [initialValues, setInitialValues] = useState(createPortInitialValues);

  const createPortValidationSchema = Yup.object().shape({
    name: Yup.string().required("Name is required"),
    country: Yup.string(),
    state: Yup.string(),
    city: Yup.string(),
    status: Yup.string().required("Status is required"),
  });

  const handleSubmit = async (values: any, { resetForm }: any) => {
    setUpdateLoading(true);
    values.status = values.status === "true" ? true : false;
    const result: any = await updatePortApi(id as string, values);
    if (result.isSuccess) {
      setUpdateLoading(false);
      resetForm({ values: createPortInitialValues });
      successToast(result.message);
      navigate("/port-list");
    } else {
      setUpdateLoading(false);
      errorToast(result.message);
    }
    return;
  };

  return (
    <>
      <div className="card">
        <div className="card-header pb-0">
          <div>
            <h5>Edit Port</h5>
          </div>
        </div>
        <div className="card-body">
          <Formik
            enableReinitialize={true}
            initialValues={initialValues}
            validationSchema={createPortValidationSchema}
            onSubmit={handleSubmit}
          >
            {({ values, handleChange, setFieldValue }) => {
              return (
                <Form className="dt_adv_search">
                  <div className="row">
                    <div className="col-12">
                      <div className="row g-3">
                        <div className="col-12 col-sm-6 col-lg-4">
                          <label
                            htmlFor="name"
                            className="form-label"
                            style={{ fontSize: "0.9375rem" }}
                          >
                            Name: <span className="text-danger">*</span>
                          </label>
                          <Field
                            id="name"
                            name="name"
                            type="text"
                            className="form-control"
                            placeholder="Enter name"
                            onChange={handleChange}
                          />
                          <ErrorMessage
                            name="name"
                            className="text-danger"
                            component="div"
                          />
                        </div>
                        <CountryStateCityField
                          countryList={countryList}
                          stateList={stateList}
                          cityList={cityList}
                          values={values}
                          setFieldValue={setFieldValue}
                          handleCountryChange={handleCountryChange}
                          handleStateChange={handleStateChange}
                        />
                        <div className="col-12 col-sm-6 col-lg-4">
                          <label
                            htmlFor="status"
                            className="form-label"
                            style={{ fontSize: "0.9375rem" }}
                          >
                            Status: <span className="text-danger">*</span>
                          </label>
                          <select
                            id="status"
                            name="status"
                            className="form-select"
                            onChange={handleChange}
                            value={values?.status}
                          >
                            <option value="">Select Status</option>
                            <option value="true">Active</option>
                            <option value="false">Inactive</option>
                          </select>
                          <ErrorMessage
                            name="status"
                            className="text-danger"
                            component="div"
                          />
                        </div>
                        <div className="col-12">
                          <button
                            type="submit"
                            className="btn btn-primary"
                            disabled={updateLoading}
                          >
                            {updateLoading && (
                              <span
                                className="spinner-border spinner-border-sm me-2"
                                role="status"
                                aria-hidden="true"
                              ></span>
                            )}
                            Submit
                          </button>
                          <Link
                            to={"/port-list"}
                            type="button"
                            className="btn btn-label-github ms-5"
                          >
                            Back
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </Form>
              );
            }}
          </Formik>
        </div>
      </div>
    </>
  );
};

export default EditPort;
