import * as Yup from "yup";
import React, { useEffect, useState } from "react";
import { errorToast, successToast } from "../Toast";
import { Field, Form, Formik, ErrorMessage } from "formik";
import { createQualitySpecificationsApi } from "../../services/_qualitySpecifications";
import { getProductNameListApi } from "../../services/_product";
import ReactSelect from "react-select";

const createQualitySpecificationsInitialValues = {
  name: "",
  description: "",
  product_id: "",
  status: "",
};

interface AddQualitySpecificationModalProps {
  addQualitySpecificationModal: boolean;
  closeAddQualitySpecificationModal: () => void;
  onQualitySpecificationCreated: any;
}

const createQualitySpecificationsValidationSchema = Yup.object().shape({
  name: Yup.string().required("Name is required"),
  description: Yup.string(),
  product_id: Yup.string(),
  status: Yup.string().required("Status is required"),
});

const AddQualitySpecificationModal: React.FC<
  AddQualitySpecificationModalProps
> = ({
  addQualitySpecificationModal,
  closeAddQualitySpecificationModal,
  onQualitySpecificationCreated,
}) => {
  const [submitLoader, setSubmitLoader] = useState(false);
  const [productList, setProductList] = useState([]);

  const getProductNameList = async () => {
    try {
      const result: any = await getProductNameListApi();
      setProductList(result.data);
    } catch (error) {
      console.error("Error fetching product list", error);
    }
  };

  useEffect(() => {
    if (addQualitySpecificationModal) {
      getProductNameList();
    }
  }, [addQualitySpecificationModal]);

  const handleSubmit = async (values: any, { resetForm }: any) => {
    setSubmitLoader(true);
    values.status = values.status === "true" ? true : false;
    const result: any = await createQualitySpecificationsApi(values);
    if (result.isSuccess) {
      setSubmitLoader(false);
      resetForm({ values: createQualitySpecificationsInitialValues });
      successToast(result.message);
      closeAddQualitySpecificationModal();
      onQualitySpecificationCreated(result.data);
    } else {
      setSubmitLoader(false);
      errorToast(result.message);
    }
    return;
  };

  return (
    <>
      {addQualitySpecificationModal && (
        <>
          <div className="modal-backdrop fade show"></div>
          <div
            className="modal fade show"
            id="addQualitySpecificationModal"
            tabIndex={-3}
            aria-hidden={!addQualitySpecificationModal}
            style={{ display: addQualitySpecificationModal ? "block" : "none" }}
          >
            <div className="modal-dialog modal-lg modal-simple modal-dialog-centered">
              <div className="modal-content">
                <div className="modal-body p-4">
                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                    onClick={closeAddQualitySpecificationModal}
                  ></button>
                  <div className="text-center mb-2">
                    <h4 className="mb-2">Add Quality Specification</h4>
                  </div>
                  <div className="modal-body p-0">
                    <Formik
                      initialValues={createQualitySpecificationsInitialValues}
                      validationSchema={
                        createQualitySpecificationsValidationSchema
                      }
                      onSubmit={handleSubmit}
                    >
                      {({ values, handleChange, setFieldValue }) => (
                        <Form className="dt_adv_search">
                          <div className="row">
                            <div className="col-12">
                              <div className="row g-3">
                                <div className="col-12 col-sm-6 col-lg-4">
                                  <label
                                    htmlFor="name"
                                    className="form-label"
                                    style={{ fontSize: "0.9375rem" }}
                                  >
                                    Name: <span className="text-danger">*</span>
                                  </label>
                                  <Field
                                    id="name"
                                    name="name"
                                    type="text"
                                    className="form-control"
                                    placeholder="Enter name"
                                    onChange={handleChange}
                                  />
                                  <ErrorMessage
                                    name="name"
                                    className="text-danger"
                                    component="div"
                                  />
                                </div>
                                <div className="col-12 col-sm-6 col-lg-4">
                                  <label
                                    className="form-label"
                                    style={{ fontSize: "0.9375rem" }}
                                  >
                                    Product:{" "}
                                    <span className="text-danger">*</span>
                                  </label>
                                  <Field name="product_id">
                                    {({ field }: any) => {
                                      const selectedOption = productList.find(
                                        (option: any) =>
                                          option.id === field.value
                                      );
                                      const formatOptionLabel = (
                                        option: any,
                                        { context }: any
                                      ) => {
                                        if (context === "value") {
                                          return option.name;
                                        } else {
                                          return (
                                            <div>
                                              <label className="form-label">
                                                {option.name}
                                              </label>
                                              <br />
                                              <span className="form-label">
                                                {option.description}
                                              </span>
                                            </div>
                                          );
                                        }
                                      };
                                      return (
                                        <ReactSelect
                                          {...field}
                                          classNamePrefix="react-select"
                                          placeholder="Select Product"
                                          options={productList}
                                          getOptionLabel={(option: any) =>
                                            option.name
                                          }
                                          formatOptionLabel={formatOptionLabel}
                                          getOptionValue={(option: any) =>
                                            option.id
                                          }
                                          onChange={(selectedOption: any) => {
                                            setFieldValue(
                                              "product_id",
                                              selectedOption
                                                ? selectedOption.id
                                                : ""
                                            );
                                          }}
                                          value={selectedOption}
                                          isClearable
                                          isSearchable
                                        />
                                      );
                                    }}
                                  </Field>
                                  <ErrorMessage
                                    name="product_id"
                                    component="div"
                                    className="text-danger"
                                  />
                                </div>
                                <div className="col-12 col-sm-6 col-lg-4">
                                  <label
                                    htmlFor="status"
                                    className="form-label"
                                    style={{ fontSize: "0.9375rem" }}
                                  >
                                    Status:{" "}
                                    <span className="text-danger">*</span>
                                  </label>
                                  <select
                                    id="status"
                                    name="status"
                                    className="form-select"
                                    value={values.status}
                                    onChange={handleChange}
                                  >
                                    <option value="">Select Status</option>
                                    <option value="true">Active</option>
                                    <option value="false">Inactive</option>
                                  </select>
                                  <ErrorMessage
                                    name="status"
                                    className="text-danger"
                                    component="div"
                                  />
                                </div>
                                <div className="col-12">
                                  <label
                                    htmlFor="description"
                                    className="form-label"
                                    style={{ fontSize: "0.9375rem" }}
                                  >
                                    Description:
                                  </label>
                                  <Field
                                    id="description"
                                    name="description"
                                    type="text"
                                    className="form-control"
                                    placeholder="Enter description"
                                    onChange={handleChange}
                                    as={"textarea"}
                                    rows={2}
                                  />
                                  <ErrorMessage
                                    name="description"
                                    className="text-danger"
                                    component="div"
                                  />
                                </div>
                                <div className="col-12 text-center mt-4">
                                  <button
                                    type="submit"
                                    className="btn btn-primary me-3"
                                    disabled={submitLoader}
                                  >
                                    {submitLoader && (
                                      <span
                                        className="spinner-border spinner-border-sm me-2"
                                        role="status"
                                        aria-hidden="true"
                                      ></span>
                                    )}{" "}
                                    Add Quality Specification
                                  </button>
                                  <button
                                    type="button"
                                    className="btn btn-label-secondary"
                                    onClick={closeAddQualitySpecificationModal}
                                  >
                                    Cancel
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </Form>
                      )}
                    </Formik>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default AddQualitySpecificationModal;
