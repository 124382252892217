import { GET, POST, PUT } from "./_config";

export const qualitySpecificationsListApi = async (query: string) =>
  await GET(`/quality-specifications?${query || ""}`, true);

export const getAllQualitySpecificationsApi = async (query?: string) =>
  await GET(`/quality-specifications/list?${query || ""}`, true);

export const createQualitySpecificationsApi = async (body: any) =>
  await POST(`/quality-specifications`, body, true);

export const getQualitySpecificationsByIdApi = async (
  qualitySpecificationsId: string
) => await GET(`/quality-specifications/id/${qualitySpecificationsId}`, true);

export const updateQualitySpecificationsApi = async (
  qualitySpecificationsId: string,
  body: any
) =>
  await PUT(
    `/quality-specifications?id=${qualitySpecificationsId}`,
    body,
    true
  );

export const updateQualitySpecificationsStatusApi = async (body: any) =>
  await POST(`/quality-specifications/update-status`, body, true);

const QualitySpecificationsService = {
  qualitySpecificationsListApi,
  createQualitySpecificationsApi,
  getQualitySpecificationsByIdApi,
  updateQualitySpecificationsApi,
  updateQualitySpecificationsStatusApi,
  getAllQualitySpecificationsApi,
};

export default QualitySpecificationsService;
