import {
  salesContractInitialValues,
  salesContractValidationSchema,
} from "./helpers/SalesContractHelper";
import moment from "moment";
import { Formik, Form } from "formik";
import { useNavigate } from "react-router-dom";
import { useCallback, useEffect, useState } from "react";
import BasicFields from "./sales-contract-form/BasicFields";
import ActionFields from "./sales-contract-form/ActionFields";
import {
  createSalesContractApi,
  getSaleContractQualitySpecificationSuggestionApi,
  getSalesContractDocumentSuggestionApi,
} from "../../../services/_sales";
import { getBuyerNameListApi } from "../../../services/_buyer";
import { getAllDocumentApi } from "../../../services/_document";
import ProductFields from "./sales-contract-form/ProductFields";
import ShipmentFields from "./sales-contract-form/ShipmentFields";
import DocumentFields from "./sales-contract-form/DocumentFields";
import { getProductNameListApi } from "../../../services/_product";
import { errorToast, successToast } from "../../../components/Toast";
import { getAllQualitySpecificationsApi } from "../../../services/_qualitySpecifications";
import QualitySpecificationFields from "./sales-contract-form/QualitySpecificationFields";
import { getAllBrokerApi } from "../../../services/_broker";
import { getAllUnitsApi } from "../../../services/_unit";
import { getAllPackingTypesApi } from "../../../services/_packingTypes";
import AddUnitModal from "../../../components/modal/AddUnit";
import AddPackingType from "../../../components/modal/AddPackingType";
import MarkingFields from "./sales-contract-form/MarkingFields";
import AddBuyerModal from "../../../components/modal/AddBuyer";
import AddBrokerModal from "../../../components/modal/AddBroker";
import AddProductModal from "../../../components/modal/AddProduct";
import AddQualitySpecificationModal from "../../../components/modal/AddQualitySpecification";
import AddDocumentModal from "../../../components/modal/AddDocument";
import BankFields from "./sales-contract-form/BankFields";
import { getAllBankListApi } from "../../../services/_bank";
import { BankType } from "../../../components/Enums";
import AddBankModal from "../../../components/modal/AddBank";
import { getSettingsApi } from "../../../services/_settings";

const CreateSalesContract = () => {
  const navigate = useNavigate();
  const [buyerId, setBuyerId] = useState("");
  const [buyerList, setBuyerList] = useState([]);
  const [brokerList, setBrokerList] = useState([]);
  const [productList, setProductList] = useState([]);
  const [unitList, setUnits] = useState<any[]>([]);
  const [packingType, setPackingType] = useState<any[]>([]);
  const [qualitySpecificationsList, setQualitySpecificationsList] = useState(
    []
  );
  const [qltSuggestionList, setQltSuggestionList] = useState([]);
  const [documentList, setDocumentList] = useState([]);
  const [documentSuggestionList, setDocumentSuggestionList] = useState([]);
  const [submitLoader, setSubmitLoader] = useState<boolean>(false);
  const [initialValues] = useState<any>(salesContractInitialValues);
  const [addUnitModalStatus, setAddUnitModalStatus] = useState(false);
  const [addPackingTypeModalStatus, setAddPackingTypeModalStatus] =
    useState(false);
  const [filePondFiles, setFilePondFiles] = useState([]);
  const [addBuyerModalStatus, setAddBuyerModalStatus] = useState(false);
  const [addProductModalStatus, setAddProductModalStatus] = useState(false);
  const [addBrokerModalStatus, setAddBrokerModalStatus] = useState(false);
  const [
    addQualitySpecificationModalStatus,
    setAddQualitySpecificationModalStatus,
  ] = useState(false);
  const [addDocumentModalStatus, setAddDocumentModalStatus] = useState(false);
  const [exporterBankList, setExporterBankList] = useState([]);
  const [buyerBankList, setBuyerBankList] = useState([]);
  const [addBankModal, setAddBankModal] = useState(false);
  const [bankType, setBankType] = useState<string>("");
  const [prefix, setPrefix] = useState("");

  const fetchInitialData = async () => {
    const allBuyers: any = await getBuyerNameListApi();
    if (allBuyers.isSuccess) {
      setBuyerList(allBuyers.data);
    }
    const allBrokers: any = await getAllBrokerApi();
    if (allBrokers.isSuccess) {
      setBrokerList(allBrokers.data);
    }
    const allProducts: any = await getProductNameListApi();
    if (allProducts.isSuccess) {
      setProductList(allProducts.data);
    }
    const allQualitySpecs: any = await getAllQualitySpecificationsApi();
    if (allQualitySpecs.isSuccess) {
      setQualitySpecificationsList(allQualitySpecs.data);
    }
    const allDocuments: any = await getAllDocumentApi();
    if (allDocuments.isSuccess) {
      setDocumentList(allDocuments.data);
    }
    const allUnits: any = await getAllUnitsApi();
    if (allUnits.isSuccess) {
      setUnits(allUnits.data);
    }

    const fetchSalesContractPrefix: any = await getSettingsApi();
    if (fetchSalesContractPrefix.isSuccess) {
      const { sales_contract_prefix } = fetchSalesContractPrefix.data;
      setPrefix(sales_contract_prefix);
    }
    const allPackingTypes: any = await getAllPackingTypesApi();
    if (allPackingTypes.isSuccess) {
      setPackingType(allPackingTypes.data);
    }
    const allExporterBanks: any = await getAllBankListApi(
      `type=${BankType.EXPORTER}`
    );
    if (allExporterBanks.isSuccess) {
      setExporterBankList(allExporterBanks.data);
    }
  };

  useEffect(() => {
    fetchInitialData();
  }, []);

  const fetchBuyerBankDetails = async (buyerId: string) => {
    if (buyerId) {
      const allBuyerBanks: any = await getAllBankListApi(
        `type=${BankType.BUYER}&relation_id=${buyerId}`
      );
      if (allBuyerBanks.isSuccess) {
        setBuyerBankList(allBuyerBanks.data);
      }
    } else {
      setBuyerBankList([]);
    }
  };

  useEffect(() => {
    fetchBuyerBankDetails(buyerId);
  }, [buyerId]);

  const handleChangeProduct = async (product_id: string) => {
    if (product_id) {
      const allQualitySpecs: any = await getAllQualitySpecificationsApi(
        `product_id=${product_id}`
      );
      if (allQualitySpecs.isSuccess) {
        setQualitySpecificationsList(allQualitySpecs.data);
      }
      const qltSuggestion: any =
        await getSaleContractQualitySpecificationSuggestionApi(product_id);
      if (qltSuggestion.isSuccess) {
        setQltSuggestionList(qltSuggestion?.data || []);
      }
      const documentSuggestionList: any =
        await getSalesContractDocumentSuggestionApi(product_id);
      if (documentSuggestionList.isSuccess) {
        setDocumentSuggestionList(documentSuggestionList?.data || []);
      }
    } else {
      setQualitySpecificationsList([]);
      setQltSuggestionList([]);
      setDocumentSuggestionList([]);
    }
  };

  const closeAddUnitModal = () => {
    setAddUnitModalStatus(false);
  };

  const closePackingTypeModal = () => {
    setAddPackingTypeModalStatus(false);
  };

  const closeAddBuyerModal = () => {
    setAddBuyerModalStatus(false);
  };

  const closeAddBrokerModal = () => {
    setAddBrokerModalStatus(false);
  };

  const closeAddProductModal = () => {
    setAddProductModalStatus(false);
  };

  const closeAddQualitySpecificationModal = () => {
    setAddQualitySpecificationModalStatus(false);
  };

  const closeAddDocumentModal = () => {
    setAddDocumentModalStatus(false);
  };

  const openAddBankModal = (isOpen: boolean, type: string) => {
    setBankType(type);
    setAddBankModal(isOpen);
  };

  const closeAddBankModal = () => {
    setAddBankModal(false);
  };

  const calculateTotalAmount = useCallback(
    (price: any, quantity: any, setFieldValue: any) => {
      if (price && quantity) {
        const calculatedAmount = parseFloat(price) * parseFloat(quantity);
        setFieldValue("total_amount", calculatedAmount.toFixed(2));
      }
    },
    []
  );

  const handleFileChange = useCallback((fileItems: any, setFieldValue: any) => {
    const files = fileItems
      .map((fileItem: any) => {
        if (fileItem?.file) {
          return {
            fileName: fileItem.file.name,
            fileData: fileItem.getFileEncodeDataURL(), // Encodes to base64
          };
        }
        return null;
      })
      .filter(Boolean);
    setFieldValue("marking_files", files);
  }, []);

  const handleSubmit = async (values: any, { resetForm }: any) => {
    values.contract_date = moment(values.contract_date).format("YYYY-MM-DD");
    values.total_amount = parseFloat(values.total_amount);
    values.is_digital_signature = values?.is_digital_signature ? true : false;
    setSubmitLoader(true);
    const result: any = await createSalesContractApi(values);
    if (result.isSuccess) {
      setSubmitLoader(false);
      successToast(result.message);
      resetForm({ values: initialValues });
      navigate(`/sales-contract-list`);
    } else {
      setSubmitLoader(false);
      errorToast(result.message);
    }
    return;
  };

  return (
    <>
      <div className="card">
        <div className="card-header pb-0 d-flex justify-content-between">
          <div>
            <h5>Create Sales Contract</h5>
          </div>
        </div>
        <div className="card-body">
          <Formik
            enableReinitialize
            initialValues={{
              ...initialValues,
              contract_no: prefix || "",
            }}
            validationSchema={salesContractValidationSchema}
            onSubmit={handleSubmit}
          >
            {({ values, handleChange, setFieldValue }) => {
              return (
                <Form className="dt_adv_search">
                  <div className="row g-3">
                    <BasicFields
                      buyerList={buyerList}
                      brokerList={brokerList}
                      setBuyerId={setBuyerId}
                      values={values}
                      setFieldValue={setFieldValue}
                      handleChange={handleChange}
                      setAddBuyerModalStatus={setAddBuyerModalStatus}
                      setAddBrokerModalStatus={setAddBrokerModalStatus}
                    />

                    <ProductFields
                      unitList={unitList}
                      productList={productList}
                      packingType={packingType}
                      values={values}
                      setFieldValue={setFieldValue}
                      handleChange={handleChange}
                      calculateTotalAmount={calculateTotalAmount}
                      setAddUnitModalStatus={setAddUnitModalStatus}
                      setAddPackingTypeModalStatus={
                        setAddPackingTypeModalStatus
                      }
                      setAddProductModalStatus={setAddProductModalStatus}
                      handleChangeProduct={handleChangeProduct}
                    />

                    <MarkingFields
                      actionType={"create"}
                      values={values}
                      handleChange={handleChange}
                      filePondFiles={filePondFiles}
                      setFilePondFiles={setFilePondFiles}
                      handleFileChange={handleFileChange}
                      setFieldValue={setFieldValue}
                    />

                    <ShipmentFields
                      values={values}
                      setFieldValue={setFieldValue}
                      handleChange={handleChange}
                    />

                    <QualitySpecificationFields
                      values={values}
                      setAddQualitySpecificationModalStatus={
                        setAddQualitySpecificationModalStatus
                      }
                      qualitySpecificationsList={qualitySpecificationsList}
                      qltSuggestionList={qltSuggestionList}
                      setFieldValue={setFieldValue}
                    />

                    <DocumentFields
                      values={values}
                      setAddDocumentModalStatus={setAddDocumentModalStatus}
                      documentList={documentList}
                      documentSuggestionList={documentSuggestionList}
                      setFieldValue={setFieldValue}
                    />

                    <BankFields
                      exporterBankList={exporterBankList}
                      buyerBankList={buyerBankList}
                      openAddBankModal={openAddBankModal}
                      values={values}
                      handleChange={handleChange}
                    />

                    <ActionFields submitLoader={submitLoader} />
                  </div>
                </Form>
              );
            }}
          </Formik>
        </div>
      </div>
      <AddUnitModal
        addUnitModalStatus={addUnitModalStatus}
        closeAddUnitModal={closeAddUnitModal}
        onUnitCreated={fetchInitialData}
      />
      <AddPackingType
        addPackingTypeModalStatus={addPackingTypeModalStatus}
        closePackingTypeModal={closePackingTypeModal}
        onPackingTypeCreated={fetchInitialData}
      />
      <AddBuyerModal
        addBuyerModal={addBuyerModalStatus}
        closeAddBuyerModal={closeAddBuyerModal}
        onBuyerCreated={fetchInitialData}
      />
      <AddBrokerModal
        addBrokerModal={addBrokerModalStatus}
        closeAddBrokerModal={closeAddBrokerModal}
        onBrokerCreated={fetchInitialData}
      />
      <AddProductModal
        addProductModalStatus={addProductModalStatus}
        closeAddProductModal={closeAddProductModal}
        onProductCreated={fetchInitialData}
      />
      <AddQualitySpecificationModal
        addQualitySpecificationModal={addQualitySpecificationModalStatus}
        closeAddQualitySpecificationModal={closeAddQualitySpecificationModal}
        onQualitySpecificationCreated={fetchInitialData}
      />
      <AddDocumentModal
        addDocumentModal={addDocumentModalStatus}
        closeAddDocumentModal={closeAddDocumentModal}
        onDocumentCreated={fetchInitialData}
      />
      <AddBankModal
        addBankModal={addBankModal}
        closeAddBankModal={closeAddBankModal}
        onBankCreated={() => {
          fetchBuyerBankDetails(buyerId);
          fetchInitialData();
        }}
        type={bankType}
        selectedBuyerId={buyerId}
      />
    </>
  );
};

export default CreateSalesContract;
