import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  PDFViewer,
} from "@react-pdf/renderer";

export const styles = StyleSheet.create({
  boldText: {
    fontWeight: "bold",
    fontSize: 8,
  },
  smallText: {
    fontSize: 6,
  },
  mediumText: {
    fontSize: 6.5,
  },
  contentPadding: {
    padding: 1,
  },
  page: {
    paddingHorizontal: 30,
    paddingVertical: 1,
    fontFamily: "HostGrotesk",
    backgroundColor: "white",
  },
  fullpage: {
    margin: 7,
    borderStyle: "solid",
    borderWidth: 1,
    borderColor: "#000000",
  },
  sideBySideContainer: { flexDirection: "row" },
  tableContainer: {
    borderBottomWidth: 1,
  },
  tableRow: {
    flexDirection: "row",
    flexGrow: 1,
    alignItems: "stretch",
  },
  tableCell: {
    padding: 5,
    fontSize: 7,
    textAlign: "center",
    borderRightWidth: 1,
  },
});

const BrokerInvoice = () => {
  return (
    <PDFViewer width="100%" height="600px">
      <Document>
        <Page style={styles.page} size="A4">
          <View style={styles.fullpage}>
            <View
              style={[
                styles.sideBySideContainer,
                { height: 30, flexDirection: "row" },
              ]}
            >
              {/* Left side: Kamani Export with its address */}
              <View style={{ flex: 1, top: 2 }}>
                <Text style={{ fontWeight: "bold", fontSize: 16 }}>
                  Kamani Export
                </Text>
                <Text
                  style={{
                    marginTop: 25,
                    fontSize: 10,
                    justifyContent: "space-around",
                  }}
                >
                  Office No. 410/411
                </Text>
                <Text
                  style={{
                    fontSize: 10,
                    marginTop: 10,
                    justifyContent: "space-around",
                  }}
                >
                  Unicorn Prime
                </Text>
                <Text
                  style={{
                    fontSize: 10,
                    marginTop: 10,
                    justifyContent: "space-around",
                  }}
                >
                  Ranjitsagar Road
                </Text>
                <Text
                  style={{
                    fontSize: 10,
                    marginTop: 10,
                    justifyContent: "space-around",
                  }}
                >
                  Jamnagar - 361005
                </Text>
                <Text
                  style={{
                    fontSize: 10,
                    marginTop: 10,
                    justifyContent: "space-around",
                  }}
                >
                  Mobile No. +91 78198 34222
                </Text>
              </View>

              {/* gstin column box*/}
              <View style={{ flex: 0.6 }}>
                <View
                  style={{
                    height: 25,
                    flexDirection: "row",
                    justifyContent: "space-between",
                    borderBottomWidth: 1,
                    borderLeft: 1,
                  }}
                >
                  {/* Left column */}
                  <View style={{ flex: 0.5 }}>
                    <Text
                      style={{
                        fontWeight: "bold",
                        fontSize: 10,
                        textAlign: "center",
                        borderRight: 1,
                        height: 30,
                      }}
                    >
                      GSTIN
                    </Text>
                  </View>

                  {/* Right column */}
                  <View
                    style={{
                      flex: 1,
                      fontWeight: "bold",
                      backgroundColor: "grey",
                      alignItems: "center",
                    }}
                  >
                    <Text
                      style={{
                        fontSize: 10,
                        textAlign: "center",
                        height: 30,
                      }}
                    >
                      24DNIPK4762H1ZU
                    </Text>
                  </View>
                </View>
              </View>
            </View>
            {/* side details*/}
            <View
              style={{
                flexDirection: "column",
                paddingLeft: 250,
                marginTop: -10,
              }}
            >
              {/* Invoice No */}
              <View
                style={{
                  flexDirection: "row",
                  justifyContent: "space-between",
                  marginTop: 5,
                }}
              >
                <Text style={{ fontSize: 10, width: "50%" }}>Invoice No </Text>
                <Text style={{ fontSize: 10, width: "20%" }}>: </Text>
                <Text style={{ fontSize: 10, width: "40%", textAlign: "left" }}>
                  049/2024
                </Text>
              </View>

              {/* Invoice Date */}
              <View
                style={{
                  flexDirection: "row",
                  justifyContent: "space-between",
                  marginTop: 5,
                }}
              >
                <Text style={{ fontSize: 10, width: "50%" }}>Invoice Date</Text>
                <Text style={{ fontSize: 10, width: "20%" }}>: </Text>
                <Text style={{ fontSize: 10, width: "40%", textAlign: "left" }}>
                  28/11/2024
                </Text>
              </View>

              {/* Buyer’s Ref. No. */}
              <View
                style={{
                  flexDirection: "row",
                  justifyContent: "space-between",
                  marginTop: 5,
                }}
              >
                <Text style={{ fontSize: 10, width: "50%" }}>
                  Buyer'S Ref. No.
                </Text>
                <Text style={{ fontSize: 10, width: "20%" }}>: </Text>
                <Text style={{ fontSize: 10, width: "40%", textAlign: "left" }}>
                  BY TELEPHONE
                </Text>
              </View>

              {/* Dispatch Through */}
              <View
                style={{
                  flexDirection: "row",
                  justifyContent: "space-between",
                  marginTop: 5,
                }}
              >
                <Text style={{ fontSize: 10, width: "50%" }}>
                  Dispatch Through
                </Text>{" "}
                <Text style={{ fontSize: 10, width: "20%" }}>: </Text>
                <Text
                  style={{ fontSize: 10, width: "40%", textAlign: "left" }}
                ></Text>
              </View>

              {/* LR No. & Date */}
              <View
                style={{
                  flexDirection: "row",
                  justifyContent: "space-between",
                  marginTop: 5,
                }}
              >
                <Text style={{ fontSize: 10, width: "50%" }}>
                  LR No. & Date
                </Text>{" "}
                <Text style={{ fontSize: 10, width: "20%" }}>: </Text>
                <Text
                  style={{ fontSize: 10, width: "40%", textAlign: "left" }}
                ></Text>
              </View>

              {/* Terms of Payment */}
              <View
                style={{
                  flexDirection: "row",
                  justifyContent: "space-between",
                  marginTop: 5,
                }}
              >
                <Text style={{ fontSize: 10, width: "50%" }}>
                  Terms of Payment
                </Text>
                <Text style={{ fontSize: 10, width: "20%" }}>: </Text>
                <Text style={{ fontSize: 10, width: "40%", textAlign: "left" }}>
                  07 DAYS ONLY
                </Text>
              </View>
            </View>
            {/* tax invoice heading */}
            <View
              style={[
                styles.sideBySideContainer,
                {
                  height: 55,
                  justifyContent: "center",
                  top: 20,
                  alignItems: "center",
                  flexDirection: "row",
                },
              ]}
            >
              {/* Left Border with Reflection */}
              <View
                style={{
                  flex: 1,
                  borderBottomWidth: 2,
                  borderColor: "#000",
                  marginRight: 10,
                  position: "relative",
                }}
              >
                <View
                  style={{
                    position: "absolute",
                    bottom: -5,
                    left: 0,
                    right: 0,
                    height: 3,
                    backgroundColor: "#000",
                    opacity: 0.2,
                  }}
                />
              </View>

              {/* Center Text */}
              <Text style={{ fontSize: 16, textAlign: "center" }}>
                Tax Invoice
              </Text>

              {/* Right Border with Reflection */}
              <View
                style={{
                  flex: 1,
                  borderBottomWidth: 2,
                  borderColor: "#000",
                  marginLeft: 10,
                  position: "relative",
                }}
              >
                <View
                  style={{
                    position: "absolute",
                    bottom: -5,
                    left: 0,
                    right: 0,
                    height: 3,
                    backgroundColor: "#000",
                    opacity: 0.2,
                  }}
                />
              </View>
            </View>

            <View
              style={[
                styles.sideBySideContainer,
                { height: 80, flexDirection: "row" },
              ]}
            >
              <View style={{ flex: 1, top: 5 }}>
                <Text
                  style={[
                    {
                      fontWeight: "semibold",
                      fontSize: 10,
                      backgroundColor: "#958f8fac",
                      height: 15,
                      width: 80,
                    },
                  ]}
                >
                  Customer Name
                </Text>
                <Text
                  style={[
                    {
                      marginTop: 1,
                      fontWeight: "light",
                      fontSize: 9,
                      height: 15,
                    },
                  ]}
                >
                  Ms Laxmi Enterprice
                </Text>

                <Text
                  style={[
                    {
                      fontWeight: "semibold",
                      fontSize: 8,
                      backgroundColor: "#958f8fac",
                      height: 15,
                      width: 80,
                    },
                  ]}
                >
                  Customer GSTIN
                </Text>
                <Text
                  style={[
                    {
                      marginTop: 1,
                      fontWeight: "light",
                      fontSize: 10,
                      height: 15,
                    },
                  ]}
                >
                  24AALFM4290M1ZU
                </Text>
              </View>

              <View style={{ flex: 1, top: 5 }}>
                <Text
                  style={[
                    {
                      fontWeight: "semibold",
                      fontSize: 10,
                      backgroundColor: "#958f8fac",
                      height: 15,
                      width: 80,
                      justifyContent: "space-around",
                    },
                  ]}
                >
                  Billing Address
                </Text>
                <Text
                  style={[
                    {
                      marginTop: 3,
                      fontWeight: "light",
                      fontSize: 8,
                      justifyContent: "space-around",
                    },
                  ]}
                >
                  PLOT NO. 612, NAGOR ROAD GIDC BHUJ, KACHCHH GUJARAT, 370001
                  (INDIA)
                </Text>
              </View>

              <View style={{ flex: 1, top: 5 }}>
                <Text
                  style={[
                    {
                      fontWeight: "semibold",
                      fontSize: 10,
                      backgroundColor: "#958f8fac",
                      height: 15,
                      width: 85,
                      justifyContent: "space-around",
                    },
                  ]}
                >
                  Shipping Address
                </Text>
                <Text
                  style={[
                    {
                      marginTop: 1,
                      fontWeight: "light",
                      fontSize: 8,
                      justifyContent: "space-around",
                    },
                  ]}
                >
                  PLOT NO. 612, NAGOR ROAD GIDC BHUJ, KACHCHH, GUJARAT, 370001
                  (INDIA)
                </Text>
              </View>
            </View>

            {/* table*/}

            <View
              style={[
                styles.tableContainer,
                {
                  borderBottom: 0,
                  borderTop: 1,
                  borderRight: 0,
                  height: "30%",
                },
              ]}
            >
              {/* Table labels */}

              <View
                style={[
                  styles.tableRow,
                  {
                    fontSize: 6,
                    height: 20,
                  },
                ]}
              >
                <Text
                  style={[
                    styles.tableCell,

                    {
                      fontWeight: "medium",
                      fontSize: 9,
                      flex: 0.3,
                      textAlign: "center",
                      flexWrap: "wrap",
                      width: 5,
                      overflow: "hidden",
                    },
                  ]}
                >
                  Sr. No.
                </Text>
                <Text
                  style={[
                    styles.tableCell,

                    {
                      fontWeight: "medium",
                      fontSize: 9,
                      flex: 1,
                      textAlign: "center",
                      flexWrap: "wrap",
                      overflow: "hidden",
                    },
                  ]}
                >
                  HSN Code
                </Text>
                <Text
                  style={[
                    styles.tableCell,

                    {
                      fontWeight: "medium",
                      fontSize: 9,
                      flex: 5,
                      textAlign: "center",
                      flexWrap: "wrap",
                      overflow: "hidden",
                    },
                  ]}
                >
                  Item Description
                </Text>{" "}
                <Text
                  style={[
                    styles.tableCell,

                    {
                      fontWeight: "medium",
                      fontSize: 9,
                      borderRight: 1,
                      flex: 0.9,
                      textAlign: "center",
                      flexWrap: "wrap",
                      overflow: "hidden",
                    },
                  ]}
                >
                  Quantity
                </Text>
                <Text
                  style={[
                    styles.tableCell,

                    {
                      fontWeight: "medium",
                      fontSize: 9,
                      borderRight: 1,
                      flex: 1,
                      textAlign: "center",
                      flexWrap: "wrap",
                      overflow: "hidden",
                    },
                  ]}
                >
                  Rate Per Ton
                </Text>
                <Text
                  style={[
                    styles.tableCell,

                    {
                      fontWeight: "medium",
                      fontSize: 9,
                      borderRight: 0,
                      flex: 1.5,
                      textAlign: "center",
                      flexWrap: "wrap",
                      overflow: "hidden",
                    },
                  ]}
                >
                  Amount In INR
                </Text>
              </View>

              {/* table row data 1 */}
              <View>
                <View style={[styles.tableRow, { height: 200, borderTop: 1 }]}>
                  <Text
                    style={[
                      styles.tableCell,

                      {
                        fontWeight: "medium",
                        fontSize: 9,
                        flex: 0.3,
                        textAlign: "center",
                        flexWrap: "wrap",
                        width: 5,
                        overflow: "hidden",
                      },
                    ]}
                  >
                    1
                  </Text>
                  <Text
                    style={[
                      styles.tableCell,

                      {
                        fontWeight: "medium",
                        fontSize: 9,
                        flex: 1,
                        textAlign: "center",
                        flexWrap: "wrap",
                        overflow: "hidden",
                      },
                    ]}
                  >
                    9961
                  </Text>
                  <Text
                    style={[
                      styles.tableCell,

                      {
                        fontWeight: "medium",
                        fontSize: 9,
                        flex: 5,
                        textAlign: "center",
                        flexWrap: "wrap",
                        overflow: "hidden",
                      },
                    ]}
                  >
                    Commission
                  </Text>{" "}
                  <Text
                    style={[
                      styles.tableCell,

                      {
                        fontWeight: "medium",
                        fontSize: 9,
                        borderRight: 1,
                        flex: 0.9,
                        textAlign: "center",
                        flexWrap: "wrap",
                        overflow: "hidden",
                      },
                    ]}
                  >
                    694 Ton
                  </Text>
                  <Text
                    style={[
                      styles.tableCell,

                      {
                        fontWeight: "medium",
                        fontSize: 9,
                        borderRight: 1,
                        flex: 1,
                        textAlign: "center",
                        flexWrap: "wrap",
                        overflow: "hidden",
                      },
                    ]}
                  >
                    835.00
                  </Text>
                  <Text
                    style={[
                      styles.tableCell,

                      {
                        fontWeight: "medium",
                        fontSize: 9,
                        borderRight: 0,
                        flex: 1.5,
                        textAlign: "center",
                        flexWrap: "wrap",
                        overflow: "hidden",
                      },
                    ]}
                  >
                    579,490.00
                  </Text>
                </View>
              </View>

              {/* table row data 2 */}

              <View>
                <View
                  style={[
                    styles.tableRow,
                    {
                      marginBottom: 10,
                      flexDirection: "row",
                      marginTop: -160,
                      borderTop: 0,
                      justifyContent: "space-around",
                    },
                  ]}
                >
                  <Text
                    style={[
                      styles.tableCell,

                      {
                        fontWeight: 500,
                        fontSize: 9,
                        flex: 0.3,
                        textAlign: "center",
                        flexWrap: "wrap",
                        width: 5,
                        overflow: "hidden",
                      },
                    ]}
                  >
                    2
                  </Text>
                  <Text
                    style={[
                      styles.tableCell,

                      {
                        fontWeight: "medium",
                        fontSize: 9,
                        flex: 1,
                        textAlign: "center",
                        flexWrap: "wrap",
                        overflow: "hidden",
                      },
                    ]}
                  >
                    996112
                  </Text>
                  <Text
                    style={[
                      styles.tableCell,

                      {
                        fontWeight: "medium",
                        fontSize: 9,
                        flex: 5,
                        textAlign: "center",
                        flexWrap: "wrap",
                        justifyContent: "space-around",
                        overflow: "hidden",
                      },
                    ]}
                  >
                    Comsdihgfidnvidhgiregnnbvivbnbjgh9truygotrmboimjbitrjghtrkghktrmboitrh09rtkhlgfmbithjug9trkhglfkhmmission
                  </Text>{" "}
                  <Text
                    style={[
                      styles.tableCell,

                      {
                        fontWeight: "medium",
                        fontSize: 9,
                        borderRight: 1,
                        flex: 0.9,
                        textAlign: "center",
                        flexWrap: "wrap",
                        maxWidth: 70,
                        overflow: "hidden",
                      },
                    ]}
                  >
                    255000011 Ton
                  </Text>
                  <Text
                    style={[
                      styles.tableCell,

                      {
                        fontWeight: 500,
                        fontSize: 9,
                        flex: 1,
                        textAlign: "center",
                        flexWrap: "wrap",
                        maxWidth: "70",
                      },
                    ]}
                  >
                    25500000 Ton
                  </Text>
                  <Text
                    style={[
                      styles.tableCell,

                      {
                        fontWeight: "medium",
                        fontSize: 9,
                        borderRight: 0,
                        flex: 1.5,
                        textAlign: "center",
                        flexWrap: "wrap",
                        overflow: "hidden",
                      },
                    ]}
                  >
                    579,490.00
                  </Text>
                </View>
              </View>

              <View>
                <View>
                  <View
                    style={[
                      styles.tableRow,
                      {
                        borderRight: 0,
                        marginBottom: 5,
                        flexDirection: "row",
                        marginTop: -100,
                        borderTop: 0,
                        justifyContent: "space-around",
                      },
                    ]}
                  >
                    <Text
                      style={[
                        styles.tableCell,

                        {
                          fontWeight: 500,
                          fontSize: 9,
                          flex: 0.3,
                          textAlign: "center",
                          flexWrap: "wrap",
                          width: 5,
                          overflow: "hidden",
                        },
                      ]}
                    >
                      3
                    </Text>
                    <Text
                      style={[
                        styles.tableCell,

                        {
                          borderRight: 0,
                          fontWeight: "medium",
                          fontSize: 9,
                          flex: 1,
                          textAlign: "center",
                          flexWrap: "wrap",
                          overflow: "hidden",
                        },
                      ]}
                    ></Text>
                    <Text
                      style={[
                        styles.tableCell,

                        {
                          borderRight: 0,
                          fontWeight: "medium",
                          fontSize: 9,
                          flex: 5,
                          textAlign: "left",
                          flexWrap: "wrap",
                          justifyContent: "space-around",
                        },
                      ]}
                    >
                      <Text
                        style={{
                          fontWeight: "light",
                          fontSize: 9,
                        }}
                      >
                        <Text> Bank Details:{"\n"}</Text>
                        <Text> Bank Name: HDFC Bank Ltd{"\n"}</Text>
                        <Text>A/C No.: 50200031713294{"\n"}</Text>
                        <Text>IFSC Code: HDFC0001251{"\n"}</Text>
                        <Text>
                          Address: Ground & First Floor, Madhav Plaza, Shop No.
                          27
                        </Text>
                        <Text>to 32{"\n"}</Text>
                        <Text>Near Lal Bunglow Circle, Jamnagar 361001</Text>
                      </Text>
                    </Text>
                    <Text
                      style={[
                        styles.tableCell,
                        {
                          borderRight: 0,
                          flex: 0.9,
                        },
                      ]}
                    ></Text>
                    <Text
                      style={[
                        styles.tableCell,

                        {
                          borderRight: 0,
                          flex: 1,
                        },
                      ]}
                    ></Text>
                    <Text
                      style={[
                        styles.tableCell,

                        {
                          fontWeight: "medium",
                          fontSize: 9,
                          borderRight: 0,
                          flex: 1.5,
                          textAlign: "center",
                          flexWrap: "wrap",
                          overflow: "hidden",
                        },
                      ]}
                    ></Text>
                  </View>
                </View>
              </View>
            </View>

            {/* total */}
            <View
              style={[
                styles.tableRow,
                {
                  borderTop: 1,
                  borderRight: 0,
                  fontSize: 6,
                  height: 30,
                  flexDirection: "row",
                  width: "100%",
                  marginLeft: 0,
                  paddingLeft: 330,
                  marginBottom: 5,
                },
              ]}
            >
              <Text
                style={[
                  styles.tableCell,
                  {
                    borderRight: 1,
                    fontWeight: "medium",
                    fontSize: 9,
                    flex: 7,
                    textAlign: "center",
                    flexWrap: "wrap",
                    width: "auto",
                  },
                ]}
              >
                Total
              </Text>

              <Text
                style={[
                  styles.tableCell,
                  {
                    borderRight: 0,
                    fontWeight: "medium",
                    fontSize: 8,
                    height: 25,
                    flex: 5,
                    textAlign: "center",
                    flexWrap: "wrap",
                  },
                ]}
              >
                579,490.00
              </Text>
            </View>

            {/* Transportation */}
            <View
              style={[
                styles.tableRow,
                {
                  marginTop: -10,
                  borderRight: 0,
                  fontSize: 6,
                  height: 30,
                  flexDirection: "row",
                  width: "100%",
                  marginLeft: 0,
                  paddingLeft: 330,
                  marginBottom: 5,
                },
              ]}
            >
              <Text
                style={[
                  styles.tableCell,
                  {
                    borderRight: 1,
                    fontWeight: "medium",
                    fontSize: 9,
                    flex: 7,
                    textAlign: "center",
                    width: 100,
                  },
                ]}
              >
                Transportation
              </Text>
              <Text
                style={[
                  styles.tableCell,
                  {
                    borderTop: 1,
                    borderRight: 0,
                    fontWeight: "medium",
                    fontSize: 8,
                    height: 25,
                    flex: 5,
                    textAlign: "center",
                    flexWrap: "wrap",
                  },
                ]}
              >
                6565
              </Text>
            </View>

            {/* Packing Charges */}
            <View
              style={[
                styles.tableRow,
                {
                  marginTop: -10,
                  borderRight: 0,
                  fontSize: 6,
                  height: 30,
                  flexDirection: "row",
                  width: "100%",
                  marginLeft: 0,
                  paddingLeft: 330,
                  marginBottom: 5,
                },
              ]}
            >
              <Text
                style={[
                  styles.tableCell,
                  {
                    borderRight: 1,
                    fontWeight: "medium",
                    fontSize: 9,
                    flex: 7,
                    textAlign: "center",
                    width: 100,
                  },
                ]}
              >
                Packing Charges
              </Text>
              <Text
                style={[
                  styles.tableCell,
                  {
                    borderTop: 1,
                    borderRight: 0,
                    fontWeight: "medium",
                    height: 25,
                    fontSize: 8,
                    flex: 5,
                    textAlign: "center",
                    flexWrap: "wrap",
                  },
                ]}
              >
                545665
              </Text>
            </View>

            {/* Other Expenses */}
            <View
              style={[
                styles.tableRow,
                {
                  marginTop: -10,
                  borderRight: 0,
                  fontSize: 5,
                  height: 30,
                  flexDirection: "row",
                  width: "100%",
                  marginLeft: 0,
                  paddingLeft: 330,
                  marginBottom: 5,
                },
              ]}
            >
              <Text
                style={[
                  styles.tableCell,
                  {
                    borderRight: 1,
                    fontWeight: "medium",
                    fontSize: 9,
                    flex: 7,
                    textAlign: "center",
                    width: 100,
                  },
                ]}
              >
                Other Expenses
              </Text>

              <Text
                style={[
                  styles.tableCell,
                  {
                    borderTop: 1,
                    borderRight: 0,
                    fontWeight: "medium",
                    height: 25,
                    fontSize: 9,
                    flex: 5,
                    textAlign: "center",
                    flexWrap: "wrap",
                  },
                ]}
              >
                54565
              </Text>
            </View>

            {/* total */}
            <View
              style={[
                styles.tableRow,
                {
                  marginTop: -10,
                  borderRight: 0,
                  fontSize: 5,
                  height: 50,
                  flexDirection: "row",
                  width: "100%",
                  marginLeft: 0,
                  paddingLeft: 330,
                  marginBottom: 5,
                },
              ]}
            >
              <Text
                style={[
                  styles.tableCell,
                  {
                    borderRight: 1,
                    fontWeight: "medium",
                    fontSize: 9,
                    flex: 7,
                    textAlign: "center",
                    width: 100,
                  },
                ]}
              >
                Total
              </Text>

              <Text
                style={[
                  styles.tableCell,
                  {
                    borderTop: 1,
                    borderRight: 0,
                    fontWeight: "medium",
                    height: 25,
                    fontSize: 8,
                    flex: 5,
                    textAlign: "center",
                    flexWrap: "wrap",
                  },
                ]}
              >
                579,490.00
              </Text>
            </View>

            {/* SGST */}
            <View
              style={[
                styles.tableRow,
                {
                  marginTop: -30,
                  borderRight: 0,
                  fontSize: 9,
                  height: 30,
                  flexDirection: "row",
                  width: "100%",
                  marginLeft: 0,
                  paddingLeft: 330,
                  marginBottom: 5,
                },
              ]}
            >
              <Text
                style={[
                  styles.tableCell,
                  {
                    borderRight: 1,
                    fontWeight: "medium",
                    fontSize: 8,
                    flex: 7,
                    textAlign: "center",
                    width: 100,
                  },
                ]}
              >
                SGST 9%
              </Text>

              <Text
                style={[
                  styles.tableCell,
                  {
                    borderTop: 1,
                    borderRight: 0,
                    fontWeight: "medium",
                    height: 25,
                    fontSize: 8,
                    flex: 5,
                    textAlign: "center",
                    flexWrap: "wrap",
                  },
                ]}
              >
                52,154.10
              </Text>
            </View>

            {/* CGST */}
            <View
              style={[
                styles.tableRow,
                {
                  marginTop: -15,
                  borderRight: 0,
                  fontSize: 9,
                  height: 30,
                  flexDirection: "row",
                  width: "100%",
                  marginLeft: 0,
                  paddingLeft: 330,
                  marginBottom: 5,
                },
              ]}
            >
              <Text
                style={[
                  styles.tableCell,
                  {
                    borderRight: 1,
                    fontWeight: "medium",
                    fontSize: 8,
                    flex: 7,
                    textAlign: "center",
                    width: 100,
                  },
                ]}
              >
                CGST 9%
              </Text>

              <Text
                style={[
                  styles.tableCell,
                  {
                    borderTop: 1,
                    borderRight: 0,
                    fontWeight: "medium",
                    height: 25,
                    fontSize: 8,
                    flex: 5,
                    textAlign: "center",
                    flexWrap: "wrap",
                  },
                ]}
              >
                52,154.10
              </Text>
            </View>

            {/* IGST */}
            <View
              style={[
                styles.tableRow,
                {
                  marginTop: -15,
                  borderRight: 0,
                  fontSize: 9,
                  height: 30,
                  flexDirection: "row",
                  width: "100%",
                  marginLeft: 0,
                  paddingLeft: 330,
                  marginBottom: 5,
                },
              ]}
            >
              <Text
                style={[
                  styles.tableCell,
                  {
                    height: 23,
                    borderRight: 1,
                    fontWeight: "medium",
                    fontSize: 8,
                    flex: 7,
                    textAlign: "center",
                    width: 100,
                  },
                ]}
              >
                IGST 0%
              </Text>

              <Text
                style={[
                  styles.tableCell,
                  {
                    borderBottom: 1,
                    borderTop: 1,
                    borderRight: 0,
                    fontWeight: "medium",
                    height: 23,
                    fontSize: 8,
                    flex: 5,
                    textAlign: "center",
                    flexWrap: "wrap",
                  },
                ]}
              >
                0
              </Text>
            </View>

            {/* total amount in INR */}
            <View
              style={[
                styles.tableRow,
                {
                  marginTop: -10,
                  borderRight: 0,
                  fontSize: 9,
                  height: 30,
                  flexDirection: "row",
                  width: "100%",
                  marginLeft: 0,
                  paddingLeft: 330,
                  marginBottom: 5,
                },
              ]}
            >
              <Text
                style={[
                  styles.tableCell,
                  {
                    height: 23,
                    borderRight: 0,
                    fontWeight: "bold",
                    fontSize: 9,
                    flex: 7,
                    textAlign: "center",
                    width: 100,
                  },
                ]}
              >
                Total Amount in INR
              </Text>

              <Text
                style={[
                  styles.tableCell,
                  {
                    borderBottom: 0,
                    borderTop: 0,
                    borderRight: 0,
                    fontWeight: "bold",
                    height: 23,
                    fontSize: 9,
                    flex: 5,
                    textAlign: "center",
                    flexWrap: "wrap",
                  },
                ]}
              >
                683,798.00
              </Text>
            </View>

            <View
              style={[
                styles.tableRow,
                {
                  marginTop: -10,
                  borderRight: 0,
                  fontSize: 9,
                  height: 30,
                  flexDirection: "row",
                  width: "100%",
                  marginLeft: 0,
                  paddingLeft: 200,
                  marginBottom: 5,
                },
              ]}
            >
              <Text
                style={[
                  styles.tableCell,
                  {
                    height: 30,
                    borderRight: 0,
                    fontWeight: "bold",
                    fontSize: 8,
                    flex: 7,
                    textAlign: "center",
                    width: 300,
                  },
                ]}
              >
                Total Six Lakh Eighty Three Thousand Seven Hundred Nighty Eight
                Cent Twenty
              </Text>
            </View>

            {/* notes */}
            <View style={[styles.sideBySideContainer]}>
              <View style={[styles.contentPadding]}>
                <Text
                  style={[
                    styles.boldText,
                    { backgroundColor: "#958f8fac", width: 30, marginTop: -15 },
                  ]}
                >
                  Notes
                </Text>
                <Text
                  style={{
                    fontWeight: "light",
                    justifyContent: "space-around",
                    fontSize: 8,
                    paddingLeft: 20,
                  }}
                >
                  1 Goods One Sold Will Not Be Accepted{" "}
                </Text>
                <Text
                  style={{
                    fontWeight: "light",
                    justifyContent: "space-around",
                    fontSize: 8,
                    paddingLeft: 20,
                  }}
                >
                  2 Subject Of JamnagarJurisdiction Only.{" "}
                </Text>
                <Text
                  style={{
                    fontWeight: "light",
                    justifyContent: "space-around",
                    fontSize: 8,
                    paddingLeft: 20,
                  }}
                >
                  3 E. & O.E.
                </Text>
              </View>
            </View>

            {/* signature */}

            <View
              style={{
                flex: 1,
                borderBottomWidth: 3,
                borderColor: "#000",
                marginLeft: 350,
                position: "relative",
              }}
            ></View>

            {/* Center Text */}
            <Text
              style={{
                fontSize: 8,
                textAlign: "center",
                marginLeft: 350,
                marginTop: 5,
                fontWeight: "bold",
              }}
            >
              For,Kamani Export
            </Text>
            <Text
              style={{
                fontSize: 8,
                textAlign: "center",
                marginLeft: 350,
                fontWeight: "bold",
              }}
            >
              (Signature)
            </Text>
            <View>
              <Text
                style={{
                  fontSize: 10,
                  textAlign: "center",
                  marginRight: 300,
                  marginBottom: 10,
                  marginTop: -15,
                  fontWeight: "bold",
                }}
              >
                Thank You For Your Business
              </Text>
            </View>
            <View
              style={{
                marginBottom: 10,
                flex: 1,
                borderBottomWidth: 3,
                borderColor: "#000",
                marginRight: 30,
                position: "relative",
              }}
            ></View>

            <Text
              style={{
                fontWeight: "light",
                fontSize: 8,
                marginBottom: 12,
                marginTop: -8,
                marginLeft: 20,
              }}
            >
              Kamani Exports Office No. 410/411, Unicorn Prime, Ranjitsagar
              Road, Jamnagar 361005 India Mobile No. +91 7819834222
            </Text>
          </View>
        </Page>
      </Document>
    </PDFViewer>
  );
};
export default BrokerInvoice;
