import { Link, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { createSealNumberApi } from "../../services/_sealNumber";
import { errorToast, successToast } from "../../components/Toast";
import { useState } from "react";

const CreateSealNumber = () => {
  const initialValues = { prefix: "", start_no: "", end_no: "" };
  const [records, setRecords] = useState<any>([]);
  const [isSubmit, setIsSubmit] = useState<any>(false);
  const navigate = useNavigate();

  const createSealNumberValidationSchema = Yup.object().shape({
    prefix: Yup.string().required("Prefix is required"),
    start_no: Yup.number()
      .typeError("Enter a valid seal number")
      .required("Start Seal Number is required"),
    end_no: Yup.number()
      .typeError("Enter a valid seal number")
      .required("End Seal Number is required")
      .test(
        "is-greater",
        "End Seal number must be greater then Start Seal Number",
        function (value) {
          return value && this.parent.start_no <= value ? true : false;
        }
      ),
  });

  const generateSealNumbers = ({ prefix, start_no, end_no }: any) => {
    const n = end_no - start_no + 1;
    const data = new Array(n);
    const paddingLength = start_no.length;

    for (let i = 0; i < n; i++) {
      data[i] = {
        prefix,
        suffix: (Number(start_no) + i).toString().padStart(paddingLength, "0"),
        seal_no: `${prefix}${(Number(start_no) + i)
          .toString()
          .padStart(paddingLength, "0")}`,
      };
    }
    return data;
  };

  const handleGenerateNumber = async (values: any) => {
    values.prefix = values.prefix.toUpperCase();
    const data = generateSealNumbers(values);
    setRecords(data);
    if (data.length > 0) {
      setIsSubmit(true);
    }
  };

  const handleSubmit = async () => {
    if (isSubmit) {
      const result: any = await createSealNumberApi(records);
      if (result.isSuccess) {
        successToast(result.message);
        navigate("/seal-number-list");
      } else {
        errorToast(result.message);
      }
      return;
    }
  };

  return (
    <>
      <div className="card">
        <div className="card-header pb-0">
          <div>
            <h5>Create Seal Number</h5>
          </div>
        </div>
        <div className="card-body">
          <Formik
            enableReinitialize={true}
            initialValues={initialValues}
            validationSchema={createSealNumberValidationSchema}
            onSubmit={handleGenerateNumber}
          >
            {({ values, handleChange, setFieldValue }) => (
              <Form className="dt_adv_search">
                <div className="row">
                  <div className="col-12">
                    <div className="row g-3">
                      <div className="col-12 col-sm-4 col-lg-3">
                        <label
                          htmlFor="prefix"
                          className="form-label"
                          style={{ fontSize: "0.9375rem" }}
                        >
                          Prefix: <span className="text-danger">*</span>
                        </label>
                        <Field
                          id="prefix"
                          name="prefix"
                          type="text"
                          className="form-control"
                          placeholder="Enter Prefix"
                          value={values.prefix}
                          onChange={(e: any) => {
                            e.target.value = e.target.value.toUpperCase();
                            handleChange(e);
                          }}
                        />
                        <ErrorMessage
                          name="prefix"
                          className="text-danger"
                          component="div"
                        />
                      </div>
                      <div className="col-12 col-sm-4 col-lg-3">
                        <label
                          htmlFor="start_no"
                          className="form-label"
                          style={{ fontSize: "0.9375rem" }}
                        >
                          Start Seal No: <span className="text-danger">*</span>
                        </label>
                        <Field
                          id="start_no"
                          name="start_no"
                          type="text"
                          className="form-control"
                          placeholder="Enter Seal No"
                          value={values.start_no}
                          onChange={(e: any) => {
                            handleChange(e);
                            setFieldValue(
                              "end_no",
                              values.end_no && values.end_no > e.target.value
                                ? values.end_no
                                : e.target.value
                            );
                          }}
                        />
                        <ErrorMessage
                          name="start_no"
                          className="text-danger"
                          component="div"
                        />
                      </div>
                      <div className="col-12 col-sm-4 col-lg-3">
                        <label
                          htmlFor="end_no"
                          className="form-label"
                          style={{ fontSize: "0.9375rem" }}
                        >
                          End Seal No: <span className="text-danger">*</span>
                        </label>
                        <Field
                          id="end_no"
                          name="end_no"
                          type="text"
                          className="form-control"
                          placeholder="Enter Seal No"
                          value={
                            values.end_no ? values.end_no : values.start_no
                          }
                          onChange={handleChange}
                        />
                        <ErrorMessage
                          name="end_no"
                          className="text-danger"
                          component="div"
                        />
                      </div>
                      <div className="col-12 col-sm-4 col-lg-3 d-flex align-items-center pt-4">
                        <button type="submit" className="btn btn-primary">
                          Generate
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </Form>
            )}
          </Formik>

          <div className="row justify-content-center mt-5">
            <label
              htmlFor="Seal_no"
              className="form-label"
              style={{
                fontSize: "0.9375rem",
                marginBottom: "10px",
              }}
            >
              Seal Number List:
            </label>
            <div className="col-12 col-sm-12 col-lg-12">
              <div
                role="group"
                aria-labelledby="checkbox-group"
                className="row m-1 p-2"
                style={{
                  border: "1px solid #d1d0d4",
                  borderRadius: "0.375rem",
                }}
              >
                {records && records.length > 0 ? (
                  records.map((item: any, i: number) => (
                    <div key={i} className="col-12 col-sm-6 col-md-4 col-lg-3">
                      <b>
                        {i + 1}. {""}
                      </b>
                      {item.seal_no}
                    </div>
                  ))
                ) : (
                  <div className="col-12" style={{ height: "100px" }}>
                    Generate The Seal Numbers
                  </div>
                )}
              </div>
            </div>
          </div>

          <div className="col-12 mt-5">
            <button
              type="submit"
              className="btn btn-primary"
              disabled={!isSubmit}
              onClick={handleSubmit}
            >
              Submit
            </button>
            <Link
              to={"/seal-number-list"}
              type="button"
              className="btn btn-label-github ms-5"
            >
              Back
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default CreateSealNumber;
