/* eslint-disable react-hooks/exhaustive-deps */
import * as Yup from "yup";
import { useEffect, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { Link, useNavigate, useParams } from "react-router-dom";
import { errorToast, successToast } from "../../components/Toast";
import {
  getPackingTypeByIdApi,
  updatePackingTypeApi,
} from "../../services/_packingTypes";

const EditPackingType = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [updateLoading, setUpdateLoading] = useState<boolean>(false);
  const [initialValues, setInitialValues] = useState({
    name: "",
    description: "",
    status: "",
  });

  const getPortDetails = async () => {
    const result: any = await getPackingTypeByIdApi(id as string);
    if (result.isSuccess) {
      setInitialValues({
        name: result?.data?.name,
        description: result?.data?.description,
        status: result?.data?.status?.toString(),
      });
    }
  };

  useEffect(() => {
    if (!id) {
      navigate("/packing-type-list");
    } else {
      getPortDetails();
    }
  }, []);

  const createPackingTypeValidationSchema = Yup.object().shape({
    name: Yup.string().required("Name is required"),
    description: Yup.string(),
    status: Yup.string().required("Status is required"),
  });

  const handleSubmit = async (values: any, { resetForm }: any) => {
    setUpdateLoading(true);
    values.status = values.status === "true" ? true : false;
    values.name = values.name.toUpperCase();

    const result: any = await updatePackingTypeApi(id as string, values);
    if (result.isSuccess) {
      setUpdateLoading(false);
      resetForm({ values: initialValues });
      successToast(result.message);
      navigate("/packing-type-list");
    } else {
      setUpdateLoading(false);
      errorToast(result.message);
    }
    return;
  };

  return (
    <>
      <div className="card">
        <div className="card-header pb-0">
          <div>
            <h5>Edit PackingType</h5>
          </div>
        </div>
        <div className="card-body">
          <Formik
            enableReinitialize={true}
            initialValues={initialValues}
            validationSchema={createPackingTypeValidationSchema}
            onSubmit={handleSubmit}
          >
            {({ values, handleChange }) => {
              return (
                <Form className="dt_adv_search">
                  <div className="row">
                    <div className="col-12">
                      <div className="row g-3">
                        <div className="col-12 col-sm-6 col-lg-4">
                          <label
                            htmlFor="name"
                            className="form-label"
                            style={{ fontSize: "0.9375rem" }}
                          >
                            Name: <span className="text-danger">*</span>
                          </label>
                          <Field
                            id="name"
                            name="name"
                            type="text"
                            className="form-control"
                            placeholder="Enter name"
                            onChange={(e: any) => {
                              e.target.value = e.target.value.toUpperCase();
                              handleChange(e);
                            }}
                          />
                          <ErrorMessage
                            name="name"
                            className="text-danger"
                            component="div"
                          />
                        </div>
                        <div className="col-12 col-sm-6 col-lg-4">
                          <label
                            htmlFor="description"
                            className="form-label"
                            style={{ fontSize: "0.9375rem" }}
                          >
                            Description: <span className="text-danger">*</span>
                          </label>
                          <Field
                            id="description"
                            name="description"
                            type="text"
                            className="form-control"
                            placeholder="Enter description"
                            onChange={handleChange}
                          />
                          <ErrorMessage
                            name="description"
                            className="text-danger"
                            component="div"
                          />
                        </div>
                        <div className="col-12 col-sm-6 col-lg-4">
                          <label
                            htmlFor="status"
                            className="form-label"
                            style={{ fontSize: "0.9375rem" }}
                          >
                            Status: <span className="text-danger">*</span>
                          </label>
                          <select
                            id="status"
                            name="status"
                            className="form-select"
                            onChange={handleChange}
                            value={values?.status}
                          >
                            <option value="">Select Status</option>
                            <option value="true">Active</option>
                            <option value="false">Inactive</option>
                          </select>
                          <ErrorMessage
                            name="status"
                            className="text-danger"
                            component="div"
                          />
                        </div>
                        <div className="col-12">
                          <button
                            type="submit"
                            className="btn btn-primary"
                            disabled={updateLoading}
                          >
                            {updateLoading && (
                              <span
                                className="spinner-border spinner-border-sm me-2"
                                role="status"
                                aria-hidden="true"
                              ></span>
                            )}
                            Submit
                          </button>
                          <Link
                            to={"/packing-type-list"}
                            type="button"
                            className="btn btn-label-github ms-5"
                          >
                            Back
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </Form>
              );
            }}
          </Formik>
        </div>
      </div>
    </>
  );
};

export default EditPackingType;
