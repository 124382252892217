import { Field, FieldArray, ErrorMessage, FormikValues } from "formik";
import { containerInitialValues } from "../helpers/SalesInvoiceHelper";
import { Autocomplete, TextField } from "@mui/material";
interface ContainerFieldsProps {
  values: FormikValues;
  setFieldValue: any;
  sealNumbersList: any;
  unitList: any;
  packingType: any;
  noOfContainers: string;
  setNoOfContainers: (value: string) => void;
  isShowContainerButton: any;
  handleChange: (e: React.ChangeEvent<any>) => void;
  setIsShowContainerButton: any;
  setAddUnitModalStatus: (show: boolean) => void;
  setAddPackingTypeModalStatus: (show: boolean) => void;
  calculateWeight: any;
}

const ContainerFields: React.FC<ContainerFieldsProps> = ({
  values,
  setFieldValue,
  sealNumbersList,
  unitList,
  packingType,
  noOfContainers,
  setNoOfContainers,
  isShowContainerButton,
  handleChange,
  setIsShowContainerButton,
  setAddUnitModalStatus,
  setAddPackingTypeModalStatus,
  calculateWeight,
}) => {
  return (
    <div className="card my-card">
      <div className="card-header my-card-header bg-lighter d-flex justify-content-between">
        <div>
          <h6 className="my-card-header-head">Containers</h6>
        </div>
        {isShowContainerButton && (
          <div>
            <button
              title="Add Containers"
              className="btn btn-sm btn-primary waves-effect waves-light p-0"
              type="button"
              style={{ margin: "5px" }}
              onClick={() => {
                setIsShowContainerButton(false);
              }}
            >
              <span>
                <i className="ti ti-plus my-card-icon-button"></i>
              </span>
            </button>
          </div>
        )}
      </div>
      {!isShowContainerButton && (
        <div className="card-body my-card-body">
          <FieldArray
            name="container"
            render={({ push, remove }) => (
              <>
                <div className="row g-3 m-0">
                  <div className="col-12 col-sm-6 col-md-4 col-lg-3">
                    <input
                      type="number"
                      min={1}
                      className="form-control dt-input dt-full-name"
                      placeholder="No. of containers"
                      value={noOfContainers}
                      onChange={(e) => {
                        setNoOfContainers(e.target.value);
                      }}
                    />
                  </div>
                  <div className="col-12 col-sm-6 col-lg-4">
                    <button
                      type="button"
                      className="btn btn-primary"
                      onClick={() => {
                        for (let i = 0; i < Number(noOfContainers); i++) {
                          push(containerInitialValues);
                        }
                        setNoOfContainers("");
                      }}
                    >
                      Add
                    </button>
                  </div>
                </div>
                {values?.container?.length > 0 &&
                  values?.container.map((_: any, index: any) => (
                    <div className="sales_section_card" key={index}>
                      <div className="row g-3 m-0">
                        <div className="col-12 col-sm-6 col-md-4 col-lg-3">
                          <label
                            htmlFor={`container[${index}].container_no`}
                            className="form-label"
                            style={{ fontSize: "0.9375rem" }}
                          >
                            Container No: <span className="text-danger">*</span>
                          </label>
                          <Field
                            name={`container[${index}].container_no`}
                            type="text"
                            className="form-control"
                            placeholder="Enter Container No"
                            onChange={handleChange}
                          />
                          <ErrorMessage
                            name={`container[${index}].container_no`}
                            component="div"
                            className="text-danger"
                          />
                        </div>
                        <div className="col-12 col-sm-6 col-md-4 col-lg-3">
                          <label
                            htmlFor={`container[${index}].lot_no`}
                            className="form-label"
                            style={{ fontSize: "0.9375rem" }}
                          >
                            Lot No:
                          </label>
                          <Field
                            name={`container[${index}].lot_no`}
                            type="text"
                            className="form-control"
                            placeholder="Enter Lot No"
                            onChange={handleChange}
                          />
                          <ErrorMessage
                            name={`container[${index}].lot_no`}
                            component="div"
                            className="text-danger"
                          />
                        </div>
                        <div className="col-12 col-sm-6 col-md-4 col-lg-3">
                          <label
                            htmlFor={`container[${index}].lot_label_seal`}
                            className="form-label"
                            style={{ fontSize: "0.9375rem" }}
                          >
                            Lot Label Seal:
                          </label>
                          <Field
                            name={`container[${index}].lot_label_seal`}
                            type="text"
                            className="form-control"
                            placeholder="Enter Lot Label Seal"
                            onChange={handleChange}
                          />
                          <ErrorMessage
                            name={`container[${index}].lot_label_seal`}
                            component="div"
                            className="text-danger"
                          />
                        </div>
                        <div className="col-12 col-sm-6 col-md-4 col-lg-3">
                          <label
                            htmlFor={`container[${index}].line_seal_no`}
                            className="form-label"
                            style={{ fontSize: "0.9375rem" }}
                          >
                            Line Seal No:
                          </label>
                          <Field
                            name={`container[${index}].line_seal_no`}
                            type="text"
                            className="form-control"
                            placeholder="Enter Line Seal No"
                            onChange={handleChange}
                          />
                          <ErrorMessage
                            name={`container[${index}].line_seal_no`}
                            component="div"
                            className="text-danger"
                          />
                        </div>
                        <div className="col-12 col-sm-6 col-md-4 col-lg-3">
                          <label
                            htmlFor={`container[${index}].self_seal_id`}
                            className="form-label"
                            style={{ fontSize: "0.9375rem" }}
                          >
                            Self Seal No:
                          </label>
                          <Autocomplete
                            disablePortal
                            options={sealNumbersList.filter(
                              (seal: any) =>
                                !values.container.some(
                                  (container: any, containerIndex: number) =>
                                    containerIndex !== index &&
                                    container.self_seal_id === seal.id
                                )
                            )}
                            getOptionLabel={(option: any) =>
                              option.seal_no || ""
                            }
                            isOptionEqualToValue={(option, value) =>
                              option.id === value.id
                            }
                            onChange={(e, newValue): any => {
                              setFieldValue(
                                `container[${index}].self_seal_id`,
                                newValue?.id || ""
                              );
                            }}
                            value={
                              sealNumbersList.find(
                                (item: any) =>
                                  item.id ===
                                  values.container[index].self_seal_id
                              ) || ""
                            }
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                className="form-control"
                                type="text"
                                placeholder="Enter Name"
                              />
                            )}
                          />
                          <ErrorMessage
                            name={`container[${index}].self_seal_id`}
                            component="div"
                            className="text-danger"
                          />
                        </div>
                        <div className="col-12 col-sm-6 col-md-4 col-lg-3">
                          <label
                            htmlFor={`container[${index}].sticker_no`}
                            className="form-label"
                            style={{ fontSize: "0.9375rem" }}
                          >
                            Sticker No:{" "}
                          </label>
                          <Field
                            name={`container[${index}].sticker_no`}
                            type="text"
                            className="form-control"
                            placeholder="Enter Sticker No"
                            onChange={handleChange}
                          />
                          <ErrorMessage
                            name={`container[${index}].sticker_no`}
                            component="div"
                            className="text-danger"
                          />
                        </div>
                        <div className="col-12 col-sm-6 col-md-4 col-lg-3">
                          <label
                            htmlFor={`container[${index}].vehicle_no`}
                            className="form-label"
                            style={{ fontSize: "0.9375rem" }}
                          >
                            Vehicle No:
                          </label>
                          <Field
                            name={`container[${index}].vehicle_no`}
                            type="text"
                            className="form-control"
                            placeholder="Enter Vehicle No"
                            onChange={handleChange}
                          />
                          <ErrorMessage
                            name={`container[${index}].vehicle_no`}
                            component="div"
                            className="text-danger"
                          />
                        </div>
                        <div className="col-12 col-sm-6 col-md-4 col-lg-3">
                          <label
                            htmlFor={`container[${index}].size`}
                            className="form-label"
                            style={{ fontSize: "0.9375rem" }}
                          >
                            Size:{" "}
                          </label>
                          <Field
                            name={`container[${index}].size`}
                            type="text"
                            className="form-control"
                            placeholder="Enter Size"
                            onChange={handleChange}
                          />
                          <ErrorMessage
                            name={`container[${index}].size`}
                            component="div"
                            className="text-danger"
                          />
                        </div>
                        {/* <div className="col-12 col-sm-6 col-md-4 col-lg-3">
                          <label
                            htmlFor={`container[${index}].quantity`}
                            className="form-label"
                            style={{ fontSize: "0.9375rem" }}
                          >
                            Quantity:
                          </label>
                          <Field
                            name={`container[${index}].quantity`}
                            type="number"
                            className="form-control"
                            placeholder="Enter Quantity"
                            value={
                              values?.container[index].quantity ||
                              undefined
                            }
                            onChange={handleChange}
                          />
                          <ErrorMessage
                            name={`container[${index}].quantity`}
                            component="div"
                            className="text-danger"
                          />
                        </div> */}
                        <div className="col-12 col-sm-6 col-md-4 col-lg-3">
                          <label
                            htmlFor={`container[${index}].no_of_bags`}
                            className="form-label"
                            style={{ fontSize: "0.9375rem" }}
                          >
                            No of Bags:
                          </label>
                          <Field
                            name={`container[${index}].no_of_bags`}
                            type="number"
                            className="form-control"
                            placeholder="Enter No of Bags"
                            value={
                              values?.container[index].no_of_bags || undefined
                            }
                            onChange={(e: any) => {
                              handleChange(e);
                              values.container[index].no_of_bags =
                                e.target.value;
                              calculateWeight(values, setFieldValue);
                            }}
                          />
                          <ErrorMessage
                            name={`container[${index}].no_of_bags`}
                            component="div"
                            className="text-danger"
                          />
                        </div>
                        {/* <div className="col-12 col-sm-6 col-md-4 col-lg-3">
                          <div className="d-flex justify-content-between">
                            <div>
                              <label
                                htmlFor={`container[${index}].packing_type_id`}
                                className="form-label"
                                style={{ fontSize: "0.9375rem" }}
                              >
                                Packing Type:
                              </label>
                            </div>
                            <div>
                              <button
                                title="Add Packing Type"
                                className="btn btn-sm btn-primary waves-effect waves-light p-0"
                                type="button"
                                onClick={() =>
                                  setAddPackingTypeModalStatus(true)
                                }
                              >
                                <span>
                                  <i className="ti ti-plus my-card-icon-button"></i>
                                </span>
                              </button>
                            </div>
                          </div>
                          <select
                            id={`container[${index}].packing_type_id`}
                            name={`container[${index}].packing_type_id`}
                            className="form-select"
                            value={values.container[index].packing_type_id}
                            onChange={handleChange}
                          >
                            <option defaultChecked value={""}>
                              Select Packing Types
                            </option>
                            {packingType.map((packing: any) => (
                              <option key={packing.id} value={packing.id}>
                                {packing.name}
                              </option>
                            ))}
                          </select>
                          <ErrorMessage
                            name={`container[${index}].packing_type_id`}
                            component="div"
                            className="text-danger"
                          />
                        </div> */}
                        <div className="col-12 col-sm-6 col-md-4 col-lg-3">
                          <label
                            htmlFor={`container[${index}].total_net_weight`}
                            className="form-label"
                            style={{ fontSize: "0.9375rem" }}
                          >
                            Total Net Weight (KGS):
                          </label>
                          <Field
                            name={`container[${index}].total_net_weight`}
                            type="number"
                            className="form-control"
                            placeholder="Enter Total Net Weight (KGS)"
                            value={
                              values?.container[index].total_net_weight ||
                              undefined
                            }
                            onChange={handleChange}
                          />
                          <ErrorMessage
                            name={`container[${index}].total_net_weight`}
                            component="div"
                            className="text-danger"
                          />
                        </div>
                        {/* <div className="col-12 col-sm-6 col-md-4 col-lg-3">
                          <div className="d-flex justify-content-between">
                            <div>
                              <label
                                htmlFor={`container[${index}].net_weight_unit_id`}
                                className="form-label"
                                style={{ fontSize: "0.9375rem" }}
                              >
                                Net Weight Unit:
                              </label>
                            </div>
                            <div>
                              <button
                                title="Add Unit"
                                className="btn btn-sm btn-primary waves-effect waves-light p-0"
                                type="button"
                                onClick={() => setAddUnitModalStatus(true)}
                              >
                                <span>
                                  <i className="ti ti-plus my-card-icon-button"></i>
                                </span>
                              </button>
                            </div>
                          </div>
                          <select
                            id={`container[${index}].net_weight_unit_id`}
                            name={`container[${index}].net_weight_unit_id`}
                            className="form-select"
                            value={values.container[index].net_weight_unit_id}
                            onChange={handleChange}
                          >
                            <option defaultChecked value={""}>
                              Select Net Weight Unit
                            </option>
                            {unitList.map((unit: any) => (
                              <option key={unit.id} value={unit.id}>
                                {unit.name}
                              </option>
                            ))}
                          </select>
                          <ErrorMessage
                            name={`container[${index}].net_weight_unit_id`}
                            component="div"
                            className="text-danger"
                          />
                        </div> */}
                        <div className="col-12 col-sm-6 col-md-4 col-lg-3">
                          <label
                            htmlFor={`container[${index}].total_gross_weight`}
                            className="form-label"
                            style={{ fontSize: "0.9375rem" }}
                          >
                            Total Gross Weight (KGS):
                          </label>
                          <Field
                            name={`container[${index}].total_gross_weight`}
                            type="number"
                            className="form-control"
                            placeholder="Enter Total Gross Weight (KGS)"
                            value={
                              values?.container[index].total_gross_weight ||
                              undefined
                            }
                            onChange={handleChange}
                          />
                          <ErrorMessage
                            name={`container[${index}].total_gross_weight`}
                            component="div"
                            className="text-danger"
                          />
                        </div>
                        {/* <div className="col-12 col-sm-6 col-md-4 col-lg-3">
                          <label
                            htmlFor={`container[${index}].gross_weight_unit_id`}
                            className="form-label"
                            style={{ fontSize: "0.9375rem" }}
                          >
                            Gross Weight Unit:
                          </label>
                          <select
                            id={`container[${index}].gross_weight_unit_id`}
                            name={`container[${index}].gross_weight_unit_id`}
                            className="form-select"
                            value={values.container[index].gross_weight_unit_id}
                            onChange={handleChange}
                          >
                            <option defaultChecked value={""}>
                              Select Gross Weight Unit
                            </option>
                            {unitList.map((unit: any) => (
                              <option key={unit.id} value={unit.id}>
                                {unit.name}
                              </option>
                            ))}
                          </select>
                          <ErrorMessage
                            name={`container[${index}].gross_weight_unit_id`}
                            component="div"
                            className="text-danger"
                          />
                        </div> */}
                        <div className="col-12 col-sm-6 col-md-4 col-lg-3">
                          <label
                            htmlFor={`container[${index}].remark`}
                            className="form-label"
                            style={{ fontSize: "0.9375rem" }}
                          >
                            Remark:
                          </label>
                          <Field
                            name={`container[${index}].remark`}
                            type="text"
                            className="form-control"
                            placeholder="Enter Remark"
                            onChange={handleChange}
                          />
                          <ErrorMessage
                            name={`container[${index}].remark`}
                            component="div"
                            className="text-danger"
                          />
                        </div>

                        <i
                          title="Remove Container"
                          className="fa-solid fa-xmark sales_document_btn"
                          onClick={() => {
                            remove(index);
                          }}
                        ></i>
                      </div>
                    </div>
                  ))}
              </>
            )}
          />
        </div>
      )}
    </div>
  );
};
export default ContainerFields;
