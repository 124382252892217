import { GET, POST, PUT } from "./_config";

export const expensesListApi = async (query: string) =>
  await GET(`/expenses?${query || ""}`, true);

export const getAllExpensesApi = async () => await GET(`/expenses/list`, true);

export const createExpensesApi = async (body: any) =>
  await POST(`/expenses`, body, true);

export const getExpensesByIdApi = async (expensesId: string) =>
  await GET(`/expenses/id/${expensesId}`, true);

export const updateExpensesApi = async (expensesId: string, body: any) =>
  await PUT(`/expenses?id=${expensesId}`, body, true);

export const updateExpensesStatusApi = async (body: any) =>
  await POST(`/expenses/update-status`, body, true);

export const getExpenseListApi = async () =>
  await GET(`/expenses/list`, true);

const ExpensesService = {
  expensesListApi,
  getAllExpensesApi,
  createExpensesApi,
  getExpensesByIdApi,
  updateExpensesApi,
  updateExpensesStatusApi,
  getExpenseListApi,
};

export default ExpensesService;
