import {
  Document,
  Page,
  Text,
  View,
  Font,
  StyleSheet,
  Image,
} from "@react-pdf/renderer";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import {
  convertAmountToWords,
  formatAmount,
  formatDate,
  stringToUpperCase,
} from "../../../components/Helper";
import { CURRENCY } from "../../../components/Constants";

Font.register({
  family: "HostGrotesk",
  fonts: [
    { src: "/fonts/HostGrotesk-Regular.ttf" },
    { src: "/fonts/HostGrotesk-Bold.ttf", fontWeight: "bold" },
  ],
});

export const styles = StyleSheet.create({
  boldFont: {
    fontWeight: "bold",
  },
  boldText: {
    fontWeight: "bold",
    fontSize: 8,
  },
  smallText: {
    fontSize: 6,
  },
  mediumText: {
    fontSize: 7.5,
  },
  contentPadding: {
    padding: 1,
  },
  page: {
    paddingHorizontal: 30,
    paddingVertical: 15,
    fontFamily: "HostGrotesk",
    backgroundColor: "white",
  },
  mediumFontSize: {
    fontSize: 6.5,
  },
  regularFontSize: {
    fontSize: 7,
  },
  fullpage: {
    margin: 7,
    borderStyle: "solid",
    borderWidth: 1,
    borderColor: "#000000",
  },
  header: {
    fontSize: 10,
    textAlign: "center",
    padding: 1,
    marginBottom: 0,
    fontWeight: "bold",
    borderBottomWidth: 1,
    borderBottomColor: "black",
    borderBottomStyle: "solid",
  },
  section: {
    borderBottomWidth: 1,
    borderBottomColor: "black",
    borderbottomStyle: "solid",
  },
  sideBySideContainer: { flexDirection: "row" },
  halfWidthRightSection: {
    width: "50%",
    borderBottomWidth: 1,
    borderBottomColor: "black",
    borderbottomStyle: "solid",
    borderRightWidth: 1,
    borderRightColor: "black",
    borderRightStyle: "solid",
  },
  halfWidthleftSection: {
    width: "50%",
    borderBottomWidth: 1,
    borderBottomColor: "black",
    borderbottomStyle: "solid",
  },
  tableContainer: {
    borderBottomWidth: 1,
    borderBottomColor: "black",
  },
  tableRow: {
    flexDirection: "row",
    flexGrow: 1,
  },
  tableCell: {
    padding: 3,
    fontSize: 7,
    textAlign: "center",
    borderRightWidth: 1,
    borderRightColor: "black",
    borderRightStyle: "solid",
  },
  addressBlock: { fontSize: 7.5, marginBottom: 1.5 },
});

const MiscellaneousSalesInvoice = ({
  salesData,
  hsnWiseProductData,
  settingData,
}: {
  salesData: any;
  hsnWiseProductData: any;
  settingData: any;
}) => {
  let emptyRowHeight = 470;
  const hsnRowHeight = 10;
  const productRowHeight = 15;
  if (salesData?.miscellaneous_sales_items?.length > 0) {
    emptyRowHeight =
      470 -
      productRowHeight * salesData?.miscellaneous_sales_items?.length -
      hsnRowHeight * hsnWiseProductData?.length;
  }

  const rows = salesData?.miscellaneous_sales_items || [];
  const calculateTotal = (
    items: any[],
    key1: string,
    key2?: string
  ): string => {
    const total = items.reduce((acc, item) => {
      const value1 = Number(item[key1]) || 0;
      const value2 = key2 ? Number(item[key2]) || 0 : 0;
      return acc + value1 + value2;
    }, 0);
    return formatAmount(total);
  };

  return (
    <Document>
      <Page style={styles.page} size="A4">
        <View style={styles.fullpage}>
          {/* INVOICE TITLE */}
          <Text style={[styles.header]}>SALES INVOICE</Text>

          {/* BUYER | SUPPLIER | ORDER DETAILS | PAYMENT DETAILS */}
          <View style={styles.sideBySideContainer}>
            {/* BUYER */}
            <View style={[styles.halfWidthRightSection, styles.contentPadding]}>
              <Text style={styles.addressBlock}>
                <Text style={[styles.boldText]}>INVOICE TO : </Text>
                {stringToUpperCase(salesData?.buyer?.name)}
              </Text>
              <Text style={styles.addressBlock}>
                {stringToUpperCase(salesData?.buyer?.address)}
              </Text>
              {salesData?.buyer?.gst_no && (
                <Text style={styles.addressBlock}>
                  <Text style={[styles.boldText]}>GSTIN : </Text>
                  {stringToUpperCase(salesData?.buyer?.gst_no)}
                </Text>
              )}
              {salesData?.buyer?.pan_no && (
                <Text style={styles.addressBlock}>
                  <Text style={[styles.boldText]}>PAN NO : </Text>
                  {stringToUpperCase(salesData?.buyer?.pan_no)}
                </Text>
              )}
            </View>

            <View style={[styles.halfWidthleftSection, styles.mediumText]}>
              {/* ORDER NO | ORDER DATE */}
              <View style={[styles.sideBySideContainer, { display: "flex" }]}>
                <View
                  style={[
                    styles.contentPadding,
                    {
                      borderBottomWidth: 1,
                      borderBottomColor: "black",
                      borderBottomStyle: "solid",
                      borderRightWidth: 1,
                      borderRightColor: "black",
                      borderRightStyle: "solid",
                      flex: 2,
                    },
                  ]}
                >
                  <Text style={[styles.boldText]}>SALES ORDER NO :</Text>
                  <Text style={[styles.mediumText]}>
                    {salesData.invoice_no}
                  </Text>
                </View>
                <View
                  style={[
                    styles.contentPadding,
                    {
                      borderBottomWidth: 1,
                      borderBottomColor: "black",
                      borderBottomStyle: "solid",
                      flex: 1,
                    },
                  ]}
                >
                  <Text style={[styles.boldText]}>DATE :</Text>
                  <Text style={[styles.mediumText]}>
                    {formatDate(salesData.sale_date)}
                  </Text>
                </View>
              </View>

              {/* TERMS OF PAYMENT */}
              <View style={styles.section}>
                <Text>
                  <Text style={[styles.boldText]}> TERMS OF PAYMENT : </Text>
                  {stringToUpperCase(salesData?.terms_of_payment)}
                </Text>
              </View>

              {/* SUPPLIER */}
              <View style={styles.contentPadding}>
                <Text style={styles.addressBlock}>
                  <Text style={[styles.boldText]}>SUPPLIER (BILL FROM) : </Text>
                  {stringToUpperCase(settingData?.company_name)}
                </Text>
                <Text style={styles.addressBlock}>
                  {stringToUpperCase(settingData?.company_address)}
                </Text>
                <Text style={styles.addressBlock}>
                  <Text style={[styles.boldText]}>GSTIN : </Text>
                  {settingData.company_gst_no}
                </Text>
                <Text style={styles.addressBlock}>
                  <Text style={[styles.boldText]}>PAN NO : </Text>
                  {settingData.company_pan_no}
                </Text>
              </View>
            </View>
          </View>

          {/* PRODUCT DETAILS */}
          <View>
            <View style={{ borderBottom: 1 }}>
              {/* TABLE HEADER */}
              <View
                style={[
                  styles.tableRow,
                  {
                    borderBottom: 1,
                    flexDirection: "row",
                  },
                ]}
              >
                <Text
                  style={[
                    styles.boldText,
                    styles.mediumText,
                    {
                      padding: 2,
                      width: 25,
                      borderRight: 1,
                      textAlign: "center",
                    },
                  ]}
                >
                  SR NO
                </Text>
                <Text
                  style={[
                    styles.boldText,
                    styles.mediumText,
                    {
                      padding: 2,
                      paddingTop: 6,
                      width: 285,
                      borderRight: 1,
                      textAlign: "center",
                      verticalAlign: "sub",
                    },
                  ]}
                >
                  DESCRIPTION OF GOODS
                </Text>
                <Text
                  style={[
                    styles.boldText,
                    styles.mediumText,
                    {
                      padding: 2,
                      paddingTop: 6,
                      width: 60,
                      borderRight: 1,
                      textAlign: "center",
                      verticalAlign: "sub",
                    },
                  ]}
                >
                  HSN/SAC
                </Text>
                <Text
                  style={[
                    styles.boldText,
                    styles.mediumText,
                    {
                      padding: 2,
                      paddingTop: 6,
                      width: 75,
                      borderRight: 1,
                      textAlign: "center",
                      verticalAlign: "sub",
                    },
                  ]}
                >
                  QUANTITY
                </Text>
                <Text
                  style={[
                    styles.boldText,
                    styles.mediumText,
                    {
                      padding: 2,
                      paddingTop: 6,
                      width: 80,
                      borderRight: 1,
                      textAlign: "center",
                      verticalAlign: "sub",
                    },
                  ]}
                >
                  PRICE
                </Text>
                <Text
                  style={[
                    styles.boldText,
                    styles.mediumText,
                    {
                      padding: 2,
                      paddingTop: 6,
                      width: 95,
                      textAlign: "center",
                      verticalAlign: "sub",
                    },
                  ]}
                >
                  AMOUNT (INR)
                </Text>
              </View>

              {/* PRODUCT LIST */}
              {rows.map((row: any, index: number) => {
                return (
                  <View
                    key={index}
                    style={[
                      styles.tableRow,
                      {
                        // borderBottom: 1,
                        flexDirection: "row",
                      },
                    ]}
                  >
                    <Text
                      style={[
                        styles.mediumText,
                        {
                          padding: 2,
                          width: 25,
                          borderRight: 1,
                          textAlign: "center",
                        },
                      ]}
                    >
                      {index + 1}
                    </Text>
                    <Text
                      style={[
                        styles.mediumText,
                        {
                          padding: 2,
                          width: 285,
                          borderRight: 1,
                        },
                      ]}
                    >
                      {stringToUpperCase(row?.product?.name)}
                    </Text>
                    <Text
                      style={[
                        styles.mediumText,
                        {
                          padding: 2,
                          width: 60,
                          borderRight: 1,
                          textAlign: "center",
                        },
                      ]}
                    >
                      {stringToUpperCase(row?.product?.hsn_no)}
                    </Text>
                    <Text
                      style={[
                        styles.mediumText,
                        {
                          padding: 2,
                          width: 75,
                          borderRight: 1,
                          textAlign: "center",
                        },
                      ]}
                    >
                      {row.quantity} {stringToUpperCase(row?.unit?.name)}
                    </Text>
                    <Text
                      style={[
                        styles.mediumText,
                        {
                          padding: 2,
                          width: 80,
                          borderRight: 1,
                          textAlign: "center",
                        },
                      ]}
                    >
                      {formatAmount(row.price)}
                    </Text>
                    <Text
                      style={[
                        styles.mediumText,
                        {
                          padding: 2,
                          width: 95,
                          textAlign: "center",
                        },
                      ]}
                    >
                      {formatAmount(row.sub_total)}
                    </Text>
                  </View>
                );
              })}

              {/* EMPTY ROW */}
              <View
                style={[
                  styles.tableRow,
                  {
                    height: emptyRowHeight,
                    flexDirection: "row",
                  },
                ]}
              >
                <Text
                  style={[
                    styles.boldText,
                    styles.mediumText,
                    {
                      padding: 2,
                      width: 25,
                      borderRight: 1,
                      textAlign: "center",
                    },
                  ]}
                ></Text>
                <Text
                  style={[
                    styles.boldText,
                    styles.mediumText,
                    {
                      padding: 2,
                      width: 285,
                      borderRight: 1,
                      textAlign: "center",
                      verticalAlign: "sub",
                    },
                  ]}
                ></Text>
                <Text
                  style={[
                    styles.boldText,
                    styles.mediumText,
                    {
                      padding: 2,
                      width: 60,
                      borderRight: 1,
                      textAlign: "center",
                      verticalAlign: "sub",
                    },
                  ]}
                ></Text>
                <Text
                  style={[
                    styles.boldText,
                    styles.mediumText,
                    {
                      padding: 2,
                      width: 75,
                      borderRight: 1,
                      textAlign: "center",
                      verticalAlign: "sub",
                    },
                  ]}
                ></Text>
                <Text
                  style={[
                    styles.boldText,
                    styles.mediumText,
                    {
                      padding: 2,
                      width: 80,
                      borderRight: 1,
                      textAlign: "center",
                      verticalAlign: "sub",
                    },
                  ]}
                ></Text>
                <Text
                  style={[
                    styles.boldText,
                    styles.mediumText,
                    {
                      padding: 2,
                      width: 95,
                      textAlign: "center",
                      verticalAlign: "sub",
                    },
                  ]}
                ></Text>
              </View>

              {/* SUB TOTAL */}
              <View
                style={[
                  styles.tableRow,
                  {
                    flexDirection: "row",
                  },
                ]}
              >
                <Text
                  style={[
                    styles.boldText,
                    styles.mediumText,
                    {
                      width: 25,
                      borderRight: 1,
                      textAlign: "center",
                    },
                  ]}
                ></Text>
                <Text
                  style={[
                    styles.boldText,
                    styles.mediumText,
                    {
                      width: 285,
                      borderRight: 1,
                      textAlign: "right",
                      paddingRight: 2,
                    },
                  ]}
                >
                  SUB TOTAL
                </Text>
                <Text
                  style={[
                    styles.boldText,
                    styles.mediumText,
                    {
                      width: 60,
                      borderRight: 1,
                      textAlign: "center",
                    },
                  ]}
                ></Text>
                <Text
                  style={[
                    styles.boldText,
                    styles.mediumText,
                    {
                      width: 75,
                      borderRight: 1,
                      textAlign: "center",
                    },
                  ]}
                ></Text>
                <Text
                  style={[
                    styles.boldText,
                    styles.mediumText,
                    {
                      width: 80,
                      borderRight: 1,
                      textAlign: "center",
                    },
                  ]}
                ></Text>
                <Text
                  style={[
                    styles.boldText,
                    styles.mediumText,
                    {
                      width: 95,
                      textAlign: "center",
                    },
                  ]}
                >
                  {formatAmount(salesData.sub_total)}
                </Text>
              </View>

              {/* CGST */}
              <View
                style={[
                  styles.tableRow,
                  {
                    flexDirection: "row",
                  },
                ]}
              >
                <Text
                  style={[
                    styles.boldText,
                    styles.mediumText,
                    {
                      width: 25,
                      borderRight: 1,
                      textAlign: "center",
                    },
                  ]}
                ></Text>
                <Text
                  style={[
                    styles.boldText,
                    styles.mediumText,
                    {
                      width: 285,
                      borderRight: 1,
                      textAlign: "right",
                      paddingRight: 2,
                    },
                  ]}
                >
                  CGST OUTPUT
                </Text>
                <Text
                  style={[
                    styles.boldText,
                    styles.mediumText,
                    {
                      width: 60,
                      borderRight: 1,
                      textAlign: "center",
                    },
                  ]}
                ></Text>
                <Text
                  style={[
                    styles.boldText,
                    styles.mediumText,
                    {
                      width: 75,
                      borderRight: 1,
                      textAlign: "center",
                    },
                  ]}
                ></Text>
                <Text
                  style={[
                    styles.boldText,
                    styles.mediumText,
                    {
                      width: 80,
                      borderRight: 1,
                      textAlign: "center",
                    },
                  ]}
                ></Text>
                <Text
                  style={[
                    styles.boldText,
                    styles.mediumText,
                    {
                      width: 95,
                      textAlign: "center",
                    },
                  ]}
                >
                  {formatAmount(salesData.cgst)}
                </Text>
              </View>

              {/* SGST */}
              <View
                style={[
                  styles.tableRow,
                  {
                    flexDirection: "row",
                  },
                ]}
              >
                <Text
                  style={[
                    styles.boldText,
                    styles.mediumText,
                    {
                      width: 25,
                      borderRight: 1,
                      textAlign: "center",
                    },
                  ]}
                ></Text>
                <Text
                  style={[
                    styles.boldText,
                    styles.mediumText,
                    {
                      width: 285,
                      borderRight: 1,
                      textAlign: "right",
                      paddingRight: 2,
                    },
                  ]}
                >
                  SGST OUTPUT
                </Text>
                <Text
                  style={[
                    styles.boldText,
                    styles.mediumText,
                    {
                      width: 60,
                      borderRight: 1,
                      textAlign: "center",
                    },
                  ]}
                ></Text>
                <Text
                  style={[
                    styles.boldText,
                    styles.mediumText,
                    {
                      width: 75,
                      borderRight: 1,
                      textAlign: "center",
                    },
                  ]}
                ></Text>
                <Text
                  style={[
                    styles.boldText,
                    styles.mediumText,
                    {
                      width: 80,
                      borderRight: 1,
                      textAlign: "center",
                    },
                  ]}
                ></Text>
                <Text
                  style={[
                    styles.boldText,
                    styles.mediumText,
                    {
                      width: 95,
                      textAlign: "center",
                    },
                  ]}
                >
                  {formatAmount(salesData.sgst)}
                </Text>
              </View>

              {/* ROUND OFF */}
              {(salesData?.round_of < 0 || salesData?.round_of > 0) && (
                <View
                  style={[
                    styles.tableRow,
                    {
                      flexDirection: "row",
                    },
                  ]}
                >
                  <Text
                    style={[
                      styles.boldText,
                      styles.mediumText,
                      {
                        width: 25,
                        borderRight: 1,
                        textAlign: "center",
                      },
                    ]}
                  ></Text>
                  <Text
                    style={[
                      styles.boldText,
                      styles.mediumText,
                      {
                        width: 285,
                        borderRight: 1,
                        textAlign: "right",
                        paddingRight: 2,
                      },
                    ]}
                  >
                    ROUND OFF
                  </Text>
                  <Text
                    style={[
                      styles.boldText,
                      styles.mediumText,
                      {
                        width: 60,
                        borderRight: 1,
                        textAlign: "center",
                      },
                    ]}
                  ></Text>
                  <Text
                    style={[
                      styles.boldText,
                      styles.mediumText,
                      {
                        width: 75,
                        borderRight: 1,
                        textAlign: "center",
                      },
                    ]}
                  ></Text>
                  <Text
                    style={[
                      styles.boldText,
                      styles.mediumText,
                      {
                        width: 80,
                        borderRight: 1,
                        textAlign: "center",
                      },
                    ]}
                  ></Text>
                  <Text
                    style={[
                      styles.boldText,
                      styles.mediumText,
                      {
                        width: 95,
                        textAlign: "center",
                      },
                    ]}
                  >
                    {formatAmount(salesData.round_of)}
                  </Text>
                </View>
              )}

              {/* TOTAL AMOUNT */}
              <View
                style={[
                  styles.tableRow,
                  {
                    borderTop: 1,
                    flexDirection: "row",
                  },
                ]}
              >
                <Text
                  style={[
                    styles.boldText,
                    styles.mediumText,
                    {
                      width: 25,
                      borderRight: 1,
                      textAlign: "center",
                    },
                  ]}
                ></Text>
                <Text
                  style={[
                    styles.boldText,
                    styles.mediumText,
                    {
                      width: 285,
                      borderRight: 1,
                      textAlign: "right",
                      paddingRight: 2,
                    },
                  ]}
                >
                  TOTAL
                </Text>
                <Text
                  style={[
                    styles.boldText,
                    styles.mediumText,
                    {
                      width: 60,
                      borderRight: 1,
                      textAlign: "center",
                    },
                  ]}
                ></Text>
                <Text
                  style={[
                    styles.boldText,
                    styles.mediumText,
                    {
                      width: 75,
                      borderRight: 1,
                      textAlign: "center",
                    },
                  ]}
                >
                  {calculateTotal(
                    salesData.miscellaneous_sales_items,
                    "quantity"
                  )}
                </Text>
                <Text
                  style={[
                    styles.boldText,
                    styles.mediumText,
                    {
                      width: 80,
                      borderRight: 1,
                      textAlign: "center",
                    },
                  ]}
                ></Text>
                <Text
                  style={[
                    styles.boldText,
                    styles.mediumText,
                    {
                      width: 95,
                      textAlign: "center",
                    },
                  ]}
                >
                  {formatAmount(salesData.total)}
                </Text>
              </View>
            </View>
          </View>

          {/* TOTAL AMOUNT IN WORDS */}
          <View style={[styles.section, { padding: 2, paddingBottom: 5 }]}>
            <View
              style={[
                {
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                },
              ]}
            >
              <Text style={styles.boldText}>TOTAL VALUE (IN WORDS) :-</Text>
            </View>
            <Text style={styles.mediumText}>
              {convertAmountToWords(salesData.total, CURRENCY.INR.NAME)}
            </Text>
          </View>

          {/* HSN WISE GST CALCULATION */}
          <View>
            <View style={{ borderBottom: 1 }}>
              {/* TABLE HEADER */}
              <View
                style={[
                  styles.tableRow,
                  {
                    flexDirection: "row",
                  },
                ]}
              >
                <Text
                  style={[
                    styles.boldText,
                    styles.regularFontSize,
                    {
                      width: 20,
                      borderRight: 1,
                      textAlign: "center",
                    },
                  ]}
                >
                  SR NO
                </Text>
                <Text
                  style={[
                    styles.boldText,
                    styles.regularFontSize,
                    {
                      width: 140,
                      borderRight: 1,
                      paddingTop: 5,
                      textAlign: "center",
                    },
                  ]}
                >
                  HSN/SAC
                </Text>
                <Text
                  style={[
                    styles.boldText,
                    styles.regularFontSize,
                    {
                      width: 65,
                      borderRight: 1,
                      paddingTop: 5,
                      textAlign: "center",
                    },
                  ]}
                >
                  TEXABLE VALUE
                </Text>
                <View style={{ borderRight: 1 }}>
                  <Text
                    style={[
                      styles.boldText,
                      styles.regularFontSize,
                      {
                        width: 120,
                        borderBottom: 1,
                        textAlign: "center",
                      },
                    ]}
                  >
                    CGST
                  </Text>
                  <View style={{ flexDirection: "row" }}>
                    <Text
                      style={[
                        styles.boldText,
                        styles.regularFontSize,
                        {
                          width: 62,
                          borderRight: 1,
                          textAlign: "center",
                        },
                      ]}
                    >
                      RATE
                    </Text>
                    <Text
                      style={[
                        styles.boldText,
                        styles.regularFontSize,
                        {
                          width: 62.25,
                          textAlign: "center",
                        },
                      ]}
                    >
                      AMOUNT
                    </Text>
                  </View>
                </View>
                <View style={{ borderRight: 1 }}>
                  <Text
                    style={[
                      styles.boldText,
                      styles.regularFontSize,
                      {
                        width: 120,
                        borderBottom: 1,
                        textAlign: "center",
                      },
                    ]}
                  >
                    SGST
                  </Text>
                  <View style={{ flexDirection: "row" }}>
                    <Text
                      style={[
                        styles.boldText,
                        styles.regularFontSize,
                        {
                          width: 62,
                          borderRight: 1,
                          textAlign: "center",
                        },
                      ]}
                    >
                      RATE
                    </Text>
                    <Text
                      style={[
                        styles.boldText,
                        styles.regularFontSize,
                        {
                          width: 62.25,
                          textAlign: "center",
                        },
                      ]}
                    >
                      AMOUNT
                    </Text>
                  </View>
                </View>
                <Text
                  style={[
                    styles.boldText,
                    styles.regularFontSize,
                    {
                      width: 65,
                      paddingTop: 5,
                      textAlign: "center",
                    },
                  ]}
                >
                  TOTAL
                </Text>
              </View>

              {/* TABLE BODY */}
              {hsnWiseProductData.map((item: any, index: any) => (
                <View
                  key={index}
                  style={[
                    styles.tableRow,
                    {
                      flexDirection: "row",
                      borderTop: 1,
                    },
                  ]}
                >
                  <Text
                    style={[
                      styles.regularFontSize,
                      {
                        width: 20,
                        borderRight: 1,
                        textAlign: "center",
                      },
                    ]}
                  >
                    {index + 1}
                  </Text>
                  <Text
                    style={[
                      styles.regularFontSize,
                      {
                        width: 140,
                        borderRight: 1,
                        textAlign: "center",
                      },
                    ]}
                  >
                    {stringToUpperCase(item?.hsn_no)}
                  </Text>
                  <Text
                    style={[
                      styles.regularFontSize,
                      {
                        width: 65,
                        borderRight: 1,
                        textAlign: "center",
                      },
                    ]}
                  >
                    {formatAmount(item.total)}
                  </Text>
                  <View style={{ borderRight: 1 }}>
                    <View style={{ flexDirection: "row" }}>
                      <Text
                        style={[
                          styles.regularFontSize,
                          {
                            width: 62,
                            borderRight: 1,
                            textAlign: "center",
                          },
                        ]}
                      >
                        {item.gst_percentage / 2}%
                      </Text>
                      <Text
                        style={[
                          styles.regularFontSize,
                          {
                            width: 62.25,
                            textAlign: "center",
                          },
                        ]}
                      >
                        {formatAmount(item.cgst)}
                      </Text>
                    </View>
                  </View>
                  <View style={{ borderRight: 1 }}>
                    <View style={{ flexDirection: "row" }}>
                      <Text
                        style={[
                          styles.regularFontSize,
                          {
                            width: 62,
                            borderRight: 1,
                            textAlign: "center",
                          },
                        ]}
                      >
                        {item.gst_percentage / 2}%
                      </Text>
                      <Text
                        style={[
                          styles.regularFontSize,
                          {
                            width: 62.25,
                            textAlign: "center",
                          },
                        ]}
                      >
                        {formatAmount(item.sgst)}
                      </Text>
                    </View>
                  </View>
                  <Text
                    style={[
                      styles.regularFontSize,
                      {
                        width: 65,
                        textAlign: "center",
                      },
                    ]}
                  >
                    {formatAmount(item.gst)}
                  </Text>
                </View>
              ))}

              {/* TOTAL */}
              <View
                style={[
                  styles.tableRow,
                  {
                    flexDirection: "row",
                    borderTop: 1,
                  },
                ]}
              >
                <Text
                  style={[
                    styles.boldText,
                    styles.regularFontSize,
                    {
                      width: 20,
                      borderRight: 1,
                      textAlign: "center",
                    },
                  ]}
                ></Text>
                <Text
                  style={[
                    styles.boldText,
                    styles.regularFontSize,
                    {
                      width: 140,
                      borderRight: 1,
                      textAlign: "center",
                    },
                  ]}
                >
                  TOTAL
                </Text>
                <Text
                  style={[
                    styles.boldText,
                    styles.regularFontSize,
                    {
                      width: 65,
                      borderRight: 1,
                      textAlign: "center",
                    },
                  ]}
                >
                  {calculateTotal(hsnWiseProductData, "total")}
                </Text>
                <View style={{ borderRight: 1 }}>
                  <View style={{ flexDirection: "row" }}>
                    <Text
                      style={[
                        styles.boldText,
                        styles.regularFontSize,
                        {
                          width: 62,
                          borderRight: 1,
                          textAlign: "center",
                        },
                      ]}
                    ></Text>
                    <Text
                      style={[
                        styles.boldText,
                        styles.regularFontSize,
                        {
                          width: 62.25,
                          textAlign: "center",
                        },
                      ]}
                    >
                      {calculateTotal(hsnWiseProductData, "cgst")}
                    </Text>
                  </View>
                </View>
                <View style={{ borderRight: 1 }}>
                  <View style={{ flexDirection: "row" }}>
                    <Text
                      style={[
                        styles.boldText,
                        styles.regularFontSize,
                        {
                          width: 62,
                          borderRight: 1,
                          textAlign: "center",
                        },
                      ]}
                    ></Text>
                    <Text
                      style={[
                        styles.boldText,
                        styles.regularFontSize,
                        {
                          width: 62.25,
                          textAlign: "center",
                        },
                      ]}
                    >
                      {calculateTotal(hsnWiseProductData, "sgst")}
                    </Text>
                  </View>
                </View>
                <Text
                  style={[
                    styles.boldText,
                    styles.regularFontSize,
                    {
                      width: 65,
                      textAlign: "center",
                    },
                  ]}
                >
                  {calculateTotal(hsnWiseProductData, "gst")}
                </Text>
              </View>
            </View>
          </View>

          {/* SIGNATURE */}
          <View
            style={[
              styles.sideBySideContainer,
              { display: "flex", justifyContent: "space-between" },
            ]}
            break={true}
          >
            <View></View>
            <View
              style={[
                {
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                },
              ]}
            >
              <Text style={[styles.boldText]}>
                For, {stringToUpperCase(salesData?.buyer?.name)}
              </Text>
              <Image
                src="/img/logo/ee-main.png"
                style={{ width: "40px", height: "40px" }}
              />
              <Text style={[styles.boldText, styles.smallText]}>
                (Authorised Signatory)
              </Text>
            </View>
          </View>
        </View>
      </Page>
    </Document>
  );
};

export default MiscellaneousSalesInvoice;
