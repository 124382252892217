import { Document, Page, StyleSheet, Text, View } from "@react-pdf/renderer";
import {
  formatAmount,
  formatDate,
  stringToUpperCase,
} from "../../../../components/Helper";

export const styles = StyleSheet.create({
  boldText: {
    fontWeight: "bold",
    fontSize: 8,
  },
  smallText: {
    fontSize: 6,
  },
  mediumText: {
    fontSize: 6.5,
  },

  page: {
    paddingHorizontal: 30,
    paddingVertical: 15,
    fontFamily: "HostGrotesk",
    backgroundColor: "white",
  },
  fullpage: {
    margin: 7,
    borderStyle: "solid",
    borderWidth: 1,
    borderColor: "#000000",
  },

  sideBySideContainer: { flexDirection: "row" },

  tableContainer: {
    borderBottomWidth: 1,
    borderBottomColor: "black",
  },
  tableRow: {
    flexDirection: "row",
    flexGrow: 1,
    alignItems: "stretch",
  },
  tableCell: {
    padding: 5,
    fontSize: 7,
    textAlign: "center",
    borderRightWidth: 1,
    borderRightColor: "black",
    borderRightStyle: "solid",
  },
});

const AnnexureInvoice = ({
  settingData,
  salesInvoiceData,
}: {
  settingData: any;
  salesInvoiceData: any;
}) => {
  const staticData = {
    date_of_examination: "13-08-2024",
    name_of_commissionerate: "COMM. GANDHIDHAM (KUTCH), DIV.- BHUJ",
    location_code_and_range: "RANGE- BHUJ",
    date_of_sealing: "13-08-2024",
  };

  return (
    <Document>
      <Page style={[styles.page, { paddingTop: 25 }]} size="A4">
        <View style={[styles.fullpage]}>
          {/* Heading */}
          <View>
            <Text
              style={[
                {
                  textAlign: "center",
                  fontWeight: "bold",
                  fontSize: 14,
                  paddingBottom: 10,
                  borderBottom: 1,
                  paddingTop: 10,
                },
              ]}
            >
              EXPORT GOODS UNDER SELF SEALING
            </Text>
          </View>

          {/* C. No. | Shipping Bill No. | Date */}
          <View style={[styles.sideBySideContainer, { flexDirection: "row" }]}>
            <View style={[{ flex: 1 }]}>
              <Text
                style={[
                  styles.boldText,
                  {
                    fontWeight: "bold",
                    fontSize: 8,
                    textAlign: "left",
                    paddingBottom: 10,
                    borderBottom: 0,
                    padding: 2,
                  },
                ]}
              >
                C. NO.
              </Text>
            </View>
            <View style={[{ flex: 1 }]}>
              <Text
                style={[
                  {
                    fontWeight: "bold",
                    fontSize: 8,
                    paddingBottom: 10,
                    borderBottom: 0,
                    paddingLeft: -10,
                  },
                ]}
              >
                SHIPPING BILL NO.
              </Text>
            </View>
            <View style={[{ flex: 1 }]}>
              <Text
                style={[
                  {
                    fontWeight: "bold",
                    fontSize: 8,
                    paddingBottom: 10,
                    borderBottom: 0,
                    paddingLeft: 50,
                  },
                ]}
              >
                DATE :
              </Text>
            </View>
          </View>

          {/* EXAMINATION TITLE */}
          <View
            style={[
              styles.sideBySideContainer,
              { justifyContent: "center", borderBottom: 1 },
            ]}
          >
            <Text
              style={[
                {
                  fontWeight: "bold",
                  alignItems: "center",
                  fontSize: 8,
                  paddingTop: 0,
                },
              ]}
            >
              EXAMINATION REPORT FOR SELF SEALED PACKAGES / CONTAINERS
            </Text>
          </View>

          {/* Main Content */}
          <View style={[styles.sideBySideContainer]}>
            <View
              style={{
                flexDirection: "column",
                paddingLeft: 0,
                marginTop: 5,
                fontWeight: "bold",
                fontSize: 7,
              }}
            >
              {/* 1 */}
              <View
                style={{
                  flexDirection: "row",
                }}
              >
                <Text
                  style={{
                    fontSize: 8,
                    width: "5%",
                    justifyContent: "flex-start",
                    paddingLeft: 20,
                  }}
                >
                  1
                </Text>
                <Text
                  style={{
                    fontSize: 8,
                    width: "25%",
                    justifyContent: "flex-start",
                    paddingLeft: 15,
                  }}
                >
                  NAME OF EXPORTER
                </Text>
                <Text
                  style={{
                    fontSize: 8,
                    width: "60%",
                    justifyContent: "flex-end",
                    paddingLeft: 200,
                  }}
                >
                  {stringToUpperCase(settingData?.company_name)},{" "}
                  {stringToUpperCase(settingData?.company_address)}
                </Text>
              </View>

              {/* 2 | A */}
              <View
                style={{
                  flexDirection: "row",
                }}
              >
                <Text
                  style={{
                    fontSize: 8,
                    width: "5%",
                    justifyContent: "flex-start",
                    paddingLeft: 20,
                  }}
                >
                  2
                </Text>
                <Text
                  style={{
                    fontSize: 8,
                    width: "25%",
                    justifyContent: "flex-start",
                    paddingLeft: 15,
                  }}
                >
                  A) IEC NO.
                </Text>
                <Text
                  style={{
                    fontSize: 8,
                    width: "60%",
                    justifyContent: "flex-end",
                    paddingLeft: 200,
                  }}
                >
                  {stringToUpperCase(settingData?.iec_no)}
                </Text>
              </View>

              {/* 2 | B */}
              <View
                style={{
                  flexDirection: "row",
                }}
              >
                <Text
                  style={{
                    fontSize: 8,
                    width: "5%",
                    justifyContent: "flex-start",
                    paddingLeft: 20,
                  }}
                ></Text>
                <Text
                  style={{
                    fontSize: 8,
                    width: "25%",
                    justifyContent: "flex-start",
                    paddingLeft: 15,
                  }}
                >
                  B) BRANCH CODE
                </Text>
                <Text
                  style={{
                    fontSize: 8,
                    width: "60%",
                    justifyContent: "flex-end",
                    paddingLeft: 200,
                  }}
                >
                  0
                </Text>
              </View>

              {/* 2 | C */}
              <View
                style={{
                  flexDirection: "row",
                }}
              >
                <Text
                  style={{
                    fontSize: 8,
                    width: "5%",
                    justifyContent: "flex-start",
                    paddingLeft: 20,
                  }}
                ></Text>
                <Text
                  style={{
                    fontSize: 8,
                    width: "40%",
                    justifyContent: "flex-start",
                    paddingLeft: 15,
                  }}
                >
                  C) BIN (PAN BASED BUSINESS IDENTIFICATION)
                </Text>
                <Text
                  style={{
                    fontSize: 8,
                    width: "60%",
                    justifyContent: "flex-end",
                    paddingLeft: 132,
                  }}
                >
                  {stringToUpperCase(settingData?.company_pan_no)}
                </Text>
              </View>

              {/* 3 */}
              <View
                style={{
                  flexDirection: "row",
                }}
              >
                <Text
                  style={{
                    fontSize: 8,
                    width: "5%",
                    justifyContent: "flex-start",
                    paddingLeft: 20,
                  }}
                >
                  3
                </Text>
                <Text
                  style={{
                    fontSize: 8,
                    width: "25%",
                    justifyContent: "flex-start",
                    paddingLeft: 15,
                  }}
                >
                  NAME OF THE MANUFACTURER (IF DIFFERENCE FROM THE EXPORTERS)
                </Text>
                <Text
                  style={{
                    fontSize: 8,
                    width: "60%",
                    justifyContent: "flex-end",
                    paddingLeft: 200,
                  }}
                >
                  N.A.
                </Text>
              </View>

              {/* 4 */}
              <View
                style={{
                  flexDirection: "row",
                }}
              >
                <Text
                  style={{
                    fontSize: 8,
                    width: "5%",
                    justifyContent: "flex-start",
                    paddingLeft: 20,
                  }}
                >
                  4
                </Text>
                <Text
                  style={{
                    fontSize: 8,
                    width: "25%",
                    justifyContent: "flex-start",
                    paddingLeft: 15,
                  }}
                >
                  FACTORY / GODOWN
                </Text>
                <Text
                  style={{
                    fontSize: 8,
                    width: "60%",
                    justifyContent: "flex-end",
                    paddingLeft: 200,
                  }}
                >
                  SAME AS EXPORTER DETAILS
                </Text>
              </View>

              {/* 5 */}
              <View
                style={{
                  flexDirection: "row",
                  marginTop: 10,
                }}
              >
                <Text
                  style={{
                    fontSize: 8,
                    width: "5%",
                    justifyContent: "flex-start",
                    paddingLeft: 20,
                  }}
                >
                  5
                </Text>
                <Text
                  style={{
                    fontSize: 8,
                    width: "25%",
                    justifyContent: "flex-start",
                    paddingLeft: 15,
                  }}
                >
                  DATE OF EXAMINATION
                </Text>
                <Text
                  style={{
                    fontSize: 8,
                    width: "60%",
                    justifyContent: "flex-end",
                    paddingLeft: 200,
                  }}
                >
                  {staticData.date_of_examination}
                </Text>
              </View>

              {/* 6 */}
              <View
                style={{
                  flexDirection: "row",
                }}
              >
                <Text
                  style={{
                    fontSize: 8,
                    width: "5%",
                    justifyContent: "flex-start",
                    paddingLeft: 20,
                  }}
                >
                  6
                </Text>
                <Text
                  style={{
                    fontSize: 8,
                    width: "35%",
                    justifyContent: "flex-start",
                    paddingLeft: 15,
                  }}
                >
                  NAME AND DESIGNATION OF THE EXAMINING OFFICE INSPECTOR
                </Text>
                <Text
                  style={{
                    fontSize: 8,
                    width: "55%",
                    justifyContent: "flex-end",
                    paddingLeft: 148,
                  }}
                >
                  SELF-SEAL & SELF-CERTIFICATION SELF EXAMINATION
                </Text>
              </View>

              {/* 7 | A */}
              <View
                style={{
                  flexDirection: "row",
                }}
              >
                <Text
                  style={{
                    fontSize: 8,
                    width: "5%",
                    justifyContent: "flex-start",
                    paddingLeft: 20,
                  }}
                >
                  7
                </Text>
                <Text
                  style={{
                    fontSize: 8,
                    width: "35%",
                    justifyContent: "flex-start",
                    paddingLeft: 15,
                  }}
                >
                  A) NAME OF COMMISSIONERATE / DIVISION OFFICER
                </Text>
                <Text
                  style={{
                    fontSize: 8,
                    width: "55%",
                    justifyContent: "flex-end",
                    paddingLeft: 148,
                  }}
                >
                  {stringToUpperCase(staticData.name_of_commissionerate)}
                </Text>
              </View>

              {/* 7 | B */}
              <View
                style={{
                  flexDirection: "row",
                }}
              >
                <Text
                  style={{
                    fontSize: 8,
                    width: "5%",
                    justifyContent: "flex-start",
                    paddingLeft: 20,
                  }}
                ></Text>
                <Text
                  style={{
                    fontSize: 8,
                    width: "35%",
                    justifyContent: "flex-start",
                    paddingLeft: 15,
                  }}
                >
                  B) LOCATION CODE & RANGE
                </Text>
                <Text
                  style={{
                    fontSize: 8,
                    width: "55%",
                    justifyContent: "flex-end",
                    paddingLeft: 148,
                  }}
                >
                  {stringToUpperCase(staticData.location_code_and_range)}
                </Text>
              </View>

              {/* 8 */}
              <View
                style={{
                  flexDirection: "row",
                  marginTop: 10,
                }}
              >
                <Text
                  style={{
                    fontSize: 8,
                    width: "5%",
                    justifyContent: "flex-start",
                    paddingLeft: 20,
                  }}
                >
                  8
                </Text>
                <Text
                  style={{
                    fontSize: 8,
                    width: "35%",
                    justifyContent: "flex-start",
                    paddingLeft: 15,
                  }}
                >
                  PARTICULARS OF EXPORT INVOICE
                </Text>
                <Text
                  style={{
                    fontSize: 8,
                    width: "55%",
                    justifyContent: "flex-end",
                    paddingLeft: 148,
                  }}
                ></Text>
              </View>

              {/* 8 | A */}
              <View
                style={{
                  flexDirection: "row",
                }}
              >
                <Text
                  style={{
                    fontSize: 8,
                    width: "5%",
                    justifyContent: "flex-start",
                    paddingLeft: 20,
                  }}
                ></Text>
                <Text
                  style={{
                    fontSize: 8,
                    width: "20%",
                    justifyContent: "flex-start",
                    paddingLeft: 15,
                  }}
                >
                  A) EXPORT INVOICE NO.
                </Text>
                <Text
                  style={{
                    fontSize: 8,
                    width: "75%",
                    justifyContent: "flex-end",
                    paddingLeft: 225,
                  }}
                >
                  {stringToUpperCase(salesInvoiceData?.invoice_no)} DATE:{" "}
                  {formatDate(salesInvoiceData?.invoice_date) || ""}
                </Text>
              </View>

              {/* 8 | B */}
              <View
                style={{
                  flexDirection: "row",
                }}
              >
                <Text
                  style={{
                    fontSize: 8,
                    width: "5%",
                    justifyContent: "flex-start",
                    paddingLeft: 20,
                  }}
                ></Text>
                <Text
                  style={{
                    fontSize: 8,
                    width: "25%",
                    justifyContent: "flex-start",
                    paddingLeft: 15,
                  }}
                >
                  B) TOTAL NO. OF PACKAGES
                </Text>
                <Text
                  style={{
                    fontSize: 8,
                    width: "60%",
                    justifyContent: "flex-end",
                    paddingLeft: 200,
                  }}
                >
                  {salesInvoiceData?.no_of_bags || ""}{" "}
                  {stringToUpperCase(salesInvoiceData?.packing_type?.name)}
                </Text>
              </View>

              {/* 9 | A */}
              <View
                style={{
                  flexDirection: "row",
                  marginTop: 10,
                }}
              >
                <Text
                  style={{
                    fontSize: 8,
                    width: "5%",
                    justifyContent: "flex-start",
                    paddingLeft: 20,
                  }}
                >
                  9
                </Text>
                <Text
                  style={{
                    fontSize: 8,
                    width: "35%",
                    justifyContent: "flex-start",
                    paddingLeft: 15,
                  }}
                >
                  A) IS THE DESCRIPTION OF THE GOODS, THE QUANTITY AND THEIR
                  VALUE AS PER PARTICULARS FURNISHED IN THE EXPORT INVOICE ?
                </Text>
                <Text
                  style={{
                    fontSize: 8,
                    width: "55%",
                    justifyContent: "flex-end",
                    paddingLeft: 148,
                  }}
                >
                  YES
                </Text>
              </View>

              {/* 9 | B */}
              <View
                style={{
                  flexDirection: "row",
                }}
              >
                <Text
                  style={{
                    fontSize: 8,
                    width: "5%",
                    justifyContent: "flex-start",
                  }}
                ></Text>
                <Text
                  style={{
                    fontSize: 8,
                    width: "35%",
                    justifyContent: "flex-start",
                    paddingLeft: 15,
                  }}
                >
                  B) WHETHER SAMPLE IS DRAWN BEING FORWARDED TO PORT OF EXPORT ?
                </Text>
                <Text
                  style={{
                    fontSize: 8,
                    width: "55%",
                    justifyContent: "flex-end",
                    paddingLeft: 148,
                  }}
                >
                  NO
                </Text>
              </View>

              {/* 9 | C */}
              <View
                style={{
                  flexDirection: "row",
                }}
              >
                <Text
                  style={{
                    fontSize: 8,
                    width: "5%",
                    justifyContent: "flex-start",
                    paddingLeft: 20,
                  }}
                ></Text>
                <Text
                  style={{
                    fontSize: 8,
                    width: "35%",
                    justifyContent: "flex-start",
                    paddingLeft: 15,
                  }}
                >
                  C) IF YES, THE NUMBER OF THE SEAL OF THE PACKAGE CONTAINING
                  THE SAMPLE.
                </Text>
                <Text
                  style={{
                    fontSize: 8,
                    width: "55%",
                    justifyContent: "flex-end",
                    paddingLeft: 148,
                  }}
                ></Text>
              </View>

              {/* 10 */}
              <View
                style={{
                  flexDirection: "row",
                  marginTop: 10,
                }}
              >
                <Text
                  style={{
                    fontSize: 8,
                    width: "5%",
                    justifyContent: "flex-start",
                    paddingLeft: 20,
                  }}
                >
                  10
                </Text>
                <Text
                  style={{
                    fontSize: 8,
                    width: "35%",
                    justifyContent: "flex-start",
                    paddingLeft: 15,
                  }}
                >
                  CENTRAL EXCISE SEAL NOS./PACKAGES ETC
                </Text>
                <Text
                  style={{
                    fontSize: 8,
                    width: "55%",
                    justifyContent: "flex-end",
                    paddingLeft: 148,
                  }}
                ></Text>
              </View>

              {/* 10 | A */}
              <View
                style={{
                  flexDirection: "row",
                }}
              >
                <Text
                  style={{
                    fontSize: 8,
                    width: "5%",
                    justifyContent: "flex-start",
                    paddingLeft: 20,
                  }}
                ></Text>
                <Text
                  style={{
                    fontSize: 8,
                    width: "35%",
                    justifyContent: "flex-start",
                    paddingLeft: 15,
                  }}
                >
                  A) FOR NON- CONTAINERSED CARGO
                </Text>
                <Text
                  style={{
                    fontSize: 8,
                    width: "55%",
                    justifyContent: "flex-end",
                    paddingLeft: 148,
                  }}
                >
                  N.A.
                </Text>
              </View>

              {/* 10 | B */}
              <View
                style={{
                  flexDirection: "row",
                }}
              >
                <Text
                  style={{
                    fontSize: 8,
                    width: "5%",
                    justifyContent: "flex-start",
                    paddingLeft: 20,
                  }}
                ></Text>
                <Text
                  style={{
                    fontSize: 8,
                    width: "35%",
                    justifyContent: "flex-start",
                    paddingLeft: 15,
                  }}
                >
                  B) FOR - CONTAINERSED CARGO
                </Text>
                <Text
                  style={{
                    fontSize: 8,
                    width: "55%",
                    justifyContent: "flex-end",
                    paddingLeft: 148,
                  }}
                >
                  DETAILS AS UNDER
                </Text>
              </View>

              {/* 10 | C */}
              <View
                style={{
                  flexDirection: "row",
                }}
              >
                <Text
                  style={{
                    fontSize: 8,
                    width: "5%",
                    justifyContent: "flex-start",
                    paddingLeft: 20,
                  }}
                ></Text>
                <Text
                  style={{
                    fontSize: 8,
                    width: "35%",
                    justifyContent: "flex-start",
                    paddingLeft: 15,
                  }}
                >
                  C) SELF SEALING PERMISSION NO:
                </Text>
              </View>

              {/* F. NO. | Date | VALIDITY */}
              {(settingData?.self_sealing_permission_no ||
                settingData?.self_sealing_permission_date ||
                settingData?.self_sealing_permission_validity) && (
                <View
                  style={[
                    styles.tableContainer,
                    { paddingLeft: 20, borderBottom: 0 },
                  ]}
                >
                  <View style={[styles.tableRow, { width: "100%" }]}>
                    <Text
                      style={{
                        fontSize: 8,
                        width: "4%",
                      }}
                    ></Text>
                    <Text
                      style={[
                        {
                          fontSize: 10,
                          borderLeft: 1,
                          borderTop: 1,
                          borderBottom: 1,
                          paddingTop: 2,
                          borderRight: 1,
                          paddingLeft: 5,
                          marginVertical: 0,
                          width: "40%",
                        },
                      ]}
                    >
                      F. NO.{" "}
                      {stringToUpperCase(
                        settingData?.self_sealing_permission_no
                      )}
                    </Text>
                    <Text
                      style={[
                        {
                          borderBottom: 1,
                          textAlign: "center",
                          fontSize: 10,
                          paddingTop: 2,
                          borderTop: 1,
                          borderRight: 1,
                          width: "23%",
                        },
                      ]}
                    >
                      {stringToUpperCase(
                        settingData?.self_sealing_permission_date
                      )}
                    </Text>
                    <Text
                      style={[
                        {
                          borderBottom: 1,
                          textAlign: "center",
                          fontSize: 10,
                          borderTop: 1,
                          borderRight: 1,
                          paddingTop: 2,
                          width: "30%",
                        },
                      ]}
                    >
                      VALIDITY -{" "}
                      {stringToUpperCase(
                        settingData?.self_sealing_permission_validity
                      )}
                    </Text>
                  </View>
                </View>
              )}

              {/* 11 | GSTIN */}
              <View
                style={{
                  flexDirection: "row",
                  paddingTop: 2,
                }}
              >
                <Text
                  style={{
                    fontSize: 8,
                    width: "5%",
                    justifyContent: "flex-start",
                    paddingLeft: 20,
                    paddingTop: 1,
                  }}
                >
                  11
                </Text>
                <Text
                  style={{
                    fontSize: 10,
                    width: "35%",
                    justifyContent: "flex-start",
                    paddingLeft: 15,
                  }}
                >
                  GSTIN : {stringToUpperCase(settingData?.company_gst_no)}
                </Text>
              </View>
            </View>
          </View>

          {/* CONTAINER DETAILS  */}

          {salesInvoiceData.containers.length > 0 && (
            <View
              style={[
                styles.tableContainer,
                { borderBottom: 0, fontWeight: "bold" },
              ]}
            >
              {/* TABLE HEADER */}
              <View
                style={[styles.tableRow, { borderTop: 1, borderBottom: 0 }]}
              >
                <Text
                  style={[
                    styles.tableCell,
                    {
                      padding: 0,
                      margin: 0,
                      fontSize: 8,
                      borderBottom: 1,
                      width: 20,
                      paddingLeft: 2,
                    },
                  ]}
                >
                  SR.
                </Text>
                <Text
                  style={[
                    styles.tableCell,
                    {
                      fontSize: 8,
                      padding: 0,
                      margin: 0,
                      borderTop: 0,
                      borderBottom: 1,
                      width: 90,
                    },
                  ]}
                >
                  CONTAINER NO.
                </Text>
                <Text
                  style={[
                    styles.tableCell,
                    {
                      fontSize: 8,
                      borderTop: 0,
                      padding: 0,
                      margin: 0,
                      borderBottom: 1,
                      width: 50,
                    },
                  ]}
                >
                  SIZE
                </Text>
                <Text
                  style={[
                    styles.tableCell,
                    {
                      fontSize: 8,
                      padding: 0,
                      margin: 0,
                      borderTop: 0,
                      borderBottom: 1,
                      width: 79,
                    },
                  ]}
                >
                  LINE SEAL NO.
                </Text>
                <Text
                  style={[
                    styles.tableCell,
                    {
                      padding: 0,
                      margin: 0,
                      fontSize: 8,
                      borderTop: 0,
                      borderBottom: 1,
                      width: 90,
                    },
                  ]}
                >
                  SELF SEAL NO.
                </Text>
                <Text
                  style={[
                    styles.tableCell,
                    {
                      padding: 0,
                      margin: 0,
                      fontSize: 8,
                      borderTop: 0,
                      borderBottom: 1,
                      width: 80,
                    },
                  ]}
                >
                  STUFFING PKGS
                </Text>
                <Text
                  style={[
                    styles.tableCell,
                    {
                      padding: 0,
                      margin: 0,
                      fontSize: 8,
                      borderTop: 0,
                      borderBottom: 1,
                      width: 63,
                    },
                  ]}
                >
                  NWT (KGS)
                </Text>
                <Text
                  style={[
                    styles.tableCell,
                    {
                      fontSize: 8,
                      borderTop: 0,
                      padding: 0,
                      margin: 0,
                      borderBottom: 1,
                      borderRight: 0,
                      width: 63,
                    },
                  ]}
                >
                  GWT (KGS)
                </Text>
              </View>

              {/* TABLE BODY */}
              {salesInvoiceData.containers.map((row: any, index: number) => {
                return (
                  <View key={index}>
                    <View style={[styles.tableRow, { borderBottom: 0 }]}>
                      <Text
                        style={[
                          styles.tableCell,
                          {
                            fontSize: 8,
                            borderBottom: 1,
                            width: 20,
                            padding: 0,
                            margin: 0,
                          },
                        ]}
                      >
                        {index + 1}
                      </Text>
                      <Text
                        style={[
                          styles.tableCell,
                          {
                            fontSize: 8,
                            borderTop: 0,
                            padding: 0,
                            margin: 0,
                            borderBottom: 1,
                            width: 90,
                          },
                        ]}
                      >
                        {stringToUpperCase(row?.container_no)}
                      </Text>
                      <Text
                        style={[
                          styles.tableCell,
                          {
                            fontSize: 8,
                            padding: 0,
                            margin: 0,
                            borderTop: 0,
                            borderBottom: 1,
                            width: 50,
                          },
                        ]}
                      >
                        {stringToUpperCase(row?.size)}
                      </Text>
                      <Text
                        style={[
                          styles.tableCell,
                          {
                            fontSize: 8,
                            borderTop: 0,
                            padding: 0,
                            margin: 0,
                            borderBottom: 1,
                            width: 79,
                          },
                        ]}
                      >
                        {stringToUpperCase(row?.line_seal_no)}
                      </Text>
                      <Text
                        style={[
                          styles.tableCell,
                          {
                            fontSize: 8,
                            borderTop: 0,
                            padding: 0,
                            margin: 0,
                            borderBottom: 1,
                            width: 90,
                          },
                        ]}
                      >
                        {stringToUpperCase(row?.self_seal?.seal_no)}
                      </Text>
                      <Text
                        style={[
                          styles.tableCell,
                          {
                            fontSize: 8,
                            padding: 0,
                            margin: 0,
                            borderTop: 0,
                            borderBottom: 1,
                            width: 80,
                          },
                        ]}
                      >
                        {row?.no_of_bags || 0}{" "}
                        {stringToUpperCase(row?.packing_type?.name)}
                      </Text>
                      <Text
                        style={[
                          styles.tableCell,
                          {
                            fontSize: 8,
                            borderTop: 0,
                            padding: 0,
                            margin: 0,
                            borderBottom: 1,
                            width: 63,
                          },
                        ]}
                      >
                        {formatAmount(row?.total_net_weight)}
                      </Text>
                      <Text
                        style={[
                          styles.tableCell,
                          {
                            fontSize: 8,
                            padding: 0,
                            margin: 0,
                            borderTop: 0,
                            borderBottom: 1,
                            borderRight: 0,
                            width: 63,
                          },
                        ]}
                      >
                        {formatAmount(row?.total_gross_weight)}
                      </Text>
                    </View>
                  </View>
                );
              })}
            </View>
          )}

          {/* DESCRIPTION */}
          <View style={[styles.sideBySideContainer]}>
            <Text
              style={[
                styles.mediumText,
                { textAlign: "center", padding: 5, paddingLeft: 15 },
              ]}
            >
              CERTIFIED THAT THE DESCRIPTION, WEIGHT, SIZE, QUANTITY & VALUE OF
              THE GOODS COVERED BY THIS INVOICE HAVE BEEN CHECKED BY ME AND THE
              GOODS HAVE BEEN PACKED AND SEALED WITH ONE TIME SEAL HAVING
              NUMBERS AS DETAILS MENTIONED ABOVE UNDER MY SUPERVISION
            </Text>
          </View>

          {/* DATE OF SEALING */}
          <View style={[styles.sideBySideContainer, { flexDirection: "row" }]}>
            <View style={[{}]}>
              <Text
                style={[
                  {
                    textAlign: "center",
                    fontWeight: "bold",
                    fontSize: 8,
                    width: 150,
                    padding: 20,
                    paddingBottom: 10,
                    borderBottom: 0,
                    paddingLeft: 30,
                  },
                ]}
              >
                DATE OF SEALING
              </Text>
            </View>
            <View style={[{ flex: 1 }]}>
              <Text
                style={[
                  {
                    textAlign: "center",
                    fontWeight: "bold",
                    fontSize: 8,
                    padding: 20,
                    paddingBottom: 10,
                    borderBottom: 0,
                    paddingRight: 180,
                  },
                ]}
              >
                {staticData.date_of_sealing}
              </Text>
            </View>
          </View>

          {/* PARTNER NAME | SIGNATURE AND COMPANY SEAL */}
          <View
            style={[
              styles.tableContainer,
              {
                paddingLeft: 20,
                borderBottom: 0,
                fontWeight: "bold",
              },
            ]}
          >
            <View style={[styles.tableRow]}>
              <Text
                style={[
                  styles.tableCell,
                  {
                    width: 175,
                    textAlign: "center",
                    fontSize: 10,
                    borderLeft: 1,
                    borderTop: 1,
                    borderBottom: 0,
                    padding: 0,
                    paddingTop: 2,
                  },
                ]}
              >
                {stringToUpperCase(settingData?.name_of_proprietor)}
              </Text>
              <Text
                style={[
                  styles.tableCell,
                  {
                    width: 130,
                    borderBottom: 0,
                    textAlign: "center",
                    fontSize: 10,
                    padding: 0,
                    borderTop: 1,
                    paddingTop: 2,
                  },
                ]}
              >
                PARTNER
              </Text>
              <Text
                style={[
                  styles.tableCell,
                  {
                    borderRight: 0,
                    borderTop: 0,
                    borderLeft: 0,
                    borderBottom: 0,
                    textAlign: "center",
                    fontSize: 8,
                    padding: 0,
                    paddingLeft: 35,
                    paddingTop: 5,
                  },
                ]}
              >
                SIGNATURE AND COMPANY SEAL
              </Text>
            </View>
          </View>
        </View>
      </Page>
    </Document>
  );
};
export default AnnexureInvoice;
