/* eslint-disable react-hooks/exhaustive-deps */
import {
  salesInvoiceListApi,
  deleteSalesInvoiceApi,
  getAllSalesContractApi,
  getAllSalesInvoiceApi,
} from "../../../services/_sales";
import moment from "moment";
import { Link } from "react-router-dom";
import { DateRangePicker } from "rsuite";
import { useEffect, useRef, useState } from "react";
import Spinner from "../../../theme/Spinner";
import Pagination from "../../../components/Pagination";
import { CURRENCY } from "../../../components/Constants";
import { getPermissions } from "../../../context/AuthContext";
import { getBuyerNameListApi } from "../../../services/_buyer";
import { SalesInvoiceStatus } from "../../../components/Enums";
import { getProductNameListApi } from "../../../services/_product";
import { errorToast, successToast } from "../../../components/Toast";
import { formatAmount, formatString } from "../../../components/Helper";
import "rsuite/DateRangePicker/styles/index.css";
import { Autocomplete, TextField } from "@mui/material";
import ReactSelect from "react-select";
import ReCAPTCHA from "react-google-recaptcha";

const SalesInvoiceList = () => {
  const permissions = getPermissions();
  const [records, setRecords] = useState([]);
  const [buyerList, setBuyerList] = useState([]);
  const [product, setProduct] = useState([]);
  const [salesContractList, setSalesContractList] = useState([]);
  const [salesInvoiceList, setSalesInvoiceList] = useState([]);
  const [page, setPage] = useState<number>(1);
  const [perPage] = useState<number>(10);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [totalRecords, setTotalRecords] = useState<number>(0);
  const [applyFilter, setApplyFilter] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [filterInvoiceNo, setFilterInvoiceNo] = useState<string>("");
  const [filterContractNo, setFilterContractNo] = useState<string>("");
  const [filterBuyerId, setFilterBuyerId] = useState<string>("");
  const [filterStatus, setFilterStatus] = useState<string>("");
  const [dateRange, setDateRange] = useState(null);
  const [filterProductId, setFilterProductId] = useState("");
  const [displayFilter, setDisplayFilter] = useState(false);
  const [sortKey, setSortKey] = useState<string>("");
  const [sortType, setSortType] = useState<string>("");
  const productRef = useRef<any>(null);
  const [isCaptchaVerified, setIsCaptchaVerified] = useState(false);
  const [showCaptcha, setShowCaptcha] = useState(false);

  const toggleFilter = async () => {
    setDisplayFilter(!displayFilter);
  };
  const [selectedItemId, setSelectedItemId] = useState<string | null>(null);
  const [deleteLoading, setDeleteLoading] = useState<boolean>(false);

  const getSalesList = async (
    page: number,
    perPage: number,
    isFilter?: boolean,
    sortKey?: string,
    sortType?: string
  ) => {
    setLoading(true);
    let query = `page=${page}&per_page=${perPage}`;
    if (isFilter) {
      if (filterInvoiceNo) {
        query += `&invoice_no=${filterInvoiceNo}`;
      }
      if (filterContractNo) {
        query += `&contract_no=${filterContractNo}`;
      }
      if (filterStatus) {
        query += `&status=${filterStatus}`;
      }
      if (dateRange) {
        query += `&from_date=${moment(dateRange[0]).format("YYYY-MM-DD")}`;
        query += `&to_date=${moment(dateRange[1]).format("YYYY-MM-DD")}`;
      }
      if (filterBuyerId) {
        query += `&buyer_id=${filterBuyerId}`;
      }
      if (filterProductId) {
        query += `&product_id=${filterProductId}`;
      }
      if (sortKey && sortType) {
        query += `&sort_key=${sortKey}&sort_type=${sortType}`;
      }
    }
    const result: any = await salesInvoiceListApi(query);
    if (result.isSuccess) {
      const totalPagesCount = Math.ceil(result.data.total_records / perPage);
      setTotalRecords(result.data.total_records);
      setTotalPages(totalPagesCount);
      setRecords(result.data.records);
    }
    setLoading(false);
  };

  const filterList = async () => {
    setApplyFilter(true);
    getSalesList(page, perPage, true);
  };

  const resetList = async () => {
    setPage(1);
    setApplyFilter(false);
    setFilterInvoiceNo("");
    setFilterContractNo("");
    setFilterBuyerId("");
    setFilterStatus("");
    setFilterProductId("");
    setDateRange(null);
    if (productRef.current) {
      productRef.current.clearValue();
    }
    getSalesList(page, perPage);
    setIsCaptchaVerified(false);
    setShowCaptcha(false);
  };

  const prevButton = async () => {
    if (page > 1) {
      setPage(page - 1);
    }
  };

  const nextButton = async () => {
    if (totalPages > page) {
      setPage(page + 1);
    }
  };

  const getSalesOrderList = async (contract_id?: string) => {
    try {
      const result: any = await getAllSalesInvoiceApi(contract_id);
      setSalesInvoiceList(result?.data);
    } catch (error) {
      console.error("Error fetching SaleOrder list", error);
    }
  };
  const fetchSalesContractList = async () => {
    const sales: any = await getAllSalesContractApi();
    if (sales.isSuccess) {
      setSalesContractList(sales.data);
    }
  };

  useEffect(() => {
    if (page && perPage) {
      getSalesList(page, perPage, applyFilter);
    }
  }, [page, perPage, applyFilter]);

  useEffect(() => {
    const fetchBuyerData = async () => {
      const result: any = await getBuyerNameListApi();
      if (result.isSuccess) {
        setBuyerList(result.data);
      }
      const product: any = await getProductNameListApi();
      if (product.isSuccess) {
        setProduct(product.data);
      }
    };
    fetchSalesContractList();
    getSalesOrderList();
    fetchBuyerData();
  }, []);

  const handleCaptchaChange = (value: any | null) => {
    if (value) {
      setIsCaptchaVerified(true);
    }
  };

  const confirmDelete = async () => {
    setDeleteLoading(true);
    if (selectedItemId) {
      const result = await deleteSalesInvoiceApi(selectedItemId);
      if (result.isSuccess) {
        await getSalesList(page, perPage);
        successToast(result.message);
      } else {
        errorToast(result.message);
      }
      setDeleteLoading(false);
      setSelectedItemId(null);
    }
  };
  const handleDeleteClick = (id: any) => {
    setSelectedItemId(id);
    setShowCaptcha(true);
  };

  const sortTable = async (key: string) => {
    let type = sortType === "asc" ? "desc" : "asc";
    if (sortKey !== key) {
      type = "asc";
    }
    setSortKey(key);
    setSortType(type);
    await getSalesList(page, perPage, true, key, type);
  };

  return (
    <>
      <div className="card">
        <div className="card-header pb-0 d-flex justify-content-between">
          <div>
            <h5>Sales Invoice List</h5>
          </div>
          <div>
            <Link
              to="#"
              type="button"
              className="btn btn-label-github"
              style={{ marginRight: "15px" }}
              onClick={toggleFilter}
            >
              <span>
                <i className="ti ti-filter me-sm-1"></i>{" "}
                <span className="d-none d-sm-inline-block">Filter</span>
              </span>
            </Link>
            {permissions.includes("create-sales-invoice") && (
              <Link
                className="btn btn-primary waves-effect waves-light"
                type="button"
                to="/create-sales-invoice"
              >
                <span>
                  <i className="ti ti-plus me-sm-1"></i>{" "}
                  <span className="d-none d-sm-inline-block">
                    Add Sales Invoice
                  </span>
                </span>
              </Link>
            )}
          </div>
        </div>
        {displayFilter && (
          <div className="card-body">
            <form className="dt_adv_search">
              <div className="row">
                <div className="col-12">
                  <div className="row g-3">
                    <div className="col-12 col-sm-6 col-md-4 col-lg-3">
                      <label
                        htmlFor="date"
                        className="form-label"
                        style={{ fontSize: "0.9375rem" }}
                      >
                        Invoice Date:
                      </label>
                      <DateRangePicker
                        format="dd-MM-yyyy"
                        className="form-control"
                        placeholder="From Date ~ To Date"
                        value={dateRange}
                        onChange={(values: any) => {
                          setDateRange(values);
                        }}
                      />
                    </div>
                    <div className="col-12 col-sm-6 col-md-4 col-lg-3">
                      <label
                        className="form-label"
                        style={{ fontSize: "0.9375rem" }}
                      >
                        Invoice No:
                      </label>
                      <Autocomplete
                        disablePortal
                        options={salesInvoiceList.map(
                          (suggestion: any) => suggestion.invoice_no
                        )}
                        onChange={(e, newValue) => {
                          setFilterInvoiceNo(newValue);
                        }}
                        value={filterInvoiceNo}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            className="form-control"
                            type="text"
                            placeholder="Enter Invoice No"
                            value={filterInvoiceNo}
                            onChange={(e) => setFilterInvoiceNo(e.target.value)}
                          />
                        )}
                      />
                    </div>
                    <div className="col-12 col-sm-6 col-md-4 col-lg-3">
                      <label
                        className="form-label"
                        style={{ fontSize: "0.9375rem" }}
                      >
                        Contract No:
                      </label>
                      <Autocomplete
                        disablePortal
                        options={salesContractList.map(
                          (suggestion: any) => suggestion.contract_no
                        )}
                        onChange={(e, newValue) => {
                          setFilterContractNo(newValue);
                          getSalesOrderList(newValue);
                        }}
                        value={filterContractNo}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            className="form-control"
                            type="text"
                            placeholder="Enter Contract No"
                            value={filterContractNo}
                            onChange={(e) =>
                              setFilterContractNo(e.target.value)
                            }
                          />
                        )}
                      />
                    </div>
                    <div className="col-12 col-sm-6 col-md-4 col-lg-3">
                      <label
                        htmlFor="buyer_id"
                        className="form-label"
                        style={{ fontSize: "0.9375rem" }}
                      >
                        Buyer:
                      </label>
                      <select
                        className="form-select"
                        id="buyer_id"
                        value={filterBuyerId}
                        onChange={(e) => {
                          if (e.target.value) {
                            setFilterBuyerId(e.target.value);
                          }
                        }}
                      >
                        <option defaultChecked value={""}>
                          Select Buyer
                        </option>
                        {buyerList.map((e: any) => {
                          return (
                            <option value={e.id} key={e.id}>
                              {e.name}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                    <div className="col-12 col-sm-6 col-md-4 col-lg-3">
                      <label
                        className="form-label"
                        style={{ fontSize: "0.9375rem" }}
                      >
                        Product:
                      </label>
                      {(() => {
                        const selectedOption = product?.find(
                          (option: any) => option.id === filterProductId
                        );
                        const formatOptionLabel = (
                          option: any,
                          { context }: any
                        ) => {
                          if (context === "value") {
                            return option.name;
                          } else {
                            return (
                              <div>
                                <label className="form-label">
                                  {option.name}
                                </label>
                                <br />
                                <span className="form-label">
                                  {option.description}
                                </span>
                              </div>
                            );
                          }
                        };
                        return (
                          <ReactSelect
                            ref={productRef}
                            classNamePrefix="react-select"
                            placeholder="Select Product"
                            options={product}
                            getOptionLabel={(option: any) => option.name}
                            formatOptionLabel={formatOptionLabel}
                            getOptionValue={(option: any) => option.id}
                            onChange={(selectedOption: any) => {
                              setFilterProductId(selectedOption?.id || "");
                            }}
                            value={selectedOption}
                            isClearable
                            isSearchable
                          />
                        );
                      })()}
                    </div>
                    <div className="col-12 col-sm-6 col-md-4 col-lg-3">
                      <label
                        htmlFor="status"
                        className="form-label"
                        style={{ fontSize: "0.9375rem" }}
                      >
                        Status:
                      </label>
                      <select
                        className="form-select"
                        id="status"
                        value={filterStatus}
                        onChange={(e) => {
                          if (e.target.value) {
                            setFilterStatus(e.target.value);
                          }
                        }}
                      >
                        <option defaultChecked value={""}>
                          Select Status
                        </option>
                        <option
                          value={SalesInvoiceStatus.PENDING}
                          key={SalesInvoiceStatus.PENDING}
                        >
                          {formatString(SalesInvoiceStatus.PENDING)}
                        </option>
                        <option
                          value={SalesInvoiceStatus.IN_TRANSIT}
                          key={SalesInvoiceStatus.IN_TRANSIT}
                        >
                          {formatString(SalesInvoiceStatus.IN_TRANSIT)}
                        </option>
                        <option
                          value={SalesInvoiceStatus.COMPLETED}
                          key={SalesInvoiceStatus.COMPLETED}
                        >
                          {formatString(SalesInvoiceStatus.COMPLETED)}
                        </option>
                      </select>
                    </div>
                    <div className="col-12">
                      <button
                        type="button"
                        className="btn btn-primary"
                        onClick={filterList}
                      >
                        Filter
                      </button>
                      <button
                        type="button"
                        className="btn btn-label-github ms-5"
                        onClick={resetList}
                      >
                        Reset
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        )}
        <div className="card-datatable table-responsive">
          {loading && <Spinner isFullScreen={false} />}
          <table className="dt-advanced-search table table-nowrap">
            <thead>
              <tr>
                <th>#</th>
                <th
                  className="cursor-pointer"
                  onClick={() => sortTable("invoice_no")}
                >
                  Invoice No
                  <span>
                    <i className="ti ti-arrows-down-up sorting-icon"></i>
                  </span>
                </th>
                <th
                  className="cursor-pointer"
                  onClick={() => sortTable("contract_no")}
                >
                  Contract No
                  <span>
                    <i className="ti ti-arrows-down-up sorting-icon"></i>
                  </span>
                </th>
                <th
                  className="cursor-pointer"
                  onClick={() => sortTable("buyer")}
                >
                  Buyer
                  <span>
                    <i className="ti ti-arrows-down-up sorting-icon"></i>
                  </span>
                </th>
                <th
                  className="cursor-pointer"
                  onClick={() => sortTable("product")}
                >
                  Product
                  <span>
                    <i className="ti ti-arrows-down-up sorting-icon"></i>
                  </span>
                </th>
                <th
                  className="cursor-pointer"
                  onClick={() => sortTable("price")}
                >
                  Price
                  <span>
                    <i className="ti ti-arrows-down-up sorting-icon"></i>
                  </span>
                </th>
                <th
                  className="cursor-pointer"
                  onClick={() => sortTable("quantity")}
                >
                  Quantity
                  <span>
                    <i className="ti ti-arrows-down-up sorting-icon"></i>
                  </span>
                </th>
                <th
                  className="cursor-pointer"
                  onClick={() => sortTable("total_amount")}
                >
                  Total Amount
                  <span>
                    <i className="ti ti-arrows-down-up sorting-icon"></i>
                  </span>
                </th>
                <th
                  className="cursor-pointer"
                  onClick={() => sortTable("status")}
                >
                  Status
                  <span>
                    <i className="ti ti-arrows-down-up sorting-icon"></i>
                  </span>
                </th>
                <th
                  className="cursor-pointer"
                  onClick={() => sortTable("invoice_date")}
                >
                  Invoice Date
                  <span>
                    <i className="ti ti-arrows-down-up sorting-icon"></i>
                  </span>
                </th>
                {permissions.some((permission: any) =>
                  ["update-sales-invoice", "delete-sales-invoice"].includes(
                    permission
                  )
                ) && <th>Action</th>}
              </tr>
            </thead>
            <tbody>
              {records.length > 0 ? (
                records.map((item: any, i: number) => {
                  let statusClass = "";
                  if (item.status === SalesInvoiceStatus.PENDING) {
                    statusClass = `bg-label-warning`;
                  } else if (item.status === SalesInvoiceStatus.COMPLETED) {
                    statusClass = `bg-label-success`;
                  } else {
                    statusClass = `bg-label-secondary`;
                  }
                  return (
                    <tr key={item.id}>
                      <td>{(page - 1) * perPage + 1 + i}</td>
                      <td>
                        {
                          <Link to={`/view-sales-invoice/${item.id}`}>
                            {item.invoice_no}
                          </Link>
                        }
                      </td>
                      <td>{item?.sales_contract?.contract_no}</td>
                      <td>{item.buyer.name}</td>
                      <td>{item.product?.name}</td>
                      <td>
                        {CURRENCY.USD.SYMBOL}
                        {formatAmount(item.price)}
                      </td>
                      <td>
                        {item.quantity} {item.quantity_unit?.name}
                      </td>
                      <td>
                        {CURRENCY.USD.SYMBOL}
                        {formatAmount(item.total_amount)}
                      </td>
                      <td>
                        <span className={`badge ${statusClass} me-1`}>
                          {formatString(item?.status)}
                        </span>
                      </td>
                      <td>{moment(item.invoice_date).format("DD-MM-YYYY")}</td>
                      {permissions.some((permission: any) =>
                        [
                          "update-sales-invoice",
                          "delete-sales-invoice",
                        ].includes(permission)
                      ) && (
                        <td>
                          {permissions.includes("update-sales-invoice") && (
                            <Link
                              to={`/edit-sales-invoice/${item.id}`}
                              className="btn btn-sm btn-text-secondary rounded-pill btn-icon"
                              title="Edit Sales Invoice"
                            >
                              <i className="ti ti-pencil ti-md"></i>
                            </Link>
                          )}
                          {permissions.includes("delete-sales-invoice") && (
                            <div
                              className="btn btn-sm btn-text-secondary rounded-pill btn-icon"
                              data-bs-toggle="modal"
                              data-bs-target="#deleteSalesInvoiceModal"
                              onClick={() => handleDeleteClick(item.id)}
                            >
                              <i className="ti ti-trash"></i>
                            </div>
                          )}
                        </td>
                      )}
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td
                    colSpan={
                      permissions.includes("update-sales-invoice") ? 11 : 10
                    }
                    className="text-center"
                  >
                    No records found
                  </td>
                </tr>
              )}
            </tbody>
          </table>
          <div
            className="modal fade"
            id="deleteSalesInvoiceModal"
            tabIndex={-1}
            aria-hidden="true"
          >
            <div className="modal-dialog modal-simple modal-dialog-centered">
              <div className="modal-content">
                <div className="modal-body text-center">
                  <i
                    className="fa-solid fa-circle-exclamation"
                    style={{
                      fontSize: "48px",
                      color: "#ff4c51",
                      marginBottom: "20px",
                    }}
                  ></i>
                  <p style={{ fontSize: "25px" }}>Are you sure?</p>
                  <br />
                  <p style={{ fontSize: "20px" }}>
                    Do you want to delete this <b>sales invoice</b>?
                    <br />
                    This action can't be undone!
                  </p>
                  {showCaptcha && (
                    <div
                      style={{
                        marginTop: "10px",
                        marginLeft: "50px",
                        marginBottom: "10px",
                      }}
                    >
                      <ReCAPTCHA
                        sitekey="6LdCqqMqAAAAAI5NAleRuzGZv4X3LjDVJdvGDmaj"
                        onChange={handleCaptchaChange}
                      />
                    </div>
                  )}
                  <div className="text-center">
                    <button
                      type="button"
                      className="btn btn-danger me-3"
                      onClick={() => {
                        confirmDelete();
                        resetList();
                      }}
                      disabled={deleteLoading || !isCaptchaVerified}
                      data-bs-dismiss="modal"
                    >
                      {deleteLoading && (
                        <span
                          className="spinner-border spinner-border-sm me-2"
                          role="status"
                          aria-hidden="true"
                        ></span>
                      )}
                      Yes, Delete
                    </button>

                    <button
                      type="button"
                      className="btn btn-label-secondary"
                      data-bs-dismiss="modal"
                      onClick={resetList}
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Pagination
          page={page}
          perPage={perPage}
          totalRecords={totalRecords}
          totalPages={totalPages}
          records={records}
          prevButton={prevButton}
          nextButton={nextButton}
        />
      </div>
    </>
  );
};

export default SalesInvoiceList;
