import React from "react";
import { Field, ErrorMessage } from "formik";
import { CURRENCY } from "../../../components/Constants";
import ReactSelect from "react-select";

interface ProductRowProps {
  item: any;
  index: number;
  productList: Array<{ id: string; name: string; description: string }>;
  unitList: any;
  values: any;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
  remove: (index: number) => void;
  handleChange: any;
  setAddProductModalStatus: (show: boolean) => void;
  setAddUnitModalStatus: (show: boolean) => void;
}

const ProductRow: React.FC<ProductRowProps> = ({
  item,
  index,
  productList,
  unitList,
  values,
  setFieldValue,
  remove,
  handleChange,
  setAddProductModalStatus,
  setAddUnitModalStatus,
}) => {
  const handleProductCalculation = (index: any) => {
    let subTotal = 0;
    let sgst = 0;
    let cgst = 0;
    let totalGst = 0;
    let finalTotal = 0;

    if (values.sale_items[index].price && values.sale_items[index].quantity) {
      subTotal =
        Number(values.sale_items[index].price) *
        Number(values.sale_items[index].quantity);
    }

    if (values.sale_items[index]?.gst_percentage && subTotal) {
      totalGst = Number(
        (subTotal * Number(values.sale_items[index]?.gst_percentage)) / 100
      );
      sgst = totalGst / 2;
      cgst = totalGst / 2;
    }

    finalTotal = Number(subTotal) + Number(totalGst);

    setFieldValue(`sale_items.${index}.sub_total`, subTotal);
    setFieldValue(`sale_items.${index}.cgst`, cgst);
    setFieldValue(`sale_items.${index}.sgst`, sgst);
    setFieldValue(`sale_items.${index}.gst_amount`, totalGst);
    setFieldValue(`sale_items.${index}.total`, finalTotal);

    let subTotalAll = 0;
    let sgstAll = 0;
    let cgstAll = 0;
    let totalGstAll = 0;
    let finalTotalAll = 0;

    if (values?.sale_items) {
      values?.sale_items.map((row: any) => {
        if (row.price && row.quantity) {
          subTotalAll += Number(row.price) * Number(row.quantity);
        }
        if (row?.gst_percentage) {
          totalGstAll += Number(
            (Number(row.price) *
              Number(row.quantity) *
              Number(row?.gst_percentage)) /
              100
          );
          sgstAll = totalGstAll / 2;
          cgstAll = totalGstAll / 2;
        }
        return "";
      });
    }

    finalTotalAll =
      Number(subTotalAll) + Number(totalGstAll) + Number(values?.round_of);

    setFieldValue(`sub_total`, subTotalAll);
    setFieldValue(`cgst`, cgstAll);
    setFieldValue(`sgst`, sgstAll);
    setFieldValue(`gst_amount`, totalGstAll);
    setFieldValue(`total`, finalTotalAll);
  };

  const handleProductRemoveCalculation = (newSaleItems: any) => {
    let subTotalAll = 0;
    let sgstAll = 0;
    let cgstAll = 0;
    let totalGstAll = 0;
    let finalTotalAll = 0;

    if (newSaleItems) {
      newSaleItems.map((row: any) => {
        if (row.price && row.quantity) {
          subTotalAll += Number(row.price) * Number(row.quantity);
        }
        if (row?.gst_percentage) {
          totalGstAll += Number(
            (Number(row.price) *
              Number(row.quantity) *
              Number(row?.gst_percentage)) /
              100
          );
          sgstAll = totalGstAll / 2;
          cgstAll = totalGstAll / 2;
        }
        return "";
      });
    }

    finalTotalAll =
      Number(subTotalAll) + Number(totalGstAll) + Number(values?.round_of);

    setFieldValue(`sub_total`, subTotalAll);
    setFieldValue(`cgst`, cgstAll);
    setFieldValue(`sgst`, sgstAll);
    setFieldValue(`gst_amount`, totalGstAll);
    setFieldValue(`total`, finalTotalAll);
  };

  return (
    <div
      className="row m-0 mb-3 gy-1"
      style={{
        border: "1px solid #d1d0d4",
        borderRadius: "0.375rem",
        padding: "10px 5px 20px 5px",
      }}
    >
      <div className="col-12 col-sm-6 col-lg-2">
        <div className="d-flex justify-content-between">
          <div>
            <label
              htmlFor="product_id"
              className="form-label"
              style={{ fontSize: "0.9375rem" }}
            >
              Product: <span className="text-danger">*</span>
            </label>
          </div>
          <div>
            <button
              title="Add Product"
              className="btn btn-sm btn-primary waves-effect waves-light p-0"
              type="button"
              onClick={() => setAddProductModalStatus(true)}
            >
              <span>
                <i className="ti ti-plus my-card-icon-button"></i>
              </span>
            </button>
          </div>
        </div>
        <Field name={`sale_items.${index}.product_id`}>
          {({ field }: any) => {
            const selectedOption = productList.find(
              (option: any) => option.id === field.value
            );
            const formatOptionLabel = (option: any, { context }: any) => {
              if (context === "value") {
                return option.name;
              } else {
                return (
                  <div>
                    <label className="form-label">{option.name}</label>
                    <br />
                    <span className="form-label">{option.description}</span>
                  </div>
                );
              }
            };
            return (
              <ReactSelect
                {...field}
                classNamePrefix="react-select"
                placeholder="Select Product"
                options={productList}
                getOptionLabel={(option: any) => option.name}
                formatOptionLabel={formatOptionLabel}
                getOptionValue={(option: any) => option.id}
                onChange={(selectedOption: any) => {
                  setFieldValue(
                    `sale_items.${index}.product_id`,
                    selectedOption ? selectedOption.id : ""
                  );
                }}
                value={selectedOption}
                isClearable
                isSearchable
              />
            );
          }}
        </Field>
        <ErrorMessage
          name={`sale_items.${index}.product_id`}
          component="div"
          className="text-danger"
        />
      </div>

      <div className="col-12 col-sm-6 col-lg-2">
        <label className="form-label" style={{ fontSize: "0.9375rem" }}>
          Price ({CURRENCY.INR.SYMBOL}): <span className="text-danger">*</span>
        </label>
        <Field
          id={`sale_items.${index}.price`}
          name={`sale_items.${index}.price`}
          type="number"
          className="form-control"
          placeholder="Enter Price"
          value={item?.price || undefined}
          onChange={(e: any) => {
            values.sale_items[index].price = parseFloat(e.target.value);
            handleProductCalculation(index);
          }}
        />
        <ErrorMessage
          name={`sale_items.${index}.price`}
          component="div"
          className="text-danger"
        />
      </div>
      <div className="col-12 col-sm-6 col-lg-2">
        <label className="form-label" style={{ fontSize: "0.9375rem" }}>
          Quantity: <span className="text-danger">*</span>
        </label>
        <Field
          id={`sale_items.${index}.quantity`}
          name={`sale_items.${index}.quantity`}
          type="number"
          className="form-control"
          placeholder="Enter Quantity"
          value={item?.quantity || undefined}
          onChange={(e: any) => {
            values.sale_items[index].quantity = parseFloat(e.target.value);
            handleProductCalculation(index);
          }}
        />
        <ErrorMessage
          name={`sale_items.${index}.quantity`}
          component="div"
          className="text-danger"
        />
      </div>
      <div className="col-12 col-sm-6 col-lg-2">
        <div className="d-flex justify-content-between">
          <div>
            <label
              htmlFor={`sale_items.${index}.unit_id`}
              className="form-label"
              style={{ fontSize: "0.9375rem" }}
            >
              Quantity Unit: <span className="text-danger">*</span>
            </label>
          </div>
          <div>
            <button
              title="Add Unit"
              className="btn btn-sm btn-primary waves-effect waves-light p-0"
              type="button"
              onClick={() => setAddUnitModalStatus(true)}
            >
              <span>
                <i className="ti ti-plus my-card-icon-button"></i>
              </span>
            </button>
          </div>
        </div>
        <select
          id={`sale_items.${index}.unit_id`}
          name={`sale_items.${index}.unit_id`}
          className="form-select"
          value={item?.unit_id}
          onChange={handleChange}
        >
          <option defaultChecked value={""}>
            Select Quantity Unit
          </option>
          {unitList.map((unit: any) => (
            <option key={unit.id} value={unit.id}>
              {unit.name}
            </option>
          ))}
        </select>
        <ErrorMessage
          name={`sale_items.${index}.unit_id`}
          className="text-danger"
          component="div"
        />
      </div>

      <div className="col-12 col-sm-6 col-lg-2">
        <label className="form-label" style={{ fontSize: "0.9375rem" }}>
          No of Bags:
        </label>
        <Field
          id={`sale_items.${index}.no_of_bags`}
          name={`sale_items.${index}.no_of_bags`}
          type="number"
          className="form-control"
          placeholder="Enter No of Bags"
          value={item?.no_of_bags || null}
          onChange={handleChange}
        />
        <ErrorMessage
          name={`sale_items.${index}.no_of_bags`}
          className="text-danger"
          component="div"
        />
      </div>
      <div className="col-12 col-sm-6 col-lg-2">
        <label
          htmlFor={`sale_items.${index}.sub_total`}
          className="form-label"
          style={{ fontSize: "0.9375rem" }}
        >
          SubTotal ({CURRENCY.INR.SYMBOL}):
        </label>
        <Field
          id={`sale_items.${index}.sub_total`}
          name={`sale_items.${index}.sub_total`}
          type="number"
          className="form-control"
          disabled
        />
        <ErrorMessage
          name={`sale_items.${index}.sub_total`}
          component="div"
          className="text-danger"
        />
      </div>
      <div className="col-12 col-sm-6 col-lg-2">
        <label
          htmlFor={`sale_items.${index}.gst_percentage`}
          className="form-label"
          style={{ fontSize: "0.9375rem" }}
        >
          GST Percentage: <span className="text-danger">*</span>
        </label>
        <Field
          as="select"
          id={`sale_items.${index}.gst_percentage`}
          name={`sale_items.${index}.gst_percentage`}
          className="form-select"
          onChange={(e: any) => {
            handleChange(e);
            values.sale_items[index].gst_percentage = parseFloat(
              e.target.value
            );
            handleProductCalculation(index);
          }}
        >
          <option value="">Select Percentage</option>
          <option value="0">0%</option>
          <option value="5">5%</option>
          <option value="18">18%</option>
          <option value="28">28%</option>
        </Field>
        <ErrorMessage
          name={`sale_items.${index}.gst_percentage`}
          component="div"
          className="text-danger"
        />
      </div>
      <div className="col-12 col-sm-6 col-lg-2">
        <label
          htmlFor={`sale_items.${index}.cgst`}
          className="form-label"
          style={{ fontSize: "0.9375rem" }}
        >
          CGST ({CURRENCY.INR.SYMBOL}):
        </label>
        <Field
          id={`sale_items.${index}.cgst`}
          name={`sale_items.${index}.cgst`}
          type="number"
          className="form-control"
          disabled
        />
        <ErrorMessage
          name={`sale_items.${index}.cgst`}
          component="div"
          className="text-danger"
        />
      </div>
      <div className="col-12 col-sm-6 col-lg-2">
        <label
          htmlFor={`sale_items.${index}.sgst`}
          className="form-label"
          style={{ fontSize: "0.9375rem" }}
        >
          SGST ({CURRENCY.INR.SYMBOL}):
        </label>
        <Field
          id={`sale_items.${index}.sgst`}
          name={`sale_items.${index}.sgst`}
          type="number"
          className="form-control"
          disabled
        />
        <ErrorMessage
          name={`sale_items.${index}.sgst`}
          component="div"
          className="text-danger"
        />
      </div>
      <div className="col-12 col-sm-6 col-lg-2">
        <label
          htmlFor={`sale_items.${index}.gst_amount`}
          className="form-label"
          style={{ fontSize: "0.9375rem" }}
        >
          GST Amount ({CURRENCY.INR.SYMBOL}):
        </label>
        <Field
          id={`sale_items.${index}.gst_amount`}
          name={`sale_items.${index}.gst_amount`}
          type="number"
          className="form-control"
          disabled
        />
        <ErrorMessage
          name={`sale_items.${index}.gst_amount`}
          component="div"
          className="text-danger"
        />
      </div>
      <div className="col-12 col-sm-6 col-lg-2">
        <label
          htmlFor={`sale_items.${index}.total`}
          className="form-label"
          style={{ fontSize: "0.9375rem" }}
        >
          Total ({CURRENCY.INR.SYMBOL}):
        </label>
        <Field
          id={`sale_items.${index}.total`}
          name={`sale_items.${index}.total`}
          type="number"
          className="form-control"
          disabled
        />
      </div>
      <div className="col-12 col-sm-6 col-lg-2 d-flex align-items-end">
        <button
          type="button"
          className="btn btn-danger mt-3"
          onClick={() => {
            remove(index);
            const newSaleItems = values.sale_items.filter(
              (x: any, indexKey: any) => indexKey !== index
            );
            handleProductRemoveCalculation(newSaleItems);
          }}
        >
          Delete
        </button>
      </div>
    </div>
  );
};

export default ProductRow;
