import { GET } from "./_config";

export const buyerWiseSalesReportApi = async (query: string) =>
  await GET(`/reports/buyer-wise-sales-report?${query}`, true);

export const productWiseSalesReportApi = async (query: string) =>
  await GET(`/reports/product-wise-sales-report?${query || ""}`, true);

export const supplierWisePurchaseReportApi = async (query: string) =>
  await GET(`/reports/supplier-wise-purchase-report?${query}`, true);

export const productWisePurchaseReportApi = async (query: string) =>
  await GET(`/reports/product-wise-purchase-report?${query}`, true);

export const profitAndLossReportApi = async (query: string) =>
  await GET(`/reports/profit-and-loss-report?${query}`, true);

export const buyerWiseSalesPaymentReportApi = async (query: string) =>
  await GET(`/reports/buyer-wise-sales-payment-report?${query}`, true);

export const brokerWisePaymentReportApi = async (query: string) =>
  await GET(`/reports/broker-wise-payment-report?${query}`, true);

export const supplierWisePaymentReportApi = async (query: string) =>
  await GET(`/reports/supplier-wise-payment-report?${query}`, true);

export const logisticWisePaymentReportApi = async (query: string) =>
  await GET(`/reports/logistic-wise-payment-report?${query}`, true);

export const chaWisePaymentReportApi = async (query: string) =>
  await GET(`/reports/cha-wise-payment-report?${query}`, true);

export const expenseReportApi = async (query: string) =>
  await GET(`/reports/expense-report?${query}`, true);

const reportService = {
  buyerWiseSalesReportApi,
  productWiseSalesReportApi,
  supplierWisePurchaseReportApi,
  productWisePurchaseReportApi,
  profitAndLossReportApi,
  buyerWiseSalesPaymentReportApi,
  supplierWisePaymentReportApi,
  logisticWisePaymentReportApi,
  chaWisePaymentReportApi,
  expenseReportApi,
};

export default reportService;
