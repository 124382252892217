/* eslint-disable react-hooks/exhaustive-deps */
import moment from "moment";
import { useEffect, useState } from "react";
import { getBuyerByIdApi } from "../../services/_buyer";

interface Props {
  buyerId: string;
}

const ViewBuyer: React.FC<Props> = ({ buyerId }) => {
  const [buyer, setBuyer] = useState<any>({});

  useEffect(() => {
    if (buyerId) {
      setBuyer({});
      getBuyerDetails();
    }
  }, [buyerId]);

  const getBuyerDetails = async () => {
    const result: any = await getBuyerByIdApi(buyerId as string);
    if (result.isSuccess) {
      setBuyer(result?.data);
    }
  };

  return (
    <div className="modal fade" id="viewBuyer" tabIndex={-1} aria-hidden="true">
      <div className="modal-dialog modal-simple modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-body pt-0 pb-0">
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
            <div className="text-center">
              <h4 className="mb-0">Buyer Details</h4>
            </div>
            <div className="info-container">
              <div className="pb-4 border-bottom text-capitalize mb-4"></div>
              <ul className="list-unstyled mb-6">
                <li className="mb-2">
                  <span className="h6 me-1">Name:</span>
                  <span>{buyer?.name}</span>
                </li>
                <li className="mb-2">
                  <span className="h6 me-1">Email:</span>
                  <span>{buyer?.email}</span>
                </li>
                <li className="mb-2">
                  <span className="h6 me-1">Phone No:</span>
                  <span>{buyer?.phone_no}</span>
                </li>
                <li className="mb-2">
                  <span className="h6 me-1">PAN No:</span>
                  <span>{buyer?.pan_no}</span>
                </li>
                <li className="mb-2">
                  <span className="h6 me-1">GST No:</span>
                  <span>{buyer?.gst_no}</span>
                </li>
                <li className="mb-2">
                  <span className="h6 me-1">TAN No:</span>
                  <span>{buyer?.tan_no}</span>
                </li>
                <li className="mb-2">
                  <span className="h6 me-1">UDHYAM No:</span>
                  <span>{buyer?.udhyam_no}</span>
                </li>
                <li className="mb-2">
                  <span className="h6 me-1">Address:</span>
                  <span>{buyer?.address}</span>
                </li>
                <li className="mb-2">
                  <span className="h6 me-1">City:</span>
                  <span>{buyer?.city}</span>
                </li>
                <li className="mb-2">
                  <span className="h6 me-1">State:</span>
                  <span>{buyer?.state}</span>
                </li>
                <li className="mb-2">
                  <span className="h6 me-1">Country:</span>
                  <span>{buyer?.country}</span>
                </li>
                <li className="mb-2">
                  <span className="h6 me-1">Pin Code:</span>
                  <span>{buyer?.pin_code}</span>
                </li>
                <li className="mb-2">
                  <span className="h6 me-1">Status:</span>
                  <span
                    className={`badge ${
                      buyer?.status ? "bg-label-success" : "bg-label-danger"
                    }`}
                  >
                    {buyer?.status ? "Active" : "Inactive"}
                  </span>
                </li>
                <li className="mb-2">
                  <span className="h6 me-1">Created At:</span>
                  <span>
                    {buyer?.created_at
                      ? moment(buyer?.created_at).format("YYYY-MM-DD HH:mm:ss")
                      : ""}
                  </span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewBuyer;
