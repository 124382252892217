/* eslint-disable react-hooks/exhaustive-deps */
import {
  updatePurchaseApi,
  getPurchaseByIdApi,
} from "../../services/_purchase";
import {
  Product,
  initialPurchaseItem,
  purchaseInitialValues,
  purchaseValidationSchema,
} from "./helpers/PurchaseHelper";
import { DatePicker } from "rsuite";
import ProductRow from "./ProductRow";
import TextEditor from "../../components/TextEditor";
import { CURRENCY } from "../../components/Constants";
import { useCallback, useEffect, useState } from "react";
import { getProductNameListApi } from "../../services/_product";
import { Link, useNavigate, useParams } from "react-router-dom";
import { getSupplierNameListApi } from "../../services/_supplier";
import { errorToast, successToast } from "../../components/Toast";
import { Formik, Form, Field, ErrorMessage, FieldArray } from "formik";
import "rsuite/DateRangePicker/styles/index.css";
import { getAllUnitsApi } from "../../services/_unit";
import AddUnitModal from "../../components/modal/AddUnit";
import AddProductModal from "../../components/modal/AddProduct";
import AddSupplierModal from "../../components/modal/AddSupplier";

const EditPurchase = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [supplierOptions, setSupplierOptions] = useState<Product[]>([]);
  const [productList, setProductList] = useState<Product[]>([]);
  const [updateLoading, setUpdateLoading] = useState<boolean>(false);
  const [purchaseItemDetails, setPurchaseItemDetails] = useState(
    purchaseInitialValues
  );
  const [unitList, setUnitList] = useState<any[]>([]);
  const [addUnitModalStatus, setAddUnitModalStatus] = useState(false);
  const [addProductModalStatus, setAddProductModalStatus] = useState(false);
  const [addSupplierModal, setAddSupplierModal] = useState(false);

  useEffect(() => {
    if (!id) {
      navigate(`/purchase-list`);
    } else {
      getProductNameList();
      getSupplierNameList();
      getPurchaseDetailsById();
      getUnitList();
    }
  }, [id]);

  const getProductNameList = async () => {
    try {
      const result: any = await getProductNameListApi();
      setProductList(result.data);
    } catch (error) {
      console.error("Error fetching product list", error);
    }
  };

  const getSupplierNameList = async () => {
    try {
      const result: any = await getSupplierNameListApi();
      setSupplierOptions(result.data);
    } catch (error) {
      console.error("Error fetching supplier list", error);
    }
  };

  const getUnitList = async () => {
    try {
      const result: any = await getAllUnitsApi();
      setUnitList(result.data);
    } catch (error) {
      console.error("Error fetching Unit List", error);
    }
  };

  const closeAddUnitModal = () => {
    setAddUnitModalStatus(false);
  };

  const closeAddProductModal = () => {
    setAddProductModalStatus(false);
  };

  const closeAddSupplierModal = () => {
    setAddSupplierModal(false);
  };

  const getPurchaseDetailsById = async () => {
    try {
      const result: any = await getPurchaseByIdApi(id as string);
      if (result.isSuccess) {
        setPurchaseItemDetails({
          invoice_no: result.data.invoice_no,
          supplier_id: result.data.supplier_id,
          purchase_date: result?.data?.purchase_date,
          sub_total: parseFloat(result.data.sub_total),
          cgst: result.data.cgst,
          sgst: result.data.sgst,
          gst_amount: result.data.gst_amount,
          round_of: result.data.round_of ?? 0,
          total: result.data.total,
          purchase_items: result.data.purchase_item,
          packing_details: result?.data?.packing_details || "",
          terms_of_payment: result?.data?.terms_of_payment || "",
          terms_and_condition: result.data.terms_and_condition
            ? JSON.parse(result.data.terms_and_condition)
            : "",
        });
      }
    } catch (error) {
      console.error("Error fetching supplier list", error);
    }
  };

  const handleCalculation = useCallback((values: any, setFieldValue: any) => {
    const finalTotal =
      Number(values?.sub_total) +
      Number(values?.gst_amount) +
      Number(values?.round_of);
    setFieldValue(`total`, finalTotal);
  }, []);

  const handleSubmit = async (values: any, { resetForm }: any) => {
    setUpdateLoading(true);
    if (values?.terms_and_condition) {
      values.terms_and_condition = JSON.stringify(values.terms_and_condition);
    }
    const result: any = await updatePurchaseApi(id as string, values);
    if (result.isSuccess) {
      getPurchaseDetailsById();
      setUpdateLoading(false);
      successToast(result.message);
      navigate(`/purchase-list`);
    } else {
      setUpdateLoading(false);
      errorToast(result.message);
    }
  };

  return (
    <>
      <div className="card">
        <div className="card-header pb-0">
          <div>
            <h5>Edit Purchase </h5>
          </div>
        </div>
        <div className="card-body">
          <Formik
            initialValues={purchaseItemDetails}
            validationSchema={purchaseValidationSchema}
            onSubmit={handleSubmit}
            enableReinitialize={true}
          >
            {({ errors, touched, handleChange, values, setFieldValue }) => {
              return (
                <Form className="dt_adv_search">
                  <div className="row">
                    <div className="col-12">
                      <div className="row g-3">
                        <div className="col-12 col-sm-6 col-lg-4">
                          <label
                            htmlFor="invoice_no"
                            className="form-label"
                            style={{ fontSize: "0.9375rem" }}
                          >
                            Invoice No: <span className="text-danger">*</span>
                          </label>
                          <Field
                            id="invoice_no"
                            name="invoice_no"
                            type="text"
                            className="form-control"
                            placeholder="Enter Invoice No"
                            onChange={handleChange}
                          />
                          <ErrorMessage
                            name="invoice_no"
                            className="text-danger"
                            component="div"
                          />
                        </div>
                        <div className="col-12 col-sm-6 col-lg-4">
                          <div className="d-flex justify-content-between">
                            <div>
                              <label
                                htmlFor="supplier_id"
                                className="form-label"
                                style={{ fontSize: "0.9375rem" }}
                              >
                                Supplier: <span className="text-danger">*</span>
                              </label>
                            </div>
                            <div>
                              <button
                                title="Add Supplier"
                                className="btn btn-sm btn-primary waves-effect waves-light p-0"
                                type="button"
                                onClick={() => setAddSupplierModal(true)}
                              >
                                <span>
                                  <i className="ti ti-plus my-card-icon-button"></i>
                                </span>
                              </button>
                            </div>
                          </div>
                          <Field
                            as="select"
                            id="supplier_id"
                            name="supplier_id"
                            className="form-control"
                            onChange={handleChange}
                          >
                            <option value="">Enter Supplier Name</option>
                            {supplierOptions.map((supplier) => (
                              <option key={supplier.id} value={supplier.id}>
                                {supplier.name}
                              </option>
                            ))}
                          </Field>
                          <ErrorMessage
                            name="supplier_id"
                            className="text-danger"
                            component="div"
                          />
                        </div>
                        <div className="col-12 col-sm-6 col-lg-4">
                          <label
                            htmlFor="purchase_date"
                            className="form-label"
                            style={{ fontSize: "0.9375rem" }}
                          >
                            Purchase Date:{" "}
                            <span className="text-danger">*</span>
                          </label>
                          <DatePicker
                            id="purchase_date"
                            name="purchase_date"
                            format="dd-MM-yyyy"
                            className="form-control"
                            placeholder="Select Purchase Date"
                            value={
                              values.purchase_date
                                ? new Date(values.purchase_date)
                                : null
                            }
                            onChange={(value: any) => {
                              setFieldValue("purchase_date", value);
                            }}
                          />
                          <ErrorMessage
                            name="purchase_date"
                            className="text-danger"
                            component="div"
                          />
                        </div>
                        <div>
                          <label
                            htmlFor="products"
                            className="form-label"
                            style={{ fontSize: "0.9375rem" }}
                          >
                            Add Products: <span className="text-danger">*</span>
                          </label>

                          <div>
                            <FieldArray name="purchase_items">
                              {({ remove }) => (
                                <>
                                  {values.purchase_items.map(
                                    (item: any, index: number) => (
                                      <ProductRow
                                        key={index}
                                        index={index}
                                        item={item}
                                        productList={productList}
                                        unitList={unitList}
                                        values={values}
                                        setFieldValue={setFieldValue}
                                        remove={remove}
                                        handleChange={handleChange}
                                        setAddUnitModalStatus={
                                          setAddUnitModalStatus
                                        }
                                        setAddProductModalStatus={
                                          setAddProductModalStatus
                                        }
                                      />
                                    )
                                  )}

                                  <div className="row mb-1 g-1 px-3">
                                    <button
                                      type="button"
                                      className="btn btn-primary col-lg-3"
                                      onClick={() => {
                                        const newItems = [
                                          ...values.purchase_items,
                                          initialPurchaseItem,
                                        ];
                                        setFieldValue(
                                          "purchase_items",
                                          newItems
                                        );
                                      }}
                                    >
                                      Add Product
                                    </button>
                                  </div>
                                </>
                              )}
                            </FieldArray>

                            {errors.purchase_items &&
                              touched.purchase_items &&
                              typeof errors.purchase_items === "string" && (
                                <div className="text-danger">
                                  {errors.purchase_items}
                                </div>
                              )}
                          </div>
                        </div>
                        <div className="col-12 col-sm-6 col-lg-3 mt-4">
                          <label
                            htmlFor="sub_total"
                            className="form-label"
                            style={{ fontSize: "0.9375rem" }}
                          >
                            Sub Total ({CURRENCY.INR.SYMBOL}):
                          </label>
                          <Field
                            name="sub_total"
                            type="number"
                            className="form-control"
                            disabled
                            value={values.sub_total}
                          />
                          <ErrorMessage
                            name="sub_total"
                            component="div"
                            className="text-danger"
                          />
                        </div>

                        <div className="col-12 col-sm-6 col-lg-3">
                          <label
                            htmlFor="cgst"
                            className="form-label"
                            style={{ fontSize: "0.9375rem" }}
                          >
                            CGST ({CURRENCY.INR.SYMBOL}):
                          </label>
                          <Field
                            name="cgst"
                            type="number"
                            className="form-control"
                            value={values.cgst}
                            disabled
                          />
                          <ErrorMessage
                            name="cgst"
                            component="div"
                            className="text-danger"
                          />
                        </div>

                        <div className="col-12 col-sm-6 col-lg-3">
                          <label
                            htmlFor="sgst"
                            className="form-label"
                            style={{ fontSize: "0.9375rem" }}
                          >
                            SGST ({CURRENCY.INR.SYMBOL}):
                          </label>
                          <Field
                            name="sgst"
                            type="number"
                            className="form-control"
                            value={values.sgst}
                            disabled
                          />
                          <ErrorMessage
                            name="sgst"
                            component="div"
                            className="text-danger"
                          />
                        </div>

                        <div className="col-12 col-sm-6 col-lg-3">
                          <label
                            htmlFor="gst_amount"
                            className="form-label"
                            style={{ fontSize: "0.9375rem" }}
                          >
                            GST Amount ({CURRENCY.INR.SYMBOL}):
                          </label>
                          <Field
                            name="gst_amount"
                            type="number"
                            className="form-control"
                            value={values.gst_amount}
                            disabled
                          />
                          <ErrorMessage
                            name="gst_amount"
                            component="div"
                            className="text-danger"
                          />
                        </div>

                        <div className="col-12 col-sm-6 col-lg-3">
                          <label
                            htmlFor="round_of"
                            className="form-label"
                            style={{ fontSize: "0.9375rem" }}
                          >
                            Round Of ({CURRENCY.INR.SYMBOL}):
                          </label>
                          <Field
                            name="round_of"
                            type="number"
                            className="form-control"
                            step="0.1"
                            value={values.round_of}
                            onInput={(e: any) => {
                              values.round_of = parseFloat(e.target.value) || 0;
                              handleCalculation(values, setFieldValue);
                            }}
                          />
                        </div>

                        <div className="col-12 col-sm-6 col-lg-3">
                          <label
                            htmlFor="total"
                            className="form-label"
                            style={{ fontSize: "0.9375rem" }}
                          >
                            Total Amount ({CURRENCY.INR.SYMBOL}):
                            <span className="text-danger">*</span>
                          </label>
                          <Field
                            name="total"
                            type="number"
                            className="form-control"
                            value={values.total}
                            disabled
                          />
                          <ErrorMessage
                            name="total"
                            component="div"
                            className="text-danger"
                          />
                        </div>
                        <div className="col-12 col-sm-6 col-lg-3">
                          <label
                            htmlFor="terms_of_payment"
                            className="form-label"
                            style={{ fontSize: "0.9375rem" }}
                          >
                            Terms of Payment:
                          </label>
                          <Field
                            id="terms_of_payment"
                            name="terms_of_payment"
                            type="text"
                            className="form-control"
                            placeholder="Enter Terms of Payment"
                            onChange={handleChange}
                          />
                        </div>
                        <div className="col-12">
                          <label
                            htmlFor="packing_details"
                            className="form-label"
                            style={{ fontSize: "0.9375rem" }}
                          >
                            Packing Details:
                          </label>
                          <Field
                            id="packing_details"
                            name="packing_details"
                            type="text"
                            className="form-control"
                            placeholder="Enter Packing Details"
                            onChange={handleChange}
                            as={"textarea"}
                            rows={2}
                          />
                        </div>
                        <div
                          className="col-12 "
                          style={{ height: "275px", marginBottom: "10px" }}
                        >
                          <label
                            htmlFor="packing_details"
                            className="form-label"
                            style={{ fontSize: "0.9375rem" }}
                          >
                            Terms & Condition :
                          </label>
                          <TextEditor
                            value={values.terms_and_condition}
                            onChange={(value: string) =>
                              setFieldValue("terms_and_condition", value)
                            }
                            placeholder="Write terms & conditions"
                          />
                        </div>

                        <div className="col-12">
                          <button
                            type="submit"
                            className="btn btn-primary"
                            disabled={updateLoading}
                          >
                            {updateLoading && (
                              <span
                                className="spinner-border spinner-border-sm me-2"
                                role="status"
                                aria-hidden="true"
                              ></span>
                            )}
                            Submit
                          </button>
                          <Link
                            to={"/purchase-list"}
                            type="button"
                            className="btn btn-label-github ms-5"
                          >
                            Back
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </Form>
              );
            }}
          </Formik>
        </div>
      </div>
      <AddUnitModal
        addUnitModalStatus={addUnitModalStatus}
        closeAddUnitModal={closeAddUnitModal}
        onUnitCreated={getUnitList}
      />
      <AddProductModal
        addProductModalStatus={addProductModalStatus}
        closeAddProductModal={closeAddProductModal}
        onProductCreated={getProductNameList}
      />
      <AddSupplierModal
        addSupplierModal={addSupplierModal}
        closeAddSupplierModal={closeAddSupplierModal}
        onSupplierCreated={getSupplierNameList}
      />
    </>
  );
};

export default EditPurchase;
