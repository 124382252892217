import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  Font,
  // Image,
} from "@react-pdf/renderer";
import {
  convertAmountToWords,
  formatAmount,
  formatDate,
  stringToUpperCase,
} from "../../../../components/Helper";
import { CURRENCY } from "../../../../components/Constants";

Font.register({
  family: "Times New Roman",
  fonts: [{ src: "/fonts/Times-New-Roman.ttf" }],
});

Font.register({
  family: "Times New Roman",
  fonts: [{ src: "/fonts/Times-New-Roman-Bold.ttf" }],
});

export const styles = StyleSheet.create({
  smallFontSize: {
    fontSize: 6,
  },
  mediumFontSize: {
    fontSize: 6.5,
  },
  regularFontSize: {
    fontSize: 8,
  },
  largeFontSize: {
    fontSize: 9,
  },
  boldFont: {
    fontWeight: "bold",
  },
  textUnderline: {
    textDecoration: "underline",
  },
  header: {
    fontSize: 8,
    textAlign: "center",
    padding: 1,
    fontWeight: "bold",
    borderBottomWidth: 1,
  },
  contentPadding: {
    padding: 1,
  },
  page: {
    paddingHorizontal: 30,
    paddingVertical: 15,
    fontFamily: "Times New Roman",
    backgroundColor: "white",
  },
  fullpage: {
    margin: 7,
    borderStyle: "solid",
    borderWidth: 1,
    borderColor: "#000000",
  },
  leftSection: {
    flex: 1,
    padding: 10,
    paddingLeft: 0,
  },
  rightSection: {
    flex: 3,
    padding: 10,
  },
  section: {
    borderBottomWidth: 1,
    borderbottomStyle: "solid",
  },
  sideBySideContainer: { flexDirection: "row" },
  halfWidthRightSection: {
    width: "50%",
    borderBottomWidth: 1,
    borderbottomStyle: "solid",
    borderRightWidth: 1,
    textAlign: "left",
  },
  halfWidthleftSection: {
    width: "50%",
    borderBottomWidth: 1,
    borderbottomStyle: "solid",
  },
  tableContainer: {
    borderBottomWidth: 1,
  },
  tableRow: {
    flexDirection: "row",
    flexGrow: 1,
  },
  tableHeader: {
    fontSize: 7,
    width: "50%",
  },
  tableData: {
    fontSize: 7,
    width: "70%",
  },
  tableCell: {
    padding: 5,
    fontSize: 7,
    textAlign: "center",
    borderRightWidth: 1,
  },
  addressBlock: { fontSize: 7, marginBottom: 1.5 },
});

const CommercialInvoice = ({
  settingData,
  salesInvoiceData,
}: {
  settingData: any;
  salesInvoiceData: any;
}) => {
  let centerHeight = 400;
  const containerRowRatio = 13;
  if (salesInvoiceData?.containers?.length > 0) {
    centerHeight =
      400 - containerRowRatio * salesInvoiceData?.containers?.length;
  }

  return (
    <Document>
      <Page style={styles.page} size="A4">
        <View style={styles.fullpage}>
          {/* HEADER */}
          <Text style={[styles.header]}>COMMERCIAL INVOICE</Text>

          {/* BLANK SECTION */}
          <Text style={[styles.header, { height: 10 }]}></Text>

          {/* EXPORTER | INVOICE NO | IEC NO | BUYER SECTION */}
          <View style={styles.sideBySideContainer}>
            <View style={[styles.halfWidthRightSection, styles.contentPadding]}>
              <Text style={styles.addressBlock}>
                <Text style={[styles.regularFontSize, styles.boldFont]}>
                  EXPORTER:
                </Text>
              </Text>
              <Text style={[styles.addressBlock, styles.regularFontSize]}>
                {stringToUpperCase(settingData?.company_name)}
              </Text>
              <Text style={[styles.addressBlock, styles.regularFontSize]}>
                {stringToUpperCase(settingData?.company_address)}
              </Text>
              <Text style={[styles.addressBlock, styles.regularFontSize]}>
                GSTIN: {stringToUpperCase(settingData?.company_gst_no)}
              </Text>
              <Text style={[styles.addressBlock, styles.regularFontSize]}>
                PAN NO: {stringToUpperCase(settingData?.company_pan_no)}
              </Text>
              <Text style={[styles.addressBlock, styles.regularFontSize]}>
                AEO REG. NO:{" "}
                {stringToUpperCase(settingData?.aeo_registration_no)}
              </Text>
            </View>
            <View style={[styles.halfWidthleftSection]}>
              <View style={[styles.sideBySideContainer]}>
                <View
                  style={[
                    {
                      borderBottom: 1,
                      borderRight: 1,
                      flex: 1.5,
                    },
                  ]}
                >
                  <Text
                    style={[
                      styles.regularFontSize,
                      {
                        paddingLeft: 1,
                        borderBottom: 1,
                      },
                    ]}
                  >
                    INVOICE NO & DATE:
                  </Text>

                  <Text
                    style={[
                      styles.regularFontSize,
                      styles.boldFont,
                      { paddingLeft: 1 },
                    ]}
                  >
                    {stringToUpperCase(salesInvoiceData?.invoice_no)},{" "}
                    {formatDate(salesInvoiceData?.invoice_date)}
                  </Text>
                </View>
                <View
                  style={[
                    {
                      borderBottom: 1,
                      flex: 1.5,
                    },
                  ]}
                >
                  <Text
                    style={[
                      styles.regularFontSize,
                      {
                        borderBottom: 1,
                        textAlign: "center",
                      },
                    ]}
                  >
                    IEC NO.
                  </Text>

                  <Text
                    style={[
                      styles.regularFontSize,
                      styles.boldFont,
                      { textAlign: "center" },
                    ]}
                  >
                    {stringToUpperCase(settingData?.iec_no)}
                  </Text>
                </View>
              </View>
              <View style={styles.section}>
                <Text style={[styles.mediumFontSize, styles.contentPadding]}>
                  SALES CONTRACT NO.{" "}
                  <Text style={[styles.boldFont]}>
                    {salesInvoiceData?.sales_contract?.contract_no}
                  </Text>{" "}
                  DATE:{" "}
                  <Text style={[styles.boldFont]}>
                    {formatDate(
                      salesInvoiceData?.sales_contract?.contract_date
                    )}
                  </Text>
                </Text>
              </View>
              <View style={styles.contentPadding}>
                <Text style={styles.addressBlock}>
                  <Text
                    style={[
                      styles.largeFontSize,
                      styles.boldFont,
                      styles.textUnderline,
                    ]}
                  >
                    NOTIFY PARTY (BUYER) :
                  </Text>
                </Text>
                <Text style={[styles.regularFontSize]}>SAME AS CONSIGNEE</Text>
              </View>
            </View>
          </View>

          {/* CONSIGNEE | BANK DETAILS */}
          <View style={styles.sideBySideContainer}>
            {/* CONSIGNEE */}
            <View style={[styles.halfWidthRightSection, styles.contentPadding]}>
              <Text style={styles.addressBlock}>
                <Text
                  style={[
                    styles.regularFontSize,
                    styles.textUnderline,
                    styles.boldFont,
                  ]}
                >
                  CONSIGNEE:
                </Text>
              </Text>
              <Text style={[styles.addressBlock, styles.regularFontSize]}>
                {stringToUpperCase(salesInvoiceData?.buyer?.name)}
              </Text>
              <Text style={[styles.addressBlock, styles.regularFontSize]}>
                {stringToUpperCase(salesInvoiceData?.buyer?.address)}
              </Text>
            </View>
            {/* BANK DETAILS */}
            <View style={[styles.halfWidthleftSection, styles.contentPadding]}>
              <Text style={styles.addressBlock}>
                <Text
                  style={[
                    styles.addressBlock,
                    styles.regularFontSize,
                    styles.boldFont,
                    { textAlign: "center" },
                  ]}
                >
                  BANK DETAILS
                </Text>
              </Text>

              <View style={[styles.tableRow, styles.regularFontSize]}>
                <Text style={styles.tableHeader}>BANK NAME</Text>
                <Text style={styles.tableData}>
                  {stringToUpperCase(
                    salesInvoiceData?.sales_contract?.exporter_bank?.bank_name
                  )}
                </Text>
              </View>

              <View style={[styles.tableRow, styles.regularFontSize]}>
                <Text style={styles.tableHeader}>BANK ACCOUNT NO</Text>
                <Text style={styles.tableData}>
                  {stringToUpperCase(
                    salesInvoiceData?.sales_contract?.exporter_bank
                      ?.account_number
                  )}
                </Text>
              </View>

              <View style={[styles.tableRow, styles.regularFontSize]}>
                <Text style={styles.tableHeader}>BANK IFSC CODE</Text>
                <Text style={styles.tableData}>
                  {stringToUpperCase(
                    salesInvoiceData?.sales_contract?.exporter_bank?.ifsc_code
                  )}
                </Text>
              </View>

              <View style={[styles.tableRow, styles.regularFontSize]}>
                <Text style={styles.tableHeader}>BANK SWIFT CODE</Text>
                <Text style={styles.tableData}>
                  {stringToUpperCase(
                    salesInvoiceData?.sales_contract?.exporter_bank?.swift_code
                  )}
                </Text>
              </View>

              <View style={[styles.tableRow, styles.regularFontSize]}>
                <Text style={styles.tableHeader}>BANK BRANCH ADDRESS</Text>
                <Text style={styles.tableData}>
                  {stringToUpperCase(
                    salesInvoiceData?.sales_contract?.exporter_bank?.address
                  )}
                </Text>
              </View>

              <View style={[styles.tableRow, styles.regularFontSize]}>
                <Text style={styles.tableHeader}>BANK AD CODE</Text>
                <Text style={styles.tableData}>
                  {stringToUpperCase(
                    salesInvoiceData?.sales_contract?.exporter_bank?.ad_code
                  )}
                </Text>
              </View>
            </View>
          </View>

          {/* PORT DETAILS | DELIVERY TERM | PAYMENT TERM */}
          <View style={[styles.sideBySideContainer]}>
            <View style={[styles.halfWidthRightSection]}>
              <View style={[styles.sideBySideContainer]}>
                <View
                  style={[
                    styles.contentPadding,
                    {
                      borderBottomWidth: 1,
                      borderRightWidth: 1,
                      flex: 2,
                    },
                  ]}
                >
                  <Text style={[styles.regularFontSize]}>Pre-Carrige by</Text>
                  <Text
                    style={[
                      styles.regularFontSize,
                      styles.boldFont,
                      { textAlign: "center" },
                    ]}
                  >
                    SEA
                  </Text>
                </View>

                <View
                  style={[
                    styles.contentPadding,
                    {
                      borderBottomWidth: 1,
                      flex: 2,
                    },
                  ]}
                >
                  <Text style={[styles.regularFontSize]}>
                    Place of Receipt by Pre-carrier
                  </Text>
                  <Text
                    style={[
                      styles.regularFontSize,
                      styles.boldFont,
                      { textAlign: "center", borderRightWidth: 0 },
                    ]}
                  >
                    {salesInvoiceData?.origin_port?.name &&
                    salesInvoiceData?.origin_port?.country
                      ? `${stringToUpperCase(
                          salesInvoiceData?.origin_port?.name
                        )}, ${stringToUpperCase(
                          salesInvoiceData?.origin_port?.country
                        )}`
                      : "-"}
                  </Text>
                </View>
              </View>

              {/* 2 */}
              <View style={[styles.sideBySideContainer]}>
                <View
                  style={[
                    styles.contentPadding,
                    {
                      borderBottomWidth: 1,
                      borderRightWidth: 1,
                      flex: 2,
                    },
                  ]}
                >
                  <Text style={[styles.regularFontSize]}>
                    Vessel/ Flight No
                  </Text>
                  <Text
                    style={[
                      styles.regularFontSize,
                      styles.boldFont,
                      { textAlign: "center" },
                    ]}
                  >
                    {salesInvoiceData?.vessel_no
                      ? stringToUpperCase(salesInvoiceData?.vessel_no)
                      : "-"}
                  </Text>
                </View>

                <View
                  style={[
                    styles.contentPadding,
                    {
                      borderBottomWidth: 1,
                      flex: 2,
                    },
                  ]}
                >
                  <Text style={[styles.regularFontSize]}>Port of Loading</Text>

                  <Text
                    style={[
                      styles.regularFontSize,
                      styles.boldFont,
                      { textAlign: "center" },
                    ]}
                  >
                    {salesInvoiceData?.origin_port?.name &&
                    salesInvoiceData?.origin_port?.country
                      ? `${stringToUpperCase(
                          salesInvoiceData?.origin_port?.name
                        )}, ${stringToUpperCase(
                          salesInvoiceData?.origin_port?.country
                        )}`
                      : "-"}
                  </Text>
                </View>
              </View>

              {/* 3*/}
              <View style={[styles.sideBySideContainer]}>
                <View
                  style={[
                    styles.contentPadding,
                    {
                      borderRightWidth: 1,
                      flex: 2,
                    },
                  ]}
                >
                  <Text style={[styles.regularFontSize]}>
                    Port of Discharge
                  </Text>
                  <Text
                    style={[
                      styles.regularFontSize,
                      styles.boldFont,
                      { textAlign: "center" },
                    ]}
                  >
                    {salesInvoiceData?.destination_port?.name &&
                    salesInvoiceData?.destination_port?.country
                      ? `${stringToUpperCase(
                          salesInvoiceData?.destination_port?.name
                        )}, ${stringToUpperCase(
                          salesInvoiceData?.destination_port?.country
                        )}`
                      : "-"}
                  </Text>
                </View>

                <View
                  style={[
                    styles.contentPadding,
                    {
                      flex: 2,
                    },
                  ]}
                >
                  <Text style={[styles.regularFontSize]}>
                    Final Destination
                  </Text>

                  <Text
                    style={[
                      styles.regularFontSize,
                      styles.boldFont,
                      { textAlign: "center" },
                    ]}
                  >
                    {salesInvoiceData?.destination_port?.name &&
                    salesInvoiceData?.destination_port?.country
                      ? `${stringToUpperCase(
                          salesInvoiceData?.destination_port?.name
                        )}, ${stringToUpperCase(
                          salesInvoiceData?.destination_port?.country
                        )}`
                      : "-"}
                  </Text>
                </View>
              </View>
            </View>

            <View style={[styles.halfWidthleftSection]}>
              <View style={[styles.sideBySideContainer]}>
                <View
                  style={[
                    styles.contentPadding,
                    {
                      borderBottomWidth: 1,
                      flex: 2,
                    },
                  ]}
                >
                  <Text style={[styles.regularFontSize]}>
                    Delivery Term:{" "}
                    <Text style={[styles.boldFont]}>
                      {stringToUpperCase(salesInvoiceData?.delivery_term)}
                    </Text>
                  </Text>
                </View>
              </View>
              <View style={[styles.sideBySideContainer]}>
                <View
                  style={[
                    {
                      borderBottomWidth: 1,
                      flex: 2,
                    },
                  ]}
                >
                  <Text style={[styles.regularFontSize, { paddingLeft: 1 }]}>
                    Payment Terms:{" "}
                    <Text style={[styles.boldFont]}>
                      {stringToUpperCase(salesInvoiceData?.payment_term)}
                    </Text>
                  </Text>
                </View>
              </View>

              <View style={[styles.sideBySideContainer]}>
                <View
                  style={[
                    styles.contentPadding,
                    {
                      borderBottomWidth: 1,
                      borderRightWidth: 1,
                      flex: 2,
                    },
                  ]}
                >
                  <Text style={[styles.regularFontSize]}>
                    Country of Origin of Goods
                  </Text>
                </View>
                <View
                  style={[
                    styles.contentPadding,
                    {
                      borderBottomWidth: 1,
                      flex: 2,
                    },
                  ]}
                >
                  <Text style={[styles.regularFontSize]}>
                    Country of Final Destination
                  </Text>
                </View>
              </View>
              <View style={[styles.sideBySideContainer, { borderBottom: 1 }]}>
                <View
                  style={[
                    styles.contentPadding,
                    {
                      borderRightWidth: 1,
                      flex: 2,
                    },
                  ]}
                >
                  <Text
                    style={[
                      styles.regularFontSize,
                      styles.boldFont,
                      { textAlign: "center" },
                    ]}
                  >
                    {salesInvoiceData?.origin_port?.country
                      ? stringToUpperCase(
                          salesInvoiceData?.origin_port?.country
                        )
                      : "-"}
                  </Text>
                </View>
                <View
                  style={[
                    styles.contentPadding,
                    {
                      flex: 2,
                    },
                  ]}
                >
                  <Text
                    style={[
                      styles.regularFontSize,
                      styles.boldFont,
                      { textAlign: "center" },
                    ]}
                  >
                    {salesInvoiceData?.destination_port?.country
                      ? stringToUpperCase(
                          salesInvoiceData?.destination_port?.country
                        )
                      : "-"}
                  </Text>
                </View>
              </View>
            </View>
          </View>

          {/* MAIN TABLE */}
          <View>
            {/* TABLE HEADER */}
            <View
              style={[
                styles.tableRow,
                {
                  borderBottom: 1,
                },
              ]}
            >
              <Text
                style={[
                  {
                    fontSize: 8,
                    paddingLeft: 1,
                    flex: 5,
                  },
                ]}
              >
                Marks & Nos./ Container No.
              </Text>

              <Text
                style={[
                  {
                    fontSize: 8,
                    flex: 9,
                    paddingLeft: 30,
                    verticalAlign: "sub",
                    textAlign: "center",
                  },
                ]}
              >
                No. & Kind of Pkgs
              </Text>

              <Text
                style={[
                  {
                    fontSize: 8,
                    flex: 9,
                    paddingLeft: 40,
                    borderRightWidth: 1,
                    verticalAlign: "sub",
                    textAlign: "center",
                  },
                ]}
              >
                Description of Goods
              </Text>

              <Text
                style={[
                  styles.mediumFontSize,
                  {
                    fontSize: 8,
                    borderRight: 1,
                    textAlign: "center",
                    flexWrap: "wrap",
                    width: 60,
                  },
                ]}
              >
                QUANTITY{"  "}MTS
              </Text>

              <Text
                style={[
                  styles.mediumFontSize,
                  {
                    fontSize: 8,
                    borderRight: 1,
                    textAlign: "center",
                    flexWrap: "wrap",
                    width: 60,
                  },
                ]}
              >
                Rate (USD) Per MTS
              </Text>

              <Text
                style={[
                  styles.mediumFontSize,
                  {
                    fontSize: 8,
                    borderRight: 0,
                    textAlign: "center",
                    flexWrap: "wrap",
                    width: 60,
                    verticalAlign: "sub",
                  },
                ]}
              >
                Total Value USD
              </Text>
            </View>

            {/* TABLE ROWS */}
            <View>
              {/* MAIN ROW */}
              <View
                style={[
                  styles.tableRow,
                  {
                    height: centerHeight,
                    borderBottom: 0,
                  },
                ]}
              >
                <Text
                  style={[
                    styles.regularFontSize,
                    styles.mediumFontSize,
                    {
                      fontSize: 7,
                      paddingLeft: 5,
                      flex: 4,
                      paddingTop: 15,
                      paddingBottom: 15,
                      textAlign: "center",
                    },
                  ]}
                >
                  {salesInvoiceData?.container_size
                    ? stringToUpperCase(salesInvoiceData?.container_size)
                    : "-"}
                </Text>

                <Text
                  style={[
                    styles.regularFontSize,
                    styles.mediumFontSize,
                    {
                      fontSize: 7,
                      flex: 9,
                      paddingLeft: 30,
                      paddingTop: 15,
                      paddingBottom: 15,
                      textAlign: "center",
                    },
                  ]}
                >
                  {salesInvoiceData?.packing_type?.name
                    ? `(PACKED IN ${stringToUpperCase(
                        salesInvoiceData?.packing_type?.name
                      )}) `
                    : ""}
                  {salesInvoiceData?.packing_net_weight &&
                  salesInvoiceData?.net_weight_unit?.name
                    ? `NET ${
                        salesInvoiceData?.packing_net_weight
                      } ${stringToUpperCase(
                        salesInvoiceData?.net_weight_unit?.name
                      )} & `
                    : ""}
                  {salesInvoiceData?.packing_gross_weight &&
                  salesInvoiceData?.gross_weight_unit?.name
                    ? `GROSS ${
                        salesInvoiceData?.packing_gross_weight
                      } ${stringToUpperCase(
                        salesInvoiceData?.gross_weight_unit?.name
                      )} EACH BAG.`
                    : ""}
                </Text>

                <Text
                  style={[
                    styles.regularFontSize,
                    styles.mediumFontSize,
                    {
                      fontSize: 7,
                      flex: 9,
                      paddingLeft: 30,
                      paddingTop: 15,
                      paddingBottom: 15,
                      borderRightWidth: 1,
                      textAlign: "center",
                    },
                  ]}
                >
                  {stringToUpperCase(salesInvoiceData.product?.name)} {"\n"}
                  {salesInvoiceData.product?.hsn_no
                    ? `(HS CODE:- ${stringToUpperCase(
                        salesInvoiceData.product?.hsn_no
                      )})`
                    : ""}
                </Text>

                <Text
                  style={[
                    styles.tableCell,
                    styles.mediumFontSize,
                    {
                      fontSize: 8,
                      fontWeight: "medium",
                      borderRight: 1,
                      textAlign: "center",
                      flexWrap: "wrap",
                      width: 60,
                      paddingTop: 15,
                    },
                  ]}
                >
                  {formatAmount(salesInvoiceData?.quantity)}
                </Text>

                <Text
                  style={[
                    styles.tableCell,
                    styles.mediumFontSize,
                    {
                      fontSize: 8,
                      fontWeight: "medium",
                      borderRight: 1,
                      textAlign: "center",
                      flexWrap: "wrap",
                      width: 60,
                      paddingTop: 15,
                    },
                  ]}
                >
                  {formatAmount(salesInvoiceData?.price)}
                </Text>

                <Text
                  style={[
                    styles.tableCell,
                    styles.mediumFontSize,
                    {
                      fontSize: 8,
                      fontWeight: "medium",
                      borderRight: 0,
                      textAlign: "center",
                      flexWrap: "wrap",
                      width: 60,
                      paddingTop: 15,
                    },
                  ]}
                >
                  {formatAmount(salesInvoiceData?.total_amount)}
                </Text>
              </View>

              {/* SUMMARY */}
              <View
                style={[
                  styles.tableRow,
                  {
                    borderBottom: 0,
                  },
                ]}
              >
                <Text
                  style={[
                    {
                      paddingLeft: 5,
                      flex: 4,
                    },
                  ]}
                ></Text>

                <Text
                  style={[
                    styles.boldFont,
                    {
                      fontSize: 7,
                      flex: 18,
                      paddingLeft: 30,
                      paddingTop: 3,
                      textAlign: "center",
                      borderRight: 1,
                      borderTop: 1,
                      borderLeft: 1,
                      borderBottom: 1,
                    },
                  ]}
                >
                  SUMMARY
                </Text>

                <Text
                  style={[
                    styles.tableCell,
                    {
                      borderRight: 1,
                      flexWrap: "wrap",
                      width: 60,
                    },
                  ]}
                ></Text>

                <Text
                  style={[
                    styles.tableCell,
                    {
                      borderRight: 1,
                      flexWrap: "wrap",
                      width: 60,
                    },
                  ]}
                ></Text>

                <Text
                  style={[
                    styles.tableCell,
                    {
                      fontSize: 8,
                      fontWeight: "medium",
                      borderRight: 0,
                      textAlign: "center",
                      flexWrap: "wrap",
                      width: 60,
                    },
                  ]}
                ></Text>
              </View>

              {/* TOTAL NO. OF PACKAGES */}
              <View
                style={[
                  styles.tableRow,
                  {
                    borderBottom: 0,
                  },
                ]}
              >
                <Text
                  style={[
                    {
                      paddingLeft: 5,
                      flex: 4,
                    },
                  ]}
                ></Text>

                <Text
                  style={[
                    {
                      fontSize: 7,
                      flex: 9,
                      paddingLeft: 29,
                      paddingTop: 2,
                      borderRight: 1,
                      borderLeft: 1,
                      borderBottom: 1,
                    },
                  ]}
                >
                  TOTAL NO. OF PACKAGES
                </Text>

                <Text
                  style={[
                    {
                      fontSize: 7,
                      flex: 9,
                      paddingTop: 2,
                      textAlign: "center",
                      borderRight: 1,
                      borderBottom: 1,
                    },
                  ]}
                >
                  {salesInvoiceData?.no_of_bags
                    ? `${salesInvoiceData?.no_of_bags} BAGS`
                    : "-"}
                </Text>

                <Text
                  style={[
                    styles.tableCell,
                    {
                      borderRight: 1,
                      flexWrap: "wrap",
                      width: 60,
                    },
                  ]}
                ></Text>

                <Text
                  style={[
                    styles.tableCell,
                    {
                      borderRight: 1,
                      flexWrap: "wrap",
                      width: 60,
                    },
                  ]}
                ></Text>

                <Text
                  style={[
                    styles.tableCell,
                    {
                      fontSize: 8,
                      fontWeight: "medium",
                      borderRight: 0,
                      textAlign: "center",
                      flexWrap: "wrap",
                      width: 60,
                    },
                  ]}
                ></Text>
              </View>

              {/* NET WEIGHT (MTS) */}
              <View
                style={[
                  styles.tableRow,
                  {
                    borderBottom: 0,
                  },
                ]}
              >
                <Text
                  style={[
                    {
                      paddingLeft: 5,
                      flex: 4,
                    },
                  ]}
                ></Text>

                <Text
                  style={[
                    {
                      fontSize: 7,
                      flex: 9,
                      paddingLeft: 29,
                      paddingTop: 2,
                      borderRight: 1,
                      borderLeft: 1,
                      borderBottom: 1,
                    },
                  ]}
                >
                  NET WEIGHT (MTS)
                </Text>

                <Text
                  style={[
                    {
                      fontSize: 7,
                      flex: 9,
                      paddingTop: 2,
                      textAlign: "center",
                      borderRight: 1,
                      borderBottom: 1,
                    },
                  ]}
                >
                  {salesInvoiceData?.total_net_weight
                    ? formatAmount(salesInvoiceData?.total_net_weight)
                    : "-"}
                </Text>

                <Text
                  style={[
                    styles.tableCell,
                    {
                      borderRight: 1,
                      borderBottom: 1,
                      flexWrap: "wrap",
                      width: 60,
                    },
                  ]}
                ></Text>

                <Text
                  style={[
                    styles.tableCell,
                    {
                      borderRight: 1,
                      borderBottom: 1,
                      flexWrap: "wrap",
                      width: 60,
                    },
                  ]}
                ></Text>

                <Text
                  style={[
                    styles.tableCell,
                    {
                      fontSize: 8,
                      fontWeight: "medium",
                      borderRight: 0,
                      borderBottom: 1,
                      textAlign: "center",
                      flexWrap: "wrap",
                      width: 60,
                    },
                  ]}
                ></Text>
              </View>

              {/* GROSS WEIGHT (MTS) */}
              <View
                style={[
                  styles.tableRow,
                  {
                    borderBottom: 0,
                  },
                ]}
              >
                <Text
                  style={[
                    {
                      paddingLeft: 5,
                      flex: 4,
                      borderBottom: 1,
                    },
                  ]}
                ></Text>

                <Text
                  style={[
                    {
                      fontSize: 7,
                      flex: 9,
                      paddingLeft: 29,
                      paddingTop: 2,
                      borderRight: 1,
                      borderLeft: 1,
                      borderBottom: 1,
                    },
                  ]}
                >
                  GROSS WEIGHT (MTS)
                </Text>

                <Text
                  style={[
                    {
                      fontSize: 7,
                      flex: 9,
                      paddingTop: 2,
                      textAlign: "center",
                      borderRight: 1,
                      borderBottom: 1,
                    },
                  ]}
                >
                  {salesInvoiceData?.total_gross_weight
                    ? formatAmount(salesInvoiceData?.total_gross_weight)
                    : "-"}
                </Text>

                <Text
                  style={[
                    {
                      borderRight: 1,
                      fontSize: 8,
                      paddingTop: 1,
                      textAlign: "center",
                      borderBottom: 1,
                      flexWrap: "wrap",
                      width: 120,
                    },
                  ]}
                >
                  TOTAL CIF VALUE (IN USD)
                </Text>

                <Text
                  style={[
                    {
                      fontSize: 8,
                      paddingTop: 1,
                      fontWeight: "medium",
                      borderRight: 0,
                      borderBottom: 1,
                      textAlign: "center",
                      flexWrap: "wrap",
                      width: 60,
                    },
                  ]}
                >
                  {formatAmount(salesInvoiceData?.total_amount)}
                </Text>
              </View>
            </View>

            {/* BL NO */}
            <View style={[styles.tableContainer]}>
              <Text
                style={[
                  styles.regularFontSize,
                  styles.mediumFontSize,
                  styles.boldFont,
                  {
                    textAlign: "center",
                    fontSize: 10,
                    height: 12,
                  },
                ]}
              >
                {salesInvoiceData?.bl_no && (
                  <>BL NO. {stringToUpperCase(salesInvoiceData?.bl_no)}</>
                )}
              </Text>
            </View>

            {/* CONTAINER DETAILS */}
            {salesInvoiceData?.containers?.length && (
              <View>
                {/* TABLE HEADER */}
                <View
                  style={[
                    styles.tableRow,
                    {
                      borderBottom: 1,
                      flexDirection: "row",
                    },
                  ]}
                >
                  <Text
                    style={[
                      {
                        fontSize: 7,
                        padding: 2,
                        width: 40,
                        borderRight: 1,
                        textAlign: "center",
                      },
                    ]}
                  >
                    SR
                  </Text>
                  <Text
                    style={[
                      {
                        fontSize: 7,
                        padding: 2,
                        width: 90,
                        borderRight: 1,
                        textAlign: "center",
                      },
                    ]}
                  >
                    CONTAINER NO.
                  </Text>
                  <Text
                    style={[
                      {
                        fontSize: 7,
                        padding: 2,
                        width: 60,
                        borderRight: 1,
                        textAlign: "center",
                      },
                    ]}
                  >
                    SIZE
                  </Text>
                  <Text
                    style={[
                      {
                        fontSize: 7,
                        padding: 2,
                        width: 90,
                        borderRight: 1,
                        textAlign: "center",
                      },
                    ]}
                  >
                    LINE SEAL NO.
                  </Text>
                  <Text
                    style={[
                      {
                        fontSize: 7,
                        padding: 2,
                        width: 110,
                        borderRight: 1,
                        textAlign: "center",
                      },
                    ]}
                  >
                    SELF SEAL NO.
                  </Text>
                  <Text
                    style={[
                      {
                        fontSize: 7,
                        padding: 2,
                        width: 90,
                        borderRight: 1,
                        textAlign: "center",
                      },
                    ]}
                  >
                    STUFFING PKGS
                  </Text>
                  <Text
                    style={[
                      {
                        fontSize: 7,
                        padding: 2,
                        width: 70,
                        borderRight: 1,
                        textAlign: "center",
                      },
                    ]}
                  >
                    NWT (KGS)
                  </Text>
                  <Text
                    style={[
                      {
                        fontSize: 7,
                        padding: 2,
                        width: 70,
                        borderRight: 0,
                        textAlign: "center",
                      },
                    ]}
                  >
                    GWT (KGS)
                  </Text>
                </View>

                {salesInvoiceData?.containers?.map(
                  (row: any, index: number) => {
                    return (
                      <View
                        key={index}
                        style={[
                          styles.tableRow,
                          {
                            borderBottom: 1,
                            flexDirection: "row",
                          },
                        ]}
                      >
                        <Text
                          style={[
                            {
                              fontSize: 7,
                              padding: 2,
                              width: 40,
                              borderRight: 1,
                              textAlign: "center",
                            },
                          ]}
                        >
                          {index + 1}
                        </Text>
                        <Text
                          style={[
                            {
                              fontSize: 7,
                              padding: 2,
                              width: 90,
                              borderRight: 1,
                              textAlign: "center",
                            },
                          ]}
                        >
                          {stringToUpperCase(row?.container_no)}
                        </Text>
                        <Text
                          style={[
                            {
                              fontSize: 7,
                              padding: 2,
                              width: 60,
                              borderRight: 1,
                              textAlign: "center",
                            },
                          ]}
                        >
                          {stringToUpperCase(row?.size)}
                        </Text>
                        <Text
                          style={[
                            {
                              fontSize: 7,
                              padding: 2,
                              width: 90,
                              borderRight: 1,
                              textAlign: "center",
                            },
                          ]}
                        >
                          {stringToUpperCase(row?.line_seal_no)}
                        </Text>
                        <Text
                          style={[
                            {
                              fontSize: 7,
                              padding: 2,
                              width: 110,
                              borderRight: 1,
                              textAlign: "center",
                            },
                          ]}
                        >
                          {stringToUpperCase(row?.self_seal?.seal_no)}
                        </Text>
                        <Text
                          style={[
                            {
                              fontSize: 7,
                              padding: 2,
                              width: 90,
                              borderRight: 1,
                              textAlign: "center",
                            },
                          ]}
                        >
                          {row?.no_of_bags} BAGS
                        </Text>
                        <Text
                          style={[
                            {
                              fontSize: 7,
                              padding: 2,
                              width: 70,
                              borderRight: 1,
                              textAlign: "center",
                            },
                          ]}
                        >
                          {formatAmount(row.total_net_weight)}
                        </Text>
                        <Text
                          style={[
                            {
                              fontSize: 7,
                              padding: 2,
                              width: 70,
                              borderRight: 0,
                              textAlign: "center",
                            },
                          ]}
                        >
                          {formatAmount(row.total_gross_weight)}
                        </Text>
                      </View>
                    );
                  }
                )}
              </View>
            )}

            {/* FOOTER */}
            <View style={styles.sideBySideContainer}>
              <View
                style={[
                  {
                    borderBottomWidth: 0,
                    width: "70%",
                    textAlign: "left",
                  },
                ]}
              >
                <View style={{ padding: 2 }}>
                  <Text style={[styles.regularFontSize]}>
                    TOTAL VALUE (IN WORDS) :-
                  </Text>
                  <View>
                    <Text style={[styles.regularFontSize, styles.boldFont]}>
                      {convertAmountToWords(
                        Number(salesInvoiceData.total_amount),
                        CURRENCY.USD.NAME
                      )}
                    </Text>
                  </View>
                  <View style={{ paddingTop: 5 }}>
                    <View>
                      <Text style={[styles.regularFontSize, styles.boldFont]}>
                        Declaration:-
                      </Text>
                    </View>
                    <View>
                      <Text style={[styles.regularFontSize]}>
                        We declare that this Invoice shows the actual price of
                        the goods described and that all particulars are true
                        and correct.
                      </Text>
                    </View>
                  </View>
                </View>
              </View>
              <View
                style={[
                  {
                    width: "30%",
                    display: "flex",
                    alignItems: "flex-end",
                  },
                ]}
              >
                {/* <Image
                  src="/img/logo/ee-main.png"
                  style={{ height: "60px", width: "60px" }}
                /> */}
              </View>
            </View>
          </View>
        </View>
      </Page>
    </Document>
  );
};

export default CommercialInvoice;
