import * as Yup from "yup";

export interface Product {
  id: string;
  name: string;
  description: string;
}

export interface PurchaseItem {
  product_id: string;
  price: number;
  quantity: number;
  unit_id: string;
  no_of_bags: number;
  sub_total: number;
  gst_percentage: string;
  cgst: number;
  sgst: number;
  gst_amount: number;
  total: number;
}

export const initialPurchaseItem: PurchaseItem = {
  product_id: "",
  price: 0,
  quantity: 0,
  no_of_bags: 0,
  unit_id: "",
  sub_total: 0,
  gst_percentage: "",
  cgst: 0,
  sgst: 0,
  gst_amount: 0,
  total: 0,
};

export const purchaseInitialValues = {
  invoice_no: "",
  supplier_id: "",
  purchase_date: null,
  purchase_items: [initialPurchaseItem],
  sub_total: 0,
  cgst: 0,
  sgst: 0,
  gst_amount: 0,
  round_of: 0,
  total: 0,
  terms_of_payment: "",
  packing_details: "",
  terms_and_condition: "",
};

export const purchaseValidationSchema = Yup.object().shape({
  invoice_no: Yup.string().required("Invoice no is required"),
  supplier_id: Yup.string().required("Supplier is required"),
  purchase_date: Yup.date().required("Purchase date is required"),
  purchase_items: Yup.array()
    .of(
      Yup.object().shape({
        product_id: Yup.string().required("Product is required"),
        price: Yup.number()
          .required("Price is required")
          .test("price-required", "Price is required", function (value: any) {
            return !(value <= 0);
          }),
        quantity: Yup.number()
          .required("Quantity is required")
          .test(
            "quantity-required",
            "Quantity is required",
            function (value: any) {
              return !(value <= 0);
            }
          ),
        unit_id: Yup.string().required("Unit is required"),
         no_of_bags: Yup.string()
            .nullable()
            .test(
              "is-valid-decimal",
              "No. of bags must be a positive value",
              function (value: any) {
                return !(value < 0);
              }
            ),
        sub_total: Yup.number().required("Sub total is required"),
        gst_percentage: Yup.string()
          .required("GST percentage is required")
          .test(
            "gst-percentage-required",
            "GST percentage is required",
            function (value: any) {
              return !(value < 0);
            }
          ),
        cgst: Yup.number().required("Cgst is required"),
        sgst: Yup.number().required("Sgst is required"),
        gst_amount: Yup.number().required("GST amount is required"),
        total: Yup.number().required("Total is required"),
      })
    )
    .min(1, "At least one product is required"),
  sub_total: Yup.number().required("Sub total is required"),
  cgst: Yup.number().required("Cgst is required"),
  sgst: Yup.number().required("Sgst is required"),
  gst_amount: Yup.number().required("GST amount is required"),
  total: Yup.number().required("Total is required"),
});
