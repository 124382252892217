import { GET, POST, PUT } from "./_config";

export const getSealNumberListApi = async (query: string) =>
  await GET(`/seal-number?${query || ""}`, true);

export const getAllSealNumberNameListApi = async (query?: string) =>
  await GET(`/seal-number/list?${query || ""}`, true);

export const createSealNumberApi = async (body: any) =>
  await POST(`/seal-number`, body, true);

export const getSealNumberByIdApi = async (sealNumberId: string) =>
  await GET(`/seal-number/id/${sealNumberId}`, true);

export const updateSealNumberApi = async (sealNumberId: string, body: any) =>
  await PUT(`/seal-number?id=${sealNumberId}`, body, true);

const SealNumberService = {
  getSealNumberListApi,
  getAllSealNumberNameListApi,
  createSealNumberApi,
  getSealNumberByIdApi,
  updateSealNumberApi,
};

export default SealNumberService;
