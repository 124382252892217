import * as Yup from "yup";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { createMyBankApi } from "../../services/_myBank";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { errorToast, successToast } from "../../components/Toast";

const CreateMyBank = () => {
  const navigate = useNavigate();
  const [submitLoader, setSubmitLoader] = useState<boolean>(false);
  const createMyBankInitialValues = {
    branch_name: "",
    bank_name: "",
    account_number: "",
    ifsc_code: "",
    swift_code: "",
    address: "",
    ad_code:"",
  };

  const createMyBankValidationSchema = Yup.object().shape({
    branch_name: Yup.string().required("Branch Name is required"),
    bank_name: Yup.string().required("Bank Name is required"),
    account_number: Yup.string().required("Account no is required"),
    ifsc_code: Yup.string().required("IFSC code is required"),
    swift_code: Yup.string().required("Swift code is required"),
  });

  const handleSubmit = async (values: any, { resetForm }: any) => {
    setSubmitLoader(true);
    const result: any = await createMyBankApi(values);
    if (result.isSuccess) {
      setSubmitLoader(false);
      resetForm({ values: createMyBankInitialValues });
      successToast(result.message);
      navigate("/my-bank-list");
    } else {
      setSubmitLoader(false);
      errorToast(result.message);
    }
    return;
  };

  return (
    <>
      <div className="card">
        <div className="card-header pb-0">
          <div>
            <h5>Create My Bank</h5>
          </div>
        </div>
        <div className="card-body">
          <Formik
            initialValues={createMyBankInitialValues}
            validationSchema={createMyBankValidationSchema}
            onSubmit={handleSubmit}
          >
            {({ handleChange }) => (
              <Form className="dt_adv_search">
                <div className="row">
                  <div className="col-12">
                    <div className="row g-3">
                      <div className="col-12 col-sm-6 col-lg-4">
                        <label
                          htmlFor="bank_name"
                          className="form-label"
                          style={{ fontSize: "0.9375rem" }}
                        >
                          Bank Name: <span className="text-danger">*</span>
                        </label>
                        <Field
                          id="bank_name"
                          name="bank_name"
                          type="text"
                          className="form-control"
                          placeholder="Enter Bank Name"
                          onChange={handleChange}
                        />
                        <ErrorMessage
                          name="bank_name"
                          className="text-danger"
                          component="div"
                        />
                      </div>
                      <div className="col-12 col-sm-6 col-lg-4">
                        <label
                          htmlFor="account_number"
                          className="form-label"
                          style={{ fontSize: "0.9375rem" }}
                        >
                          Account No: <span className="text-danger">*</span>
                        </label>
                        <Field
                          id="account_number"
                          name="account_number"
                          type="text"
                          className="form-control"
                          placeholder="Enter Account No"
                          onChange={handleChange}
                        />
                        <ErrorMessage
                          name="account_number"
                          className="text-danger"
                          component="div"
                        />
                      </div>
                      <div className="col-12 col-sm-6 col-lg-4">
                        <label
                          htmlFor="ifsc_code"
                          className="form-label"
                          style={{ fontSize: "0.9375rem" }}
                        >
                          IFSC Code : <span className="text-danger">*</span>
                        </label>
                        <Field
                          id="ifsc_code"
                          name="ifsc_code"
                          type="text"
                          className="form-control"
                          placeholder="Enter IFSC Code"
                          onChange={handleChange}
                        />
                        <ErrorMessage
                          name="ifsc_code"
                          className="text-danger"
                          component="div"
                        />
                      </div>
                      <div className="col-12 col-sm-6 col-lg-4">
                        <label
                          htmlFor="branch_name"
                          className="form-label"
                          style={{ fontSize: "0.9375rem" }}
                        >
                          Branch Name: <span className="text-danger">*</span>
                        </label>
                        <Field
                          id="branch_name"
                          name="branch_name"
                          type="text"
                          className="form-control"
                          placeholder="Enter Branch Name"
                          onChange={handleChange}
                        />
                        <ErrorMessage
                          name="branch_name"
                          className="text-danger"
                          component="div"
                        />
                      </div>
                      <div className="col-12 col-sm-6 col-lg-4">
                        <label
                          htmlFor="swift_code"
                          className="form-label"
                          style={{ fontSize: "0.9375rem" }}
                        >
                          Swift Code: <span className="text-danger">*</span>
                        </label>
                        <Field
                          id="swift_code"
                          name="swift_code"
                          type="text"
                          className="form-control"
                          placeholder="Enter Swift Code"
                          onChange={handleChange}
                        />
                        <ErrorMessage
                          name="swift_code"
                          className="text-danger"
                          component="div"
                        />
                      </div>
                      <div className="col-12 col-sm-6 col-lg-4">
                        <label
                          htmlFor="ad_code"
                          className="form-label"
                          style={{ fontSize: "0.9375rem" }}
                        >
                          Ad Code:
                        </label>
                        <Field
                          id="ad_code"
                          name="ad_code"
                          type="text"
                          className="form-control"
                          placeholder="Enter Ad Code"
                          onChange={handleChange}
                        />
                        <ErrorMessage
                          name="ad_code"
                          className="text-danger"
                          component="div"
                        />
                      </div>
                      <div className="col-12">
                        <label
                          htmlFor="address"
                          className="form-label"
                          style={{ fontSize: "0.9375rem" }}
                        >
                          Address:
                        </label>
                        <Field
                          id="address"
                          name="address"
                          type="text"
                          className="form-control"
                          placeholder="Enter Address"
                          onChange={handleChange}
                          as={"textarea"}
                          rows={2}
                        />
                      </div>

                      <div className="col-12">
                        <button
                          type="submit"
                          className="btn btn-primary"
                          disabled={submitLoader}
                        >
                          {submitLoader && (
                            <span
                              className="spinner-border spinner-border-sm me-2"
                              role="status"
                              aria-hidden="true"
                            ></span>
                          )}
                          Submit
                        </button>
                        <Link
                          to={"/my-bank-list"}
                          type="button"
                          className="btn btn-label-github ms-5"
                        >
                          Back
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </>
  );
};
export default CreateMyBank;
