import { ErrorMessage, Field, FormikValues } from "formik";
import { FilePond } from "react-filepond";
import { getFileNameFromUrl } from "../../../../components/Helper";

interface MarkingFieldsProps {
  actionType: string;
  values: FormikValues;
  handleChange: (e: React.ChangeEvent<any>) => void;
  filePondFiles: any;
  setFilePondFiles: (files: any) => void;
  handleFileChange: (fileItems: any, setFieldValue: any) => void;
  setFieldValue: any;
}

const MarkingFields: React.FC<MarkingFieldsProps> = ({
  actionType,
  values,
  handleChange,
  filePondFiles,
  setFilePondFiles,
  handleFileChange,
  setFieldValue,
}) => {
  return (
    <div className="card my-card">
      <div className="card-header my-card-header bg-lighter">
        <div>
          <h6 className="my-card-header-head">Marking Details</h6>
        </div>
      </div>
      <div className="card-body my-card-body">
        <div className="row g-3 m-0">
          <div className="col-12 col-sm-6 col-md-4 col-lg-4">
            <label
              htmlFor="marking"
              className="form-label"
              style={{ fontSize: "0.9375rem" }}
            >
              Marking:
            </label>
            <Field
              id="marking"
              name="marking"
              as={"textarea"}
              rows={3}
              className="form-control"
              placeholder="Enter Marking Details"
              value={values.marking}
              onChange={handleChange}
            />
          </div>
          <div
            className={`col-12 col-sm-6 ${
              actionType === "create" ||
              (actionType === "update" &&
                values?.marking_existing_files?.length <= 0)
                ? "col-md-8 col-lg-8"
                : "col-md-4 col-lg-4"
            }`}
          >
            <label
              htmlFor="marking_files"
              className="form-label"
              style={{ fontSize: "0.9375rem" }}
            >
              Marking Files:
            </label>
            <div className="">
              <div className="file-pond-main-block">
                <FilePond
                  files={filePondFiles}
                  onupdatefiles={(fileItems: any) => {
                    setFilePondFiles(fileItems);
                    handleFileChange(fileItems, setFieldValue);
                  }}
                  allowMultiple
                  name={`marking_files`}
                  labelIdle="Drag & drop files or click to browse"
                />
              </div>
              <ErrorMessage
                name={`marking_files`}
                component="div"
                className="text-danger"
              />
            </div>
          </div>
          {actionType === "update" &&
            values?.marking_existing_files?.length > 0 && (
              <div className="col-12 col-sm-6 col-md-4 col-lg-4">
                <label
                  htmlFor="existing_files"
                  className="form-label"
                  style={{ fontSize: "0.9375rem" }}
                >
                  Uploaded Marking Files:
                </label>
                <div
                  className="file-pond-main-block d-flex justify-content-left"
                  style={{
                    alignItems: "center",
                    padding: "15px",
                  }}
                >
                  <div className="row p-0">
                    {values.marking_existing_files &&
                      values.marking_existing_files.map((file: any) => (
                        <>
                          <div
                            className="col-md mb-md-0"
                            style={{
                              width: "165px",
                              height: "100%",
                              position: "relative",
                            }}
                          >
                            <div className="form-check custom-option custom-option-icon">
                              <label
                                className="form-check-label custom-option-content"
                                onClick={() =>
                                  window.open(
                                    `${process.env.REACT_APP_BACKEND_URL}${file}`,
                                    "_blank"
                                  )
                                }
                              >
                                <span className="custom-option-body">
                                  <small>
                                    {getFileNameFromUrl(
                                      `${process.env.REACT_APP_BACKEND_URL}${file}`
                                    )}
                                  </small>
                                </span>
                              </label>
                            </div>
                            <i
                              className="fa-solid fa-xmark remove_document_file_btn"
                              onClick={() => {
                                const newFiles =
                                  values?.marking_existing_files?.filter(
                                    (x: string) => x !== file
                                  );
                                setFieldValue(
                                  `marking_existing_files`,
                                  newFiles
                                );
                              }}
                            ></i>
                          </div>
                        </>
                      ))}
                  </div>
                </div>
              </div>
            )}
        </div>
      </div>
    </div>
  );
};

export default MarkingFields;
