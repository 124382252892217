import { Link } from "react-router-dom";

const AccessDenied = () => {
  return (
    <div className="container-xxl container-p-y">
      <div className="misc-wrapper">
        <h1
          className="mb-2 mx-2"
          style={{ lineHeight: "6rem", fontSize: "6rem" }}
        >
          403
        </h1>
        <h4 className="mb-2 mx-2">Forbidden! 🔐</h4>
        <p className="mb-6 mx-2">
          You don’t have permission to access this page. Go Home!
        </p>
        <Link to={"/dashboard"} className="btn btn-primary">
          Back to home
        </Link>
      </div>
    </div>
  );
};

export default AccessDenied;
