import * as Yup from "yup";
import React, { useEffect, useState } from "react";
import { errorToast, successToast } from "../Toast";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { BankType } from "../../components/Enums";
import { createBankApi } from "../../services/_bank";
import { getAllBrokerApi } from "../../services/_broker";
import { getAllChaApi } from "../../services/_cha";
import { getAllLogisticApi } from "../../services/_logistic";
import { getSupplierNameListApi } from "../../services/_supplier";
import { getBuyerNameListApi } from "../../services/_buyer";

const createBankInitialValues = {
  type: "",
  relation_id: "",
  bank_name: "",
  account_number: "",
  ifsc_code: "",
  branch_name: "",
  swift_code: "",
  ad_code: "",
  status: "",
  address: "",
};
const createBankValidationSchema = Yup.object().shape({
  type: Yup.string().required("Type is required"),
  relation_id: Yup.string().test(
    "relation-id-required",
    "Name is required",
    function (value) {
      const { type } = this.parent;
      if (type === "exporter") {
        return false;
      }
      return true;
    }
  ),
  bank_name: Yup.string().required("Bank Name is required"),
  account_number: Yup.string().required("Account Number is required"),
  ifsc_code: Yup.string().required("IFSC is required"),
  branch_name: Yup.string().required("Branch Name is required"),
  swift_code: Yup.string().nullable(),
  ad_code: Yup.string().nullable(),
  status: Yup.string().required("Status is required"),
  address: Yup.string().nullable(),
});

interface AddBankTypeModalProps {
  addBankModal: boolean;
  closeAddBankModal: () => void;
  onBankCreated: any;
  type: string;
  selectedBuyerId: string;
}

const AddBankModal: React.FC<AddBankTypeModalProps> = ({
  addBankModal,
  closeAddBankModal,
  onBankCreated,
  type,
  selectedBuyerId,
}) => {
  const [submitLoader, setSubmitLoader] = useState(false);
  const [relationList, setRelationList] = useState<AddBankTypeModalProps[]>([]);

  const handleTypeChange = async (filterType: BankType | string) => {
    if (filterType === BankType.EXPORTER) {
      setRelationList([]);
    }
    if (filterType === BankType.BUYER) {
      const result: any = await getBuyerNameListApi();
      if (result.isSuccess) {
        setRelationList(result?.data);
      }
    }
    if (filterType === BankType.SUPPLIER) {
      const result: any = await getSupplierNameListApi();
      if (result.isSuccess) {
        setRelationList(result?.data);
      }
    }
    if (filterType === BankType.LOGISTIC) {
      const result: any = await getAllLogisticApi();
      if (result.isSuccess) {
        setRelationList(result?.data);
      }
    }
    if (filterType === BankType.CHA) {
      const result: any = await getAllChaApi();
      if (result.isSuccess) {
        setRelationList(result?.data);
      }
    }
    if (filterType === BankType.BROKER) {
      const result: any = await getAllBrokerApi();
      if (result.isSuccess) {
        setRelationList(result?.data);
      }
    }
  };

  useEffect(() => {
    handleTypeChange(BankType.BUYER || BankType.EXPORTER);
  }, []);
  const handleSubmit = async (values: any, { resetForm }: any) => {
    setSubmitLoader(true);
    values.status = values.status === "true" ? true : false;

    try {
      const result: any = await createBankApi(values);
      if (result && result.data) {
        setSubmitLoader(false);
        resetForm({ values: createBankInitialValues });
        successToast(result.message);
        closeAddBankModal();
        onBankCreated(result.data);
      } else {
        setSubmitLoader(false);
        errorToast(result.message);
      }
    } catch (error) {
      setSubmitLoader(false);
      errorToast("An unexpected error occurred.");
    }

    return;
  };

  return (
    <>
      {addBankModal && (
        <>
          <div className="modal-backdrop fade show"></div>
          <div
            className="modal fade show"
            id="addBankModal"
            tabIndex={-3}
            aria-hidden={!addBankModal}
            style={{ display: addBankModal ? "block" : "none" }}
          >
            <div
              className="modal fade show"
              id="addBankModal"
              tabIndex={-3}
              aria-hidden={!addBankModal}
              style={{ display: addBankModal ? "block" : "none" }}
            >
              <div
                className="modal fade show"
                id="addBankModal"
                tabIndex={-3}
                aria-hidden={!addBankModal}
                style={{ display: addBankModal ? "block" : "none" }}
              >
                <div className="modal-dialog modal-lg modal-simple modal-dialog-centered">
                  <div className="modal-content">
                    <div className="modal-body p-4 d-flex align-items-center justify-content-center">
                      <button
                        type="button"
                        className="btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                        onClick={closeAddBankModal}
                      ></button>
                      <div className="w-100">
                        <div className="text-center mb-4">
                          <h4 className="mb-2">Add Bank</h4>
                        </div>

                        <Formik
                          enableReinitialize={true}
                          initialValues={{
                            ...createBankInitialValues,
                            type:
                              type === BankType.EXPORTER
                                ? BankType.EXPORTER
                                : BankType.BUYER,
                            relation_id: selectedBuyerId || "",
                          }}
                          validationSchema={createBankValidationSchema}
                          onSubmit={handleSubmit}
                        >
                          {({ values, handleChange, setFieldValue }) => (
                            <Form className="dt_adv_search">
                              <div className="row">
                                <div className="col-12">
                                  <div className="row g-3">
                                    <div className="col-12 col-sm-6 col-lg-4">
                                      <label
                                        htmlFor="type"
                                        className="form-label"
                                        style={{ fontSize: "0.9375rem" }}
                                      >
                                        Type:{" "}
                                        <span className="text-danger">*</span>
                                      </label>
                                      <select
                                        id="type"
                                        name="type"
                                        className="form-select"
                                        value={values.type}
                                        onChange={(e) => {
                                          setFieldValue("type", e.target.value);
                                          setFieldValue("relation_id", "");
                                          handleTypeChange(e.target.value);
                                        }}
                                      >
                                        <option value="">Select Type</option>
                                        {[
                                          BankType.EXPORTER,
                                          BankType.BUYER,
                                        ].map((item: any) => (
                                          <option value={item} key={item}>
                                            {item}
                                          </option>
                                        ))}
                                      </select>
                                      <ErrorMessage
                                        name="type"
                                        className="text-danger"
                                        component="div"
                                      />
                                    </div>

                                    {values.type &&
                                      values.type !== BankType.EXPORTER && (
                                        <div className="col-12 col-sm-6 col-lg-4">
                                          <label
                                            htmlFor="relation_id"
                                            className="form-label"
                                            style={{ fontSize: "0.9375rem" }}
                                          >
                                            {values.type}:{" "}
                                            <span className="text-danger">
                                              *
                                            </span>
                                          </label>
                                          <select
                                            id="relation_id"
                                            name="relation_id"
                                            className="form-select"
                                            value={
                                              values.relation_id ||
                                              selectedBuyerId ||
                                              ""
                                            }
                                            onChange={(e) => {
                                              setFieldValue(
                                                "relation_id",
                                                e.target.value
                                              );
                                            }}
                                          >
                                            <option value="">
                                              Select Type
                                            </option>
                                            {relationList.length > 0 &&
                                              relationList.map((data: any) => (
                                                <option
                                                  key={data.id}
                                                  value={data.id}
                                                >
                                                  {data.name}
                                                </option>
                                              ))}
                                          </select>
                                          <ErrorMessage
                                            name="relation_id"
                                            className="text-danger"
                                            component="div"
                                          />
                                        </div>
                                      )}

                                    <div className="col-12 col-sm-6 col-lg-4">
                                      <label
                                        htmlFor="bank_name"
                                        className="form-label"
                                        style={{ fontSize: "0.9375rem" }}
                                      >
                                        Bank Name:{" "}
                                        <span className="text-danger">*</span>
                                      </label>
                                      <Field
                                        id="bank_name"
                                        name="bank_name"
                                        type="text"
                                        className="form-control"
                                        placeholder="Enter Bank Name"
                                        value={values.bank_name}
                                      />
                                      <ErrorMessage
                                        name="bank_name"
                                        className="text-danger"
                                        component="div"
                                      />
                                    </div>
                                    <div className="col-12 col-sm-6 col-lg-4">
                                      <label
                                        htmlFor="account_number"
                                        className="form-label"
                                        style={{ fontSize: "0.9375rem" }}
                                      >
                                        Account Number:{" "}
                                        <span className="text-danger">*</span>
                                      </label>
                                      <Field
                                        id="account_number"
                                        name="account_number"
                                        type="text"
                                        className="form-control"
                                        placeholder="Enter Account Name"
                                        value={values.account_number}
                                        onChange={handleChange}
                                      />
                                      <ErrorMessage
                                        name="account_number"
                                        className="text-danger"
                                        component="div"
                                      />
                                    </div>
                                    <div className="col-12 col-sm-6 col-lg-4">
                                      <label
                                        htmlFor="ifsc_code"
                                        className="form-label"
                                        style={{ fontSize: "0.9375rem" }}
                                      >
                                        IFSC Code:{" "}
                                        <span className="text-danger">*</span>
                                      </label>
                                      <Field
                                        id="ifsc_code"
                                        name="ifsc_code"
                                        type="text"
                                        className="form-control"
                                        placeholder="Enter IFSC Code"
                                        value={values.ifsc_code}
                                        onChange={handleChange}
                                      />
                                      <ErrorMessage
                                        name="ifsc_code"
                                        className="text-danger"
                                        component="div"
                                      />
                                    </div>
                                    <div className="col-12 col-sm-6 col-lg-4">
                                      <label
                                        htmlFor="branch_name"
                                        className="form-label"
                                        style={{ fontSize: "0.9375rem" }}
                                      >
                                        Branch Name:{" "}
                                        <span className="text-danger">*</span>
                                      </label>
                                      <Field
                                        id="branch_name"
                                        name="branch_name"
                                        type="text"
                                        className="form-control"
                                        placeholder="Enter Branch Name"
                                        value={values.branch_name}
                                        onChange={handleChange}
                                      />
                                      <ErrorMessage
                                        name="branch_name"
                                        className="text-danger"
                                        component="div"
                                      />
                                    </div>
                                    <div className="col-12 col-sm-6 col-lg-4">
                                      <label
                                        htmlFor="swift_code"
                                        className="form-label"
                                        style={{ fontSize: "0.9375rem" }}
                                      >
                                        Swift Code:
                                      </label>
                                      <Field
                                        id="swift_code"
                                        name="swift_code"
                                        type="text"
                                        className="form-control"
                                        placeholder="Enter Swift Code"
                                        value={values.swift_code}
                                        onChange={handleChange}
                                      />
                                      <ErrorMessage
                                        name="swift_code"
                                        className="text-danger"
                                        component="div"
                                      />
                                    </div>
                                    <div className="col-12 col-sm-6 col-lg-4">
                                      <label
                                        htmlFor="ad_code"
                                        className="form-label"
                                        style={{ fontSize: "0.9375rem" }}
                                      >
                                        AD Code:
                                      </label>
                                      <Field
                                        id="ad_code"
                                        name="ad_code"
                                        type="text"
                                        className="form-control"
                                        placeholder="Enter AD Code"
                                        value={values.ad_code}
                                        onChange={handleChange}
                                      />
                                      <ErrorMessage
                                        name="ad_code"
                                        className="text-danger"
                                        component="div"
                                      />
                                    </div>
                                    <div className="col-12 col-sm-6 col-lg-4">
                                      <label
                                        htmlFor="status"
                                        className="form-label"
                                        style={{ fontSize: "0.9375rem" }}
                                      >
                                        Status:{" "}
                                        <span className="text-danger">*</span>
                                      </label>
                                      <select
                                        id="status"
                                        name="status"
                                        className="form-select"
                                        value={values.status}
                                        onChange={handleChange}
                                      >
                                        <option value="">Select Status</option>
                                        <option value="true">Active</option>
                                        <option value="false">Inactive</option>
                                      </select>
                                      <ErrorMessage
                                        name="status"
                                        className="text-danger"
                                        component="div"
                                      />
                                    </div>
                                    <div className="col-12">
                                      <label
                                        htmlFor="address"
                                        className="form-label"
                                        style={{ fontSize: "0.9375rem" }}
                                      >
                                        Address:
                                      </label>
                                      <Field
                                        id="address"
                                        name="address"
                                        type="text"
                                        className="form-control"
                                        placeholder="Enter Address"
                                        onChange={handleChange}
                                        as={"textarea"}
                                        rows={2}
                                      />
                                    </div>
                                    <div className="text-center mt-4">
                                      <button
                                        type="submit"
                                        className="btn btn-primary me-3"
                                        disabled={submitLoader}
                                      >
                                        {submitLoader && (
                                          <span
                                            className="spinner-border spinner-border-sm me-2"
                                            role="status"
                                            aria-hidden="true"
                                          ></span>
                                        )}
                                        Add Bank
                                      </button>
                                      <button
                                        type="button"
                                        className="btn btn-label-secondary"
                                        onClick={closeAddBankModal}
                                      >
                                        Cancel
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </Form>
                          )}
                        </Formik>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default AddBankModal;
