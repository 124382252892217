import { Document, Page, StyleSheet, Text, View } from "@react-pdf/renderer";
import { formatDate, stringToUpperCase } from "../../../../components/Helper";

export const styles = StyleSheet.create({
  container: {
    width: 345,
    paddingLeft: 5,
    borderRight: 1,
  },
  boldText: {
    fontWeight: "bold",
    fontSize: 10,
  },
  smallFontSize: {
    fontSize: 6,
  },
  mediumFontSize: {
    fontSize: 8,
  },
  regularFontSize: {
    fontSize: 9,
  },
  largeFontSize: {
    fontSize: 10,
  },
  checkbox: {
    width: 10,
    height: 10,
    borderWidth: 1,
    borderColor: "black",
    marginRight: 1,
  },
  checkMark: {
    fontSize: 18,
    color: "black",
  },
  page: {
    paddingHorizontal: 30,
    paddingVertical: 15,
    fontFamily: "HostGrotesk",
    backgroundColor: "white",
  },
  fullpage: {
    margin: 7,
    borderStyle: "solid",
    borderWidth: 1,
    borderColor: "#000000",
  },

  sideBySideContainer: { flexDirection: "row" },

  halfWidthRightSection: {
    width: "50%",
    borderRightWidth: 1,
    textAlign: "left",
  },
  halfWidthLeftSection: {
    marginLeft: 5,
    width: "50%",
  },
  tableContainer: {
    borderBottomWidth: 1,
    borderBottomColor: "black",
  },
  tableRow: {
    flexDirection: "row",
    flexGrow: 1,
    alignItems: "stretch",
  },
});

const CertificateOfOrigin = ({
  salesInvoiceData,
  settingsData,
}: {
  salesInvoiceData: any;
  settingsData: any;
}) => {
  const checkboxData = [
    { label: "Third Country", isChecked: false },
    { label: "Invoicing", isChecked: false },
    { label: "Exhibition", isChecked: false },
    { label: "ISSUED RETROSPECTIVELY", isChecked: true },
    { label: "Cumulation", isChecked: false },
  ];

  return (
    <Document>
      {/* Page1 */}
      <Page style={styles.page} size="A4">
        <Text
          style={[styles.boldText, { textAlign: "center", marginBottom: -8 }]}
        >
          {" "}
          (ELECTRONIC)
        </Text>
        <View style={styles.fullpage}>
          <View
            style={[
              styles.sideBySideContainer,
              { height: "200px", borderBottom: 1 },
            ]}
          >
            <View style={[styles.halfWidthRightSection, {}]}>
              <Text
                style={[
                  styles.regularFontSize,
                  {
                    justifyContent: "space-between",
                    paddingLeft: 5,
                    paddingRight: 5,
                    paddingTop: 5,
                  },
                ]}
              >
                1. Goods consigned from (Exporter's business name, address,
                country):
              </Text>
              <Text style={[styles.largeFontSize, { top: 5, paddingLeft: 5 }]}>
                {stringToUpperCase(settingsData?.company_name)}
              </Text>
              <Text
                style={[
                  styles.largeFontSize,
                  {
                    justifyContent: "space-between",
                    top: 5,
                    paddingLeft: 5,
                    paddingBottom: 15,
                  },
                ]}
              >
                {stringToUpperCase(settingsData?.company_address)}
              </Text>
              <View
                style={{
                  paddingTop: 5,
                  borderTop: 1,
                  paddingLeft: 5,
                  paddingRight: 5,
                }}
              >
                <Text
                  style={[
                    styles.regularFontSize,
                    { justifyContent: "space-between" },
                  ]}
                >
                  2. Goods consigned to (Consignee's name, address, country):
                </Text>
                <Text style={[styles.largeFontSize, { top: 5 }]}>
                  {stringToUpperCase(salesInvoiceData?.buyer?.name)}
                </Text>
                <Text
                  style={[
                    styles.largeFontSize,
                    { justifyContent: "space-between", top: 5 },
                  ]}
                >
                  {stringToUpperCase(
                    salesInvoiceData?.buyer?.address.slice(0, 13)
                  )}
                  ,{""}
                  {stringToUpperCase(
                    salesInvoiceData?.buyer?.address.slice(13, 47)
                  )}
                  ,{""}
                  {stringToUpperCase(
                    salesInvoiceData?.buyer?.address.slice(40)
                  )}
                  ,{""}
                  {stringToUpperCase(salesInvoiceData?.buyer?.city)},{""}
                  {stringToUpperCase(salesInvoiceData?.buyer?.state)},{""}
                  {stringToUpperCase(salesInvoiceData?.buyer?.country)}
                  {""}
                </Text>
              </View>
            </View>
            <View style={[styles.halfWidthLeftSection]}>
              <Text style={[styles.largeFontSize, { top: 5 }]}>
                Reference No. :
              </Text>
              <Text
                style={[
                  styles.boldText,
                  { paddingTop: 15, textAlign: "center" },
                ]}
              >
                CERTIFICATE OF ORIGIN
              </Text>
              <Text
                style={[
                  styles.largeFontSize,
                  {
                    justifyContent: "space-between",
                    top: 5,
                    textAlign: "center",
                  },
                ]}
              >
                (NON PREFERENTIAL)
              </Text>
              <Text
                style={[
                  styles.largeFontSize,
                  {
                    justifyContent: "space-between",
                    top: 5,
                    textAlign: "center",
                  },
                ]}
              >
                (Combined declaration and certificate)
              </Text>
              <Text
                style={[
                  styles.largeFontSize,
                  {
                    textAlign: "center",
                    top: 5,
                  },
                ]}
              >
                Issued in{" "}
                <Text
                  style={[
                    styles.boldText,
                    {
                      textDecoration: "underline",
                      textDecorationStyle: "dotted",
                    },
                  ]}
                >
                  INDIA
                </Text>
              </Text>
              <Text
                style={[
                  styles.largeFontSize,
                  {
                    justifyContent: "space-between",
                    top: 55,
                    textAlign: "left",
                  },
                ]}
              >
                {/* logo */}
              </Text>
              <Text
                style={[
                  styles.largeFontSize,
                  {
                    justifyContent: "space-between",
                    top: 5,
                    textAlign: "left",
                    marginLeft: 55,
                  },
                ]}
              >
                THE GANDHIDHAM CHAMBER OF COMMERCE AND INDUSTRY
              </Text>
              <Text
                style={[
                  styles.largeFontSize,
                  {
                    justifyContent: "space-between",
                    top: 5,
                    textAlign: "left",
                    marginLeft: 55,
                  },
                ]}
              >
                Plot No.71, Sector - 8, Gandhidham - Kachchh, KACHCHH
              </Text>
              <Text
                style={[
                  styles.largeFontSize,
                  {
                    justifyContent: "space-between",
                    top: 5,
                    textAlign: "left",
                    marginLeft: 55,
                  },
                ]}
              >
                E-mail: info@gccikandla.com
              </Text>
              <Text
                style={[
                  styles.largeFontSize,
                  {
                    justifyContent: "space-between",
                    top: 5,
                    textAlign: "left",
                    marginLeft: 55,
                  },
                ]}
              >
                Office Phone: 02836-220977
              </Text>
            </View>
          </View>

          <View
            style={[
              styles.sideBySideContainer,
              { height: 50, borderBottom: 1 },
            ]}
          >
            <View style={[styles.halfWidthRightSection]}>
              <Text
                style={[
                  styles.regularFontSize,
                  { paddingLeft: 5, paddingTop: 10 },
                ]}
              >
                3. Means of transport and route (as far as known):
              </Text>
              <Text style={[styles.largeFontSize, { paddingLeft: 5 }]}>
                BY SEA{" "}
                {salesInvoiceData?.origin_port?.name &&
                salesInvoiceData?.origin_port?.country
                  ? `${stringToUpperCase(
                      salesInvoiceData?.origin_port?.name
                    )}, ${stringToUpperCase(
                      salesInvoiceData?.origin_port?.country
                    )}`
                  : "-"}{" "}
                TO{" "}
                {salesInvoiceData?.destination_port?.name &&
                salesInvoiceData?.destination_port?.country
                  ? `${stringToUpperCase(
                      salesInvoiceData?.destination_port?.name
                    )}, ${stringToUpperCase(
                      salesInvoiceData?.destination_port?.country
                    )}`
                  : "-"}{" "}
              </Text>
            </View>
            <View style={[styles.halfWidthLeftSection]}>
              <Text
                style={[
                  styles.largeFontSize,
                  { paddingLeft: 3, paddingTop: 10 },
                ]}
              >
                4. For Official Use
              </Text>
            </View>
          </View>

          {/* table */}

          <View>
            <View
              style={[
                styles.tableContainer,
                styles.mediumFontSize,
                {
                  textAlign: "left",
                  height: 50,
                  borderTop: 1,
                  marginTop: 5,
                  marginLeft: 5,
                  marginRight: 5,
                  borderRight: 1,
                  borderLeft: 1,
                },
              ]}
            >
              <View
                style={[
                  styles.tableRow,
                  {
                    paddingLeft: 5,
                    paddingRight: 5,
                    flexDirection: "row",
                  },
                ]}
              >
                <Text
                  style={[
                    {
                      paddingTop: 2,
                      borderRightWidth: 1,
                      width: "20%",
                      justifyContent: "space-between",
                    },
                  ]}
                >
                  5 . Item number
                </Text>
                <Text
                  style={[
                    {
                      borderRightWidth: 1,
                      paddingRight: 5,
                      paddingTop: 2,
                      paddingLeft: 5,
                      width: "20%",
                      justifyContent: "space-between",
                    },
                  ]}
                >
                  6. Marks and numbers of packages
                </Text>
                <Text
                  style={[
                    {
                      borderRightWidth: 1,
                      paddingTop: 2,
                      paddingRight: 5,
                      paddingLeft: 5,
                      width: "45%",
                      justifyContent: "space-between",
                    },
                  ]}
                >
                  7. Number and kind of packages, description of goods
                </Text>
                <Text
                  style={[
                    {
                      borderRightWidth: 1,
                      paddingTop: 2,
                      paddingLeft: 5,
                      paddingRight: 5,
                      width: "20%",
                      justifyContent: "space-between",
                    },
                  ]}
                >
                  8. Origin criterion (see notes overleaf)
                </Text>
                <Text
                  style={[
                    {
                      borderRightWidth: 1,
                      paddingLeft: 5,
                      paddingTop: 2,
                      paddingRight: 5,
                      width: "20%",
                      justifyContent: "space-between",
                    },
                  ]}
                >
                  9. Gross weight or other quantity
                </Text>
                <Text
                  style={[
                    {
                      paddingTop: 2,
                      paddingLeft: 5,
                      paddingRight: 5,
                      width: "20%",
                      justifyContent: "space-between",
                    },
                  ]}
                >
                  10. Number and date of invoices
                </Text>
              </View>
            </View>
            {/* table data */}
            <View
              style={[
                styles.tableContainer,
                styles.mediumFontSize,
                {
                  textAlign: "left",
                  height: 50,
                  marginLeft: 5,
                  marginRight: 5,
                  borderRight: 1,
                  borderLeft: 1,
                },
              ]}
            >
              <View
                style={[
                  styles.tableRow,
                  {
                    paddingLeft: 5,
                    paddingRight: 5,
                    flexDirection: "row",
                  },
                ]}
              >
                <Text
                  style={[
                    {
                      borderRightWidth: 1,
                      paddingTop: 2,
                      width: "20%",
                      justifyContent: "space-between",
                    },
                  ]}
                >
                  1
                </Text>
                <Text
                  style={[
                    {
                      borderRightWidth: 1,
                      paddingRight: 5,
                      paddingTop: 2,
                      paddingLeft: 5,
                      width: "20%",
                      justifyContent: "space-between",
                    },
                  ]}
                >
                  {salesInvoiceData?.no_of_bags}
                </Text>
                <Text
                  style={[
                    {
                      borderRightWidth: 1,
                      paddingTop: 2,
                      paddingRight: 5,
                      paddingLeft: 5,
                      width: "45%",
                      justifyContent: "space-between",
                    },
                  ]}
                >
                  TOTAL {salesInvoiceData?.no_of_bags} BAGS,{" "}
                  {stringToUpperCase(salesInvoiceData?.product?.name)} (PACKING:
                  IN{""}
                  {stringToUpperCase(
                    salesInvoiceData?.packing_type?.name
                  )} OF {salesInvoiceData?.packing_net_weight}{" "}
                  {stringToUpperCase(salesInvoiceData?.net_weight_unit?.name)}{" "}
                  NET EACH) (GROSS WT: {salesInvoiceData?.packing_gross_weight}{" "}
                  {salesInvoiceData?.gross_weight_unit?.name}), HS Code:
                  {salesInvoiceData?.product?.hsn_no}
                </Text>
                <Text
                  style={[
                    {
                      borderRightWidth: 1,
                      paddingLeft: 5,
                      paddingTop: 2,
                      paddingRight: 5,
                      width: "20%",
                      justifyContent: "space-between",
                    },
                  ]}
                >
                  "INDIAN ORIGIN"
                </Text>
                <Text
                  style={[
                    {
                      borderRightWidth: 1,
                      paddingLeft: 5,
                      paddingTop: 2,
                      paddingRight: 5,
                      width: "20%",
                      justifyContent: "space-between",
                    },
                  ]}
                >
                  {salesInvoiceData?.total_gross_weight}
                </Text>
                <Text
                  style={[
                    {
                      paddingLeft: 5,
                      paddingTop: 2,
                      paddingRight: 5,
                      width: "20%",
                      justifyContent: "space-between",
                    },
                  ]}
                >
                  {stringToUpperCase(salesInvoiceData?.invoice_no)}
                  {formatDate(salesInvoiceData?.invoice_date)}
                </Text>
              </View>
            </View>
          </View>

          {/* 11 | 12 */}

          <View
            style={[
              styles.sideBySideContainer,
              { marginTop: 45, borderTop: 1, height: 200, borderBottom: 1 },
            ]}
          >
            <View style={[styles.halfWidthRightSection]}>
              <Text
                style={[styles.boldText, { paddingTop: 2, paddingLeft: 5 }]}
              >
                11. Certification
              </Text>
              <Text
                style={[
                  styles.mediumFontSize,
                  {
                    paddingTop: 2,
                    paddingRight: 5,
                    paddingLeft: 5,
                    justifyContent: "space-between",
                  },
                ]}
              >
                It is hereby certified, on the basis of control carried out that
                the declaration by the exporter is correct. To verify this
                certificate, you may scan the QR code here
              </Text>
              <Text
                style={[
                  styles.mediumFontSize,
                  {
                    textAlign: "center",
                    paddingTop: 90,
                    paddingLeft: 5,
                    paddingRight: 5,
                    justifyContent: "space-between",
                  },
                ]}
              >
                ......................................................
              </Text>
              <Text
                style={[
                  styles.mediumFontSize,
                  {
                    textAlign: "center",
                    paddingTop: 2,
                    paddingLeft: 5,
                    paddingRight: 5,
                    justifyContent: "space-between",
                  },
                ]}
              >
                Place and date, signature and Stamp of Certifying authority.
              </Text>
            </View>
            <View style={[styles.halfWidthLeftSection]}>
              <Text
                style={[styles.boldText, { paddingTop: 2, paddingLeft: 5 }]}
              >
                12. Declaration by the exporter :
              </Text>
              <Text
                style={[
                  styles.mediumFontSize,
                  {
                    paddingTop: 2,
                    paddingLeft: 5,
                    paddingRight: 5,
                    justifyContent: "space-between",
                  },
                ]}
              >
                The undersigned hereby declare that the above details and
                statements are correct that all the good(s) were produced in
                India and that they comply with the origin requirements for
                export to VIETNAM (Importing Country)
              </Text>
              <Text
                style={[
                  styles.boldText,
                  {
                    textAlign: "center",
                    paddingTop: 110,
                    paddingLeft: 5,
                    paddingRight: 5,
                    justifyContent: "space-between",
                  },
                ]}
              >
                SURAT, GUJARAT, INDIA, 04-01-2025
              </Text>
              <Text
                style={[
                  styles.mediumFontSize,
                  {
                    paddingTop: 2,
                    marginLeft: 5,
                    marginRight: 5,
                    borderBottom: 2,
                  },
                ]}
              ></Text>
              <Text
                style={[
                  styles.mediumFontSize,
                  {
                    paddingTop: 2,
                    paddingBottom: 2,
                    paddingLeft: 5,
                    paddingRight: 5,
                    justifyContent: "space-between",
                  },
                ]}
              >
                Place and date, signature & Stamp of the authorized Signatory
              </Text>
            </View>
          </View>

          {/* 13 */}
          <View>
            <Text style={[styles.mediumFontSize, { paddingLeft: 5 }]}>
              13. Where appropriate please tick:
            </Text>
            <View
              style={{
                flexDirection: "row",
                flexWrap: "wrap",
                marginTop: 10,
              }}
            >
              {checkboxData.map((item, index) => {
                return (
                  <View
                    key={index}
                    style={{
                      flexDirection: "row",
                      alignItems: "center",
                      marginRight: 5,
                      marginLeft: 30,
                      paddingBottom: 8,
                    }}
                  >
                    <View style={styles.checkbox}>
                      {item.isChecked && (
                        <Text style={styles.checkMark}>✔</Text>
                      )}
                    </View>
                    <Text style={styles.mediumFontSize}>{item.label}</Text>
                  </View>
                );
              })}
            </View>
          </View>
        </View>
      </Page>

      {/* Page2 */}

      <Page style={styles.page} size="A4">
        <Text
          style={[styles.boldText, { textAlign: "center", marginBottom: -8 }]}
        >
          {" "}
          (ORIGINAL)
        </Text>
        <View style={styles.fullpage}>
          <View
            style={[
              styles.sideBySideContainer,
              { height: "200px", borderBottom: 1 },
            ]}
          >
            <View style={[styles.halfWidthRightSection, {}]}>
              <Text
                style={[
                  styles.regularFontSize,
                  {
                    justifyContent: "space-between",
                    paddingLeft: 5,
                    paddingRight: 5,
                    paddingTop: 15,
                  },
                ]}
              >
                1. Goods consigned from (Exporter's business name, address,
                country):
              </Text>
              <Text style={[styles.largeFontSize, { top: 5, paddingLeft: 5 }]}>
                {stringToUpperCase(settingsData?.company_name)}
              </Text>
              <Text
                style={[
                  styles.largeFontSize,
                  {
                    justifyContent: "space-between",
                    top: 5,
                    paddingLeft: 5,
                    paddingBottom: 15,
                  },
                ]}
              >
                {stringToUpperCase(settingsData?.company_address)}
              </Text>
              <View
                style={{
                  paddingTop: 5,
                  borderTop: 1,
                  paddingLeft: 5,
                  paddingRight: 5,
                }}
              >
                <Text
                  style={[
                    styles.regularFontSize,
                    { justifyContent: "space-between" },
                  ]}
                >
                  2. Goods consigned to (Consignee's name, address, country):
                </Text>
                <Text style={[styles.largeFontSize, { top: 5 }]}>
                  {stringToUpperCase(salesInvoiceData?.buyer?.name)}
                </Text>
                <Text
                  style={[
                    styles.largeFontSize,
                    { justifyContent: "space-between", top: 5 },
                  ]}
                >
                  {stringToUpperCase(
                    salesInvoiceData?.buyer?.address.slice(0, 13)
                  )}
                  ,{""}
                  {stringToUpperCase(
                    salesInvoiceData?.buyer?.address.slice(13, 47)
                  )}
                  ,{""}
                  {stringToUpperCase(
                    salesInvoiceData?.buyer?.address.slice(40)
                  )}
                  ,{""}
                  {stringToUpperCase(salesInvoiceData?.buyer?.city)},{""}
                  {stringToUpperCase(salesInvoiceData?.buyer?.state)},{""}
                  {stringToUpperCase(salesInvoiceData?.buyer?.country)}
                  {""}
                </Text>
              </View>
            </View>
            <View style={[styles.halfWidthLeftSection]}>
              <Text style={[styles.largeFontSize, { top: 5 }]}>
                Reference No. :
              </Text>
              <Text
                style={[
                  styles.boldText,
                  { paddingTop: 15, textAlign: "center" },
                ]}
              >
                CERTIFICATE OF ORIGIN
              </Text>
              <Text
                style={[
                  styles.largeFontSize,
                  {
                    justifyContent: "space-between",
                    top: 5,
                    textAlign: "center",
                  },
                ]}
              >
                (NON PREFERENTIAL)
              </Text>
              <Text
                style={[
                  styles.largeFontSize,
                  {
                    justifyContent: "space-between",
                    top: 5,
                    textAlign: "center",
                  },
                ]}
              >
                (Combined declaration and certificate)
              </Text>
              <Text
                style={[
                  styles.largeFontSize,
                  {
                    textAlign: "center",
                    top: 5,
                  },
                ]}
              >
                Issued in{" "}
                <Text
                  style={[
                    styles.boldText,
                    {
                      textDecoration: "underline",
                      textDecorationStyle: "dotted",
                    },
                  ]}
                >
                  INDIA
                </Text>
              </Text>
              <Text
                style={[
                  styles.largeFontSize,
                  {
                    justifyContent: "space-between",
                    top: 55,
                    textAlign: "left",
                  },
                ]}
              >
                {/* logo */}
              </Text>
              <Text
                style={[
                  styles.largeFontSize,
                  {
                    justifyContent: "space-between",
                    top: 5,
                    textAlign: "left",
                    marginLeft: 55,
                  },
                ]}
              >
                THE GANDHIDHAM CHAMBER OF COMMERCE AND INDUSTRY
              </Text>
              <Text
                style={[
                  styles.largeFontSize,
                  {
                    justifyContent: "space-between",
                    top: 5,
                    textAlign: "left",
                    marginLeft: 55,
                  },
                ]}
              >
                Plot No.71, Sector - 8, Gandhidham - Kachchh, KACHCHH
              </Text>
              <Text
                style={[
                  styles.largeFontSize,
                  {
                    justifyContent: "space-between",
                    top: 5,
                    textAlign: "left",
                    marginLeft: 55,
                  },
                ]}
              >
                E-mail: info@gccikandla.com
              </Text>
              <Text
                style={[
                  styles.largeFontSize,
                  {
                    justifyContent: "space-between",
                    top: 5,
                    textAlign: "left",
                    marginLeft: 55,
                  },
                ]}
              >
                Office Phone: 02836-220977
              </Text>
            </View>
          </View>

          <View
            style={[
              styles.sideBySideContainer,
              { height: 50, borderBottom: 1 },
            ]}
          >
            <View style={[styles.halfWidthRightSection]}>
              <Text
                style={[
                  styles.regularFontSize,
                  { paddingLeft: 5, paddingTop: 10 },
                ]}
              >
                3. Means of transport and route (as far as known):
              </Text>
              <Text style={[styles.largeFontSize, { paddingLeft: 5 }]}>
                BY SEA{" "}
                {salesInvoiceData?.origin_port?.name &&
                salesInvoiceData?.origin_port?.country
                  ? `${stringToUpperCase(
                      salesInvoiceData?.origin_port?.name
                    )}, ${stringToUpperCase(
                      salesInvoiceData?.origin_port?.country
                    )}`
                  : "-"}{" "}
                TO{" "}
                {salesInvoiceData?.destination_port?.name &&
                salesInvoiceData?.destination_port?.country
                  ? `${stringToUpperCase(
                      salesInvoiceData?.destination_port?.name
                    )}, ${stringToUpperCase(
                      salesInvoiceData?.destination_port?.country
                    )}`
                  : "-"}{" "}
              </Text>
            </View>
            <View style={[styles.halfWidthLeftSection]}>
              <Text
                style={[
                  styles.largeFontSize,
                  { paddingLeft: 3, paddingTop: 10 },
                ]}
              >
                4. For Official Use
              </Text>
            </View>
          </View>

          {/* table */}

          <View>
            <View
              style={[
                styles.tableContainer,
                styles.mediumFontSize,
                {
                  textAlign: "left",
                  height: 50,
                  borderTop: 1,
                  marginTop: 5,
                  marginLeft: 5,
                  marginRight: 5,
                  borderRight: 1,
                  borderLeft: 1,
                },
              ]}
            >
              <View
                style={[
                  styles.tableRow,
                  {
                    paddingLeft: 5,
                    paddingRight: 5,
                    flexDirection: "row",
                  },
                ]}
              >
                <Text
                  style={[
                    {
                      paddingTop: 2,
                      borderRightWidth: 1,
                      width: "20%",
                      justifyContent: "space-between",
                    },
                  ]}
                >
                  5 . Item number
                </Text>
                <Text
                  style={[
                    {
                      borderRightWidth: 1,
                      paddingRight: 5,
                      paddingTop: 2,
                      paddingLeft: 5,
                      width: "20%",
                      justifyContent: "space-between",
                    },
                  ]}
                >
                  6. Marks and numbers of packages
                </Text>
                <Text
                  style={[
                    {
                      borderRightWidth: 1,
                      paddingTop: 2,
                      paddingRight: 5,
                      paddingLeft: 5,
                      width: "45%",
                      justifyContent: "space-between",
                    },
                  ]}
                >
                  7. Number and kind of packages, description of goods
                </Text>
                <Text
                  style={[
                    {
                      borderRightWidth: 1,
                      paddingTop: 2,
                      paddingLeft: 5,
                      paddingRight: 5,
                      width: "20%",
                      justifyContent: "space-between",
                    },
                  ]}
                >
                  8. Origin criterion (see notes overleaf)
                </Text>
                <Text
                  style={[
                    {
                      borderRightWidth: 1,
                      paddingLeft: 5,
                      paddingTop: 2,
                      paddingRight: 5,
                      width: "20%",
                      justifyContent: "space-between",
                    },
                  ]}
                >
                  9. Gross weight or other quantity
                </Text>
                <Text
                  style={[
                    {
                      paddingTop: 2,
                      paddingLeft: 5,
                      paddingRight: 5,
                      width: "20%",
                      justifyContent: "space-between",
                    },
                  ]}
                >
                  10. Number and date of invoices
                </Text>
              </View>
            </View>
            {/* table data */}
            <View
              style={[
                styles.tableContainer,
                styles.mediumFontSize,
                {
                  textAlign: "left",
                  height: 50,
                  marginLeft: 5,
                  marginRight: 5,
                  borderRight: 1,
                  borderLeft: 1,
                },
              ]}
            >
              <View
                style={[
                  styles.tableRow,
                  {
                    paddingLeft: 5,
                    paddingRight: 5,
                    flexDirection: "row",
                  },
                ]}
              >
                <Text
                  style={[
                    {
                      borderRightWidth: 1,
                      paddingTop: 2,
                      width: "20%",
                      justifyContent: "space-between",
                    },
                  ]}
                >
                  1
                </Text>
                <Text
                  style={[
                    {
                      borderRightWidth: 1,
                      paddingRight: 5,
                      paddingTop: 2,
                      paddingLeft: 5,
                      width: "20%",
                      justifyContent: "space-between",
                    },
                  ]}
                >
                  {salesInvoiceData?.no_of_bags}
                </Text>
                <Text
                  style={[
                    {
                      borderRightWidth: 1,
                      paddingTop: 2,
                      paddingRight: 5,
                      paddingLeft: 5,
                      width: "45%",
                      justifyContent: "space-between",
                    },
                  ]}
                >
                  TOTAL {salesInvoiceData?.no_of_bags} BAGS,{" "}
                  {stringToUpperCase(salesInvoiceData?.product?.name)} (PACKING:
                  IN{""}
                  {stringToUpperCase(
                    salesInvoiceData?.packing_type?.name
                  )} OF {salesInvoiceData?.packing_net_weight}{" "}
                  {stringToUpperCase(salesInvoiceData?.net_weight_unit?.name)}{" "}
                  NET EACH) (GROSS WT: {salesInvoiceData?.packing_gross_weight}{" "}
                  {salesInvoiceData?.gross_weight_unit?.name}), HS Code:
                  {salesInvoiceData?.product?.hsn_no}
                </Text>
                <Text
                  style={[
                    {
                      borderRightWidth: 1,
                      paddingLeft: 5,
                      paddingTop: 2,
                      paddingRight: 5,
                      width: "20%",
                      justifyContent: "space-between",
                    },
                  ]}
                >
                  "INDIAN ORIGIN"
                </Text>
                <Text
                  style={[
                    {
                      borderRightWidth: 1,
                      paddingLeft: 5,
                      paddingTop: 2,
                      paddingRight: 5,
                      width: "20%",
                      justifyContent: "space-between",
                    },
                  ]}
                >
                  {salesInvoiceData?.total_gross_weight}
                </Text>
                <Text
                  style={[
                    {
                      paddingLeft: 5,
                      paddingTop: 2,
                      paddingRight: 5,
                      width: "20%",
                      justifyContent: "space-between",
                    },
                  ]}
                >
                  {salesInvoiceData?.invoice_no} {"\n"}
                  {formatDate(salesInvoiceData?.invoice_date)}
                </Text>
              </View>
            </View>
          </View>

          {/* 11 | 12 */}

          <View
            style={[
              styles.sideBySideContainer,
              { marginTop: 45, borderTop: 1, height: 200, borderBottom: 1 },
            ]}
          >
            <View style={[styles.halfWidthRightSection]}>
              <Text
                style={[styles.boldText, { paddingTop: 2, paddingLeft: 5 }]}
              >
                11. Certification
              </Text>
              <Text
                style={[
                  styles.mediumFontSize,
                  {
                    paddingTop: 2,
                    paddingRight: 5,
                    paddingLeft: 5,
                    justifyContent: "space-between",
                  },
                ]}
              >
                It is hereby certified, on the basis of control carried out that
                the declaration by the exporter is correct. To verify this
                certificate, you may scan the QR code here
              </Text>
              <Text
                style={[
                  styles.mediumFontSize,
                  {
                    textAlign: "center",
                    paddingTop: 90,
                    paddingLeft: 5,
                    paddingRight: 5,
                    justifyContent: "space-between",
                  },
                ]}
              >
                ......................................................
              </Text>
              <Text
                style={[
                  styles.mediumFontSize,
                  {
                    textAlign: "center",
                    paddingTop: 2,
                    paddingLeft: 5,
                    paddingRight: 5,
                    justifyContent: "space-between",
                  },
                ]}
              >
                Place and date, signature and Stamp of Certifying authority.
              </Text>
            </View>
            <View style={[styles.halfWidthLeftSection]}>
              <Text
                style={[styles.boldText, { paddingTop: 2, paddingLeft: 5 }]}
              >
                12. Declaration by the exporter :
              </Text>
              <Text
                style={[
                  styles.mediumFontSize,
                  {
                    paddingTop: 2,
                    paddingLeft: 5,
                    paddingRight: 5,
                    justifyContent: "space-between",
                  },
                ]}
              >
                The undersigned hereby declare that the above details and
                statements are correct that all the good(s) were produced in
                India and that they comply with the origin requirements for
                export to VIETNAM (Importing Country)
              </Text>
              <Text
                style={[
                  styles.boldText,
                  {
                    textAlign: "center",
                    paddingTop: 110,
                    paddingLeft: 5,
                    paddingRight: 5,
                    justifyContent: "space-between",
                  },
                ]}
              >
                SURAT, GUJARAT, INDIA, 04-01-2025
              </Text>
              <Text
                style={[
                  styles.mediumFontSize,
                  {
                    paddingTop: 2,
                    marginLeft: 5,
                    marginRight: 5,
                    borderBottom: 2,
                  },
                ]}
              ></Text>
              <Text
                style={[
                  styles.mediumFontSize,
                  {
                    paddingTop: 2,
                    paddingBottom: 2,
                    paddingLeft: 5,
                    paddingRight: 5,
                    justifyContent: "space-between",
                  },
                ]}
              >
                Place and date, signature & Stamp of the authorized Signatory
              </Text>
            </View>
          </View>

          {/* 13 */}
          <View>
            <Text style={[styles.mediumFontSize, { paddingLeft: 5 }]}>
              13. Where appropriate please tick:
            </Text>
            <View
              style={{
                flexDirection: "row",
                flexWrap: "wrap",
                marginTop: 10,
              }}
            >
              {checkboxData.map((item, index) => (
                <View
                  key={index}
                  style={{
                    flexDirection: "row",
                    alignItems: "center",
                    marginRight: 5,
                    marginLeft: 30,
                    paddingBottom: 8,
                  }}
                >
                  <View style={styles.checkbox}>
                    {item.isChecked === true && (
                      <Text style={styles.checkMark}>✔</Text>
                    )}
                  </View>
                  <Text style={styles.mediumFontSize}>{item.label}</Text>
                </View>
              ))}
            </View>
          </View>
        </View>
      </Page>

      {/* Page3 */}
      <Page style={styles.page} size="A4">
        <Text
          style={[styles.boldText, { textAlign: "center", marginBottom: -8 }]}
        >
          {" "}
          (DUPLICATE)
        </Text>
        <View style={styles.fullpage}>
          <View
            style={[
              styles.sideBySideContainer,
              { height: "200px", borderBottom: 1 },
            ]}
          >
            <View style={[styles.halfWidthRightSection, {}]}>
              <Text
                style={[
                  styles.regularFontSize,
                  {
                    justifyContent: "space-between",
                    paddingLeft: 5,
                    paddingRight: 5,
                    paddingTop: 15,
                  },
                ]}
              >
                1. Goods consigned from (Exporter's business name, address,
                country):
              </Text>
              <Text style={[styles.largeFontSize, { top: 5, paddingLeft: 5 }]}>
                {stringToUpperCase(settingsData?.company_name)}
              </Text>
              <Text
                style={[
                  styles.largeFontSize,
                  {
                    justifyContent: "space-between",
                    top: 5,
                    paddingLeft: 5,
                    paddingBottom: 15,
                  },
                ]}
              >
                {stringToUpperCase(settingsData?.company_address)}
              </Text>
              <View
                style={{
                  paddingTop: 5,
                  borderTop: 1,
                  paddingLeft: 5,
                  paddingRight: 5,
                }}
              >
                <Text
                  style={[
                    styles.regularFontSize,
                    { justifyContent: "space-between" },
                  ]}
                >
                  2. Goods consigned to (Consignee's name, address, country):
                </Text>
                <Text style={[styles.largeFontSize, { top: 5 }]}>
                  {stringToUpperCase(salesInvoiceData?.buyer?.name)}
                </Text>
                <Text
                  style={[
                    styles.largeFontSize,
                    { justifyContent: "space-between", top: 5 },
                  ]}
                >
                  {stringToUpperCase(
                    salesInvoiceData?.buyer?.address.slice(0, 13)
                  )}
                  ,{""}
                  {stringToUpperCase(
                    salesInvoiceData?.buyer?.address.slice(13, 47)
                  )}
                  ,{""}
                  {stringToUpperCase(
                    salesInvoiceData?.buyer?.address.slice(40)
                  )}
                  ,{""}
                  {stringToUpperCase(salesInvoiceData?.buyer?.city)},{""}
                  {stringToUpperCase(salesInvoiceData?.buyer?.state)},{""}
                  {stringToUpperCase(salesInvoiceData?.buyer?.country)}
                  {""}
                </Text>
              </View>
            </View>
            <View style={[styles.halfWidthLeftSection]}>
              <Text style={[styles.largeFontSize, { top: 5 }]}>
                Reference No. :
              </Text>
              <Text
                style={[
                  styles.boldText,
                  { paddingTop: 15, textAlign: "center" },
                ]}
              >
                CERTIFICATE OF ORIGIN
              </Text>
              <Text
                style={[
                  styles.largeFontSize,
                  {
                    justifyContent: "space-between",
                    top: 5,
                    textAlign: "center",
                  },
                ]}
              >
                (NON PREFERENTIAL)
              </Text>
              <Text
                style={[
                  styles.largeFontSize,
                  {
                    justifyContent: "space-between",
                    top: 5,
                    textAlign: "center",
                  },
                ]}
              >
                (Combined declaration and certificate)
              </Text>
              <Text
                style={[
                  styles.largeFontSize,
                  {
                    textAlign: "center",
                    top: 5,
                  },
                ]}
              >
                Issued in{" "}
                <Text
                  style={[
                    styles.boldText,
                    {
                      textDecoration: "underline",
                      textDecorationStyle: "dotted",
                    },
                  ]}
                >
                  INDIA
                </Text>
              </Text>
              <Text
                style={[
                  styles.largeFontSize,
                  {
                    justifyContent: "space-between",
                    top: 55,
                    textAlign: "left",
                  },
                ]}
              >
                {/* logo */}
              </Text>
              <Text
                style={[
                  styles.largeFontSize,
                  {
                    justifyContent: "space-between",
                    top: 5,
                    textAlign: "left",
                    marginLeft: 55,
                  },
                ]}
              >
                THE GANDHIDHAM CHAMBER OF COMMERCE AND INDUSTRY
              </Text>
              <Text
                style={[
                  styles.largeFontSize,
                  {
                    justifyContent: "space-between",
                    top: 5,
                    textAlign: "left",
                    marginLeft: 55,
                  },
                ]}
              >
                Plot No.71, Sector - 8, Gandhidham - Kachchh, KACHCHH
              </Text>
              <Text
                style={[
                  styles.largeFontSize,
                  {
                    justifyContent: "space-between",
                    top: 5,
                    textAlign: "left",
                    marginLeft: 55,
                  },
                ]}
              >
                E-mail: info@gccikandla.com
              </Text>
              <Text
                style={[
                  styles.largeFontSize,
                  {
                    justifyContent: "space-between",
                    top: 5,
                    textAlign: "left",
                    marginLeft: 55,
                  },
                ]}
              >
                Office Phone: 02836-220977
              </Text>
            </View>
          </View>

          <View
            style={[
              styles.sideBySideContainer,
              { height: 50, borderBottom: 1 },
            ]}
          >
            <View style={[styles.halfWidthRightSection]}>
              <Text
                style={[
                  styles.regularFontSize,
                  { paddingLeft: 5, paddingTop: 10 },
                ]}
              >
                3. Means of transport and route (as far as known):
              </Text>
              <Text style={[styles.largeFontSize, { paddingLeft: 5 }]}>
                BY SEA{" "}
                {salesInvoiceData?.origin_port?.name &&
                salesInvoiceData?.origin_port?.country
                  ? `${stringToUpperCase(
                      salesInvoiceData?.origin_port?.name
                    )}, ${stringToUpperCase(
                      salesInvoiceData?.origin_port?.country
                    )}`
                  : "-"}{" "}
                TO{" "}
                {salesInvoiceData?.destination_port?.name &&
                salesInvoiceData?.destination_port?.country
                  ? `${stringToUpperCase(
                      salesInvoiceData?.destination_port?.name
                    )}, ${stringToUpperCase(
                      salesInvoiceData?.destination_port?.country
                    )}`
                  : "-"}{" "}
              </Text>
            </View>
            <View style={[styles.halfWidthLeftSection]}>
              <Text
                style={[
                  styles.largeFontSize,
                  { paddingLeft: 3, paddingTop: 10 },
                ]}
              >
                4. For Official Use
              </Text>
            </View>
          </View>

          {/* table */}

          <View>
            <View
              style={[
                styles.tableContainer,
                styles.mediumFontSize,
                {
                  textAlign: "left",
                  height: 50,
                  borderTop: 1,
                  marginTop: 5,
                  marginLeft: 5,
                  marginRight: 5,
                  borderRight: 1,
                  borderLeft: 1,
                },
              ]}
            >
              <View
                style={[
                  styles.tableRow,
                  {
                    paddingLeft: 5,
                    paddingRight: 5,
                    flexDirection: "row",
                  },
                ]}
              >
                <Text
                  style={[
                    {
                      paddingTop: 2,
                      borderRightWidth: 1,
                      width: "20%",
                      justifyContent: "space-between",
                    },
                  ]}
                >
                  5 . Item number
                </Text>
                <Text
                  style={[
                    {
                      borderRightWidth: 1,
                      paddingRight: 5,
                      paddingTop: 2,
                      paddingLeft: 5,
                      width: "20%",
                      justifyContent: "space-between",
                    },
                  ]}
                >
                  6. Marks and numbers of packages
                </Text>
                <Text
                  style={[
                    {
                      borderRightWidth: 1,
                      paddingTop: 2,
                      paddingRight: 5,
                      paddingLeft: 5,
                      width: "45%",
                      justifyContent: "space-between",
                    },
                  ]}
                >
                  7. Number and kind of packages, description of goods
                </Text>
                <Text
                  style={[
                    {
                      borderRightWidth: 1,
                      paddingTop: 2,
                      paddingLeft: 5,
                      paddingRight: 5,
                      width: "20%",
                      justifyContent: "space-between",
                    },
                  ]}
                >
                  8. Origin criterion (see notes overleaf)
                </Text>
                <Text
                  style={[
                    {
                      borderRightWidth: 1,
                      paddingLeft: 5,
                      paddingTop: 2,
                      paddingRight: 5,
                      width: "20%",
                      justifyContent: "space-between",
                    },
                  ]}
                >
                  9. Gross weight or other quantity
                </Text>
                <Text
                  style={[
                    {
                      paddingTop: 2,
                      paddingLeft: 5,
                      paddingRight: 5,
                      width: "20%",
                      justifyContent: "space-between",
                    },
                  ]}
                >
                  10. Number and date of invoices
                </Text>
              </View>
            </View>
            {/* table data */}
            <View
              style={[
                styles.tableContainer,
                styles.mediumFontSize,
                {
                  textAlign: "left",
                  height: 50,
                  marginLeft: 5,
                  marginRight: 5,
                  borderRight: 1,
                  borderLeft: 1,
                },
              ]}
            >
              <View
                style={[
                  styles.tableRow,
                  {
                    paddingLeft: 5,
                    paddingRight: 5,
                    flexDirection: "row",
                  },
                ]}
              >
                <Text
                  style={[
                    {
                      borderRightWidth: 1,
                      paddingTop: 2,
                      width: "20%",
                      justifyContent: "space-between",
                    },
                  ]}
                >
                  1
                </Text>
                <Text
                  style={[
                    {
                      borderRightWidth: 1,
                      paddingRight: 5,
                      paddingTop: 2,
                      paddingLeft: 5,
                      width: "20%",
                      justifyContent: "space-between",
                    },
                  ]}
                >
                  {salesInvoiceData?.no_of_bags}
                </Text>
                <Text
                  style={[
                    {
                      borderRightWidth: 1,
                      paddingTop: 2,
                      paddingRight: 5,
                      paddingLeft: 5,
                      width: "45%",
                      justifyContent: "space-between",
                    },
                  ]}
                >
                  TOTAL {salesInvoiceData?.no_of_bags} BAGS,{" "}
                  {stringToUpperCase(salesInvoiceData?.product?.name)} (PACKING:
                  IN{""}
                  {stringToUpperCase(
                    salesInvoiceData?.packing_type?.name
                  )} OF {salesInvoiceData?.packing_net_weight}{" "}
                  {stringToUpperCase(salesInvoiceData?.net_weight_unit?.name)}{" "}
                  NET EACH) (GROSS WT: {salesInvoiceData?.packing_gross_weight}{" "}
                  {salesInvoiceData?.gross_weight_unit?.name}), HS Code:
                  {salesInvoiceData?.product?.hsn_no}
                </Text>
                <Text
                  style={[
                    {
                      borderRightWidth: 1,
                      paddingLeft: 5,
                      paddingTop: 2,
                      paddingRight: 5,
                      width: "20%",
                      justifyContent: "space-between",
                    },
                  ]}
                >
                  "INDIAN ORIGIN"
                </Text>
                <Text
                  style={[
                    {
                      borderRightWidth: 1,
                      paddingLeft: 5,
                      paddingTop: 2,
                      paddingRight: 5,
                      width: "20%",
                      justifyContent: "space-between",
                    },
                  ]}
                >
                  {salesInvoiceData?.total_gross_weight}
                </Text>
                <Text
                  style={[
                    {
                      paddingLeft: 5,
                      paddingTop: 2,
                      paddingRight: 5,
                      width: "20%",
                      justifyContent: "space-between",
                    },
                  ]}
                >
                  {stringToUpperCase(salesInvoiceData?.invoice_no)}
                  {"\n"}
                  {formatDate(salesInvoiceData?.invoice_date)}
                </Text>
              </View>
            </View>
          </View>

          {/* 11 | 12 */}

          <View
            style={[
              styles.sideBySideContainer,
              { marginTop: 45, borderTop: 1, height: 200, borderBottom: 1 },
            ]}
          >
            <View style={[styles.halfWidthRightSection]}>
              <Text
                style={[styles.boldText, { paddingTop: 2, paddingLeft: 5 }]}
              >
                11. Certification
              </Text>
              <Text
                style={[
                  styles.mediumFontSize,
                  {
                    paddingTop: 2,
                    paddingRight: 5,
                    paddingLeft: 5,
                    justifyContent: "space-between",
                  },
                ]}
              >
                It is hereby certified, on the basis of control carried out that
                the declaration by the exporter is correct. To verify this
                certificate, you may scan the QR code here
              </Text>
              <Text
                style={[
                  styles.mediumFontSize,
                  {
                    textAlign: "center",
                    paddingTop: 90,
                    paddingLeft: 5,
                    paddingRight: 5,
                    justifyContent: "space-between",
                  },
                ]}
              >
                ......................................................
              </Text>
              <Text
                style={[
                  styles.mediumFontSize,
                  {
                    textAlign: "center",
                    paddingTop: 2,
                    paddingLeft: 5,
                    paddingRight: 5,
                    justifyContent: "space-between",
                  },
                ]}
              >
                Place and date, signature and Stamp of Certifying authority.
              </Text>
            </View>
            <View style={[styles.halfWidthLeftSection]}>
              <Text
                style={[styles.boldText, { paddingTop: 2, paddingLeft: 5 }]}
              >
                12. Declaration by the exporter :
              </Text>
              <Text
                style={[
                  styles.mediumFontSize,
                  {
                    paddingTop: 2,
                    paddingLeft: 5,
                    paddingRight: 5,
                    justifyContent: "space-between",
                  },
                ]}
              >
                The undersigned hereby declare that the above details and
                statements are correct that all the good(s) were produced in
                India and that they comply with the origin requirements for
                export to VIETNAM (Importing Country)
              </Text>
              <Text
                style={[
                  styles.boldText,
                  {
                    textAlign: "center",
                    paddingTop: 110,
                    paddingLeft: 5,
                    paddingRight: 5,
                    justifyContent: "space-between",
                  },
                ]}
              >
                SURAT, GUJARAT, INDIA, 04-01-2025
              </Text>
              <Text
                style={[
                  styles.mediumFontSize,
                  {
                    paddingTop: 2,
                    marginLeft: 5,
                    marginRight: 5,
                    borderBottom: 2,
                  },
                ]}
              ></Text>
              <Text
                style={[
                  styles.mediumFontSize,
                  {
                    paddingTop: 2,
                    paddingBottom: 2,
                    paddingLeft: 5,
                    paddingRight: 5,
                    justifyContent: "space-between",
                  },
                ]}
              >
                Place and date, signature & Stamp of the authorized Signatory
              </Text>
            </View>
          </View>

          {/* 13 */}
          <View>
            <Text style={[styles.mediumFontSize, { paddingLeft: 5 }]}>
              13. Where appropriate please tick:
            </Text>
            <View
              style={{
                flexDirection: "row",
                flexWrap: "wrap",
                marginTop: 10,
              }}
            >
              {checkboxData.map((item, index) => (
                <View
                  key={index}
                  style={{
                    flexDirection: "row",
                    alignItems: "center",
                    marginRight: 5,
                    marginLeft: 30,
                    paddingBottom: 8,
                  }}
                >
                  <View style={styles.checkbox}>
                    {item.isChecked && <Text style={styles.checkMark}>✔</Text>}
                  </View>
                  <Text style={styles.mediumFontSize}>{item.label}</Text>
                </View>
              ))}
            </View>
          </View>
        </View>
      </Page>
    </Document>
  );
};

export default CertificateOfOrigin;
