import { CURRENCY } from "../../../../components/Constants";
import { ErrorMessage, Field, FormikErrors, FormikValues } from "formik";
import ReactSelect from "react-select";

interface ProductFieldsProps {
  productList: any;
  unitList: any;
  packingType: any;
  values: FormikValues;
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean
  ) => Promise<void | FormikErrors<FormikValues>>;
  handleChange: (e: React.ChangeEvent<any>) => void;
  calculateTotalAmount: any;
  setAddUnitModalStatus: (show: boolean) => void;
  setAddPackingTypeModalStatus: (show: boolean) => void;
  setAddProductModalStatus: (show: boolean) => void;
  handleChangeProduct: (product_id: string) => void;
}

const ProductFields: React.FC<ProductFieldsProps> = ({
  productList,
  unitList,
  packingType,
  values,
  setFieldValue,
  handleChange,
  calculateTotalAmount,
  setAddUnitModalStatus,
  setAddPackingTypeModalStatus,
  setAddProductModalStatus,
  handleChangeProduct,
}) => {
  return (
    <div className="card my-card">
      <div className="card-header my-card-header bg-lighter">
        <div>
          <h6 className="my-card-header-head">Product Details</h6>
        </div>
      </div>
      <div className="card-body my-card-body">
        <div className="row g-3 m-0">
          <div className="col-12 col-sm-6 col-md-4 col-lg-3">
            <div className="d-flex justify-content-between">
              <div>
                <label
                  htmlFor="product_id"
                  className="form-label"
                  style={{ fontSize: "0.9375rem" }}
                >
                  Product: <span className="text-danger">*</span>
                </label>
              </div>
              <div>
                <button
                  title="Add Product"
                  className="btn btn-sm btn-primary waves-effect waves-light p-0"
                  type="button"
                  onClick={() => setAddProductModalStatus(true)}
                >
                  <span>
                    <i className="ti ti-plus my-card-icon-button"></i>
                  </span>
                </button>
              </div>
            </div>
            <Field name="product_id">
              {({ field }: any) => {
                const selectedOption = productList.find(
                  (option: any) => option.id === field.value
                );
                const formatOptionLabel = (option: any, { context }: any) => {
                  if (context === "value") {
                    return option.name;
                  } else {
                    return (
                      <div>
                        <label className="form-label">{option.name}</label>
                        <br />
                        <span className="form-label">{option.description}</span>
                      </div>
                    );
                  }
                };
                return (
                  <ReactSelect
                    {...field}
                    classNamePrefix="react-select"
                    placeholder="Select Product"
                    options={productList}
                    getOptionLabel={(option: any) => option.name}
                    formatOptionLabel={formatOptionLabel}
                    getOptionValue={(option: any) => option.id}
                    onChange={(selectedOption: any) => {
                      setFieldValue(
                        "product_id",
                        selectedOption ? selectedOption.id : ""
                      );
                      handleChangeProduct(selectedOption?.id || "");
                      values.quality_specification.forEach(
                        (element: any, index: any) => {
                          setFieldValue(`quality_specification[${index}]`, {
                            quality_specification_id: "",
                            value: "",
                          });
                        }
                      );
                    }}
                    value={selectedOption}
                    isClearable
                    isSearchable
                  />
                );
              }}
            </Field>
            <ErrorMessage
              name="product_id"
              component="div"
              className="text-danger"
            />
          </div>
          <div className="col-12 col-sm-6 col-md-4 col-lg-3">
            <label
              htmlFor="price"
              className="form-label"
              style={{ fontSize: "0.9375rem" }}
            >
              Price ({CURRENCY.USD.SYMBOL}):
              <span className="text-danger">*</span>
            </label>
            <Field
              id="price"
              name="price"
              type="number"
              className="form-control"
              placeholder="Enter Price"
              value={values?.price || undefined}
              onChange={(e: any) => {
                handleChange(e);
                calculateTotalAmount(
                  e.target.value,
                  values.quantity,
                  setFieldValue
                );
              }}
            />
            <ErrorMessage
              name="price"
              className="text-danger"
              component="div"
            />
          </div>
          <div className="col-12 col-sm-6 col-md-4 col-lg-3">
            <label
              htmlFor="quantity"
              className="form-label"
              style={{ fontSize: "0.9375rem" }}
            >
              Quantity: <span className="text-danger">*</span>
            </label>
            <Field
              id="quantity"
              name="quantity"
              type="number"
              className="form-control"
              placeholder="Enter Quantity"
              value={values?.quantity || undefined}
              onChange={(e: any) => {
                handleChange(e);
                calculateTotalAmount(
                  values.price,
                  e.target.value,
                  setFieldValue
                );
              }}
            />
            <ErrorMessage
              name="quantity"
              className="text-danger"
              component="div"
            />
          </div>
          <div className="col-12 col-sm-6 col-md-4 col-lg-3">
            <div className="d-flex justify-content-between">
              <div>
                <label
                  htmlFor="quantity_unit_id"
                  className="form-label"
                  style={{ fontSize: "0.9375rem" }}
                >
                  Quantity Unit:
                </label>
              </div>
              <div>
                <button
                  title="Add Unit"
                  className="btn btn-sm btn-primary waves-effect waves-light p-0"
                  type="button"
                  onClick={() => setAddUnitModalStatus(true)}
                >
                  <span>
                    <i className="ti ti-plus my-card-icon-button"></i>
                  </span>
                </button>
              </div>
            </div>
            <select
              id="quantity_unit_id"
              name="quantity_unit_id"
              className="form-select"
              value={values.quantity_unit_id}
              onChange={handleChange}
            >
              <option defaultChecked value={""}>
                Select Quantity Unit
              </option>
              {unitList.map((unit: any) => (
                <option key={unit.id} value={unit.id}>
                  {unit.name}
                </option>
              ))}
            </select>
            <ErrorMessage
              name="quantity_unit_id"
              className="text-danger"
              component="div"
            />
          </div>
          <div className="col-12 col-sm-6 col-md-4 col-lg-3">
            <label
              htmlFor="total_amount"
              className="form-label"
              style={{ fontSize: "0.9375rem" }}
            >
              Total Amount ({CURRENCY.USD.SYMBOL}) :
            </label>
            <Field
              id="total_amount"
              name="total_amount"
              type="number"
              className="form-control"
              placeholder="0"
              value={values.total_amount}
              onChange={handleChange}
              disabled
            />
            <ErrorMessage
              name="total_amount"
              className="text-danger"
              component="div"
            />
          </div>
          <div className="col-12 col-sm-6 col-md-4 col-lg-3">
            <label
              htmlFor="container_load"
              className="form-label"
              style={{ fontSize: "0.9375rem" }}
            >
              Container Size:{" "}
            </label>
            <Field
              id="container_load"
              name="container_load"
              type="text"
              className="form-control"
              placeholder="Enter Container Size"
              value={values.container_load}
              onChange={handleChange}
            />
            <ErrorMessage
              name="container_load"
              className="text-danger"
              component="div"
            />
          </div>
          <div className="col-12 col-sm-6 col-md-4 col-lg-3">
            <div className="d-flex justify-content-between">
              <div>
                <label
                  htmlFor="packing_type"
                  className="form-label"
                  style={{ fontSize: "0.9375rem" }}
                >
                  Package Type:
                </label>
              </div>
              <div>
                <button
                  title="Add Packing Type"
                  className="btn btn-sm btn-primary waves-effect waves-light p-0"
                  type="button"
                  onClick={() => setAddPackingTypeModalStatus(true)}
                >
                  <span>
                    <i className="ti ti-plus my-card-icon-button"></i>
                  </span>
                </button>
              </div>
            </div>
            <select
              id="packing_type_id"
              name="packing_type_id"
              className="form-select"
              value={values.packing_type_id}
              onChange={handleChange}
            >
              <option defaultChecked value={""}>
                Select Packing Types
              </option>
              {packingType.map((packing: any) => (
                <option key={packing.id} value={packing.id}>
                  {packing.name}
                </option>
              ))}
            </select>
            <ErrorMessage
              name="packing_type"
              className="text-danger"
              component="div"
            />
          </div>
          <div className="col-12 col-sm-6 col-md-4 col-lg-3">
            <label
              htmlFor="packing_net_weight"
              className="form-label"
              style={{ fontSize: "0.9375rem" }}
            >
              Packing Net Weight:
            </label>
            <Field
              id="packing_net_weight"
              name="packing_net_weight"
              type="number"
              className="form-control"
              placeholder="Enter Packing Net Weight"
              value={values?.packing_net_weight || undefined}
              onChange={handleChange}
            />
            <ErrorMessage
              name="packing_net_weight"
              className="text-danger"
              component="div"
            />
          </div>
          <div className="col-12 col-sm-6 col-md-4 col-lg-3">
            <label
              htmlFor="packing_gross_weight"
              className="form-label"
              style={{ fontSize: "0.9375rem" }}
            >
              Packing Gross Weight:
            </label>
            <Field
              id="packing_gross_weight"
              name="packing_gross_weight"
              type="number"
              className="form-control"
              placeholder="Enter Packing Gross Weight"
              value={values?.packing_gross_weight || undefined}
              onChange={handleChange}
            />
            <ErrorMessage
              name="packing_gross_weight"
              className="text-danger"
              component="div"
            />
          </div>
          <div className="col-12 col-sm-6 col-md-4 col-lg-3">
            <label
              htmlFor="packing_unit_id"
              className="form-label"
              style={{ fontSize: "0.9375rem" }}
            >
              Packing Unit:
            </label>
            <select
              id="packing_unit_id"
              name="packing_unit_id"
              className="form-select"
              value={values.packing_unit_id}
              onChange={handleChange}
            >
              <option defaultChecked value={""}>
                Select Packing Unit
              </option>
              {unitList.map((unit: any) => (
                <option key={unit.id} value={unit.id}>
                  {unit.name}
                </option>
              ))}
            </select>
            <ErrorMessage
              name="packing_unit"
              className="text-danger"
              component="div"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductFields;
