/* eslint-disable react-hooks/exhaustive-deps */
import {
  updatePaymentApi,
  getPaymentDetailsByIDApi,
} from "../../services/_payment";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { errorToast, successToast } from "../../components/Toast";
import PaymentForm from "./PaymentForm";

const EditPayment = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [submitLoader, setSubmitLoader] = useState<boolean>(false);

  const [initialValues, setInitialValues] = useState({
    sales_type: "",
    sales_contract_id: "",
    sales_invoice_id: "",
    miscellaneous_sales_id: "",
    payment_role: "",
    transaction_type: "",
    payment_method: "",
    payment_status: "",
    transaction_no: "",
    amount: 0,
    transaction_date: new Date(),
    remark: "",
    relation_id: "",
    purchase_id: "",
    exchange_rate: 0,
    exchange_amount: 0,
    exchange_currency: null,
  });

  const fetchInitialPaymentData = async () => {
    const result: any = await getPaymentDetailsByIDApi(id as string);
    if (result.isSuccess) {
      setInitialValues({
        sales_type: result.data.sales_type || "",
        sales_contract_id: result.data.sales_contract_id || "",
        sales_invoice_id: result.data.sales_invoice_id || "",
        miscellaneous_sales_id: result.data.miscellaneous_sales_id || "",
        payment_role: result.data.payment_role || "",
        transaction_type: result.data.transaction_type || "",
        payment_method: result.data.payment_method || "",
        payment_status: result.data.payment_status || "",
        transaction_no: result.data.transaction_no || "",
        amount: parseInt(result.data.amount) || 0,
        transaction_date: new Date(result.data.transaction_date) || new Date(),
        remark: result.data.remark || "",
        relation_id:
          result.data.buyer_id ||
          result.data.cha_id ||
          result.data.expense_id ||
          result.data.logistic_id ||
          result.data.supplier_id ||
          result.data.broker_id ||
          "",
        exchange_amount: result.data.exchange_amount || 0,
        exchange_rate: result.data.exchange_rate || 0,
        purchase_id: result.data.purchase_id || "",
        exchange_currency: result.data.exchange_currency || null,
      });
    }

    return result?.data;
  };

  useEffect(() => {
    if (!id) {
      navigate("/payment-list");
    }
  }, []);

  const handleSubmit = async (values: any) => {
    const result: any = await updatePaymentApi(id as string, values);
    if (result.isSuccess) {
      setSubmitLoader(false);
      successToast(result.message);
      navigate("/payment-list");
    } else {
      setSubmitLoader(false);
      errorToast(result.message);
    }
    return;
  };

  return (
    <>
      <div className="card">
        <div className="card-header pb-0">
          <div>
            <h5>Edit Payments</h5>
          </div>
        </div>
        <div className="card-body">
          <PaymentForm
            initialValues={initialValues}
            handleSubmit={handleSubmit}
            submitLoader={submitLoader}
            fetchInitialPaymentData={fetchInitialPaymentData}
            id={id}
          />
        </div>
      </div>
    </>
  );
};

export default EditPayment;
