import {
  Document,
  Page,
  PDFViewer,
  StyleSheet,
  Text,
  View,
} from "@react-pdf/renderer";

export const styles = StyleSheet.create({
  page: {
    paddingHorizontal: 30,
    paddingVertical: 15,
    fontFamily: "HostGrotesk",
    backgroundColor: "white",
  },
  fullpage: {
    margin: 7,
    borderStyle: "solid",
    borderWidth: 1,
    borderColor: "#000000",
  },

  sideBySideContainer: { flexDirection: "row" },
  halfWidthRightSection: {
    width: "50%",
    borderBottomWidth: 1,
    borderbottomStyle: "solid",
    borderRightWidth: 1,
    textAlign: "left",
  },
  halfWidthLeftSection: {
    width: "50%",
    borderBottomWidth: 1,
    borderbottomStyle: "solid",
  },
  tableContainer: {
    borderBottomWidth: 1,
    borderBottomColor: "black",
  },
  tableRow: {
    flexDirection: "row",
    flexGrow: 1,
    alignItems: "stretch",
  },
  contentPadding: {
    paddingLeft: 5,
    marginRight: 4,
    paddingTop: 2,
  },
  row: {
    flexDirection: "row",
  },
  squareBullet: {
    width: 5,
    height: 5,
    backgroundColor: "black",
    marginLeft: 10,
    marginTop: 5,
  },
  container: {
    paddingTop: 10,
    width: "100%",
    paddingLeft: 5,
  },
  boldText: {
    fontWeight: "bold",
    fontSize: 10,
  },
  smallFontSize: {
    fontSize: 6,
  },
  mediumFontSize: {
    fontSize: 8,
  },
  regularFontSize: {
    fontSize: 9,
  },
  subContent: {
    paddingTop: 5,
    paddingLeft: 5,
  },
  checkbox: {
    width: 10,
    height: 10,
    borderWidth: 1,
    borderColor: "black",
    marginRight: 10,
  },
  checkMark: {
    fontSize: 18,
    color: "black",
  },
});

const FormAiDraft = () => {
  const checkboxData = [
    { label: "Third Country Invoicing", isChecked: false },
    { label: "Exhibition", isChecked: false },
    { label: "Back-to-Back CO ", isChecked: true },
    { label: "Cumulation", isChecked: false },
  ];
  return (
    <PDFViewer width="100%" height="800">
      <Document>
        {/* Page-1 */}
        <Page style={styles.page} size="A4">
          <View>
            <Text style={[styles.boldText, { textAlign: "center" }]}>
              (ELECTORNIC COPY)
            </Text>
          </View>
          <View style={styles.fullpage}>
            <View style={[styles.sideBySideContainer, { height: "150px" }]}>
              <View style={[styles.halfWidthRightSection]}>
                <Text
                  style={[
                    styles.regularFontSize,
                    styles.contentPadding,
                    { justifyContent: "space-between" },
                  ]}
                >
                  1. Goods consigned from (Exporter's business name, address,
                  country)
                </Text>
                <Text style={[styles.regularFontSize, { paddingLeft: 15 }]}>
                  ENFINY EXIM LLP
                </Text>
                <Text style={[styles.regularFontSize, { paddingLeft: 15 }]}>
                  PLOT NO-36, GROUND FLOOR, SHUBHAM INDUSTRY-2 SANIYA HEMED,
                  SURAT, GUJARAT (395006)
                </Text>
                <Text style={[styles.regularFontSize, { paddingLeft: 15 }]}>
                  INDIA
                </Text>
                <View
                  style={{
                    borderTop: 1,
                  }}
                >
                  <Text
                    style={[
                      styles.regularFontSize,
                      styles.contentPadding,
                      { justifyContent: "space-between" },
                    ]}
                  >
                    2. Goods consigned to (Consignee's name, address, country):
                  </Text>
                  <Text style={[styles.regularFontSize, { paddingLeft: 15 }]}>
                    DAC THANH FOOD COMPANY LIMITED
                  </Text>
                  <Text
                    style={[
                      styles.regularFontSize,
                      { justifyContent: "space-between", paddingLeft: 15 },
                    ]}
                  >
                    BINH TINH QUARTER, BINH PHU TOWNLET, CAI LAY DISTRICT, TIEN
                    GIANG PROVINCE, VIETNAM,TAX ID: 1201545414
                  </Text>
                  <Text style={[styles.regularFontSize, { paddingLeft: 15 }]}>
                    VIETNAM
                  </Text>
                </View>
              </View>
              <View style={[styles.halfWidthLeftSection]}>
                <Text style={[styles.regularFontSize, styles.contentPadding]}>
                  Reference No. EIC/2024/013/1026343A
                </Text>
                <Text
                  style={[
                    styles.boldText,
                    styles.contentPadding,
                    { textAlign: "center" },
                  ]}
                >
                  ASEAN-INDIA FREE TRADE AREA
                </Text>
                <Text
                  style={[
                    styles.boldText,
                    styles.contentPadding,
                    { textAlign: "center" },
                  ]}
                >
                  PREFERENTIAL TARIFF
                </Text>
                <Text
                  style={[
                    styles.boldText,
                    styles.contentPadding,
                    { textAlign: "center" },
                  ]}
                >
                  CERTIFICATE OF ORIGIN
                </Text>
                <Text
                  style={[
                    styles.regularFontSize,
                    styles.contentPadding,
                    { textAlign: "center" },
                  ]}
                >
                  (Combined Declaration and Certificate)
                </Text>
                <Text
                  style={[
                    styles.boldText,
                    styles.contentPadding,
                    { textAlign: "center" },
                  ]}
                >
                  FORM AI
                </Text>
                <Text
                  style={[
                    styles.regularFontSize,
                    styles.contentPadding,
                    {
                      textAlign: "center",
                      top: 5,
                    },
                  ]}
                >
                  Issued in:-
                  <Text
                    style={[
                      styles.boldText,
                      styles.contentPadding,
                      {
                        textAlign: "center",
                        textDecoration: "underline",
                        textDecorationStyle: "dotted",
                      },
                    ]}
                  >
                    INDIA
                  </Text>
                </Text>
                <Text
                  style={[
                    styles.regularFontSize,
                    styles.contentPadding,
                    {
                      top: 4,
                      textAlign: "center",
                    },
                  ]}
                >
                  (Country)
                </Text>
                <Text
                  style={[
                    styles.regularFontSize,
                    styles.contentPadding,
                    {
                      top: 4,
                      textAlign: "center",
                    },
                  ]}
                >
                  (See Notes Overleaf)
                </Text>
              </View>
            </View>

            <View style={[styles.sideBySideContainer, { height: "160px" }]}>
              <View style={[styles.halfWidthRightSection]}>
                <Text style={[styles.regularFontSize, styles.contentPadding]}>
                  3. Means of transport and route (as far as known)
                </Text>
                <Text style={[styles.regularFontSize, { paddingLeft: 15 }]}>
                  Departure date: 29-12-2024
                </Text>

                <Text style={[styles.regularFontSize, { paddingLeft: 15 }]}>
                  Vessel's name/Aircraft etc.: X PRESS KAILASH / 1889E
                </Text>
                <Text style={[styles.regularFontSize, { paddingLeft: 15 }]}>
                  Port of Discharge: CAT LAI, VIETNAM
                </Text>
              </View>
              <View style={[styles.halfWidthLeftSection]}>
                {" "}
                <Text style={[styles.regularFontSize, styles.contentPadding]}>
                  4. For Official Use
                </Text>
                <View style={styles.contentPadding}>
                  <View style={styles.row}>
                    <View style={styles.squareBullet}></View>
                    <Text
                      style={[
                        styles.regularFontSize,
                        {
                          paddingLeft: 35,
                          justifyContent: "space-between",
                          paddingRight: 10,
                        },
                      ]}
                    >
                      Preferential Tariff Treatment Given Under ASEAN-India Free
                      Trade Area Preferential Tariff
                    </Text>
                  </View>
                  <View style={{ flexDirection: "row-reverse" }}>
                    <Text
                      style={[
                        {
                          paddingLeft: 80,
                          borderTopWidth: 1,
                          paddingRight: 90,
                          marginRight: 20,
                          top: 18,
                        },
                      ]}
                    ></Text>
                  </View>
                  <View style={styles.row}>
                    <View style={[styles.squareBullet, { top: 22 }]}></View>
                    <Text
                      style={[
                        styles.regularFontSize,
                        {
                          paddingLeft: 35,
                          justifyContent: "space-between",
                          paddingRight: 10,
                          top: 22,
                        },
                      ]}
                    >
                      Preferential Tariff Treatment Not Given (Please state
                      reason/s)
                    </Text>
                  </View>
                  <View style={{ flexDirection: "row-reverse" }}>
                    <Text
                      style={[
                        {
                          paddingLeft: 80,
                          borderTopWidth: 1,
                          paddingRight: 90,
                          marginRight: 20,
                          top: 40,
                        },
                      ]}
                    ></Text>
                  </View>
                </View>
                <View
                  style={{
                    flexDirection: "row-reverse",
                    justifyContent: "flex-end",
                    width: "100%",
                  }}
                >
                  <Text
                    style={[
                      {
                        paddingLeft: 100,
                        borderTopWidth: 1,
                        paddingRight: 100,
                        marginLeft: 50,
                        top: 60,
                      },
                    ]}
                  ></Text>
                </View>
                <View style={styles.row}>
                  <Text
                    style={[
                      styles.regularFontSize,
                      {
                        paddingLeft: 35,
                        justifyContent: "space-between",
                        marginRight: 25,
                        top: 65,
                        textAlign: "center",
                      },
                    ]}
                  >
                    Signature of Authorised Signatory of the Importing Country
                  </Text>
                </View>
              </View>
            </View>

            <View>
              <View
                style={[
                  styles.tableContainer,
                  styles.mediumFontSize,
                  {
                    textAlign: "left",
                    height: 50,
                  },
                ]}
              >
                <View
                  style={[
                    styles.tableRow,
                    {
                      paddingLeft: 5,
                      paddingRight: 5,
                      flexDirection: "row",
                    },
                  ]}
                >
                  <Text
                    style={[
                      styles.contentPadding,
                      {
                        width: "10%",
                        justifyContent: "space-between",
                      },
                    ]}
                  >
                    5 . Item number
                  </Text>
                  <Text
                    style={[
                      styles.contentPadding,
                      {
                        borderLeft: 1,

                        paddingRight: 5,
                        width: "20%",
                        justifyContent: "space-between",
                      },
                    ]}
                  >
                    6. Marks and numbers of packages
                  </Text>
                  <Text
                    style={[
                      styles.contentPadding,
                      {
                        borderLeft: 1,

                        paddingRight: 5,
                        width: "45%",
                        justifyContent: "space-between",
                      },
                    ]}
                  >
                    7.Number and type of packages, description of goods
                    (including quantity where appropriate and HS number of the
                    importing country)
                  </Text>
                  <Text
                    style={[
                      styles.contentPadding,
                      {
                        borderLeft: 1,

                        paddingRight: 5,
                        width: "20%",
                        justifyContent: "space-between",
                      },
                    ]}
                  >
                    8. Origin criterion (see notes)
                  </Text>
                  <Text
                    style={[
                      styles.contentPadding,
                      {
                        borderLeft: 1,
                        paddingRight: 5,
                        width: "20%",
                        justifyContent: "space-between",
                      },
                    ]}
                  >
                    9. Gross weight or other quantity and value(FOB)
                  </Text>
                  <Text
                    style={[
                      styles.contentPadding,
                      {
                        borderLeft: 1,
                        paddingRight: 5,
                        width: "20%",
                        justifyContent: "space-between",
                      },
                    ]}
                  >
                    10. Number and date of invoices
                  </Text>
                </View>
              </View>
              {/* table data */}
              <View
                style={[
                  styles.tableContainer,
                  styles.mediumFontSize,
                  {
                    textAlign: "left",
                    height: 50,
                  },
                ]}
              >
                <View
                  style={[
                    styles.tableRow,
                    {
                      paddingLeft: 5,
                      paddingRight: 5,
                      flexDirection: "row",
                    },
                  ]}
                >
                  <Text
                    style={[
                      styles.contentPadding,
                      {
                        width: "10%",
                        justifyContent: "space-between",
                      },
                    ]}
                  >
                    1
                  </Text>
                  <Text
                    style={[
                      styles.contentPadding,
                      {
                        borderLeft: 1,

                        paddingRight: 5,
                        width: "20%",
                        justifyContent: "space-between",
                      },
                    ]}
                  >
                    AC THANH
                  </Text>
                  <Text
                    style={[
                      styles.contentPadding,
                      {
                        borderLeft: 1,

                        paddingRight: 5,
                        width: "45%",
                        justifyContent: "space-between",
                      },
                    ]}
                  >
                    TOTAL 2700 BAGS, INDIAN SONA MASOORI WHITE RICE (PACKING:IN
                    PP BAGS OF 50 KGS NET EACH) (NET WT: 135.000 MTS) HS CODE:
                    1006.30.90
                  </Text>
                  <Text
                    style={[
                      styles.contentPadding,
                      {
                        borderLeft: 1,

                        paddingRight: 5,
                        width: "20%",
                        justifyContent: "space-between",
                      },
                    ]}
                  >
                    "WO"
                  </Text>
                  <Text
                    style={[
                      styles.contentPadding,
                      {
                        borderLeft: 1,
                        paddingRight: 5,
                        width: "20%",
                        justifyContent: "space-between",
                      },
                    ]}
                  >
                    GORSS WT:135.27 MT , USD 695520.00
                  </Text>
                  <Text
                    style={[
                      styles.contentPadding,
                      {
                        borderLeft: 1,
                        paddingRight: 5,
                        width: "20%",
                        justifyContent: "space-between",
                      },
                    ]}
                  >
                    EXP/24-25/010 DT :17/12/2024
                  </Text>
                </View>
              </View>
            </View>

            <View style={[styles.sideBySideContainer, { height: 100 }]}></View>
            {/* 11 | 12 */}
            <View
              style={[
                styles.sideBySideContainer,
                { borderTop: 1, height: 155 },
              ]}
            >
              <View style={[styles.halfWidthRightSection]}>
                <Text style={[styles.regularFontSize, styles.contentPadding]}>
                  11. Declaration by the exporter
                </Text>
                <Text
                  style={[
                    styles.mediumFontSize,
                    styles.contentPadding,
                    {
                      paddingRight: 5,
                      justifyContent: "space-between",
                    },
                  ]}
                >
                  The undersigned hereby declares that the above details and
                  statement are correct; that all the goods were produced in
                </Text>
                <Text
                  style={[
                    styles.contentPadding,
                    styles.mediumFontSize,
                    {
                      paddingBottom: -50,
                      paddingRight: 5,
                      textAlign: "center",
                    },
                  ]}
                >
                  INDIA
                </Text>
                <Text
                  style={[
                    styles.boldText,
                    styles.contentPadding,
                    {
                      textAlign: "center",
                      paddingTop: -8,
                      paddingRight: 5,
                      justifyContent: "space-between",
                    },
                  ]}
                >
                  ....................................................................................................
                </Text>
                <Text
                  style={[
                    styles.contentPadding,
                    styles.mediumFontSize,
                    {
                      paddingTop: -10,
                      paddingRight: 5,
                      textAlign: "center",
                    },
                  ]}
                >
                  (Country)
                </Text>
                <Text
                  style={[
                    styles.mediumFontSize,
                    styles.contentPadding,
                    {
                      paddingRight: 5,
                      justifyContent: "space-between",
                    },
                  ]}
                >
                  and that they comply with the origin requirements specified
                  for these goods in the ASEAN-INDIA Free Trade Area
                  Preferential Tariff for the goods exported to
                </Text>
                <Text
                  style={[
                    styles.contentPadding,
                    styles.mediumFontSize,
                    {
                      paddingBottom: -50,
                      paddingRight: 5,
                      textAlign: "center",
                    },
                  ]}
                >
                  VITENAM
                </Text>
                <Text
                  style={[
                    styles.boldText,
                    styles.contentPadding,
                    {
                      textAlign: "center",
                      paddingTop: -8,
                      paddingRight: 5,
                      justifyContent: "space-between",
                    },
                  ]}
                >
                  ....................................................................................................
                </Text>
                <Text
                  style={[
                    styles.contentPadding,
                    styles.mediumFontSize,
                    {
                      paddingTop: -10,
                      paddingRight: 5,
                      textAlign: "center",
                    },
                  ]}
                >
                  (Importing Country)
                </Text>
                <Text
                  style={[
                    styles.contentPadding,
                    styles.mediumFontSize,
                    {
                      paddingRight: 5,
                      textAlign: "center",
                    },
                  ]}
                >
                  SURAT,GUJARTA,INDIA,04/01/2025
                </Text>
                <Text
                  style={[
                    styles.boldText,
                    styles.contentPadding,
                    {
                      paddingTop: -2,
                      textAlign: "center",
                      paddingRight: 5,
                    },
                  ]}
                >
                  ....................................................................................................
                </Text>
                <Text
                  style={[
                    styles.mediumFontSize,
                    styles.contentPadding,
                    {
                      textAlign: "center",
                      paddingRight: 5,
                      paddingTop: -3,
                      justifyContent: "space-between",
                    },
                  ]}
                >
                  Place and date, signature and Stamp of Certifying authority.
                </Text>
              </View>
              <View style={[styles.halfWidthLeftSection]}>
                <Text style={[styles.regularFontSize, styles.contentPadding]}>
                  12.Certification :
                </Text>
                <Text
                  style={[
                    styles.mediumFontSize,
                    styles.contentPadding,
                    {
                      paddingRight: 5,
                      justifyContent: "space-between",
                    },
                  ]}
                >
                  It is hereby certified,on the basis of control carried out,
                  that the declaration by the exporter is correct.
                </Text>
              </View>
            </View>

            {/* 13 */}
            <View>
              <Text style={[styles.mediumFontSize, { paddingLeft: 5 }]}>
                13. Where appropriate please tick:
              </Text>
              <View
                style={{
                  flexDirection: "row",
                  flexWrap: "wrap",
                  marginTop: 10,
                }}
              >
                {checkboxData.map((item, index) => (
                  <View
                    key={index}
                    style={{
                      flexDirection: "row",
                      alignItems: "center",
                      marginRight: 5,
                      marginLeft: 35,
                      paddingBottom: 8,
                    }}
                  >
                    <View style={styles.checkbox}>
                      {item.isChecked === true && (
                        <Text style={styles.checkMark}>✔</Text>
                      )}
                    </View>
                    <Text style={styles.mediumFontSize}>{item.label}</Text>
                  </View>
                ))}
              </View>
            </View>
          </View>
        </Page>

        {/* Page-2 */}
        <Page style={styles.page} size="A4">
          <Text style={[styles.contentPadding, styles.boldText]}>
            OVERLEAF NOTES
          </Text>
          <View style={[styles.sideBySideContainer]}>
            <View style={[styles.row]}>
              <Text
                style={[
                  styles.subContent,
                  styles.regularFontSize,
                  { marginRight: 10 },
                ]}
              >
                1.
              </Text>

              <Text
                style={[
                  styles.regularFontSize,
                  styles.subContent,
                  { marginLeft: 5, marginRight: 35 },
                ]}
              >
                Parties which accept this form for the purpose of preferential
                tariff treatment under the ASEAN-INDIA Free Trade Agreement
                (AIFTA):
              </Text>
            </View>
          </View>
          <View style={{ marginLeft: 40 }}>
            <View
              style={[
                styles.tableContainer,
                styles.mediumFontSize,
                {
                  top: 5,
                  textAlign: "left",
                  height: 20,
                  borderBottom: 0,
                  width: "100%",
                },
              ]}
            >
              <View
                style={[
                  styles.tableRow,
                  {
                    paddingLeft: 5,
                    paddingRight: 5,
                    flexDirection: "row",
                  },
                ]}
              >
                <Text
                  style={[
                    styles.regularFontSize,
                    {
                      paddingTop: 2,
                      width: "50%",
                      justifyContent: "space-between",
                    },
                  ]}
                >
                  BRUNEI DARUSSALAM
                </Text>
                <Text
                  style={[
                    styles.regularFontSize,
                    {
                      paddingRight: 5,
                      paddingTop: 2,
                      paddingLeft: 5,
                      width: "50%",
                      justifyContent: "space-between",
                    },
                  ]}
                >
                  CAMBODIA
                </Text>
                <Text
                  style={[
                    styles.regularFontSize,
                    {
                      paddingTop: 2,
                      paddingRight: 5,
                      width: "50%",
                      justifyContent: "space-between",
                    },
                  ]}
                >
                  INDONESIA
                </Text>
              </View>
            </View>

            <View
              style={[
                styles.tableContainer,
                styles.mediumFontSize,
                {
                  top: 5,
                  textAlign: "left",
                  height: 20,
                  borderBottom: 0,
                  width: "100%",
                },
              ]}
            >
              <View
                style={[
                  styles.tableRow,
                  {
                    paddingLeft: 5,
                    paddingRight: 5,
                    flexDirection: "row",
                  },
                ]}
              >
                <Text
                  style={[
                    styles.regularFontSize,
                    {
                      paddingTop: 2,
                      width: "50%",
                      justifyContent: "space-between",
                    },
                  ]}
                >
                  INDIA
                </Text>
                <Text
                  style={[
                    styles.regularFontSize,
                    {
                      paddingRight: 5,
                      paddingTop: 2,
                      paddingLeft: 5,
                      width: "50%",
                      justifyContent: "space-between",
                    },
                  ]}
                >
                  LAOS
                </Text>
                <Text
                  style={[
                    styles.regularFontSize,
                    {
                      paddingTop: 2,
                      paddingRight: 5,
                      width: "50%",
                      justifyContent: "space-between",
                    },
                  ]}
                >
                  MALASIYA
                </Text>
              </View>
            </View>

            <View
              style={[
                styles.tableContainer,
                styles.mediumFontSize,
                {
                  top: 5,
                  textAlign: "left",
                  height: 20,
                  borderBottom: 0,
                  width: "100%",
                },
              ]}
            >
              <View
                style={[
                  styles.tableRow,
                  {
                    paddingLeft: 5,
                    paddingRight: 5,
                    flexDirection: "row",
                  },
                ]}
              >
                <Text
                  style={[
                    styles.regularFontSize,
                    {
                      paddingTop: 2,
                      width: "50%",
                      justifyContent: "space-between",
                    },
                  ]}
                >
                  MYNAMMAR
                </Text>
                <Text
                  style={[
                    styles.regularFontSize,
                    {
                      paddingRight: 5,
                      paddingTop: 2,
                      paddingLeft: 5,
                      width: "50%",
                      justifyContent: "space-between",
                    },
                  ]}
                >
                  PHILLIPINES
                </Text>
                <Text
                  style={[
                    styles.regularFontSize,
                    {
                      paddingTop: 2,
                      paddingRight: 5,
                      width: "50%",
                      justifyContent: "space-between",
                    },
                  ]}
                >
                  SINGAPORE
                </Text>
              </View>
            </View>

            <View
              style={[
                styles.tableContainer,
                styles.mediumFontSize,
                {
                  top: 5,
                  textAlign: "left",
                  height: 20,
                  borderBottom: 0,
                  width: "100%",
                },
              ]}
            >
              <View
                style={[
                  styles.tableRow,
                  {
                    paddingLeft: 5,
                    paddingRight: 5,
                    flexDirection: "row",
                  },
                ]}
              >
                <Text
                  style={[
                    styles.regularFontSize,
                    {
                      paddingTop: 2,
                      width: "50%",
                      justifyContent: "space-between",
                    },
                  ]}
                >
                  THAILAND
                </Text>
                <Text
                  style={[
                    styles.regularFontSize,
                    {
                      paddingRight: 5,
                      paddingTop: 2,
                      paddingLeft: 5,
                      width: "100%",
                      justifyContent: "space-between",
                    },
                  ]}
                >
                  VITENAM
                </Text>
              </View>
            </View>
          </View>

          <View style={[styles.sideBySideContainer]}>
            <View style={[styles.row]}>
              <Text
                style={[
                  styles.subContent,
                  styles.regularFontSize,
                  { marginRight: 10 },
                ]}
              >
                2.
              </Text>
              <Text
                style={[
                  styles.regularFontSize,
                  styles.subContent,
                  { marginLeft: 5, marginRight: 35 },
                ]}
              >
                CONDITIONS: To enjoy preferential tariff under the AIFTA, goods
                sent to any Parties listed above:
              </Text>{" "}
            </View>
          </View>

          <View style={[styles.sideBySideContainer]}>
            <View style={[styles.row]}>
              <Text
                style={[
                  styles.subContent,
                  styles.regularFontSize,
                  { marginRight: 10, paddingLeft: 40 },
                ]}
              >
                (i)
              </Text>
              <Text
                style={[
                  styles.regularFontSize,
                  styles.subContent,
                  { marginLeft: 5, marginRight: 95 },
                ]}
              >
                must fall within a description of goods eligible for concessions
                in the Party of destination;
              </Text>{" "}
            </View>
          </View>
          <View style={[styles.sideBySideContainer]}>
            <View style={[styles.row]}>
              <Text
                style={[
                  styles.subContent,
                  styles.regularFontSize,
                  { marginRight: 10, paddingLeft: 40 },
                ]}
              >
                (ii)
              </Text>
              <Text
                style={[
                  styles.regularFontSize,
                  styles.subContent,
                  { marginLeft: 5, marginRight: 95 },
                ]}
              >
                must comply with the consignment conditions in accordance with
                Rule 8 of the AIFTA Rules of Origin; and
              </Text>{" "}
            </View>
          </View>
          <View style={[styles.sideBySideContainer]}>
            <View style={[styles.row]}>
              <Text
                style={[
                  styles.subContent,
                  styles.regularFontSize,
                  { marginRight: 10, paddingLeft: 37 },
                ]}
              >
                (iii)
              </Text>
              <Text
                style={[
                  styles.regularFontSize,
                  styles.subContent,
                  { marginLeft: 1, marginRight: 95 },
                ]}
              >
                must comply with the origin criteria in the AIFTA Rules of
                Origin.
              </Text>{" "}
            </View>
          </View>

          <View style={[styles.sideBySideContainer]}>
            <View style={[styles.row]}>
              <Text
                style={[
                  styles.subContent,
                  styles.regularFontSize,
                  { marginRight: 10 },
                ]}
              >
                3.
              </Text>
              <Text
                style={[
                  styles.regularFontSize,
                  styles.subContent,
                  { marginLeft: 5, marginRight: 35 },
                ]}
              >
                ORIGIN CRITERIA : For goods that meet the origin criteria, the
                exporter and/or producer must indicate in box 8 of this Form,
                the origin criteria met, in the manner shown in the following
                table:
              </Text>
            </View>
          </View>
          <View>
            <View
              style={[
                styles.tableContainer,
                styles.regularFontSize,
                {
                  textAlign: "left",
                  height: 33,
                  borderTop: 1,
                  marginTop: 5,
                  marginLeft: 30,
                  marginRight: 5,
                  borderRight: 1,
                  borderLeft: 1,
                },
              ]}
            >
              <View
                style={[
                  styles.tableRow,
                  {
                    paddingLeft: 5,
                    paddingRight: 5,
                    flexDirection: "row",
                  },
                ]}
              >
                <Text
                  style={[
                    {
                      paddingTop: 2,
                      borderRightWidth: 1,
                      width: "50%",
                      justifyContent: "space-between",
                    },
                  ]}
                >
                  Circumstances of production or manufacture in the first
                  country named in Box 11 of this form
                </Text>
                <Text
                  style={[
                    {
                      paddingRight: 5,
                      paddingTop: 2,
                      paddingLeft: 5,
                      width: "50%",
                      justifyContent: "space-between",
                    },
                  ]}
                >
                  Insert in Box 8
                </Text>
              </View>
            </View>
            <View
              style={[
                styles.tableContainer,
                styles.regularFontSize,
                {
                  textAlign: "left",
                  height: 35,
                  borderBottom: 1,
                  marginLeft: 30,
                  marginRight: 5,
                  borderRight: 1,
                  borderLeft: 1,
                },
              ]}
            >
              <View
                style={[
                  styles.tableRow,
                  {
                    paddingLeft: 5,
                    paddingRight: 5,
                    flexDirection: "row",
                  },
                ]}
              >
                <Text
                  style={[
                    {
                      paddingTop: 3,
                      borderRightWidth: 1,
                      width: "50%",
                      justifyContent: "space-between",
                    },
                  ]}
                >
                  (a) Goods wholly obtained or produced in the territory of the
                  exporting Party
                </Text>
                <Text
                  style={[
                    {
                      paddingRight: 5,
                      paddingTop: 2,
                      paddingLeft: 5,
                      width: "50%",
                      justifyContent: "space-between",
                    },
                  ]}
                >
                  "WO"
                </Text>
              </View>
            </View>

            <View
              style={[
                styles.tableContainer,
                styles.regularFontSize,
                {
                  textAlign: "left",
                  height: 35,
                  borderBottom: 1,
                  marginLeft: 30,
                  marginRight: 5,
                  borderRight: 1,
                  borderLeft: 1,
                },
              ]}
            >
              <View
                style={[
                  styles.tableRow,
                  {
                    paddingLeft: 5,
                    paddingRight: 5,
                    flexDirection: "row",
                  },
                ]}
              >
                <Text
                  style={[
                    {
                      paddingTop: 3,
                      borderRightWidth: 1,
                      width: "50%",
                      justifyContent: "space-between",
                    },
                  ]}
                >
                  (b) Goods satisfying Rule 4 (Not Wholly Produced or Obtained
                  Products) of the AIFTA Rules of Origin
                </Text>
                <Text
                  style={[
                    {
                      paddingRight: 5,
                      paddingTop: 2,
                      paddingLeft: 5,
                      width: "50%",
                      justifyContent: "space-between",
                    },
                  ]}
                >
                  "RVC [ ]% + CTSH”
                </Text>
              </View>
            </View>

            <View
              style={[
                styles.tableContainer,
                styles.regularFontSize,
                {
                  textAlign: "left",
                  height: 35,
                  borderBottom: 1,
                  marginLeft: 30,
                  marginRight: 5,
                  borderRight: 1,
                  borderLeft: 1,
                },
              ]}
            >
              <View
                style={[
                  styles.tableRow,
                  {
                    paddingLeft: 5,
                    paddingRight: 5,
                    flexDirection: "row",
                  },
                ]}
              >
                <Text
                  style={[
                    {
                      paddingTop: 3,
                      borderRightWidth: 1,
                      width: "50%",
                      justifyContent: "space-between",
                    },
                  ]}
                >
                  (c) Goods satisfying Rule 6 (Product Specific Rules) of the
                  AIFTA Rules of Origin
                </Text>
                <Text
                  style={[
                    {
                      paddingRight: 5,
                      paddingTop: 2,
                      paddingLeft: 5,
                      width: "50%",
                      justifyContent: "space-between",
                    },
                  ]}
                >
                  Appropriate qualifying criteria
                </Text>
              </View>
            </View>
          </View>

          <View style={[styles.sideBySideContainer]}>
            <View style={[styles.row]}>
              <Text
                style={[
                  styles.subContent,
                  styles.regularFontSize,
                  { marginRight: 10 },
                ]}
              >
                4.
              </Text>

              <Text
                style={[
                  styles.regularFontSize,
                  styles.subContent,
                  { marginLeft: 5, marginRight: 35 },
                ]}
              >
                EACH ARTICLE MUST QUALIFY : It should be noted that all the
                goods in a consignment must qualify separately in their own
                right. This is of particular relevance when similar articles of
                different sizes or spare parts are sent.
              </Text>
            </View>
          </View>

          <View style={[styles.sideBySideContainer]}>
            <View style={[styles.row]}>
              <Text
                style={[
                  styles.subContent,
                  styles.regularFontSize,
                  { marginRight: 10 },
                ]}
              >
                5.
              </Text>

              <Text
                style={[
                  styles.regularFontSize,
                  styles.subContent,
                  { marginLeft: 5, marginRight: 35 },
                ]}
              >
                DESCRIPTION OF GOODS : The description of goods must be
                sufficiently detailed to enable the goods to be identified by
                the Customs Officers examining them. Name of manufacturer, any
                trade mark shall also be specified.
              </Text>
            </View>
          </View>

          <View style={[styles.sideBySideContainer]}>
            <View style={[styles.row]}>
              <Text
                style={[
                  styles.subContent,
                  styles.regularFontSize,
                  { marginRight: 10 },
                ]}
              >
                6.
              </Text>

              <Text
                style={[
                  styles.regularFontSize,
                  styles.subContent,
                  { marginLeft: 5, marginRight: 35 },
                ]}
              >
                HARMONIZED SYSTEM NUMBER : The Harmonized System number shall be
                that of importing Party.
              </Text>
            </View>
          </View>

          <View style={[styles.sideBySideContainer]}>
            <View style={[styles.row]}>
              <Text
                style={[
                  styles.subContent,
                  styles.regularFontSize,
                  { marginRight: 10 },
                ]}
              >
                7.
              </Text>

              <Text
                style={[
                  styles.regularFontSize,
                  styles.subContent,
                  { marginLeft: 5, marginRight: 35 },
                ]}
              >
                EXPORTER : The term “Exporter” in Box 11 may include the
                manufacturer or the producer.
              </Text>
            </View>
          </View>

          <View style={[styles.sideBySideContainer]}>
            <View style={[styles.row]}>
              <Text
                style={[
                  styles.subContent,
                  styles.regularFontSize,
                  { marginRight: 10 },
                ]}
              >
                8.
              </Text>

              <Text
                style={[
                  styles.regularFontSize,
                  styles.subContent,
                  { marginLeft: 5, marginRight: 35 },
                ]}
              >
                FOR OFFICIAL USE : The Customs Authority of the importing Party
                must indicate ( ) in the relevant boxes in column 4 whether or
                not preferential tariff is accorded.
              </Text>
            </View>
          </View>

          <View style={[styles.sideBySideContainer]}>
            <View style={[styles.row]}>
              <Text
                style={[
                  styles.subContent,
                  styles.regularFontSize,
                  { marginRight: 10 },
                ]}
              >
                9.
              </Text>

              <Text
                style={[
                  styles.regularFontSize,
                  styles.subContent,
                  { marginLeft: 4, marginRight: 35 },
                ]}
              >
                {" "}
                THIRD COUNTRY INVOICING : In cases where invoices are issued by
                a third country, “Third Country Invoicing” in Box 13 should be
                ticked ( ) and such information as name and country of the
                company issuing the invoice shall be indicated in Box 7.
              </Text>
            </View>
          </View>

          <View style={[styles.sideBySideContainer]}>
            <View style={[styles.row]}>
              <Text
                style={[
                  styles.subContent,
                  styles.regularFontSize,
                  { marginRight: 10 },
                ]}
              >
                10.
              </Text>

              <Text
                style={[
                  styles.regularFontSize,
                  styles.subContent,
                  { marginLeft: 1, marginRight: 35 },
                ]}
              >
                EXHIBITIONS : In cases where goods are sent from the territory
                of the exporting Party for exhibition in another country and
                sold during or after the exhibition for importation into the
                territory of a Party, in accordance with Article 21 of the
                Operational Certification Procedures, “Exhibitions” in Box 13
                should be ticked ( ) and the name and address of the exhibition
                indicated in Box 2
              </Text>
            </View>
          </View>

          <View style={[styles.sideBySideContainer]}>
            <View style={[styles.row]}>
              <Text
                style={[
                  styles.subContent,
                  styles.regularFontSize,
                  { marginRight: 10 },
                ]}
              >
                11.
              </Text>

              <Text
                style={[
                  styles.regularFontSize,
                  styles.subContent,
                  { marginLeft: 1, marginRight: 35 },
                ]}
              >
                BACK-TO-BACK CERTIFICATE OF ORIGIN : In cases of Back-to-Back
                CO, in accordance with Article 11 of the Operational
                Certification Procedures, “Back-to-Back CO” in Box13 should be
                ticked ( ). The name of original exporting Party to be indicated
                in Box 11 and the date of the issuance of CO and the reference
                number will be indicated in Box 7.
              </Text>
            </View>
          </View>
        </Page>

        {/* Page-3 */}
        <Page style={styles.page} size="A4">
          <View>
            <Text style={[styles.boldText, { textAlign: "center" }]}>
              (ORIGINAL)
            </Text>
          </View>
          <View style={styles.fullpage}>
            <View style={[styles.sideBySideContainer, { height: "150px" }]}>
              <View style={[styles.halfWidthRightSection]}>
                <Text
                  style={[
                    styles.regularFontSize,
                    styles.contentPadding,
                    { justifyContent: "space-between" },
                  ]}
                >
                  1. Goods consigned from (Exporter's business name, address,
                  country)
                </Text>
                <Text style={[styles.regularFontSize, { paddingLeft: 15 }]}>
                  ENFINY EXIM LLP
                </Text>
                <Text style={[styles.regularFontSize, { paddingLeft: 15 }]}>
                  PLOT NO-36, GROUND FLOOR, SHUBHAM INDUSTRY-2 SANIYA HEMED,
                  SURAT, GUJARAT (395006)
                </Text>
                <Text style={[styles.regularFontSize, { paddingLeft: 15 }]}>
                  INDIA
                </Text>
                <View
                  style={{
                    borderTop: 1,
                  }}
                >
                  <Text
                    style={[
                      styles.regularFontSize,
                      styles.contentPadding,
                      { justifyContent: "space-between" },
                    ]}
                  >
                    2. Goods consigned to (Consignee's name, address, country):
                  </Text>
                  <Text style={[styles.regularFontSize, { paddingLeft: 15 }]}>
                    DAC THANH FOOD COMPANY LIMITED
                  </Text>
                  <Text
                    style={[
                      styles.regularFontSize,
                      { justifyContent: "space-between", paddingLeft: 15 },
                    ]}
                  >
                    BINH TINH QUARTER, BINH PHU TOWNLET, CAI LAY DISTRICT, TIEN
                    GIANG PROVINCE, VIETNAM,TAX ID: 1201545414
                  </Text>
                  <Text style={[styles.regularFontSize, { paddingLeft: 15 }]}>
                    VIETNAM
                  </Text>
                </View>
              </View>
              <View style={[styles.halfWidthLeftSection]}>
                <Text style={[styles.regularFontSize, styles.contentPadding]}>
                  Reference No. EIC/2024/013/1026343A
                </Text>
                <Text
                  style={[
                    styles.boldText,
                    styles.contentPadding,
                    { textAlign: "center" },
                  ]}
                >
                  ASEAN-INDIA FREE TRADE AREA
                </Text>
                <Text
                  style={[
                    styles.boldText,
                    styles.contentPadding,
                    { textAlign: "center" },
                  ]}
                >
                  PREFERENTIAL TARIFF
                </Text>
                <Text
                  style={[
                    styles.boldText,
                    styles.contentPadding,
                    { textAlign: "center" },
                  ]}
                >
                  CERTIFICATE OF ORIGIN
                </Text>
                <Text
                  style={[
                    styles.regularFontSize,
                    styles.contentPadding,
                    { textAlign: "center" },
                  ]}
                >
                  (Combined Declaration and Certificate)
                </Text>
                <Text
                  style={[
                    styles.boldText,
                    styles.contentPadding,
                    { textAlign: "center" },
                  ]}
                >
                  FORM AI
                </Text>
                <Text
                  style={[
                    styles.regularFontSize,
                    styles.contentPadding,
                    {
                      textAlign: "center",
                      top: 5,
                    },
                  ]}
                >
                  Issued in:-
                  <Text
                    style={[
                      styles.boldText,
                      styles.contentPadding,
                      {
                        textAlign: "center",
                        textDecoration: "underline",
                        textDecorationStyle: "dotted",
                      },
                    ]}
                  >
                    INDIA
                  </Text>
                </Text>
                <Text
                  style={[
                    styles.regularFontSize,
                    styles.contentPadding,
                    {
                      top: 4,
                      textAlign: "center",
                    },
                  ]}
                >
                  (Country)
                </Text>
                <Text
                  style={[
                    styles.regularFontSize,
                    styles.contentPadding,
                    {
                      top: 4,
                      textAlign: "center",
                    },
                  ]}
                >
                  (See Notes Overleaf)
                </Text>
              </View>
            </View>

            <View style={[styles.sideBySideContainer, { height: "160px" }]}>
              <View style={[styles.halfWidthRightSection]}>
                <Text style={[styles.regularFontSize, styles.contentPadding]}>
                  3. Means of transport and route (as far as known)
                </Text>
                <Text style={[styles.regularFontSize, { paddingLeft: 15 }]}>
                  Departure date: 29-12-2024
                </Text>

                <Text style={[styles.regularFontSize, { paddingLeft: 15 }]}>
                  Vessel's name/Aircraft etc.: X PRESS KAILASH / 1889E
                </Text>
                <Text style={[styles.regularFontSize, { paddingLeft: 15 }]}>
                  Port of Discharge: CAT LAI, VIETNAM
                </Text>
              </View>
              <View style={[styles.halfWidthLeftSection]}>
                {" "}
                <Text style={[styles.regularFontSize, styles.contentPadding]}>
                  4. For Official Use
                </Text>
                <View style={styles.contentPadding}>
                  <View style={styles.row}>
                    <View style={styles.squareBullet}></View>
                    <Text
                      style={[
                        styles.regularFontSize,
                        {
                          paddingLeft: 35,
                          justifyContent: "space-between",
                          paddingRight: 10,
                        },
                      ]}
                    >
                      Preferential Tariff Treatment Given Under ASEAN-India Free
                      Trade Area Preferential Tariff
                    </Text>
                  </View>
                  <View style={{ flexDirection: "row-reverse" }}>
                    <Text
                      style={[
                        {
                          paddingLeft: 80,
                          borderTopWidth: 1,
                          paddingRight: 90,
                          marginRight: 20,
                          top: 18,
                        },
                      ]}
                    ></Text>
                  </View>
                  <View style={styles.row}>
                    <View style={[styles.squareBullet, { top: 22 }]}></View>
                    <Text
                      style={[
                        styles.regularFontSize,
                        {
                          paddingLeft: 35,
                          justifyContent: "space-between",
                          paddingRight: 10,
                          top: 22,
                        },
                      ]}
                    >
                      Preferential Tariff Treatment Not Given (Please state
                      reason/s)
                    </Text>
                  </View>
                  <View style={{ flexDirection: "row-reverse" }}>
                    <Text
                      style={[
                        {
                          paddingLeft: 80,
                          borderTopWidth: 1,
                          paddingRight: 90,
                          marginRight: 20,
                          top: 40,
                        },
                      ]}
                    ></Text>
                  </View>
                </View>
                <View
                  style={{
                    flexDirection: "row-reverse",
                    justifyContent: "flex-end",
                    width: "100%",
                  }}
                >
                  <Text
                    style={[
                      {
                        paddingLeft: 100,
                        borderTopWidth: 1,
                        paddingRight: 100,
                        marginLeft: 50,
                        top: 60,
                      },
                    ]}
                  ></Text>
                </View>
                <View style={styles.row}>
                  <Text
                    style={[
                      styles.regularFontSize,
                      {
                        paddingLeft: 35,
                        justifyContent: "space-between",
                        marginRight: 25,
                        top: 65,
                        textAlign: "center",
                      },
                    ]}
                  >
                    Signature of Authorised Signatory of the Importing Country
                  </Text>
                </View>
              </View>
            </View>

            <View>
              <View
                style={[
                  styles.tableContainer,
                  styles.mediumFontSize,
                  {
                    textAlign: "left",
                    height: 50,
                  },
                ]}
              >
                <View
                  style={[
                    styles.tableRow,
                    {
                      paddingLeft: 5,
                      paddingRight: 5,
                      flexDirection: "row",
                    },
                  ]}
                >
                  <Text
                    style={[
                      styles.contentPadding,
                      {
                        width: "10%",
                        justifyContent: "space-between",
                      },
                    ]}
                  >
                    5 . Item number
                  </Text>
                  <Text
                    style={[
                      styles.contentPadding,
                      {
                        borderLeft: 1,

                        paddingRight: 5,
                        width: "20%",
                        justifyContent: "space-between",
                      },
                    ]}
                  >
                    6. Marks and numbers of packages
                  </Text>
                  <Text
                    style={[
                      styles.contentPadding,
                      {
                        borderLeft: 1,

                        paddingRight: 5,
                        width: "45%",
                        justifyContent: "space-between",
                      },
                    ]}
                  >
                    7.Number and type of packages, description of goods
                    (including quantity where appropriate and HS number of the
                    importing country)
                  </Text>
                  <Text
                    style={[
                      styles.contentPadding,
                      {
                        borderLeft: 1,

                        paddingRight: 5,
                        width: "20%",
                        justifyContent: "space-between",
                      },
                    ]}
                  >
                    8. Origin criterion (see notes)
                  </Text>
                  <Text
                    style={[
                      styles.contentPadding,
                      {
                        borderLeft: 1,
                        paddingRight: 5,
                        width: "20%",
                        justifyContent: "space-between",
                      },
                    ]}
                  >
                    9. Gross weight or other quantity and value(FOB)
                  </Text>
                  <Text
                    style={[
                      styles.contentPadding,
                      {
                        borderLeft: 1,
                        paddingRight: 5,
                        width: "20%",
                        justifyContent: "space-between",
                      },
                    ]}
                  >
                    10. Number and date of invoices
                  </Text>
                </View>
              </View>
              {/* table data */}
              <View
                style={[
                  styles.tableContainer,
                  styles.mediumFontSize,
                  {
                    textAlign: "left",
                    height: 50,
                  },
                ]}
              >
                <View
                  style={[
                    styles.tableRow,
                    {
                      paddingLeft: 5,
                      paddingRight: 5,
                      flexDirection: "row",
                    },
                  ]}
                >
                  <Text
                    style={[
                      styles.contentPadding,
                      {
                        width: "10%",
                        justifyContent: "space-between",
                      },
                    ]}
                  >
                    1
                  </Text>
                  <Text
                    style={[
                      styles.contentPadding,
                      {
                        borderLeft: 1,

                        paddingRight: 5,
                        width: "20%",
                        justifyContent: "space-between",
                      },
                    ]}
                  >
                    AC THANH
                  </Text>
                  <Text
                    style={[
                      styles.contentPadding,
                      {
                        borderLeft: 1,

                        paddingRight: 5,
                        width: "45%",
                        justifyContent: "space-between",
                      },
                    ]}
                  >
                    TOTAL 2700 BAGS, INDIAN SONA MASOORI WHITE RICE (PACKING:IN
                    PP BAGS OF 50 KGS NET EACH) (NET WT: 135.000 MTS) HS CODE:
                    1006.30.90
                  </Text>
                  <Text
                    style={[
                      styles.contentPadding,
                      {
                        borderLeft: 1,

                        paddingRight: 5,
                        width: "20%",
                        justifyContent: "space-between",
                      },
                    ]}
                  >
                    "WO"
                  </Text>
                  <Text
                    style={[
                      styles.contentPadding,
                      {
                        borderLeft: 1,
                        paddingRight: 5,
                        width: "20%",
                        justifyContent: "space-between",
                      },
                    ]}
                  >
                    GORSS WT:135.27 MT , USD 695520.00
                  </Text>
                  <Text
                    style={[
                      styles.contentPadding,
                      {
                        borderLeft: 1,
                        paddingRight: 5,
                        width: "20%",
                        justifyContent: "space-between",
                      },
                    ]}
                  >
                    EXP/24-25/010 DT :17/12/2024
                  </Text>
                </View>
              </View>
            </View>

            <View style={[styles.sideBySideContainer, { height: 100 }]}></View>
            {/* 11 | 12 */}
            <View
              style={[
                styles.sideBySideContainer,
                { borderTop: 1, height: 155 },
              ]}
            >
              <View style={[styles.halfWidthRightSection]}>
                <Text style={[styles.regularFontSize, styles.contentPadding]}>
                  11. Declaration by the exporter
                </Text>
                <Text
                  style={[
                    styles.mediumFontSize,
                    styles.contentPadding,
                    {
                      paddingRight: 5,
                      justifyContent: "space-between",
                    },
                  ]}
                >
                  The undersigned hereby declares that the above details and
                  statement are correct; that all the goods were produced in
                </Text>
                <Text
                  style={[
                    styles.contentPadding,
                    styles.mediumFontSize,
                    {
                      paddingBottom: -50,
                      paddingRight: 5,
                      textAlign: "center",
                    },
                  ]}
                >
                  INDIA
                </Text>
                <Text
                  style={[
                    styles.boldText,
                    styles.contentPadding,
                    {
                      textAlign: "center",
                      paddingTop: -8,
                      paddingRight: 5,
                      justifyContent: "space-between",
                    },
                  ]}
                >
                  ....................................................................................................
                </Text>
                <Text
                  style={[
                    styles.contentPadding,
                    styles.mediumFontSize,
                    {
                      paddingTop: -10,
                      paddingRight: 5,
                      textAlign: "center",
                    },
                  ]}
                >
                  (Country)
                </Text>
                <Text
                  style={[
                    styles.mediumFontSize,
                    styles.contentPadding,
                    {
                      paddingRight: 5,
                      justifyContent: "space-between",
                    },
                  ]}
                >
                  and that they comply with the origin requirements specified
                  for these goods in the ASEAN-INDIA Free Trade Area
                  Preferential Tariff for the goods exported to
                </Text>
                <Text
                  style={[
                    styles.contentPadding,
                    styles.mediumFontSize,
                    {
                      paddingBottom: -50,
                      paddingRight: 5,
                      textAlign: "center",
                    },
                  ]}
                >
                  VITENAM
                </Text>
                <Text
                  style={[
                    styles.boldText,
                    styles.contentPadding,
                    {
                      textAlign: "center",
                      paddingTop: -8,
                      paddingRight: 5,
                      justifyContent: "space-between",
                    },
                  ]}
                >
                  ....................................................................................................
                </Text>
                <Text
                  style={[
                    styles.contentPadding,
                    styles.mediumFontSize,
                    {
                      paddingTop: -10,
                      paddingRight: 5,
                      textAlign: "center",
                    },
                  ]}
                >
                  (Importing Country)
                </Text>
                <Text
                  style={[
                    styles.contentPadding,
                    styles.mediumFontSize,
                    {
                      paddingRight: 5,
                      textAlign: "center",
                    },
                  ]}
                >
                  SURAT,GUJARTA,INDIA,04/01/2025
                </Text>
                <Text
                  style={[
                    styles.boldText,
                    styles.contentPadding,
                    {
                      paddingTop: -2,
                      textAlign: "center",
                      paddingRight: 5,
                    },
                  ]}
                >
                  ....................................................................................................
                </Text>
                <Text
                  style={[
                    styles.mediumFontSize,
                    styles.contentPadding,
                    {
                      textAlign: "center",
                      paddingRight: 5,
                      paddingTop: -3,
                      justifyContent: "space-between",
                    },
                  ]}
                >
                  Place and date, signature and Stamp of Certifying authority.
                </Text>
              </View>
              <View style={[styles.halfWidthLeftSection]}>
                <Text style={[styles.regularFontSize, styles.contentPadding]}>
                  12.Certification :
                </Text>
                <Text
                  style={[
                    styles.mediumFontSize,
                    styles.contentPadding,
                    {
                      paddingRight: 5,
                      justifyContent: "space-between",
                    },
                  ]}
                >
                  It is hereby certified,on the basis of control carried out,
                  that the declaration by the exporter is correct.
                </Text>
              </View>
            </View>

            {/* 13 */}
            <View>
              <Text style={[styles.mediumFontSize, { paddingLeft: 5 }]}>
                13. Where appropriate please tick:
              </Text>
              <View
                style={{
                  flexDirection: "row",
                  flexWrap: "wrap",
                  marginTop: 10,
                }}
              >
                {checkboxData.map((item, index) => (
                  <View
                    key={index}
                    style={{
                      flexDirection: "row",
                      alignItems: "center",
                      marginRight: 5,
                      marginLeft: 35,
                      paddingBottom: 8,
                    }}
                  >
                    <View style={styles.checkbox}>
                      {item.isChecked === true && (
                        <Text style={styles.checkMark}>✔</Text>
                      )}
                    </View>
                    <Text style={styles.mediumFontSize}>{item.label}</Text>
                  </View>
                ))}
              </View>
            </View>
          </View>
        </Page>

        {/* Page-4 */}
        <Page style={styles.page} size="A4">
          <Text style={[styles.contentPadding, styles.boldText]}>
            OVERLEAF NOTES
          </Text>
          <View style={[styles.sideBySideContainer]}>
            <View style={[styles.row]}>
              <Text
                style={[
                  styles.subContent,
                  styles.regularFontSize,
                  { marginRight: 10 },
                ]}
              >
                1.
              </Text>

              <Text
                style={[
                  styles.regularFontSize,
                  styles.subContent,
                  { marginLeft: 5, marginRight: 35 },
                ]}
              >
                Parties which accept this form for the purpose of preferential
                tariff treatment under the ASEAN-INDIA Free Trade Agreement
                (AIFTA):
              </Text>
            </View>
          </View>
          <View style={{ marginLeft: 40 }}>
            <View
              style={[
                styles.tableContainer,
                styles.mediumFontSize,
                {
                  top: 5,
                  textAlign: "left",
                  height: 20,
                  borderBottom: 0,
                  width: "100%",
                },
              ]}
            >
              <View
                style={[
                  styles.tableRow,
                  {
                    paddingLeft: 5,
                    paddingRight: 5,
                    flexDirection: "row",
                  },
                ]}
              >
                <Text
                  style={[
                    styles.regularFontSize,
                    {
                      paddingTop: 2,
                      width: "50%",
                      justifyContent: "space-between",
                    },
                  ]}
                >
                  BRUNEI DARUSSALAM
                </Text>
                <Text
                  style={[
                    styles.regularFontSize,
                    {
                      paddingRight: 5,
                      paddingTop: 2,
                      paddingLeft: 5,
                      width: "50%",
                      justifyContent: "space-between",
                    },
                  ]}
                >
                  CAMBODIA
                </Text>
                <Text
                  style={[
                    styles.regularFontSize,
                    {
                      paddingTop: 2,
                      paddingRight: 5,
                      width: "50%",
                      justifyContent: "space-between",
                    },
                  ]}
                >
                  INDONESIA
                </Text>
              </View>
            </View>

            <View
              style={[
                styles.tableContainer,
                styles.mediumFontSize,
                {
                  top: 5,
                  textAlign: "left",
                  height: 20,
                  borderBottom: 0,
                  width: "100%",
                },
              ]}
            >
              <View
                style={[
                  styles.tableRow,
                  {
                    paddingLeft: 5,
                    paddingRight: 5,
                    flexDirection: "row",
                  },
                ]}
              >
                <Text
                  style={[
                    styles.regularFontSize,
                    {
                      paddingTop: 2,
                      width: "50%",
                      justifyContent: "space-between",
                    },
                  ]}
                >
                  INDIA
                </Text>
                <Text
                  style={[
                    styles.regularFontSize,
                    {
                      paddingRight: 5,
                      paddingTop: 2,
                      paddingLeft: 5,
                      width: "50%",
                      justifyContent: "space-between",
                    },
                  ]}
                >
                  LAOS
                </Text>
                <Text
                  style={[
                    styles.regularFontSize,
                    {
                      paddingTop: 2,
                      paddingRight: 5,
                      width: "50%",
                      justifyContent: "space-between",
                    },
                  ]}
                >
                  MALASIYA
                </Text>
              </View>
            </View>

            <View
              style={[
                styles.tableContainer,
                styles.mediumFontSize,
                {
                  top: 5,
                  textAlign: "left",
                  height: 20,
                  borderBottom: 0,
                  width: "100%",
                },
              ]}
            >
              <View
                style={[
                  styles.tableRow,
                  {
                    paddingLeft: 5,
                    paddingRight: 5,
                    flexDirection: "row",
                  },
                ]}
              >
                <Text
                  style={[
                    styles.regularFontSize,
                    {
                      paddingTop: 2,
                      width: "50%",
                      justifyContent: "space-between",
                    },
                  ]}
                >
                  MYNAMMAR
                </Text>
                <Text
                  style={[
                    styles.regularFontSize,
                    {
                      paddingRight: 5,
                      paddingTop: 2,
                      paddingLeft: 5,
                      width: "50%",
                      justifyContent: "space-between",
                    },
                  ]}
                >
                  PHILLIPINES
                </Text>
                <Text
                  style={[
                    styles.regularFontSize,
                    {
                      paddingTop: 2,
                      paddingRight: 5,
                      width: "50%",
                      justifyContent: "space-between",
                    },
                  ]}
                >
                  SINGAPORE
                </Text>
              </View>
            </View>

            <View
              style={[
                styles.tableContainer,
                styles.mediumFontSize,
                {
                  top: 5,
                  textAlign: "left",
                  height: 20,
                  borderBottom: 0,
                  width: "100%",
                },
              ]}
            >
              <View
                style={[
                  styles.tableRow,
                  {
                    paddingLeft: 5,
                    paddingRight: 5,
                    flexDirection: "row",
                  },
                ]}
              >
                <Text
                  style={[
                    styles.regularFontSize,
                    {
                      paddingTop: 2,
                      width: "50%",
                      justifyContent: "space-between",
                    },
                  ]}
                >
                  THAILAND
                </Text>
                <Text
                  style={[
                    styles.regularFontSize,
                    {
                      paddingRight: 5,
                      paddingTop: 2,
                      paddingLeft: 5,
                      width: "100%",
                      justifyContent: "space-between",
                    },
                  ]}
                >
                  VITENAM
                </Text>
              </View>
            </View>
          </View>

          <View style={[styles.sideBySideContainer]}>
            <View style={[styles.row]}>
              <Text
                style={[
                  styles.subContent,
                  styles.regularFontSize,
                  { marginRight: 10 },
                ]}
              >
                2.
              </Text>
              <Text
                style={[
                  styles.regularFontSize,
                  styles.subContent,
                  { marginLeft: 5, marginRight: 35 },
                ]}
              >
                CONDITIONS: To enjoy preferential tariff under the AIFTA, goods
                sent to any Parties listed above:
              </Text>{" "}
            </View>
          </View>

          <View style={[styles.sideBySideContainer]}>
            <View style={[styles.row]}>
              <Text
                style={[
                  styles.subContent,
                  styles.regularFontSize,
                  { marginRight: 10, paddingLeft: 40 },
                ]}
              >
                (i)
              </Text>
              <Text
                style={[
                  styles.regularFontSize,
                  styles.subContent,
                  { marginLeft: 5, marginRight: 95 },
                ]}
              >
                must fall within a description of goods eligible for concessions
                in the Party of destination;
              </Text>{" "}
            </View>
          </View>
          <View style={[styles.sideBySideContainer]}>
            <View style={[styles.row]}>
              <Text
                style={[
                  styles.subContent,
                  styles.regularFontSize,
                  { marginRight: 10, paddingLeft: 40 },
                ]}
              >
                (ii)
              </Text>
              <Text
                style={[
                  styles.regularFontSize,
                  styles.subContent,
                  { marginLeft: 5, marginRight: 95 },
                ]}
              >
                must comply with the consignment conditions in accordance with
                Rule 8 of the AIFTA Rules of Origin; and
              </Text>{" "}
            </View>
          </View>
          <View style={[styles.sideBySideContainer]}>
            <View style={[styles.row]}>
              <Text
                style={[
                  styles.subContent,
                  styles.regularFontSize,
                  { marginRight: 10, paddingLeft: 37 },
                ]}
              >
                (iii)
              </Text>
              <Text
                style={[
                  styles.regularFontSize,
                  styles.subContent,
                  { marginLeft: 1, marginRight: 95 },
                ]}
              >
                must comply with the origin criteria in the AIFTA Rules of
                Origin.
              </Text>{" "}
            </View>
          </View>

          <View style={[styles.sideBySideContainer]}>
            <View style={[styles.row]}>
              <Text
                style={[
                  styles.subContent,
                  styles.regularFontSize,
                  { marginRight: 10 },
                ]}
              >
                3.
              </Text>
              <Text
                style={[
                  styles.regularFontSize,
                  styles.subContent,
                  { marginLeft: 5, marginRight: 35 },
                ]}
              >
                ORIGIN CRITERIA : For goods that meet the origin criteria, the
                exporter and/or producer must indicate in box 8 of this Form,
                the origin criteria met, in the manner shown in the following
                table:
              </Text>
            </View>
          </View>
          <View>
            <View
              style={[
                styles.tableContainer,
                styles.regularFontSize,
                {
                  textAlign: "left",
                  height: 33,
                  borderTop: 1,
                  marginTop: 5,
                  marginLeft: 30,
                  marginRight: 5,
                  borderRight: 1,
                  borderLeft: 1,
                },
              ]}
            >
              <View
                style={[
                  styles.tableRow,
                  {
                    paddingLeft: 5,
                    paddingRight: 5,
                    flexDirection: "row",
                  },
                ]}
              >
                <Text
                  style={[
                    {
                      paddingTop: 2,
                      borderRightWidth: 1,
                      width: "50%",
                      justifyContent: "space-between",
                    },
                  ]}
                >
                  Circumstances of production or manufacture in the first
                  country named in Box 11 of this form
                </Text>
                <Text
                  style={[
                    {
                      paddingRight: 5,
                      paddingTop: 2,
                      paddingLeft: 5,
                      width: "50%",
                      justifyContent: "space-between",
                    },
                  ]}
                >
                  Insert in Box 8
                </Text>
              </View>
            </View>
            <View
              style={[
                styles.tableContainer,
                styles.regularFontSize,
                {
                  textAlign: "left",
                  height: 35,
                  borderBottom: 1,
                  marginLeft: 30,
                  marginRight: 5,
                  borderRight: 1,
                  borderLeft: 1,
                },
              ]}
            >
              <View
                style={[
                  styles.tableRow,
                  {
                    paddingLeft: 5,
                    paddingRight: 5,
                    flexDirection: "row",
                  },
                ]}
              >
                <Text
                  style={[
                    {
                      paddingTop: 3,
                      borderRightWidth: 1,
                      width: "50%",
                      justifyContent: "space-between",
                    },
                  ]}
                >
                  (a) Goods wholly obtained or produced in the territory of the
                  exporting Party
                </Text>
                <Text
                  style={[
                    {
                      paddingRight: 5,
                      paddingTop: 2,
                      paddingLeft: 5,
                      width: "50%",
                      justifyContent: "space-between",
                    },
                  ]}
                >
                  "WO"
                </Text>
              </View>
            </View>

            <View
              style={[
                styles.tableContainer,
                styles.regularFontSize,
                {
                  textAlign: "left",
                  height: 35,
                  borderBottom: 1,
                  marginLeft: 30,
                  marginRight: 5,
                  borderRight: 1,
                  borderLeft: 1,
                },
              ]}
            >
              <View
                style={[
                  styles.tableRow,
                  {
                    paddingLeft: 5,
                    paddingRight: 5,
                    flexDirection: "row",
                  },
                ]}
              >
                <Text
                  style={[
                    {
                      paddingTop: 3,
                      borderRightWidth: 1,
                      width: "50%",
                      justifyContent: "space-between",
                    },
                  ]}
                >
                  (b) Goods satisfying Rule 4 (Not Wholly Produced or Obtained
                  Products) of the AIFTA Rules of Origin
                </Text>
                <Text
                  style={[
                    {
                      paddingRight: 5,
                      paddingTop: 2,
                      paddingLeft: 5,
                      width: "50%",
                      justifyContent: "space-between",
                    },
                  ]}
                >
                  "RVC [ ]% + CTSH”
                </Text>
              </View>
            </View>

            <View
              style={[
                styles.tableContainer,
                styles.regularFontSize,
                {
                  textAlign: "left",
                  height: 35,
                  borderBottom: 1,
                  marginLeft: 30,
                  marginRight: 5,
                  borderRight: 1,
                  borderLeft: 1,
                },
              ]}
            >
              <View
                style={[
                  styles.tableRow,
                  {
                    paddingLeft: 5,
                    paddingRight: 5,
                    flexDirection: "row",
                  },
                ]}
              >
                <Text
                  style={[
                    {
                      paddingTop: 3,
                      borderRightWidth: 1,
                      width: "50%",
                      justifyContent: "space-between",
                    },
                  ]}
                >
                  (c) Goods satisfying Rule 6 (Product Specific Rules) of the
                  AIFTA Rules of Origin
                </Text>
                <Text
                  style={[
                    {
                      paddingRight: 5,
                      paddingTop: 2,
                      paddingLeft: 5,
                      width: "50%",
                      justifyContent: "space-between",
                    },
                  ]}
                >
                  Appropriate qualifying criteria
                </Text>
              </View>
            </View>
          </View>

          <View style={[styles.sideBySideContainer]}>
            <View style={[styles.row]}>
              <Text
                style={[
                  styles.subContent,
                  styles.regularFontSize,
                  { marginRight: 10 },
                ]}
              >
                4.
              </Text>

              <Text
                style={[
                  styles.regularFontSize,
                  styles.subContent,
                  { marginLeft: 5, marginRight: 35 },
                ]}
              >
                EACH ARTICLE MUST QUALIFY : It should be noted that all the
                goods in a consignment must qualify separately in their own
                right. This is of particular relevance when similar articles of
                different sizes or spare parts are sent.
              </Text>
            </View>
          </View>

          <View style={[styles.sideBySideContainer]}>
            <View style={[styles.row]}>
              <Text
                style={[
                  styles.subContent,
                  styles.regularFontSize,
                  { marginRight: 10 },
                ]}
              >
                5.
              </Text>

              <Text
                style={[
                  styles.regularFontSize,
                  styles.subContent,
                  { marginLeft: 5, marginRight: 35 },
                ]}
              >
                DESCRIPTION OF GOODS : The description of goods must be
                sufficiently detailed to enable the goods to be identified by
                the Customs Officers examining them. Name of manufacturer, any
                trade mark shall also be specified.
              </Text>
            </View>
          </View>

          <View style={[styles.sideBySideContainer]}>
            <View style={[styles.row]}>
              <Text
                style={[
                  styles.subContent,
                  styles.regularFontSize,
                  { marginRight: 10 },
                ]}
              >
                6.
              </Text>

              <Text
                style={[
                  styles.regularFontSize,
                  styles.subContent,
                  { marginLeft: 5, marginRight: 35 },
                ]}
              >
                HARMONIZED SYSTEM NUMBER : The Harmonized System number shall be
                that of importing Party.
              </Text>
            </View>
          </View>

          <View style={[styles.sideBySideContainer]}>
            <View style={[styles.row]}>
              <Text
                style={[
                  styles.subContent,
                  styles.regularFontSize,
                  { marginRight: 10 },
                ]}
              >
                7.
              </Text>

              <Text
                style={[
                  styles.regularFontSize,
                  styles.subContent,
                  { marginLeft: 5, marginRight: 35 },
                ]}
              >
                EXPORTER : The term “Exporter” in Box 11 may include the
                manufacturer or the producer.
              </Text>
            </View>
          </View>

          <View style={[styles.sideBySideContainer]}>
            <View style={[styles.row]}>
              <Text
                style={[
                  styles.subContent,
                  styles.regularFontSize,
                  { marginRight: 10 },
                ]}
              >
                8.
              </Text>

              <Text
                style={[
                  styles.regularFontSize,
                  styles.subContent,
                  { marginLeft: 5, marginRight: 35 },
                ]}
              >
                FOR OFFICIAL USE : The Customs Authority of the importing Party
                must indicate ( ) in the relevant boxes in column 4 whether or
                not preferential tariff is accorded.
              </Text>
            </View>
          </View>

          <View style={[styles.sideBySideContainer]}>
            <View style={[styles.row]}>
              <Text
                style={[
                  styles.subContent,
                  styles.regularFontSize,
                  { marginRight: 10 },
                ]}
              >
                9.
              </Text>

              <Text
                style={[
                  styles.regularFontSize,
                  styles.subContent,
                  { marginLeft: 4, marginRight: 35 },
                ]}
              >
                {" "}
                THIRD COUNTRY INVOICING : In cases where invoices are issued by
                a third country, “Third Country Invoicing” in Box 13 should be
                ticked ( ) and such information as name and country of the
                company issuing the invoice shall be indicated in Box 7.
              </Text>
            </View>
          </View>

          <View style={[styles.sideBySideContainer]}>
            <View style={[styles.row]}>
              <Text
                style={[
                  styles.subContent,
                  styles.regularFontSize,
                  { marginRight: 10 },
                ]}
              >
                10.
              </Text>

              <Text
                style={[
                  styles.regularFontSize,
                  styles.subContent,
                  { marginLeft: 1, marginRight: 35 },
                ]}
              >
                EXHIBITIONS : In cases where goods are sent from the territory
                of the exporting Party for exhibition in another country and
                sold during or after the exhibition for importation into the
                territory of a Party, in accordance with Article 21 of the
                Operational Certification Procedures, “Exhibitions” in Box 13
                should be ticked ( ) and the name and address of the exhibition
                indicated in Box 2
              </Text>
            </View>
          </View>

          <View style={[styles.sideBySideContainer]}>
            <View style={[styles.row]}>
              <Text
                style={[
                  styles.subContent,
                  styles.regularFontSize,
                  { marginRight: 10 },
                ]}
              >
                11.
              </Text>

              <Text
                style={[
                  styles.regularFontSize,
                  styles.subContent,
                  { marginLeft: 1, marginRight: 35 },
                ]}
              >
                BACK-TO-BACK CERTIFICATE OF ORIGIN : In cases of Back-to-Back
                CO, in accordance with Article 11 of the Operational
                Certification Procedures, “Back-to-Back CO” in Box13 should be
                ticked ( ). The name of original exporting Party to be indicated
                in Box 11 and the date of the issuance of CO and the reference
                number will be indicated in Box 7.
              </Text>
            </View>
          </View>
        </Page>

        {/* Page-5 */}
        <Page style={styles.page} size="A4">
          <View>
            <Text style={[styles.boldText, { textAlign: "center" }]}>
              (DUPLICATE)
            </Text>
          </View>
          <View style={styles.fullpage}>
            <View style={[styles.sideBySideContainer, { height: "150px" }]}>
              <View style={[styles.halfWidthRightSection]}>
                <Text
                  style={[
                    styles.regularFontSize,
                    styles.contentPadding,
                    { justifyContent: "space-between" },
                  ]}
                >
                  1. Goods consigned from (Exporter's business name, address,
                  country)
                </Text>
                <Text style={[styles.regularFontSize, { paddingLeft: 15 }]}>
                  ENFINY EXIM LLP
                </Text>
                <Text style={[styles.regularFontSize, { paddingLeft: 15 }]}>
                  PLOT NO-36, GROUND FLOOR, SHUBHAM INDUSTRY-2 SANIYA HEMED,
                  SURAT, GUJARAT (395006)
                </Text>
                <Text style={[styles.regularFontSize, { paddingLeft: 15 }]}>
                  INDIA
                </Text>
                <View
                  style={{
                    borderTop: 1,
                  }}
                >
                  <Text
                    style={[
                      styles.regularFontSize,
                      styles.contentPadding,
                      { justifyContent: "space-between" },
                    ]}
                  >
                    2. Goods consigned to (Consignee's name, address, country):
                  </Text>
                  <Text style={[styles.regularFontSize, { paddingLeft: 15 }]}>
                    DAC THANH FOOD COMPANY LIMITED
                  </Text>
                  <Text
                    style={[
                      styles.regularFontSize,
                      { justifyContent: "space-between", paddingLeft: 15 },
                    ]}
                  >
                    BINH TINH QUARTER, BINH PHU TOWNLET, CAI LAY DISTRICT, TIEN
                    GIANG PROVINCE, VIETNAM,TAX ID: 1201545414
                  </Text>
                  <Text style={[styles.regularFontSize, { paddingLeft: 15 }]}>
                    VIETNAM
                  </Text>
                </View>
              </View>
              <View style={[styles.halfWidthLeftSection]}>
                <Text style={[styles.regularFontSize, styles.contentPadding]}>
                  Reference No. EIC/2024/013/1026343A
                </Text>
                <Text
                  style={[
                    styles.boldText,
                    styles.contentPadding,
                    { textAlign: "center" },
                  ]}
                >
                  ASEAN-INDIA FREE TRADE AREA
                </Text>
                <Text
                  style={[
                    styles.boldText,
                    styles.contentPadding,
                    { textAlign: "center" },
                  ]}
                >
                  PREFERENTIAL TARIFF
                </Text>
                <Text
                  style={[
                    styles.boldText,
                    styles.contentPadding,
                    { textAlign: "center" },
                  ]}
                >
                  CERTIFICATE OF ORIGIN
                </Text>
                <Text
                  style={[
                    styles.regularFontSize,
                    styles.contentPadding,
                    { textAlign: "center" },
                  ]}
                >
                  (Combined Declaration and Certificate)
                </Text>
                <Text
                  style={[
                    styles.boldText,
                    styles.contentPadding,
                    { textAlign: "center" },
                  ]}
                >
                  FORM AI
                </Text>
                <Text
                  style={[
                    styles.regularFontSize,
                    styles.contentPadding,
                    {
                      textAlign: "center",
                      top: 5,
                    },
                  ]}
                >
                  Issued in:-
                  <Text
                    style={[
                      styles.boldText,
                      styles.contentPadding,
                      {
                        textAlign: "center",
                        textDecoration: "underline",
                        textDecorationStyle: "dotted",
                      },
                    ]}
                  >
                    INDIA
                  </Text>
                </Text>
                <Text
                  style={[
                    styles.regularFontSize,
                    styles.contentPadding,
                    {
                      top: 4,
                      textAlign: "center",
                    },
                  ]}
                >
                  (Country)
                </Text>
                <Text
                  style={[
                    styles.regularFontSize,
                    styles.contentPadding,
                    {
                      top: 4,
                      textAlign: "center",
                    },
                  ]}
                >
                  (See Notes Overleaf)
                </Text>
              </View>
            </View>

            <View style={[styles.sideBySideContainer, { height: "160px" }]}>
              <View style={[styles.halfWidthRightSection]}>
                <Text style={[styles.regularFontSize, styles.contentPadding]}>
                  3. Means of transport and route (as far as known)
                </Text>
                <Text style={[styles.regularFontSize, { paddingLeft: 15 }]}>
                  Departure date: 29-12-2024
                </Text>

                <Text style={[styles.regularFontSize, { paddingLeft: 15 }]}>
                  Vessel's name/Aircraft etc.: X PRESS KAILASH / 1889E
                </Text>
                <Text style={[styles.regularFontSize, { paddingLeft: 15 }]}>
                  Port of Discharge: CAT LAI, VIETNAM
                </Text>
              </View>
              <View style={[styles.halfWidthLeftSection]}>
                {" "}
                <Text style={[styles.regularFontSize, styles.contentPadding]}>
                  4. For Official Use
                </Text>
                <View style={styles.contentPadding}>
                  <View style={styles.row}>
                    <View style={styles.squareBullet}></View>
                    <Text
                      style={[
                        styles.regularFontSize,
                        {
                          paddingLeft: 35,
                          justifyContent: "space-between",
                          paddingRight: 10,
                        },
                      ]}
                    >
                      Preferential Tariff Treatment Given Under ASEAN-India Free
                      Trade Area Preferential Tariff
                    </Text>
                  </View>
                  <View style={{ flexDirection: "row-reverse" }}>
                    <Text
                      style={[
                        {
                          paddingLeft: 80,
                          borderTopWidth: 1,
                          paddingRight: 90,
                          marginRight: 20,
                          top: 18,
                        },
                      ]}
                    ></Text>
                  </View>
                  <View style={styles.row}>
                    <View style={[styles.squareBullet, { top: 22 }]}></View>
                    <Text
                      style={[
                        styles.regularFontSize,
                        {
                          paddingLeft: 35,
                          justifyContent: "space-between",
                          paddingRight: 10,
                          top: 22,
                        },
                      ]}
                    >
                      Preferential Tariff Treatment Not Given (Please state
                      reason/s)
                    </Text>
                  </View>
                  <View style={{ flexDirection: "row-reverse" }}>
                    <Text
                      style={[
                        {
                          paddingLeft: 80,
                          borderTopWidth: 1,
                          paddingRight: 90,
                          marginRight: 20,
                          top: 40,
                        },
                      ]}
                    ></Text>
                  </View>
                </View>
                <View
                  style={{
                    flexDirection: "row-reverse",
                    justifyContent: "flex-end",
                    width: "100%",
                  }}
                >
                  <Text
                    style={[
                      {
                        paddingLeft: 100,
                        borderTopWidth: 1,
                        paddingRight: 100,
                        marginLeft: 50,
                        top: 60,
                      },
                    ]}
                  ></Text>
                </View>
                <View style={styles.row}>
                  <Text
                    style={[
                      styles.regularFontSize,
                      {
                        paddingLeft: 35,
                        justifyContent: "space-between",
                        marginRight: 25,
                        top: 65,
                        textAlign: "center",
                      },
                    ]}
                  >
                    Signature of Authorised Signatory of the Importing Country
                  </Text>
                </View>
              </View>
            </View>

            <View>
              <View
                style={[
                  styles.tableContainer,
                  styles.mediumFontSize,
                  {
                    textAlign: "left",
                    height: 50,
                  },
                ]}
              >
                <View
                  style={[
                    styles.tableRow,
                    {
                      paddingLeft: 5,
                      paddingRight: 5,
                      flexDirection: "row",
                    },
                  ]}
                >
                  <Text
                    style={[
                      styles.contentPadding,
                      {
                        width: "10%",
                        justifyContent: "space-between",
                      },
                    ]}
                  >
                    5 . Item number
                  </Text>
                  <Text
                    style={[
                      styles.contentPadding,
                      {
                        borderLeft: 1,

                        paddingRight: 5,
                        width: "20%",
                        justifyContent: "space-between",
                      },
                    ]}
                  >
                    6. Marks and numbers of packages
                  </Text>
                  <Text
                    style={[
                      styles.contentPadding,
                      {
                        borderLeft: 1,

                        paddingRight: 5,
                        width: "45%",
                        justifyContent: "space-between",
                      },
                    ]}
                  >
                    7.Number and type of packages, description of goods
                    (including quantity where appropriate and HS number of the
                    importing country)
                  </Text>
                  <Text
                    style={[
                      styles.contentPadding,
                      {
                        borderLeft: 1,

                        paddingRight: 5,
                        width: "20%",
                        justifyContent: "space-between",
                      },
                    ]}
                  >
                    8. Origin criterion (see notes)
                  </Text>
                  <Text
                    style={[
                      styles.contentPadding,
                      {
                        borderLeft: 1,
                        paddingRight: 5,
                        width: "20%",
                        justifyContent: "space-between",
                      },
                    ]}
                  >
                    9. Gross weight or other quantity and value(FOB)
                  </Text>
                  <Text
                    style={[
                      styles.contentPadding,
                      {
                        borderLeft: 1,
                        paddingRight: 5,
                        width: "20%",
                        justifyContent: "space-between",
                      },
                    ]}
                  >
                    10. Number and date of invoices
                  </Text>
                </View>
              </View>
              {/* table data */}
              <View
                style={[
                  styles.tableContainer,
                  styles.mediumFontSize,
                  {
                    textAlign: "left",
                    height: 50,
                  },
                ]}
              >
                <View
                  style={[
                    styles.tableRow,
                    {
                      paddingLeft: 5,
                      paddingRight: 5,
                      flexDirection: "row",
                    },
                  ]}
                >
                  <Text
                    style={[
                      styles.contentPadding,
                      {
                        width: "10%",
                        justifyContent: "space-between",
                      },
                    ]}
                  >
                    1
                  </Text>
                  <Text
                    style={[
                      styles.contentPadding,
                      {
                        borderLeft: 1,

                        paddingRight: 5,
                        width: "20%",
                        justifyContent: "space-between",
                      },
                    ]}
                  >
                    AC THANH
                  </Text>
                  <Text
                    style={[
                      styles.contentPadding,
                      {
                        borderLeft: 1,

                        paddingRight: 5,
                        width: "45%",
                        justifyContent: "space-between",
                      },
                    ]}
                  >
                    TOTAL 2700 BAGS, INDIAN SONA MASOORI WHITE RICE (PACKING:IN
                    PP BAGS OF 50 KGS NET EACH) (NET WT: 135.000 MTS) HS CODE:
                    1006.30.90
                  </Text>
                  <Text
                    style={[
                      styles.contentPadding,
                      {
                        borderLeft: 1,

                        paddingRight: 5,
                        width: "20%",
                        justifyContent: "space-between",
                      },
                    ]}
                  >
                    "WO"
                  </Text>
                  <Text
                    style={[
                      styles.contentPadding,
                      {
                        borderLeft: 1,
                        paddingRight: 5,
                        width: "20%",
                        justifyContent: "space-between",
                      },
                    ]}
                  >
                    GORSS WT:135.27 MT , USD 695520.00
                  </Text>
                  <Text
                    style={[
                      styles.contentPadding,
                      {
                        borderLeft: 1,
                        paddingRight: 5,
                        width: "20%",
                        justifyContent: "space-between",
                      },
                    ]}
                  >
                    EXP/24-25/010 DT :17/12/2024
                  </Text>
                </View>
              </View>
            </View>

            <View style={[styles.sideBySideContainer, { height: 100 }]}></View>
            {/* 11 | 12 */}
            <View
              style={[
                styles.sideBySideContainer,
                { borderTop: 1, height: 155 },
              ]}
            >
              <View style={[styles.halfWidthRightSection]}>
                <Text style={[styles.regularFontSize, styles.contentPadding]}>
                  11. Declaration by the exporter
                </Text>
                <Text
                  style={[
                    styles.mediumFontSize,
                    styles.contentPadding,
                    {
                      paddingRight: 5,
                      justifyContent: "space-between",
                    },
                  ]}
                >
                  The undersigned hereby declares that the above details and
                  statement are correct; that all the goods were produced in
                </Text>
                <Text
                  style={[
                    styles.contentPadding,
                    styles.mediumFontSize,
                    {
                      paddingBottom: -50,
                      paddingRight: 5,
                      textAlign: "center",
                    },
                  ]}
                >
                  INDIA
                </Text>
                <Text
                  style={[
                    styles.boldText,
                    styles.contentPadding,
                    {
                      textAlign: "center",
                      paddingTop: -8,
                      paddingRight: 5,
                      justifyContent: "space-between",
                    },
                  ]}
                >
                  ....................................................................................................
                </Text>
                <Text
                  style={[
                    styles.contentPadding,
                    styles.mediumFontSize,
                    {
                      paddingTop: -10,
                      paddingRight: 5,
                      textAlign: "center",
                    },
                  ]}
                >
                  (Country)
                </Text>
                <Text
                  style={[
                    styles.mediumFontSize,
                    styles.contentPadding,
                    {
                      paddingRight: 5,
                      justifyContent: "space-between",
                    },
                  ]}
                >
                  and that they comply with the origin requirements specified
                  for these goods in the ASEAN-INDIA Free Trade Area
                  Preferential Tariff for the goods exported to
                </Text>
                <Text
                  style={[
                    styles.contentPadding,
                    styles.mediumFontSize,
                    {
                      paddingBottom: -50,
                      paddingRight: 5,
                      textAlign: "center",
                    },
                  ]}
                >
                  VITENAM
                </Text>
                <Text
                  style={[
                    styles.boldText,
                    styles.contentPadding,
                    {
                      textAlign: "center",
                      paddingTop: -8,
                      paddingRight: 5,
                      justifyContent: "space-between",
                    },
                  ]}
                >
                  ....................................................................................................
                </Text>
                <Text
                  style={[
                    styles.contentPadding,
                    styles.mediumFontSize,
                    {
                      paddingTop: -10,
                      paddingRight: 5,
                      textAlign: "center",
                    },
                  ]}
                >
                  (Importing Country)
                </Text>
                <Text
                  style={[
                    styles.contentPadding,
                    styles.mediumFontSize,
                    {
                      paddingRight: 5,
                      textAlign: "center",
                    },
                  ]}
                >
                  SURAT,GUJARTA,INDIA,04/01/2025
                </Text>
                <Text
                  style={[
                    styles.boldText,
                    styles.contentPadding,
                    {
                      paddingTop: -2,
                      textAlign: "center",
                      paddingRight: 5,
                    },
                  ]}
                >
                  ....................................................................................................
                </Text>
                <Text
                  style={[
                    styles.mediumFontSize,
                    styles.contentPadding,
                    {
                      textAlign: "center",
                      paddingRight: 5,
                      paddingTop: -3,
                      justifyContent: "space-between",
                    },
                  ]}
                >
                  Place and date, signature and Stamp of Certifying authority.
                </Text>
              </View>
              <View style={[styles.halfWidthLeftSection]}>
                <Text style={[styles.regularFontSize, styles.contentPadding]}>
                  12.Certification :
                </Text>
                <Text
                  style={[
                    styles.mediumFontSize,
                    styles.contentPadding,
                    {
                      paddingRight: 5,
                      justifyContent: "space-between",
                    },
                  ]}
                >
                  It is hereby certified,on the basis of control carried out,
                  that the declaration by the exporter is correct.
                </Text>
              </View>
            </View>

            {/* 13 */}
            <View>
              <Text style={[styles.mediumFontSize, { paddingLeft: 5 }]}>
                13. Where appropriate please tick:
              </Text>
              <View
                style={{
                  flexDirection: "row",
                  flexWrap: "wrap",
                  marginTop: 10,
                }}
              >
                {checkboxData.map((item, index) => (
                  <View
                    key={index}
                    style={{
                      flexDirection: "row",
                      alignItems: "center",
                      marginRight: 5,
                      marginLeft: 35,
                      paddingBottom: 8,
                    }}
                  >
                    <View style={styles.checkbox}>
                      {item.isChecked === true && (
                        <Text style={styles.checkMark}>✔</Text>
                      )}
                    </View>
                    <Text style={styles.mediumFontSize}>{item.label}</Text>
                  </View>
                ))}
              </View>
            </View>
          </View>
        </Page>

        {/* Page-6 */}
        <Page style={styles.page} size="A4">
          <Text style={[styles.contentPadding, styles.boldText]}>
            OVERLEAF NOTES
          </Text>
          <View style={[styles.sideBySideContainer]}>
            <View style={[styles.row]}>
              <Text
                style={[
                  styles.subContent,
                  styles.regularFontSize,
                  { marginRight: 10 },
                ]}
              >
                1.
              </Text>

              <Text
                style={[
                  styles.regularFontSize,
                  styles.subContent,
                  { marginLeft: 5, marginRight: 35 },
                ]}
              >
                Parties which accept this form for the purpose of preferential
                tariff treatment under the ASEAN-INDIA Free Trade Agreement
                (AIFTA):
              </Text>
            </View>
          </View>
          <View style={{ marginLeft: 40 }}>
            <View
              style={[
                styles.tableContainer,
                styles.mediumFontSize,
                {
                  top: 5,
                  textAlign: "left",
                  height: 20,
                  borderBottom: 0,
                  width: "100%",
                },
              ]}
            >
              <View
                style={[
                  styles.tableRow,
                  {
                    paddingLeft: 5,
                    paddingRight: 5,
                    flexDirection: "row",
                  },
                ]}
              >
                <Text
                  style={[
                    styles.regularFontSize,
                    {
                      paddingTop: 2,
                      width: "50%",
                      justifyContent: "space-between",
                    },
                  ]}
                >
                  BRUNEI DARUSSALAM
                </Text>
                <Text
                  style={[
                    styles.regularFontSize,
                    {
                      paddingRight: 5,
                      paddingTop: 2,
                      paddingLeft: 5,
                      width: "50%",
                      justifyContent: "space-between",
                    },
                  ]}
                >
                  CAMBODIA
                </Text>
                <Text
                  style={[
                    styles.regularFontSize,
                    {
                      paddingTop: 2,
                      paddingRight: 5,
                      width: "50%",
                      justifyContent: "space-between",
                    },
                  ]}
                >
                  INDONESIA
                </Text>
              </View>
            </View>

            <View
              style={[
                styles.tableContainer,
                styles.mediumFontSize,
                {
                  top: 5,
                  textAlign: "left",
                  height: 20,
                  borderBottom: 0,
                  width: "100%",
                },
              ]}
            >
              <View
                style={[
                  styles.tableRow,
                  {
                    paddingLeft: 5,
                    paddingRight: 5,
                    flexDirection: "row",
                  },
                ]}
              >
                <Text
                  style={[
                    styles.regularFontSize,
                    {
                      paddingTop: 2,
                      width: "50%",
                      justifyContent: "space-between",
                    },
                  ]}
                >
                  INDIA
                </Text>
                <Text
                  style={[
                    styles.regularFontSize,
                    {
                      paddingRight: 5,
                      paddingTop: 2,
                      paddingLeft: 5,
                      width: "50%",
                      justifyContent: "space-between",
                    },
                  ]}
                >
                  LAOS
                </Text>
                <Text
                  style={[
                    styles.regularFontSize,
                    {
                      paddingTop: 2,
                      paddingRight: 5,
                      width: "50%",
                      justifyContent: "space-between",
                    },
                  ]}
                >
                  MALASIYA
                </Text>
              </View>
            </View>

            <View
              style={[
                styles.tableContainer,
                styles.mediumFontSize,
                {
                  top: 5,
                  textAlign: "left",
                  height: 20,
                  borderBottom: 0,
                  width: "100%",
                },
              ]}
            >
              <View
                style={[
                  styles.tableRow,
                  {
                    paddingLeft: 5,
                    paddingRight: 5,
                    flexDirection: "row",
                  },
                ]}
              >
                <Text
                  style={[
                    styles.regularFontSize,
                    {
                      paddingTop: 2,
                      width: "50%",
                      justifyContent: "space-between",
                    },
                  ]}
                >
                  MYNAMMAR
                </Text>
                <Text
                  style={[
                    styles.regularFontSize,
                    {
                      paddingRight: 5,
                      paddingTop: 2,
                      paddingLeft: 5,
                      width: "50%",
                      justifyContent: "space-between",
                    },
                  ]}
                >
                  PHILLIPINES
                </Text>
                <Text
                  style={[
                    styles.regularFontSize,
                    {
                      paddingTop: 2,
                      paddingRight: 5,
                      width: "50%",
                      justifyContent: "space-between",
                    },
                  ]}
                >
                  SINGAPORE
                </Text>
              </View>
            </View>

            <View
              style={[
                styles.tableContainer,
                styles.mediumFontSize,
                {
                  top: 5,
                  textAlign: "left",
                  height: 20,
                  borderBottom: 0,
                  width: "100%",
                },
              ]}
            >
              <View
                style={[
                  styles.tableRow,
                  {
                    paddingLeft: 5,
                    paddingRight: 5,
                    flexDirection: "row",
                  },
                ]}
              >
                <Text
                  style={[
                    styles.regularFontSize,
                    {
                      paddingTop: 2,
                      width: "50%",
                      justifyContent: "space-between",
                    },
                  ]}
                >
                  THAILAND
                </Text>
                <Text
                  style={[
                    styles.regularFontSize,
                    {
                      paddingRight: 5,
                      paddingTop: 2,
                      paddingLeft: 5,
                      width: "100%",
                      justifyContent: "space-between",
                    },
                  ]}
                >
                  VITENAM
                </Text>
              </View>
            </View>
          </View>

          <View style={[styles.sideBySideContainer]}>
            <View style={[styles.row]}>
              <Text
                style={[
                  styles.subContent,
                  styles.regularFontSize,
                  { marginRight: 10 },
                ]}
              >
                2.
              </Text>
              <Text
                style={[
                  styles.regularFontSize,
                  styles.subContent,
                  { marginLeft: 5, marginRight: 35 },
                ]}
              >
                CONDITIONS: To enjoy preferential tariff under the AIFTA, goods
                sent to any Parties listed above:
              </Text>{" "}
            </View>
          </View>

          <View style={[styles.sideBySideContainer]}>
            <View style={[styles.row]}>
              <Text
                style={[
                  styles.subContent,
                  styles.regularFontSize,
                  { marginRight: 10, paddingLeft: 40 },
                ]}
              >
                (i)
              </Text>
              <Text
                style={[
                  styles.regularFontSize,
                  styles.subContent,
                  { marginLeft: 5, marginRight: 95 },
                ]}
              >
                must fall within a description of goods eligible for concessions
                in the Party of destination;
              </Text>{" "}
            </View>
          </View>
          <View style={[styles.sideBySideContainer]}>
            <View style={[styles.row]}>
              <Text
                style={[
                  styles.subContent,
                  styles.regularFontSize,
                  { marginRight: 10, paddingLeft: 40 },
                ]}
              >
                (ii)
              </Text>
              <Text
                style={[
                  styles.regularFontSize,
                  styles.subContent,
                  { marginLeft: 5, marginRight: 95 },
                ]}
              >
                must comply with the consignment conditions in accordance with
                Rule 8 of the AIFTA Rules of Origin; and
              </Text>{" "}
            </View>
          </View>
          <View style={[styles.sideBySideContainer]}>
            <View style={[styles.row]}>
              <Text
                style={[
                  styles.subContent,
                  styles.regularFontSize,
                  { marginRight: 10, paddingLeft: 37 },
                ]}
              >
                (iii)
              </Text>
              <Text
                style={[
                  styles.regularFontSize,
                  styles.subContent,
                  { marginLeft: 1, marginRight: 95 },
                ]}
              >
                must comply with the origin criteria in the AIFTA Rules of
                Origin.
              </Text>{" "}
            </View>
          </View>

          <View style={[styles.sideBySideContainer]}>
            <View style={[styles.row]}>
              <Text
                style={[
                  styles.subContent,
                  styles.regularFontSize,
                  { marginRight: 10 },
                ]}
              >
                3.
              </Text>
              <Text
                style={[
                  styles.regularFontSize,
                  styles.subContent,
                  { marginLeft: 5, marginRight: 35 },
                ]}
              >
                ORIGIN CRITERIA : For goods that meet the origin criteria, the
                exporter and/or producer must indicate in box 8 of this Form,
                the origin criteria met, in the manner shown in the following
                table:
              </Text>
            </View>
          </View>
          <View>
            <View
              style={[
                styles.tableContainer,
                styles.regularFontSize,
                {
                  textAlign: "left",
                  height: 33,
                  borderTop: 1,
                  marginTop: 5,
                  marginLeft: 30,
                  marginRight: 5,
                  borderRight: 1,
                  borderLeft: 1,
                },
              ]}
            >
              <View
                style={[
                  styles.tableRow,
                  {
                    paddingLeft: 5,
                    paddingRight: 5,
                    flexDirection: "row",
                  },
                ]}
              >
                <Text
                  style={[
                    {
                      paddingTop: 2,
                      borderRightWidth: 1,
                      width: "50%",
                      justifyContent: "space-between",
                    },
                  ]}
                >
                  Circumstances of production or manufacture in the first
                  country named in Box 11 of this form
                </Text>
                <Text
                  style={[
                    {
                      paddingRight: 5,
                      paddingTop: 2,
                      paddingLeft: 5,
                      width: "50%",
                      justifyContent: "space-between",
                    },
                  ]}
                >
                  Insert in Box 8
                </Text>
              </View>
            </View>
            <View
              style={[
                styles.tableContainer,
                styles.regularFontSize,
                {
                  textAlign: "left",
                  height: 35,
                  borderBottom: 1,
                  marginLeft: 30,
                  marginRight: 5,
                  borderRight: 1,
                  borderLeft: 1,
                },
              ]}
            >
              <View
                style={[
                  styles.tableRow,
                  {
                    paddingLeft: 5,
                    paddingRight: 5,
                    flexDirection: "row",
                  },
                ]}
              >
                <Text
                  style={[
                    {
                      paddingTop: 3,
                      borderRightWidth: 1,
                      width: "50%",
                      justifyContent: "space-between",
                    },
                  ]}
                >
                  (a) Goods wholly obtained or produced in the territory of the
                  exporting Party
                </Text>
                <Text
                  style={[
                    {
                      paddingRight: 5,
                      paddingTop: 2,
                      paddingLeft: 5,
                      width: "50%",
                      justifyContent: "space-between",
                    },
                  ]}
                >
                  "WO"
                </Text>
              </View>
            </View>

            <View
              style={[
                styles.tableContainer,
                styles.regularFontSize,
                {
                  textAlign: "left",
                  height: 35,
                  borderBottom: 1,
                  marginLeft: 30,
                  marginRight: 5,
                  borderRight: 1,
                  borderLeft: 1,
                },
              ]}
            >
              <View
                style={[
                  styles.tableRow,
                  {
                    paddingLeft: 5,
                    paddingRight: 5,
                    flexDirection: "row",
                  },
                ]}
              >
                <Text
                  style={[
                    {
                      paddingTop: 3,
                      borderRightWidth: 1,
                      width: "50%",
                      justifyContent: "space-between",
                    },
                  ]}
                >
                  (b) Goods satisfying Rule 4 (Not Wholly Produced or Obtained
                  Products) of the AIFTA Rules of Origin
                </Text>
                <Text
                  style={[
                    {
                      paddingRight: 5,
                      paddingTop: 2,
                      paddingLeft: 5,
                      width: "50%",
                      justifyContent: "space-between",
                    },
                  ]}
                >
                  "RVC [ ]% + CTSH”
                </Text>
              </View>
            </View>

            <View
              style={[
                styles.tableContainer,
                styles.regularFontSize,
                {
                  textAlign: "left",
                  height: 35,
                  borderBottom: 1,
                  marginLeft: 30,
                  marginRight: 5,
                  borderRight: 1,
                  borderLeft: 1,
                },
              ]}
            >
              <View
                style={[
                  styles.tableRow,
                  {
                    paddingLeft: 5,
                    paddingRight: 5,
                    flexDirection: "row",
                  },
                ]}
              >
                <Text
                  style={[
                    {
                      paddingTop: 3,
                      borderRightWidth: 1,
                      width: "50%",
                      justifyContent: "space-between",
                    },
                  ]}
                >
                  (c) Goods satisfying Rule 6 (Product Specific Rules) of the
                  AIFTA Rules of Origin
                </Text>
                <Text
                  style={[
                    {
                      paddingRight: 5,
                      paddingTop: 2,
                      paddingLeft: 5,
                      width: "50%",
                      justifyContent: "space-between",
                    },
                  ]}
                >
                  Appropriate qualifying criteria
                </Text>
              </View>
            </View>
          </View>

          <View style={[styles.sideBySideContainer]}>
            <View style={[styles.row]}>
              <Text
                style={[
                  styles.subContent,
                  styles.regularFontSize,
                  { marginRight: 10 },
                ]}
              >
                4.
              </Text>

              <Text
                style={[
                  styles.regularFontSize,
                  styles.subContent,
                  { marginLeft: 5, marginRight: 35 },
                ]}
              >
                EACH ARTICLE MUST QUALIFY : It should be noted that all the
                goods in a consignment must qualify separately in their own
                right. This is of particular relevance when similar articles of
                different sizes or spare parts are sent.
              </Text>
            </View>
          </View>

          <View style={[styles.sideBySideContainer]}>
            <View style={[styles.row]}>
              <Text
                style={[
                  styles.subContent,
                  styles.regularFontSize,
                  { marginRight: 10 },
                ]}
              >
                5.
              </Text>

              <Text
                style={[
                  styles.regularFontSize,
                  styles.subContent,
                  { marginLeft: 5, marginRight: 35 },
                ]}
              >
                DESCRIPTION OF GOODS : The description of goods must be
                sufficiently detailed to enable the goods to be identified by
                the Customs Officers examining them. Name of manufacturer, any
                trade mark shall also be specified.
              </Text>
            </View>
          </View>

          <View style={[styles.sideBySideContainer]}>
            <View style={[styles.row]}>
              <Text
                style={[
                  styles.subContent,
                  styles.regularFontSize,
                  { marginRight: 10 },
                ]}
              >
                6.
              </Text>

              <Text
                style={[
                  styles.regularFontSize,
                  styles.subContent,
                  { marginLeft: 5, marginRight: 35 },
                ]}
              >
                HARMONIZED SYSTEM NUMBER : The Harmonized System number shall be
                that of importing Party.
              </Text>
            </View>
          </View>

          <View style={[styles.sideBySideContainer]}>
            <View style={[styles.row]}>
              <Text
                style={[
                  styles.subContent,
                  styles.regularFontSize,
                  { marginRight: 10 },
                ]}
              >
                7.
              </Text>

              <Text
                style={[
                  styles.regularFontSize,
                  styles.subContent,
                  { marginLeft: 5, marginRight: 35 },
                ]}
              >
                EXPORTER : The term “Exporter” in Box 11 may include the
                manufacturer or the producer.
              </Text>
            </View>
          </View>

          <View style={[styles.sideBySideContainer]}>
            <View style={[styles.row]}>
              <Text
                style={[
                  styles.subContent,
                  styles.regularFontSize,
                  { marginRight: 10 },
                ]}
              >
                8.
              </Text>

              <Text
                style={[
                  styles.regularFontSize,
                  styles.subContent,
                  { marginLeft: 5, marginRight: 35 },
                ]}
              >
                FOR OFFICIAL USE : The Customs Authority of the importing Party
                must indicate ( ) in the relevant boxes in column 4 whether or
                not preferential tariff is accorded.
              </Text>
            </View>
          </View>

          <View style={[styles.sideBySideContainer]}>
            <View style={[styles.row]}>
              <Text
                style={[
                  styles.subContent,
                  styles.regularFontSize,
                  { marginRight: 10 },
                ]}
              >
                9.
              </Text>

              <Text
                style={[
                  styles.regularFontSize,
                  styles.subContent,
                  { marginLeft: 4, marginRight: 35 },
                ]}
              >
                {" "}
                THIRD COUNTRY INVOICING : In cases where invoices are issued by
                a third country, “Third Country Invoicing” in Box 13 should be
                ticked ( ) and such information as name and country of the
                company issuing the invoice shall be indicated in Box 7.
              </Text>
            </View>
          </View>

          <View style={[styles.sideBySideContainer]}>
            <View style={[styles.row]}>
              <Text
                style={[
                  styles.subContent,
                  styles.regularFontSize,
                  { marginRight: 10 },
                ]}
              >
                10.
              </Text>

              <Text
                style={[
                  styles.regularFontSize,
                  styles.subContent,
                  { marginLeft: 1, marginRight: 35 },
                ]}
              >
                EXHIBITIONS : In cases where goods are sent from the territory
                of the exporting Party for exhibition in another country and
                sold during or after the exhibition for importation into the
                territory of a Party, in accordance with Article 21 of the
                Operational Certification Procedures, “Exhibitions” in Box 13
                should be ticked ( ) and the name and address of the exhibition
                indicated in Box 2
              </Text>
            </View>
          </View>

          <View style={[styles.sideBySideContainer]}>
            <View style={[styles.row]}>
              <Text
                style={[
                  styles.subContent,
                  styles.regularFontSize,
                  { marginRight: 10 },
                ]}
              >
                11.
              </Text>

              <Text
                style={[
                  styles.regularFontSize,
                  styles.subContent,
                  { marginLeft: 1, marginRight: 35 },
                ]}
              >
                BACK-TO-BACK CERTIFICATE OF ORIGIN : In cases of Back-to-Back
                CO, in accordance with Article 11 of the Operational
                Certification Procedures, “Back-to-Back CO” in Box13 should be
                ticked ( ). The name of original exporting Party to be indicated
                in Box 11 and the date of the issuance of CO and the reference
                number will be indicated in Box 7.
              </Text>
            </View>
          </View>
        </Page>

        {/* Page-7 */}
        <Page style={styles.page} size="A4">
          <Text style={[styles.boldText, { textAlign: "center" }]}>
            APPLICATION FOR CERTIFICATE OF ORIGIN UNDER ASEAN-India FTA
          </Text>
          <View>
            <Text
              style={[
                styles.regularFontSize,
                styles.subContent,
                { top: 5, marginRight: 35, marginLeft: 5 },
              ]}
            >
              The undersigned, being the exporter of the goods described
              overleaf,
            </Text>

            <Text
              style={[
                styles.regularFontSize,
                styles.subContent,
                { top: 5, marginLeft: 5, marginRight: 35 },
              ]}
            >
              <Text
                style={[styles.boldText, { marginLeft: 3, marginRight: 35 }]}
              >
                {" "}
                DECLARES{" "}
              </Text>{" "}
              that these goods were produced in{" "}
              <Text
                style={[styles.boldText, { marginLeft: 3, marginRight: 35 }]}
              >
                {" "}
                INDIA{" "}
              </Text>{" "}
              <Text>
                and are presently available at CONSIGNMENT IS ALREADY SHIPPED ON
                29.12.2024 and that no certificate of origin has been obtained
                from any authority for these goods,
              </Text>
            </Text>

            <Text
              style={[
                styles.regularFontSize,
                styles.subContent,
                { top: 5, marginLeft: 5, marginRight: 35 },
              ]}
            >
              <Text
                style={[styles.boldText, { marginLeft: 3, marginRight: 35 }]}
              >
                {" "}
                SPECIFIES{" "}
              </Text>{" "}
              as follows the grounds on which the goods are claimed to comply
              with
              <Text
                style={[styles.boldText, { marginLeft: 3, marginRight: 35 }]}
              >
                {" "}
                AIFTA{" "}
              </Text>{" "}
              <Text>
                origin requirements,1) I/WE HEREBY DECLARE THAT THE PRODUCTS ARE
                MANUFACTURED WITH INDIGENOUS RAW MATERIALS AND NO IMPORTED OR
                UNDETERMINED RAW MATERIALS ARE USED.
              </Text>
            </Text>

            <Text
              style={[
                styles.regularFontSize,
                styles.subContent,
                { top: 5, marginLeft: 5, marginRight: 35 },
              ]}
            >
              <Text
                style={[styles.boldText, { marginLeft: 3, marginRight: 35 }]}
              >
                {" "}
                SUBMITS{" "}
              </Text>{" "}
              the following supporting documents
            </Text>
            <Text
              style={[
                styles.regularFontSize,
                styles.subContent,
                { top: 0, marginLeft: 3, marginRight: 35 },
              ]}
            >
              INVOICE & B/L
            </Text>

            <Text
              style={[
                styles.regularFontSize,
                styles.subContent,
                { top: 5, marginLeft: 5, marginRight: 35 },
              ]}
            >
              <Text
                style={[styles.boldText, { marginLeft: 3, marginRight: 35 }]}
              >
                {" "}
                UNDERTAKES
              </Text>{" "}
              to submit,at the request of the appropriate authorities, any
              additional supporting evidence which these authorities may require
              for the purpose of issuing a certificate of origin and undertakes,
              if required, to agree to any inspection of his accounts and any
              check on the processes of manufacture of the above goods, carried
              out by the said authority.{" "}
            </Text>

            <Text
              style={[
                styles.regularFontSize,
                styles.subContent,
                { top: 5, marginLeft: 5, marginRight: 35 },
              ]}
            >
              <Text
                style={[styles.boldText, { marginLeft: 3, marginRight: 35 }]}
              >
                {" "}
                REQUESTS
              </Text>{" "}
              the issue of a certificate of origin for these goods.{" "}
            </Text>

            <Text
              style={[
                styles.regularFontSize,
                styles.subContent,
                { top: 5, marginLeft: 250, justifyContent: "flex-end" },
              ]}
            >
              <Text
                style={[
                  styles.regularFontSize,
                  { marginLeft: 3, marginRight: 35 },
                ]}
              >
                {" "}
                Place and date{" "}
              </Text>{" "}
              SURAT, GUJARAT, INDIA, 04/01/2025{" "}
            </Text>
            <Text
              style={[
                styles.regularFontSize,
                styles.subContent,
                { top: 5, marginLeft: 350, justifyContent: "flex-end" },
              ]}
            >
              <Text
                style={[
                  styles.regularFontSize,
                  { marginLeft: 3, marginRight: 5, top: 1, bottom: -10 },
                ]}
              >
                ..............................................................................................{" "}
              </Text>{" "}
            </Text>

            <Text
              style={[
                styles.regularFontSize,
                styles.subContent,
                { top: 5, marginLeft: 300, justifyContent: "flex-end" },
              ]}
            >
              <Text
                style={[
                  styles.regularFontSize,
                  { marginLeft: 3, marginRight: 35 },
                ]}
              >
                {" "}
                .......................................................................................................................{" "}
              </Text>{" "}
            </Text>

            <Text
              style={[
                styles.regularFontSize,
                styles.subContent,
                { top: 5, marginLeft: 300, justifyContent: "flex-end" },
              ]}
            >
              <Text
                style={[
                  styles.regularFontSize,
                  { marginLeft: 3, marginRight: 35 },
                ]}
              >
                {" "}
                (Signature & stamp of authorised signatory)
              </Text>{" "}
            </Text>
          </View>
          <View
            style={[
              styles.sideBySideContainer,
              { borderTop: 1, borderColor: "grey", top: 15 },
            ]}
          ></View>
          <View style={[styles.sideBySideContainer, { top: 15 }]}>
            <View style={[styles.row]}>
              <Text
                style={[
                  styles.subContent,
                  styles.regularFontSize,
                  { marginRight: 10 },
                ]}
              >
                1)
              </Text>

              <Text
                style={[
                  styles.regularFontSize,
                  styles.subContent,
                  { marginLeft: 4, marginRight: 35 },
                ]}
              >
                To be complicated if materials or components in another country
                have used in the manufacture of the goods in question. Indicate
                materials or components used. Their Harmonized System heading at
                the 4 digit level, their country of origin and, where
                appropriate, the manufacturing processes qualifying the goods as
                originating in the country of manufacture (application of the
                List of working or processing operations), the goods produced
                and their Harmonised System Heading at the 4 digit level.
              </Text>
            </View>
          </View>

          <View style={[styles.sideBySideContainer, { top: 15 }]}>
            <View style={[styles.row]}>
              <Text
                style={[
                  styles.subContent,
                  styles.regularFontSize,
                  { marginRight: 10 },
                ]}
              ></Text>

              <Text
                style={[
                  styles.regularFontSize,
                  styles.subContent,
                  { marginLeft: 13, marginRight: 35 },
                ]}
              >
                Where the origin criteria involve a percentage value, give
                information enabling this percentage to be verified-for example
                the value of imported materials and components and those of
                undetermined origin and the ex-factory price of the exported
                goods, where applicable.
              </Text>
            </View>
          </View>

          <View style={[styles.sideBySideContainer, { top: 15 }]}>
            <View style={[styles.row]}>
              <Text
                style={[
                  styles.subContent,
                  styles.regularFontSize,
                  { marginRight: 10 },
                ]}
              >
                2)
              </Text>

              <Text
                style={[
                  styles.regularFontSize,
                  styles.subContent,
                  { marginLeft: 4, marginRight: 35 },
                ]}
              >
                For example, import documents, invoice etc., relating to the
                non-originating materials or components used.
              </Text>
            </View>
          </View>

          <Text
            style={[
              styles.boldText,
              { textAlign: "center", top: 25, paddingBottom: 10 },
            ]}
          >
            NOTES
          </Text>

          <View style={[styles.sideBySideContainer, { top: 15 }]}>
            <View style={[styles.row]}>
              <Text
                style={[
                  styles.subContent,
                  styles.regularFontSize,
                  { marginRight: 10 },
                ]}
              >
                A)
              </Text>

              <Text
                style={[
                  styles.regularFontSize,
                  styles.subContent,
                  { marginLeft: 4, marginRight: 35 },
                ]}
              >
                <Text style={[styles.boldText]}>
                  {" "}
                  Procedure for claiming perference.{" "}
                </Text>{" "}
                {""}A declaration on the certificate of origin form must be
                prepared by the exporter of the goods and submitted in
                quadruplicate, together with a AIFTA application form, to the
                Indian Certifying Authority which will, if satifsifed, while
                retaining the duplicate, shall provide the original and
                remaining two copies to the exporter. The original shall be
                forwarded, together with the triplicate, by the exporter to the
                importer for submission to the custom authority at the port of
                importation. The triplicate shall be retained by the importer.
                The quadruplicate shall be retained by the exporter.
              </Text>
            </View>
          </View>

          <View style={[styles.sideBySideContainer, { top: 15 }]}>
            <View style={[styles.row]}>
              <Text
                style={[
                  styles.subContent,
                  styles.regularFontSize,
                  { marginRight: 10 },
                ]}
              >
                B)
              </Text>

              <Text
                style={[
                  styles.regularFontSize,
                  styles.subContent,
                  { marginLeft: 4, marginRight: 35 },
                ]}
              >
                <Text style={[styles.boldText]}> Sanctions </Text> {""}
                Persons who furnish, or cause to be furnished, information which
                relates to origin or consignment and which is untrue in a
                material particular are liable to legal penalities and to the
                suspension of facilities for their goods to obtain preference
              </Text>
            </View>
          </View>
        </Page>

        {/* Page-8 */}
        <Page style={styles.page} size="A4">
          <View>
            <Text style={[styles.boldText, { textAlign: "center" }]}>
              (TPIPLICATE)
            </Text>
          </View>
          <View style={styles.fullpage}>
            <View style={[styles.sideBySideContainer, { height: "150px" }]}>
              <View style={[styles.halfWidthRightSection]}>
                <Text
                  style={[
                    styles.regularFontSize,
                    styles.contentPadding,
                    { justifyContent: "space-between" },
                  ]}
                >
                  1. Goods consigned from (Exporter's business name, address,
                  country)
                </Text>
                <Text style={[styles.regularFontSize, { paddingLeft: 15 }]}>
                  ENFINY EXIM LLP
                </Text>
                <Text style={[styles.regularFontSize, { paddingLeft: 15 }]}>
                  PLOT NO-36, GROUND FLOOR, SHUBHAM INDUSTRY-2 SANIYA HEMED,
                  SURAT, GUJARAT (395006)
                </Text>
                <Text style={[styles.regularFontSize, { paddingLeft: 15 }]}>
                  INDIA
                </Text>
                <View
                  style={{
                    borderTop: 1,
                  }}
                >
                  <Text
                    style={[
                      styles.regularFontSize,
                      styles.contentPadding,
                      { justifyContent: "space-between" },
                    ]}
                  >
                    2. Goods consigned to (Consignee's name, address, country):
                  </Text>
                  <Text style={[styles.regularFontSize, { paddingLeft: 15 }]}>
                    DAC THANH FOOD COMPANY LIMITED
                  </Text>
                  <Text
                    style={[
                      styles.regularFontSize,
                      { justifyContent: "space-between", paddingLeft: 15 },
                    ]}
                  >
                    BINH TINH QUARTER, BINH PHU TOWNLET, CAI LAY DISTRICT, TIEN
                    GIANG PROVINCE, VIETNAM,TAX ID: 1201545414
                  </Text>
                  <Text style={[styles.regularFontSize, { paddingLeft: 15 }]}>
                    VIETNAM
                  </Text>
                </View>
              </View>
              <View style={[styles.halfWidthLeftSection]}>
                <Text style={[styles.regularFontSize, styles.contentPadding]}>
                  Reference No. EIC/2024/013/1026343A
                </Text>
                <Text
                  style={[
                    styles.boldText,
                    styles.contentPadding,
                    { textAlign: "center" },
                  ]}
                >
                  ASEAN-INDIA FREE TRADE AREA
                </Text>
                <Text
                  style={[
                    styles.boldText,
                    styles.contentPadding,
                    { textAlign: "center" },
                  ]}
                >
                  PREFERENTIAL TARIFF
                </Text>
                <Text
                  style={[
                    styles.boldText,
                    styles.contentPadding,
                    { textAlign: "center" },
                  ]}
                >
                  CERTIFICATE OF ORIGIN
                </Text>
                <Text
                  style={[
                    styles.regularFontSize,
                    styles.contentPadding,
                    { textAlign: "center" },
                  ]}
                >
                  (Combined Declaration and Certificate)
                </Text>
                <Text
                  style={[
                    styles.boldText,
                    styles.contentPadding,
                    { textAlign: "center" },
                  ]}
                >
                  FORM AI
                </Text>
                <Text
                  style={[
                    styles.regularFontSize,
                    styles.contentPadding,
                    {
                      textAlign: "center",
                      top: 5,
                    },
                  ]}
                >
                  Issued in:-
                  <Text
                    style={[
                      styles.boldText,
                      styles.contentPadding,
                      {
                        textAlign: "center",
                        textDecoration: "underline",
                        textDecorationStyle: "dotted",
                      },
                    ]}
                  >
                    INDIA
                  </Text>
                </Text>
                <Text
                  style={[
                    styles.regularFontSize,
                    styles.contentPadding,
                    {
                      top: 4,
                      textAlign: "center",
                    },
                  ]}
                >
                  (Country)
                </Text>
                <Text
                  style={[
                    styles.regularFontSize,
                    styles.contentPadding,
                    {
                      top: 4,
                      textAlign: "center",
                    },
                  ]}
                >
                  (See Notes Overleaf)
                </Text>
              </View>
            </View>

            <View style={[styles.sideBySideContainer, { height: "160px" }]}>
              <View style={[styles.halfWidthRightSection]}>
                <Text style={[styles.regularFontSize, styles.contentPadding]}>
                  3. Means of transport and route (as far as known)
                </Text>
                <Text style={[styles.regularFontSize, { paddingLeft: 15 }]}>
                  Departure date: 29-12-2024
                </Text>

                <Text style={[styles.regularFontSize, { paddingLeft: 15 }]}>
                  Vessel's name/Aircraft etc.: X PRESS KAILASH / 1889E
                </Text>
                <Text style={[styles.regularFontSize, { paddingLeft: 15 }]}>
                  Port of Discharge: CAT LAI, VIETNAM
                </Text>
              </View>
              <View style={[styles.halfWidthLeftSection]}>
                {" "}
                <Text style={[styles.regularFontSize, styles.contentPadding]}>
                  4. For Official Use
                </Text>
                <View style={styles.contentPadding}>
                  <View style={styles.row}>
                    <View style={styles.squareBullet}></View>
                    <Text
                      style={[
                        styles.regularFontSize,
                        {
                          paddingLeft: 35,
                          justifyContent: "space-between",
                          paddingRight: 10,
                        },
                      ]}
                    >
                      Preferential Tariff Treatment Given Under ASEAN-India Free
                      Trade Area Preferential Tariff
                    </Text>
                  </View>
                  <View style={{ flexDirection: "row-reverse" }}>
                    <Text
                      style={[
                        {
                          paddingLeft: 80,
                          borderTopWidth: 1,
                          paddingRight: 90,
                          marginRight: 20,
                          top: 18,
                        },
                      ]}
                    ></Text>
                  </View>
                  <View style={styles.row}>
                    <View style={[styles.squareBullet, { top: 22 }]}></View>
                    <Text
                      style={[
                        styles.regularFontSize,
                        {
                          paddingLeft: 35,
                          justifyContent: "space-between",
                          paddingRight: 10,
                          top: 22,
                        },
                      ]}
                    >
                      Preferential Tariff Treatment Not Given (Please state
                      reason/s)
                    </Text>
                  </View>
                  <View style={{ flexDirection: "row-reverse" }}>
                    <Text
                      style={[
                        {
                          paddingLeft: 80,
                          borderTopWidth: 1,
                          paddingRight: 90,
                          marginRight: 20,
                          top: 40,
                        },
                      ]}
                    ></Text>
                  </View>
                </View>
                <View
                  style={{
                    flexDirection: "row-reverse",
                    justifyContent: "flex-end",
                    width: "100%",
                  }}
                >
                  <Text
                    style={[
                      {
                        paddingLeft: 100,
                        borderTopWidth: 1,
                        paddingRight: 100,
                        marginLeft: 50,
                        top: 60,
                      },
                    ]}
                  ></Text>
                </View>
                <View style={styles.row}>
                  <Text
                    style={[
                      styles.regularFontSize,
                      {
                        paddingLeft: 35,
                        justifyContent: "space-between",
                        marginRight: 25,
                        top: 65,
                        textAlign: "center",
                      },
                    ]}
                  >
                    Signature of Authorised Signatory of the Importing Country
                  </Text>
                </View>
              </View>
            </View>

            <View>
              <View
                style={[
                  styles.tableContainer,
                  styles.mediumFontSize,
                  {
                    textAlign: "left",
                    height: 50,
                  },
                ]}
              >
                <View
                  style={[
                    styles.tableRow,
                    {
                      paddingLeft: 5,
                      paddingRight: 5,
                      flexDirection: "row",
                    },
                  ]}
                >
                  <Text
                    style={[
                      styles.contentPadding,
                      {
                        width: "10%",
                        justifyContent: "space-between",
                      },
                    ]}
                  >
                    5 . Item number
                  </Text>
                  <Text
                    style={[
                      styles.contentPadding,
                      {
                        borderLeft: 1,

                        paddingRight: 5,
                        width: "20%",
                        justifyContent: "space-between",
                      },
                    ]}
                  >
                    6. Marks and numbers of packages
                  </Text>
                  <Text
                    style={[
                      styles.contentPadding,
                      {
                        borderLeft: 1,

                        paddingRight: 5,
                        width: "45%",
                        justifyContent: "space-between",
                      },
                    ]}
                  >
                    7.Number and type of packages, description of goods
                    (including quantity where appropriate and HS number of the
                    importing country)
                  </Text>
                  <Text
                    style={[
                      styles.contentPadding,
                      {
                        borderLeft: 1,

                        paddingRight: 5,
                        width: "20%",
                        justifyContent: "space-between",
                      },
                    ]}
                  >
                    8. Origin criterion (see notes)
                  </Text>
                  <Text
                    style={[
                      styles.contentPadding,
                      {
                        borderLeft: 1,
                        paddingRight: 5,
                        width: "20%",
                        justifyContent: "space-between",
                      },
                    ]}
                  >
                    9. Gross weight or other quantity and value(FOB)
                  </Text>
                  <Text
                    style={[
                      styles.contentPadding,
                      {
                        borderLeft: 1,
                        paddingRight: 5,
                        width: "20%",
                        justifyContent: "space-between",
                      },
                    ]}
                  >
                    10. Number and date of invoices
                  </Text>
                </View>
              </View>
              {/* table data */}
              <View
                style={[
                  styles.tableContainer,
                  styles.mediumFontSize,
                  {
                    textAlign: "left",
                    height: 50,
                  },
                ]}
              >
                <View
                  style={[
                    styles.tableRow,
                    {
                      paddingLeft: 5,
                      paddingRight: 5,
                      flexDirection: "row",
                    },
                  ]}
                >
                  <Text
                    style={[
                      styles.contentPadding,
                      {
                        width: "10%",
                        justifyContent: "space-between",
                      },
                    ]}
                  >
                    1
                  </Text>
                  <Text
                    style={[
                      styles.contentPadding,
                      {
                        borderLeft: 1,

                        paddingRight: 5,
                        width: "20%",
                        justifyContent: "space-between",
                      },
                    ]}
                  >
                    AC THANH
                  </Text>
                  <Text
                    style={[
                      styles.contentPadding,
                      {
                        borderLeft: 1,

                        paddingRight: 5,
                        width: "45%",
                        justifyContent: "space-between",
                      },
                    ]}
                  >
                    TOTAL 2700 BAGS, INDIAN SONA MASOORI WHITE RICE (PACKING:IN
                    PP BAGS OF 50 KGS NET EACH) (NET WT: 135.000 MTS) HS CODE:
                    1006.30.90
                  </Text>
                  <Text
                    style={[
                      styles.contentPadding,
                      {
                        borderLeft: 1,

                        paddingRight: 5,
                        width: "20%",
                        justifyContent: "space-between",
                      },
                    ]}
                  >
                    "WO"
                  </Text>
                  <Text
                    style={[
                      styles.contentPadding,
                      {
                        borderLeft: 1,
                        paddingRight: 5,
                        width: "20%",
                        justifyContent: "space-between",
                      },
                    ]}
                  >
                    GORSS WT:135.27 MT , USD 695520.00
                  </Text>
                  <Text
                    style={[
                      styles.contentPadding,
                      {
                        borderLeft: 1,
                        paddingRight: 5,
                        width: "20%",
                        justifyContent: "space-between",
                      },
                    ]}
                  >
                    EXP/24-25/010 DT :17/12/2024
                  </Text>
                </View>
              </View>
            </View>

            <View style={[styles.sideBySideContainer, { height: 100 }]}></View>
            {/* 11 | 12 */}
            <View
              style={[
                styles.sideBySideContainer,
                { borderTop: 1, height: 155 },
              ]}
            >
              <View style={[styles.halfWidthRightSection]}>
                <Text style={[styles.regularFontSize, styles.contentPadding]}>
                  11. Declaration by the exporter
                </Text>
                <Text
                  style={[
                    styles.mediumFontSize,
                    styles.contentPadding,
                    {
                      paddingRight: 5,
                      justifyContent: "space-between",
                    },
                  ]}
                >
                  The undersigned hereby declares that the above details and
                  statement are correct; that all the goods were produced in
                </Text>
                <Text
                  style={[
                    styles.contentPadding,
                    styles.mediumFontSize,
                    {
                      paddingBottom: -50,
                      paddingRight: 5,
                      textAlign: "center",
                    },
                  ]}
                >
                  INDIA
                </Text>
                <Text
                  style={[
                    styles.boldText,
                    styles.contentPadding,
                    {
                      textAlign: "center",
                      paddingTop: -8,
                      paddingRight: 5,
                      justifyContent: "space-between",
                    },
                  ]}
                >
                  ....................................................................................................
                </Text>
                <Text
                  style={[
                    styles.contentPadding,
                    styles.mediumFontSize,
                    {
                      paddingTop: -10,
                      paddingRight: 5,
                      textAlign: "center",
                    },
                  ]}
                >
                  (Country)
                </Text>
                <Text
                  style={[
                    styles.mediumFontSize,
                    styles.contentPadding,
                    {
                      paddingRight: 5,
                      justifyContent: "space-between",
                    },
                  ]}
                >
                  and that they comply with the origin requirements specified
                  for these goods in the ASEAN-INDIA Free Trade Area
                  Preferential Tariff for the goods exported to
                </Text>
                <Text
                  style={[
                    styles.contentPadding,
                    styles.mediumFontSize,
                    {
                      paddingBottom: -50,
                      paddingRight: 5,
                      textAlign: "center",
                    },
                  ]}
                >
                  VITENAM
                </Text>
                <Text
                  style={[
                    styles.boldText,
                    styles.contentPadding,
                    {
                      textAlign: "center",
                      paddingTop: -8,
                      paddingRight: 5,
                      justifyContent: "space-between",
                    },
                  ]}
                >
                  ....................................................................................................
                </Text>
                <Text
                  style={[
                    styles.contentPadding,
                    styles.mediumFontSize,
                    {
                      paddingTop: -10,
                      paddingRight: 5,
                      textAlign: "center",
                    },
                  ]}
                >
                  (Importing Country)
                </Text>
                <Text
                  style={[
                    styles.contentPadding,
                    styles.mediumFontSize,
                    {
                      paddingRight: 5,
                      textAlign: "center",
                    },
                  ]}
                >
                  SURAT,GUJARTA,INDIA,04/01/2025
                </Text>
                <Text
                  style={[
                    styles.boldText,
                    styles.contentPadding,
                    {
                      paddingTop: -2,
                      textAlign: "center",
                      paddingRight: 5,
                    },
                  ]}
                >
                  ....................................................................................................
                </Text>
                <Text
                  style={[
                    styles.mediumFontSize,
                    styles.contentPadding,
                    {
                      textAlign: "center",
                      paddingRight: 5,
                      paddingTop: -3,
                      justifyContent: "space-between",
                    },
                  ]}
                >
                  Place and date, signature and Stamp of Certifying authority.
                </Text>
              </View>
              <View style={[styles.halfWidthLeftSection]}>
                <Text style={[styles.regularFontSize, styles.contentPadding]}>
                  12.Certification :
                </Text>
                <Text
                  style={[
                    styles.mediumFontSize,
                    styles.contentPadding,
                    {
                      paddingRight: 5,
                      justifyContent: "space-between",
                    },
                  ]}
                >
                  It is hereby certified,on the basis of control carried out,
                  that the declaration by the exporter is correct.
                </Text>
              </View>
            </View>

            {/* 13 */}
            <View>
              <Text style={[styles.mediumFontSize, { paddingLeft: 5 }]}>
                13. Where appropriate please tick:
              </Text>
              <View
                style={{
                  flexDirection: "row",
                  flexWrap: "wrap",
                  marginTop: 10,
                }}
              >
                {checkboxData.map((item, index) => (
                  <View
                    key={index}
                    style={{
                      flexDirection: "row",
                      alignItems: "center",
                      marginRight: 5,
                      marginLeft: 35,
                      paddingBottom: 8,
                    }}
                  >
                    <View style={styles.checkbox}>
                      {item.isChecked === true && (
                        <Text style={styles.checkMark}>✔</Text>
                      )}
                    </View>
                    <Text style={styles.mediumFontSize}>{item.label}</Text>
                  </View>
                ))}
              </View>
            </View>
          </View>
        </Page>

        {/* Page-9 */}
        <Page style={styles.page} size="A4">
          <Text style={[styles.contentPadding, styles.boldText]}>
            OVERLEAF NOTES
          </Text>
          <View style={[styles.sideBySideContainer]}>
            <View style={[styles.row]}>
              <Text
                style={[
                  styles.subContent,
                  styles.regularFontSize,
                  { marginRight: 10 },
                ]}
              >
                1.
              </Text>

              <Text
                style={[
                  styles.regularFontSize,
                  styles.subContent,
                  { marginLeft: 5, marginRight: 35 },
                ]}
              >
                Parties which accept this form for the purpose of preferential
                tariff treatment under the ASEAN-INDIA Free Trade Agreement
                (AIFTA):
              </Text>
            </View>
          </View>
          <View style={{ marginLeft: 40 }}>
            <View
              style={[
                styles.tableContainer,
                styles.mediumFontSize,
                {
                  top: 5,
                  textAlign: "left",
                  height: 20,
                  borderBottom: 0,
                  width: "100%",
                },
              ]}
            >
              <View
                style={[
                  styles.tableRow,
                  {
                    paddingLeft: 5,
                    paddingRight: 5,
                    flexDirection: "row",
                  },
                ]}
              >
                <Text
                  style={[
                    styles.regularFontSize,
                    {
                      paddingTop: 2,
                      width: "50%",
                      justifyContent: "space-between",
                    },
                  ]}
                >
                  BRUNEI DARUSSALAM
                </Text>
                <Text
                  style={[
                    styles.regularFontSize,
                    {
                      paddingRight: 5,
                      paddingTop: 2,
                      paddingLeft: 5,
                      width: "50%",
                      justifyContent: "space-between",
                    },
                  ]}
                >
                  CAMBODIA
                </Text>
                <Text
                  style={[
                    styles.regularFontSize,
                    {
                      paddingTop: 2,
                      paddingRight: 5,
                      width: "50%",
                      justifyContent: "space-between",
                    },
                  ]}
                >
                  INDONESIA
                </Text>
              </View>
            </View>

            <View
              style={[
                styles.tableContainer,
                styles.mediumFontSize,
                {
                  top: 5,
                  textAlign: "left",
                  height: 20,
                  borderBottom: 0,
                  width: "100%",
                },
              ]}
            >
              <View
                style={[
                  styles.tableRow,
                  {
                    paddingLeft: 5,
                    paddingRight: 5,
                    flexDirection: "row",
                  },
                ]}
              >
                <Text
                  style={[
                    styles.regularFontSize,
                    {
                      paddingTop: 2,
                      width: "50%",
                      justifyContent: "space-between",
                    },
                  ]}
                >
                  INDIA
                </Text>
                <Text
                  style={[
                    styles.regularFontSize,
                    {
                      paddingRight: 5,
                      paddingTop: 2,
                      paddingLeft: 5,
                      width: "50%",
                      justifyContent: "space-between",
                    },
                  ]}
                >
                  LAOS
                </Text>
                <Text
                  style={[
                    styles.regularFontSize,
                    {
                      paddingTop: 2,
                      paddingRight: 5,
                      width: "50%",
                      justifyContent: "space-between",
                    },
                  ]}
                >
                  MALASIYA
                </Text>
              </View>
            </View>

            <View
              style={[
                styles.tableContainer,
                styles.mediumFontSize,
                {
                  top: 5,
                  textAlign: "left",
                  height: 20,
                  borderBottom: 0,
                  width: "100%",
                },
              ]}
            >
              <View
                style={[
                  styles.tableRow,
                  {
                    paddingLeft: 5,
                    paddingRight: 5,
                    flexDirection: "row",
                  },
                ]}
              >
                <Text
                  style={[
                    styles.regularFontSize,
                    {
                      paddingTop: 2,
                      width: "50%",
                      justifyContent: "space-between",
                    },
                  ]}
                >
                  MYNAMMAR
                </Text>
                <Text
                  style={[
                    styles.regularFontSize,
                    {
                      paddingRight: 5,
                      paddingTop: 2,
                      paddingLeft: 5,
                      width: "50%",
                      justifyContent: "space-between",
                    },
                  ]}
                >
                  PHILLIPINES
                </Text>
                <Text
                  style={[
                    styles.regularFontSize,
                    {
                      paddingTop: 2,
                      paddingRight: 5,
                      width: "50%",
                      justifyContent: "space-between",
                    },
                  ]}
                >
                  SINGAPORE
                </Text>
              </View>
            </View>

            <View
              style={[
                styles.tableContainer,
                styles.mediumFontSize,
                {
                  top: 5,
                  textAlign: "left",
                  height: 20,
                  borderBottom: 0,
                  width: "100%",
                },
              ]}
            >
              <View
                style={[
                  styles.tableRow,
                  {
                    paddingLeft: 5,
                    paddingRight: 5,
                    flexDirection: "row",
                  },
                ]}
              >
                <Text
                  style={[
                    styles.regularFontSize,
                    {
                      paddingTop: 2,
                      width: "50%",
                      justifyContent: "space-between",
                    },
                  ]}
                >
                  THAILAND
                </Text>
                <Text
                  style={[
                    styles.regularFontSize,
                    {
                      paddingRight: 5,
                      paddingTop: 2,
                      paddingLeft: 5,
                      width: "100%",
                      justifyContent: "space-between",
                    },
                  ]}
                >
                  VITENAM
                </Text>
              </View>
            </View>
          </View>

          <View style={[styles.sideBySideContainer]}>
            <View style={[styles.row]}>
              <Text
                style={[
                  styles.subContent,
                  styles.regularFontSize,
                  { marginRight: 10 },
                ]}
              >
                2.
              </Text>
              <Text
                style={[
                  styles.regularFontSize,
                  styles.subContent,
                  { marginLeft: 5, marginRight: 35 },
                ]}
              >
                CONDITIONS: To enjoy preferential tariff under the AIFTA, goods
                sent to any Parties listed above:
              </Text>{" "}
            </View>
          </View>

          <View style={[styles.sideBySideContainer]}>
            <View style={[styles.row]}>
              <Text
                style={[
                  styles.subContent,
                  styles.regularFontSize,
                  { marginRight: 10, paddingLeft: 40 },
                ]}
              >
                (i)
              </Text>
              <Text
                style={[
                  styles.regularFontSize,
                  styles.subContent,
                  { marginLeft: 5, marginRight: 95 },
                ]}
              >
                must fall within a description of goods eligible for concessions
                in the Party of destination;
              </Text>{" "}
            </View>
          </View>
          <View style={[styles.sideBySideContainer]}>
            <View style={[styles.row]}>
              <Text
                style={[
                  styles.subContent,
                  styles.regularFontSize,
                  { marginRight: 10, paddingLeft: 40 },
                ]}
              >
                (ii)
              </Text>
              <Text
                style={[
                  styles.regularFontSize,
                  styles.subContent,
                  { marginLeft: 5, marginRight: 95 },
                ]}
              >
                must comply with the consignment conditions in accordance with
                Rule 8 of the AIFTA Rules of Origin; and
              </Text>{" "}
            </View>
          </View>
          <View style={[styles.sideBySideContainer]}>
            <View style={[styles.row]}>
              <Text
                style={[
                  styles.subContent,
                  styles.regularFontSize,
                  { marginRight: 10, paddingLeft: 37 },
                ]}
              >
                (iii)
              </Text>
              <Text
                style={[
                  styles.regularFontSize,
                  styles.subContent,
                  { marginLeft: 1, marginRight: 95 },
                ]}
              >
                must comply with the origin criteria in the AIFTA Rules of
                Origin.
              </Text>{" "}
            </View>
          </View>

          <View style={[styles.sideBySideContainer]}>
            <View style={[styles.row]}>
              <Text
                style={[
                  styles.subContent,
                  styles.regularFontSize,
                  { marginRight: 10 },
                ]}
              >
                3.
              </Text>
              <Text
                style={[
                  styles.regularFontSize,
                  styles.subContent,
                  { marginLeft: 5, marginRight: 35 },
                ]}
              >
                ORIGIN CRITERIA : For goods that meet the origin criteria, the
                exporter and/or producer must indicate in box 8 of this Form,
                the origin criteria met, in the manner shown in the following
                table:
              </Text>
            </View>
          </View>
          <View>
            <View
              style={[
                styles.tableContainer,
                styles.regularFontSize,
                {
                  textAlign: "left",
                  height: 33,
                  borderTop: 1,
                  marginTop: 5,
                  marginLeft: 30,
                  marginRight: 5,
                  borderRight: 1,
                  borderLeft: 1,
                },
              ]}
            >
              <View
                style={[
                  styles.tableRow,
                  {
                    paddingLeft: 5,
                    paddingRight: 5,
                    flexDirection: "row",
                  },
                ]}
              >
                <Text
                  style={[
                    {
                      paddingTop: 2,
                      borderRightWidth: 1,
                      width: "50%",
                      justifyContent: "space-between",
                    },
                  ]}
                >
                  Circumstances of production or manufacture in the first
                  country named in Box 11 of this form
                </Text>
                <Text
                  style={[
                    {
                      paddingRight: 5,
                      paddingTop: 2,
                      paddingLeft: 5,
                      width: "50%",
                      justifyContent: "space-between",
                    },
                  ]}
                >
                  Insert in Box 8
                </Text>
              </View>
            </View>
            <View
              style={[
                styles.tableContainer,
                styles.regularFontSize,
                {
                  textAlign: "left",
                  height: 35,
                  borderBottom: 1,
                  marginLeft: 30,
                  marginRight: 5,
                  borderRight: 1,
                  borderLeft: 1,
                },
              ]}
            >
              <View
                style={[
                  styles.tableRow,
                  {
                    paddingLeft: 5,
                    paddingRight: 5,
                    flexDirection: "row",
                  },
                ]}
              >
                <Text
                  style={[
                    {
                      paddingTop: 3,
                      borderRightWidth: 1,
                      width: "50%",
                      justifyContent: "space-between",
                    },
                  ]}
                >
                  (a) Goods wholly obtained or produced in the territory of the
                  exporting Party
                </Text>
                <Text
                  style={[
                    {
                      paddingRight: 5,
                      paddingTop: 2,
                      paddingLeft: 5,
                      width: "50%",
                      justifyContent: "space-between",
                    },
                  ]}
                >
                  "WO"
                </Text>
              </View>
            </View>

            <View
              style={[
                styles.tableContainer,
                styles.regularFontSize,
                {
                  textAlign: "left",
                  height: 35,
                  borderBottom: 1,
                  marginLeft: 30,
                  marginRight: 5,
                  borderRight: 1,
                  borderLeft: 1,
                },
              ]}
            >
              <View
                style={[
                  styles.tableRow,
                  {
                    paddingLeft: 5,
                    paddingRight: 5,
                    flexDirection: "row",
                  },
                ]}
              >
                <Text
                  style={[
                    {
                      paddingTop: 3,
                      borderRightWidth: 1,
                      width: "50%",
                      justifyContent: "space-between",
                    },
                  ]}
                >
                  (b) Goods satisfying Rule 4 (Not Wholly Produced or Obtained
                  Products) of the AIFTA Rules of Origin
                </Text>
                <Text
                  style={[
                    {
                      paddingRight: 5,
                      paddingTop: 2,
                      paddingLeft: 5,
                      width: "50%",
                      justifyContent: "space-between",
                    },
                  ]}
                >
                  "RVC [ ]% + CTSH”
                </Text>
              </View>
            </View>

            <View
              style={[
                styles.tableContainer,
                styles.regularFontSize,
                {
                  textAlign: "left",
                  height: 35,
                  borderBottom: 1,
                  marginLeft: 30,
                  marginRight: 5,
                  borderRight: 1,
                  borderLeft: 1,
                },
              ]}
            >
              <View
                style={[
                  styles.tableRow,
                  {
                    paddingLeft: 5,
                    paddingRight: 5,
                    flexDirection: "row",
                  },
                ]}
              >
                <Text
                  style={[
                    {
                      paddingTop: 3,
                      borderRightWidth: 1,
                      width: "50%",
                      justifyContent: "space-between",
                    },
                  ]}
                >
                  (c) Goods satisfying Rule 6 (Product Specific Rules) of the
                  AIFTA Rules of Origin
                </Text>
                <Text
                  style={[
                    {
                      paddingRight: 5,
                      paddingTop: 2,
                      paddingLeft: 5,
                      width: "50%",
                      justifyContent: "space-between",
                    },
                  ]}
                >
                  Appropriate qualifying criteria
                </Text>
              </View>
            </View>
          </View>

          <View style={[styles.sideBySideContainer]}>
            <View style={[styles.row]}>
              <Text
                style={[
                  styles.subContent,
                  styles.regularFontSize,
                  { marginRight: 10 },
                ]}
              >
                4.
              </Text>

              <Text
                style={[
                  styles.regularFontSize,
                  styles.subContent,
                  { marginLeft: 5, marginRight: 35 },
                ]}
              >
                EACH ARTICLE MUST QUALIFY : It should be noted that all the
                goods in a consignment must qualify separately in their own
                right. This is of particular relevance when similar articles of
                different sizes or spare parts are sent.
              </Text>
            </View>
          </View>

          <View style={[styles.sideBySideContainer]}>
            <View style={[styles.row]}>
              <Text
                style={[
                  styles.subContent,
                  styles.regularFontSize,
                  { marginRight: 10 },
                ]}
              >
                5.
              </Text>

              <Text
                style={[
                  styles.regularFontSize,
                  styles.subContent,
                  { marginLeft: 5, marginRight: 35 },
                ]}
              >
                DESCRIPTION OF GOODS : The description of goods must be
                sufficiently detailed to enable the goods to be identified by
                the Customs Officers examining them. Name of manufacturer, any
                trade mark shall also be specified.
              </Text>
            </View>
          </View>

          <View style={[styles.sideBySideContainer]}>
            <View style={[styles.row]}>
              <Text
                style={[
                  styles.subContent,
                  styles.regularFontSize,
                  { marginRight: 10 },
                ]}
              >
                6.
              </Text>

              <Text
                style={[
                  styles.regularFontSize,
                  styles.subContent,
                  { marginLeft: 5, marginRight: 35 },
                ]}
              >
                HARMONIZED SYSTEM NUMBER : The Harmonized System number shall be
                that of importing Party.
              </Text>
            </View>
          </View>

          <View style={[styles.sideBySideContainer]}>
            <View style={[styles.row]}>
              <Text
                style={[
                  styles.subContent,
                  styles.regularFontSize,
                  { marginRight: 10 },
                ]}
              >
                7.
              </Text>

              <Text
                style={[
                  styles.regularFontSize,
                  styles.subContent,
                  { marginLeft: 5, marginRight: 35 },
                ]}
              >
                EXPORTER : The term “Exporter” in Box 11 may include the
                manufacturer or the producer.
              </Text>
            </View>
          </View>

          <View style={[styles.sideBySideContainer]}>
            <View style={[styles.row]}>
              <Text
                style={[
                  styles.subContent,
                  styles.regularFontSize,
                  { marginRight: 10 },
                ]}
              >
                8.
              </Text>

              <Text
                style={[
                  styles.regularFontSize,
                  styles.subContent,
                  { marginLeft: 5, marginRight: 35 },
                ]}
              >
                FOR OFFICIAL USE : The Customs Authority of the importing Party
                must indicate ( ) in the relevant boxes in column 4 whether or
                not preferential tariff is accorded.
              </Text>
            </View>
          </View>

          <View style={[styles.sideBySideContainer]}>
            <View style={[styles.row]}>
              <Text
                style={[
                  styles.subContent,
                  styles.regularFontSize,
                  { marginRight: 10 },
                ]}
              >
                9.
              </Text>

              <Text
                style={[
                  styles.regularFontSize,
                  styles.subContent,
                  { marginLeft: 4, marginRight: 35 },
                ]}
              >
                {" "}
                THIRD COUNTRY INVOICING : In cases where invoices are issued by
                a third country, “Third Country Invoicing” in Box 13 should be
                ticked ( ) and such information as name and country of the
                company issuing the invoice shall be indicated in Box 7.
              </Text>
            </View>
          </View>

          <View style={[styles.sideBySideContainer]}>
            <View style={[styles.row]}>
              <Text
                style={[
                  styles.subContent,
                  styles.regularFontSize,
                  { marginRight: 10 },
                ]}
              >
                10.
              </Text>

              <Text
                style={[
                  styles.regularFontSize,
                  styles.subContent,
                  { marginLeft: 1, marginRight: 35 },
                ]}
              >
                EXHIBITIONS : In cases where goods are sent from the territory
                of the exporting Party for exhibition in another country and
                sold during or after the exhibition for importation into the
                territory of a Party, in accordance with Article 21 of the
                Operational Certification Procedures, “Exhibitions” in Box 13
                should be ticked ( ) and the name and address of the exhibition
                indicated in Box 2
              </Text>
            </View>
          </View>

          <View style={[styles.sideBySideContainer]}>
            <View style={[styles.row]}>
              <Text
                style={[
                  styles.subContent,
                  styles.regularFontSize,
                  { marginRight: 10 },
                ]}
              >
                11.
              </Text>

              <Text
                style={[
                  styles.regularFontSize,
                  styles.subContent,
                  { marginLeft: 1, marginRight: 35 },
                ]}
              >
                BACK-TO-BACK CERTIFICATE OF ORIGIN : In cases of Back-to-Back
                CO, in accordance with Article 11 of the Operational
                Certification Procedures, “Back-to-Back CO” in Box13 should be
                ticked ( ). The name of original exporting Party to be indicated
                in Box 11 and the date of the issuance of CO and the reference
                number will be indicated in Box 7.
              </Text>
            </View>
          </View>
        </Page>

        {/* Page-10 */}
        <Page style={styles.page} size="A4">
          <View>
            <Text style={[styles.boldText, { textAlign: "center" }]}>
              (QUADRUPLICATE)
            </Text>
          </View>
          <View style={styles.fullpage}>
            <View style={[styles.sideBySideContainer, { height: "150px" }]}>
              <View style={[styles.halfWidthRightSection]}>
                <Text
                  style={[
                    styles.regularFontSize,
                    styles.contentPadding,
                    { justifyContent: "space-between" },
                  ]}
                >
                  1. Goods consigned from (Exporter's business name, address,
                  country)
                </Text>
                <Text style={[styles.regularFontSize, { paddingLeft: 15 }]}>
                  ENFINY EXIM LLP
                </Text>
                <Text style={[styles.regularFontSize, { paddingLeft: 15 }]}>
                  PLOT NO-36, GROUND FLOOR, SHUBHAM INDUSTRY-2 SANIYA HEMED,
                  SURAT, GUJARAT (395006)
                </Text>
                <Text style={[styles.regularFontSize, { paddingLeft: 15 }]}>
                  INDIA
                </Text>
                <View
                  style={{
                    borderTop: 1,
                  }}
                >
                  <Text
                    style={[
                      styles.regularFontSize,
                      styles.contentPadding,
                      { justifyContent: "space-between" },
                    ]}
                  >
                    2. Goods consigned to (Consignee's name, address, country):
                  </Text>
                  <Text style={[styles.regularFontSize, { paddingLeft: 15 }]}>
                    DAC THANH FOOD COMPANY LIMITED
                  </Text>
                  <Text
                    style={[
                      styles.regularFontSize,
                      { justifyContent: "space-between", paddingLeft: 15 },
                    ]}
                  >
                    BINH TINH QUARTER, BINH PHU TOWNLET, CAI LAY DISTRICT, TIEN
                    GIANG PROVINCE, VIETNAM,TAX ID: 1201545414
                  </Text>
                  <Text style={[styles.regularFontSize, { paddingLeft: 15 }]}>
                    VIETNAM
                  </Text>
                </View>
              </View>
              <View style={[styles.halfWidthLeftSection]}>
                <Text style={[styles.regularFontSize, styles.contentPadding]}>
                  Reference No. EIC/2024/013/1026343A
                </Text>
                <Text
                  style={[
                    styles.boldText,
                    styles.contentPadding,
                    { textAlign: "center" },
                  ]}
                >
                  ASEAN-INDIA FREE TRADE AREA
                </Text>
                <Text
                  style={[
                    styles.boldText,
                    styles.contentPadding,
                    { textAlign: "center" },
                  ]}
                >
                  PREFERENTIAL TARIFF
                </Text>
                <Text
                  style={[
                    styles.boldText,
                    styles.contentPadding,
                    { textAlign: "center" },
                  ]}
                >
                  CERTIFICATE OF ORIGIN
                </Text>
                <Text
                  style={[
                    styles.regularFontSize,
                    styles.contentPadding,
                    { textAlign: "center" },
                  ]}
                >
                  (Combined Declaration and Certificate)
                </Text>
                <Text
                  style={[
                    styles.boldText,
                    styles.contentPadding,
                    { textAlign: "center" },
                  ]}
                >
                  FORM AI
                </Text>
                <Text
                  style={[
                    styles.regularFontSize,
                    styles.contentPadding,
                    {
                      textAlign: "center",
                      top: 5,
                    },
                  ]}
                >
                  Issued in:-
                  <Text
                    style={[
                      styles.boldText,
                      styles.contentPadding,
                      {
                        textAlign: "center",
                        textDecoration: "underline",
                        textDecorationStyle: "dotted",
                      },
                    ]}
                  >
                    INDIA
                  </Text>
                </Text>
                <Text
                  style={[
                    styles.regularFontSize,
                    styles.contentPadding,
                    {
                      top: 4,
                      textAlign: "center",
                    },
                  ]}
                >
                  (Country)
                </Text>
                <Text
                  style={[
                    styles.regularFontSize,
                    styles.contentPadding,
                    {
                      top: 4,
                      textAlign: "center",
                    },
                  ]}
                >
                  (See Notes Overleaf)
                </Text>
              </View>
            </View>

            <View style={[styles.sideBySideContainer, { height: "160px" }]}>
              <View style={[styles.halfWidthRightSection]}>
                <Text style={[styles.regularFontSize, styles.contentPadding]}>
                  3. Means of transport and route (as far as known)
                </Text>
                <Text style={[styles.regularFontSize, { paddingLeft: 15 }]}>
                  Departure date: 29-12-2024
                </Text>

                <Text style={[styles.regularFontSize, { paddingLeft: 15 }]}>
                  Vessel's name/Aircraft etc.: X PRESS KAILASH / 1889E
                </Text>
                <Text style={[styles.regularFontSize, { paddingLeft: 15 }]}>
                  Port of Discharge: CAT LAI, VIETNAM
                </Text>
              </View>
              <View style={[styles.halfWidthLeftSection]}>
                {" "}
                <Text style={[styles.regularFontSize, styles.contentPadding]}>
                  4. For Official Use
                </Text>
                <View style={styles.contentPadding}>
                  <View style={styles.row}>
                    <View style={styles.squareBullet}></View>
                    <Text
                      style={[
                        styles.regularFontSize,
                        {
                          paddingLeft: 35,
                          justifyContent: "space-between",
                          paddingRight: 10,
                        },
                      ]}
                    >
                      Preferential Tariff Treatment Given Under ASEAN-India Free
                      Trade Area Preferential Tariff
                    </Text>
                  </View>
                  <View style={{ flexDirection: "row-reverse" }}>
                    <Text
                      style={[
                        {
                          paddingLeft: 80,
                          borderTopWidth: 1,
                          paddingRight: 90,
                          marginRight: 20,
                          top: 18,
                        },
                      ]}
                    ></Text>
                  </View>
                  <View style={styles.row}>
                    <View style={[styles.squareBullet, { top: 22 }]}></View>
                    <Text
                      style={[
                        styles.regularFontSize,
                        {
                          paddingLeft: 35,
                          justifyContent: "space-between",
                          paddingRight: 10,
                          top: 22,
                        },
                      ]}
                    >
                      Preferential Tariff Treatment Not Given (Please state
                      reason/s)
                    </Text>
                  </View>
                  <View style={{ flexDirection: "row-reverse" }}>
                    <Text
                      style={[
                        {
                          paddingLeft: 80,
                          borderTopWidth: 1,
                          paddingRight: 90,
                          marginRight: 20,
                          top: 40,
                        },
                      ]}
                    ></Text>
                  </View>
                </View>
                <View
                  style={{
                    flexDirection: "row-reverse",
                    justifyContent: "flex-end",
                    width: "100%",
                  }}
                >
                  <Text
                    style={[
                      {
                        paddingLeft: 100,
                        borderTopWidth: 1,
                        paddingRight: 100,
                        marginLeft: 50,
                        top: 60,
                      },
                    ]}
                  ></Text>
                </View>
                <View style={styles.row}>
                  <Text
                    style={[
                      styles.regularFontSize,
                      {
                        paddingLeft: 35,
                        justifyContent: "space-between",
                        marginRight: 25,
                        top: 65,
                        textAlign: "center",
                      },
                    ]}
                  >
                    Signature of Authorised Signatory of the Importing Country
                  </Text>
                </View>
              </View>
            </View>

            <View>
              <View
                style={[
                  styles.tableContainer,
                  styles.mediumFontSize,
                  {
                    textAlign: "left",
                    height: 50,
                  },
                ]}
              >
                <View
                  style={[
                    styles.tableRow,
                    {
                      paddingLeft: 5,
                      paddingRight: 5,
                      flexDirection: "row",
                    },
                  ]}
                >
                  <Text
                    style={[
                      styles.contentPadding,
                      {
                        width: "10%",
                        justifyContent: "space-between",
                      },
                    ]}
                  >
                    5 . Item number
                  </Text>
                  <Text
                    style={[
                      styles.contentPadding,
                      {
                        borderLeft: 1,

                        paddingRight: 5,
                        width: "20%",
                        justifyContent: "space-between",
                      },
                    ]}
                  >
                    6. Marks and numbers of packages
                  </Text>
                  <Text
                    style={[
                      styles.contentPadding,
                      {
                        borderLeft: 1,

                        paddingRight: 5,
                        width: "45%",
                        justifyContent: "space-between",
                      },
                    ]}
                  >
                    7.Number and type of packages, description of goods
                    (including quantity where appropriate and HS number of the
                    importing country)
                  </Text>
                  <Text
                    style={[
                      styles.contentPadding,
                      {
                        borderLeft: 1,

                        paddingRight: 5,
                        width: "20%",
                        justifyContent: "space-between",
                      },
                    ]}
                  >
                    8. Origin criterion (see notes)
                  </Text>
                  <Text
                    style={[
                      styles.contentPadding,
                      {
                        borderLeft: 1,
                        paddingRight: 5,
                        width: "20%",
                        justifyContent: "space-between",
                      },
                    ]}
                  >
                    9. Gross weight or other quantity and value(FOB)
                  </Text>
                  <Text
                    style={[
                      styles.contentPadding,
                      {
                        borderLeft: 1,
                        paddingRight: 5,
                        width: "20%",
                        justifyContent: "space-between",
                      },
                    ]}
                  >
                    10. Number and date of invoices
                  </Text>
                </View>
              </View>
              {/* table data */}
              <View
                style={[
                  styles.tableContainer,
                  styles.mediumFontSize,
                  {
                    textAlign: "left",
                    height: 50,
                  },
                ]}
              >
                <View
                  style={[
                    styles.tableRow,
                    {
                      paddingLeft: 5,
                      paddingRight: 5,
                      flexDirection: "row",
                    },
                  ]}
                >
                  <Text
                    style={[
                      styles.contentPadding,
                      {
                        width: "10%",
                        justifyContent: "space-between",
                      },
                    ]}
                  >
                    1
                  </Text>
                  <Text
                    style={[
                      styles.contentPadding,
                      {
                        borderLeft: 1,

                        paddingRight: 5,
                        width: "20%",
                        justifyContent: "space-between",
                      },
                    ]}
                  >
                    AC THANH
                  </Text>
                  <Text
                    style={[
                      styles.contentPadding,
                      {
                        borderLeft: 1,

                        paddingRight: 5,
                        width: "45%",
                        justifyContent: "space-between",
                      },
                    ]}
                  >
                    TOTAL 2700 BAGS, INDIAN SONA MASOORI WHITE RICE (PACKING:IN
                    PP BAGS OF 50 KGS NET EACH) (NET WT: 135.000 MTS) HS CODE:
                    1006.30.90
                  </Text>
                  <Text
                    style={[
                      styles.contentPadding,
                      {
                        borderLeft: 1,

                        paddingRight: 5,
                        width: "20%",
                        justifyContent: "space-between",
                      },
                    ]}
                  >
                    "WO"
                  </Text>
                  <Text
                    style={[
                      styles.contentPadding,
                      {
                        borderLeft: 1,
                        paddingRight: 5,
                        width: "20%",
                        justifyContent: "space-between",
                      },
                    ]}
                  >
                    GORSS WT:135.27 MT , USD 695520.00
                  </Text>
                  <Text
                    style={[
                      styles.contentPadding,
                      {
                        borderLeft: 1,
                        paddingRight: 5,
                        width: "20%",
                        justifyContent: "space-between",
                      },
                    ]}
                  >
                    EXP/24-25/010 DT :17/12/2024
                  </Text>
                </View>
              </View>
            </View>

            <View style={[styles.sideBySideContainer, { height: 100 }]}></View>
            {/* 11 | 12 */}
            <View
              style={[
                styles.sideBySideContainer,
                { borderTop: 1, height: 155 },
              ]}
            >
              <View style={[styles.halfWidthRightSection]}>
                <Text style={[styles.regularFontSize, styles.contentPadding]}>
                  11. Declaration by the exporter
                </Text>
                <Text
                  style={[
                    styles.mediumFontSize,
                    styles.contentPadding,
                    {
                      paddingRight: 5,
                      justifyContent: "space-between",
                    },
                  ]}
                >
                  The undersigned hereby declares that the above details and
                  statement are correct; that all the goods were produced in
                </Text>
                <Text
                  style={[
                    styles.contentPadding,
                    styles.mediumFontSize,
                    {
                      paddingBottom: -50,
                      paddingRight: 5,
                      textAlign: "center",
                    },
                  ]}
                >
                  INDIA
                </Text>
                <Text
                  style={[
                    styles.boldText,
                    styles.contentPadding,
                    {
                      textAlign: "center",
                      paddingTop: -8,
                      paddingRight: 5,
                      justifyContent: "space-between",
                    },
                  ]}
                >
                  ....................................................................................................
                </Text>
                <Text
                  style={[
                    styles.contentPadding,
                    styles.mediumFontSize,
                    {
                      paddingTop: -10,
                      paddingRight: 5,
                      textAlign: "center",
                    },
                  ]}
                >
                  (Country)
                </Text>
                <Text
                  style={[
                    styles.mediumFontSize,
                    styles.contentPadding,
                    {
                      paddingRight: 5,
                      justifyContent: "space-between",
                    },
                  ]}
                >
                  and that they comply with the origin requirements specified
                  for these goods in the ASEAN-INDIA Free Trade Area
                  Preferential Tariff for the goods exported to
                </Text>
                <Text
                  style={[
                    styles.contentPadding,
                    styles.mediumFontSize,
                    {
                      paddingBottom: -50,
                      paddingRight: 5,
                      textAlign: "center",
                    },
                  ]}
                >
                  VITENAM
                </Text>
                <Text
                  style={[
                    styles.boldText,
                    styles.contentPadding,
                    {
                      textAlign: "center",
                      paddingTop: -8,
                      paddingRight: 5,
                      justifyContent: "space-between",
                    },
                  ]}
                >
                  ....................................................................................................
                </Text>
                <Text
                  style={[
                    styles.contentPadding,
                    styles.mediumFontSize,
                    {
                      paddingTop: -10,
                      paddingRight: 5,
                      textAlign: "center",
                    },
                  ]}
                >
                  (Importing Country)
                </Text>
                <Text
                  style={[
                    styles.contentPadding,
                    styles.mediumFontSize,
                    {
                      paddingRight: 5,
                      textAlign: "center",
                    },
                  ]}
                >
                  SURAT,GUJARTA,INDIA,04/01/2025
                </Text>
                <Text
                  style={[
                    styles.boldText,
                    styles.contentPadding,
                    {
                      paddingTop: -2,
                      textAlign: "center",
                      paddingRight: 5,
                    },
                  ]}
                >
                  ....................................................................................................
                </Text>
                <Text
                  style={[
                    styles.mediumFontSize,
                    styles.contentPadding,
                    {
                      textAlign: "center",
                      paddingRight: 5,
                      paddingTop: -3,
                      justifyContent: "space-between",
                    },
                  ]}
                >
                  Place and date, signature and Stamp of Certifying authority.
                </Text>
              </View>
              <View style={[styles.halfWidthLeftSection]}>
                <Text style={[styles.regularFontSize, styles.contentPadding]}>
                  12.Certification :
                </Text>
                <Text
                  style={[
                    styles.mediumFontSize,
                    styles.contentPadding,
                    {
                      paddingRight: 5,
                      justifyContent: "space-between",
                    },
                  ]}
                >
                  It is hereby certified,on the basis of control carried out,
                  that the declaration by the exporter is correct.
                </Text>
              </View>
            </View>

            {/* 13 */}
            <View>
              <Text style={[styles.mediumFontSize, { paddingLeft: 5 }]}>
                13. Where appropriate please tick:
              </Text>
              <View
                style={{
                  flexDirection: "row",
                  flexWrap: "wrap",
                  marginTop: 10,
                }}
              >
                {checkboxData.map((item, index) => (
                  <View
                    key={index}
                    style={{
                      flexDirection: "row",
                      alignItems: "center",
                      marginRight: 5,
                      marginLeft: 35,
                      paddingBottom: 8,
                    }}
                  >
                    <View style={styles.checkbox}>
                      {item.isChecked === true && (
                        <Text style={styles.checkMark}>✔</Text>
                      )}
                    </View>
                    <Text style={styles.mediumFontSize}>{item.label}</Text>
                  </View>
                ))}
              </View>
            </View>
          </View>
        </Page>

        {/* Page-11 */}
        <Page style={styles.page} size="A4">
          <Text style={[styles.contentPadding, styles.boldText]}>
            OVERLEAF NOTES
          </Text>
          <View style={[styles.sideBySideContainer]}>
            <View style={[styles.row]}>
              <Text
                style={[
                  styles.subContent,
                  styles.regularFontSize,
                  { marginRight: 10 },
                ]}
              >
                1.
              </Text>

              <Text
                style={[
                  styles.regularFontSize,
                  styles.subContent,
                  { marginLeft: 5, marginRight: 35 },
                ]}
              >
                Parties which accept this form for the purpose of preferential
                tariff treatment under the ASEAN-INDIA Free Trade Agreement
                (AIFTA):
              </Text>
            </View>
          </View>
          <View style={{ marginLeft: 40 }}>
            <View
              style={[
                styles.tableContainer,
                styles.mediumFontSize,
                {
                  top: 5,
                  textAlign: "left",
                  height: 20,
                  borderBottom: 0,
                  width: "100%",
                },
              ]}
            >
              <View
                style={[
                  styles.tableRow,
                  {
                    paddingLeft: 5,
                    paddingRight: 5,
                    flexDirection: "row",
                  },
                ]}
              >
                <Text
                  style={[
                    styles.regularFontSize,
                    {
                      paddingTop: 2,
                      width: "50%",
                      justifyContent: "space-between",
                    },
                  ]}
                >
                  BRUNEI DARUSSALAM
                </Text>
                <Text
                  style={[
                    styles.regularFontSize,
                    {
                      paddingRight: 5,
                      paddingTop: 2,
                      paddingLeft: 5,
                      width: "50%",
                      justifyContent: "space-between",
                    },
                  ]}
                >
                  CAMBODIA
                </Text>
                <Text
                  style={[
                    styles.regularFontSize,
                    {
                      paddingTop: 2,
                      paddingRight: 5,
                      width: "50%",
                      justifyContent: "space-between",
                    },
                  ]}
                >
                  INDONESIA
                </Text>
              </View>
            </View>

            <View
              style={[
                styles.tableContainer,
                styles.mediumFontSize,
                {
                  top: 5,
                  textAlign: "left",
                  height: 20,
                  borderBottom: 0,
                  width: "100%",
                },
              ]}
            >
              <View
                style={[
                  styles.tableRow,
                  {
                    paddingLeft: 5,
                    paddingRight: 5,
                    flexDirection: "row",
                  },
                ]}
              >
                <Text
                  style={[
                    styles.regularFontSize,
                    {
                      paddingTop: 2,
                      width: "50%",
                      justifyContent: "space-between",
                    },
                  ]}
                >
                  INDIA
                </Text>
                <Text
                  style={[
                    styles.regularFontSize,
                    {
                      paddingRight: 5,
                      paddingTop: 2,
                      paddingLeft: 5,
                      width: "50%",
                      justifyContent: "space-between",
                    },
                  ]}
                >
                  LAOS
                </Text>
                <Text
                  style={[
                    styles.regularFontSize,
                    {
                      paddingTop: 2,
                      paddingRight: 5,
                      width: "50%",
                      justifyContent: "space-between",
                    },
                  ]}
                >
                  MALASIYA
                </Text>
              </View>
            </View>

            <View
              style={[
                styles.tableContainer,
                styles.mediumFontSize,
                {
                  top: 5,
                  textAlign: "left",
                  height: 20,
                  borderBottom: 0,
                  width: "100%",
                },
              ]}
            >
              <View
                style={[
                  styles.tableRow,
                  {
                    paddingLeft: 5,
                    paddingRight: 5,
                    flexDirection: "row",
                  },
                ]}
              >
                <Text
                  style={[
                    styles.regularFontSize,
                    {
                      paddingTop: 2,
                      width: "50%",
                      justifyContent: "space-between",
                    },
                  ]}
                >
                  MYNAMMAR
                </Text>
                <Text
                  style={[
                    styles.regularFontSize,
                    {
                      paddingRight: 5,
                      paddingTop: 2,
                      paddingLeft: 5,
                      width: "50%",
                      justifyContent: "space-between",
                    },
                  ]}
                >
                  PHILLIPINES
                </Text>
                <Text
                  style={[
                    styles.regularFontSize,
                    {
                      paddingTop: 2,
                      paddingRight: 5,
                      width: "50%",
                      justifyContent: "space-between",
                    },
                  ]}
                >
                  SINGAPORE
                </Text>
              </View>
            </View>

            <View
              style={[
                styles.tableContainer,
                styles.mediumFontSize,
                {
                  top: 5,
                  textAlign: "left",
                  height: 20,
                  borderBottom: 0,
                  width: "100%",
                },
              ]}
            >
              <View
                style={[
                  styles.tableRow,
                  {
                    paddingLeft: 5,
                    paddingRight: 5,
                    flexDirection: "row",
                  },
                ]}
              >
                <Text
                  style={[
                    styles.regularFontSize,
                    {
                      paddingTop: 2,
                      width: "50%",
                      justifyContent: "space-between",
                    },
                  ]}
                >
                  THAILAND
                </Text>
                <Text
                  style={[
                    styles.regularFontSize,
                    {
                      paddingRight: 5,
                      paddingTop: 2,
                      paddingLeft: 5,
                      width: "100%",
                      justifyContent: "space-between",
                    },
                  ]}
                >
                  VITENAM
                </Text>
              </View>
            </View>
          </View>

          <View style={[styles.sideBySideContainer]}>
            <View style={[styles.row]}>
              <Text
                style={[
                  styles.subContent,
                  styles.regularFontSize,
                  { marginRight: 10 },
                ]}
              >
                2.
              </Text>
              <Text
                style={[
                  styles.regularFontSize,
                  styles.subContent,
                  { marginLeft: 5, marginRight: 35 },
                ]}
              >
                CONDITIONS: To enjoy preferential tariff under the AIFTA, goods
                sent to any Parties listed above:
              </Text>{" "}
            </View>
          </View>

          <View style={[styles.sideBySideContainer]}>
            <View style={[styles.row]}>
              <Text
                style={[
                  styles.subContent,
                  styles.regularFontSize,
                  { marginRight: 10, paddingLeft: 40 },
                ]}
              >
                (i)
              </Text>
              <Text
                style={[
                  styles.regularFontSize,
                  styles.subContent,
                  { marginLeft: 5, marginRight: 95 },
                ]}
              >
                must fall within a description of goods eligible for concessions
                in the Party of destination;
              </Text>{" "}
            </View>
          </View>
          <View style={[styles.sideBySideContainer]}>
            <View style={[styles.row]}>
              <Text
                style={[
                  styles.subContent,
                  styles.regularFontSize,
                  { marginRight: 10, paddingLeft: 40 },
                ]}
              >
                (ii)
              </Text>
              <Text
                style={[
                  styles.regularFontSize,
                  styles.subContent,
                  { marginLeft: 5, marginRight: 95 },
                ]}
              >
                must comply with the consignment conditions in accordance with
                Rule 8 of the AIFTA Rules of Origin; and
              </Text>{" "}
            </View>
          </View>
          <View style={[styles.sideBySideContainer]}>
            <View style={[styles.row]}>
              <Text
                style={[
                  styles.subContent,
                  styles.regularFontSize,
                  { marginRight: 10, paddingLeft: 37 },
                ]}
              >
                (iii)
              </Text>
              <Text
                style={[
                  styles.regularFontSize,
                  styles.subContent,
                  { marginLeft: 1, marginRight: 95 },
                ]}
              >
                must comply with the origin criteria in the AIFTA Rules of
                Origin.
              </Text>{" "}
            </View>
          </View>

          <View style={[styles.sideBySideContainer]}>
            <View style={[styles.row]}>
              <Text
                style={[
                  styles.subContent,
                  styles.regularFontSize,
                  { marginRight: 10 },
                ]}
              >
                3.
              </Text>
              <Text
                style={[
                  styles.regularFontSize,
                  styles.subContent,
                  { marginLeft: 5, marginRight: 35 },
                ]}
              >
                ORIGIN CRITERIA : For goods that meet the origin criteria, the
                exporter and/or producer must indicate in box 8 of this Form,
                the origin criteria met, in the manner shown in the following
                table:
              </Text>
            </View>
          </View>
          <View>
            <View
              style={[
                styles.tableContainer,
                styles.regularFontSize,
                {
                  textAlign: "left",
                  height: 33,
                  borderTop: 1,
                  marginTop: 5,
                  marginLeft: 30,
                  marginRight: 5,
                  borderRight: 1,
                  borderLeft: 1,
                },
              ]}
            >
              <View
                style={[
                  styles.tableRow,
                  {
                    paddingLeft: 5,
                    paddingRight: 5,
                    flexDirection: "row",
                  },
                ]}
              >
                <Text
                  style={[
                    {
                      paddingTop: 2,
                      borderRightWidth: 1,
                      width: "50%",
                      justifyContent: "space-between",
                    },
                  ]}
                >
                  Circumstances of production or manufacture in the first
                  country named in Box 11 of this form
                </Text>
                <Text
                  style={[
                    {
                      paddingRight: 5,
                      paddingTop: 2,
                      paddingLeft: 5,
                      width: "50%",
                      justifyContent: "space-between",
                    },
                  ]}
                >
                  Insert in Box 8
                </Text>
              </View>
            </View>
            <View
              style={[
                styles.tableContainer,
                styles.regularFontSize,
                {
                  textAlign: "left",
                  height: 35,
                  borderBottom: 1,
                  marginLeft: 30,
                  marginRight: 5,
                  borderRight: 1,
                  borderLeft: 1,
                },
              ]}
            >
              <View
                style={[
                  styles.tableRow,
                  {
                    paddingLeft: 5,
                    paddingRight: 5,
                    flexDirection: "row",
                  },
                ]}
              >
                <Text
                  style={[
                    {
                      paddingTop: 3,
                      borderRightWidth: 1,
                      width: "50%",
                      justifyContent: "space-between",
                    },
                  ]}
                >
                  (a) Goods wholly obtained or produced in the territory of the
                  exporting Party
                </Text>
                <Text
                  style={[
                    {
                      paddingRight: 5,
                      paddingTop: 2,
                      paddingLeft: 5,
                      width: "50%",
                      justifyContent: "space-between",
                    },
                  ]}
                >
                  "WO"
                </Text>
              </View>
            </View>

            <View
              style={[
                styles.tableContainer,
                styles.regularFontSize,
                {
                  textAlign: "left",
                  height: 35,
                  borderBottom: 1,
                  marginLeft: 30,
                  marginRight: 5,
                  borderRight: 1,
                  borderLeft: 1,
                },
              ]}
            >
              <View
                style={[
                  styles.tableRow,
                  {
                    paddingLeft: 5,
                    paddingRight: 5,
                    flexDirection: "row",
                  },
                ]}
              >
                <Text
                  style={[
                    {
                      paddingTop: 3,
                      borderRightWidth: 1,
                      width: "50%",
                      justifyContent: "space-between",
                    },
                  ]}
                >
                  (b) Goods satisfying Rule 4 (Not Wholly Produced or Obtained
                  Products) of the AIFTA Rules of Origin
                </Text>
                <Text
                  style={[
                    {
                      paddingRight: 5,
                      paddingTop: 2,
                      paddingLeft: 5,
                      width: "50%",
                      justifyContent: "space-between",
                    },
                  ]}
                >
                  "RVC [ ]% + CTSH”
                </Text>
              </View>
            </View>

            <View
              style={[
                styles.tableContainer,
                styles.regularFontSize,
                {
                  textAlign: "left",
                  height: 35,
                  borderBottom: 1,
                  marginLeft: 30,
                  marginRight: 5,
                  borderRight: 1,
                  borderLeft: 1,
                },
              ]}
            >
              <View
                style={[
                  styles.tableRow,
                  {
                    paddingLeft: 5,
                    paddingRight: 5,
                    flexDirection: "row",
                  },
                ]}
              >
                <Text
                  style={[
                    {
                      paddingTop: 3,
                      borderRightWidth: 1,
                      width: "50%",
                      justifyContent: "space-between",
                    },
                  ]}
                >
                  (c) Goods satisfying Rule 6 (Product Specific Rules) of the
                  AIFTA Rules of Origin
                </Text>
                <Text
                  style={[
                    {
                      paddingRight: 5,
                      paddingTop: 2,
                      paddingLeft: 5,
                      width: "50%",
                      justifyContent: "space-between",
                    },
                  ]}
                >
                  Appropriate qualifying criteria
                </Text>
              </View>
            </View>
          </View>

          <View style={[styles.sideBySideContainer]}>
            <View style={[styles.row]}>
              <Text
                style={[
                  styles.subContent,
                  styles.regularFontSize,
                  { marginRight: 10 },
                ]}
              >
                4.
              </Text>

              <Text
                style={[
                  styles.regularFontSize,
                  styles.subContent,
                  { marginLeft: 5, marginRight: 35 },
                ]}
              >
                EACH ARTICLE MUST QUALIFY : It should be noted that all the
                goods in a consignment must qualify separately in their own
                right. This is of particular relevance when similar articles of
                different sizes or spare parts are sent.
              </Text>
            </View>
          </View>

          <View style={[styles.sideBySideContainer]}>
            <View style={[styles.row]}>
              <Text
                style={[
                  styles.subContent,
                  styles.regularFontSize,
                  { marginRight: 10 },
                ]}
              >
                5.
              </Text>

              <Text
                style={[
                  styles.regularFontSize,
                  styles.subContent,
                  { marginLeft: 5, marginRight: 35 },
                ]}
              >
                DESCRIPTION OF GOODS : The description of goods must be
                sufficiently detailed to enable the goods to be identified by
                the Customs Officers examining them. Name of manufacturer, any
                trade mark shall also be specified.
              </Text>
            </View>
          </View>

          <View style={[styles.sideBySideContainer]}>
            <View style={[styles.row]}>
              <Text
                style={[
                  styles.subContent,
                  styles.regularFontSize,
                  { marginRight: 10 },
                ]}
              >
                6.
              </Text>

              <Text
                style={[
                  styles.regularFontSize,
                  styles.subContent,
                  { marginLeft: 5, marginRight: 35 },
                ]}
              >
                HARMONIZED SYSTEM NUMBER : The Harmonized System number shall be
                that of importing Party.
              </Text>
            </View>
          </View>

          <View style={[styles.sideBySideContainer]}>
            <View style={[styles.row]}>
              <Text
                style={[
                  styles.subContent,
                  styles.regularFontSize,
                  { marginRight: 10 },
                ]}
              >
                7.
              </Text>

              <Text
                style={[
                  styles.regularFontSize,
                  styles.subContent,
                  { marginLeft: 5, marginRight: 35 },
                ]}
              >
                EXPORTER : The term “Exporter” in Box 11 may include the
                manufacturer or the producer.
              </Text>
            </View>
          </View>

          <View style={[styles.sideBySideContainer]}>
            <View style={[styles.row]}>
              <Text
                style={[
                  styles.subContent,
                  styles.regularFontSize,
                  { marginRight: 10 },
                ]}
              >
                8.
              </Text>

              <Text
                style={[
                  styles.regularFontSize,
                  styles.subContent,
                  { marginLeft: 5, marginRight: 35 },
                ]}
              >
                FOR OFFICIAL USE : The Customs Authority of the importing Party
                must indicate ( ) in the relevant boxes in column 4 whether or
                not preferential tariff is accorded.
              </Text>
            </View>
          </View>

          <View style={[styles.sideBySideContainer]}>
            <View style={[styles.row]}>
              <Text
                style={[
                  styles.subContent,
                  styles.regularFontSize,
                  { marginRight: 10 },
                ]}
              >
                9.
              </Text>

              <Text
                style={[
                  styles.regularFontSize,
                  styles.subContent,
                  { marginLeft: 4, marginRight: 35 },
                ]}
              >
                {" "}
                THIRD COUNTRY INVOICING : In cases where invoices are issued by
                a third country, “Third Country Invoicing” in Box 13 should be
                ticked ( ) and such information as name and country of the
                company issuing the invoice shall be indicated in Box 7.
              </Text>
            </View>
          </View>

          <View style={[styles.sideBySideContainer]}>
            <View style={[styles.row]}>
              <Text
                style={[
                  styles.subContent,
                  styles.regularFontSize,
                  { marginRight: 10 },
                ]}
              >
                10.
              </Text>

              <Text
                style={[
                  styles.regularFontSize,
                  styles.subContent,
                  { marginLeft: 1, marginRight: 35 },
                ]}
              >
                EXHIBITIONS : In cases where goods are sent from the territory
                of the exporting Party for exhibition in another country and
                sold during or after the exhibition for importation into the
                territory of a Party, in accordance with Article 21 of the
                Operational Certification Procedures, “Exhibitions” in Box 13
                should be ticked ( ) and the name and address of the exhibition
                indicated in Box 2
              </Text>
            </View>
          </View>

          <View style={[styles.sideBySideContainer]}>
            <View style={[styles.row]}>
              <Text
                style={[
                  styles.subContent,
                  styles.regularFontSize,
                  { marginRight: 10 },
                ]}
              >
                11.
              </Text>

              <Text
                style={[
                  styles.regularFontSize,
                  styles.subContent,
                  { marginLeft: 1, marginRight: 35 },
                ]}
              >
                BACK-TO-BACK CERTIFICATE OF ORIGIN : In cases of Back-to-Back
                CO, in accordance with Article 11 of the Operational
                Certification Procedures, “Back-to-Back CO” in Box13 should be
                ticked ( ). The name of original exporting Party to be indicated
                in Box 11 and the date of the issuance of CO and the reference
                number will be indicated in Box 7.
              </Text>
            </View>
          </View>
        </Page>
      </Document>
    </PDFViewer>
  );
};
export default FormAiDraft;
