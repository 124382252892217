/* eslint-disable react-hooks/exhaustive-deps */
import moment from "moment";
import { useEffect, useState } from "react";
import { getBankByIdApi } from "../../services/_bank";
import { BankType } from "../../components/Enums";

interface Props {
  bankId: string;
}

const ViewBank: React.FC<Props> = ({ bankId }) => {
  const [bank, setBank] = useState<any>({});

  const getBankDetails = async () => {
    const result: any = await getBankByIdApi(bankId as string);
    if (result.isSuccess) {
      setBank(result?.data);
    }
  };

  const getRelationName = (data: any) => {
    const typeMap: any = {
      [BankType.SUPPLIER]: data?.supplier?.name,
      [BankType.BUYER]: data?.buyer?.name,
      [BankType.EXPORTER]: "-",
      [BankType.LOGISTIC]: data?.logistic?.name,
      [BankType.CHA]: data?.cha?.name,
      [BankType.BROKER]: data?.broker?.name,
    };
    return typeMap[data.type] || "N/A";
  };

  useEffect(() => {
    if (bankId) {
      setBank({});
      getBankDetails();
    }
  }, [bankId]);

  return (
    <div className="modal fade" id="viewBank" tabIndex={-1} aria-hidden="true">
      <div className="modal-dialog modal-simple modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-body pt-0 pb-0">
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
            <div className="text-center">
              <h4 className="mb-0">Bank Details</h4>
            </div>
            <div className="info-container">
              <div className="pb-4 border-bottom text-capitalize mb-4"></div>
              <ul className="list-unstyled mb-6">
                <li className="mb-2">
                  <span className="h6 me-1">Type:</span>
                  <span>{bank?.type}</span>
                </li>
                <li className="mb-2">
                  <span className="h6 me-1">{bank?.type}:</span>
                  <span>{getRelationName(bank)}</span>
                </li>
                <li className="mb-2">
                  <span className="h6 me-1">Bank Name:</span>
                  <span>{bank?.bank_name}</span>
                </li>
                <li className="mb-2">
                  <span className="h6 me-1">Account Number:</span>
                  <span>{bank?.account_number}</span>
                </li>
                <li className="mb-2">
                  <span className="h6 me-1">IFSC:</span>
                  <span>{bank?.ifsc_code}</span>
                </li>
                <li className="mb-2">
                  <span className="h6 me-1">Branch Name:</span>
                  <span>{bank?.branch_name}</span>
                </li>
                <li className="mb-2">
                  <span className="h6 me-1">Swift Code:</span>
                  <span>{bank?.swift_code || "-"}</span>
                </li>
                <li className="mb-2">
                  <span className="h6 me-1">AD Code:</span>
                  <span>{bank?.ad_code || "-"}</span>
                </li>
                <li className="mb-2">
                  <span className="h6 me-1">Address:</span>
                  <span>{bank?.address || "-"}</span>
                </li>
                <li className="mb-2">
                  <span className="h6 me-1">Status:</span>
                  <span
                    className={`badge ${
                      bank?.status ? "bg-label-success" : "bg-label-danger"
                    }`}
                  >
                    {bank?.status ? "Active" : "Inactive"}
                  </span>
                </li>
                <li className="mb-2">
                  <span className="h6 me-1">Created At:</span>
                  <span>
                    {bank?.created_at
                      ? moment(bank?.created_at).format("YYYY-MM-DD HH:mm:ss")
                      : ""}
                  </span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewBank;
