import { Form, Formik } from "formik";
import * as Yup from "yup";
import { FilePond, registerPlugin } from "react-filepond";
import FilePondPluginFileEncode from "filepond-plugin-file-encode";
import { useCallback, useRef, useState } from "react";
import "filepond/dist/filepond.min.css";
import { uploadMultipleFileApi } from "../../../services/_other";
import { errorToast, successToast } from "../../../components/Toast";
registerPlugin(FilePondPluginFileEncode);

const UploadMultipleFileFilePond = () => {
  const mediaRef: any = useRef(null);
  const [filePondFiles, setFilePondFiles] = useState([]);

  const uploadMultipleFileValidationSchema = Yup.object().shape({
    media: Yup.array()
      .of(
        Yup.object().shape({
          fileName: Yup.string().required("File is required"),
          fileData: Yup.string().required("File is required"),
        })
      )
      .min(1, "At least one file is required"),
  });

  const handleFileChange = useCallback((fileItems: any, setFieldValue: any) => {
    const files = fileItems
      .map((fileItem: any) => {
        if (fileItem?.file) {
          return {
            fileName: fileItem.file.name,
            fileData: fileItem.getFileEncodeDataURL(), // Encodes to base64
          };
        }
        return null;
      })
      .filter(Boolean);
    setFieldValue("media", files);
  }, []);

  return (
    <div className="card mt-6">
      <div className="card-header pb-0 d-flex justify-content-between">
        <div>
          <h5>Upload Multiple Files (Filepond)</h5>
        </div>
      </div>
      <div className="card-body">
        <Formik
          initialValues={{ media: [] }}
          validationSchema={uploadMultipleFileValidationSchema}
          onSubmit={async (values, { resetForm }) => {
            const result: any = await uploadMultipleFileApi(values);
            if (!result.isSuccess) {
              errorToast(result.message);
              return;
            } else {
              successToast(result.message);
              resetForm();
              setFilePondFiles([]);
              if (mediaRef.current) {
                mediaRef.current.value = null;
              }
            }
          }}
        >
          {({ setFieldValue }) => {
            return (
              <Form>
                <div className="row">
                  <div className="col-12">
                    <div className="row g-3">
                      <div className="col-12">
                        <div className="file-pond-main-block">
                          <FilePond
                            ref={mediaRef}
                            files={filePondFiles}
                            onupdatefiles={(fileItems: any) => {
                              setFilePondFiles(fileItems);
                              handleFileChange(fileItems, setFieldValue);
                            }}
                            allowMultiple={true}
                            allowReorder={false}
                            allowRemove={true}
                            allowDrop={true}
                            name="media"
                            labelIdle="Drag & drop files here or click to browse"
                            required
                          />
                        </div>
                      </div>
                      <div className="col-12">
                        <button type="submit" className="btn btn-primary">
                          Upload
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
    </div>
  );
};

export default UploadMultipleFileFilePond;
