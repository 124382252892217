import * as Yup from "yup";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { errorToast, successToast } from "../../components/Toast";
import { createQualitySpecificationsApi } from "../../services/_qualitySpecifications";
import { getProductNameListApi } from "../../services/_product";
import ReactSelect from "react-select";

const createQualitySpecificationsInitialValues = {
  name: "",
  description: "",
  product_id: "",
  status: "",
};

const CreateQualitySpecifications = () => {
  const navigate = useNavigate();
  const [submitLoader, setSubmitLoader] = useState<boolean>(false);
  const [productList, setProductList] = useState([]);

  const createQualitySpecificationsValidationSchema = Yup.object().shape({
    name: Yup.string().required("Name is required"),
    description: Yup.string(),
    product_id: Yup.string(),
    status: Yup.string().required("Status is required"),
  });

  const handleSubmit = async (values: any, { resetForm }: any) => {
    setSubmitLoader(true);
    values.status = values.status === "true" ? true : false;
    const result: any = await createQualitySpecificationsApi(values);
    if (result.isSuccess) {
      setSubmitLoader(false);
      resetForm({ values: createQualitySpecificationsInitialValues });
      successToast(result.message);
      navigate("/quality-specification-list");
    } else {
      setSubmitLoader(false);
      errorToast(result.message);
    }
    return;
  };

  const getProductNameList = async () => {
    try {
      const result: any = await getProductNameListApi();
      setProductList(result.data);
    } catch (error) {
      console.error("Error fetching product list", error);
    }
  };

  useEffect(() => {
    getProductNameList();
  }, []);

  return (
    <>
      <div className="card">
        <div className="card-header pb-0">
          <div>
            <h5>Create Quality Specification</h5>
          </div>
        </div>
        <div className="card-body">
          <Formik
            initialValues={createQualitySpecificationsInitialValues}
            validationSchema={createQualitySpecificationsValidationSchema}
            onSubmit={handleSubmit}
          >
            {({ values, handleChange, setFieldValue }) => (
              <Form className="dt_adv_search">
                <div className="row">
                  <div className="col-12">
                    <div className="row g-3">
                      <div className="col-12 col-sm-6 col-lg-4">
                        <label
                          htmlFor="name"
                          className="form-label"
                          style={{ fontSize: "0.9375rem" }}
                        >
                          Name: <span className="text-danger">*</span>
                        </label>
                        <Field
                          id="name"
                          name="name"
                          type="text"
                          className="form-control"
                          placeholder="Enter name"
                          onChange={handleChange}
                        />
                        <ErrorMessage
                          name="name"
                          className="text-danger"
                          component="div"
                        />
                      </div>
                      <div className="col-12 col-sm-6 col-lg-4">
                        <label
                          className="form-label"
                          style={{ fontSize: "0.9375rem" }}
                        >
                          Product: <span className="text-danger">*</span>
                        </label>
                        <Field name="product_id">
                          {({ field }: any) => {
                            const selectedOption = productList.find(
                              (option: any) => option.id === field.value
                            );
                            const formatOptionLabel = (
                              option: any,
                              { context }: any
                            ) => {
                              if (context === "value") {
                                return option.name;
                              } else {
                                return (
                                  <div>
                                    <label className="form-label">
                                      {option.name}
                                    </label>
                                    <br />
                                    <span className="form-label">
                                      {option.description}
                                    </span>
                                  </div>
                                );
                              }
                            };
                            return (
                              <ReactSelect
                                {...field}
                                classNamePrefix="react-select"
                                placeholder="Select Product"
                                options={productList}
                                getOptionLabel={(option: any) => option.name}
                                formatOptionLabel={formatOptionLabel}
                                getOptionValue={(option: any) => option.id}
                                onChange={(selectedOption: any) => {
                                  setFieldValue(
                                    "product_id",
                                    selectedOption ? selectedOption.id : ""
                                  );
                                }}
                                value={selectedOption}
                                isClearable
                                isSearchable
                              />
                            );
                          }}
                        </Field>
                        <ErrorMessage
                          name="product_id"
                          component="div"
                          className="text-danger"
                        />
                      </div>
                      <div className="col-12 col-sm-6 col-lg-4">
                        <label
                          htmlFor="status"
                          className="form-label"
                          style={{ fontSize: "0.9375rem" }}
                        >
                          Status: <span className="text-danger">*</span>
                        </label>
                        <select
                          id="status"
                          name="status"
                          className="form-select"
                          value={values.status}
                          onChange={handleChange}
                        >
                          <option value="">Select Status</option>
                          <option value="true">Active</option>
                          <option value="false">Inactive</option>
                        </select>
                        <ErrorMessage
                          name="status"
                          className="text-danger"
                          component="div"
                        />
                      </div>
                      <div className="col-12">
                        <label
                          htmlFor="description"
                          className="form-label"
                          style={{ fontSize: "0.9375rem" }}
                        >
                          Description:
                        </label>
                        <Field
                          id="description"
                          name="description"
                          type="text"
                          className="form-control"
                          placeholder="Enter description"
                          onChange={handleChange}
                          as={"textarea"}
                          rows={2}
                        />
                        <ErrorMessage
                          name="description"
                          className="text-danger"
                          component="div"
                        />
                      </div>
                      <div className="col-12">
                        <button
                          type="submit"
                          className="btn btn-primary"
                          disabled={submitLoader}
                        >
                          {submitLoader && (
                            <span
                              className="spinner-border spinner-border-sm me-2"
                              role="status"
                              aria-hidden="true"
                            ></span>
                          )}
                          Submit
                        </button>
                        <Link
                          to={"/quality-specification-list"}
                          type="button"
                          className="btn btn-label-github ms-5"
                        >
                          Back
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </>
  );
};

export default CreateQualitySpecifications;
