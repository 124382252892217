import { DatePicker } from "rsuite";
import { ErrorMessage, Field, FormikErrors, FormikValues } from "formik";
import "rsuite/DateRangePicker/styles/index.css";

interface ShipmentProps {
  portList: any;
  values: FormikValues;
  setAddPortModalStatus: (show: boolean) => void;
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean
  ) => Promise<void | FormikErrors<FormikValues>>;
  handleChange: (e: React.ChangeEvent<any>) => void;
}

const ShipmentFields: React.FC<ShipmentProps> = ({
  portList,
  values,
  setAddPortModalStatus,
  setFieldValue,
  handleChange,
}) => {
  return (
    <div className="card my-card">
      <div className="card-header my-card-header bg-lighter">
        <div>
          <h6 className="my-card-header-head">Shipment Details</h6>
        </div>
      </div>
      <div className="card-body my-card-body">
        <div className="row g-3 m-0">
          <div className="col-12 col-sm-6 col-md-4 col-lg-3">
            <div className="d-flex justify-content-between">
              <div>
                <label
                  htmlFor="origin_port_id"
                  className="form-label"
                  style={{ fontSize: "0.9375rem" }}
                >
                  Origin Port:
                </label>
              </div>
              <div>
                <button
                  title="Add Packing Type"
                  className="btn btn-sm btn-primary waves-effect waves-light p-0"
                  type="button"
                  onClick={() => setAddPortModalStatus(true)}
                >
                  <span>
                    <i className="ti ti-plus my-card-icon-button"></i>
                  </span>
                </button>
              </div>
            </div>
            <select
              name="origin_port_id"
              className="form-select"
              id="origin_port_id"
              value={values.origin_port_id}
              onChange={handleChange}
            >
              <option defaultChecked value={""}>
                Select Origin Port
              </option>
              {portList.map((e: any) => {
                return (
                  <option value={e.id} key={e.id}>
                    {e.name}
                  </option>
                );
              })}
            </select>
          </div>
          <div className="col-12 col-sm-6 col-md-4 col-lg-3 d-flex flex-column">
            <label
              htmlFor="etd"
              className="form-label"
              style={{ fontSize: "0.9375rem" }}
            >
              ETD:
            </label>
            <DatePicker
              id="etd"
              name="etd"
              format="dd-MM-yyyy HH:mm:ss"
              className="form-control"
              placeholder="Select ETD Date-Time"
              value={values.etd}
              onChange={(value: any) => {
                setFieldValue("etd", value);
              }}
            />
            <ErrorMessage name="etd" className="text-danger" component="div" />
          </div>
          <div className="col-12 col-sm-6 col-md-4 col-lg-3">
            <label
              htmlFor="destination_port_id"
              className="form-label"
              style={{ fontSize: "0.9375rem" }}
            >
              Destination Port:
            </label>
            <select
              name="destination_port_id"
              className="form-select"
              id="destination_port_id"
              value={values.destination_port_id}
              onChange={handleChange}
            >
              <option defaultChecked value={""}>
                Select Destination Port
              </option>
              {portList.map((e: any) => {
                return (
                  <option value={e.id} key={e.id}>
                    {e.name}
                  </option>
                );
              })}
            </select>
            <ErrorMessage
              name="destination_port_id"
              className="text-danger"
              component="div"
            />
          </div>
          <div className="col-12 col-sm-6 col-md-4 col-lg-3 d-flex flex-column">
            <label
              htmlFor="eta"
              className="form-label"
              style={{ fontSize: "0.9375rem" }}
            >
              ETA:
            </label>
            <DatePicker
              id="eta"
              name="eta"
              format="dd-MM-yyyy HH:mm:ss"
              className="form-control"
              placeholder="Select ETA DateTime"
              value={values.eta}
              onChange={(value: any) => {
                setFieldValue("eta", value);
              }}
            />
          </div>
          <div className="col-12 col-sm-6 col-md-4 col-lg-3">
            <label
              htmlFor="pre_carriage_by"
              className="form-label"
              style={{ fontSize: "0.9375rem" }}
            >
              Pre Carriage By:
            </label>
            <Field
              id="pre_carriage_by"
              name="pre_carriage_by"
              type="text"
              className="form-control"
              placeholder="Enter Pre Carriage By"
              value={values.pre_carriage_by}
              onChange={handleChange}
            />
          </div>
          <div className="col-12 col-sm-6 col-md-4 col-lg-3">
            <label
              htmlFor="vessel_no"
              className="form-label"
              style={{ fontSize: "0.9375rem" }}
            >
              Vessel No:
            </label>
            <Field
              id="vessel_no"
              name="vessel_no"
              type="text"
              className="form-control"
              placeholder="Enter Vessel No"
              value={values.vessel_no}
              onChange={handleChange}
            />
          </div>
          <div className="col-12 col-sm-6 col-md-4 col-lg-3">
            <label
              htmlFor="delivery_term"
              className="form-label"
              style={{ fontSize: "0.9375rem" }}
            >
              Delivery Term:
            </label>
            <Field
              id="delivery_term"
              name="delivery_term"
              type="text"
              className="form-control"
              placeholder="Enter Delivery Term"
              value={values.delivery_term}
              onChange={handleChange}
            />
          </div>
          <div className="col-12 col-sm-6 col-md-4 col-lg-3">
            <label
              htmlFor="payment_term"
              className="form-label"
              style={{ fontSize: "0.9375rem" }}
            >
              Payment Term:
            </label>
            <Field
              id="payment_term"
              name="payment_term"
              type="text"
              className="form-control"
              placeholder="Enter Payment Term"
              value={values.payment_term}
              onChange={handleChange}
            />
          </div>
          <div className="col-12 col-sm-6 col-md-4 col-lg-3">
            <label
              htmlFor="bl_no"
              className="form-label"
              style={{ fontSize: "0.9375rem" }}
            >
              BL No:
            </label>
            <Field
              id="bl_no"
              name="bl_no"
              type="text"
              className="form-control"
              placeholder="Enter BL No"
              value={values.bl_no}
              onChange={handleChange}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ShipmentFields;
