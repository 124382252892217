import { ErrorMessage, Form, Formik } from "formik";
import * as Yup from "yup";
import { uploadSingleFileApi } from "../../../services/_other";
import { errorToast, successToast } from "../../../components/Toast";
import { useRef } from "react";

const UploadSingleFile = () => {
  const mediaRef: any = useRef(null);

  const uploadSingleFileValidationSchema = Yup.object().shape({
    media: Yup.object().shape({
      fileName: Yup.string().required("File is required"),
      fileData: Yup.string().required("File is required"),
    }),
  });

  const convertFileToBase64 = (file: File): Promise<string> => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);

      reader.onload = () => {
        if (reader.result) {
          resolve((reader.result as string).split(",")[1]);
        } else {
          reject(new Error("File could not be read"));
        }
      };

      reader.onerror = (error) => reject(error);
    });
  };

  return (
    <div className="card">
      <div className="card-header pb-0 d-flex justify-content-between">
        <div>
          <h5>Upload Single File</h5>
        </div>
      </div>
      <div className="card-body">
        <Formik
          validationSchema={uploadSingleFileValidationSchema}
          initialValues={{
            media: {
              fileName: "",
              fileData: "",
            },
          }}
          onSubmit={async (values, { resetForm }) => {
            const result: any = await uploadSingleFileApi(values);
            if (!result.isSuccess) {
              errorToast(result.message);
              return;
            } else {
              successToast(result.message);
              resetForm();
              if (mediaRef.current) {
                mediaRef.current.value = null;
              }
            }
          }}
        >
          {({ setFieldValue }) => {
            return (
              <Form>
                <div className="row">
                  <div className="col-12">
                    <div className="row g-3">
                      <div className="col-12 col-sm-6 col-lg-4">
                        <label
                          className="form-label"
                          style={{ fontSize: "0.9375rem" }}
                        >
                          File:
                        </label>
                        <input
                          ref={mediaRef}
                          type="file"
                          name="media"
                          id="media"
                          className="form-control"
                          onChange={async (event: any) => {
                            const file = event.currentTarget.files[0];
                            const base64FileData = await convertFileToBase64(
                              file
                            );
                            const media = {
                              fileName: file.name,
                              fileData: base64FileData,
                            };
                            setFieldValue("media", media);
                          }}
                        />
                        <ErrorMessage
                          name="media.fileName"
                          className="text-danger"
                          component="div"
                        />
                      </div>
                      <div className="col-12">
                        <button type="submit" className="btn btn-primary">
                          Upload
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
    </div>
  );
};

export default UploadSingleFile;
