import { GET, POST, PUT } from "./_config";

export const bankListApi = async (query: string) =>
  await GET(`/bank?${query || ""}`, true);

export const getAllBankListApi = async (query?: string) =>
  await GET(`/bank/list?${query || ""}`, true);

export const createBankApi = async (body: any) =>
  await POST(`/bank`, body, true);

export const getBankByIdApi = async (bankId: string) =>
  await GET(`/bank/id/${bankId}`, true);

export const updateBankApi = async (bankId: string, body: any) =>
  await PUT(`/bank?id=${bankId}`, body, true);

export const updateBankStatusApi = async (body: any) =>
  await POST(`/bank/update-status`, body, true);

const BankService = {
  bankListApi,
  getAllBankListApi,
  createBankApi,
  getBankByIdApi,
  updateBankApi,
  updateBankStatusApi,
};

export default BankService;
