/* eslint-disable react-hooks/exhaustive-deps */
import moment from "moment";
import { Link } from "react-router-dom";
import { DateRangePicker } from "rsuite";
import Spinner from "../../theme/Spinner";
import { useEffect, useState } from "react";
import Pagination from "../../components/Pagination";
import { CURRENCY } from "../../components/Constants";
import { getPermissions } from "../../context/AuthContext";
import { getSupplierNameListApi } from "../../services/_supplier";
import { errorToast, successToast } from "../../components/Toast";
import {
  deletePurchaseApi,
  getAllPurchasesApi,
  purchaseListApi,
} from "../../services/_purchase";
import "rsuite/DateRangePicker/styles/index.css";
import { Autocomplete, TextField } from "@mui/material";
import ReCAPTCHA from "react-google-recaptcha";

const PurchaseList = () => {
  const permissions = getPermissions();
  const [records, setRecords] = useState([]);
  const [supplierList, setSupplierList] = useState([]);
  const [purchaseOrders, setPurchaseOrders] = useState([]);
  const [page, setPage] = useState<number>(1);
  const [perPage] = useState<number>(10);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [totalRecords, setTotalRecords] = useState<number>(0);
  const [applyFilter, setApplyFilter] = useState<boolean>(false);
  const [filterInvoiceNo, setFilterInvoiceNo] = useState<string>("");
  const [selectedSupplierId, setSelectedSupplierId] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [dateRange, setDateRange] = useState(null);
  const [displayFilter, setDisplayFilter] = useState(false);
  const [selectedItemId, setSelectedItemId] = useState<string | null>(null);
  const [sortKey, setSortKey] = useState<string>("");
  const [sortType, setSortType] = useState<string>("");
  const [isCaptchaVerified, setIsCaptchaVerified] = useState(false);
  const [showCaptcha, setShowCaptcha] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState<boolean>(false);

  const toggleFilter = async () => {
    setDisplayFilter(!displayFilter);
  };

  const getPurchaseList = async (
    page: number,
    perPage: number,
    isFilter?: boolean,
    sortKey?: string,
    sortType?: string
  ) => {
    setLoading(true);
    let query = `page=${page}&per_page=${perPage}`;
    if (isFilter) {
      if (filterInvoiceNo) {
        query += `&invoice_no=${filterInvoiceNo}`;
      }
      if (dateRange) {
        query += `&from_date=${moment(dateRange[0]).format("YYYY-MM-DD")}`;
        query += `&to_date=${moment(dateRange[1]).format("YYYY-MM-DD")}`;
      }
      if (selectedSupplierId) {
        query += `&supplier_id=${selectedSupplierId}`;
      }
      if (sortKey && sortType) {
        query += `&sort_key=${sortKey}&sort_type=${sortType}`;
      }
    }
    const result: any = await purchaseListApi(query);
    if (result.isSuccess) {
      const totalPagesCount = Math.ceil(result.data.total_records / perPage);
      setTotalRecords(result.data.total_records);
      setTotalPages(totalPagesCount);
      setRecords(result.data.records);
    }
    setLoading(false);
  };

  const getSupplierNameList = async () => {
    try {
      const result: any = await getSupplierNameListApi();
      setSupplierList(result.data);
    } catch (error) {
      console.error("Error fetching supplier list", error);
    }
  };

  const getPurchaseOrderList = async () => {
    try {
      const result: any = await getAllPurchasesApi();
      setPurchaseOrders(result?.data);
    } catch (error) {
      console.error("Error fetching SaleOrder list", error);
    }
  };

  const handleCaptchaChange = (value: any | null) => {
    if (value) {
      setIsCaptchaVerified(true);
    }
  };

  const confirmDelete = async () => {
    setDeleteLoading(true);
    if (selectedItemId) {
      const result = await deletePurchaseApi(selectedItemId);
      if (result.isSuccess) {
        await getPurchaseList(page, perPage);
        successToast(result.message);
      } else {
        errorToast(result.message);
      }
      setDeleteLoading(false);
      setSelectedItemId(null);
    }
  };

  const handleDeleteClick = (id: any) => {
    setSelectedItemId(id);
    setShowCaptcha(true);
  };

  useEffect(() => {
    getSupplierNameList();
    getPurchaseOrderList();
  }, []);

  const filterList = async () => {
    setApplyFilter(true);
    getPurchaseList(page, perPage, true);
  };

  const resetList = async () => {
    setPage(1);
    setApplyFilter(false);
    setFilterInvoiceNo("");
    getPurchaseList(page, perPage);
    setSelectedSupplierId("");
    setDateRange(null);
    setIsCaptchaVerified(false);
    setShowCaptcha(false);
  };

  const prevButton = async () => {
    if (page > 1) {
      setPage(page - 1);
    }
  };

  const nextButton = async () => {
    if (totalPages > page) {
      setPage(page + 1);
    }
  };

  const sortTable = async (key: string) => {
    let type = sortType === "asc" ? "desc" : "asc";
    if (sortKey !== key) {
      type = "asc";
    }
    setSortKey(key);
    setSortType(type);
    await getPurchaseList(page, perPage, true, key, type);
  };

  useEffect(() => {
    if (page && perPage) {
      getPurchaseList(page, perPage, applyFilter);
    }
  }, [page, perPage, applyFilter]);

  return (
    <>
      <div className="card">
        <div className="card-header pb-0 d-flex justify-content-between">
          <div>
            <h5>Purchase List</h5>
          </div>

          <div>
            <Link
              to="#"
              type="button"
              className="btn btn-label-github"
              style={{ marginRight: "15px" }}
              onClick={toggleFilter}
            >
              <span>
                <i className="ti ti-filter me-sm-1"></i>{" "}
                <span className="d-none d-sm-inline-block">Filter</span>
              </span>
            </Link>
            {permissions.includes("create-purchase") && (
              <Link
                className="btn btn-primary waves-effect waves-light"
                type="button"
                to="/create-purchase"
              >
                <span>
                  <i className="ti ti-plus me-sm-1"></i>{" "}
                  <span className="d-none d-sm-inline-block">
                    Create Purchase
                  </span>
                </span>
              </Link>
            )}
          </div>
        </div>

        {displayFilter && (
          <div className="card-body">
            <form className="dt_adv_search">
              <div className="row">
                <div className="col-12">
                  <div className="row g-3">
                    <div className="col-12 col-sm-6 col-lg-3">
                      <label
                        className="form-label"
                        style={{ fontSize: "0.9375rem" }}
                      >
                        Purchase Date:
                      </label>
                      <DateRangePicker
                        format="dd-MM-yyyy"
                        className="form-control"
                        placeholder="From Date ~ To Date"
                        value={dateRange}
                        onChange={(values: any) => {
                          setDateRange(values);
                        }}
                      />
                    </div>
                    <div className="col-12 col-sm-6 col-lg-3">
                      <label
                        className="form-label"
                        style={{ fontSize: "0.9375rem" }}
                      >
                        Invoice No:
                      </label>
                      <Autocomplete
                        disablePortal
                        options={purchaseOrders.map(
                          (suggestion: any) => suggestion.invoice_no
                        )}
                        onChange={(e, newValue) => {
                          setFilterInvoiceNo(newValue);
                        }}
                        value={filterInvoiceNo}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            className="form-control"
                            type="text"
                            placeholder="Enter Invoice No"
                            value={filterInvoiceNo}
                            onChange={(e) => setFilterInvoiceNo(e.target.value)}
                          />
                        )}
                      />
                    </div>
                    <div className="col-12 col-sm-6 col-lg-3">
                      <label
                        className="form-label"
                        style={{ fontSize: "0.9375rem" }}
                      >
                        Supplier:
                      </label>
                      <select
                        className="form-select"
                        value={selectedSupplierId}
                        onChange={(e: any) => {
                          setSelectedSupplierId(e.target.value);
                        }}
                      >
                        <option value="">Select Supplier</option>
                        {supplierList.map((supplier: any) => (
                          <option key={supplier.id} value={supplier.id}>
                            {supplier.name}
                          </option>
                        ))}
                      </select>
                    </div>

                    <div className="col-12">
                      <button
                        type="button"
                        className="btn btn-primary"
                        onClick={filterList}
                      >
                        Filter
                      </button>
                      <button
                        type="button"
                        className="btn btn-label-github ms-5"
                        onClick={resetList}
                      >
                        Reset
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        )}

        <div className="card-datatable table-responsive">
          {loading && <Spinner isFullScreen={false} />}
          <table className="dt-advanced-search table table-nowrap">
            <thead>
              <tr>
                <th>#</th>
                <th
                  className="cursor-pointer"
                  onClick={() => sortTable("invoice_no")}
                >
                  Invoice No
                  <span>
                    <i className="ti ti-arrows-down-up sorting-icon"></i>
                  </span>
                </th>
                <th
                  className="cursor-pointer"
                  onClick={() => sortTable("supplier")}
                >
                  Supplier
                  <span>
                    <i className="ti ti-arrows-down-up sorting-icon"></i>
                  </span>
                </th>
                <th
                  className="cursor-pointer"
                  onClick={() => sortTable("sub_total")}
                >
                  Sub Total
                  <span>
                    <i className="ti ti-arrows-down-up sorting-icon"></i>
                  </span>
                </th>
                <th
                  className="cursor-pointer"
                  onClick={() => sortTable("gst_amount")}
                >
                  GST Amount
                  <span>
                    <i className="ti ti-arrows-down-up sorting-icon"></i>
                  </span>
                </th>
                <th
                  className="cursor-pointer"
                  onClick={() => sortTable("total")}
                >
                  Total Amount
                  <span>
                    <i className="ti ti-arrows-down-up sorting-icon"></i>
                  </span>
                </th>
                <th
                  className="cursor-pointer"
                  onClick={() => sortTable("purchase_date")}
                >
                  Purchase Date
                  <span>
                    <i className="ti ti-arrows-down-up sorting-icon"></i>
                  </span>
                </th>
                {(permissions.includes("update-purchase") ||
                  permissions.includes("delete-purchase")) && <th>Action</th>}
              </tr>
            </thead>
            <tbody>
              {records.length > 0 ? (
                records.map((item: any, i: number) => {
                  return (
                    <tr key={item.id}>
                      <td>{(page - 1) * perPage + 1 + i}</td>
                      <td>
                        {
                          <Link to={`/view-purchase/${item.id}`}>
                            {item.invoice_no}
                          </Link>
                        }
                      </td>
                      <td>{item?.supplier?.name || ""}</td>
                      <td>
                        {CURRENCY.INR.SYMBOL}
                        {parseFloat(item.sub_total).toFixed(2)}
                      </td>
                      <td>
                        {CURRENCY.INR.SYMBOL}
                        {parseFloat(item.gst_amount).toFixed(2)}
                      </td>
                      <td>
                        {CURRENCY.INR.SYMBOL}
                        {parseFloat(item.total).toFixed(2)}
                      </td>
                      <td>{moment(item.purchase_date).format("DD-MM-YYYY")}</td>
                      {(permissions.includes("update-purchase") ||
                        permissions.includes("delete-purchase")) && (
                        <td>
                          <>
                            {permissions.includes("update-purchase") && (
                              <Link
                                to={`/edit-purchase/${item.id}`}
                                className="btn btn-sm btn-text-secondary rounded-pill btn-icon"
                                title="Edit supplier"
                              >
                                <i className="ti ti-pencil ti-md"></i>
                              </Link>
                            )}
                            {permissions.includes("delete-purchase") && (
                              <Link
                                to={`#`}
                                className="btn btn-sm btn-text-secondary rounded-pill btn-icon"
                                data-bs-toggle="modal"
                                data-bs-target="#deletePurchaseOrderModal"
                                onClick={() => handleDeleteClick(item.id)}
                              >
                                <i className="ti ti-trash ti-md"></i>
                              </Link>
                            )}
                          </>
                        </td>
                      )}
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td
                    colSpan={permissions.includes("update-purchase") ? 8 : 7}
                    className="text-center"
                  >
                    No records found
                  </td>
                </tr>
              )}
            </tbody>
          </table>
          <div
            className="modal fade"
            id="deletePurchaseOrderModal"
            tabIndex={-1}
            aria-hidden="true"
          >
            <div className="modal-dialog modal-simple modal-dialog-centered">
              <div className="modal-content">
                <div className="modal-body text-center">
                  <i
                    className="fa-solid fa-circle-exclamation"
                    style={{
                      fontSize: "48px",
                      color: "#ff4c51",
                      marginBottom: "20px",
                    }}
                  ></i>
                  <p style={{ fontSize: "30px" }}>Are you sure?</p>
                  <br />
                  <p style={{ fontSize: "20px" }}>
                    Do you want to delete this <b>Purchase order</b>?
                    <br />
                    This action can't be undone!
                  </p>
                  {showCaptcha && (
                    <div
                      style={{
                        marginTop: "10px",
                        marginLeft: "50px",
                        marginBottom: "10px",
                      }}
                    >
                      <ReCAPTCHA
                        sitekey="6LdCqqMqAAAAAI5NAleRuzGZv4X3LjDVJdvGDmaj"
                        onChange={handleCaptchaChange}
                      />
                    </div>
                  )}
                  <div className="text-center">
                    <button
                      type="button"
                      className="btn btn-danger me-3"
                      onClick={() => {
                        confirmDelete();
                        resetList();
                      }}
                      disabled={deleteLoading || !isCaptchaVerified}
                      data-bs-dismiss="modal"
                    >
                      {deleteLoading && (
                        <span
                          className="spinner-border spinner-border-sm me-2"
                          role="status"
                          aria-hidden="true"
                        ></span>
                      )}
                      Yes, Delete
                    </button>

                    <button
                      type="button"
                      className="btn btn-label-secondary"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                      onClick={resetList}
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Pagination
          page={page}
          perPage={perPage}
          totalRecords={totalRecords}
          totalPages={totalPages}
          records={records}
          prevButton={prevButton}
          nextButton={nextButton}
        />
      </div>
    </>
  );
};

export default PurchaseList;
