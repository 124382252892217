import { DELETE, GET, POST, PUT } from "./_config";

export const paymentListApi = async (query: string) =>
  await GET(`/payments?${query || ""}`, true);

export const createPaymentApi = async (body: any) =>
  await POST(`/payments`, body, true);

export const getPaymentDetailsByIDApi = async (paymentID: string) =>
  await GET(`/payments/id/${paymentID}`, true);

export const updatePaymentApi = async (paymentID: string, body: any) =>
  await PUT(`/payments?id=${paymentID}`, body, true);

export const deletePaymentApi = async (paymentID: string) =>
  await DELETE(`/payments/delete?id=${paymentID}`, true);

const PaymentService = {
  paymentListApi,
  createPaymentApi,
  getPaymentDetailsByIDApi,
  updatePaymentApi,
  deletePaymentApi,
};

export default PaymentService;
