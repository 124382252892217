import UploadSingleFile from "./other_sections/UploadSingleFile";
import UploadMultipleFileBasic from "./other_sections/UploadMultipleFileBasic";
import UploadMultipleFileDropzone from "./other_sections/UploadMultipleFileDropzone";
import UploadMultipleFileFilePond from "./other_sections/UploadMultipleFileFilePond";
import DatePicker from "./other_sections/DatePicker";

const Other = () => {
  return (
    <>
      <DatePicker />
      <UploadSingleFile />
      <UploadMultipleFileBasic />
      <UploadMultipleFileFilePond />
      <UploadMultipleFileDropzone />
    </>
  );
};

export default Other;
