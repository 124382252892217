import {
  Document,
  Page,
  PDFViewer,
  StyleSheet,
  Text,
  View,
} from "@react-pdf/renderer";

export const styles = StyleSheet.create({
  container: {
    width: 345,
    paddingLeft: 5,
    borderRight: 1,
  },
  boldText: {
    fontWeight: "bold",
    fontSize: 10,
  },
  smallText: {
    fontSize: 6,
  },
  mediumText: {
    fontSize: 6.5,
  },
  largeText: {
    fontSize: 8,
  },
  smallFontSize: {
    fontSize: 6,
  },
  mediumFontSize: {
    fontSize: 6.5,
  },
  regularFontSize: {
    fontSize: 8,
  },
  largeFontSize: {
    fontSize: 9,
  },
  page: {
    paddingHorizontal: 30,
    paddingVertical: 15,
    fontFamily: "HostGrotesk",
    backgroundColor: "white",
  },
  fullpage: {
    margin: 7,
    borderStyle: "solid",
    borderWidth: 1,
    borderColor: "#000000",
  },

  sideBySideContainer: { flexDirection: "row" },

  halfWidthRightSection: {
    width: "50%",
    borderBottomWidth: 1,
    borderbottomStyle: "solid",
    borderRightWidth: 1,
    textAlign: "left",
  },
  halfWidthLeftSection: {
    width: "50%",
    borderBottomWidth: 1,
    borderbottomStyle: "solid",
  },
  tableContainer: {
    borderBottomWidth: 1,
    borderBottomColor: "black",
  },
  tableRow: {
    flexDirection: "row",
    flexGrow: 1,
    alignItems: "stretch",
  },
  tableCell: {
    padding: 5,
    fontSize: 7,
    textAlign: "center",
    borderRightWidth: 1,
    borderRightColor: "black",
    borderRightStyle: "solid",
  },
});

const BlDraftInvoice = () => {
  return (
    <PDFViewer width="100%" height="800px">
      <Document>
        <Page style={styles.page} size="A4">
          <View style={styles.fullpage}>
            {/* address section */}
            <View
              style={[
                styles.sideBySideContainer,
                { height: 120, flexDirection: "row" },
              ]}
            >
              <View style={[styles.halfWidthRightSection]}>
                <Text style={[styles.boldText, { left: 5 }]}>SHIPPER</Text>

                <Text style={[styles.largeText, { top: 15, left: 5 }]}>
                  {" "}
                  MS LAXMI ENTERPRISE
                </Text>
                <Text style={[styles.largeText, { top: 15, left: 5 }]}>
                  {" "}
                  PLOT NO. 612, NAGOR ROAD,
                </Text>
                <Text style={[styles.largeText, { top: 15, left: 5 }]}>
                  {" "}
                  GIDC BHUJ, KACHCHH
                </Text>
                <Text style={[styles.largeText, { top: 15, left: 5 }]}>
                  {" "}
                  GUJARAT, 370001 (INDIA)
                </Text>
                <Text style={[styles.largeText, { top: 15, left: 5 }]}>
                  {" "}
                  GSTIN: 24AALFM4290M1ZU
                </Text>
                <Text style={[styles.largeText, { top: 15, left: 5 }]}>
                  {" "}
                  PAN NO: AALFM4290M
                </Text>
                <Text style={[styles.largeText, { top: 15, left: 5 }]}>
                  {" "}
                  AEO REG. NO. INAALFM4290M1F219 (T1)
                </Text>
              </View>
              <View
                style={[
                  styles.sideBySideContainer,
                  { height: 120, flexDirection: "row" },
                ]}
              >
                <View
                  style={[styles.halfWidthLeftSection, { borderBottom: 0 }]}
                >
                  <Text style={[styles.boldText, { top: 15, left: 15 }]}>
                    BOOKING REF NO :
                  </Text>

                  <Text style={[styles.boldText, { top: 25, left: 12 }]}>
                    {" "}
                    LINE:
                  </Text>
                </View>
              </View>
            </View>

            {/* Consignee section*/}
            <View
              style={[
                styles.sideBySideContainer,
                { height: 110, flexDirection: "row" },
              ]}
            >
              <View style={[styles.halfWidthRightSection]}>
                <Text style={[styles.boldText, { left: 5 }]}>CONSIGNEE:</Text>

                <Text style={[styles.largeText, { left: 5 }]}>
                  {" "}
                  GLOBAL TRADING & COMPANY (PVT) LTD.
                </Text>
                <Text style={[styles.largeText, { left: 5 }]}>
                  {" "}
                  NO: 136/F, DAM STREET,
                </Text>
                <Text style={[styles.largeText, { left: 5 }]}>
                  {" "}
                  COLOMBO – 12, SRI LANKA.
                </Text>
                <Text style={[styles.largeText, { left: 5 }]}>
                  {" "}
                  CONTACT NO_______
                </Text>
                <Text style={[styles.largeText, { left: 5 }]}>
                  {" "}
                  E MAIL ID:__________
                </Text>
                <Text style={[styles.largeText, { left: 5 }]}>
                  {" "}
                  TAX ID:____________
                </Text>
                <Text style={[styles.largeText, { left: 5 }]}>
                  {" "}
                  CONTACT NAME:_______
                </Text>
              </View>
              <View
                style={[
                  styles.sideBySideContainer,
                  { height: 110, flexDirection: "row", borderBottom: 1 },
                ]}
              >
                <View
                  style={[styles.halfWidthLeftSection, { borderBottom: 0 }]}
                ></View>
              </View>
            </View>
            {/* Notify Party Section */}
            <View
              style={[
                styles.sideBySideContainer,
                { height: 110, flexDirection: "row" },
              ]}
            >
              <View style={[styles.halfWidthRightSection]}>
                <Text style={[styles.boldText, { left: 5 }]}>
                  NOTIFY PARTY:
                </Text>

                <Text style={[styles.largeText, { left: 5 }]}>
                  {" "}
                  GLOBAL TRADING & COMPANY (PVT) LTD.
                </Text>
                <Text style={[styles.largeText, { left: 5 }]}>
                  {" "}
                  NO: 136/F, DAM STREET,
                </Text>
                <Text style={[styles.largeText, { left: 5 }]}>
                  {" "}
                  COLOMBO – 12, SRI LANKA.
                </Text>
                <Text style={[styles.largeText, { left: 5 }]}>
                  {" "}
                  CONTACT NO_______
                </Text>
                <Text style={[styles.largeText, { left: 5 }]}>
                  {" "}
                  E MAIL ID:__________
                </Text>
                <Text style={[styles.largeText, { left: 5 }]}>
                  {" "}
                  TAX ID:____________
                </Text>
                <Text style={[styles.largeText, { left: 5 }]}>
                  {" "}
                  CONTACT NAME:_______
                </Text>
              </View>
              <View
                style={[
                  styles.sideBySideContainer,
                  { height: 110, flexDirection: "row", borderBottom: 1 },
                ]}
              >
                <View
                  style={[styles.halfWidthLeftSection, { borderBottom: 0 }]}
                >
                  <Text
                    style={[
                      styles.boldText,
                      {
                        borderBottom: 0,
                        left: 10,
                        top: 15,
                      },
                    ]}
                  >
                    {" "}
                    SWITCH BL LOCATION:{" "}
                  </Text>
                </View>
              </View>
            </View>

            {/* Port detail */}
            <View
              style={[
                styles.sideBySideContainer,
                { height: 20, flexDirection: "row" },
              ]}
            >
              <View style={[styles.halfWidthRightSection]}>
                <Text style={[styles.boldText, { left: 5, top: 3 }]}>
                  PORT OF LOADING: CHENNAI , INDIA
                </Text>
              </View>
              <View
                style={[
                  styles.sideBySideContainer,
                  { height: 55, flexDirection: "row", borderBottom: 1 },
                ]}
              >
                <View
                  style={[styles.halfWidthLeftSection, { borderBottom: 0 }]}
                >
                  <Text
                    style={[
                      styles.boldText,
                      {
                        borderBottom: 0,
                        left: 10,
                        top: 15,
                      },
                    ]}
                  >
                    {" "}
                    VESSEL VOYAGE :
                  </Text>
                </View>
              </View>
            </View>

            <View
              style={[
                styles.sideBySideContainer,
                styles.halfWidthRightSection,
                { height: 35, borderBottom: 1 },
              ]}
            >
              <Text
                style={[
                  styles.boldText,
                  {
                    borderRight: 1,
                    width: 150,
                    left: 5,
                    paddingTop: 5,
                  },
                ]}
              >
                PORT OF DISCHARGE:{"\n"}
                COLOMBO, SRI LANKA
              </Text>
              <Text
                style={[
                  styles.boldText,
                  {
                    width: 200,
                    paddingTop: 5,
                    left: 9,
                  },
                ]}
              >
                Final Place of Delivery:{"\n"}
                COLOMBO, SRI LANKA
              </Text>
            </View>

            {/* table */}
            {/* table headings */}
            <View>
              <View
                style={[
                  styles.tableRow,
                  {
                    borderBottom: 1,
                    justifyContent: "space-between",
                  },
                ]}
              >
                <Text
                  style={[
                    styles.boldText,
                    {
                      borderRight: 1,
                      textAlign: "left",
                      flexWrap: "wrap",
                      paddingLeft: 5,
                      width: 145,
                    },
                  ]}
                >
                  MARKS & NUMBERS
                </Text>

                <Text
                  style={[
                    styles.boldText,
                    {
                      borderRight: 1,
                      textAlign: "left",
                      flexWrap: "wrap",
                      width: 345,
                      paddingLeft: 5,
                    },
                  ]}
                >
                  NUMBER AND KIND OF PACKAGES DESCRIPTION OF GOODS
                </Text>

                <Text
                  style={[
                    styles.boldText,
                    {
                      borderRight: 1,
                      paddingLeft: 2,
                      textAlign: "left",
                      flexWrap: "wrap",
                      width: 80,
                    },
                  ]}
                >
                  GROSS WEIGHT
                </Text>

                <Text
                  style={[
                    styles.boldText,
                    {
                      paddingLeft: 2,
                      borderRight: 0,
                      textAlign: "left",
                      flexWrap: "wrap",
                      width: 80,
                    },
                  ]}
                >
                  MEASUREMENT
                </Text>
              </View>

              <View>
                {/* MAIN ROW */}
                <View
                  style={[
                    styles.tableRow,
                    {
                      borderBottom: 1,
                    },
                  ]}
                >
                  <Text
                    style={[
                      styles.regularFontSize,
                      styles.largeFontSize,
                      {
                        borderRight: 1,
                        textAlign: "center",
                        flexWrap: "wrap",
                        paddingLeft: 2,
                        width: 145,
                      },
                    ]}
                  >
                    {/* 02 X 20' FCL */}
                  </Text>

                  <View style={[styles.container]}>
                    <Text
                      style={[styles.regularFontSize, styles.largeFontSize]}
                    >
                      3 X 20 ‘ DC CONTAINER STC
                    </Text>
                    <View style={{ marginTop: 10 }}>
                      <Text
                        style={[styles.regularFontSize, styles.largeFontSize]}
                      >
                        81.000 MTS PARBOILED RICE GR 11-5% BROK.EN
                      </Text>
                      <Text
                        style={[styles.regularFontSize, styles.largeFontSize]}
                      >
                        PACKING IN 30 KILO NEW WHITE PP BAGS
                      </Text>
                      <Text
                        style={[styles.regularFontSize, styles.largeFontSize]}
                      >
                        AND GROSS WT 30.01KG EACH BAG
                      </Text>
                      <Text
                        style={[styles.regularFontSize, styles.largeFontSize]}
                      >
                        INVOICE NO. LE/EXP/24-25/079 DT 28.12.2024
                      </Text>
                      <Text
                        style={[styles.regularFontSize, styles.largeFontSize]}
                      >
                        EXPORTERS H.S.CODE 1006.30.10
                      </Text>
                      <Text
                        style={[styles.regularFontSize, styles.largeFontSize]}
                      >
                        IMPORTERS H.S CODE 1006.30.19
                      </Text>
                      <Text
                        style={[styles.regularFontSize, styles.largeFontSize]}
                      >
                        NET WT : 81.000 MT
                      </Text>
                      <Text
                        style={[styles.regularFontSize, styles.largeFontSize]}
                      >
                        GRS WT : 81.270 MT
                      </Text>
                      <Text
                        style={[styles.regularFontSize, styles.largeFontSize]}
                      >
                        TOTAL NO. OF BAGS: 2700 BAGS
                      </Text>
                      <Text
                        style={[styles.regularFontSize, styles.largeFontSize]}
                      >
                        SB NO:
                      </Text>
                    </View>
                    <View style={{ marginTop: 10 }}>
                      <Text
                        style={[styles.regularFontSize, styles.largeFontSize]}
                      >
                        AGREED FREE DAYS : 14 DAYS FREE PERIOD
                      </Text>
                      <Text
                        style={[styles.regularFontSize, styles.largeFontSize]}
                      >
                        AT DESTINATION
                      </Text>
                    </View>
                    <View style={{ marginTop: 10 }}>
                      <Text
                        style={[styles.regularFontSize, styles.largeFontSize]}
                      >
                        TERMS : FREIGHT PREPAID
                      </Text>
                    </View>
                    <View style={{ marginTop: 10 }}>
                      <Text
                        style={[styles.regularFontSize, styles.largeFontSize]}
                      >
                        “SHIPPED ON BOARD”
                      </Text>
                    </View>
                  </View>

                  <Text
                    style={[
                      styles.tableCell,
                      styles.largeFontSize,
                      {
                        borderRight: 1,
                        textAlign: "left",
                        flexWrap: "wrap",
                        width: 80,
                        paddingTop: 5,
                      },
                    ]}
                  >
                    81270.000 KGS
                  </Text>

                  <Text
                    style={[
                      styles.tableCell,
                      styles.largeFontSize,
                      {
                        borderRight: 0,
                        textAlign: "left",
                        flexWrap: "wrap",
                        width: 80,
                      },
                    ]}
                  >
                    985.00
                  </Text>
                </View>
              </View>
              <View
                style={[
                  styles.tableRow,
                  {
                    borderBottom: 0,
                  },
                ]}
              >
                <Text
                  style={[
                    styles.largeFontSize,
                    {
                      borderRight: 1,
                      textAlign: "left",
                      flexWrap: "wrap",
                      paddingLeft: 5,
                      paddingTop: 15,
                      width: 145,
                    },
                  ]}
                >
                  POD AGENT
                </Text>
                <Text
                  style={[
                    styles.largeFontSize,
                    {
                      borderRight: 1,
                      textAlign: "center",
                      flexWrap: "wrap",
                      width: 345,
                      paddingTop: 15,
                      paddingLeft: 5,
                    },
                  ]}
                >
                  CY/CY
                </Text>

                <Text
                  style={[
                    styles.largeFontSize,
                    {
                      borderRight: 1,
                      paddingLeft: 2,
                      textAlign: "left",
                      flexWrap: "wrap",
                      paddingTop: 15,
                      width: 80,
                    },
                  ]}
                >
                  BL TYPE:
                </Text>

                <Text
                  style={[
                    styles.largeFontSize,
                    {
                      paddingLeft: 2,
                      borderRight: 0,
                      textAlign: "left",
                      flexWrap: "wrap",
                      width: 80,
                      paddingTop: 15,
                    },
                  ]}
                >
                  DRAFT
                </Text>
              </View>
            </View>
          </View>
        </Page>

        {/* page 2 */}

        <Page style={styles.page} size="A4">
          <View style={styles.fullpage}>
            <View style={[{ marginTop: 5, marginBottom: 5 }]}>
              <Text style={[styles.largeFontSize, { textAlign: "center" }]}>
                PROVIDE US BELOW CONTAINER WISE SPLIT UP (PACKAGES & KGS)
              </Text>
            </View>
            <View>
              <View
                style={[
                  styles.tableContainer,
                  styles.boldText,
                  {
                    height: 15,
                    borderTopWidth: 1,
                  },
                ]}
              >
                <View
                  style={[
                    styles.tableRow,
                    {
                      flexDirection: "row",
                      width: "100%",
                    },
                  ]}
                >
                  <Text
                    style={[
                      styles.largeFontSize,
                      {
                        borderRightWidth: 1,
                        textAlign: "center",
                        flexWrap: "wrap",
                        width: "25%",
                        justifyContent: "space-between",
                      },
                    ]}
                  >
                    CONTR. NO & SEAL NO
                  </Text>
                  <Text
                    style={[
                      styles.largeFontSize,
                      {
                        borderRightWidth: 1,
                        textAlign: "center",
                        flexWrap: "wrap",
                        width: "15%",
                      },
                    ]}
                  >
                    SIZE
                  </Text>
                  <Text
                    style={[
                      styles.largeFontSize,
                      {
                        borderRightWidth: 1,
                        textAlign: "center",
                        flexWrap: "wrap",
                        width: "15%",
                      },
                    ]}
                  >
                    NO OF BAGS
                  </Text>
                  <Text
                    style={[
                      styles.largeFontSize,
                      {
                        borderRightWidth: 1,
                        textAlign: "center",
                        flexWrap: "wrap",
                        width: "15%",
                      },
                    ]}
                  >
                    NET WT
                  </Text>
                  <Text
                    style={[
                      styles.largeFontSize,
                      {
                        borderRightWidth: 1,
                        textAlign: "center",
                        flexWrap: "wrap",
                        width: "15%",
                      },
                    ]}
                  >
                    GR WT
                  </Text>
                  <Text
                    style={[
                      styles.largeFontSize,
                      {
                        textAlign: "center",
                        flexWrap: "wrap",
                        width: "15%",
                      },
                    ]}
                  >
                    CBM
                  </Text>
                </View>
              </View>

              {/* table data  */}

              <View
                style={[
                  styles.tableContainer,
                  {
                    height: 15,
                  },
                ]}
              >
                <View
                  style={[
                    styles.tableRow,
                    {
                      flexDirection: "row",
                      width: "100%",
                    },
                  ]}
                >
                  <Text
                    style={[
                      styles.largeFontSize,
                      {
                        borderRight: 1,
                        textAlign: "center",
                        flexWrap: "wrap",
                        width: "25%",
                        justifyContent: "space-between",
                      },
                    ]}
                  >
                    {/* CONTR. NO & SEAL NO */}
                  </Text>
                  <Text
                    style={[
                      styles.largeFontSize,
                      {
                        borderRightWidth: 1,
                        textAlign: "center",
                        flexWrap: "wrap",
                        width: "15%",
                      },
                    ]}
                  >
                    20
                  </Text>
                  <Text
                    style={[
                      styles.largeFontSize,
                      {
                        borderRightWidth: 1,
                        textAlign: "center",
                        flexWrap: "wrap",
                        width: "15%",
                      },
                    ]}
                  >
                    900
                  </Text>
                  <Text
                    style={[
                      styles.largeFontSize,
                      {
                        borderRightWidth: 1,
                        textAlign: "center",
                        flexWrap: "wrap",
                        width: "15%",
                      },
                    ]}
                  >
                    27000
                  </Text>
                  <Text
                    style={[
                      styles.largeFontSize,
                      {
                        borderRightWidth: 1,
                        textAlign: "center",
                        flexWrap: "wrap",
                        width: "15%",
                      },
                    ]}
                  >
                    27090
                  </Text>
                  <Text
                    style={[
                      styles.largeFontSize,
                      {
                        textAlign: "center",
                        flexWrap: "wrap",
                        width: "15%",
                      },
                    ]}
                  >
                    {/* CBM */}
                  </Text>
                </View>
              </View>

              <View
                style={[
                  styles.tableContainer,
                  {
                    height: 15,
                  },
                ]}
              >
                <View
                  style={[
                    styles.tableRow,
                    {
                      flexDirection: "row",
                      width: "100%",
                    },
                  ]}
                >
                  <Text
                    style={[
                      styles.largeFontSize,
                      {
                        borderRightWidth: 1,
                        textAlign: "center",
                        flexWrap: "wrap",
                        width: "25%",
                        justifyContent: "space-between",
                      },
                    ]}
                  >
                    {/* CONTR. NO & SEAL NO */}
                  </Text>
                  <Text
                    style={[
                      styles.largeFontSize,
                      {
                        borderRightWidth: 1,
                        textAlign: "center",
                        flexWrap: "wrap",
                        width: "15%",
                      },
                    ]}
                  >
                    20
                  </Text>
                  <Text
                    style={[
                      styles.largeFontSize,
                      {
                        borderRightWidth: 1,
                        textAlign: "center",
                        flexWrap: "wrap",
                        width: "15%",
                      },
                    ]}
                  >
                    900
                  </Text>
                  <Text
                    style={[
                      styles.largeFontSize,
                      {
                        borderRightWidth: 1,
                        textAlign: "center",
                        flexWrap: "wrap",
                        width: "15%",
                      },
                    ]}
                  >
                    27000
                  </Text>
                  <Text
                    style={[
                      styles.largeFontSize,
                      {
                        borderRightWidth: 1,
                        textAlign: "center",
                        flexWrap: "wrap",
                        width: "15%",
                      },
                    ]}
                  >
                    27090
                  </Text>
                  <Text
                    style={[
                      styles.largeFontSize,
                      {
                        textAlign: "center",
                        flexWrap: "wrap",
                        width: "15%",
                      },
                    ]}
                  >
                    {/* CBM */}
                  </Text>
                </View>
              </View>

              <View
                style={[
                  styles.tableContainer,
                  {
                    height: 15,
                  },
                ]}
              >
                <View
                  style={[
                    styles.tableRow,
                    {
                      flexDirection: "row",
                      width: "100%",
                    },
                  ]}
                >
                  <Text
                    style={[
                      styles.largeFontSize,
                      {
                        borderRightWidth: 1,
                        textAlign: "center",
                        flexWrap: "wrap",
                        width: "25%",
                        justifyContent: "space-between",
                      },
                    ]}
                  >
                    {/* CONTR. NO & SEAL NO */}
                  </Text>
                  <Text
                    style={[
                      styles.largeFontSize,
                      {
                        borderRightWidth: 1,
                        textAlign: "center",
                        flexWrap: "wrap",

                        width: "15%",
                      },
                    ]}
                  >
                    20
                  </Text>
                  <Text
                    style={[
                      styles.largeFontSize,
                      {
                        borderRightWidth: 1,
                        textAlign: "center",
                        flexWrap: "wrap",
                        width: "15%",
                      },
                    ]}
                  >
                    900
                  </Text>
                  <Text
                    style={[
                      styles.largeFontSize,
                      {
                        borderRightWidth: 1,
                        textAlign: "center",
                        flexWrap: "wrap",
                        width: "15%",
                      },
                    ]}
                  >
                    27000
                  </Text>
                  <Text
                    style={[
                      styles.largeFontSize,
                      {
                        borderRightWidth: 1,
                        textAlign: "center",
                        flexWrap: "wrap",
                        width: "15%",
                      },
                    ]}
                  >
                    27090
                  </Text>
                  <Text
                    style={[
                      styles.largeFontSize,
                      {
                        textAlign: "center",
                        flexWrap: "wrap",
                        width: "15%",
                      },
                    ]}
                  >
                    {/* CBM */}
                  </Text>
                </View>
              </View>

              <View
                style={[
                  styles.tableContainer,
                  {
                    height: 15,
                  },
                ]}
              >
                <View
                  style={[
                    styles.tableRow,
                    {
                      flexDirection: "row",
                      width: "100%",
                    },
                  ]}
                >
                  <Text
                    style={[
                      styles.largeFontSize,
                      {
                        borderRightWidth: 1,
                        textAlign: "center",
                        flexWrap: "wrap",
                        width: "25%",
                        justifyContent: "space-between",
                      },
                    ]}
                  >
                    {/* CONTR. NO & SEAL NO */}
                  </Text>
                  <Text
                    style={[
                      styles.largeFontSize,
                      {
                        borderRightWidth: 1,
                        textAlign: "center",
                        flexWrap: "wrap",

                        width: "15%",
                      },
                    ]}
                  >
                    20
                  </Text>
                  <Text
                    style={[
                      styles.largeFontSize,
                      {
                        borderRightWidth: 1,
                        textAlign: "center",
                        flexWrap: "wrap",
                        width: "15%",
                      },
                    ]}
                  >
                    900
                  </Text>
                  <Text
                    style={[
                      styles.largeFontSize,
                      {
                        borderRightWidth: 1,
                        textAlign: "center",
                        flexWrap: "wrap",
                        width: "15%",
                      },
                    ]}
                  >
                    27000
                  </Text>
                  <Text
                    style={[
                      styles.largeFontSize,
                      {
                        borderRightWidth: 1,
                        textAlign: "center",
                        flexWrap: "wrap",
                        width: "15%",
                      },
                    ]}
                  >
                    27090
                  </Text>
                  <Text
                    style={[
                      styles.largeFontSize,
                      {
                        textAlign: "center",
                        flexWrap: "wrap",
                        width: "15%",
                      },
                    ]}
                  >
                    {/* CBM */}
                  </Text>
                </View>
              </View>

              <View
                style={[
                  styles.tableContainer,
                  {
                    height: 15,
                  },
                ]}
              >
                <View
                  style={[
                    styles.tableRow,
                    {
                      flexDirection: "row",
                      width: "100%",
                    },
                  ]}
                >
                  <Text
                    style={[
                      styles.largeFontSize,
                      {
                        borderRightWidth: 1,
                        textAlign: "center",
                        flexWrap: "wrap",
                        width: "25%",
                        justifyContent: "space-between",
                      },
                    ]}
                  >
                    {/* CONTR. NO & SEAL NO */}
                  </Text>
                  <Text
                    style={[
                      styles.largeFontSize,
                      {
                        borderRightWidth: 1,
                        textAlign: "center",
                        flexWrap: "wrap",

                        width: "15%",
                      },
                    ]}
                  >
                    20
                  </Text>
                  <Text
                    style={[
                      styles.largeFontSize,
                      {
                        borderRightWidth: 1,
                        textAlign: "center",
                        flexWrap: "wrap",
                        width: "15%",
                      },
                    ]}
                  >
                    900
                  </Text>
                  <Text
                    style={[
                      styles.largeFontSize,
                      {
                        borderRightWidth: 1,
                        textAlign: "center",
                        flexWrap: "wrap",
                        width: "15%",
                      },
                    ]}
                  >
                    27000
                  </Text>
                  <Text
                    style={[
                      styles.largeFontSize,
                      {
                        borderRightWidth: 1,
                        textAlign: "center",
                        flexWrap: "wrap",
                        width: "15%",
                      },
                    ]}
                  >
                    27090
                  </Text>
                  <Text
                    style={[
                      styles.largeFontSize,
                      {
                        textAlign: "center",
                        flexWrap: "wrap",
                        width: "15%",
                      },
                    ]}
                  >
                    {/* CBM */}
                  </Text>
                </View>
              </View>

              <View
                style={[
                  styles.tableContainer,
                  {
                    height: 15,
                  },
                ]}
              >
                <View
                  style={[
                    styles.tableRow,
                    {
                      flexDirection: "row",
                      width: "100%",
                    },
                  ]}
                >
                  <Text
                    style={[
                      styles.largeFontSize,
                      {
                        borderRightWidth: 1,
                        textAlign: "center",
                        flexWrap: "wrap",
                        width: "25%",
                        justifyContent: "space-between",
                      },
                    ]}
                  >
                    {/* CONTR. NO & SEAL NO */}
                  </Text>
                  <Text
                    style={[
                      styles.largeFontSize,
                      {
                        borderRightWidth: 1,
                        textAlign: "center",
                        flexWrap: "wrap",
                        width: "15%",
                      },
                    ]}
                  >
                    20
                  </Text>
                  <Text
                    style={[
                      styles.largeFontSize,
                      {
                        borderRightWidth: 1,
                        textAlign: "center",
                        flexWrap: "wrap",
                        width: "15%",
                      },
                    ]}
                  >
                    900
                  </Text>
                  <Text
                    style={[
                      styles.largeFontSize,
                      {
                        borderRightWidth: 1,
                        textAlign: "center",
                        flexWrap: "wrap",
                        width: "15%",
                      },
                    ]}
                  >
                    27000
                  </Text>
                  <Text
                    style={[
                      styles.largeFontSize,
                      {
                        borderRightWidth: 1,
                        textAlign: "center",
                        flexWrap: "wrap",
                        width: "15%",
                      },
                    ]}
                  >
                    27090
                  </Text>
                  <Text
                    style={[
                      styles.largeFontSize,
                      {
                        textAlign: "center",
                        flexWrap: "wrap",
                        width: "15%",
                      },
                    ]}
                  >
                    {/* CBM */}
                  </Text>
                </View>
              </View>

              <View
                style={[
                  styles.tableContainer,
                  {
                    height: 15,
                  },
                ]}
              >
                <View
                  style={[
                    styles.tableRow,
                    {
                      flexDirection: "row",
                      width: "100%",
                    },
                  ]}
                >
                  <Text
                    style={[
                      styles.largeFontSize,
                      {
                        borderRightWidth: 1,
                        textAlign: "center",
                        flexWrap: "wrap",
                        width: "25%",
                        justifyContent: "space-between",
                      },
                    ]}
                  >
                    {/* CONTR. NO & SEAL NO */}
                  </Text>
                  <Text
                    style={[
                      styles.largeFontSize,
                      {
                        borderRightWidth: 1,
                        textAlign: "center",
                        flexWrap: "wrap",
                        width: "15%",
                      },
                    ]}
                  >
                    20
                  </Text>
                  <Text
                    style={[
                      styles.largeFontSize,
                      {
                        borderRightWidth: 1,
                        textAlign: "center",
                        flexWrap: "wrap",
                        width: "15%",
                      },
                    ]}
                  >
                    900
                  </Text>
                  <Text
                    style={[
                      styles.largeFontSize,
                      {
                        borderRightWidth: 1,
                        textAlign: "center",
                        flexWrap: "wrap",
                        width: "15%",
                      },
                    ]}
                  >
                    27000
                  </Text>
                  <Text
                    style={[
                      styles.largeFontSize,
                      {
                        borderRightWidth: 1,
                        textAlign: "center",
                        flexWrap: "wrap",
                        width: "15%",
                      },
                    ]}
                  >
                    27090
                  </Text>
                  <Text
                    style={[
                      styles.largeFontSize,
                      {
                        textAlign: "center",
                        flexWrap: "wrap",
                        width: "15%",
                      },
                    ]}
                  >
                    {/* CBM */}
                  </Text>
                </View>
              </View>
            </View>
            {/* instructions */}
            <View
              style={[
                styles.largeFontSize,
                {
                  justifyContent: "space-between",
                  left: 5,
                  marginRight: 15,
                  marginTop: 10,
                  marginBottom: 5,
                },
              ]}
            >
              <Text style={{ marginTop: 10 }}>
                1. If Switch BL involved provide us the Second Leg BL thru same
                format along with surrender letter for 1st Leg BL and LOI for
                2nd Leg BL.
              </Text>
              <Text style={{ marginTop: 10 }}>
                2. If Surrender BL , Surrender letter should be provided in
                Shipper Letter head.
              </Text>
              <Text style={{ marginTop: 10 }}>
                3. NATIONAL ID , NPWP NO , HSN CODE , ACID NO , USCI NO are
                Mandatory to some of the Destination in CUSTOMS to avoid
                additional charges / delay in POD.
              </Text>
              <Text style={{ marginTop: 10 }}>
                4. Consignee contact No and Mail Id is Mandatory to share CAN
                (Cargo Arrival Notice) without delay at POD.
              </Text>
              <Text style={{ marginTop: 10 }}>
                5. Ensure to confirm us the BL type for the shipment along with
                share us Invoice party details (GST , PAN , TAN copy) for the
                shipment.
              </Text>
            </View>
          </View>
        </Page>
      </Document>
    </PDFViewer>
  );
};
export default BlDraftInvoice;
