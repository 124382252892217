import {
  Document,
  Page,
  Text,
  View,
  Font,
  // Image,
  StyleSheet,
} from "@react-pdf/renderer";
import moment from "moment";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import {
  convertAmountToWords,
  formatAmount,
  stringToUpperCase,
} from "../../../components/Helper";
import { CURRENCY } from "../../../components/Constants";

Font.register({
  family: "HostGrotesk",
  fonts: [
    { src: "/fonts/HostGrotesk-Regular.ttf" },
    { src: "/fonts/HostGrotesk-Bold.ttf", fontWeight: "bold" },
  ],
});

export const styles = StyleSheet.create({
  boldText: {
    fontWeight: "bold",
    fontSize: 8,
  },
  borderTop: {
    borderTopWidth: 1,
    borderTopColor: "black",
    borderTopStyle: "solid",
  },
  borderBottom: {
    borderBottomWidth: 1,
    borderBottomColor: "black",
    borderbottomStyle: "solid",
  },
  smallText: {
    fontSize: 6,
  },
  mediumText: {
    fontSize: 6.5,
  },
  contentPadding: {
    padding: 1,
  },
  purchaseDetails: {
    fontSize: 7,
  },
  page: {
    paddingHorizontal: 30,
    paddingVertical: 15,
    fontFamily: "HostGrotesk",
    backgroundColor: "white",
  },
  fullpage: {
    margin: 7,
    borderStyle: "solid",
    borderWidth: 1,
    borderColor: "#000000",
  },
  header: {
    display: "flex",
    justifyContent: "flex-start",
    padding: 1,
    fontWeight: "bold",
    borderBottomWidth: 1,
    borderBottomColor: "black",
    borderBottomStyle: "solid",
  },
  subheader: {
    display: "flex",
    fontSize: 10,
    textAlign: "center",
    padding: 1,
    marginBottom: 0,
    marginTop: 10,
    fontWeight: "bold",
  },
  subheaderText: {
    marginTop: 5,
    display: "flex",
    justifyContent: "center",
    borderBottomWidth: 1,
    borderBottomColor: "black",
    borderBottomStyle: "solid",
  },

  section: {
    borderBottomWidth: 1,
    borderBottomColor: "black",
    borderbottomStyle: "solid",
  },
  sideBySideContainer: { flexDirection: "row" },
  halfWidthRightSection: {
    width: "50%",
  },
  halfWidthleftSection: {
    width: "50%",
    borderLeftWidth: 1,
    borderBottomColor: "black",
    borderbottomStyle: "solid",
  },
  tableContainer: {
    borderBottomWidth: 1,
    borderBottomColor: "black",
  },
  tableRow: {
    flexDirection: "row",
    flexGrow: 1,
    alignItems: "stretch",
  },
  tableCell: {
    padding: 5,
    fontSize: 7,
    textAlign: "center",
    borderRightWidth: 1,
    borderRightColor: "black",
    borderRightStyle: "solid",
  },
  expandableDescriptionCell: {
    flex: 8,
    flexDirection: "column",
    alignItems: "flex-start",
    textAlign: "left",
  },
  descriptionText: {
    alignSelf: "flex-start",
    fontSize: 6.5,
    flexWrap: "wrap",
    paddingVertical: 1,
  },
  emptyTableRow: {
    minHeight: 50,
  },
  thirdWidthSection: {
    flex: 1,
    height: 100,
    padding: 2,
    borderTopWidth: 1,
    borderTopColor: "black",
    borderTopStyle: "solid",
    borderLeftWidth: 1,
    borderLeftColor: "black",
    borderLeftStyle: "solid",
  },
  signatoryContainer: {
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "center",
  },
  addressBlock: { fontSize: 8, marginBottom: 1.5, paddingHorizontal: 3 },
  subComponentHeading: {
    paddingHorizontal: 3,
    fontSize: 8,
    marginBottom: 1.5,
    borderBottomWidth: 1,
    borderBottomColor: "black",
    borderbottomStyle: "solid",
  },
  subComponentText: {
    fontSize: 7,
    marginBottom: 1.5,
    fontWeight: "bold",
  },
  footer: {
    display: "flex",
    justifyContent: "center",
    textAlign: "center",
    fontSize: 8,
    marginBottom: 1.5,
    marginTop: 1.5,
  },
  logo: {
    height: "60px",
    width: "60px",
  },
  footerInline: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
  },
});

const SalesContractInvoice = ({
  settingData,
  buyerData,
  salesContractData,
}: {
  settingData: any;
  buyerData: any;
  salesContractData: any;
}) => {
  const qualitySpecifications = salesContractData.quality_specifications || [];
  const middleIndex = Math.ceil(qualitySpecifications.length / 2);

  const qsLeftData = qualitySpecifications.slice(0, middleIndex);
  const qsRightData = qualitySpecifications.slice(middleIndex);

  return (
    <Document>
      <Page style={styles.page} size="A4">
        <View style={styles.header}>
          {/* <Image src="/img/logo/ee-main.png" style={styles.logo} /> */}
          {/* Remove below view after logo is added */}
          <View style={styles.logo}></View>
        </View>
        <View style={styles.header}>
          <Text style={[styles.addressBlock, { fontSize: 9 }]}>
            Start Export House And Authorized Economic Operator, Govt.Of India
          </Text>
        </View>
        <View style={[styles.subheader]}>
          <Text
            style={[
              styles.borderBottom,
              {
                width: "20%",
                margin: "auto",
              },
            ]}
          >
            SALES CONTRACT
          </Text>
        </View>

        {/* Shipper/Exporter & Buyer/Consignee Details */}
        <View style={styles.fullpage}>
          <View style={styles.sideBySideContainer}>
            <View style={[styles.halfWidthRightSection, styles.contentPadding]}>
              <Text style={styles.subComponentHeading}>
                <Text style={styles.boldText}>DATE : </Text>
                {moment(salesContractData?.contract_date).format("DD/MM/YYYY")}
              </Text>
              <Text
                style={[
                  styles.borderBottom,
                  styles.addressBlock,
                  {
                    width: "33%",
                  },
                ]}
              >
                SHIPPER EXPORTER :
              </Text>
              <Text
                style={[styles.boldText, { fontSize: 9, paddingHorizontal: 3 }]}
              >
                {stringToUpperCase(settingData.company_name)}
              </Text>

              <Text style={styles.addressBlock}>
                {stringToUpperCase(settingData.company_address)}
              </Text>
            </View>

            <View style={[styles.halfWidthleftSection, styles.contentPadding]}>
              <Text style={styles.subComponentHeading}>
                <Text style={styles.boldText}>CONTRACT NO :</Text>{" "}
                {stringToUpperCase(salesContractData?.contract_no)}{" "}
                {salesContractData?.reference_no
                  ? `(${salesContractData?.reference_no})`
                  : ""}
              </Text>
              <Text
                style={[
                  styles.borderBottom,
                  styles.addressBlock,
                  {
                    width: "35%",
                  },
                ]}
              >
                BUYER / CONSIGNEE :
              </Text>
              <Text
                style={[styles.boldText, { fontSize: 9, paddingHorizontal: 3 }]}
              >
                {stringToUpperCase(buyerData.name)}
              </Text>
              <Text style={styles.addressBlock}>
                {stringToUpperCase(buyerData.address)}
              </Text>
            </View>
          </View>
        </View>

        {/* Particulars & Shipment Term */}
        <View style={styles.fullpage}>
          <View style={styles.sideBySideContainer}>
            <View style={[styles.halfWidthRightSection, styles.contentPadding]}>
              <Text style={styles.subComponentHeading}>
                <Text style={styles.boldText}>PARTICULAR : </Text>
              </Text>
              <Text style={styles.addressBlock}>
                <Text style={styles.boldText}>COMMODITY : </Text>
                {stringToUpperCase(salesContractData?.product?.name)}
              </Text>
              <Text style={styles.addressBlock}>
                <Text style={styles.boldText}>QUANTITY : </Text>
                {formatAmount(salesContractData.quantity)}{" "}
                {stringToUpperCase(salesContractData?.quantity_unit?.name)}{" "}
                {salesContractData?.container_load
                  ? `(${salesContractData?.container_load})`
                  : ""}
              </Text>
              <Text style={styles.addressBlock}>
                <Text style={styles.boldText}>PRICE : </Text>
                {CURRENCY.USD.NAME} {formatAmount(salesContractData.price)}
                {salesContractData?.quantity_unit?.name
                  ? `/${stringToUpperCase(
                      salesContractData?.quantity_unit?.name
                    )}`
                  : ""}
              </Text>
              <Text style={styles.addressBlock}>
                <Text style={styles.boldText}>VALUE : </Text>
                {CURRENCY.USD.NAME}{" "}
                {formatAmount(salesContractData?.total_amount)}
              </Text>
              <Text style={styles.addressBlock}>
                <Text style={styles.boldText}>VALUE IN WORDS : </Text>
                {convertAmountToWords(
                  Number(salesContractData?.total_amount),
                  CURRENCY.USD.NAME
                )}
              </Text>
              <Text style={styles.addressBlock}>
                <Text style={styles.boldText}>PACKING : </Text>
                NET {salesContractData.packing_net_weight}
                {stringToUpperCase(salesContractData?.packing_unit?.name)}{" "}
                {stringToUpperCase(salesContractData?.packing_type?.name)}
              </Text>
              <Text style={styles.addressBlock}>
                <Text style={styles.boldText}>MARKING : </Text>
                {stringToUpperCase(salesContractData?.marking)}
              </Text>
            </View>

            <View style={[styles.halfWidthleftSection, styles.contentPadding]}>
              <Text style={styles.subComponentHeading}>
                <Text style={styles.boldText}>SHIPMENT TERM : </Text>
              </Text>
              <Text style={styles.addressBlock}>
                <Text style={styles.boldText}>FINAL DESTINATION : </Text>
                {stringToUpperCase(salesContractData?.final_destination)}
              </Text>
              <Text style={styles.addressBlock}>
                <Text style={styles.boldText}>PORT OF LOADING : </Text>
                {stringToUpperCase(salesContractData?.port_of_loading)}
              </Text>
              <Text style={styles.addressBlock}>
                <Text style={styles.boldText}>PORT OF DISCHARGE : </Text>
                {stringToUpperCase(salesContractData?.port_of_discharge)}
              </Text>
              <Text style={styles.addressBlock}>
                <Text style={styles.boldText}>SHIPMENT : </Text>
                {stringToUpperCase(salesContractData?.shipment)}
              </Text>
              {salesContractData.trans_shipment && (
                <Text style={styles.addressBlock}>
                  <Text style={styles.boldText}>TRANS SHIPMENT : </Text>
                  {stringToUpperCase(salesContractData?.trans_shipment)}
                </Text>
              )}
              {salesContractData.partial_shipment && (
                <Text style={styles.addressBlock}>
                  <Text style={styles.boldText}>PARTIAL SHIPMENT : </Text>
                  {stringToUpperCase(salesContractData?.partial_shipment)}
                </Text>
              )}
              <Text style={styles.addressBlock}>
                <Text style={styles.boldText}>PAYMENT TERMS : </Text>
                {stringToUpperCase(salesContractData?.payment_terms)}
              </Text>
            </View>
          </View>
        </View>

        {/* Quality Specifications */}
        {salesContractData.quality_specifications.length > 0 && (
          <View style={styles.fullpage}>
            <Text style={[styles.subComponentHeading]}>
              <Text style={[styles.boldText]}>QUALITY SPECIFICATION :</Text>
            </Text>
            <View style={styles.sideBySideContainer}>
              <View
                style={[styles.halfWidthRightSection, styles.contentPadding]}
              >
                {qsLeftData.map((element: any, index: number) => {
                  return (
                    <Text style={styles.addressBlock} key={index}>
                      <Text style={styles.boldText}>
                        {index + 1}.{" "}
                        {stringToUpperCase(
                          element?.quality_specification?.name
                        )}{" "}
                        :
                      </Text>{" "}
                      {stringToUpperCase(element?.value)}
                    </Text>
                  );
                })}
              </View>

              {qsRightData && (
                <View
                  style={[styles.halfWidthleftSection, styles.contentPadding]}
                >
                  {qsRightData.map((element: any, index: number) => {
                    return (
                      <Text style={styles.addressBlock} key={index}>
                        <Text style={styles.boldText}>
                          {index + 1 + middleIndex}.{" "}
                          {stringToUpperCase(
                            element?.quality_specification?.name
                          )}{" "}
                          :
                        </Text>{" "}
                        {stringToUpperCase(element?.value)}
                      </Text>
                    );
                  })}
                </View>
              )}
            </View>
          </View>
        )}

        <View style={styles.fullpage}>
          {salesContractData.documents?.length > 0 && (
            <>
              <Text style={[styles.subComponentHeading]}>
                <Text style={styles.boldText}>REQUIRED DOCUMENTS :</Text>
              </Text>
              <View style={[styles.contentPadding]}>
                {salesContractData.documents.map(
                  (element: any, index: number) => {
                    return (
                      <Text style={styles.addressBlock} key={index}>
                        <Text style={styles.boldText}>
                          {index + 1}.{" "}
                          {stringToUpperCase(element?.document?.name)} :
                        </Text>{" "}
                        {stringToUpperCase(element?.value)}
                      </Text>
                    );
                  }
                )}
              </View>
            </>
          )}

          {(salesContractData?.exporter_bank ||
            salesContractData?.buyer_bank) && (
            <View style={[styles.contentPadding, styles.borderTop]}>
              <View style={styles.sideBySideContainer}>
                {salesContractData?.exporter_bank && (
                  <View
                    style={[
                      styles.halfWidthRightSection,
                      styles.contentPadding,
                    ]}
                  >
                    <Text
                      style={[
                        styles.borderBottom,
                        styles.addressBlock,
                        styles.boldText,
                        styles.contentPadding,
                        {
                          width: "39%",
                        },
                      ]}
                    >
                      EXPORTER BANK DETAILS :
                    </Text>
                    <Text style={styles.addressBlock}>
                      <Text>BANK NAME :</Text>{" "}
                      {stringToUpperCase(
                        salesContractData?.exporter_bank?.bank_name
                      )}
                    </Text>
                    <Text style={styles.addressBlock}>
                      <Text>ADDRESS : </Text>{" "}
                      {stringToUpperCase(
                        salesContractData?.exporter_bank?.address
                      )}
                    </Text>
                    <Text style={styles.addressBlock}>
                      <Text>OUR A/C NO : </Text>{" "}
                      {stringToUpperCase(
                        salesContractData?.exporter_bank?.account_number
                      )}
                    </Text>
                    <Text style={styles.addressBlock}>
                      <Text>SWIFT CODE : </Text>{" "}
                      {stringToUpperCase(
                        salesContractData?.exporter_bank?.swift_code
                      )}
                    </Text>
                  </View>
                )}

                {salesContractData?.buyer_bank && (
                  <View
                    style={[
                      styles.halfWidthleftSection,
                      styles.contentPadding,
                      {
                        borderLeftWidth: salesContractData?.exporter_bank
                          ? 1
                          : 0,
                      },
                    ]}
                  >
                    <Text
                      style={[
                        styles.borderBottom,
                        styles.addressBlock,
                        styles.boldText,
                        styles.contentPadding,
                        {
                          width: "33%",
                        },
                      ]}
                    >
                      BUYER BANK DETAILS :
                    </Text>
                    <Text style={styles.addressBlock}>
                      <Text>BANK NAME :</Text>{" "}
                      {stringToUpperCase(
                        salesContractData?.buyer_bank?.bank_name
                      )}
                    </Text>
                    <Text style={styles.addressBlock}>
                      <Text>ADDRESS : </Text>{" "}
                      {stringToUpperCase(
                        salesContractData?.buyer_bank?.address
                      )}
                    </Text>
                    <Text style={styles.addressBlock}>
                      <Text>OUR A/C NO : </Text>{" "}
                      {stringToUpperCase(
                        salesContractData?.buyer_bank?.account_number
                      )}
                    </Text>
                    <Text style={styles.addressBlock}>
                      <Text>SWIFT CODE : </Text>{" "}
                      {stringToUpperCase(
                        salesContractData?.buyer_bank?.swift_code
                      )}
                    </Text>
                  </View>
                )}
              </View>
            </View>
          )}

          <Text style={[styles.borderTop, styles.subComponentHeading]}>
            <Text style={styles.boldText}>GENERAL TERMS :</Text>
          </Text>

          <View style={[styles.contentPadding]}>
            <Text
              style={[
                styles.borderBottom,
                styles.addressBlock,
                styles.boldText,
                {
                  width: "18%",
                },
              ]}
            >
              WEIGHT AND QUALITY :
            </Text>
            <Text style={styles.addressBlock}>
              {salesContractData?.weight_and_quality
                ? salesContractData.weight_and_quality
                : `WEIGHT AND QUALITY`}
            </Text>
            <Text
              style={[
                styles.borderBottom,
                styles.addressBlock,
                styles.boldText,
                {
                  width: "12%",
                },
              ]}
            >
              ARBITRATION :
            </Text>
            <Text style={styles.addressBlock}>
              {salesContractData?.arbitration
                ? salesContractData.arbitration
                : `ARBITRATION`}
            </Text>
            <Text
              style={[
                styles.borderBottom,
                styles.addressBlock,
                styles.boldText,
                styles.contentPadding,
                {
                  width: "40%",
                },
              ]}
            >
              LANGUAGE IN WHICH DOCUMENT WILL BE PREPARED :
            </Text>
            <Text style={styles.addressBlock}>
              ALL THE DOCUMENT SHALL BE PREPARED IN ENGLISH LANGUAGE
            </Text>
          </View>
        </View>

        <View style={[styles.sideBySideContainer]}>
          <View style={[styles.contentPadding, { flex: 1 }]}>
            {/* <Image src="/img/logo/ee-main.png" style={styles.logo} /> */}
            {/* Remove below view after logo is added */}
            <View style={styles.logo}></View>
            <Text style={styles.addressBlock}>SHIPPER / EXPORTER</Text>
          </View>
          <View style={[styles.contentPadding]}>
            {/* <Image src="/img/logo/ee-main.png" style={styles.logo} /> */}
            {/* Remove below view after logo is added */}
            <View style={styles.logo}></View>
            <Text style={styles.addressBlock}>BUYER / IMPORTER</Text>
          </View>
        </View>

        <View style={styles.footer}>
          <Text style={[styles.boldText]}>
            {stringToUpperCase(settingData?.company_name)}
          </Text>
          <Text style={[styles.addressBlock]}>
            {stringToUpperCase(settingData?.company_address)}
          </Text>
          <View style={styles.footerInline}>
            <Text style={[styles.addressBlock]}>
              <Text style={styles.boldText}>Email : </Text>
              {stringToUpperCase(settingData?.company_email)}
            </Text>
            <Text style={[styles.addressBlock, { marginLeft: 5 }]}>
              <Text style={styles.boldText}>Phone No. : </Text>
              {stringToUpperCase(settingData?.company_phone_1)} /{" "}
              {stringToUpperCase(settingData?.company_phone_2)}
            </Text>
          </View>
          <View style={styles.footerInline}>
            <Text style={[styles.addressBlock]}>
              <Text style={styles.boldText}>Subject : </Text>
              Subject
            </Text>
            <Text style={[styles.addressBlock, { marginLeft: 5 }]}>
              <Text style={styles.boldText}>GSTIN No. : </Text>
              {stringToUpperCase(settingData?.company_gst_no)}
            </Text>
          </View>
        </View>
      </Page>
    </Document>
  );
};

export default SalesContractInvoice;
