/* eslint-disable react-hooks/exhaustive-deps */
import { Link, useNavigate, useParams } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { forgetPasswordApi, verifyOtpApi } from "../../services/_auth";
import { errorToast, successToast } from "../../components/Toast";
import { COMPANY_NAME } from "../../components/Constants";
import { useEffect } from "react";

const VerifyOtp = () => {
  const { email } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (!email) {
      navigate("/login");
    }
  }, [email]);

  const VerifyOtpValidationSchema = Yup.object().shape({
    otp: Yup.string().required("OTP is required"),
  });

  const VerifyOtpInitialValues = {
    email: email || "",
    otp: "",
  };

  const resendOtp = async (email: any) => {
    const result: any = await forgetPasswordApi({ email });
    if (!result.isSuccess) {
      errorToast(result.message);
    } else {
      successToast(`OTP resend ${result.message}`);
    }
  };

  const handleSubmit = async (values: any) => {
    const result: any = await verifyOtpApi(values);
    if (!result.isSuccess) {
      errorToast(result.message);
    } else {
      successToast(result.message);
      navigate(`/reset-password/${email}`);
    }
  };

  return (
    <div className="container-xxl">
      <div className="authentication-wrapper authentication-basic container-p-y">
        <div className="authentication-inner py-6">
          <div className="card">
            <div className="card-body">
              <div className="app-brand justify-content-center mb-6">
                <Link to="#" className="app-brand-link">
                  <span
                    className="app-brand-logo demo"
                    style={{ alignItems: "unset !important", height: "44px" }}
                  >
                    <img src="/img/logo/ee-main.svg" alt={COMPANY_NAME} />
                  </span>
                  <span className="app-brand-text demo text-heading fw-bold">
                    {COMPANY_NAME}
                  </span>
                </Link>
              </div>

              <h4 className="mb-1">Verify OTP ✉️</h4>
              <p className="text-start">
                Reset password OTP sent to your email address: <b>{email}</b>{" "}
                Please verify here to continue.
              </p>

              <Formik
                initialValues={VerifyOtpInitialValues}
                validationSchema={VerifyOtpValidationSchema}
                onSubmit={handleSubmit}
              >
                {({ handleChange, values }) => {
                  return (
                    <Form className="mb-4 my-6">
                      <div className="mb-6">
                        <label htmlFor="otp" className="form-label">
                          Enter OTP
                        </label>
                        <Field
                          type="text"
                          onChange={handleChange}
                          className="form-control"
                          id="otp"
                          name="otp"
                          placeholder="Enter your OTP"
                        />
                        <ErrorMessage
                          name="otp"
                          className="text-danger"
                          component="div"
                        />
                      </div>
                      <div className="mb-6">
                        <button
                          className="btn btn-primary d-grid w-100"
                          type="submit"
                        >
                          Verify OTP
                        </button>
                      </div>
                      <div className="text-center d-flex justify-content-between">
                        <Link to={"/login"}>
                          <i className="ti ti-chevron-left scaleX-n1-rtl me-1_5"></i>
                          Back to login
                        </Link>
                        <Link
                          to={"#"}
                          onClick={() => {
                            resendOtp(values.email);
                          }}
                        >
                          Resend OTP
                        </Link>
                      </div>
                    </Form>
                  );
                }}
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VerifyOtp;
