import { Document, Page, Text, View, StyleSheet } from "@react-pdf/renderer";
import {
  formatAmount,
  formatDate,
  stringToUpperCase,
} from "../../../../components/Helper";

export const styles = StyleSheet.create({
  boldText: {
    fontWeight: "bold",
    fontSize: 8,
  },
  smallText: {
    fontSize: 6,
  },
  mediumText: {
    fontSize: 6.5,
  },
  contentPadding: {
    padding: 1,
  },
  page: {
    paddingHorizontal: 30,
    paddingVertical: 15,
    fontFamily: "HostGrotesk",
    backgroundColor: "white",
  },
  fullpage: {
    margin: 7,
    borderStyle: "solid",
    borderWidth: 1,
    borderColor: "#000000",
  },
  leftSection: {
    flex: 1,
    padding: 10,
    paddingLeft: 0,
  },
  rightSection: {
    flex: 3,
    padding: 10,
  },
  centerSection: {
    flex: 7,
  },
  header: {
    fontSize: 10,
    textAlign: "center",
    padding: 1,
    marginBottom: 0,
    fontWeight: "bold",
    borderBottomWidth: 1,
    borderBottomColor: "black",
    borderBottomStyle: "solid",
  },
  sideBySideContainer: { flexDirection: "row" },
});

const PhytoSanitaryCertificate = ({
  settingData,
  salesInvoiceData,
}: {
  settingData: any;
  salesInvoiceData: any;
}) => {
  const staticData = {
    psc_no: "",
    reg_no_and_Date: "XXXXXX",
    place_of_issue: "CHENNAI",
  };

  return (
    <Document>
      <Page style={styles.page} size="A4">
        <View style={styles.fullpage}>
          {/* Header */}
          <View
            style={[
              styles.sideBySideContainer,
              {
                height: 70,
                flexDirection: "row",
              },
            ]}
          >
            {/* Left Section */}
            <View
              style={[
                styles.leftSection,
                {
                  borderRightWidth: 1,
                  borderRightColor: "black",
                  borderRightStyle: "solid",
                },
              ]}
            >
              {/* Left content */}
            </View>

            {/* Center Section */}
            <View
              style={[
                styles.centerSection,
                {
                  justifyContent: "center",
                  paddingHorizontal: 10,
                  borderRightWidth: 1,
                  borderRightColor: "black",
                  borderRightStyle: "solid",
                },
              ]}
            >
              <Text
                style={[
                  styles.header,
                  { borderBottom: 0, textAlign: "center" },
                ]}
              >
                GOVERNMENT OF INDIA
              </Text>
              <Text
                style={[
                  {
                    borderBottom: 0,
                    fontWeight: "normal",
                    fontSize: 10,
                    textAlign: "center",
                  },
                ]}
              >
                MINISTRY OF AGRICULTURE
              </Text>
              <Text
                style={[{ borderBottom: 0, fontSize: 10, textAlign: "center" }]}
              >
                DEPARTMENT OF AGRICULTURE & COOPERATION
              </Text>
              <Text
                style={[{ borderBottom: 0, fontSize: 10, textAlign: "center" }]}
              >
                DIRECTORATE OF PLANT PROTECTION, QUARANTINE & STORAGE
              </Text>
              <Text style={[styles.header, { borderBottom: 0 }]}>
                PHYTOSANITARY CERTIFICATE
              </Text>
            </View>

            {/* Right Section */}
            <View style={[styles.rightSection]}>
              <Text
                style={[
                  {
                    width: 148,
                    marginLeft: -7,
                    height: 20,
                    fontSize: 8,
                  },
                ]}
              >
                PSC NO :
              </Text>
              <Text
                style={[
                  {
                    width: 148,
                    marginLeft: -10,
                    borderBottom: 1,
                  },
                ]}
              ></Text>
              <Text
                style={[
                  styles.mediumText,
                  {
                    borderTop: 0,
                    width: 148,
                    marginLeft: -7,
                    marginTop: 5,
                    fontSize: 8,
                  },
                ]}
              >
                E. Reg No./Date :
              </Text>
              <Text
                style={[
                  styles.boldText,
                  {
                    borderTop: 0,
                    width: 148,
                    marginLeft: -7,
                    fontSize: 8,
                  },
                ]}
              >
                {stringToUpperCase(staticData.reg_no_and_Date)}
              </Text>
            </View>
          </View>

          {/* 1 | 2 :- From & To Section */}
          <View
            style={[
              {
                flexDirection: "row",
                borderTopWidth: 1,
                borderTopColor: "black",
                borderTopStyle: "solid",
              },
            ]}
          >
            {/* 1 | Left Section */}
            <View
              style={[
                {
                  width: "100%",
                  paddingLeft: 6,
                  paddingTop: 6,
                  paddingBottom: 0,
                  borderLeftWidth: 0,
                  borderRightWidth: 1,
                },
              ]}
            >
              <Text
                style={[
                  {
                    fontSize: 8,
                    fontWeight: "light",
                    verticalAlign: "super",
                  },
                ]}
              >
                1. FROM: PLANT PROTECTION ORGANISATION OF INDIA
              </Text>
            </View>

            {/* 2 | Right Section */}
            <View
              style={[
                {
                  width: "100%",
                  paddingLeft: 6,
                  paddingTop: 6,
                  paddingBottom: 0,
                  borderLeftWidth: 0,
                },
              ]}
            >
              <Text
                style={[
                  {
                    fontSize: 8,
                    fontWeight: "light",
                    verticalAlign: "super",
                  },
                ]}
              >
                2. TO: THE PLANT PROTECTION ORGANISATION OF{" "}
                {stringToUpperCase(salesInvoiceData?.buyer?.country)}
              </Text>
            </View>
          </View>

          {/* Title */}
          <View style={styles.sideBySideContainer}>
            <View style={styles.centerSection}>
              <Text
                style={[
                  styles.boldText,
                  { borderTop: 1, textAlign: "center", verticalAlign: "sub" },
                ]}
              >
                DESCRIPTION OF CONSIGNMENT
              </Text>
            </View>
          </View>

          {/* 3 | 4 :- Exporter & Consignee Section */}
          <View
            style={[
              styles.sideBySideContainer,
              {
                flexDirection: "row",
                // height: 50,
                borderTopWidth: 1,
                borderTopColor: "black",
                borderTopStyle: "solid",
              },
            ]}
          >
            {/* Left Section */}
            <View
              style={[
                styles.leftSection,
                {
                  borderLeftWidth: 0,
                  flex: 1,
                  borderRightWidth: 1,
                  justifyContent: "flex-start",
                  alignItems: "flex-start",
                  paddingLeft: 5,
                },
              ]}
            >
              <Text style={{ fontWeight: "light", fontSize: 9, marginTop: -5 }}>
                3. Name & Address of exporter
              </Text>
              <Text style={{ fontWeight: "heavy", fontSize: 9 }}>
                {stringToUpperCase(settingData?.company_address)}
              </Text>
            </View>

            {/* Right Section */}
            <View
              style={[
                styles.rightSection,
                {
                  borderLeftWidth: 0,
                  flex: 1,
                  borderRightWidth: 0,
                  justifyContent: "flex-start",
                  alignItems: "flex-start",
                  paddingLeft: 5,
                },
              ]}
            >
              <Text style={{ fontWeight: "light", fontSize: 9, marginTop: -5 }}>
                4. Declared name & address of consignee:
              </Text>
              <Text style={{ fontWeight: "heavy", fontSize: 9 }}>
                {stringToUpperCase(salesInvoiceData?.buyer?.address)}
              </Text>
            </View>
          </View>

          {/* 5 | 6 | 7 */}
          <View
            style={[
              styles.sideBySideContainer,
              {
                flexDirection: "row",
                height: 35,
                borderTopWidth: 1,
                borderTopColor: "black",
                borderTopStyle: "solid",
              },
            ]}
          >
            {/* Left Section */}
            <View
              style={[
                styles.leftSection,
                {
                  borderLeftWidth: 0,
                  flex: 1,
                  borderRightWidth: 1,
                  justifyContent: "center",

                  paddingLeft: 5,
                },
              ]}
            >
              <Text
                style={{
                  fontWeight: "medium",
                  fontSize: 8,
                  marginTop: -9,
                  alignItems: "flex-start",
                }}
              >
                5. Declared means of conveyance
              </Text>

              <Text
                style={{
                  fontWeight: "heavy",
                  fontSize: 9,
                  textAlign: "center",
                }}
              >
                BY SEA
              </Text>
            </View>

            {/* Right Section */}
            <View
              style={[
                styles.rightSection,
                {
                  borderLeftWidth: 0,
                  flex: 1,
                  borderRightWidth: 0,
                  justifyContent: "center",
                  paddingLeft: 5,
                  flexDirection: "row",
                },
              ]}
            >
              {/* First Section */}
              <View style={{ flex: 1 }}>
                <Text
                  style={{
                    fontWeight: "medium",
                    fontSize: 8,
                    marginTop: -9,
                    alignItems: "flex-start",
                  }}
                >
                  6. Place of origin
                </Text>

                <Text
                  style={{
                    fontWeight: "heavy",
                    fontSize: 9,
                    textAlign: "center",
                  }}
                >
                  INDIA
                </Text>
              </View>

              {/* Second Section */}
              <View
                style={{
                  flex: 1,
                  borderLeftWidth: 1,
                  height: 35,
                  marginTop: -10,
                }}
              >
                <Text
                  style={{
                    fontWeight: "medium",
                    fontSize: 8,
                    alignItems: "flex-start",
                    paddingTop: 2,
                    paddingLeft: 5,
                  }}
                >
                  7. Declared point of entry
                </Text>

                <Text
                  style={{
                    fontWeight: "heavy",
                    fontSize: 9,
                    textAlign: "center",
                  }}
                >
                  {stringToUpperCase(salesInvoiceData?.buyer?.city)},{" "}
                  {stringToUpperCase(salesInvoiceData?.buyer?.country)}
                </Text>
              </View>
            </View>
          </View>

          {/* 8 | 9 */}
          <View
            style={[
              styles.sideBySideContainer,
              {
                flexDirection: "row",
                height: 35,
                borderTopWidth: 1,
                borderTopColor: "black",
                borderTopStyle: "solid",
              },
            ]}
          >
            {/* Left Section */}
            <View
              style={[
                styles.leftSection,
                {
                  borderLeftWidth: 0,
                  flex: 1,
                  borderRightWidth: 1,
                  justifyContent: "center",

                  paddingLeft: 5,
                },
              ]}
            >
              <Text
                style={{
                  fontWeight: "medium",
                  fontSize: 8,
                  marginTop: -9,
                  alignItems: "flex-start",
                }}
              >
                8. Distinguish marks
              </Text>

              <Text
                style={{
                  fontWeight: "heavy",
                  fontSize: 9,
                  textAlign: "center",
                }}
              >
                AS PER INVOICE
              </Text>
            </View>
            {/* Right Section */}
            <View
              style={[
                styles.rightSection,
                {
                  borderLeftWidth: 0,
                  flex: 1,
                  borderRightWidth: 0,
                  justifyContent: "center",
                  paddingLeft: 5,
                  flexDirection: "row",
                },
              ]}
            >
              <View style={{ flex: 1 }}>
                <Text
                  style={{
                    fontWeight: "medium",
                    fontSize: 8,
                    marginTop: -9,
                    alignItems: "flex-start",
                  }}
                >
                  9. Number & description of packages
                </Text>

                <Text
                  style={{
                    fontWeight: "heavy",
                    fontSize: 9,
                    textAlign: "center",
                  }}
                >
                  {salesInvoiceData?.no_of_bags || ""}{" "}
                  {stringToUpperCase(salesInvoiceData?.packing_type?.name)}
                </Text>
              </View>
            </View>
          </View>

          {/* 10 | 11 */}
          <View
            style={[
              styles.sideBySideContainer,
              {
                flexDirection: "row",
                height: 45,
                borderTopWidth: 1,
                borderTopColor: "black",
                borderTopStyle: "solid",
              },
            ]}
          >
            {/* Left Section */}
            <View
              style={[
                styles.leftSection,
                {
                  borderLeftWidth: 0,
                  flex: 1,
                  borderRightWidth: 1,
                  justifyContent: "center",

                  paddingLeft: 5,
                },
              ]}
            >
              <Text
                style={{
                  fontWeight: "medium",
                  fontSize: 8,
                  marginTop: -9,
                  alignItems: "flex-start",
                }}
              >
                10. Name of produce / Botanical name of plants
              </Text>

              <Text
                style={{
                  fontWeight: "heavy",
                  fontSize: 10,
                }}
              >
                {stringToUpperCase(salesInvoiceData?.product?.name)}
              </Text>

              {/* <Text
                style={{
                  fontWeight: "medium",
                  fontSize: 9,
                  paddingLeft: 120,
                }}
              >
                (Oryza sativa)
              </Text> */}
            </View>
            {/* Right Section */}
            <View
              style={[
                styles.rightSection,
                {
                  borderLeftWidth: 0,
                  flex: 1,
                  borderRightWidth: 0,
                  justifyContent: "center",
                  paddingLeft: 5,
                  flexDirection: "row",
                },
              ]}
            >
              <View style={{ flex: 1 }}>
                <Text
                  style={{
                    fontWeight: "medium",
                    fontSize: 8,
                    marginTop: -9,
                    alignItems: "flex-start",
                  }}
                >
                  11. Quantity declared
                </Text>

                <Text
                  style={{
                    fontWeight: "heavy",
                    fontSize: 9,
                    textAlign: "center",
                  }}
                >
                  {formatAmount(salesInvoiceData?.quantity) || ""}{" "}
                  {stringToUpperCase(salesInvoiceData?.quantity_unit?.name)}
                </Text>
              </View>
            </View>
          </View>

          {/* Description Section */}
          <View
            style={[
              styles.sideBySideContainer,
              {
                flexDirection: "row",
                height: 45,
                borderTopWidth: 1,
                borderTopColor: "black",
                borderTopStyle: "solid",
              },
            ]}
          >
            <View
              style={[
                styles.leftSection,
                {
                  borderLeftWidth: 0,
                  flex: 1,
                  borderRightWidth: 0,
                  justifyContent: "center",

                  paddingLeft: 5,
                },
              ]}
            >
              <Text
                style={{
                  fontWeight: "medium",
                  fontSize: 8,
                  marginBottom: 30,
                  justifyContent: "space-around",
                  alignItems: "flex-start",
                }}
              >
                This is to certify that the plants or plant products described
                above have been inspected according to appropriate procedures
                and are considered to be free from quarantine pests and
                practically free from other injurious pests and they are
                considered to conform with the current phytosanitary regulations
                of the importing country
              </Text>
            </View>
          </View>

          {/* Treatment Section */}
          <View
            style={[
              styles.sideBySideContainer,
              {
                flexDirection: "row",
                height: 20,
                borderTopWidth: 1,
                borderTopColor: "black",
                borderTopStyle: "solid",
              },
            ]}
          >
            <View
              style={[
                styles.leftSection,
                {
                  borderLeftWidth: 0,
                  flex: 1,
                  borderRightWidth: 0,
                  justifyContent: "center",

                  paddingLeft: 5,
                },
              ]}
            >
              <Text
                style={{
                  fontWeight: "bold",
                  fontSize: 7,
                  marginBottom: 10,
                  justifyContent: "space-around",
                  textAlign: "center",
                }}
              >
                DISINFESTATION AND / ORDISINFECTION TREATMENT
              </Text>
            </View>
          </View>

          {/* 12 | 13 */}
          <View
            style={[
              styles.sideBySideContainer,
              {
                flexDirection: "row",
                height: 33,
                borderTopWidth: 1,
                borderTopColor: "black",
                borderTopStyle: "solid",
              },
            ]}
          >
            {/* Left Section */}
            <View
              style={[
                styles.leftSection,
                {
                  borderLeftWidth: 0,
                  flex: 1,
                  borderRightWidth: 1,
                  justifyContent: "center",

                  paddingLeft: 5,
                },
              ]}
            >
              <Text
                style={{
                  fontWeight: "medium",
                  fontSize: 8,
                  marginTop: -9,
                  alignItems: "flex-start",
                }}
              >
                12. Date:
              </Text>

              <Text
                style={{
                  fontWeight: "heavy",
                  fontSize: 9,
                }}
              >
                NIL
              </Text>
            </View>
            {/* Right Section */}
            <View
              style={[
                styles.rightSection,
                {
                  borderLeftWidth: 0,
                  flex: 1,
                  borderRightWidth: 0,
                  justifyContent: "center",
                  paddingLeft: 5,
                  flexDirection: "row",
                },
              ]}
            >
              <View style={{ flex: 1 }}>
                <Text
                  style={{
                    fontWeight: "medium",
                    fontSize: 8,
                    marginTop: -9,
                    alignItems: "flex-start",
                  }}
                >
                  13. Treatment:
                </Text>

                <Text
                  style={{
                    fontWeight: "heavy",
                    fontSize: 9,
                  }}
                >
                  NIL
                </Text>
              </View>
            </View>
          </View>

          {/* 14 | 15 */}
          <View
            style={[
              styles.sideBySideContainer,
              {
                flexDirection: "row",
                height: 33,
                borderTopWidth: 1,
                borderTopColor: "black",
                borderTopStyle: "solid",
              },
            ]}
          >
            {/* Left Section */}
            <View
              style={[
                styles.leftSection,
                {
                  borderLeftWidth: 0,
                  flex: 1,
                  borderRightWidth: 1,
                  justifyContent: "center",

                  paddingLeft: 5,
                },
              ]}
            >
              <Text
                style={{
                  fontWeight: "medium",
                  fontSize: 8,
                  marginTop: -9,
                  alignItems: "flex-start",
                }}
              >
                14. Chemical (Active ingredients):
              </Text>

              <Text
                style={{
                  fontWeight: "heavy",
                  fontSize: 9,
                }}
              >
                NIL
              </Text>
            </View>
            {/* Right Section */}
            <View
              style={[
                styles.rightSection,
                {
                  borderLeftWidth: 0,
                  flex: 1,
                  borderRightWidth: 0,
                  justifyContent: "center",
                  paddingLeft: 5,
                  flexDirection: "row",
                },
              ]}
            >
              <View style={{ flex: 1 }}>
                <Text
                  style={{
                    fontWeight: "medium",
                    fontSize: 8,
                    marginTop: -9,
                    alignItems: "flex-start",
                  }}
                >
                  15. Duration & Temperature
                </Text>

                <Text
                  style={{
                    fontWeight: "heavy",
                    fontSize: 9,
                  }}
                >
                  NIL
                </Text>
              </View>
            </View>
          </View>

          {/* 16 | 17 */}
          <View
            style={[
              styles.sideBySideContainer,
              {
                flexDirection: "row",
                height: 33,
                borderTopWidth: 1,
                borderTopColor: "black",
                borderTopStyle: "solid",
              },
            ]}
          >
            {/* Left Section */}
            <View
              style={[
                styles.leftSection,
                {
                  borderLeftWidth: 0,
                  flex: 1,
                  borderRightWidth: 1,
                  justifyContent: "center",

                  paddingLeft: 5,
                },
              ]}
            >
              <Text
                style={{
                  fontWeight: "medium",
                  fontSize: 8,
                  marginTop: -9,
                  alignItems: "flex-start",
                }}
              >
                16. Concentration.
              </Text>

              <Text
                style={{
                  fontWeight: "heavy",
                  fontSize: 9,
                }}
              >
                NIL
              </Text>
            </View>
            {/* Right Section */}
            <View
              style={[
                styles.rightSection,
                {
                  borderLeftWidth: 0,
                  flex: 1,
                  borderRightWidth: 0,
                  justifyContent: "center",
                  paddingLeft: 5,
                  flexDirection: "row",
                },
              ]}
            >
              <View style={{ flex: 1 }}>
                <Text
                  style={{
                    fontWeight: "medium",
                    fontSize: 8,
                    marginTop: -9,
                    alignItems: "flex-start",
                  }}
                >
                  17. Additional information:
                </Text>

                <Text
                  style={{
                    fontWeight: "heavy",
                    fontSize: 9,
                  }}
                >
                  NIL
                </Text>
              </View>
            </View>
          </View>

          {/* 18 */}
          <View
            style={[
              styles.sideBySideContainer,
              {
                borderBottom: 1,
                flexDirection: "row",
                height: 45,
                borderTopWidth: 1,
                borderTopColor: "black",
                borderTopStyle: "solid",
              },
            ]}
          >
            <View
              style={[
                styles.leftSection,
                {
                  borderLeftWidth: 0,
                  flex: 1,
                  justifyContent: "center",
                  paddingLeft: 5,
                },
              ]}
            >
              <Text
                style={{
                  fontWeight: "medium",
                  fontSize: 8,
                  marginTop: -9,
                  alignItems: "flex-start",
                }}
              >
                18. Additional Declaration:
              </Text>

              <Text
                style={{
                  fontWeight: "bold",
                  fontSize: 9,
                }}
              >
                Invoice No - {stringToUpperCase(salesInvoiceData?.invoice_no)}{" "}
                Invoice Date -{" "}
                {formatDate(salesInvoiceData?.invoice_date) || ""}
              </Text>
            </View>
          </View>

          {/* 19 | 20 | 21 | Stamp Section | Signature & Authorised Section  */}
          <View
            style={[
              styles.sideBySideContainer,
              {
                height: 100,
                flexDirection: "row",
              },
            ]}
          >
            <View
              style={[
                styles.rightSection,
                {
                  borderRight: 1,
                  paddingHorizontal: 10,
                  paddingVertical: 5,
                },
              ]}
            >
              {/* Top Section */}
              <Text
                style={[
                  styles.mediumText,
                  {
                    borderBottomWidth: 1,
                    width: 123,
                    marginBottom: 10,
                    height: 20,
                    fontSize: 8,
                    marginLeft: -10,
                    paddingLeft: 5,
                  },
                ]}
              >
                19. Date:
              </Text>
              {/* Middle Section */}
              <Text
                style={{
                  fontWeight: "medium",
                  borderTopWidth: 0,
                  width: "100%",
                  marginTop: 0,
                  paddingLeft: 5,
                  fontSize: 8,
                  marginLeft: -10,
                }}
              >
                20. Place of Issue
              </Text>
              <Text
                style={[
                  {
                    paddingLeft: 5,
                    fontWeight: "heavy",
                    borderTopWidth: 0,
                    width: "100%",
                    marginBottom: 0,
                    fontSize: 9,
                    marginLeft: -10,
                  },
                ]}
              >
                {stringToUpperCase(staticData.place_of_issue)}
              </Text>
              <Text
                style={[
                  {
                    width: 123,
                    paddingLeft: 5,
                    borderTopWidth: 1,
                    marginTop: 15,
                    marginLeft: -10,
                  },
                ]}
              ></Text>
              {/* Bottom Section */}
              <Text
                style={[
                  {
                    width: 123,
                    paddingLeft: 5,
                    height: 20,
                    fontSize: 8,
                    marginTop: 2,
                    marginLeft: -10,
                  },
                ]}
              >
                21. Code No.
              </Text>
            </View>

            {/* Stamp Section */}
            <View
              style={[
                styles.centerSection,
                {
                  paddingTop: 85,
                  justifyContent: "flex-start",
                  paddingHorizontal: 10,
                  borderRightWidth: 1,
                  borderRightColor: "black",
                  borderRightStyle: "solid",
                },
              ]}
            >
              <Text style={[{ borderBottom: 0, fontSize: 10 }]}>
                Stamp of organisation
              </Text>
            </View>

            {/* Signature & Authorised Section */}
            <View
              style={[
                styles.rightSection,
                {
                  paddingHorizontal: 16,
                  paddingVertical: 14,
                },
              ]}
            >
              <Text
                style={{
                  fontWeight: "light",
                  fontSize: 8,
                  width: "100%",
                  marginBottom: 8,
                }}
              >
                Name of Authorised Officer:
              </Text>
              <Text
                style={{
                  fontWeight: "light",
                  fontSize: 8,
                  width: "100%",
                  marginBottom: 5,
                }}
              >
                {/*name */}
              </Text>
              <Text
                style={{
                  fontWeight: "light",
                  fontSize: 8,
                  width: "100%",
                  marginTop: 5,
                  textAlign: "left",
                  justifyContent: "space-around",
                }}
              >
                Signature & Stamp of authorised officer
              </Text>
              <Text
                style={{
                  fontWeight: "light",
                  fontSize: 8,
                  width: "100%",
                  marginTop: 8,
                  textAlign: "left",
                }}
              >
                {/* sign*/}
              </Text>
            </View>
          </View>
        </View>
        {/* Footer Section */}
        <Text
          style={[
            {
              justifyContent: "space-around",
              paddingLeft: 10,
              fontWeight: "medium",
              fontSize: 9,
            },
          ]}
        >
          No financial liability with respect of this certificate shall attach
          to the Ministry of Agriculture (Department of Agriculture
          cooperation),
        </Text>
        <Text
          style={[
            {
              justifyContent: "space-around",
              paddingLeft: 10,
              fontWeight: "medium",
              fontSize: 9,
            },
          ]}
        >
          Government of India Or to any officers of its officers or
          representative.
        </Text>
      </Page>
    </Document>
  );
};

export default PhytoSanitaryCertificate;
