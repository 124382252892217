import { Link } from "react-router-dom";

interface PaginationProps {
  page: number;
  perPage: number;
  totalRecords: number;
  totalPages: number;
  records: any;
  prevButton: () => void;
  nextButton: () => void;
}

const Pagination: React.FC<PaginationProps> = ({
  page,
  perPage,
  totalRecords,
  totalPages,
  records,
  prevButton,
  nextButton,
}) => {
  return (
    <div className="card-body p-3">
      <div className="row">
        <div
          className="col-lg-6 d-flex align-items-center"
          style={{ color: "#acaab1" }}
        >
          Showing {records?.length ? (page - 1) * perPage + 1 : 0} to{" "}
          {(page - 1) * perPage + records?.length} of {totalRecords} entries
        </div>
        <div className="col-lg-6">
          <nav aria-label="Page navigation">
            <ul className="pagination p-0 m-0 d-flex justify-content-end">
              <li
                className={`page-item prev ${page <= 1 ? "disabled" : ""} ${
                  page > 1 ? "active" : ""
                }`}
                onClick={prevButton}
              >
                <Link className="page-link" to="#">
                  <i className="ti ti-chevron-left ti-sm"></i>
                </Link>
              </li>
              <li className={`page-item`}>
                <Link className="page-link" to="#">
                  {page}
                </Link>
              </li>
              <li
                className={`page-item next ${
                  totalPages === page ? "disabled" : ""
                } ${totalPages > page ? "active" : ""}`}
                onClick={nextButton}
              >
                <Link className="page-link" to="#">
                  <i className="ti ti-chevron-right ti-sm"></i>
                </Link>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </div>
  );
};

export default Pagination;
