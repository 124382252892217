/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import {
  getAllSealNumberNameListApi,
  getSealNumberListApi,
} from "../../services/_sealNumber";
import { Link } from "react-router-dom";
import moment from "moment";
import Pagination from "../../components/Pagination";
import { getPermissions } from "../../context/AuthContext";
import { ADMIN_ID } from "../../components/Constants";
import Spinner from "../../theme/Spinner";
import { Autocomplete, TextField } from "@mui/material";
import {
  getAllSalesContractApi,
  getAllSalesInvoiceApi,
} from "../../services/_sales";

const SealNumberList = () => {
  const permissions = getPermissions();
  const [records, setRecords] = useState([]);
  const [sealNumberNameList, setSealNumberNameList] = useState([]);
  const [salesContractList, setSalesContractList] = useState([]);
  const [salesInvoiceList, setSalesInvoiceList] = useState([]);
  const [filterContractId, setFilterContractId] = useState<string>("");
  const [filterInvoiceId, setFilterInvoiceId] = useState<string>("");
  const [page, setPage] = useState<number>(1);
  const [perPage] = useState<number>(10);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [totalRecords, setTotalRecords] = useState<number>(0);
  const [applyFilter, setApplyFilter] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [filterSealNo, setFilterSealNo] = useState<string>("");
  const [sortKey, setSortKey] = useState<string>("");
  const [sortType, setSortType] = useState<string>("");

  const getSealNumberList = async (
    page: number,
    perPage: number,
    isFilter?: boolean,
    sortKey?: string,
    sortType?: string
  ) => {
    setLoading(true);
    let query = `page=${page}&per_page=${perPage}`;
    if (isFilter) {
      if (filterSealNo) {
        query += `&seal_no=${filterSealNo}`;
      }
      if (filterContractId) {
        query += `&sales_contract_id=${filterContractId}`;
      }
      if (filterInvoiceId) {
        query += `&sales_invoice_id=${filterInvoiceId}`;
      }
      if (sortKey && sortType) {
        query += `&sort_key=${sortKey}&sort_type=${sortType}`;
      }
    }
    const result: any = await getSealNumberListApi(query);
    if (result.isSuccess) {
      const totalPagesCount = Math.ceil(result.data.total_records / perPage);
      setTotalRecords(result.data.total_records);
      setTotalPages(totalPagesCount);
      setRecords(result.data.records);
    }
    setLoading(false);
  };

  const getSealNumberNameList = async () => {
    try {
      const result: any = await getAllSealNumberNameListApi();
      if (result.isSuccess) {
        setSealNumberNameList(result?.data);
      }
    } catch (error) {
      console.error("Error fetching Seal Name list", error);
    }
  };
  const getSalesContractList = async () => {
    try {
      const sales: any = await getAllSalesContractApi();
      if (sales.isSuccess) {
        setSalesContractList(sales.data);
      }
    } catch (error) {
      console.error("Error fetching SaleOrder list", error);
    }
  };
  const getSalesOrderList = async (query?: string) => {
    try {
      const result: any = await getAllSalesInvoiceApi(query && query);
      if (result?.isSuccess) {
        setSalesInvoiceList(result?.data);
      }
    } catch (error) {
      console.error("Error fetching SaleOrder list", error);
    }
  };
  const handleChangeSalesContract = async (contract_id: string) => {
    let query = `sales_contract_id=${contract_id}`;
    getSalesOrderList(query);
  };

  const filterList = async () => {
    setApplyFilter(true);
    getSealNumberList(page, perPage, true);
  };

  const resetList = async () => {
    setPage(1);
    setApplyFilter(false);
    setFilterSealNo("");
    setFilterContractId("");
    setFilterInvoiceId("");
    getSealNumberList(page, perPage);
    getSealNumberNameList();
    getSalesContractList();
    getSalesOrderList();
  };

  const prevButton = async () => {
    if (page > 1) {
      setPage(page - 1);
    }
  };

  const nextButton = async () => {
    if (totalPages > page) {
      setPage(page + 1);
    }
  };

  const sortTable = async (key: string) => {
    let type = sortType === "asc" ? "desc" : "asc";
    if (sortKey !== key) {
      type = "asc";
    }
    setSortKey(key);
    setSortType(type);
    await getSealNumberList(page, perPage, true, key, type);
  };

  useEffect(() => {
    if (page && perPage) {
      getSealNumberList(page, perPage, applyFilter);
    }
  }, [page, perPage, applyFilter]);

  useEffect(() => {
    getSealNumberNameList();
    getSalesContractList();
    getSalesOrderList();
  }, []);

  return (
    <>
      <div className="card">
        <div className="card-header pb-0 d-flex justify-content-between">
          <div>
            <h5>Seal Number List</h5>
          </div>
          {permissions.includes("create-seal-number") && (
            <div>
              <Link
                className="btn btn-primary waves-effect waves-light"
                type="button"
                to="/create-seal-number"
              >
                <span>
                  <i className="ti ti-plus me-sm-1"></i>{" "}
                  <span className="d-none d-sm-inline-block">
                    Add Seal Number
                  </span>
                </span>
              </Link>
            </div>
          )}
        </div>
        <div className="card-body">
          <form className="dt_adv_search">
            <div className="row">
              <div className="col-12">
                <div className="row g-3">
                  <div className="col-12 col-sm-6 col-lg-3">
                    <label
                      className="form-label"
                      style={{ fontSize: "0.9375rem" }}
                    >
                      Seal No:
                    </label>
                    <Autocomplete
                      disablePortal
                      options={sealNumberNameList.map(
                        (suggestion: any) => suggestion.seal_no
                      )}
                      onChange={(e, newValue) => {
                        setFilterSealNo(newValue);
                      }}
                      value={filterSealNo}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          className="form-control"
                          type="text"
                          placeholder="Enter Seal No"
                          value={filterSealNo}
                          onChange={(e) => setFilterSealNo(e.target.value)}
                        />
                      )}
                    />
                  </div>
                  <div className="col-12 col-sm-6 col-lg-3">
                    <label
                      className="form-label"
                      style={{ fontSize: "0.9375rem" }}
                    >
                      Contract No:
                    </label>
                    <Autocomplete
                      disablePortal
                      options={salesContractList}
                      getOptionLabel={(option: any) => option.contract_no || ""}
                      onChange={(e, newValue: any) => {
                        setFilterContractId(newValue?.id || "");
                        setFilterInvoiceId("");
                        handleChangeSalesContract(newValue?.id);
                      }}
                      value={
                        salesContractList.find(
                          (item: any) => item.id === filterContractId
                        ) || null
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          className="form-control"
                          type="text"
                          placeholder="Enter Contract No"
                          onChange={(e) => setFilterContractId(e.target.value)}
                        />
                      )}
                    />
                  </div>
                  <div className="col-12 col-sm-6 col-lg-3">
                    <label
                      className="form-label"
                      style={{ fontSize: "0.9375rem" }}
                    >
                      Invoice No:
                    </label>
                    <Autocomplete
                      disablePortal
                      options={salesInvoiceList}
                      getOptionLabel={(option: any) => option.invoice_no || ""}
                      onChange={(e, newValue: any) => {
                        setFilterInvoiceId(newValue?.id || "");
                      }}
                      value={
                        salesInvoiceList.find(
                          (item: any) => item.id === filterInvoiceId
                        ) || null
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          className="form-control"
                          type="text"
                          placeholder="Enter Invoice No"
                          value={filterInvoiceId}
                          onChange={(e) => setFilterInvoiceId(e.target.value)}
                        />
                      )}
                    />
                  </div>
                  <div className="col-12">
                    <button
                      type="button"
                      className="btn btn-primary"
                      onClick={filterList}
                    >
                      Filter
                    </button>
                    <button
                      type="button"
                      className="btn btn-label-github ms-5"
                      onClick={resetList}
                    >
                      Reset
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
        <div className="card-datatable table-responsive">
          {loading && <Spinner isFullScreen={false} />}
          <table className="dt-advanced-search table table-nowrap">
            <thead>
              <tr>
                <th>#</th>
                <th
                  className="cursor-pointer"
                  onClick={() => sortTable("seal_no")}
                >
                  Seal No
                  <span>
                    <i className="ti ti-arrows-down-up sorting-icon"></i>
                  </span>
                </th>
                <th
                  className="cursor-pointer"
                  onClick={() => sortTable("sales_contract_no")}
                >
                  Sales Contract No
                  <span>
                    <i className="ti ti-arrows-down-up sorting-icon"></i>
                  </span>
                </th>
                <th
                  className="cursor-pointer"
                  onClick={() => sortTable("sales_invoice_no")}
                >
                  Sales Invoice No
                  <span>
                    <i className="ti ti-arrows-down-up sorting-icon"></i>
                  </span>
                </th>
                <th
                  className="cursor-pointer"
                  onClick={() => sortTable("created_at")}
                >
                  Created At
                  <span>
                    <i className="ti ti-arrows-down-up sorting-icon"></i>
                  </span>
                </th>
                {permissions.includes("update-seal-number") && <th>Action</th>}
              </tr>
            </thead>
            <tbody>
              {records.length > 0 ? (
                records.map((item: any, i: number) => (
                  <tr key={item.id}>
                    <td>{(page - 1) * perPage + 1 + i}</td>
                    <td>{item.seal_no}</td>
                    <td>
                      {item?.sales_invoice_container?.sales_invoice
                        ?.sales_contract.contract_no || "-"}
                    </td>
                    <td>
                      {item?.sales_invoice_container?.sales_invoice
                        ?.invoice_no || "-"}
                    </td>
                    <td>
                      {moment(item.created_at).format("YYYY-MM-DD HH:mm:ss")}
                    </td>
                    {permissions.includes("update-seal-number") && (
                      <td>
                        {ADMIN_ID !== item.id && (
                          <>
                            <Link
                              to={`/edit-seal-number/${item.id}`}
                              className="btn btn-sm btn-text-secondary rounded-pill btn-icon"
                              title="Edit Seal Number"
                            >
                              <i className="ti ti-pencil ti-md"></i>
                            </Link>
                          </>
                        )}
                      </td>
                    )}
                  </tr>
                ))
              ) : (
                <tr>
                  <td
                    colSpan={permissions.includes("update-seal-number") ? 7 : 5}
                    className="text-center"
                  >
                    No records found
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        <Pagination
          page={page}
          perPage={perPage}
          totalRecords={totalRecords}
          totalPages={totalPages}
          records={records}
          prevButton={prevButton}
          nextButton={nextButton}
        />
      </div>
    </>
  );
};

export default SealNumberList;
