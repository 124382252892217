import { ErrorMessage, Field, FormikErrors, FormikValues } from "formik";

interface PackingFieldsProps {
  unitList: any;
  packingType: any;
  values: FormikValues;
  handleChange: (e: React.ChangeEvent<any>) => void;
  setAddUnitModalStatus: (show: boolean) => void;
  setAddPackingTypeModalStatus: (show: boolean) => void;
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean
  ) => Promise<void | FormikErrors<FormikValues>>;
  calculateWeight: any;
}

const PackingFields: React.FC<PackingFieldsProps> = ({
  unitList,
  packingType,
  values,
  handleChange,
  setAddUnitModalStatus,
  setAddPackingTypeModalStatus,
  setFieldValue,
  calculateWeight,
}) => {
  return (
    <div className="card my-card">
      <div className="card-header my-card-header bg-lighter">
        <div>
          <h6 className="my-card-header-head">Packing Details</h6>
        </div>
      </div>
      <div className="card-body my-card-body">
        <div className="row g-3 m-0">
          <div className="col-12 col-sm-6 col-md-4 col-lg-3">
            <label
              htmlFor="container_size"
              className="form-label"
              style={{ fontSize: "0.9375rem" }}
            >
              Container Size:{" "}
            </label>
            <Field
              id="container_size"
              name="container_size"
              type="text"
              className="form-control"
              placeholder="Enter Container Size"
              value={values.container_size}
              onChange={handleChange}
            />
          </div>
          <div className="col-12 col-sm-6 col-md-4 col-lg-3">
            <label
              htmlFor="no_of_bags"
              className="form-label"
              style={{ fontSize: "0.9375rem" }}
            >
              No of Bags:
            </label>
            <Field
              id="no_of_bags"
              name="no_of_bags"
              type="number"
              className="form-control"
              placeholder="Enter No of Bags"
              value={values?.no_of_bags || undefined}
              onChange={(e: any) => {
                handleChange(e);
                values.no_of_bags = e.target.value;
                calculateWeight(values, setFieldValue);
              }}
            />
            <ErrorMessage
              name="no_of_bags"
              className="text-danger"
              component="div"
            />
          </div>
          <div className="col-12 col-sm-6 col-md-4 col-lg-3">
            <div className="d-flex justify-content-between">
              <div>
                <label
                  htmlFor="packing_type_id"
                  className="form-label"
                  style={{ fontSize: "0.9375rem" }}
                >
                  Packing Type:
                </label>
              </div>
              <div>
                <button
                  title="Add Packing Type"
                  className="btn btn-sm btn-primary waves-effect waves-light p-0"
                  type="button"
                  onClick={() => setAddPackingTypeModalStatus(true)}
                >
                  <span>
                    <i className="ti ti-plus my-card-icon-button"></i>
                  </span>
                </button>
              </div>
            </div>
            <select
              id="packing_type_id"
              name="packing_type_id"
              className="form-select"
              value={values.packing_type_id}
              onChange={handleChange}
            >
              <option defaultChecked value={""}>
                Select Packing Type
              </option>
              {packingType.map((packing: any) => (
                <option key={packing.id} value={packing.id}>
                  {packing.name}
                </option>
              ))}
            </select>
            <ErrorMessage
              name="packing_type_id"
              className="text-danger"
              component="div"
            />
          </div>
          <div className="col-12 col-sm-6 col-md-4 col-lg-3">
            <label
              htmlFor="packing_net_weight"
              className="form-label"
              style={{ fontSize: "0.9375rem" }}
            >
              Packing Net Weight:
            </label>
            <Field
              id="packing_net_weight"
              name="packing_net_weight"
              type="number"
              className="form-control"
              placeholder="Enter Packing Net Weight"
              value={values?.packing_net_weight || undefined}
              onChange={(e: any) => {
                handleChange(e);
                values.packing_net_weight = e.target.value;
                calculateWeight(values, setFieldValue);
              }}
            />
            <ErrorMessage
              name="packing_net_weight"
              className="text-danger"
              component="div"
            />
          </div>
          <div className="col-12 col-sm-6 col-md-4 col-lg-3">
            <div className="d-flex justify-content-between">
              <div>
                <label
                  htmlFor="net_weight_unit_id"
                  className="form-label"
                  style={{ fontSize: "0.9375rem" }}
                >
                  Packing Net Weight Unit:
                </label>
              </div>
              <div>
                <button
                  title="Add Unit"
                  className="btn btn-sm btn-primary waves-effect waves-light p-0"
                  type="button"
                  onClick={() => setAddUnitModalStatus(true)}
                >
                  <span>
                    <i className="ti ti-plus my-card-icon-button"></i>
                  </span>
                </button>
              </div>
            </div>
            <select
              id="net_weight_unit_id"
              name="net_weight_unit_id"
              className="form-select"
              value={values.net_weight_unit_id}
              onChange={(e: any) => {
                handleChange(e);
                values.net_weight_unit_id = e.target.value;
                calculateWeight(values, setFieldValue);
              }}
            >
              <option defaultChecked value={""}>
                Select Packing Net Weight Unit
              </option>
              {unitList.map((unit: any) => (
                <option key={unit.id} value={unit.id}>
                  {unit.name}
                </option>
              ))}
            </select>
          </div>
          <div className="col-12 col-sm-6 col-md-4 col-lg-3">
            <label
              htmlFor="packing_gross_weight"
              className="form-label"
              style={{ fontSize: "0.9375rem" }}
            >
              Packing Gross Weight:
            </label>
            <Field
              id="packing_gross_weight"
              name="packing_gross_weight"
              type="number"
              className="form-control"
              placeholder="Enter Packing Gross Weight"
              value={values?.packing_gross_weight || undefined}
              onChange={(e: any) => {
                handleChange(e);
                values.packing_gross_weight = e.target.value;
                calculateWeight(values, setFieldValue);
              }}
            />
            <ErrorMessage
              name="packing_gross_weight"
              className="text-danger"
              component="div"
            />
          </div>
          <div className="col-12 col-sm-6 col-md-4 col-lg-3">
            <label
              htmlFor="gross_weight_unit_id"
              className="form-label"
              style={{ fontSize: "0.9375rem" }}
            >
              Packing Gross Weight Unit:
            </label>
            <select
              id="gross_weight_unit_id"
              name="gross_weight_unit_id"
              className="form-select"
              value={values.gross_weight_unit_id}
              onChange={(e: any) => {
                handleChange(e);
                values.gross_weight_unit_id = e.target.value;
                calculateWeight(values, setFieldValue);
              }}
            >
              <option defaultChecked value={""}>
                Select Packing Gross Weight Unit
              </option>
              {unitList.map((unit: any) => (
                <option key={unit.id} value={unit.id}>
                  {unit.name}
                </option>
              ))}
            </select>
          </div>
          <div className="col-12 col-sm-6 col-md-4 col-lg-3">
            <label
              htmlFor="total_net_weight"
              className="form-label"
              style={{ fontSize: "0.9375rem" }}
            >
              Total Net Weight (MTS):
            </label>
            <Field
              id="total_net_weight"
              name="total_net_weight"
              type="number"
              className="form-control"
              placeholder="Enter Total Net Weight (MTS)"
              value={values?.total_net_weight || undefined}
              onChange={handleChange}
            />
            <ErrorMessage
              name="total_net_weight"
              className="text-danger"
              component="div"
            />
          </div>
          <div className="col-12 col-sm-6 col-md-4 col-lg-3">
            <label
              htmlFor="total_gross_weight"
              className="form-label"
              style={{ fontSize: "0.9375rem" }}
            >
              Total Gross Weight (MTS):
            </label>
            <Field
              id="total_gross_weight"
              name="total_gross_weight"
              type="number"
              className="form-control"
              placeholder="Enter Total Gross Weight (MTS)"
              value={values?.total_gross_weight || undefined}
              onChange={handleChange}
            />
            <ErrorMessage
              name="total_gross_weight"
              className="text-danger"
              component="div"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default PackingFields;
