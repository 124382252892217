/* eslint-disable react-hooks/exhaustive-deps */
import {
  updateSalesContractApi,
  getSalesContractDetailsByIdApi,
  getSaleContractQualitySpecificationSuggestionApi,
  getSalesContractDocumentSuggestionApi,
} from "../../../services/_sales";
import {
  salesContractInitialValues,
  salesContractValidationSchema,
} from "./helpers/SalesContractHelper";
import moment from "moment";
import { Formik, Form } from "formik";
import { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import BasicFields from "./sales-contract-form/BasicFields";
import ActionFields from "./sales-contract-form/ActionFields";
import { getBuyerNameListApi } from "../../../services/_buyer";
import { getAllDocumentApi } from "../../../services/_document";
import ProductFields from "./sales-contract-form/ProductFields";
import ShipmentFields from "./sales-contract-form/ShipmentFields";
import DocumentFields from "./sales-contract-form/DocumentFields";
import { getProductNameListApi } from "../../../services/_product";
import { errorToast, successToast } from "../../../components/Toast";
import { getAllQualitySpecificationsApi } from "../../../services/_qualitySpecifications";
import QualitySpecificationFields from "./sales-contract-form/QualitySpecificationFields";
import { getAllBrokerApi } from "../../../services/_broker";
import { getAllUnitsApi } from "../../../services/_unit";
import { getAllPackingTypesApi } from "../../../services/_packingTypes";
import AddUnitModal from "../../../components/modal/AddUnit";
import AddPackingType from "../../../components/modal/AddPackingType";
import MarkingFields from "./sales-contract-form/MarkingFields";
import AddBuyerModal from "../../../components/modal/AddBuyer";
import AddBrokerModal from "../../../components/modal/AddBroker";
import AddProductModal from "../../../components/modal/AddProduct";
import AddQualitySpecificationModal from "../../../components/modal/AddQualitySpecification";
import AddDocumentModal from "../../../components/modal/AddDocument";
import BankFields from "./sales-contract-form/BankFields";
import { getAllBankListApi } from "../../../services/_bank";
import { BankType } from "../../../components/Enums";
import AddBankModal from "../../../components/modal/AddBank";

const EditSalesContract = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [buyerId, setBuyerId] = useState("");
  const [buyerList, setBuyerList] = useState([]);
  const [brokerList, setBrokerList] = useState([]);
  const [productList, setProductList] = useState([]);
  const [unitList, setUnits] = useState<any[]>([]);
  const [qualitySpecificationsList, setQualitySpecificationsList] = useState(
    []
  );
  const [qltSuggestionList, setQltSuggestionList] = useState([]);
  const [packingType, setPackingType] = useState<any[]>([]);
  const [documentList, setDocumentList] = useState([]);
  const [documentSuggestionList, setDocumentSuggestionList] = useState([]);
  const [submitLoader, setSubmitLoader] = useState<boolean>(false);
  const [initialValues, setInitialValues] = useState<any>(
    salesContractInitialValues
  );
  const [addPackingTypeModalStatus, setAddPackingTypeModalStatus] =
    useState(false);
  const [addUnitModalStatus, setAddUnitModalStatus] = useState(false);
  const [filePondFiles, setFilePondFiles] = useState([]);
  const [addBrokerModalStatus, setAddBrokerModalStatus] = useState(false);
  const [addBuyerModalStatus, setAddBuyerModalStatus] = useState(false);
  const [addProductModalStatus, setAddProductModalStatus] = useState(false);
  const [
    addQualitySpecificationModalStatus,
    setAddQualitySpecificationModalStatus,
  ] = useState(false);
  const [addDocumentModalStatus, setAddDocumentModalStatus] = useState(false);
  const [exporterBankList, setExporterBankList] = useState([]);
  const [buyerBankList, setBuyerBankList] = useState([]);
  const [addBankModal, setAddBankModal] = useState(false);
  const [bankType, setBankType] = useState<string>("");

  useEffect(() => {
    if (!id) {
      navigate("/sales-contract-list");
    }
  });

  const fetchInitialData = async () => {
    const result: any = await getSalesContractDetailsByIdApi(id as string);
    if (result.isSuccess) {
      const transformedData = {
        ...salesContractInitialValues,
        contract_no: result?.data?.contract_no || "",
        reference_no: result?.data?.reference_no || "",
        contract_date: result?.data?.contract_date
          ? new Date(result?.data?.contract_date)
          : null,
        buyer_id: result?.data?.buyer_id || "",
        broker_id: result?.data?.broker_id || "",
        status: result?.data?.status,
        product_id: result?.data?.product_id,
        currency: result?.data?.currency,
        price: result?.data?.price ? parseFloat(result?.data?.price) : 0,
        quantity: result?.data?.quantity
          ? parseFloat(result?.data?.quantity)
          : 0,
        quantity_unit_id: result?.data?.quantity_unit_id || "",
        total_amount: result?.data?.total_amount
          ? parseFloat(result?.data?.total_amount)
          : 0,
        container_load: result?.data?.container_load || "",
        packing_type_id: result?.data?.packing_type_id || "",
        packing_net_weight: result?.data?.packing_net_weight
          ? parseFloat(result?.data?.packing_net_weight)
          : 0,
        packing_gross_weight: result?.data?.packing_gross_weight
          ? parseFloat(result?.data?.packing_gross_weight)
          : 0,
        packing_unit_id: result?.data?.packing_unit_id || "",
        marking: result?.data?.marking || "",
        marking_existing_files: result?.data?.marking_files,
        marking_files: [],
        final_destination: result?.data?.final_destination || "",
        port_of_loading: result?.data?.port_of_loading || "",
        port_of_discharge: result?.data?.port_of_discharge || "",
        shipment: result?.data?.shipment || "",
        trans_shipment: result?.data?.trans_shipment || "",
        partial_shipment: result?.data?.partial_shipment || "",
        payment_terms: result?.data?.payment_terms || "",
        weight_and_quality: result?.data?.weight_and_quality || "",
        arbitration: result?.data?.arbitration || "",
        is_digital_signature: result?.data?.is_digital_signature ? 1 : 0,
        quality_specification: result?.data?.quality_specifications || [],
        document: result?.data?.documents || [],
        exporter_bank_id: result?.data?.exporter_bank_id || "",
        buyer_bank_id: result?.data?.buyer_bank_id || "",
      };
      setInitialValues(transformedData);
    } else {
      errorToast(result.message);
    }

    const qltSuggestion: any =
      await getSaleContractQualitySpecificationSuggestionApi(
        result?.data?.product_id
      );
    if (qltSuggestion.isSuccess) {
      setQltSuggestionList(qltSuggestion?.data || []);
    }

    const documentSuggestionList: any =
      await getSalesContractDocumentSuggestionApi(result?.data?.product_id);
    if (documentSuggestionList.isSuccess) {
      setDocumentSuggestionList(documentSuggestionList?.data || []);
    }

    const allBuyers: any = await getBuyerNameListApi();
    if (allBuyers.isSuccess) {
      setBuyerList(allBuyers.data);
    }
    const allBrokers: any = await getAllBrokerApi();
    if (allBrokers.isSuccess) {
      setBrokerList(allBrokers.data);
    }
    const allProducts: any = await getProductNameListApi();
    if (allProducts.isSuccess) {
      setProductList(allProducts.data);
    }
    let query =
      result?.data?.product_id && `product_id=${result?.data?.product_id}`;
    const allQualitySpecs: any = await getAllQualitySpecificationsApi(
      query && query
    );
    if (allQualitySpecs.isSuccess) {
      setQualitySpecificationsList(allQualitySpecs.data);
    }
    const allDocuments: any = await getAllDocumentApi();
    if (allDocuments.isSuccess) {
      setDocumentList(allDocuments.data);
    }
    const allUnits: any = await getAllUnitsApi();
    if (allUnits.isSuccess) {
      setUnits(allUnits.data);
    }
    const allPackingType: any = await getAllPackingTypesApi();
    if (allPackingType.isSuccess) {
      setPackingType(allPackingType.data);
    }
    const allExporterBanks: any = await getAllBankListApi(
      `type=${BankType.EXPORTER}`
    );
    if (allExporterBanks.isSuccess) {
      setExporterBankList(allExporterBanks.data);
    }

    setBuyerId(result?.data?.buyer_id || "");
  };

  useEffect(() => {
    fetchInitialData();
  }, []);

  const fetchBuyerBankDetails = async (buyerId: string) => {
    if (buyerId) {
      const allBuyerBanks: any = await getAllBankListApi(
        `type=${BankType.BUYER}&relation_id=${buyerId}`
      );
      if (allBuyerBanks.isSuccess) {
        setBuyerBankList(allBuyerBanks.data);
      }
    } else {
      setBuyerBankList([]);
    }
  };

  useEffect(() => {
    fetchBuyerBankDetails(buyerId);
  }, [buyerId]);

  const handleChangeProduct = async (product_id: string) => {
    if (product_id) {
      const allQualitySpecs: any = await getAllQualitySpecificationsApi(
        `product_id=${product_id}`
      );
      if (allQualitySpecs.isSuccess) {
        setQualitySpecificationsList(allQualitySpecs.data);
      }

      const qltSuggestion: any =
        await getSaleContractQualitySpecificationSuggestionApi(product_id);
      if (qltSuggestion.isSuccess) {
        setQltSuggestionList(qltSuggestion?.data || []);
      }

      const documentSuggestionList: any =
        await getSalesContractDocumentSuggestionApi(product_id);
      if (documentSuggestionList.isSuccess) {
        setDocumentSuggestionList(documentSuggestionList?.data || []);
      }
    } else {
      setQualitySpecificationsList([]);
      setQltSuggestionList([]);
      setDocumentSuggestionList([]);
    }
  };

  const closeAddUnitModal = () => {
    setAddUnitModalStatus(false);
  };

  const closePackingTypeModal = () => {
    setAddPackingTypeModalStatus(false);
  };

  const closeAddBuyerModal = () => {
    setAddBuyerModalStatus(false);
  };

  const closeAddBrokerModal = () => {
    setAddBrokerModalStatus(false);
  };

  const closeAddProductModal = () => {
    setAddProductModalStatus(false);
  };

  const closeAddQualitySpecificationModal = () => {
    setAddQualitySpecificationModalStatus(false);
  };

  const closeAddDocumentModal = () => {
    setAddDocumentModalStatus(false);
  };

  const openAddBankModal = (isOpen: boolean, type: string) => {
    setBankType(type);
    setAddBankModal(isOpen);
  };

  const closeAddBankModal = () => {
    setAddBankModal(false);
  };

  const calculateTotalAmount = useCallback(
    (price: any, quantity: any, setFieldValue: any) => {
      if (price && quantity) {
        const calculatedAmount = parseFloat(price) * parseFloat(quantity);
        setFieldValue("total_amount", calculatedAmount.toFixed(2));
      }
    },
    []
  );

  const handleFileChange = useCallback((fileItems: any, setFieldValue: any) => {
    const files = fileItems
      .map((fileItem: any) => {
        if (fileItem?.file) {
          return {
            fileName: fileItem.file.name,
            fileData: fileItem.getFileEncodeDataURL(), // Encodes to base64
          };
        }
        return null;
      })
      .filter(Boolean);
    setFieldValue("marking_files", files);
  }, []);

  const handleSubmit = async (values: any, { resetForm }: any) => {
    values.contract_date = moment(values.contract_date).format("YYYY-MM-DD");
    values.total_amount = parseFloat(values.total_amount);
    values.is_digital_signature = values?.is_digital_signature ? true : false;
    setSubmitLoader(true);
    const result: any = await updateSalesContractApi(id as string, values);
    if (result.isSuccess) {
      setSubmitLoader(false);
      successToast(result.message);
      resetForm({ values: initialValues });
      navigate(`/sales-contract-list`);
    } else {
      setSubmitLoader(false);
      errorToast(result.message);
    }
    return;
  };

  return (
    <>
      <div className="card">
        <div className="card-header pb-0 d-flex justify-content-between">
          <div>
            <h5>Edit Sales Contract</h5>
          </div>
        </div>
        <div className="card-body">
          <Formik
            enableReinitialize={true}
            initialValues={initialValues}
            validationSchema={salesContractValidationSchema}
            onSubmit={handleSubmit}
          >
            {({ values, handleChange, setFieldValue }) => {
              return (
                <Form className="dt_adv_search">
                  <div className="row g-3">
                    <BasicFields
                      buyerList={buyerList}
                      brokerList={brokerList}
                      setBuyerId={setBuyerId}
                      values={values}
                      setFieldValue={setFieldValue}
                      setAddBuyerModalStatus={setAddBuyerModalStatus}
                      handleChange={handleChange}
                      setAddBrokerModalStatus={setAddBrokerModalStatus}
                    />

                    <ProductFields
                      unitList={unitList}
                      productList={productList}
                      packingType={packingType}
                      values={values}
                      setFieldValue={setFieldValue}
                      handleChange={handleChange}
                      calculateTotalAmount={calculateTotalAmount}
                      setAddUnitModalStatus={setAddUnitModalStatus}
                      setAddPackingTypeModalStatus={
                        setAddPackingTypeModalStatus
                      }
                      setAddProductModalStatus={setAddProductModalStatus}
                      handleChangeProduct={handleChangeProduct}
                    />

                    <MarkingFields
                      actionType={"update"}
                      values={values}
                      handleChange={handleChange}
                      filePondFiles={filePondFiles}
                      setFilePondFiles={setFilePondFiles}
                      handleFileChange={handleFileChange}
                      setFieldValue={setFieldValue}
                    />

                    <ShipmentFields
                      values={values}
                      setFieldValue={setFieldValue}
                      handleChange={handleChange}
                    />

                    <QualitySpecificationFields
                      values={values}
                      setAddQualitySpecificationModalStatus={
                        setAddQualitySpecificationModalStatus
                      }
                      qualitySpecificationsList={qualitySpecificationsList}
                      qltSuggestionList={qltSuggestionList}
                      setFieldValue={setFieldValue}
                    />

                    <DocumentFields
                      values={values}
                      setAddDocumentModalStatus={setAddDocumentModalStatus}
                      documentList={documentList}
                      documentSuggestionList={documentSuggestionList}
                      setFieldValue={setFieldValue}
                    />

                    <BankFields
                      exporterBankList={exporterBankList}
                      buyerBankList={buyerBankList}
                      openAddBankModal={openAddBankModal}
                      values={values}
                      handleChange={handleChange}
                    />

                    <ActionFields submitLoader={submitLoader} />
                  </div>
                </Form>
              );
            }}
          </Formik>
        </div>
      </div>
      <AddUnitModal
        addUnitModalStatus={addUnitModalStatus}
        closeAddUnitModal={closeAddUnitModal}
        onUnitCreated={fetchInitialData}
      />
      <AddPackingType
        addPackingTypeModalStatus={addPackingTypeModalStatus}
        closePackingTypeModal={closePackingTypeModal}
        onPackingTypeCreated={fetchInitialData}
      />
      <AddBuyerModal
        addBuyerModal={addBuyerModalStatus}
        closeAddBuyerModal={closeAddBuyerModal}
        onBuyerCreated={fetchInitialData}
      />
      <AddBrokerModal
        addBrokerModal={addBrokerModalStatus}
        closeAddBrokerModal={closeAddBrokerModal}
        onBrokerCreated={fetchInitialData}
      />
      <AddProductModal
        addProductModalStatus={addProductModalStatus}
        closeAddProductModal={closeAddProductModal}
        onProductCreated={fetchInitialData}
      />
      <AddQualitySpecificationModal
        addQualitySpecificationModal={addQualitySpecificationModalStatus}
        closeAddQualitySpecificationModal={closeAddQualitySpecificationModal}
        onQualitySpecificationCreated={fetchInitialData}
      />
      <AddDocumentModal
        addDocumentModal={addDocumentModalStatus}
        closeAddDocumentModal={closeAddDocumentModal}
        onDocumentCreated={fetchInitialData}
      />
      <AddBankModal
        addBankModal={addBankModal}
        closeAddBankModal={closeAddBankModal}
        onBankCreated={() => {
          fetchBuyerBankDetails(buyerId);
          fetchInitialData();
        }}
        selectedBuyerId={buyerId}
        type={bankType}
      />
    </>
  );
};

export default EditSalesContract;
