/* eslint-disable react-hooks/exhaustive-deps */
import moment from "moment";
import { CSVLink } from "react-csv";
import { Link } from "react-router-dom";
import { DateRangePicker } from "rsuite";
import Spinner from "../../theme/Spinner";
import { useEffect, useRef, useState } from "react";
import Pagination from "../../components/Pagination";
import { CURRENCY } from "../../components/Constants";
import { getAllPurchasesApi } from "../../services/_purchase";
import { getProductNameListApi } from "../../services/_product";
import { getSupplierNameListApi } from "../../services/_supplier";
import { formatAmount, formatDate } from "../../components/Helper";
import { productWisePurchaseReportApi } from "../../services/_report";
import ReactSelect from "react-select";

interface CustomCSVLink extends HTMLAnchorElement {
  link: HTMLAnchorElement;
}

const ProductWisePurchaseReport = () => {
  const [productPurchaseRecord, setProductPurchaseRecord] = useState<any[]>([]);
  const [productList, setProductList] = useState<any[]>([]);
  const [supplierList, setSupplierList] = useState<any[]>([]);
  const [purchaseOrderList, setPurchaseOrderList] = useState<any[]>([]);
  const [filteredPurchaseOrder, setFilteredPurchaseOrder] = useState<any>([]);
  const [filterProduct, setFilterProduct] = useState<string>("");
  const [filterSupplier, setFilterSupplier] = useState<string>("");
  const [filterPurchaseNo, setFilterPurchaseNo] = useState<string>("");
  const [dateRange, setDateRange] = useState(null);
  const [page, setPage] = useState<number>(1);
  const [perPage] = useState<number>(10);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [totalRecords, setTotalRecords] = useState<number>(0);
  const [applyFilter, setApplyFilter] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [displayFilter, setDisplayFilter] = useState(false);
  const [totalPrice, setTotalPrice] = useState(0);
  const [totalQuantity, setTotalQuantity] = useState(0);
  const [subTotalAmount, setSubTotalAmount] = useState(0);
  const [totalCgstAmount, setTotalCgstAmount] = useState(0);
  const [totalSgstAmount, setTotalSgstAmount] = useState(0);
  const [totalGstAmount, setTotalGstAmount] = useState(0);
  const [totalAmount, setTotalAmount] = useState(0);
  const csvLinkRef = useRef<CustomCSVLink>(null);
  const [exportData, setExportData] = useState([]);
  const [sortKey, setSortKey] = useState<string>("");
  const [sortType, setSortType] = useState<string>("");
  const productRef = useRef<any>(null);

  const toggleFilter = async () => {
    setDisplayFilter(!displayFilter);
  };

  const getProductNameList = async () => {
    try {
      const result: any = await getProductNameListApi();
      setProductList(result.data);
    } catch (error) {
      console.error("Error fetching product list", error);
    }
  };

  const getSupplierNameList = async () => {
    try {
      const result: any = await getSupplierNameListApi();
      setSupplierList(result?.data);
    } catch (error) {
      console.error("Error fetching logistic list", error);
    }
  };

  const getPurchaseOrderList = async () => {
    try {
      const result: any = await getAllPurchasesApi();
      setPurchaseOrderList(result?.data);
      setFilteredPurchaseOrder(result?.data);
    } catch (error) {
      console.error("Error fetching SaleOrder list", error);
    }
  };

  const getProductWisePurchaseList = async (
    page: number,
    perPage: number,
    isFilter?: boolean,
    sortKey?: string,
    sortType?: string
  ) => {
    setLoading(true);
    let query = `page=${page}&per_page=${perPage}`;
    if (isFilter) {
      if (filterProduct) {
        query += `&product_id=${filterProduct}`;
      }
      if (filterSupplier) {
        query += `&supplier_id=${filterSupplier}`;
      }
      if (filterPurchaseNo) {
        query += `&purchase_order_id=${filterPurchaseNo}`;
      }
      if (dateRange) {
        query += `&from_date=${moment(dateRange[0]).format("YYYY-MM-DD")}`;
        query += `&to_date=${moment(dateRange[1]).format("YYYY-MM-DD")}`;
      }
      if (sortKey && sortType) {
        query += `&sort_key=${sortKey}&sort_type=${sortType}`;
      }
    }

    const result: any = await productWisePurchaseReportApi(query);
    if (result.isSuccess) {
      const totalPagesCount = Math.ceil(result.data.total_records / perPage);
      setProductPurchaseRecord(result?.data.records);
      setTotalPages(totalPagesCount);
      setTotalRecords(result.data.total_records);
      setTotalPrice(result?.data?.total_price || 0);
      setTotalQuantity(result?.data?.total_quantity || 0);
      setSubTotalAmount(result?.data?.sub_total_amount || 0);
      setTotalCgstAmount(result?.data?.total_cgst_amount || 0);
      setTotalSgstAmount(result?.data?.total_sgst_amount || 0);
      setTotalGstAmount(result?.data?.total_gst_amount || 0);
      setTotalAmount(result?.data?.total_amount || 0);
    }
    setLoading(false);
  };

  const filterList = async () => {
    setApplyFilter(true);
    getProductWisePurchaseList(page, perPage, true);
  };

  const resetList = async () => {
    setPage(1);
    setApplyFilter(false);
    setFilterProduct("");
    if (productRef.current) {
      productRef.current.clearValue();
    }
    setFilterPurchaseNo("");
    setFilterSupplier("");
    setDateRange(null);
    setFilteredPurchaseOrder(purchaseOrderList);
    getProductWisePurchaseList(page, perPage);
  };

  const prevButton = async () => {
    if (page > 1) {
      setPage(page - 1);
    }
  };

  const nextButton = async () => {
    if (totalPages > page) {
      setPage(page + 1);
    }
  };

  const getFileName = () => {
    const filerName = "product-wise-purchase-report";
    return `${filerName}-${moment().format("MM-DD-YYYY-HH-mm-ss")}.csv`;
  };

  const exportSupplierRecords = async () => {
    setExportData([]);
    let csvHeader: any;
    let csvData: any;
    let csvFooter: any;

    let query = `export=true`;
    if (filterProduct) {
      query += `&product_id=${filterProduct}`;
    }
    if (filterSupplier) {
      query += `&supplier_id=${filterSupplier}`;
    }
    if (filterPurchaseNo) {
      query += `&purchase_order_id=${filterPurchaseNo}`;
    }
    if (dateRange) {
      query += `&from_date=${moment(dateRange[0]).format("YYYY-MM-DD")}`;
      query += `&to_date=${moment(dateRange[1]).format("YYYY-MM-DD")}`;
    }
    if (sortKey && sortType) {
      query += `&sort_key=${sortKey}&sort_type=${sortType}`;
    }

    const result: any = await productWisePurchaseReportApi(query);
    if (result.isSuccess) {
      if (result.data.records) {
        csvHeader = [
          "Sr No.",
          "Product",
          "Supplier",
          "Purchase Order No",
          "Price",
          "Quantity",
          "Unit",
          "Sub Total",
          "GST (%)",
          "CGST",
          "SGST",
          "Total GST Amount",
          "Total Amount",
          "Purchase Date",
        ];
        csvData = result.data.records.map((item: any, index: number) => [
          (index + 1).toString(),
          item?.product?.name || "",
          item?.purchase?.supplier?.name || "",
          item?.purchase?.invoice_no || "",
          `${CURRENCY.INR.SYMBOL}${formatAmount(item.price)}`,
          Number(item.quantity),
          item?.unit?.name || "",
          `${CURRENCY.INR.SYMBOL}${formatAmount(item.sub_total)}`,
          `${Number(item.gst_percentage)}%`,
          `${CURRENCY.INR.SYMBOL}${formatAmount(item.cgst)}`,
          `${CURRENCY.INR.SYMBOL}${formatAmount(item.sgst)}`,
          `${CURRENCY.INR.SYMBOL}${formatAmount(item.gst_amount)}`,
          `${CURRENCY.INR.SYMBOL}${formatAmount(item.total)}`,
          formatDate(item?.purchase?.purchase_date),
        ]);
        csvFooter = [
          "",
          "",
          "",
          "",
          `${CURRENCY.INR.SYMBOL}${formatAmount(totalPrice)}`,
          Number(totalQuantity),
          "",
          `${CURRENCY.INR.SYMBOL}${formatAmount(subTotalAmount)}`,
          "",
          `${CURRENCY.INR.SYMBOL}${formatAmount(totalCgstAmount)}`,
          `${CURRENCY.INR.SYMBOL}${formatAmount(totalSgstAmount)}`,
          `${CURRENCY.INR.SYMBOL}${formatAmount(totalGstAmount)}`,
          `${CURRENCY.INR.SYMBOL}${formatAmount(totalAmount)}`,
          "",
        ];

        const exportData: any = [csvHeader, ...csvData, csvFooter];
        setExportData(exportData);

        if (csvLinkRef.current?.link) {
          setTimeout(() => {
            csvLinkRef.current!.link.click();
          }, 0);
        }
      }
    }
  };

  const sortTable = async (key: string) => {
    let type = sortType === "asc" ? "desc" : "asc";
    if (sortKey !== key) {
      type = "asc";
    }
    setSortKey(key);
    setSortType(type);
    await getProductWisePurchaseList(page, perPage, true, key, type);
  };

  const filterSuggestions = (e: any) => {
    const name = e.target.name;
    const value = e.target.value;

    if (name === "supplier_id") {
      const data = value
        ? purchaseOrderList.filter((item: any) => item.supplier_id === value)
        : purchaseOrderList;
      setFilterPurchaseNo("");
      setFilteredPurchaseOrder(data);
    }
  };

  useEffect(() => {
    getProductNameList();
    getSupplierNameList();
    getPurchaseOrderList();
  }, []);

  useEffect(() => {
    if (page && perPage) {
      getProductWisePurchaseList(page, perPage, applyFilter);
    }
  }, [page, perPage, applyFilter]);

  return (
    <div className="card">
      <div className="card-header pb-0 d-flex justify-content-between">
        <div>
          <h5>Product Wise Purchase Report</h5>
        </div>
        <div>
          <Link
            to="#"
            type="button"
            className="btn btn-label-github"
            style={{ marginRight: "15px" }}
            onClick={toggleFilter}
          >
            <span>
              <i className="ti ti-filter me-sm-1"></i>{" "}
              <span className="d-none d-sm-inline-block">Filter</span>
            </span>
          </Link>
          <Link
            to={"#"}
            className="btn btn-primary waves-effect waves-light"
            type="button"
            onClick={() => {
              exportSupplierRecords();
            }}
          >
            <span>
              <i className="ti ti-download me-sm-1"></i>{" "}
              <span className="d-none d-sm-inline-block">Download Excel</span>
            </span>
          </Link>
          <CSVLink
            data={exportData}
            filename={getFileName()}
            className="d-none"
            ref={csvLinkRef as any}
          >
            <span>
              <i className="ti ti-download me-sm-1"></i>{" "}
              <span className="d-none d-sm-inline-block">Download Excel</span>
            </span>
          </CSVLink>
        </div>
      </div>

      {displayFilter && (
        <div className="card-body">
          <form className="dt_adv_search">
            <div className="row">
              <div className="col-12">
                <div className="row g-3">
                  <div className="col-12 col-sm-6 col-lg-3">
                    <label
                      htmlFor="date"
                      className="form-label"
                      style={{ fontSize: "0.9375rem" }}
                    >
                      Purchase Date:
                    </label>
                    <DateRangePicker
                      format="dd-MM-yyyy"
                      className="form-control"
                      placeholder="From Date ~ To Date"
                      value={dateRange}
                      onChange={(values: any) => {
                        setDateRange(values);
                      }}
                    />
                  </div>
                  <div className="col-12 col-sm-6 col-lg-3">
                    <label
                      className="form-label"
                      style={{ fontSize: "0.9375rem" }}
                    >
                      Product:
                    </label>
                    {(() => {
                      const selectedOption = productList?.find(
                        (option: any) => option.id === filterProduct
                      );
                      const formatOptionLabel = (
                        option: any,
                        { context }: any
                      ) => {
                        if (context === "value") {
                          return option.name;
                        } else {
                          return (
                            <div>
                              <label className="form-label">
                                {option.name}
                              </label>
                              <br />
                              <span className="form-label">
                                {option.description}
                              </span>
                            </div>
                          );
                        }
                      };
                      return (
                        <ReactSelect
                          ref={productRef}
                          classNamePrefix="react-select"
                          placeholder="Select Product"
                          options={productList}
                          getOptionLabel={(option: any) => option.name}
                          formatOptionLabel={formatOptionLabel}
                          getOptionValue={(option: any) => option.id}
                          onChange={(selectedOption: any) => {
                            setFilterProduct(selectedOption?.id || "");
                          }}
                          value={selectedOption}
                          isClearable
                          isSearchable
                        />
                      );
                    })()}
                  </div>
                  <div className="col-12 col-sm-6 col-lg-3">
                    <label
                      htmlFor="supplier_id"
                      className="form-label"
                      style={{ fontSize: "0.9375rem" }}
                    >
                      Supplier:
                    </label>
                    <select
                      className="form-select"
                      name="supplier_id"
                      id="supplier_id"
                      value={filterSupplier}
                      onChange={(e) => {
                        setFilterSupplier(e.target.value);
                        filterSuggestions(e);
                      }}
                    >
                      <option value="">Select Supplier</option>
                      {supplierList.map((supplier) => (
                        <option value={supplier.id} key={supplier.id}>
                          {supplier.name}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="col-12 col-sm-6 col-lg-3">
                    <label
                      htmlFor="purchase_order"
                      className="form-label"
                      style={{ fontSize: "0.9375rem" }}
                    >
                      Purchase Order No:
                    </label>
                    <select
                      className="form-select"
                      id="purchase_order"
                      value={filterPurchaseNo}
                      onChange={(e) => {
                        setFilterPurchaseNo(e.target.value);
                      }}
                    >
                      <option value="">Select Purchase Order No</option>
                      {filteredPurchaseOrder.map((order: any) => (
                        <option value={order.id} key={order.id}>
                          {order.invoice_no}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="col-12">
                    <button
                      type="button"
                      className="btn btn-primary"
                      onClick={filterList}
                    >
                      Filter
                    </button>
                    <button
                      type="button"
                      className="btn btn-label-github ms-5"
                      onClick={resetList}
                    >
                      Reset
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      )}

      <div className="card-datatable table-responsive">
        {loading && <Spinner isFullScreen={false} />}
        <table className="dt-advanced-search table table-nowrap">
          <thead>
            <tr>
              <th>#</th>
              <th
                className="cursor-pointer"
                onClick={() => sortTable("product")}
              >
                Product
                <span>
                  <i className="ti ti-arrows-down-up sorting-icon"></i>
                </span>
              </th>
              <th
                className="cursor-pointer"
                onClick={() => sortTable("supplier")}
              >
                Supplier
                <span>
                  <i className="ti ti-arrows-down-up sorting-icon"></i>
                </span>
              </th>
              <th
                className="cursor-pointer"
                onClick={() => sortTable("invoice_no")}
              >
                Purchase Order No
                <span>
                  <i className="ti ti-arrows-down-up sorting-icon"></i>
                </span>
              </th>
              <th className="cursor-pointer" onClick={() => sortTable("price")}>
                Price
                <span>
                  <i className="ti ti-arrows-down-up sorting-icon"></i>
                </span>
              </th>
              <th
                className="cursor-pointer"
                onClick={() => sortTable("quantity")}
              >
                Quantity
                <span>
                  <i className="ti ti-arrows-down-up sorting-icon"></i>
                </span>
              </th>
              <th className="cursor-pointer" onClick={() => sortTable("unit")}>
                Unit
                <span>
                  <i className="ti ti-arrows-down-up sorting-icon"></i>
                </span>
              </th>
              <th
                className="cursor-pointer"
                onClick={() => sortTable("sub_total")}
              >
                Sub Total
                <span>
                  <i className="ti ti-arrows-down-up sorting-icon"></i>
                </span>
              </th>
              <th className="cursor-pointer" onClick={() => sortTable("gst")}>
                GST (%)
                <span>
                  <i className="ti ti-arrows-down-up sorting-icon"></i>
                </span>
              </th>
              <th className="cursor-pointer" onClick={() => sortTable("cgst")}>
                CGST
                <span>
                  <i className="ti ti-arrows-down-up sorting-icon"></i>
                </span>
              </th>
              <th className="cursor-pointer" onClick={() => sortTable("sgst")}>
                SGST
                <span>
                  <i className="ti ti-arrows-down-up sorting-icon"></i>
                </span>
              </th>
              <th
                className="cursor-pointer"
                onClick={() => sortTable("gst_amount")}
              >
                Total GST Amount
                <span>
                  <i className="ti ti-arrows-down-up sorting-icon"></i>
                </span>
              </th>
              <th className="cursor-pointer" onClick={() => sortTable("total")}>
                Total Amount
                <span>
                  <i className="ti ti-arrows-down-up sorting-icon"></i>
                </span>
              </th>
              <th
                className="cursor-pointer"
                onClick={() => sortTable("purchase_date")}
              >
                Purchase Date
                <span>
                  <i className="ti ti-arrows-down-up sorting-icon"></i>
                </span>
              </th>
            </tr>
          </thead>
          <tbody>
            {productPurchaseRecord.length > 0 ? (
              <>
                {productPurchaseRecord.map((item: any, i: number) => (
                  <tr key={item.id}>
                    <td>{i + 1}</td>
                    <td>{item?.product?.name || ""}</td>
                    <td>{item?.purchase?.supplier?.name || ""}</td>
                    <td>{item?.purchase?.invoice_no || ""}</td>
                    <td>
                      {CURRENCY.INR.SYMBOL}
                      {formatAmount(item.price)}
                    </td>
                    <td>{Number(item.quantity)}</td>
                    <td>{item?.unit?.name || ""}</td>
                    <td>
                      {CURRENCY.INR.SYMBOL}
                      {formatAmount(item.sub_total)}
                    </td>
                    <td>{Number(item.gst_percentage)}%</td>
                    <td>
                      {CURRENCY.INR.SYMBOL}
                      {formatAmount(item.cgst)}
                    </td>
                    <td>
                      {CURRENCY.INR.SYMBOL}
                      {formatAmount(item.sgst)}
                    </td>
                    <td>
                      {CURRENCY.INR.SYMBOL}
                      {formatAmount(item.gst_amount)}
                    </td>
                    <td>
                      {CURRENCY.INR.SYMBOL}
                      {formatAmount(item.total)}
                    </td>
                    <td>{formatDate(item?.purchase?.purchase_date)}</td>
                  </tr>
                ))}
                <tr key="total-amount-row" style={{ fontWeight: "bold" }}>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td>
                    {CURRENCY.INR.SYMBOL}
                    {formatAmount(totalPrice)}
                  </td>
                  <td>{Number(totalQuantity)}</td>
                  <td></td>
                  <td>
                    {CURRENCY.INR.SYMBOL}
                    {formatAmount(subTotalAmount)}
                  </td>
                  <td></td>
                  <td>
                    {CURRENCY.INR.SYMBOL}
                    {formatAmount(totalCgstAmount)}
                  </td>
                  <td>
                    {CURRENCY.INR.SYMBOL}
                    {formatAmount(totalSgstAmount)}
                  </td>
                  <td>
                    {CURRENCY.INR.SYMBOL}
                    {formatAmount(totalGstAmount)}
                  </td>
                  <td>
                    {CURRENCY.INR.SYMBOL}
                    {formatAmount(totalAmount)}
                  </td>
                  <td></td>
                </tr>
              </>
            ) : (
              <tr>
                <td colSpan={14} className="text-center">
                  No records found
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      <Pagination
        page={page}
        perPage={perPage}
        totalRecords={totalRecords}
        totalPages={totalPages}
        records={productPurchaseRecord}
        prevButton={prevButton}
        nextButton={nextButton}
      />
    </div>
  );
};

export default ProductWisePurchaseReport;
