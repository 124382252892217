import * as Yup from "yup";
import { useState } from "react";
import { createChaApi } from "../../services/_cha";
import { Link, useNavigate } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { errorToast, successToast } from "../../components/Toast";
import CountryStateCityField from "../other/CountryStateCityField";
import { getCities, getCountries, getStates } from "../../components/Helper";

const CreateCha = () => {
  const navigate = useNavigate();
  const [submitLoader, setSubmitLoader] = useState<boolean>(false);
  const countryList = getCountries();
  const [stateList, setStateList] = useState<any>([]);
  const [cityList, setCityList] = useState<any>([]);

  const handleCountryChange = (country: any) => {
    setStateList([]);
    setCityList([]);
    const allStates = getStates(country);
    setStateList(allStates);
  };
  const handleStateChange = (state: any) => {
    setCityList([]);
    const cities = getCities(state);
    setCityList(cities);
  };

  const createChaValidationSchema = Yup.object().shape({
    name: Yup.string().required("Name is required"),
    email: Yup.string().required("Email is required"),
    status: Yup.string().required("Status is required"),
  });

  const createChaInitialValues = {
    name: "",
    email: "",
    phone_no: "",
    address: "",
    country: "",
    state: "",
    city: "",
    pin_code: "",
    tan_no: "",
    pan_no: "",
    gst_no: "",
    udhyam_no: "",
    status: "",
  };

  const handleSubmit = async (values: any, { resetForm }: any) => {
    setSubmitLoader(true);
    values.status = values.status === "true" ? true : false;
    const result: any = await createChaApi(values);
    if (result.isSuccess) {
      resetForm({ values: createChaInitialValues });
      setSubmitLoader(false);
      successToast(result.message);
      navigate("/cha-list");
    } else {
      setSubmitLoader(false);
      errorToast(result.message);
    }
    return;
  };

  return (
    <>
      <div className="card">
        <div className="card-header pb-0">
          <div>
            <h5>Create CHA</h5>
          </div>
        </div>
        <div className="card-body">
          <Formik
            initialValues={createChaInitialValues}
            validationSchema={createChaValidationSchema}
            onSubmit={handleSubmit}
          >
            {({ handleChange, values, setFieldValue }) => (
              <Form className="dt_adv_search">
                <div className="row">
                  <div className="col-12">
                    <div className="row g-3">
                      <div className="col-12 col-sm-6 col-lg-4">
                        <label
                          htmlFor="name"
                          className="form-label"
                          style={{ fontSize: "0.9375rem" }}
                        >
                          Name: <span className="text-danger">*</span>
                        </label>
                        <Field
                          id="name"
                          name="name"
                          type="text"
                          className="form-control"
                          placeholder="Enter name"
                          onChange={handleChange}
                        />
                        <ErrorMessage
                          name="name"
                          className="text-danger"
                          component="div"
                        />
                      </div>
                      <div className="col-12 col-sm-6 col-lg-4">
                        <label
                          htmlFor="email"
                          className="form-label"
                          style={{ fontSize: "0.9375rem" }}
                        >
                          Email: <span className="text-danger">*</span>
                        </label>
                        <Field
                          id="email"
                          name="email"
                          type="email"
                          className="form-control"
                          placeholder="Enter email"
                          onChange={handleChange}
                        />
                        <ErrorMessage
                          name="email"
                          className="text-danger"
                          component="div"
                        />
                      </div>
                      <div className="col-12 col-sm-6 col-lg-4">
                        <label
                          htmlFor="phone_no"
                          className="form-label"
                          style={{ fontSize: "0.9375rem" }}
                        >
                          Phone No:
                        </label>
                        <Field
                          id="phone_no"
                          name="phone_no"
                          type="text"
                          className="form-control"
                          placeholder="Enter phone no"
                          onChange={handleChange}
                        />
                      </div>
                      <div className="col-12 col-sm-6 col-lg-4">
                        <label
                          htmlFor="pan_no"
                          className="form-label"
                          style={{ fontSize: "0.9375rem" }}
                        >
                          PAN No:
                        </label>
                        <Field
                          id="pan_no"
                          name="pan_no"
                          type="text"
                          className="form-control"
                          placeholder="Enter PAN no"
                          onChange={handleChange}
                        />
                      </div>
                      <div className="col-12 col-sm-6 col-lg-4">
                        <label
                          htmlFor="gst_no"
                          className="form-label"
                          style={{ fontSize: "0.9375rem" }}
                        >
                          GST No:
                        </label>
                        <Field
                          id="gst_no"
                          name="gst_no"
                          type="text"
                          className="form-control"
                          placeholder="Enter GST No"
                          onChange={handleChange}
                        />
                      </div>
                      <div className="col-12 col-sm-6 col-lg-4">
                        <label
                          htmlFor="tan_no"
                          className="form-label"
                          style={{ fontSize: "0.9375rem" }}
                        >
                          TAN No:
                        </label>
                        <Field
                          id="tan_no"
                          name="tan_no"
                          type="text"
                          className="form-control"
                          placeholder="Enter TAN no"
                          onChange={handleChange}
                        />
                      </div>
                      <div className="col-12 col-sm-6 col-lg-4">
                        <label
                          htmlFor="udhyam_no"
                          className="form-label"
                          style={{ fontSize: "0.9375rem" }}
                        >
                          UDHYAM No:
                        </label>
                        <Field
                          id="udhyam_no"
                          name="udhyam_no"
                          type="text"
                          className="form-control"
                          placeholder="Enter UDHYAM no"
                          onChange={handleChange}
                        />
                      </div>
                      <CountryStateCityField
                        countryList={countryList}
                        stateList={stateList}
                        cityList={cityList}
                        values={values}
                        setFieldValue={setFieldValue}
                        handleCountryChange={handleCountryChange}
                        handleStateChange={handleStateChange}
                      />
                      <div className="col-12 col-sm-6 col-lg-4">
                        <label
                          htmlFor="pin_code"
                          className="form-label"
                          style={{ fontSize: "0.9375rem" }}
                        >
                          Pin Code:
                        </label>
                        <Field
                          id="pin_code"
                          name="pin_code"
                          type="text"
                          className="form-control"
                          placeholder="Enter Pin Code"
                          onChange={handleChange}
                        />
                      </div>
                      <div className="col-12 col-sm-6 col-lg-4">
                        <label
                          htmlFor="status"
                          className="form-label"
                          style={{ fontSize: "0.9375rem" }}
                        >
                          Status: <span className="text-danger">*</span>
                        </label>
                        <select
                          id="status"
                          name="status"
                          className="form-select"
                          onChange={handleChange}
                        >
                          <option value="">Select Status</option>
                          <option value="true">Active</option>
                          <option value="false">Inactive</option>
                        </select>
                        <ErrorMessage
                          name="status"
                          className="text-danger"
                          component="div"
                        />
                      </div>
                      <div className="col-12">
                        <label
                          htmlFor="address"
                          className="form-label"
                          style={{ fontSize: "0.9375rem" }}
                        >
                          Address:
                        </label>
                        <Field
                          id="address"
                          name="address"
                          type="text"
                          className="form-control"
                          placeholder="Enter Address"
                          onChange={handleChange}
                          as={"textarea"}
                          rows={2}
                        />
                      </div>
                      <div className="col-12">
                        <button
                          type="submit"
                          className="btn btn-primary"
                          disabled={submitLoader}
                        >
                          {submitLoader && (
                            <span
                              className="spinner-border spinner-border-sm me-2"
                              role="status"
                              aria-hidden="true"
                            ></span>
                          )}
                          Submit
                        </button>
                        <Link
                          to={"/cha-list"}
                          type="button"
                          className="btn btn-label-github ms-5"
                        >
                          Back
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </>
  );
};

export default CreateCha;
