import * as Yup from "yup";
import React, { useState } from "react";
import { errorToast, successToast } from "../Toast";
import { Field, Form, Formik, ErrorMessage } from "formik";
import { createProductApi } from "../../services/_product";

const createProductInitialValues = {
  name: "",
  description: "",
  hsn_no: "",
  status: "",
};
interface AddProductModalProps {
  addProductModalStatus: boolean;
  closeAddProductModal: () => void;
  onProductCreated: any;
}

const createProductValidationSchema = Yup.object().shape({
  name: Yup.string().required("Name is required"),
  description: Yup.string(),
  hsn_no: Yup.string(),
  status: Yup.string().required("Status is required"),
});

const AddProductModal: React.FC<AddProductModalProps> = ({
  addProductModalStatus,
  closeAddProductModal,
  onProductCreated,
}) => {
  const [submitLoader, setSubmitLoader] = useState(false);

  const handleSubmit = async (values: any, { resetForm }: any) => {
    setSubmitLoader(true);
    values.status = values.status === "true" ? true : false;
    const result: any = await createProductApi(values);
    if (result.isSuccess) {
      setSubmitLoader(false);
      resetForm({ values: createProductInitialValues });
      successToast(result.message);
      closeAddProductModal();
      onProductCreated(result.data);
    } else {
      setSubmitLoader(false);
      errorToast(result.message);
    }
    return;
  };
  return (
    <>
      {addProductModalStatus && (
        <>
          <div className="modal-backdrop fade show"></div>
          <div
            className="modal fade show"
            id="addProductModal"
            tabIndex={-3}
            aria-hidden={!addProductModalStatus}
            style={{ display: addProductModalStatus ? "block" : "none" }}
          >
            <div className="modal-dialog modal-lg modal-simple modal-dialog-centered">
              <div className="modal-content">
                <div className="modal-body p-4">
                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                    onClick={closeAddProductModal}
                  ></button>
                  <div className="text-center mb-2">
                    <h4 className="mb-2">Add Product</h4>
                  </div>
                  <div className="modal-body p-0">
                    <Formik
                      initialValues={createProductInitialValues}
                      validationSchema={createProductValidationSchema}
                      onSubmit={handleSubmit}
                    >
                      {({ values, handleChange }) => (
                        <Form className="dt_adv_search">
                          <div className="row">
                            <div className="col-12">
                              <div className="row g-3">
                                <div className="col-12 col-sm-6 col-lg-4">
                                  <label
                                    htmlFor="name"
                                    className="form-label"
                                    style={{ fontSize: "0.9375rem" }}
                                  >
                                    Name: <span className="text-danger">*</span>
                                  </label>
                                  <Field
                                    id="name"
                                    name="name"
                                    type="text"
                                    className="form-control"
                                    placeholder="Enter name"
                                    onChange={handleChange}
                                  />
                                  <ErrorMessage
                                    name="name"
                                    className="text-danger"
                                    component="div"
                                  />
                                </div>
                                <div className="col-12 col-sm-6 col-lg-4">
                                  <label
                                    htmlFor="hsn_no"
                                    className="form-label"
                                    style={{ fontSize: "0.9375rem" }}
                                  >
                                    Hsn No:
                                  </label>
                                  <Field
                                    id="hsn_no"
                                    name="hsn_no"
                                    type="text"
                                    className="form-control"
                                    placeholder="Enter hsn no"
                                    onChange={handleChange}
                                  />
                                  <ErrorMessage
                                    name="hsn_no"
                                    className="text-danger"
                                    component="div"
                                  />
                                </div>
                                <div className="col-12 col-sm-6 col-lg-4">
                                  <label
                                    htmlFor="status"
                                    className="form-label"
                                    style={{ fontSize: "0.9375rem" }}
                                  >
                                    Status:{" "}
                                    <span className="text-danger">*</span>
                                  </label>
                                  <select
                                    id="status"
                                    name="status"
                                    className="form-select"
                                    value={values.status}
                                    onChange={handleChange}
                                  >
                                    <option value="">Select Status</option>
                                    <option value="true">Active</option>
                                    <option value="false">Inactive</option>
                                  </select>
                                  <ErrorMessage
                                    name="status"
                                    className="text-danger"
                                    component="div"
                                  />
                                </div>
                                <div className="col-12">
                                  <label
                                    htmlFor="description"
                                    className="form-label"
                                    style={{ fontSize: "0.9375rem" }}
                                  >
                                    Description:
                                  </label>
                                  <Field
                                    id="description"
                                    name="description"
                                    type="text"
                                    className="form-control"
                                    placeholder="Enter description"
                                    onChange={handleChange}
                                    as={"textarea"}
                                    rows={2}
                                  />
                                  <ErrorMessage
                                    name="description"
                                    className="text-danger"
                                    component="div"
                                  />
                                </div>
                                <div className="col-12 text-center mt-4">
                                  <button
                                    type="submit"
                                    className="btn btn-primary me-3"
                                    disabled={submitLoader}
                                  >
                                    {submitLoader && (
                                      <span
                                        className="spinner-border spinner-border-sm me-2"
                                        role="status"
                                        aria-hidden="true"
                                      ></span>
                                    )}{" "}
                                    Add Product
                                  </button>
                                  <button
                                    type="button"
                                    className="btn btn-label-secondary"
                                    onClick={closeAddProductModal}
                                  >
                                    Cancel
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </Form>
                      )}
                    </Formik>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default AddProductModal;
