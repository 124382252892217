/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import {
  bankListApi,
  getAllBankListApi,
  updateBankStatusApi,
} from "../../services/_bank";
import { Link } from "react-router-dom";
import moment from "moment";
import Pagination from "../../components/Pagination";
import { getPermissions } from "../../context/AuthContext";
import { ADMIN_ID } from "../../components/Constants";
import { getSupplierNameListApi } from "../../services/_supplier";
import { getAllLogisticApi } from "../../services/_logistic";
import { getAllChaApi } from "../../services/_cha";
import ViewBank from "./ViewBank";
import { BankType } from "../../components/Enums";
import { Autocomplete, TextField } from "@mui/material";
import { formatString } from "../../components/Helper";
import { getBuyerNameListApi } from "../../services/_buyer";
import { getAllBrokerApi } from "../../services/_broker";

const BankList = () => {
  const permissions = getPermissions();
  const [records, setRecords] = useState([]);
  const [relationList, setRelationList] = useState([]);
  const [bankNameList, setBankNameList] = useState([]);
  const [bankAccountsList, setBankAccountList] = useState([]);
  const [page, setPage] = useState<number>(1);
  const [perPage] = useState<number>(10);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [totalRecords, setTotalRecords] = useState<number>(0);
  const [applyFilter, setApplyFilter] = useState<boolean>(false);

  const [displayFilter, setDisplayFilter] = useState(false);
  const [filterType, setFilterType] = useState<string>("");
  const [filterRelation, setFilterRelation] = useState<string>("");
  const [filterName, setFilterName] = useState<string>("");
  const [filterAcc, setFilterAcc] = useState<string>("");
  const [bankId, setBankId] = useState<string>("");
  const [sortKey, setSortKey] = useState<string>("");
  const [sortType, setSortType] = useState<string>("");

  const toggleFilter = async () => {
    setDisplayFilter(!displayFilter);
  };
  const getBankList = async (
    page: number,
    perPage: number,
    isFilter?: boolean,
    sortKey?: string,
    sortType?: string
  ) => {
    let query = `page=${page}&per_page=${perPage}`;
    if (isFilter) {
      if (filterType) {
        query += `&type=${filterType}`;
      }
      if (filterRelation) {
        query += `&relation_id=${filterRelation}`;
      }
      if (filterName) {
        query += `&bank_name=${filterName}`;
      }
      if (filterAcc) {
        query += `&account_number=${filterAcc}`;
      }
      if (sortKey && sortType) {
        query += `&sort_key=${sortKey}&sort_type=${sortType}`;
      }
    }
    const result: any = await bankListApi(query);
    if (result.isSuccess) {
      const totalPagesCount = Math.ceil(result.data.total_records / perPage);
      setTotalRecords(result.data.total_records);
      setTotalPages(totalPagesCount);
      setRecords(result.data.records);
    }
  };
  const updateBankStatus = async (id: string, status: boolean) => {
    await updateBankStatusApi({
      id: id,
      status: !status,
    });
    setBankId("");
    setRecords([]);
    getBankList(page, perPage, true);
  };

  const filterList = async () => {
    setApplyFilter(true);
    getBankList(page, perPage, true);
  };
  const resetList = async () => {
    setPage(1);
    setApplyFilter(false);
    setFilterName("");
    setFilterAcc("");
    setFilterRelation("");
    setFilterType("");
    setRelationList([]);
    fetchBankNameAndAccFilterDetails();
    getBankList(page, perPage);
  };
  const prevButton = async () => {
    if (page > 1) {
      setPage(page - 1);
    }
  };
  const nextButton = async () => {
    if (totalPages > page) {
      setPage(page + 1);
    }
  };

  const getRelationName = (data: any) => {
    const typeMap: any = {
      [BankType.SUPPLIER]: data?.supplier?.name,
      [BankType.BUYER]: data?.buyer?.name,
      [BankType.EXPORTER]: "-",
      [BankType.LOGISTIC]: data?.logistic?.name,
      [BankType.CHA]: data?.cha?.name,
      [BankType.BROKER]: data?.broker?.name,
    };
    return typeMap[data.type] || "N/A";
  };

  const fetchRelationList = async (filterType: string) => {
    if (filterType === BankType.EXPORTER) {
      setRelationList([]);
    }
    if (filterType === BankType.BUYER) {
      const result: any = await getBuyerNameListApi();
      if (result.isSuccess) {
        setRelationList(result?.data);
      }
    }
    if (filterType === BankType.SUPPLIER) {
      const result: any = await getSupplierNameListApi();
      if (result.isSuccess) {
        setRelationList(result?.data);
      }
    }
    if (filterType === BankType.LOGISTIC) {
      const result: any = await getAllLogisticApi();
      if (result.isSuccess) {
        setRelationList(result?.data);
      }
    }
    if (filterType === BankType.CHA) {
      const result: any = await getAllChaApi();
      if (result.isSuccess) {
        setRelationList(result?.data);
      }
    }
    if (filterType === BankType.BROKER) {
      const result: any = await getAllBrokerApi();
      if (result.isSuccess) {
        setRelationList(result?.data);
      }
    }
  };

  const fetchBankNameAndAccFilterDetails = async (
    field?: string,
    value?: string
  ) => {
    let query = "";
    if (field === "type") {
      query = `type=${value}`;
    }

    if (field === "relation_id") {
      query = `${filterType ? `type=${filterType}` : ""}${
        value ? `&relation_id=${value}` : ""
      }`;
    }

    if (field === "bank_name") {
      query = `${filterType ? `type=${filterType}` : ""}${
        filterRelation ? `&relation_id=${filterRelation}` : ""
      }${value ? `&bank_name=${value}` : ""}`;
    }

    const result: any = await getAllBankListApi(query && query);
    if (result.isSuccess) {
      if (field === "bank_name") {
        setBankAccountList(result?.data);
      } else {
        const uniqueBanks = result?.data.reduce((unique: any[], bank: any) => {
          if (!unique.some((b) => b.bank_name === bank.bank_name)) {
            unique.push(bank);
          }
          return unique;
        }, []);

        setBankNameList(uniqueBanks);
        setBankAccountList(result?.data);
      }
    }
  };

  const sortTable = async (key: string) => {
    let type = sortType === "asc" ? "desc" : "asc";
    if (sortKey !== key) {
      type = "asc";
    }
    setSortKey(key);
    setSortType(type);
    await getBankList(page, perPage, true, key, type);
  };

  useEffect(() => {
    if (page && perPage) {
      getBankList(page, perPage, applyFilter);
    }
  }, [page, perPage, applyFilter]);

  useEffect(() => {
    fetchBankNameAndAccFilterDetails();
  }, []);

  return (
    <>
      <div className="card">
        <div className="card-header pb-0 d-flex justify-content-between">
          <div>
            <h5>Bank List</h5>
          </div>
          {permissions.includes("create-bank") && (
            <div>
              <Link
                to="#"
                type="button"
                className="btn btn-label-github"
                style={{ marginRight: "15px" }}
                onClick={toggleFilter}
              >
                <span>
                  <i className="ti ti-filter me-sm-1"></i>
                  <span className="d-none d-sm-inline-block">Filter</span>
                </span>
              </Link>
              <Link
                className="btn btn-primary waves-effect waves-light"
                type="button"
                to="/create-bank"
              >
                <span>
                  <i className="ti ti-plus me-sm-1"></i>{" "}
                  <span className="d-none d-sm-inline-block">Add Bank</span>
                </span>
              </Link>
            </div>
          )}
        </div>

        {displayFilter && (
          <div className="card-body">
            <form className="dt_adv_search">
              <div className="row">
                <div className="col-12">
                  <div className="row g-3">
                    <div className="col-12 col-sm-6 col-lg-3">
                      <label
                        className="form-label"
                        style={{ fontSize: "0.9375rem" }}
                      >
                        Type:
                      </label>
                      <select
                        id="type"
                        name="type"
                        className="form-select"
                        value={filterType}
                        onChange={(e) => {
                          setFilterType(e.target.value);
                          fetchRelationList(e.target.value);
                          fetchBankNameAndAccFilterDetails(
                            e.target.name,
                            e.target.value
                          );
                          setFilterRelation("");
                          setFilterName("");
                          setFilterAcc("");
                        }}
                      >
                        <option value="">Select Type</option>
                        {[
                          BankType.EXPORTER,
                          BankType.BUYER,
                          // BankType.SUPPLIER,
                          // BankType.LOGISTIC,
                          // BankType.CHA,
                          // BankType.BROKER,
                        ].map((item: any) => (
                          <option value={item} key={item}>
                            {item}
                          </option>
                        ))}
                      </select>
                    </div>
                    {filterType && filterType !== BankType.EXPORTER && (
                      <div className="col-12 col-sm-6 col-lg-3">
                        <label
                          className="form-label"
                          style={{ fontSize: "0.9375rem" }}
                        >
                          {filterType && formatString(filterType)}
                        </label>
                        <select
                          id="relation_id"
                          name="relation_id"
                          className="form-select"
                          value={filterRelation}
                          onChange={(e) => {
                            setFilterRelation(e.target.value);
                            fetchBankNameAndAccFilterDetails(
                              e.target.name,
                              e.target.value
                            );
                            setFilterName("");
                            setFilterAcc("");
                          }}
                        >
                          <option value="">Select Type</option>
                          {relationList.length > 0 &&
                            relationList.map((data: any) => (
                              <option key={data.id} value={data.id}>
                                {data.name}
                              </option>
                            ))}
                        </select>
                      </div>
                    )}
                    <div className="col-12 col-sm-6 col-lg-3">
                      <label
                        className="form-label"
                        style={{ fontSize: "0.9375rem" }}
                      >
                        Bank Name:
                      </label>
                      <Autocomplete
                        disablePortal
                        options={bankNameList.map(
                          (bank: any) => bank.bank_name
                        )}
                        value={filterName}
                        onChange={(event, newValue) => {
                          if (newValue) {
                            setFilterName(newValue);
                          } else {
                            setFilterName("");
                          }
                          fetchBankNameAndAccFilterDetails(
                            "bank_name",
                            newValue
                          );
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            className="form-control"
                            type="text"
                            placeholder="Enter Bank Name"
                            onChange={(e) => setFilterName(e.target.value)}
                          />
                        )}
                      />
                    </div>
                    <div className="col-12 col-sm-6 col-lg-3">
                      <label
                        className="form-label"
                        style={{ fontSize: "0.9375rem" }}
                      >
                        Account No:
                      </label>
                      <Autocomplete
                        disablePortal
                        options={bankAccountsList.map(
                          (bank: any) => bank.account_number
                        )}
                        value={filterAcc}
                        onChange={(event, newValue) => {
                          if (newValue) {
                            setFilterAcc(newValue);
                          } else {
                            setFilterAcc("");
                          }
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            className="form-control"
                            type="text"
                            placeholder="Enter Account Number"
                            onChange={(e) => setFilterAcc(e.target.value)}
                          />
                        )}
                      />
                    </div>
                    <div className="col-12">
                      <button
                        type="button"
                        className="btn btn-primary"
                        onClick={filterList}
                      >
                        Filter
                      </button>
                      <button
                        type="button"
                        className="btn btn-label-github ms-5"
                        onClick={resetList}
                      >
                        Reset
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        )}

        <div className="card-datatable table-responsive">
          <table className="dt-advanced-search table table-nowrap">
            <thead>
              <tr>
                <th>#</th>
                <th
                  className="cursor-pointer"
                  onClick={() => sortTable("type")}
                >
                  Type
                  <span>
                    <i className="ti ti-arrows-down-up sorting-icon"></i>
                  </span>
                </th>
                <th>Name</th>
                <th
                  className="cursor-pointer"
                  onClick={() => sortTable("bank_name")}
                >
                  Bank Name
                  <span>
                    <i className="ti ti-arrows-down-up sorting-icon"></i>
                  </span>
                </th>
                <th
                  className="cursor-pointer"
                  onClick={() => sortTable("account_number")}
                >
                  Account Number
                  <span>
                    <i className="ti ti-arrows-down-up sorting-icon"></i>
                  </span>
                </th>
                <th className="cursor-pointer" onClick={() => sortTable("ifc")}>
                  IFSC
                  <span>
                    <i className="ti ti-arrows-down-up sorting-icon"></i>
                  </span>
                </th>
                <th
                  className="cursor-pointer"
                  onClick={() => sortTable("branch_name")}
                >
                  Branch Name
                  <span>
                    <i className="ti ti-arrows-down-up sorting-icon"></i>
                  </span>
                </th>
                {permissions.includes("update-bank") && (
                  <th
                    className="cursor-pointer"
                    onClick={() => sortTable("status")}
                  >
                    Status
                    <span>
                      <i className="ti ti-arrows-down-up sorting-icon"></i>
                    </span>
                  </th>
                )}
                <th
                  className="cursor-pointer"
                  onClick={() => sortTable("created_at")}
                >
                  Created At
                  <span>
                    <i className="ti ti-arrows-down-up sorting-icon"></i>
                  </span>
                </th>
                {permissions.includes("update-bank") && <th>Action</th>}
              </tr>
            </thead>
            <tbody>
              {records.length > 0 ? (
                records.map((item: any, i: number) => (
                  <tr key={item.id}>
                    <td>{(page - 1) * perPage + 1 + i}</td>
                    <td>
                      {
                        <Link
                          to={"#"}
                          onClick={() => {
                            setBankId(item.id);
                          }}
                          title="View Bank Details"
                          data-bs-toggle="modal"
                          data-bs-target="#viewBank"
                        >
                          {item.type}
                        </Link>
                      }
                    </td>
                    <td>{getRelationName(item)}</td>
                    <td>{item.bank_name}</td>
                    <td>{item.account_number}</td>
                    <td>{item.ifsc_code}</td>
                    <td>{item.branch_name}</td>
                    {permissions.includes("update-bank") && (
                      <td>
                        {ADMIN_ID !== item.id && (
                          <div className="form-check form-switch">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              id="status"
                              defaultChecked={item.status ? true : false}
                              onClick={() => {
                                updateBankStatus(item.id, item.status);
                              }}
                            />
                          </div>
                        )}
                      </td>
                    )}
                    <td>
                      {moment(item.created_at).format("YYYY-MM-DD HH:mm:ss")}
                    </td>
                    {permissions.includes("update-bank") && (
                      <td>
                        {ADMIN_ID !== item.id && (
                          <>
                            <Link
                              to={`/edit-bank/${item.id}`}
                              className="btn btn-sm btn-text-secondary rounded-pill btn-icon"
                              title="Edit Bank"
                            >
                              <i className="ti ti-pencil ti-md"></i>
                            </Link>
                          </>
                        )}
                      </td>
                    )}
                  </tr>
                ))
              ) : (
                <tr>
                  <td
                    colSpan={permissions.includes("update-bank") ? 10 : 9}
                    className="text-center"
                  >
                    No records found
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        <Pagination
          page={page}
          perPage={perPage}
          totalRecords={totalRecords}
          totalPages={totalPages}
          records={records}
          prevButton={prevButton}
          nextButton={nextButton}
        />
        <ViewBank bankId={bankId} />
      </div>
    </>
  );
};

export default BankList;
