import { DatePicker } from "rsuite";
import { formatString } from "../../../../components/Helper";
import { SalesContractStatus } from "../../../../components/Enums";
import { ErrorMessage, Field, FormikErrors, FormikValues } from "formik";
import "rsuite/DateRangePicker/styles/index.css";

interface BasicFieldsProps {
  buyerList: any;
  brokerList: any;
  setBuyerId: any;
  values: FormikValues;
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean
  ) => Promise<void | FormikErrors<FormikValues>>;
  handleChange: (e: React.ChangeEvent<any>) => void;
  setAddBuyerModalStatus: (show: boolean) => void;
  setAddBrokerModalStatus: (show: boolean) => void;
}

const BasicFields: React.FC<BasicFieldsProps> = ({
  buyerList,
  brokerList,
  setBuyerId,
  values,
  setAddBuyerModalStatus,
  setAddBrokerModalStatus,
  setFieldValue,
  handleChange,
}) => {
  return (
    <div className="card my-card">
      <div className="card-header my-card-header bg-lighter">
        <div>
          <h6 className="my-card-header-head">Basic Details</h6>
        </div>
      </div>
      <div className="card-body my-card-body">
        <div className="row g-3 m-0">
          <div className="col-12 col-sm-6 col-md-4 col-lg-3">
            <label
              htmlFor="contract_no"
              className="form-label"
              style={{ fontSize: "0.9375rem" }}
            >
              Contract No: <span className="text-danger">*</span>
            </label>
            <Field
              id="contract_no"
              name="contract_no"
              type="text"
              className="form-control"
              placeholder="Enter Contract No"
              value={values.contract_no}
              onChange={handleChange}
            />
            <ErrorMessage
              name="contract_no"
              className="text-danger"
              component="div"
            />
          </div>
          <div className="col-12 col-sm-6 col-md-4 col-lg-3">
            <label
              htmlFor="reference_no"
              className="form-label"
              style={{ fontSize: "0.9375rem" }}
            >
              Reference No:
            </label>
            <Field
              id="reference_no"
              name="reference_no"
              type="text"
              className="form-control"
              placeholder="Enter Reference No"
              value={values.reference_no}
              onChange={handleChange}
            />
            <ErrorMessage
              name="reference_no"
              className="text-danger"
              component="div"
            />
          </div>
          <div className="col-12 col-sm-6 col-md-4 col-lg-3">
            <label
              htmlFor="contract_date"
              className="form-label"
              style={{ fontSize: "0.9375rem" }}
            >
              Contract Date: <span className="text-danger">*</span>
            </label>
            <DatePicker
              id="contract_date"
              name="contract_date"
              format="dd-MM-yyyy"
              className="form-control"
              placeholder="Select Contract Date"
              value={values.contract_date}
              onChange={(value: any) => {
                setFieldValue("contract_date", value);
              }}
            />
            <ErrorMessage
              name="contract_date"
              className="text-danger"
              component="div"
            />
          </div>
          <div className="col-12 col-sm-6 col-md-4 col-lg-3">
            <div className="d-flex justify-content-between">
              <div>
                <label
                  htmlFor="buyer_id"
                  className="form-label"
                  style={{ fontSize: "0.9375rem" }}
                >
                  Buyer: <span className="text-danger">*</span>
                </label>
              </div>
              <div>
                <button
                  title="Add Unit"
                  className="btn btn-sm btn-primary waves-effect waves-light p-0"
                  type="button"
                  onClick={() => setAddBuyerModalStatus(true)}
                >
                  <span>
                    <i className="ti ti-plus my-card-icon-button"></i>
                  </span>
                </button>
              </div>
            </div>
            <select
              name="buyer_id"
              className="form-select"
              id="buyer_id"
              value={values.buyer_id}
              onChange={(e: any) => {
                handleChange(e);
                setBuyerId(e.target.value);
                setFieldValue("buyer_bank_id", "");
              }}
            >
              <option defaultChecked value={""}>
                Select Buyer
              </option>
              {buyerList.map((e: any) => {
                return (
                  <option value={e.id} key={e.id}>
                    {e.name}
                  </option>
                );
              })}
            </select>
            <ErrorMessage
              name="buyer_id"
              className="text-danger"
              component="div"
            />
          </div>
          <div className="col-12 col-sm-6 col-md-4 col-lg-3">
            <div className="d-flex justify-content-between">
              <div>
                <label
                  htmlFor="broker_id"
                  className="form-label"
                  style={{ fontSize: "0.9375rem" }}
                >
                  Broker:
                </label>
              </div>
              <div>
                <button
                  title="Add Unit"
                  className="btn btn-sm btn-primary waves-effect waves-light p-0"
                  type="button"
                  onClick={() => setAddBrokerModalStatus(true)}
                >
                  <span>
                    <i className="ti ti-plus my-card-icon-button"></i>
                  </span>
                </button>
              </div>
            </div>
            <select
              name="broker_id"
              className="form-select"
              id="broker_id"
              value={values.broker_id}
              onChange={handleChange}
            >
              <option defaultChecked value={""}>
                Select Broker
              </option>
              {brokerList.map((e: any) => {
                return (
                  <option value={e.id} key={e.id}>
                    {e.name}
                  </option>
                );
              })}
            </select>
            <ErrorMessage
              name="broker_id"
              className="text-danger"
              component="div"
            />
          </div>
          <div className="col-12 col-sm-6 col-md-4 col-lg-3">
            <label
              htmlFor="status"
              className="form-label"
              style={{ fontSize: "0.9375rem" }}
            >
              Status: <span className="text-danger">*</span>
            </label>
            <select
              id="status"
              name="status"
              className="form-select"
              value={values.status}
              onChange={handleChange}
            >
              <option defaultChecked value={""}>
                Select Status
              </option>
              <option
                key={SalesContractStatus.PENDING}
                value={SalesContractStatus.PENDING}
              >
                {formatString(SalesContractStatus.PENDING)}
              </option>
              <option
                key={SalesContractStatus.IN_PROGRESS}
                value={SalesContractStatus.IN_PROGRESS}
              >
                {formatString(SalesContractStatus.IN_PROGRESS)}
              </option>
              <option
                key={SalesContractStatus.COMPLETED}
                value={SalesContractStatus.COMPLETED}
              >
                {formatString(SalesContractStatus.COMPLETED)}
              </option>
            </select>
            <ErrorMessage
              name="status"
              className="text-danger"
              component="div"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default BasicFields;
