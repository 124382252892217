import { DELETE, GET, POST, PUT } from "./_config";

export const salesContractListApi = async (query: string) =>
  await GET(`/sales/sales-contract-list?${query || ""}`, true);

export const getAllSalesContractApi = async (query?: string) =>
  await GET(`/sales/sales-contract-list/all?${query || ""}`, true);

export const createSalesContractApi = async (body: any) =>
  await POST(`/sales/create-sales-contract`, body, true);

export const getSalesContractDetailsByIdApi = async (id: string) =>
  await GET(`/sales/sales-contract/id/${id}`, true);

export const getSaleContractQualitySpecificationSuggestionApi = async (
  id: string
) =>
  await GET(
    `/sales/sales-contract/quality-specifications-suggestion/list/${id}`,
    true
  );

export const getSalesContractDocumentSuggestionApi = async (id: string) =>
  await GET(`/sales/sales-contract/document-suggestion/list/${id}`, true);

export const updateSalesContractApi = async (id: string, body: any) =>
  await PUT(`/sales/sales-contract/update-sales-contract?id=${id}`, body, true);

export const deleteSalesContractApi = async (id: string) =>
  await DELETE(`/sales/sales-contract/delete-sales-contract?id=${id}`, true);

export const salesInvoiceListApi = async (query: string) =>
  await GET(`/sales/sales-invoice-list?${query || ""}`, true);

export const getAllSalesInvoiceApi = async (query?: string) =>
  await GET(`/sales/sales-invoice-list/all?${query || ""}`, true);

export const getAllSalesInvoiceBillApi = async (query?: string) =>
  await GET(`/sales/sales-invoice-bill-list/all?${query || ""}`, true);

export const createBillType = async (body: any) =>
  await POST(`/sales/create-bill-type`, body, true);

export const createSalesInvoiceApi = async (body: any) =>
  await POST(`/sales/create-sales-invoice`, body, true);

export const getSalesInvoiceDetailsByIdApi = async (id: string) =>
  await GET(`/sales/sales-invoice/id/${id}`, true);

export const updateSalesInvoiceApi = async (id: string, body: any) =>
  await PUT(`/sales/sales-invoice/update-sales-invoice?id=${id}`, body, true);

export const deleteSalesInvoiceApi = async (id: string) =>
  await DELETE(`/sales/sales-invoice/delete-sales-invoice?id=${id}`, true);

export const getMiscellaneousSalesListApi = async (query: string) =>
  await GET(`/sales/miscellaneous-sales-list?${query || ""}`, true);

export const getAllMiscellaneousSalesApi = async (query?: string) =>
  await GET(`/sales/miscellaneous-sales-list/all?${query || ""}`, true);

export const getMiscellaneousSalesByIdApi = async (id: string) =>
  await GET(`/sales/miscellaneous-sales/${id}`, true);

export const createMiscellaneousSalesApi = async (body: any) =>
  await POST(`/sales/create-miscellaneous-sales`, body, true);

export const updateMiscellaneousSalesApi = async (id: string, body: any) =>
  await PUT(
    `/sales/miscellaneous-sales/update-miscellaneous-sales?id=${id}`,
    body,
    true
  );

export const deleteMiscellaneousSalesApi = async (id: string) =>
  await DELETE(
    `/sales/miscellaneous-sales/delete-miscellaneous-sales?id=${id}`,
    true
  );

const SalesService = {
  salesContractListApi,
  getAllSalesContractApi,
  createSalesContractApi,
  getSalesContractDetailsByIdApi,
  getSaleContractQualitySpecificationSuggestionApi,
  getSalesContractDocumentSuggestionApi,
  updateSalesContractApi,
  deleteSalesContractApi,
  salesInvoiceListApi,
  getAllSalesInvoiceApi,
  getAllSalesInvoiceBillApi,
  createSalesInvoiceApi,
  getSalesInvoiceDetailsByIdApi,
  updateSalesInvoiceApi,
  deleteSalesInvoiceApi,
  getMiscellaneousSalesListApi,
  getAllMiscellaneousSalesApi,
  getMiscellaneousSalesByIdApi,
  createMiscellaneousSalesApi,
  updateMiscellaneousSalesApi,
  deleteMiscellaneousSalesApi,
};

export default SalesService;
