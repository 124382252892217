import { GET, POST, PUT } from "./_config";

export const myBankListApi = async (query: string) =>
  await GET(`/my-bank/list?${query || ""}`, true);

export const updateMyBankStatusApi = async (body: any) =>
  await PUT(`/my-bank/update-status`, body, true);

export const createMyBankApi = async (body: any) =>
  await POST(`/my-bank`, body, true);

export const getMyBankDetailsApi = async (id: string) =>
  await GET(`/my-bank/id/${id}`, true);

export const updateMyBankApi = async (id: string, body: any) =>
  await PUT(`/my-bank?id=${id}`, body, true);

export const myActiveBankListApi = async () =>
  await GET(`/my-bank/status/list`, true);

const MyBankService = {
  myBankListApi,
  updateMyBankStatusApi,
  createMyBankApi,
  getMyBankDetailsApi,
  updateMyBankApi,
  myActiveBankListApi,
};

export default MyBankService;
