import {
  Document,
  Font,
  Page,
  //   PDFViewer,
  StyleSheet,
  Text,
  View,
} from "@react-pdf/renderer";
import {
  formatAmount,
  formatDate,
  stringToUpperCase,
} from "../../../../components/Helper";

Font.register({
  family: "Times New Roman",
  fonts: [{ src: "/fonts/Times-New-Roman.ttf" }],
});

Font.register({
  family: "Times New Roman",
  fonts: [{ src: "/fonts/Times-New-Roman-Bold.ttf" }],
});

export const styles = StyleSheet.create({
  container: {
    width: 345,
    paddingLeft: 5,
    borderRight: 1,
  },
  boldText: {
    fontWeight: "bold",
    fontSize: 10,
  },
  smallText: {
    fontSize: 6,
  },
  mediumText: {
    fontSize: 6.5,
  },
  largeText: {
    fontSize: 8,
  },
  smallFontSize: {
    fontSize: 6,
  },
  mediumFontSize: {
    fontSize: 6.5,
  },
  regularFontSize: {
    fontSize: 8,
  },
  largeFontSize: {
    fontSize: 9,
  },
  page: {
    paddingHorizontal: 30,
    paddingVertical: 15,
    fontFamily: "HostGrotesk",
    backgroundColor: "white",
  },
  fullpage: {
    margin: 7,
    borderStyle: "solid",
    borderWidth: 1,
    borderColor: "#000000",
  },

  sideBySideContainer: { flexDirection: "row" },

  halfWidthRightSection: {
    width: "50%",
    borderBottomWidth: 1,
    borderbottomStyle: "solid",
    borderRightWidth: 1,
    textAlign: "left",
  },
  halfWidthLeftSection: {
    width: "50%",
    borderBottomWidth: 1,
    borderbottomStyle: "solid",
  },
  tableContainer: {
    borderBottomWidth: 1,
    borderBottomColor: "black",
  },
  tableRow: {
    flexDirection: "row",
    flexGrow: 1,
    alignItems: "stretch",
  },
  tableCell: {
    padding: 5,
    fontSize: 7,
    textAlign: "center",
    borderRightWidth: 1,
    borderRightColor: "black",
    borderRightStyle: "solid",
  },
});

const BlDraftInvoice = ({
  settingData,
  salesInvoiceData,
}: {
  settingData: any;
  salesInvoiceData: any;
}) => {
  const staticData = {
    agreedDays: "AGREED FREE DAYS : 14 DAYS FREE PERIOD AT DESTINATION",
    shiipedBoard: "“SHIPPED ON BOARD”",
    destination: "AT DESTINATION",
    measurement: "985.00",
  };

  return (
    <Document>
      <Page style={styles.page} size="A4">
        <View style={styles.fullpage}>
          {/* address section */}
          <View
            style={[
              styles.sideBySideContainer,
              { height: 120, flexDirection: "row" },
            ]}
          >
            <View style={[styles.halfWidthRightSection]}>
              <Text style={[styles.boldText, { left: 5 }]}>SHIPPER</Text>

              <Text style={[styles.largeText, { top: 15, left: 5 }]}>
                {stringToUpperCase(settingData?.company_name)}
              </Text>
              <Text style={[styles.largeText, { top: 15, left: 5 }]}>
                {stringToUpperCase(settingData?.company_address?.slice(0, 13))}
              </Text>
              <Text style={[styles.largeText, { top: 15, left: 5 }]}>
                {stringToUpperCase(settingData?.company_address?.slice(14, 37))}
              </Text>
              <Text style={[styles.largeText, { top: 15, left: 5 }]}>
                {stringToUpperCase(settingData?.company_address?.slice(40))}
              </Text>
              <Text style={[styles.largeText, { top: 15, left: 5 }]}>
                GSTIN: {stringToUpperCase(settingData?.company_gst_no)}
              </Text>
              <Text style={[styles.largeText, { top: 15, left: 5 }]}>
                PAN NO: {stringToUpperCase(settingData?.company_pan_no)}
              </Text>
              <Text style={[styles.largeText, { top: 15, left: 5 }]}>
                AEO REG. NO:{" "}
                {stringToUpperCase(settingData?.aeo_registration_no)}
              </Text>
            </View>
            <View
              style={[
                styles.sideBySideContainer,
                { height: 120, flexDirection: "row" },
              ]}
            >
              <View style={[styles.halfWidthLeftSection, { borderBottom: 0 }]}>
                <Text style={[styles.boldText, { top: 15, left: 15 }]}>
                  BOOKING REF NO :
                </Text>

                <Text style={[styles.boldText, { top: 25, left: 12 }]}>
                  {" "}
                  LINE:
                </Text>
              </View>
            </View>
          </View>

          {/* Consignee section*/}
          <View
            style={[
              styles.sideBySideContainer,
              { height: 110, flexDirection: "row" },
            ]}
          >
            <View style={[styles.halfWidthRightSection]}>
              <Text style={[styles.boldText, { left: 5 }]}>CONSIGNEE:</Text>

              <Text style={[styles.largeText, { left: 5 }]}>
                {stringToUpperCase(salesInvoiceData?.buyer?.name)}{" "}
              </Text>
              <Text style={[styles.largeText, { left: 5 }]}>
                {stringToUpperCase(salesInvoiceData?.buyer?.address)}
              </Text>
              <Text style={[styles.largeText, { left: 5 }]}>
                {stringToUpperCase(salesInvoiceData?.buyer?.phone_no)}
              </Text>
              <Text style={[styles.largeText, { left: 5 }]}>
                {stringToUpperCase(salesInvoiceData?.buyer?.email)}
              </Text>
              <Text style={[styles.largeText, { left: 5 }]}>
                TAX ID:____________
              </Text>
              <Text style={[styles.largeText, { left: 5 }]}>
                CONTACT NAME:____________
              </Text>
            </View>
            <View
              style={[
                styles.sideBySideContainer,
                { height: 110, flexDirection: "row", borderBottom: 1 },
              ]}
            >
              <View
                style={[styles.halfWidthLeftSection, { borderBottom: 0 }]}
              ></View>
            </View>
          </View>
          {/* Notify Party Section */}
          <View
            style={[
              styles.sideBySideContainer,
              { height: 110, flexDirection: "row" },
            ]}
          >
            <View style={[styles.halfWidthRightSection]}>
              <Text style={[styles.boldText, { left: 5 }]}>NOTIFY PARTY:</Text>

              <Text style={[styles.largeText, { left: 5 }]}>
                {stringToUpperCase(salesInvoiceData?.buyer?.name)}{" "}
              </Text>
              <Text style={[styles.largeText, { left: 5 }]}>
                {stringToUpperCase(salesInvoiceData?.buyer?.address)}
              </Text>
              <Text style={[styles.largeText, { left: 5 }]}>
                {stringToUpperCase(salesInvoiceData?.buyer?.phone_no)}
              </Text>
              <Text style={[styles.largeText, { left: 5 }]}>
                {stringToUpperCase(salesInvoiceData?.buyer?.email)}
              </Text>
              <Text style={[styles.largeText, { left: 5 }]}>
                TAX ID:____________
              </Text>
              <Text style={[styles.largeText, { left: 5 }]}>
                CONTACT NAME:____________
              </Text>
            </View>
            <View
              style={[
                styles.sideBySideContainer,
                { height: 110, flexDirection: "row", borderBottom: 1 },
              ]}
            >
              <View style={[styles.halfWidthLeftSection, { borderBottom: 0 }]}>
                <Text
                  style={[
                    styles.boldText,
                    {
                      borderBottom: 0,
                      left: 10,
                      top: 15,
                    },
                  ]}
                >
                  SWITCH BL LOCATION:{" "}
                </Text>
              </View>
            </View>
          </View>

          {/* Port detail */}
          <View
            style={[
              styles.sideBySideContainer,
              { height: 20, flexDirection: "row" },
            ]}
          >
            <View style={[styles.halfWidthRightSection]}>
              <Text style={[styles.boldText, { left: 5, top: 3 }]}>
                PORT OF LOADING:{" "}
                <Text style={[styles.boldText, { textAlign: "center" }]}>
                  {salesInvoiceData?.origin_port?.name &&
                  salesInvoiceData?.origin_port?.country
                    ? `${stringToUpperCase(
                        salesInvoiceData?.origin_port?.name
                      )}, ${stringToUpperCase(
                        salesInvoiceData?.origin_port?.country
                      )}`
                    : "-"}
                </Text>
              </Text>
            </View>
            <View
              style={[
                styles.sideBySideContainer,
                { height: 55, flexDirection: "row", borderBottom: 1 },
              ]}
            >
              <View style={[styles.halfWidthLeftSection, { borderBottom: 0 }]}>
                <Text
                  style={[
                    styles.boldText,
                    {
                      borderBottom: 0,
                      left: 10,
                      top: 15,
                    },
                  ]}
                >
                  {" "}
                  VESSEL VOYAGE :
                </Text>
              </View>
            </View>
          </View>

          <View
            style={[
              styles.sideBySideContainer,
              styles.halfWidthRightSection,
              { height: 35, borderBottom: 1 },
            ]}
          >
            <Text
              style={[
                styles.boldText,
                {
                  borderRight: 1,
                  width: 150,
                  left: 5,
                  paddingTop: 5,
                },
              ]}
            >
              PORT OF DISCHARGE: {"\n"}
              <Text style={[styles.boldText, { textAlign: "left" }]}>
                {salesInvoiceData?.destination_port?.name &&
                salesInvoiceData?.destination_port?.country
                  ? `${stringToUpperCase(
                      salesInvoiceData?.destination_port?.name
                    )}, ${stringToUpperCase(
                      salesInvoiceData?.destination_port?.country
                    )}`
                  : "-"}
              </Text>
            </Text>
            <Text
              style={[
                styles.boldText,
                {
                  width: 200,
                  paddingTop: 5,
                  left: 9,
                },
              ]}
            >
              FINAL PLACE OF DELIVERY: {"\n"}
              <Text style={[styles.boldText, { textAlign: "left" }]}>
                {salesInvoiceData?.destination_port?.name &&
                salesInvoiceData?.destination_port?.country
                  ? `${stringToUpperCase(
                      salesInvoiceData?.destination_port?.name
                    )}, ${stringToUpperCase(
                      salesInvoiceData?.destination_port?.country
                    )}`
                  : "-"}
              </Text>
            </Text>
          </View>

          {/* table */}
          {/* table headings */}
          <View>
            <View
              style={[
                styles.tableRow,
                {
                  borderBottom: 1,
                  justifyContent: "space-between",
                },
              ]}
            >
              <Text
                style={[
                  styles.boldText,
                  {
                    borderRight: 1,
                    textAlign: "left",
                    flexWrap: "wrap",
                    paddingLeft: 5,
                    width: 145,
                  },
                ]}
              >
                MARKS & NUMBERS
              </Text>

              <Text
                style={[
                  styles.boldText,
                  {
                    borderRight: 1,
                    textAlign: "left",
                    flexWrap: "wrap",
                    width: 345,
                    paddingLeft: 5,
                  },
                ]}
              >
                NUMBER AND KIND OF PACKAGES DESCRIPTION OF GOODS
              </Text>

              <Text
                style={[
                  styles.boldText,
                  {
                    borderRight: 1,
                    paddingLeft: 2,
                    textAlign: "left",
                    flexWrap: "wrap",
                    width: 80,
                  },
                ]}
              >
                GROSS WEIGHT
              </Text>

              <Text
                style={[
                  styles.boldText,
                  {
                    paddingLeft: 2,
                    borderRight: 0,
                    textAlign: "left",
                    flexWrap: "wrap",
                    width: 80,
                  },
                ]}
              >
                MEASUREMENT
              </Text>
            </View>

            <View>
              {/* MAIN ROW */}
              <View
                style={[
                  styles.tableRow,
                  {
                    borderBottom: 1,
                  },
                ]}
              >
                <Text
                  style={[
                    styles.regularFontSize,
                    styles.largeFontSize,
                    {
                      borderRight: 1,
                      textAlign: "center",
                      flexWrap: "wrap",
                      paddingLeft: 2,
                      width: 145,
                    },
                  ]}
                ></Text>

                <View style={[styles.container]}>
                  <Text style={[styles.regularFontSize, styles.largeFontSize]}>
                    {/* {salesInvoiceData?.container_size
                      ? stringToUpperCase(salesInvoiceData?.container_quantity)
                      : "-"} */}
                    {salesInvoiceData?.container_size
                      ? stringToUpperCase(salesInvoiceData?.container_size)
                      : "-"}
                    ' DC CONTAINER STC
                  </Text>
                  <View style={{ marginTop: 10 }}>
                    <Text
                      style={[styles.regularFontSize, styles.largeFontSize]}
                    >
                      {formatAmount(salesInvoiceData?.quantity)}{" "}
                      {stringToUpperCase(salesInvoiceData.product?.name)}
                    </Text>
                    <Text
                      style={[styles.regularFontSize, styles.largeFontSize]}
                    >
                      {salesInvoiceData?.packing_type?.name
                        ? `(PACKED IN ${stringToUpperCase(
                            salesInvoiceData?.packing_type?.name
                          )}) `
                        : ""}

                      {salesInvoiceData?.packing_net_weight &&
                      salesInvoiceData?.net_weight_unit?.name
                        ? `NET ${
                            salesInvoiceData?.packing_net_weight
                          } ${stringToUpperCase(
                            salesInvoiceData?.net_weight_unit?.name
                          )} & `
                        : ""}
                    </Text>
                    <Text
                      style={[styles.regularFontSize, styles.largeFontSize]}
                    >
                      {salesInvoiceData?.packing_gross_weight &&
                      salesInvoiceData?.gross_weight_unit?.name
                        ? `GROSS ${
                            salesInvoiceData?.packing_gross_weight
                          } ${stringToUpperCase(
                            salesInvoiceData?.gross_weight_unit?.name
                          )} EACH BAG.`
                        : ""}
                    </Text>
                    <Text
                      style={[styles.regularFontSize, styles.largeFontSize]}
                    >
                      INVOICE NO.{" "}
                      {stringToUpperCase(salesInvoiceData?.invoice_no)},{" "}
                      {formatDate(salesInvoiceData?.invoice_date)}
                    </Text>
                    <Text
                      style={[styles.regularFontSize, styles.largeFontSize]}
                    >
                      EXPORTERS HS CODE: 1006.30.10
                    </Text>
                    <Text
                      style={[styles.regularFontSize, styles.largeFontSize]}
                    >
                      EXPORTERS HS CODE: 1006.30.19
                    </Text>
                    <Text
                      style={[styles.regularFontSize, styles.largeFontSize]}
                    >
                      NET WT:{" "}
                      {salesInvoiceData?.total_net_weight
                        ? formatAmount(salesInvoiceData?.total_net_weight)
                        : "-"}{" "}
                    </Text>
                    <Text
                      style={[styles.regularFontSize, styles.largeFontSize]}
                    >
                      GRS WT:{" "}
                      {salesInvoiceData?.total_gross_weight
                        ? formatAmount(salesInvoiceData?.total_gross_weight)
                        : "-"}{" "}
                    </Text>
                    <Text
                      style={[styles.regularFontSize, styles.largeFontSize]}
                    >
                      TOTAL NO. OF BAGS:{" "}
                      {salesInvoiceData?.no_of_bags
                        ? `${salesInvoiceData?.no_of_bags} BAGS`
                        : "-"}
                    </Text>
                    <Text
                      style={[styles.regularFontSize, styles.largeFontSize]}
                    >
                      SB NO:
                    </Text>
                  </View>
                  <View style={{ marginTop: 10 }}>
                    <Text
                      style={[styles.regularFontSize, styles.largeFontSize]}
                    >
                      {staticData?.agreedDays}
                      <Text
                        style={[styles.regularFontSize, styles.largeFontSize]}
                      >
                        {"\n"}
                        {staticData?.destination}
                      </Text>
                    </Text>
                  </View>
                  <View style={{ marginTop: 10 }}>
                    <Text
                      style={[styles.regularFontSize, styles.largeFontSize]}
                    >
                      {stringToUpperCase(salesInvoiceData?.payment_term)}
                    </Text>
                  </View>
                  <View style={{ marginTop: 10 }}>
                    <Text
                      style={[styles.regularFontSize, styles.largeFontSize]}
                    >
                      {staticData?.shiipedBoard}
                    </Text>
                  </View>
                </View>

                <Text
                  style={[
                    styles.tableCell,
                    styles.largeFontSize,
                    {
                      borderRight: 1,
                      textAlign: "left",
                      flexWrap: "wrap",
                      width: 80,
                      paddingTop: 5,
                    },
                  ]}
                >
                  {salesInvoiceData?.total_gross_weight
                    ? formatAmount(salesInvoiceData?.total_gross_weight)
                    : "-"}
                </Text>

                <Text
                  style={[
                    styles.tableCell,
                    styles.largeFontSize,
                    {
                      borderRight: 0,
                      textAlign: "left",
                      flexWrap: "wrap",
                      width: 80,
                    },
                  ]}
                >
                  {staticData?.measurement}
                </Text>
              </View>
            </View>
            <View
              style={[
                styles.tableRow,
                {
                  borderBottom: 0,
                },
              ]}
            >
              <Text
                style={[
                  styles.largeFontSize,
                  {
                    borderRight: 1,
                    textAlign: "left",
                    flexWrap: "wrap",
                    paddingLeft: 5,
                    paddingTop: 15,
                    width: 145,
                  },
                ]}
              >
                POD AGENT
              </Text>
              <Text
                style={[
                  styles.largeFontSize,
                  {
                    borderRight: 1,
                    textAlign: "center",
                    flexWrap: "wrap",
                    width: 345,
                    paddingTop: 15,
                    paddingLeft: 5,
                  },
                ]}
              >
                CY/CY
              </Text>

              <Text
                style={[
                  styles.largeFontSize,
                  {
                    borderRight: 1,
                    paddingLeft: 2,
                    textAlign: "left",
                    flexWrap: "wrap",
                    paddingTop: 15,
                    width: 80,
                  },
                ]}
              >
                BL TYPE:
              </Text>

              <Text
                style={[
                  styles.largeFontSize,
                  {
                    paddingLeft: 2,
                    borderRight: 0,
                    textAlign: "left",
                    flexWrap: "wrap",
                    width: 80,
                    paddingTop: 15,
                  },
                ]}
              >
                DRAFT
              </Text>
            </View>
          </View>
        </View>
      </Page>

      {/* page 2 */}

      <Page style={styles.page} size="A4">
        <View style={styles.fullpage}>
          <View style={[{ marginTop: 5, marginBottom: 5 }]}>
            <Text style={[styles.largeFontSize, { textAlign: "center" }]}>
              PROVIDE US BELOW CONTAINER WISE SPLIT UP (PACKAGES & KGS)
            </Text>
          </View>
          <View>
            {salesInvoiceData?.containers?.length && (
              <View>
                <View
                  style={[
                    styles.tableRow,
                    styles.tableContainer,
                    {
                      borderTopWidth: 1,
                      height: 15,
                      flexDirection: "row",
                      width: "100%",
                    },
                  ]}
                >
                  <Text
                    style={[
                      styles.largeFontSize,
                      {
                        borderRightWidth: 1,
                        textAlign: "center",
                        flexWrap: "wrap",
                        width: "25%",
                        justifyContent: "space-between",
                        fontWeight: "bold",
                      },
                    ]}
                  >
                    CONTR. NO
                  </Text>
                  <Text
                    style={[
                      styles.largeFontSize,
                      {
                        borderRightWidth: 1,
                        textAlign: "center",
                        flexWrap: "wrap",
                        width: "25%",
                        justifyContent: "space-between",
                        fontWeight: "bold",
                      },
                    ]}
                  >
                    SEAL NO
                  </Text>
                  <Text
                    style={[
                      styles.largeFontSize,
                      {
                        borderRightWidth: 1,
                        textAlign: "center",
                        flexWrap: "wrap",
                        width: "25%",
                        justifyContent: "space-between",
                        fontWeight: "bold",
                      },
                    ]}
                  >
                    SELF SEAL NO
                  </Text>
                  <Text
                    style={[
                      styles.largeFontSize,
                      {
                        borderRightWidth: 1,
                        textAlign: "center",
                        flexWrap: "wrap",
                        width: "15%",
                        fontWeight: "bold",
                      },
                    ]}
                  >
                    SIZE
                  </Text>
                  <Text
                    style={[
                      styles.largeFontSize,
                      {
                        borderRightWidth: 1,
                        textAlign: "center",
                        flexWrap: "wrap",
                        width: "20%",
                        fontWeight: "bold",
                      },
                    ]}
                  >
                    NO OF BAGS
                  </Text>
                  <Text
                    style={[
                      styles.largeFontSize,
                      {
                        borderRightWidth: 1,
                        textAlign: "center",
                        flexWrap: "wrap",
                        width: "15%",
                        fontWeight: "bold",
                      },
                    ]}
                  >
                    NET WT
                  </Text>
                  <Text
                    style={[
                      styles.largeFontSize,
                      {
                        borderRightWidth: 1,
                        textAlign: "center",
                        flexWrap: "wrap",
                        width: "15%",
                        fontWeight: "bold",
                      },
                    ]}
                  >
                    GR WT
                  </Text>
                  <Text
                    style={[
                      styles.largeFontSize,
                      {
                        textAlign: "center",
                        flexWrap: "wrap",
                        width: "15%",
                        fontWeight: "bold",
                      },
                    ]}
                  >
                    CBM
                  </Text>
                </View>
                {salesInvoiceData?.containers?.map(
                  (row: any, index: number) => {
                    return (
                      <View
                        key={index}
                        style={[
                          styles.tableRow,
                          styles.tableContainer,
                          {
                            height: 15,
                            flexDirection: "row",
                            width: "100%",
                          },
                        ]}
                      >
                        <Text
                          style={[
                            styles.largeFontSize,
                            {
                              borderRight: 1,
                              textAlign: "center",
                              flexWrap: "wrap",
                              width: "25%",
                              justifyContent: "space-between",
                            },
                          ]}
                        >
                          {stringToUpperCase(row?.container_no)}
                        </Text>
                        <Text
                          style={[
                            styles.largeFontSize,
                            {
                              borderRight: 1,
                              textAlign: "center",
                              flexWrap: "wrap",
                              width: "25%",
                              justifyContent: "space-between",
                            },
                          ]}
                        >
                          {stringToUpperCase(row?.line_seal_no)}
                        </Text>
                        <Text
                          style={[
                            styles.largeFontSize,
                            {
                              borderRight: 1,
                              textAlign: "center",
                              flexWrap: "wrap",
                              width: "25%",
                              justifyContent: "space-between",
                            },
                          ]}
                        >
                          {stringToUpperCase(row?.self_seal?.seal_no)}
                        </Text>
                        <Text
                          style={[
                            styles.largeFontSize,
                            {
                              borderRightWidth: 1,
                              textAlign: "center",
                              flexWrap: "wrap",
                              width: "15%",
                            },
                          ]}
                        >
                          {stringToUpperCase(row?.size)}
                        </Text>
                        <Text
                          style={[
                            styles.largeFontSize,
                            {
                              borderRightWidth: 1,
                              textAlign: "center",
                              flexWrap: "wrap",
                              width: "20%",
                            },
                          ]}
                        >
                          {stringToUpperCase(row?.no_of_bags)}
                        </Text>
                        <Text
                          style={[
                            styles.largeFontSize,
                            {
                              borderRightWidth: 1,
                              textAlign: "center",
                              flexWrap: "wrap",
                              width: "15%",
                            },
                          ]}
                        >
                          {stringToUpperCase(row?.total_net_weight)}
                        </Text>
                        <Text
                          style={[
                            styles.largeFontSize,
                            {
                              borderRightWidth: 1,
                              textAlign: "center",
                              flexWrap: "wrap",
                              width: "15%",
                            },
                          ]}
                        >
                          {stringToUpperCase(row?.total_gross_weight)}
                        </Text>
                        <Text
                          style={[
                            styles.largeFontSize,
                            {
                              textAlign: "center",
                              flexWrap: "wrap",
                              width: "15%",
                            },
                          ]}
                        >
                          {/* CBM */}
                        </Text>
                      </View>
                    );
                  }
                )}
              </View>
            )}
          </View>

          {/* instructions */}
          <View
            style={[
              styles.largeFontSize,
              {
                justifyContent: "space-between",
                left: 5,
                marginRight: 15,
                marginTop: 10,
                marginBottom: 5,
              },
            ]}
          >
            <Text style={{ marginTop: 10 }}>
              1. If Switch BL involved provide us the Second Leg BL thru same
              format along with surrender letter for 1st Leg BL and LOI for 2nd
              Leg BL.
            </Text>
            <Text style={{ marginTop: 10 }}>
              2. If Surrender BL , Surrender letter should be provided in
              Shipper Letter head.
            </Text>
            <Text style={{ marginTop: 10 }}>
              3. NATIONAL ID , NPWP NO , HSN CODE , ACID NO , USCI NO are
              Mandatory to some of the Destination in CUSTOMS to avoid
              additional charges / delay in POD.
            </Text>
            <Text style={{ marginTop: 10 }}>
              4. Consignee contact No and Mail Id is Mandatory to share CAN
              (Cargo Arrival Notice) without delay at POD.
            </Text>
            <Text style={{ marginTop: 10 }}>
              5. Ensure to confirm us the BL type for the shipment along with
              share us Invoice party details (GST , PAN , TAN copy) for the
              shipment.
            </Text>
          </View>
        </View>
      </Page>
    </Document>
  );
};

export default BlDraftInvoice;
