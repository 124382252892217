/* eslint-disable react-hooks/exhaustive-deps */
import moment from "moment";
import { Link } from "react-router-dom";
import { DateRangePicker } from "rsuite";
import Spinner from "../../theme/Spinner";
import { useEffect, useState } from "react";
import { getAllChaApi } from "../../services/_cha";
import { getBuyerNameListApi } from "../../services/_buyer";
import Pagination from "../../components/Pagination";
import { getPermissions } from "../../context/AuthContext";
import { getAllExpensesApi } from "../../services/_expenses";
import { getAllLogisticApi } from "../../services/_logistic";
import { getSupplierNameListApi } from "../../services/_supplier";
import { ADMIN_ID, CURRENCY } from "../../components/Constants";
import { errorToast, successToast } from "../../components/Toast";
import { deletePaymentApi, paymentListApi } from "../../services/_payment";
import "rsuite/DateRangePicker/styles/index.css";
import {
  PaymentRole,
  PaymentStatus,
  TransactionTypes,
} from "../../components/Enums";
import { formatString } from "../../components/Helper";
import { getAllBrokerApi } from "../../services/_broker";
import ReCAPTCHA from "react-google-recaptcha";

const PaymentList = () => {
  const permissions = getPermissions();
  const [records, setRecords] = useState([]);
  const [page, setPage] = useState<number>(1);
  const [perPage] = useState<number>(10);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [totalRecords, setTotalRecords] = useState<number>(0);
  const [applyFilter, setApplyFilter] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [paymentRole, setPaymentRole] = useState<string | null>(null);
  const [paymentStatus, setPaymentStatus] = useState<string>("");
  const [relationID, setRelationID] = useState<string>("");
  const [filterType, setFilterType] = useState<string>("");
  const [relationList, setRelationList] = useState([]);
  const [dateRange, setDateRange] = useState(null);
  const [displayFilter, setDisplayFilter] = useState(false);
  const [selectedItemId, setSelectedItemId] = useState<string | null>(null);
  const [sortKey, setSortKey] = useState<string>("");
  const [sortType, setSortType] = useState<string>("");
  const [isCaptchaVerified, setIsCaptchaVerified] = useState(false);
  const [showCaptcha, setShowCaptcha] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState<boolean>(false);

  const getPaymentList = async (
    page: number,
    perPage: number,
    isFilter?: boolean,
    sortKey?: string,
    sortType?: string
  ) => {
    setLoading(true);
    let query = `page=${page}&per_page=${perPage}`;
    if (isFilter) {
      if (paymentRole) {
        query += `&paymentRole=${paymentRole.toUpperCase()}`;
      }
      if (relationID) {
        query += `&relationID=${relationID}`;
      }
      if (paymentStatus) {
        query += `&paymentStatus=${paymentStatus.toUpperCase()}`;
      }
      if (dateRange) {
        query += `&from_date=${moment(dateRange[0]).format("YYYY-MM-DD")}`;
        query += `&to_date=${moment(dateRange[1]).format("YYYY-MM-DD")}`;
      }
      if (sortKey && sortType) {
        query += `&sort_key=${sortKey}&sort_type=${sortType}`;
      }
    }
    const result: any = await paymentListApi(query);
    if (result.isSuccess) {
      const totalPagesCount = Math.ceil(result.data.total_records / perPage);
      setTotalRecords(result.data.total_records);
      setTotalPages(totalPagesCount);
      setRecords(result.data.records);
    }
    setLoading(false);
  };

  const toggleFilter = async () => {
    setDisplayFilter(!displayFilter);
  };

  const filterList = async () => {
    setApplyFilter(true);
    getPaymentList(page, perPage, true);
  };

  const resetList = async () => {
    setPage(1);
    setApplyFilter(false);
    setPaymentRole("");
    setPaymentStatus("");
    setRelationID("");
    setRelationList([]);
    setDateRange(null);
    getPaymentList(page, perPage);
    setShowCaptcha(false);
    setIsCaptchaVerified(false);
  };

  const prevButton = async () => {
    if (page > 1) {
      setPage(page - 1);
    }
  };

  const nextButton = async () => {
    if (totalPages > page) {
      setPage(page + 1);
    }
  };

  const handleCaptchaChange = (value: any | "") => {
    if (value) {
      setIsCaptchaVerified(true);
    }
  };

  const confirmDelete = async () => {
    setDeleteLoading(true);
    if (selectedItemId) {
      const result = await deletePaymentApi(selectedItemId);
      if (result.isSuccess) {
        await getPaymentList(page, perPage);
        successToast(result.message);
      } else {
        errorToast(result.message);
      }
      setDeleteLoading(false);
      setSelectedItemId(null);
    }
  };

  const handleDeleteClick = (id: any) => {
    setSelectedItemId(id);
    setShowCaptcha(true);
  };

  const getStackHolderName = (item: any) => {
    if (
      item?.payment_role === PaymentRole.SUPPLIER &&
      item?.supplier &&
      item?.supplier?.name
    ) {
      return item.supplier.name;
    }

    if (
      item?.payment_role === PaymentRole.BUYER &&
      item?.buyer &&
      item?.buyer?.name
    ) {
      return item.buyer.name;
    }

    if (
      item?.payment_role === PaymentRole.EXPENSE &&
      item?.expense &&
      item?.expense?.name
    ) {
      return item.expense.name;
    }

    if (
      item?.payment_role === PaymentRole.LOGISTIC &&
      item?.logistic &&
      item?.logistic?.name
    ) {
      return item.logistic.name;
    }

    if (
      item?.payment_role === PaymentRole.CHA &&
      item?.cha &&
      item?.cha?.name
    ) {
      return item.cha.name;
    }

    if (
      item?.payment_role === PaymentRole.BROKER &&
      item?.broker &&
      item?.broker?.name
    ) {
      return item.broker.name;
    }
    return "N/A";
  };

  useEffect(() => {
    const fetchTypeData = async () => {
      if (filterType === PaymentRole.SUPPLIER) {
        const result: any = await getSupplierNameListApi();
        if (result.isSuccess) {
          setRelationList(result?.data);
        } else {
          errorToast(result.message);
        }
        return;
      }
      if (filterType === PaymentRole.LOGISTIC) {
        const result: any = await getAllLogisticApi();
        if (result.isSuccess) {
          setRelationList(result?.data);
        } else {
          errorToast(result.message);
        }
        return;
      }
      if (filterType === PaymentRole.EXPENSE) {
        const result: any = await getAllExpensesApi();

        if (result.isSuccess) {
          setRelationList(result?.data);
        } else {
          errorToast(result.message);
        }
        return;
      }
      if (filterType === PaymentRole.BUYER) {
        const result: any = await getBuyerNameListApi();

        if (result.isSuccess) {
          setRelationList(result?.data);
        } else {
          errorToast(result.message);
        }
        return;
      }
      if (filterType === PaymentRole.CHA) {
        const result: any = await getAllChaApi();

        if (result.isSuccess) {
          setRelationList(result?.data);
        } else {
          errorToast(result.message);
        }
        return;
      }
      if (filterType === PaymentRole.BROKER) {
        const result: any = await getAllBrokerApi();

        if (result.isSuccess) {
          setRelationList(result?.data);
        } else {
          errorToast(result.message);
        }
        return;
      }

      setRelationList([]);
      setRelationID("");
    };
    fetchTypeData();
  }, [filterType]);

  const sortTable = async (key: string) => {
    let type = sortType === "asc" ? "desc" : "asc";
    if (sortKey !== key) {
      type = "asc";
    }
    setSortKey(key);
    setSortType(type);
    await getPaymentList(page, perPage, true, key, type);
  };
  useEffect(() => {
    if (page && perPage) {
      getPaymentList(page, perPage, applyFilter);
    }
  }, [page, perPage, applyFilter]);

  return (
    <>
      <div className="card">
        <div className="card-header pb-0 d-flex justify-content-between">
          <div>
            <h5>Payment List</h5>
          </div>
          <div className="d-flex gap-4">
            <div>
              <Link
                to="#"
                type="button"
                className="btn btn-label-github"
                style={{ marginRight: "15px" }}
                onClick={toggleFilter}
              >
                <span>
                  <i className="ti ti-filter me-sm-1"></i>{" "}
                  <span className="d-none d-sm-inline-block">Filter</span>
                </span>
              </Link>
              {permissions.includes("create-payments") && (
                <Link
                  className="btn btn-primary waves-effect waves-light"
                  type="button"
                  to="/create-payment"
                >
                  <span>
                    <i className="ti ti-plus me-sm-1"></i>{" "}
                    <span className="d-none d-sm-inline-block">
                      Add Payment
                    </span>
                  </span>
                </Link>
              )}
            </div>
          </div>
        </div>

        {displayFilter && (
          <div className="card-body">
            <form className="dt_adv_search">
              <div className="row">
                <div className="col-12">
                  <div className="row g-3">
                    <div className="col-12 col-sm-6 col-lg-3">
                      <label
                        htmlFor="transaction_date"
                        className="form-label"
                        style={{ fontSize: "0.9375rem" }}
                      >
                        Transaction Date:
                      </label>
                      <DateRangePicker
                        format="dd-MM-yyyy"
                        className="form-control"
                        placeholder="From Date ~ To Date"
                        value={dateRange}
                        onChange={(values: any) => {
                          setDateRange(values);
                        }}
                      />
                    </div>
                    <div className="col-12 col-sm-6 col-lg-3">
                      <label
                        htmlFor="payment_role"
                        className="form-label"
                        style={{ fontSize: "0.9375rem" }}
                      >
                        Payment Role:
                      </label>
                      <select
                        id="payment_role"
                        name="payment_role"
                        className="form-select"
                        value={paymentRole || ""}
                        onChange={(e) => {
                          const value = e.target.value;
                          setPaymentRole(value);
                          setFilterType(value);
                        }}
                      >
                        <option defaultChecked value={""}>
                          Select Payment Role
                        </option>
                        {[
                          PaymentRole.BUYER,
                          PaymentRole.SUPPLIER,
                          PaymentRole.CHA,
                          PaymentRole.LOGISTIC,
                          PaymentRole.EXPENSE,
                          PaymentRole.BROKER,
                        ].map((item) => (
                          <option value={item} key={item}>
                            {item}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="col-12 col-sm-6 col-lg-3">
                      <label
                        className="form-label"
                        style={{ fontSize: "0.9375rem" }}
                      >
                        {paymentRole
                          ? formatString(paymentRole)
                          : "Select Stackholder"}
                        :
                      </label>
                      <select
                        id="relation_id"
                        name="relation_id"
                        className="form-select"
                        value={relationID}
                        onChange={(e) => {
                          setRelationID(e.target.value);
                        }}
                      >
                        <option defaultChecked value={""}>
                          Select
                          {paymentRole
                            ? formatString(paymentRole)
                            : "Stackholder"}
                        </option>
                        {relationList.length > 0 &&
                          relationList.map((data: any) => (
                            <option key={data.id} value={data.id}>
                              {data.name}
                            </option>
                          ))}
                      </select>
                    </div>
                    <div className="col-12 col-sm-6 col-lg-3">
                      <label
                        className="form-label"
                        style={{ fontSize: "0.9375rem" }}
                      >
                        Payment Status:
                      </label>
                      <select
                        id="payment_status"
                        name="payment_status"
                        className="form-select"
                        value={paymentStatus}
                        onChange={(e) => {
                          const value = e.target.value;
                          setPaymentStatus(value);
                        }}
                      >
                        <option defaultChecked value={""}>
                          Select Payment Status
                        </option>
                        {[
                          PaymentStatus.PENDING,
                          PaymentStatus.ADVANCE,
                          PaymentStatus.PARTIALLY_PAID,
                          PaymentStatus.COMPLETED,
                        ].map((item) => (
                          <option value={item} key={item}>
                            {formatString(item)}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="col-12">
                      <button
                        type="button"
                        className="btn btn-primary"
                        onClick={filterList}
                      >
                        Filter
                      </button>
                      <button
                        type="button"
                        className="btn btn-label-github ms-5"
                        onClick={resetList}
                      >
                        Reset
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        )}

        <div className="card-datatable table-responsive">
          {loading && <Spinner isFullScreen={false} />}
          <table className="dt-advanced-search table table-nowrap">
            <thead>
              <tr>
                <th>#</th>
                <th>Payment Role</th>
                <th>Stackholder Name</th>
                <th
                  className="cursor-pointer"
                  onClick={() => sortTable("amount")}
                >
                  Amount
                  <span>
                    <i className="ti ti-arrows-down-up sorting-icon"></i>
                  </span>
                </th>
                <th
                  className="cursor-pointer"
                  onClick={() => sortTable("transaction_type")}
                >
                  Transaction Type
                  <span>
                    <i className="ti ti-arrows-down-up sorting-icon"></i>
                  </span>
                </th>
                <th
                  className="cursor-pointer"
                  onClick={() => sortTable("transaction_date")}
                >
                  Transaction Date
                  <span>
                    <i className="ti ti-arrows-down-up sorting-icon"></i>
                  </span>
                </th>
                <th
                  className="cursor-pointer"
                  onClick={() => sortTable("payment_status")}
                >
                  Payment Status
                  <span>
                    <i className="ti ti-arrows-down-up sorting-icon"></i>
                  </span>
                </th>
                {permissions.includes("update-payments") && <th>Action</th>}
              </tr>
            </thead>
            <tbody>
              {records.length > 0 ? (
                records.map((item: any, i: number) => {
                  let statusClass = "";
                  if (item.payment_status === PaymentStatus.PENDING) {
                    statusClass = `bg-label-warning`;
                  } else if (item.payment_status === PaymentStatus.COMPLETED) {
                    statusClass = `bg-label-success`;
                  } else if (item.payment_status === PaymentStatus.ADVANCE) {
                    statusClass = `bg-label-info`;
                  } else {
                    statusClass = `bg-label-secondary`;
                  }
                  let transactionTypeClass = "";
                  if (item.transaction_type === TransactionTypes.CREDIT) {
                    transactionTypeClass = `bg-label-success`;
                  } else {
                    transactionTypeClass = `bg-label-danger`;
                  }
                  return (
                    <tr key={item.id}>
                      <td>{(page - 1) * perPage + 1 + i}</td>
                      <td>{item.payment_role}</td>
                      <td>{getStackHolderName(item)}</td>
                      <td>
                        {CURRENCY.INR.SYMBOL}
                        {parseFloat(item.amount).toFixed(2)}
                      </td>
                      <td>
                        <span className={`badge ${transactionTypeClass} me-1`}>
                          {item.transaction_type}
                        </span>
                      </td>
                      <td>
                        {moment(item.transaction_date).format("DD-MM-YYYY")}
                      </td>
                      <td>
                        <span className={`badge ${statusClass} me-1`}>
                          {formatString(item.payment_status)}
                        </span>
                      </td>
                      {permissions.includes("update-payments") && (
                        <td>
                          {ADMIN_ID !== item.id && (
                            <>
                              {permissions.includes("update-payments") && (
                                <Link
                                  to={`/edit-payment/${item.id}`}
                                  className="btn btn-sm btn-text-secondary rounded-pill btn-icon"
                                  title="Edit Payment"
                                >
                                  <i className="ti ti-pencil ti-md"></i>
                                </Link>
                              )}
                              {permissions.includes("delete-payments") && (
                                <button
                                  className="btn btn-sm btn-text-secondary rounded-pill btn-icon"
                                  data-bs-toggle="modal"
                                  data-bs-target="#deletePaymentModal"
                                  onClick={() => handleDeleteClick(item.id)}
                                >
                                  <i className="ti ti-trash ti-md"></i>
                                </button>
                              )}
                            </>
                          )}
                        </td>
                      )}
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td
                    colSpan={permissions.includes("update-payments") ? 8 : 7}
                    className="text-center"
                  >
                    No records found
                  </td>
                </tr>
              )}
            </tbody>
          </table>
          <div
            className="modal fade"
            id="deletePaymentModal"
            tabIndex={-1}
            aria-hidden="true"
          >
            <div className="modal-dialog modal-simple modal-dialog-centered">
              <div className="modal-content">
                <div className="modal-body text-center">
                  <i
                    className="fa-solid fa-circle-exclamation"
                    style={{
                      fontSize: "48px",
                      color: "#ff4c51",
                      marginBottom: "20px",
                    }}
                  ></i>
                  <p style={{ fontSize: "30px" }}>Are you sure?</p>
                  <br />
                  <p style={{ fontSize: "20px" }}>
                    Do you want to delete this <b>Payment</b>?
                    <br />
                    This action can't be undone!
                  </p>
                  {showCaptcha && (
                    <div
                      style={{
                        marginTop: "10px",
                        marginLeft: "50px",
                        marginBottom: "10px",
                      }}
                    >
                      <ReCAPTCHA
                        sitekey="6LdCqqMqAAAAAI5NAleRuzGZv4X3LjDVJdvGDmaj"
                        onChange={handleCaptchaChange}
                      />
                    </div>
                  )}
                  <div className="text-center">
                    <button
                      type="button"
                      className="btn btn-danger me-3"
                      onClick={() => {
                        confirmDelete();
                        resetList();
                      }}
                      disabled={deleteLoading || !isCaptchaVerified}
                      data-bs-dismiss="modal"
                    >
                      {deleteLoading && (
                        <span
                          className="spinner-border spinner-border-sm me-2"
                          role="status"
                          aria-hidden="true"
                        ></span>
                      )}
                      Yes, Delete
                    </button>

                    <button
                      type="button"
                      className="btn btn-label-secondary"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                      onClick={resetList}
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Pagination
          page={page}
          perPage={perPage}
          totalRecords={totalRecords}
          totalPages={totalPages}
          records={records}
          prevButton={prevButton}
          nextButton={nextButton}
        />
      </div>
    </>
  );
};

export default PaymentList;
