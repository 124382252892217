import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  Font,
} from "@react-pdf/renderer";
import {
  formatDateTime,
  stringToUpperCase,
} from "../../../../components/Helper";

Font.register({
  family: "Times New Roman",
  fonts: [
    { src: "/fonts/Times-New-Roman.ttf" },
    { src: "/fonts/Times-New-Roman-Bold.ttf", fontWeight: "bold" },
  ],
});

const styles = StyleSheet.create({
  mainContainer: {
    flexDirection: "row",
  },
  page: {
    paddingHorizontal: 30,
    paddingVertical: 15,
    fontFamily: "Times New Roman",
  },
  fullpage: {
    margin: 7,
    padding: 5,
  },
  section: {
    marginBottom: 10,
  },
  header: {
    fontSize: 11,
    textAlign: "center",
    fontWeight: "bold",
    marginBottom: 20,
    textDecoration: "underline",
  },
  regularFontSize: {
    fontSize: 10,
  },
  boldText: {
    fontWeight: "bold",
  },
  tableRow: {
    flexDirection: "row",
  },
});

const CertificateOfNonGmo = ({
  salesInvoiceData,
  settingsData,
}: {
  salesInvoiceData: any;
  settingsData: any;
}) => {
  const staticData = {
    vessel_name: "Container",
    cargo_description:
      "Flat rack containers are ideal for carrying goods like heavy machinery, vehicles, building materials, etc.",
  };
  return (
    <Document>
      <Page style={styles.page} size="A4">
        <View style={[styles.fullpage]}>
          {/* HEADER */}
          <View style={styles.mainContainer}>
            <Text
              style={[
                styles.header,
                styles.boldText,
                {
                  flex: 2,
                  width: 200,
                },
              ]}
            >
              CERTIFICATE OF NON-GMO
            </Text>
          </View>

          {/* REPORT No and Date */}
          <View style={styles.section}>
            <View style={styles.mainContainer}>
              <Text
                style={[styles.boldText, styles.regularFontSize, { flex: 1 }]}
              >
                REPORT NO.{" "}
                <Text style={styles.boldText}>
                  {stringToUpperCase(salesInvoiceData?.invoice_no)}
                </Text>
              </Text>
              <Text style={[styles.boldText, styles.regularFontSize]}>
                DATE:{" "}
                <Text style={styles.boldText}>
                  {formatDateTime(salesInvoiceData.invoice_date)}
                </Text>
              </Text>
            </View>
          </View>

          {/* CERTIFICATION */}
          <View style={styles.section}>
            <Text
              style={[
                styles.regularFontSize,
                styles.boldText,
                { marginTop: 10 },
              ]}
            >
              IT IS HEREBY CERTIFIED THAT THE PRODUCT DESCRIBED BELOW DOES NOT
              CONTAIN GENETICALLY MODIFIED ORGANISM (GMO)”
            </Text>
          </View>

          {/* TABLE */}
          <View>
            {/* TABLE ROWS */}
            <View style={[styles.tableRow, { border: 1 }]}>
              <Text
                style={[
                  styles.regularFontSize,
                  {
                    padding: 5,
                    width: 200,
                    borderRight: 1,
                  },
                  styles.boldText,
                ]}
              >
                INV NO. & DATE
              </Text>
              <Text
                style={[
                  styles.regularFontSize,
                  {
                    padding: 4,
                    width: 20,
                    borderRight: 1,
                    textAlign: "center",
                  },
                  styles.boldText,
                ]}
              >
                :
              </Text>
              <Text
                style={[
                  styles.regularFontSize,
                  {
                    padding: 4,
                    width: 300,
                  },
                ]}
              >
                {stringToUpperCase(salesInvoiceData?.invoice_no)} DATE :{" "}
                {formatDateTime(salesInvoiceData?.invoice_date)}
              </Text>
            </View>
            {/* 2 */}
            <View style={[styles.tableRow, { borderBottom: 1 }]}>
              <Text
                style={[
                  styles.regularFontSize,
                  {
                    padding: 5,
                    width: 200,
                    borderRight: 1,
                    borderLeft: 1,
                  },
                  styles.boldText,
                ]}
              >
                B/L NOS.
              </Text>
              <Text
                style={[
                  styles.regularFontSize,
                  {
                    padding: 4,
                    width: 20,
                    borderRight: 1,
                    textAlign: "center",
                  },
                  styles.boldText,
                ]}
              >
                :
              </Text>
              <Text
                style={[
                  styles.regularFontSize,
                  {
                    padding: 4,
                    width: 300,
                    borderRight: 1,
                  },
                ]}
              >
                {salesInvoiceData?.bl_no}
              </Text>
            </View>
            {/* 3 */}
            <View style={[styles.tableRow, { borderBottom: 1 }]}>
              <Text
                style={[
                  styles.regularFontSize,
                  {
                    padding: 5,
                    width: 200,
                    borderRight: 1,
                    borderLeft: 1,
                    paddingTop: 21,
                  },
                  styles.boldText,
                ]}
              >
                SHIPPER
              </Text>

              <Text
                style={[
                  styles.regularFontSize,
                  {
                    padding: 4,
                    width: 20,
                    borderRight: 1,
                    textAlign: "center",
                    paddingTop: 20,
                  },
                  styles.boldText,
                ]}
              >
                :
              </Text>

              <View
                style={[
                  {
                    flexDirection: "column",
                    width: 300,
                    borderRight: 1,
                    paddingBottom: 4,
                    paddingTop: 4,
                  },
                  styles.regularFontSize,
                ]}
              >
                <Text style={{ paddingLeft: 4 }}>
                  {stringToUpperCase(settingsData?.company_name)}
                </Text>
                <Text style={[{ paddingLeft: 4 }]}>
                  {stringToUpperCase(
                    settingsData?.company_address?.slice(0, 13)
                  )}
                </Text>
                <Text style={[{ paddingLeft: 4 }]}>
                  {stringToUpperCase(
                    settingsData?.company_address?.slice(14, 37)
                  )}
                </Text>
                <Text style={[{ paddingLeft: 4 }]}>
                  {stringToUpperCase(settingsData?.company_address?.slice(40))}
                </Text>
              </View>
            </View>
            {/* 4 */}
            <View style={[styles.tableRow, { borderBottom: 1 }]}>
              <Text
                style={[
                  styles.regularFontSize,
                  {
                    padding: 5,
                    width: 200,
                    borderRight: 1,
                    borderLeft: 1,

                    paddingTop: 15,
                  },
                  styles.boldText,
                ]}
              >
                CONSIGNEE
              </Text>
              <Text
                style={[
                  styles.regularFontSize,
                  {
                    padding: 4,
                    width: 20,
                    borderRight: 1,
                    textAlign: "center",
                    paddingTop: 14,
                  },
                  styles.boldText,
                ]}
              >
                :
              </Text>
              <View
                style={[
                  {
                    flexDirection: "column",
                    width: 300,
                    borderRight: 1,
                    paddingBottom: 4,
                    paddingTop: 4,
                  },
                  styles.regularFontSize,
                ]}
              >
                <Text style={{ paddingLeft: 4, paddingRight: 4 }}>
                  {stringToUpperCase(salesInvoiceData?.buyer?.name)}
                </Text>
                <Text style={{ paddingLeft: 4, paddingRight: 4 }}>
                  {stringToUpperCase(salesInvoiceData?.buyer?.address)},
                </Text>
                <Text style={{ paddingLeft: 4, paddingRight: 4 }}>
                  {stringToUpperCase(salesInvoiceData?.buyer?.city)},
                </Text>
                <Text style={{ paddingLeft: 4, paddingRight: 4 }}>
                  {stringToUpperCase(salesInvoiceData?.buyer?.state)},
                </Text>
                <Text style={{ paddingLeft: 4, paddingRight: 4 }}>
                  {stringToUpperCase(salesInvoiceData?.buyer?.country)},
                </Text>
              </View>
            </View>
            {/* 5 */}
            <View style={[styles.tableRow, { borderBottom: 1 }]}>
              <Text
                style={[
                  styles.regularFontSize,
                  {
                    padding: 5,
                    width: 200,
                    borderRight: 1,
                    borderLeft: 1,

                    paddingTop: 15,
                  },
                  styles.boldText,
                ]}
              >
                NOTIFY PARTY
              </Text>
              <Text
                style={[
                  styles.regularFontSize,
                  {
                    padding: 4,
                    width: 20,
                    borderRight: 1,
                    textAlign: "center",
                    paddingTop: 14,
                  },
                  styles.boldText,
                ]}
              >
                :
              </Text>
              <View
                style={[
                  {
                    flexDirection: "column",
                    width: 300,
                    borderRight: 1,
                    paddingBottom: 4,
                    paddingTop: 4,
                  },
                  styles.regularFontSize,
                ]}
              >
                <Text style={{ paddingLeft: 4, paddingRight: 4 }}>
                  {stringToUpperCase(salesInvoiceData?.buyer?.name)}
                </Text>
                <Text style={{ paddingLeft: 4, paddingRight: 4 }}>
                  {stringToUpperCase(salesInvoiceData?.buyer?.address)},
                </Text>
                <Text style={{ paddingLeft: 4, paddingRight: 4 }}>
                  {stringToUpperCase(salesInvoiceData?.buyer?.city)},
                </Text>
                <Text style={{ paddingLeft: 4, paddingRight: 4 }}>
                  {stringToUpperCase(salesInvoiceData?.buyer?.state)},
                </Text>
                <Text style={{ paddingLeft: 4, paddingRight: 4 }}>
                  {stringToUpperCase(salesInvoiceData?.buyer?.country)},
                </Text>
              </View>
            </View>
            {/* 6 */}
            <View style={[styles.tableRow, { borderBottom: 1 }]}>
              <Text
                style={[
                  styles.regularFontSize,
                  {
                    padding: 5,
                    width: 200,
                    borderRight: 1,
                    borderLeft: 1,
                  },
                  styles.boldText,
                ]}
              >
                PORT OF LOADING
              </Text>
              <Text
                style={[
                  styles.regularFontSize,
                  {
                    padding: 4,
                    width: 20,
                    borderRight: 1,
                    textAlign: "center",
                  },
                  styles.boldText,
                ]}
              >
                :
              </Text>
              <Text
                style={[
                  styles.regularFontSize,
                  {
                    padding: 4,
                    width: 300,
                    borderRight: 1,
                  },
                ]}
              >
                {salesInvoiceData?.origin_port?.name &&
                salesInvoiceData?.origin_port?.country
                  ? `${stringToUpperCase(
                      salesInvoiceData?.origin_port?.name
                    )},  ${stringToUpperCase(
                      salesInvoiceData?.origin_port?.country
                    )}`
                  : "-"}
              </Text>
            </View>
            {/* 7 */}
            <View style={[styles.tableRow, { borderBottom: 1 }]}>
              <Text
                style={[
                  styles.regularFontSize,
                  {
                    padding: 5,
                    width: 200,
                    borderRight: 1,
                    borderLeft: 1,
                  },
                  styles.boldText,
                ]}
              >
                PORT OF DESTINATION
              </Text>
              <Text
                style={[
                  styles.regularFontSize,
                  {
                    padding: 4,
                    width: 20,
                    borderRight: 1,
                    textAlign: "center",
                  },
                  styles.boldText,
                ]}
              >
                :
              </Text>
              <Text
                style={[
                  styles.regularFontSize,
                  {
                    padding: 4,
                    width: 300,
                    borderRight: 1,
                  },
                ]}
              >
                {salesInvoiceData?.destination_port?.name &&
                salesInvoiceData?.destination_port?.name
                  ? `${stringToUpperCase(
                      salesInvoiceData?.destination_port?.name
                    )}, ${stringToUpperCase(
                      salesInvoiceData?.destination_port?.country
                    )}`
                  : "-"}
              </Text>
            </View>
            {/*8 */}
            <View style={[styles.tableRow, { borderBottom: 1 }]}>
              <Text
                style={[
                  styles.regularFontSize,
                  {
                    padding: 5,
                    width: 200,
                    borderRight: 1,
                    borderLeft: 1,
                  },
                  styles.boldText,
                ]}
              >
                VESSEL NAME
              </Text>
              <Text
                style={[
                  styles.regularFontSize,
                  {
                    padding: 4,
                    width: 20,
                    borderRight: 1,
                    textAlign: "center",
                  },
                  styles.boldText,
                ]}
              >
                :
              </Text>
              <Text
                style={[
                  styles.regularFontSize,
                  {
                    padding: 4,
                    width: 300,
                    borderRight: 1,
                  },
                ]}
              >
                {stringToUpperCase(staticData.vessel_name)}
              </Text>
            </View>
            {/* 9 */}
            <View style={[styles.tableRow, { borderBottom: 1 }]}>
              <Text
                style={[
                  styles.regularFontSize,
                  {
                    padding: 5,
                    width: 200,
                    borderRight: 1,
                    borderLeft: 1,
                  },
                  styles.boldText,
                ]}
              >
                CARGO DESCRIPTION
              </Text>
              <Text
                style={[
                  styles.regularFontSize,
                  {
                    padding: 4,
                    width: 20,
                    borderRight: 1,
                    textAlign: "center",
                  },
                  styles.boldText,
                ]}
              >
                :
              </Text>
              <Text
                style={[
                  styles.regularFontSize,
                  {
                    padding: 4,
                    width: 300,
                    borderRight: 1,
                  },
                ]}
              >
                {stringToUpperCase(staticData?.cargo_description)}
              </Text>
            </View>
            {/* 10 */}
            <View style={[styles.tableRow, { borderBottom: 1 }]}>
              <Text
                style={[
                  styles.regularFontSize,
                  {
                    padding: 5,
                    width: 200,
                    borderRight: 1,
                    borderLeft: 1,

                    paddingTop: 15,
                  },
                  styles.boldText,
                ]}
              >
                QUANTITY
              </Text>
              <Text
                style={[
                  styles.regularFontSize,
                  {
                    padding: 4,
                    width: 20,
                    borderRight: 1,
                    textAlign: "center",
                    paddingTop: 14,
                  },
                  styles.boldText,
                ]}
              >
                :
              </Text>
              <View
                style={[
                  {
                    flexDirection: "column",
                    width: 300,
                    borderRight: 1,
                    paddingBottom: 4,
                    paddingTop: 4,
                  },
                  styles.regularFontSize,
                ]}
              >
                <Text style={{ paddingLeft: 4 }}>
                  {salesInvoiceData?.no_of_bags} BAGS
                </Text>
                <Text style={{ paddingLeft: 4 }}>
                  NET WEIGHT:{salesInvoiceData?.total_net_weight} MTS
                </Text>
                <Text style={{ paddingLeft: 4 }}>
                  GROSS WEIGHT: {salesInvoiceData?.total_gross_weight} MTS
                </Text>
              </View>
            </View>
            {/* 11 */}
            <View style={[styles.tableRow, { borderBottom: 1 }]}>
              <Text
                style={[
                  styles.regularFontSize,
                  {
                    padding: 5,
                    width: 200,
                    borderRight: 1,
                    borderLeft: 1,

                    paddingTop: 9,
                  },
                  styles.boldText,
                ]}
              >
                PACKING
              </Text>
              <Text
                style={[
                  styles.regularFontSize,
                  {
                    padding: 4,
                    width: 20,
                    borderRight: 1,
                    textAlign: "center",
                    paddingTop: 8,
                  },
                  styles.boldText,
                ]}
              >
                :
              </Text>
              <Text
                style={[
                  styles.regularFontSize,
                  {
                    padding: 4,
                    width: 300,
                    borderRight: 1,
                  },
                ]}
              >
                (PACKED IN NEW {salesInvoiceData?.packing_type?.name}) NET{" "}
                {salesInvoiceData?.packing_net_weight}{" "}
                {salesInvoiceData?.net_weight_unit?.name} & GROSS{" "}
                {salesInvoiceData?.packing_gross_weight} {""}
                {salesInvoiceData?.gross_weight_unit?.name} EACH BAG
              </Text>
            </View>
            {/* 12 */}
            <View style={[styles.tableRow, { borderBottom: 1 }]}>
              <Text
                style={[
                  styles.regularFontSize,
                  {
                    padding: 5,
                    width: 200,
                    borderRight: 1,
                    borderLeft: 1,
                    paddingTop: 5,
                  },
                  styles.boldText,
                ]}
              >
                HS CODE
              </Text>
              <Text
                style={[
                  styles.regularFontSize,
                  {
                    padding: 4,
                    width: 20,
                    borderRight: 1,
                    textAlign: "center",
                  },
                  styles.boldText,
                ]}
              >
                :
              </Text>
              <Text
                style={[
                  styles.regularFontSize,
                  {
                    padding: 5,
                    width: 300,
                    borderRight: 1,
                  },
                ]}
              >
                {stringToUpperCase(salesInvoiceData?.product?.hsn_no)}
              </Text>
            </View>
            {/* 13 */}
            {salesInvoiceData?.containers?.length && (
              <View style={[styles.tableRow, { borderBottom: 1 }]}>
                <Text
                  style={[
                    styles.regularFontSize,
                    {
                      padding: 5,
                      width: 200,
                      borderRight: 1,
                      borderLeft: 1,
                      paddingTop: 9,
                    },
                    styles.boldText,
                  ]}
                >
                  CONTAINER NOS
                </Text>
                <Text
                  style={[
                    styles.regularFontSize,
                    {
                      padding: 4,
                      width: 20,
                      borderRight: 1,
                      textAlign: "center",
                      paddingTop: 9,
                    },
                    styles.boldText,
                  ]}
                >
                  :
                </Text>
                <View
                  style={[
                    {
                      flexDirection: "column",
                      flexWrap: "wrap",
                      width: 300,
                      borderRight: 1,
                      paddingBottom: 4,
                      paddingTop: 4,
                    },
                    styles.regularFontSize,
                  ]}
                >
                  {salesInvoiceData?.containers?.map((row: any) => {
                    return (
                      <Text
                        style={{
                          padding: 4,
                          textAlign: "left",
                          marginBottom: 4,
                        }}
                      >
                        {stringToUpperCase(row.container_no)}
                      </Text>
                    );
                  })}
                </View>
              </View>
            )}
          </View>

          {/* FINAL CERTIFICATION */}
          <View style={(styles.section, { marginTop: 10, marginBottom: 20 })}>
            <Text style={[styles.regularFontSize]}>
              THIS CERTIFICATE REFLECTS OUR FINDINGS AT THE TIME, DATE AND PLACE
              OF INSPECTION ONLY AND DOES NOT REFER TO ANY OTHER MATTER.
            </Text>
            <Text style={[styles.regularFontSize, styles.boldText]}>
              “ISSUED WITHOUT PREJUDICE”
            </Text>
          </View>

          {/* SIGNATURE */}
          <View style={styles.section}>
            <Text
              style={[
                styles.regularFontSize,
                styles.boldText,
                { paddingLeft: 192, marginBottom: 40, marginTop: 20 },
              ]}
            >
              For, ACCURATE UNIVERSAL LABORATORIES PRIVATE LIMITED
            </Text>
            <Text
              style={[
                styles.regularFontSize,
                styles.boldText,
                { paddingLeft: 380 },
              ]}
            >
              AUTHORISED SIGNATORY
            </Text>
          </View>
        </View>
      </Page>
    </Document>
  );
};

export default CertificateOfNonGmo;
