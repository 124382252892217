import { Field, FieldArray, ErrorMessage } from "formik";
import { Autocomplete, TextField } from "@mui/material";

interface DocumentProps {
  values: any;
  setAddDocumentModalStatus: (show: boolean) => void;
  documentList: any[];
  documentSuggestionList: any[];
  setFieldValue: any;
}

const DocumentFields: React.FC<DocumentProps> = ({
  values,
  documentList,
  setAddDocumentModalStatus,
  documentSuggestionList,
  setFieldValue,
}) => {
  const filterSuggestion = (data: [] | any, id: any) => {
    const newData = data.reduce((filteredData: any[], item: any) => {
      if (
        !filteredData.some(
          (filteredItem) => filteredItem.value === item.value
        ) &&
        item.document_id === id
      ) {
        filteredData.push(item);
      }
      return filteredData;
    }, []);

    return newData || [];
  };

  return (
    <div className="card my-card">
      <FieldArray
        name="document"
        render={({ push, remove }) => (
          <>
            <div className="card-header my-card-header bg-lighter d-flex justify-content-between">
              <div>
                <h6 className="my-card-header-head">Documents</h6>
              </div>
              <div>
                <button
                  title="Add Document"
                  className="btn btn-sm btn-primary waves-effect waves-light p-0"
                  type="button"
                  style={{ margin: "5px" }}
                  onClick={() => {
                    push({
                      document_id: "",
                      value: "",
                    });
                  }}
                >
                  <span>
                    <i className="ti ti-plus my-card-icon-button"></i>
                  </span>
                </button>
              </div>
            </div>
            {values.document.map((_: any, index: number) => (
              <div
                className="card-body my-card-body"
                style={{ paddingBottom: 0, paddingTop: 0 }}
              >
                <div className="sales_section_card" key={index}>
                  <div className="row g-3 m-0">
                    <div className="col-12 col-sm-6 col-md-4">
                      <div className="d-flex justify-content-between">
                        <div>
                          <label
                            htmlFor={`document[${index}].document_id`}
                            className="form-label"
                            style={{ fontSize: "0.9375rem" }}
                          >
                            Document: <span className="text-danger">*</span>
                          </label>
                        </div>
                        <div>
                          <button
                            title="Add Product"
                            className="btn btn-sm btn-primary waves-effect waves-light p-0"
                            type="button"
                            onClick={() => setAddDocumentModalStatus(true)}
                          >
                            <span>
                              <i className="ti ti-plus my-card-icon-button"></i>
                            </span>
                          </button>
                        </div>
                      </div>

                      <Field
                        as="select"
                        name={`document[${index}].document_id`}
                        className="form-select"
                      >
                        <option value="">Select Document</option>
                        {documentList.map((data) => {
                          const isOptionDisabled = values.document.some(
                            (doc: any, docIndex: number) =>
                              doc.document_id === data.id && docIndex !== index
                          );

                          return (
                            <option
                              key={data.id}
                              value={data.id}
                              disabled={isOptionDisabled}
                            >
                              {data.name}
                            </option>
                          );
                        })}
                      </Field>
                      <ErrorMessage
                        name={`document[${index}].document_id`}
                        component="div"
                        className="text-danger"
                      />
                    </div>
                    <div className="col-12 col-sm-6 col-md-8">
                      <label
                        htmlFor={`document[${index}].value`}
                        className="form-label"
                        style={{ fontSize: "0.9375rem" }}
                      >
                        Value: <span className="text-danger">*</span>
                      </label>
                      <Autocomplete
                        disablePortal
                        options={filterSuggestion(
                          documentSuggestionList,
                          values.document[index].document_id
                        ).map((suggestion: any) => suggestion.value)}
                        value={values?.document[index]?.value}
                        onChange={(event, newValue) => {
                          setFieldValue(`document[${index}].value`, newValue);
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            className="form-control"
                            type="text"
                            placeholder="Enter Quality Specification Value"
                            onChange={(e) =>
                              setFieldValue(
                                `document[${index}].value`,
                                e.target.value
                              )
                            }
                          />
                        )}
                      />
                      <ErrorMessage
                        name={`document[${index}].value`}
                        component="div"
                        className="text-danger"
                      />
                    </div>
                    <i
                      className="fa-solid fa-xmark sales_document_btn"
                      onClick={() => remove(index)}
                    ></i>
                  </div>
                </div>
              </div>
            ))}
          </>
        )}
      />
    </div>
  );
};

export default DocumentFields;
