import * as Yup from "yup";
import { useEffect, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { errorToast, successToast } from "../../components/Toast";
import { getSettingsApi, updateSettingApi } from "../../services/_settings";
import Spinner from "../../theme/Spinner";

const Settings = () => {
  const [loading, setLoading] = useState(false);
  const [updateLoading, setUpdateLoading] = useState<boolean>(false);

  useEffect(() => {
    getSettings();
  }, []);

  const [initialValues, setInitialValues] = useState({
    company_name: "",
    company_email: "",
    company_phone_1: "",
    company_phone_2: "",
    company_pan_no: "",
    company_gst_no: "",
    company_udhyam_no: "",
    company_website: "",
    company_address: "",
    iec_no: "",
    aeo_registration_no: "",
    name_of_proprietor: "",
    self_sealing_permission_no: "",
    self_sealing_permission_date: "",
    self_sealing_permission_validity: "",
    sales_contract_prefix: "",
    sales_invoice_prefix: "",
  });

  const createSupplierValidationSchema = Yup.object().shape({
    company_name: Yup.string().required("Name is required"),
    company_email: Yup.string().required("Email is required"),
    company_phone_1: Yup.string(),
    company_phone_2: Yup.string(),
    company_pan_no: Yup.string(),
    company_gst_no: Yup.string(),
    company_udhyam_no: Yup.string(),
    company_website: Yup.string(),
    company_address: Yup.string(),
    iec_no: Yup.string(),
    aeo_registration_no: Yup.string(),
    name_of_proprietor: Yup.string(),
    self_sealing_permission_no: Yup.string(),
    self_sealing_permission_date: Yup.string(),
    self_sealing_permission_validity: Yup.string(),
    sales_contract_prefix: Yup.string(),
    sales_invoice_prefix: Yup.string(),
  });

  const getSettings = async () => {
    setLoading(true);
    try {
      const result: any = await getSettingsApi();
      if (result.isSuccess) {
        const initialValue = {
          company_name: result.data.company_name,
          company_email: result.data.company_email,
          company_phone_1: result.data.company_phone_1,
          company_phone_2: result.data.company_phone_2,
          company_pan_no: result.data.company_pan_no,
          company_gst_no: result.data.company_gst_no,
          company_udhyam_no: result.data.company_udhyam_no,
          company_website: result.data.company_website,
          company_address: result.data.company_address,
          iec_no: result.data.iec_no,
          aeo_registration_no: result.data.aeo_registration_no,
          name_of_proprietor: result.data.name_of_proprietor,
          self_sealing_permission_no: result.data.self_sealing_permission_no,
          self_sealing_permission_date:
            result.data.self_sealing_permission_date,
          self_sealing_permission_validity:
            result.data.self_sealing_permission_validity,
          sales_contract_prefix: result.data.sales_contract_prefix,
          sales_invoice_prefix: result.data.sales_invoice_prefix,
        };
        setInitialValues(initialValue);
      } else {
        errorToast(result.message);
      }
      setLoading(false);
    } catch (error) {
      console.error("Error fetching settings:", error);
      errorToast("Something went wrong, Please try again letter!");
    }
  };

  const handleSubmit = async (values: any) => {
    setUpdateLoading(true);
    try {
      const result = await updateSettingApi(values);
      if (result.isSuccess) {
        successToast(result.message);
      }
      setUpdateLoading(false);
    } catch (error) {
      console.error("Error updating settings:", error);
      errorToast("An error occurred while updating the settings");
    } finally {
      setUpdateLoading(false);
    }
  };

  return (
    <>
      <div className="card">
        <div className="card-header pb-0">
          <div>
            <h5>Settings</h5>
            {loading && <Spinner isFullScreen={false} />}
          </div>
        </div>
        <div className="card-body">
          <Formik
            enableReinitialize={true}
            initialValues={initialValues}
            validationSchema={createSupplierValidationSchema}
            onSubmit={handleSubmit}
          >
            {({ values, handleChange }) => (
              <Form className="dt_adv_search">
                <div className="row">
                  <div className="col-12">
                    <div className="row g-3">
                      <div className="col-12 col-sm-6 col-lg-4">
                        <label
                          htmlFor="company_name"
                          className="form-label"
                          style={{ fontSize: "0.9375rem" }}
                        >
                          Company Name: <span className="text-danger">*</span>
                        </label>
                        <Field
                          id="company_name"
                          name="company_name"
                          type="text"
                          className="form-control"
                          placeholder="Enter Company Name"
                          onChange={handleChange}
                          value={values.company_name || ""}
                        />
                        <ErrorMessage
                          name="company_name"
                          className="text-danger"
                          component="div"
                        />
                      </div>
                      <div className="col-12 col-sm-6 col-lg-4">
                        <label
                          htmlFor="company_email"
                          className="form-label"
                          style={{ fontSize: "0.9375rem" }}
                        >
                          Company Email: <span className="text-danger">*</span>
                        </label>
                        <Field
                          id="company_email"
                          name="company_email"
                          type="text"
                          className="form-control"
                          placeholder="Enter Company Email"
                          onChange={handleChange}
                          value={values.company_email || ""}
                        />
                        <ErrorMessage
                          name="company_email"
                          className="text-danger"
                          component="div"
                        />
                      </div>
                      <div className="col-12 col-sm-6 col-lg-4">
                        <label
                          htmlFor="company_phone_1"
                          className="form-label"
                          style={{ fontSize: "0.9375rem" }}
                        >
                          Company Phone 1 :{" "}
                        </label>
                        <Field
                          id="company_phone_1"
                          name="company_phone_1"
                          type="text"
                          className="form-control"
                          placeholder="Enter Company Phone 1"
                          onChange={handleChange}
                          value={values.company_phone_1 || ""}
                        />
                        <ErrorMessage
                          name="company_phone_1"
                          className="text-danger"
                          component="div"
                        />
                      </div>

                      <div className="col-12 col-sm-6 col-lg-4">
                        <label
                          htmlFor="company_phone_2"
                          className="form-label"
                          style={{ fontSize: "0.9375rem" }}
                        >
                          Company Phone 2:{" "}
                        </label>
                        <Field
                          id="company_phone_2"
                          name="company_phone_2"
                          type="text"
                          className="form-control"
                          placeholder="Enter Company Phone 2"
                          onChange={handleChange}
                          value={values.company_phone_2 || ""}
                        />
                        <ErrorMessage
                          name="company_phone_2"
                          className="text-danger"
                          component="div"
                        />
                      </div>
                      <div className="col-12 col-sm-6 col-lg-4">
                        <label
                          htmlFor="company_pan_no"
                          className="form-label"
                          style={{ fontSize: "0.9375rem" }}
                        >
                          Company PAN No:
                        </label>
                        <Field
                          id="company_pan_no"
                          name="company_pan_no"
                          type="text"
                          className="form-control"
                          placeholder="Enter Company PAN No"
                          onChange={handleChange}
                          value={values.company_pan_no || ""}
                        />
                        <ErrorMessage
                          name="company_pan_no"
                          className="text-danger"
                          component="div"
                        />
                      </div>
                      <div className="col-12 col-sm-6 col-lg-4">
                        <label
                          htmlFor="company_gst_no"
                          className="form-label"
                          style={{ fontSize: "0.9375rem" }}
                        >
                          Company GST No:
                        </label>
                        <Field
                          id="company_gst_no"
                          name="company_gst_no"
                          type="text"
                          className="form-control"
                          placeholder="Enter Company GST No"
                          onChange={handleChange}
                          value={values.company_gst_no || ""}
                        />
                        <ErrorMessage
                          name="company_gst_no"
                          className="text-danger"
                          component="div"
                        />
                      </div>
                      <div className="col-12 col-sm-6 col-lg-4">
                        <label
                          htmlFor="company_udhyam_no"
                          className="form-label"
                          style={{ fontSize: "0.9375rem" }}
                        >
                          Company UDHYAM No:{" "}
                        </label>
                        <Field
                          id="company_udhyam_no"
                          name="company_udhyam_no"
                          type="text"
                          className="form-control"
                          placeholder="Enter Company UDHYAM No"
                          onChange={handleChange}
                          value={values.company_udhyam_no || ""}
                        />
                        <ErrorMessage
                          name="company_udhyam_no"
                          className="text-danger"
                          component="div"
                        />
                      </div>
                      <div className="col-12 col-sm-6 col-lg-4">
                        <label
                          htmlFor="company_website"
                          className="form-label"
                          style={{ fontSize: "0.9375rem" }}
                        >
                          Company Website:{" "}
                        </label>
                        <Field
                          id="company_website"
                          name="company_website"
                          type="text"
                          className="form-control"
                          placeholder="Enter Company Website"
                          onChange={handleChange}
                          value={values.company_website || ""}
                        />
                        <ErrorMessage
                          name="company_website"
                          className="text-danger"
                          component="div"
                        />
                      </div>

                      <div className="col-12 col-sm-6 col-lg-4">
                        <label
                          htmlFor="iec_no"
                          className="form-label"
                          style={{ fontSize: "0.9375rem" }}
                        >
                          IEC No:
                        </label>
                        <Field
                          id="iec_no"
                          name="iec_no"
                          type="text"
                          className="form-control"
                          placeholder="Enter IEC No."
                          onChange={handleChange}
                          value={values.iec_no || ""}
                        />
                        <ErrorMessage
                          name="iec_no"
                          className="text-danger"
                          component="div"
                        />
                      </div>

                      <div className="col-12 col-sm-6 col-lg-4">
                        <label
                          htmlFor="aeo_registration_no"
                          className="form-label"
                          style={{ fontSize: "0.9375rem" }}
                        >
                          AEO Registration No:{" "}
                        </label>
                        <Field
                          id="aeo_registration_no"
                          name="aeo_registration_no"
                          type="text"
                          className="form-control"
                          placeholder="Enter AEO Registration No."
                          onChange={handleChange}
                          value={values.aeo_registration_no || ""}
                        />
                        <ErrorMessage
                          name="aeo_registration_no"
                          className="text-danger"
                          component="div"
                        />
                      </div>

                      <div className="col-12 col-sm-6 col-lg-4">
                        <label
                          htmlFor="name_of_proprietor"
                          className="form-label"
                          style={{ fontSize: "0.9375rem" }}
                        >
                          Name of Proprietor/Partner:{" "}
                        </label>
                        <Field
                          id="name_of_proprietor"
                          name="name_of_proprietor"
                          type="text"
                          className="form-control"
                          placeholder="Enter Name of Proprietor/Partner"
                          onChange={handleChange}
                          value={values.name_of_proprietor || ""}
                        />
                        <ErrorMessage
                          name="name_of_proprietor"
                          className="text-danger"
                          component="div"
                        />
                      </div>

                      <div className="col-12 col-sm-6 col-lg-4">
                        <label
                          htmlFor="self_sealing_permission_no"
                          className="form-label"
                          style={{ fontSize: "0.9375rem" }}
                        >
                          Self Sealing Permission No:{" "}
                        </label>
                        <Field
                          id="self_sealing_permission_no"
                          name="self_sealing_permission_no"
                          type="text"
                          className="form-control"
                          placeholder="Enter Self Sealing Permission No."
                          onChange={handleChange}
                          value={values.self_sealing_permission_no || ""}
                        />
                        <ErrorMessage
                          name="self_sealing_permission_no"
                          className="text-danger"
                          component="div"
                        />
                      </div>

                      <div className="col-12 col-sm-6 col-lg-4">
                        <label
                          htmlFor="self_sealing_permission_date"
                          className="form-label"
                          style={{ fontSize: "0.9375rem" }}
                        >
                          Self Sealing Permission Date:{" "}
                        </label>
                        <Field
                          id="self_sealing_permission_date"
                          name="self_sealing_permission_date"
                          type="text"
                          className="form-control"
                          placeholder="Enter Self Sealing Permission Date"
                          onChange={handleChange}
                          value={values.self_sealing_permission_date || ""}
                        />
                        <ErrorMessage
                          name="self_sealing_permission_date"
                          className="text-danger"
                          component="div"
                        />
                      </div>

                      <div className="col-12 col-sm-6 col-lg-4">
                        <label
                          htmlFor="self_sealing_permission_validity"
                          className="form-label"
                          style={{ fontSize: "0.9375rem" }}
                        >
                          Self Sealing Permission Validity:{" "}
                        </label>
                        <Field
                          id="self_sealing_permission_validity"
                          name="self_sealing_permission_validity"
                          type="text"
                          className="form-control"
                          placeholder="Enter Self Sealing Permission Validity"
                          onChange={handleChange}
                          value={values.self_sealing_permission_validity || ""}
                        />
                        <ErrorMessage
                          name="self_sealing_permission_validity"
                          className="text-danger"
                          component="div"
                        />
                      </div>

                      <div className="col-12 col-sm-6 col-lg-4">
                        <label
                          htmlFor="sales_contract_prefix"
                          className="form-label"
                          style={{ fontSize: "0.9375rem" }}
                        >
                          Sales Contract Prefix:{" "}
                        </label>
                        <Field
                          id="sales_contract_prefix"
                          name="sales_contract_prefix"
                          type="text"
                          className="form-control"
                          placeholder="Enter Sales Contract Prefix"
                          onChange={handleChange}
                          value={values.sales_contract_prefix || ""}
                        />
                        <ErrorMessage
                          name="sales_contract_prefix"
                          className="text-danger"
                          component="div"
                        />
                      </div>

                      <div className="col-12 col-sm-6 col-lg-4">
                        <label
                          htmlFor="sales_invoice_prefix"
                          className="form-label"
                          style={{ fontSize: "0.9375rem" }}
                        >
                          Sales Invoice Prefix:{" "}
                        </label>
                        <Field
                          id="sales_invoice_prefix"
                          name="sales_invoice_prefix"
                          type="text"
                          className="form-control"
                          placeholder="Enter Sales Invoice Prefix"
                          onChange={handleChange}
                          value={values.sales_invoice_prefix || ""}
                        />
                        <ErrorMessage
                          name="sales_invoice_prefix"
                          className="text-danger"
                          component="div"
                        />
                      </div>

                      <div className="col-12">
                        <label
                          htmlFor="company_address"
                          className="form-label"
                          style={{ fontSize: "0.9375rem" }}
                        >
                          Company Address:{" "}
                        </label>
                        <Field
                          id="company_address"
                          name="company_address"
                          as="textarea"
                          row={5}
                          className="form-control"
                          placeholder="Enter Company Address"
                          onChange={handleChange}
                          value={values.company_address || ""}
                        />
                        <ErrorMessage
                          name="company_address"
                          className="text-danger"
                          component="div"
                        />
                      </div>
                      <div className="col-12">
                        <button
                          type="submit"
                          className="btn btn-primary"
                          disabled={updateLoading}
                        >
                          {updateLoading && (
                            <span
                              className="spinner-border spinner-border-sm me-2"
                              role="status"
                              aria-hidden="true"
                            ></span>
                          )}
                          Update Settings
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
      {/* )} */}
    </>
  );
};

export default Settings;
