// ReusableEditor.tsx
import React from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

interface TextEditorProps {
  value: string;
  onChange: (value: string) => void;
  placeholder?: string;
  height?: string;
}

const TextEditor: React.FC<TextEditorProps> = ({
  value,
  onChange,
  placeholder,
  height,
}) => {
  return (
    <div className="form-group">
      <ReactQuill
        value={value}
        onChange={onChange}
        placeholder={placeholder || "Type here..."}
        modules={{
          toolbar: [
            [{ header: [1, 2, 3, 4, 5, 6, false] }],
            [{ size: ["small", false, "large", "huge"] }],
            [{ list: "ordered" }, { list: "bullet" }],
            ["bold", "italic", "underline"],
          ],
        }}
        formats={[
          "header",
          "size",
          "list",
          "bold",
          "italic",
          "underline",
          "align",
          "link",
        ]}
        style={{ height: height || "200px" }}
      />
    </div>
  );
};

export default TextEditor;
