import { POST } from "./_config";

export const loginApi = async (body: any) => await POST(`/auth/login`, body);

export const forgetPasswordApi = async (body: any) =>
  POST(`auth/forgot-password`, body);

export const verifyOtpApi = async (body: any) => POST(`auth/verify-otp`, body);

export const resetPasswordApi = async (body: any) =>
  POST(`auth/reset-password`, body);

const AuthService = {
  loginApi,
  forgetPasswordApi,
  verifyOtpApi,
  resetPasswordApi,
};

export default AuthService;
