import { Link, useNavigate } from "react-router-dom";
import { getMe, getPermissions } from "../context/AuthContext";
import { useState } from "react";
import ChangePassword from "../pages/users/ChangePassword";
import Spinner from "./Spinner";

const Navbar = () => {
  const navigate = useNavigate();
  const me = getMe();
  const permissions = getPermissions();

  const [changePasswordId, setChangePasswordId] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const handleNavbarClick = (e: any) => {
    const elements = document.getElementsByClassName("my-html");
    for (let i = 0; i < elements.length; i++) {
      if (elements[i].classList.contains("layout-menu-expanded")) {
        elements[i].classList.remove("layout-menu-expanded");
      } else {
        elements[i].classList.add("layout-menu-expanded");
      }
    }
  };

  const handleLogout = () => {
    setIsLoading(true);
    localStorage.removeItem("token");
    localStorage.removeItem("permissions");
    localStorage.removeItem("me");
    setTimeout(() => {
      setIsLoading(false);
      navigate("/login");
      window.location.reload();
    }, 1000);
  };

  return (
    <>
      {isLoading && <Spinner isFullScreen={true} />}
      <nav
        className="layout-navbar container-xxl navbar navbar-expand-xl navbar-detached align-items-center bg-navbar-theme"
        id="layout-navbar"
      >
        <div
          className="layout-menu-toggle navbar-nav align-items-xl-center me-3 me-xl-0 d-xl-none"
          onClick={(e: any) => handleNavbarClick(e)}
        >
          <Link className="nav-item nav-link px-0 me-xl-4" to="#">
            <i className="ti ti-menu-2 ti-md"></i>
          </Link>
        </div>

        <div
          className="navbar-nav-right d-flex align-items-center"
          id="navbar-collapse"
        >
          <ul className="navbar-nav flex-row align-items-center ms-auto">
            <li className="nav-item navbar-dropdown dropdown-user dropdown">
              <Link
                className="nav-link dropdown-toggle hide-arrow p-0"
                to="#"
                data-bs-toggle="dropdown"
              >
                <div className="avatar avatar-online">
                  <span className="avatar-initial rounded-circle bg-primary">
                    {me?.name?.substring(0, 2) || "EE"}
                  </span>
                </div>
              </Link>
              <ul className="dropdown-menu dropdown-menu-end">
                <li>
                  <Link className="dropdown-item mt-0" to="#">
                    <div className="d-flex align-items-center">
                      <div className="flex-shrink-0 me-2">
                        <div className="avatar avatar-online">
                          <span className="avatar-initial rounded-circle bg-primary">
                            {me?.name?.substring(0, 2) || "EE"}
                          </span>
                        </div>
                      </div>
                      <div className="flex-grow-1">
                        <h6 className="mb-0">{me?.name || "Admin"}</h6>
                        <small className="text-muted">
                          {me?.email || "Admin"}
                        </small>
                      </div>
                    </div>
                  </Link>
                </li>
                <li>
                  <div className="dropdown-divider my-1 mx-n2"></div>
                </li>
                {/* <li>
                  <Link className="dropdown-item" to="#">
                    <i className="ti ti-user me-3 ti-md"></i>
                    <span className="align-middle">My Profile</span>
                  </Link>
                </li> */}
                {permissions.includes("update-user-password") && (
                  <li>
                    <Link
                      className="dropdown-item"
                      to="#"
                      onClick={() => setChangePasswordId(me?.id)}
                      data-bs-toggle="modal"
                      data-bs-target="#changePassword"
                    >
                      <i className="ti ti-lock me-3 ti-md"></i>
                      <span className="align-middle">Change Password</span>
                    </Link>
                  </li>
                )}
                <li>
                  <div className="d-grid px-2 pt-2 pb-1">
                    <Link
                      className="btn btn-sm btn-danger d-flex"
                      to="#"
                      onClick={(e) => {
                        if (isLoading) {
                          e.preventDefault();
                          return;
                        }
                        handleLogout();
                      }}
                    >
                      <small className="align-middle">Logout</small>
                      <i className="ti ti-logout ms-2 ti-14px"></i>
                    </Link>
                  </div>
                </li>
              </ul>
            </li>
          </ul>
        </div>

        <div className="navbar-search-wrapper search-input-wrapper d-none">
          <input
            type="text"
            className="form-control search-input container-xxl border-0"
            placeholder="Search..."
            aria-label="Search..."
          />
          <i className="ti ti-x search-toggler cursor-pointer"></i>
        </div>
      </nav>
      {permissions.includes("update-user-password") && (
        <ChangePassword id={changePasswordId}></ChangePassword>
      )}
    </>
  );
};

export default Navbar;
