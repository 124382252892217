import * as Yup from "yup";
import { useEffect, useState } from "react";
import { createUserApi } from "../../services/_user";
import { Link, useNavigate } from "react-router-dom";
import { getRoleListApi } from "../../services/_role";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { errorToast, successToast } from "../../components/Toast";

const CreateUser = () => {
  const navigate = useNavigate();
  const [roles, setRoles] = useState([]);
  const [submitLoader, setSubmitLoader] = useState<boolean>(false);
  const createUserValidationSchema = Yup.object().shape({
    role_id: Yup.string().required("Role is required"),
    name: Yup.string().required("Name is required"),
    email: Yup.string().required("Email is required"),
    password: Yup.string().required("Password is required"),
    status: Yup.string().required("Status is required"),
  });

  const createUserInitialValues = {
    role_id: "",
    name: "",
    email: "",
    phone_no: "",
    password: "",
    status: undefined,
  };

  const handleSubmit = async (values: any) => {
    setSubmitLoader(true);
    values.status = values.status === "true" ? true : false;
    const result: any = await createUserApi(values);
    if (result.isSuccess) {
      setSubmitLoader(false);
      successToast(result.message);
      navigate("/user-list");
    } else {
      setSubmitLoader(false);
      errorToast(result.message);
    }
    return;
  };

  const getRoles = async () => {
    const result: any = await getRoleListApi();
    if (result?.isSuccess) {
      setRoles(result.data);
    }
  };

  useEffect(() => {
    getRoles();
  }, []);

  return (
    <>
      <div className="card">
        <div className="card-header pb-0">
          <div>
            <h5>Create User</h5>
          </div>
        </div>
        <div className="card-body">
          <Formik
            initialValues={createUserInitialValues}
            validationSchema={createUserValidationSchema}
            onSubmit={handleSubmit}
          >
            {({ handleChange }) => (
              <Form className="dt_adv_search">
                <div className="row">
                  <div className="col-12">
                    <div className="row g-3">
                      <div className="col-12 col-sm-6 col-lg-4">
                        <label
                          htmlFor="name"
                          className="form-label"
                          style={{ fontSize: "0.9375rem" }}
                        >
                          Name: <span className="text-danger">*</span>
                        </label>
                        <Field
                          id="name"
                          name="name"
                          type="text"
                          className="form-control"
                          placeholder="Enter name"
                          onChange={handleChange}
                        />
                        <ErrorMessage
                          name="name"
                          className="text-danger"
                          component="div"
                        />
                      </div>
                      <div className="col-12 col-sm-6 col-lg-4">
                        <label
                          htmlFor="email"
                          className="form-label"
                          style={{ fontSize: "0.9375rem" }}
                        >
                          Email: <span className="text-danger">*</span>
                        </label>
                        <Field
                          id="email"
                          name="email"
                          type="email"
                          className="form-control"
                          placeholder="Enter email"
                          onChange={handleChange}
                        />
                        <ErrorMessage
                          name="email"
                          className="text-danger"
                          component="div"
                        />
                      </div>
                      <div className="col-12 col-sm-6 col-lg-4">
                        <label
                          htmlFor="phone_no"
                          className="form-label"
                          style={{ fontSize: "0.9375rem" }}
                        >
                          Phone No:
                        </label>
                        <Field
                          id="phone_no"
                          name="phone_no"
                          type="text"
                          className="form-control"
                          placeholder="Enter phone no"
                          onChange={handleChange}
                        />
                      </div>
                      <div className="col-12 col-sm-6 col-lg-4">
                        <label
                          htmlFor="password_2"
                          className="form-label"
                          style={{ fontSize: "0.9375rem" }}
                        >
                          Password: <span className="text-danger">*</span>
                        </label>
                        <Field
                          id="password_2"
                          name="password"
                          type="password"
                          className="form-control"
                          placeholder="Enter password"
                          onChange={handleChange}
                        />
                        <ErrorMessage
                          name="password"
                          className="text-danger"
                          component="div"
                        />
                      </div>
                      <div className="col-12 col-sm-6 col-lg-4">
                        <label
                          htmlFor="role_id"
                          className="form-label"
                          style={{ fontSize: "0.9375rem" }}
                        >
                          Role: <span className="text-danger">*</span>
                        </label>
                        <select
                          id="role_id"
                          name="role_id"
                          className="form-select"
                          onChange={handleChange}
                        >
                          <option value="">Select Role</option>
                          {roles?.length &&
                            roles.map((role: any) => (
                              <option key={role.id} value={role.id}>
                                {role.name}
                              </option>
                            ))}
                        </select>
                        <ErrorMessage
                          name="role_id"
                          className="text-danger"
                          component="div"
                        />
                      </div>
                      <div className="col-12 col-sm-6 col-lg-4">
                        <label
                          htmlFor="status"
                          className="form-label"
                          style={{ fontSize: "0.9375rem" }}
                        >
                          Status: <span className="text-danger">*</span>
                        </label>
                        <select
                          id="status"
                          name="status"
                          className="form-select"
                          onChange={handleChange}
                        >
                          <option value="">Select Status</option>
                          <option value="true">Active</option>
                          <option value="false">Inactive</option>
                        </select>
                        <ErrorMessage
                          name="status"
                          className="text-danger"
                          component="div"
                        />
                      </div>
                      <div className="col-12">
                        <button
                          type="submit"
                          className="btn btn-primary"
                          disabled={submitLoader}
                        >
                          {submitLoader && (
                            <span
                              className="spinner-border spinner-border-sm me-2"
                              role="status"
                              aria-hidden="true"
                            ></span>
                          )}
                          Submit
                        </button>
                        <Link
                          to={"/user-list"}
                          type="button"
                          className="btn btn-label-github ms-5"
                        >
                          Back
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </>
  );
};

export default CreateUser;
