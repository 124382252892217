/* eslint-disable react-hooks/exhaustive-deps */
import moment from "moment";
import { useEffect, useRef, useState } from "react";
import { CSVLink } from "react-csv";
import { Link } from "react-router-dom";
import { DateRangePicker } from "rsuite";
import { CURRENCY } from "../../components/Constants";
import Spinner from "../../theme/Spinner";
import Pagination from "../../components/Pagination";
import { PaymentStatus, TransactionTypes } from "../../components/Enums";
import { brokerWisePaymentReportApi } from "../../services/_report";
import { getAllSalesContractApi } from "../../services/_sales";
import { getAllBrokerApi } from "../../services/_broker";
import {
  formatAmount,
  formatDate,
  formatString,
} from "../../components/Helper";

interface CustomCSVLink extends HTMLAnchorElement {
  link: HTMLAnchorElement;
}

const BrokerWisePaymentReport = () => {
  const [record, setRecord] = useState<any[]>([]);
  const [brokerList, setBrokerList] = useState<any[]>([]);
  const [saleList, setSaleList] = useState<any[]>([]);
  const [filteredSales, setFilteredSales] = useState<any[]>([]);

  const [filterBroker, setFilterBroker] = useState<string>("");
  const [filterSale, setFilterSale] = useState<string>("");
  const [dateRange, setDateRange] = useState(null);
  const [page, setPage] = useState<number>(1);
  const [perPage] = useState<number>(10);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [totalRecords, setTotalRecords] = useState<number>(0);
  const [applyFilter, setApplyFilter] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [displayFilter, setDisplayFilter] = useState(false);
  const [totalCreditAmountInr, setTotalCreditAmountInr] = useState(0);
  const [totalDebitAmountInr, setTotalDebitAmountInr] = useState(0);
  const [totalAmountInr, setTotalAmountInr] = useState(0);
  const [totalCreditAmountUsd, setTotalCreditAmountUsd] = useState(0);
  const [totalDebitAmountUsd, setTotalDebitAmountUsd] = useState(0);
  const [totalAmountUsd, setTotalAmountUsd] = useState(0);
  const csvLinkRef = useRef<CustomCSVLink>(null);
  const [exportData, setExportData] = useState([]);
  const [sortKey, setSortKey] = useState<string>("");
  const [sortType, setSortType] = useState<string>("");

  const toggleFilter = async () => {
    setDisplayFilter(!displayFilter);
  };

  const brokerNameList = async () => {
    try {
      const result: any = await getAllBrokerApi();
      setBrokerList(result?.data);
    } catch (error) {
      console.error("Error fetching BrokerName list", error);
    }
  };

  const getSales = async () => {
    try {
      const result: any = await getAllSalesContractApi();
      setSaleList(result?.data);
      setFilteredSales(result?.data);
    } catch (error) {
      console.error("Error fetching SaleContract list", error);
    }
  };

  const getBrokerWisePaymentReportList = async (
    page: number,
    perPage: number,
    isFilter?: boolean,
    sortKey?: string,
    sortType?: string
  ) => {
    setLoading(true);
    let query = `page=${page}&per_page=${perPage}`;
    if (isFilter) {
      if (filterBroker) {
        query += `&broker_id=${filterBroker}`;
      }
      if (filterSale) {
        query += `&sales_contract_id=${filterSale}`;
      }
      if (dateRange) {
        query += `&from_date=${moment(dateRange[0]).format("YYYY-MM-DD")}`;
        query += `&to_date=${moment(dateRange[1]).format("YYYY-MM-DD")}`;
      }
      if (sortKey && sortType) {
        query += `&sort_key=${sortKey}&sort_type=${sortType}`;
      }
    }
    const result: any = await brokerWisePaymentReportApi(query);

    if (result.isSuccess) {
      const totalPagesCount = Math.ceil(result.data.total_records / perPage);
      setRecord(result?.data.records);
      setTotalPages(totalPagesCount);
      setTotalRecords(result.data.total_records);
      setTotalCreditAmountInr(result?.data?.total_credit_inr || 0);
      setTotalDebitAmountInr(result?.data?.total_debit_inr || 0);
      setTotalAmountInr(result?.data?.total_amount_inr || 0);
      setTotalCreditAmountUsd(result?.data?.total_credit_usd || 0);
      setTotalDebitAmountUsd(result?.data?.total_debit_usd || 0);
      setTotalAmountUsd(result?.data?.total_amount_usd || 0);
    }
    setLoading(false);
  };

  const filterList = async () => {
    setApplyFilter(true);
    getBrokerWisePaymentReportList(page, perPage, true);
  };

  const resetList = async () => {
    setPage(1);
    setApplyFilter(false);
    setFilterBroker("");
    setFilterSale("");
    setDateRange(null);
    setFilteredSales(saleList);
    getBrokerWisePaymentReportList(page, perPage);
  };

  const prevButton = async () => {
    if (page > 1) {
      setPage(page - 1);
    }
  };

  const nextButton = async () => {
    if (totalPages > page) {
      setPage(page + 1);
    }
  };

  const getFileName = () => {
    const filerName = "broker-wise-payment-report";
    return `${filerName}-${moment().format("MM-DD-YYYY-HH-mm-ss")}.csv`;
  };

  const exportBrokerRecords = async () => {
    setExportData([]);
    let csvHeader: any;
    let csvData: any;
    let csvFooter: any;

    let query = `export=true`;
    if (filterBroker) {
      query += `&broker_id=${filterBroker}`;
    }
    if (filterSale) {
      query += `&sales_contract_id=${filterSale}`;
    }
    if (dateRange) {
      query += `&from_date=${moment(dateRange[0]).format("YYYY-MM-DD")}`;
      query += `&to_date=${moment(dateRange[1]).format("YYYY-MM-DD")}`;
    }
    if (sortKey && sortType) {
      query += `&sort_key=${sortKey}&sort_type=${sortType}`;
    }

    const result: any = await brokerWisePaymentReportApi(query);
    if (result.isSuccess) {
      if (result.data.records) {
        csvHeader = [
          "Sr No.",
          "Broker",
          "Sales Contract No",
          `Amount (${CURRENCY.INR.SYMBOL})`,
          `Exchange Rate (${CURRENCY.INR.SYMBOL})`,
          `Exchange Amount (${CURRENCY.USD.SYMBOL})`,
          "Transaction Type",
          "Payment Method",
          "Transaction No",
          "Payment Status",
          "Remark",
          "Transaction Date",
        ];
        csvData = result.data.records.map((item: any, index: number) => [
          (index + 1).toString(),
          item?.broker?.name || "",
          item?.sales_contract?.contract_no || "",
          `${CURRENCY.INR.SYMBOL}${formatAmount(item.amount)}`,
          `${CURRENCY.INR.SYMBOL}${formatAmount(item.exchange_rate)}`,
          `${CURRENCY.USD.SYMBOL}${formatAmount(item.exchange_amount)}`,
          item?.transaction_type || "",
          item?.payment_method.replace("_", " ") || "",
          item?.transaction_no || "",
          formatString(item?.payment_status) || "",
          item?.remark || "",
          formatDate(item.transaction_date),
        ]);
        csvFooter = [
          [
            "",
            "",
            "Total Credit :",
            `${CURRENCY.INR.SYMBOL}${formatAmount(totalCreditAmountInr)}`,
            "",
            `${CURRENCY.USD.SYMBOL}${formatAmount(totalCreditAmountUsd)}`,
            "",
            "",
            "",
            "",
            "",
          ],
          [
            "",
            "",
            "Total Debit :",
            `${CURRENCY.INR.SYMBOL}${formatAmount(totalDebitAmountInr)}`,
            "",
            `${CURRENCY.USD.SYMBOL}${formatAmount(totalDebitAmountUsd)}`,
            "",
            "",
            "",
            "",
            "",
          ],
          [
            "",
            "",
            totalAmountInr > 0 ? "Profit :" : "Loss :",
            `${CURRENCY.INR.SYMBOL}${formatAmount(totalAmountInr)}`,
            "",
            `${CURRENCY.USD.SYMBOL}${formatAmount(totalAmountUsd)}`,
            "",
            "",
            "",
            "",
            "",
          ],
        ];

        const exportData: any = [csvHeader, ...csvData, ...csvFooter];
        setExportData(exportData);

        if (csvLinkRef.current?.link) {
          setTimeout(() => {
            csvLinkRef.current!.link.click();
          }, 0);
        }
      }
    }
  };

  const sortTable = async (key: string) => {
    let type = sortType === "asc" ? "desc" : "asc";
    if (sortKey !== key) {
      type = "asc";
    }
    setSortKey(key);
    setSortType(type);
    await getBrokerWisePaymentReportList(page, perPage, true, key, type);
  };

  const filterSuggestions = (e: any) => {
    const name = e.target.name;
    const value = e.target.value;

    if (name === "broker_id") {
      const saleData = value
        ? saleList.filter(
            (sale_contract: any) => sale_contract.broker_id === value
          )
        : saleList;
      setFilterSale("");
      setFilteredSales(saleData);
    }
  };

  useEffect(() => {
    brokerNameList();
    getSales();
  }, []);

  useEffect(() => {
    if (page && perPage) {
      getBrokerWisePaymentReportList(page, perPage, applyFilter);
    }
  }, [page, perPage, applyFilter]);

  return (
    <div className="card">
      <div className="card-header pb-0 d-flex justify-content-between">
        <div>
          <h5>Broker Wise Payment Report</h5>
        </div>
        <div>
          <Link
            to="#"
            type="button"
            className="btn btn-label-github"
            style={{ marginRight: "15px" }}
            onClick={toggleFilter}
          >
            <span>
              <i className="ti ti-filter me-sm-1"></i>{" "}
              <span className="d-none d-sm-inline-block">Filter</span>
            </span>
          </Link>
          <Link
            to={"#"}
            className="btn btn-primary waves-effect waves-light"
            type="button"
            onClick={() => {
              exportBrokerRecords();
            }}
          >
            <span>
              <i className="ti ti-download me-sm-1"></i>{" "}
              <span className="d-none d-sm-inline-block">Download Excel</span>
            </span>
          </Link>
          <CSVLink
            data={exportData}
            filename={getFileName()}
            className="d-none"
            ref={csvLinkRef as any}
          >
            <span>
              <i className="ti ti-download me-sm-1"></i>{" "}
              <span className="d-none d-sm-inline-block">Download Excel</span>
            </span>
          </CSVLink>
        </div>
      </div>

      {displayFilter && (
        <div className="card-body">
          <form className="dt_adv_search">
            <div className="row">
              <div className="col-12">
                <div className="row g-3">
                  <div className="col-12 col-sm-6 col-lg-3">
                    <label
                      htmlFor="date"
                      className="form-label"
                      style={{ fontSize: "0.9375rem" }}
                    >
                      Transaction Date:
                    </label>
                    <DateRangePicker
                      format="dd-MM-yyyy"
                      className="form-control"
                      placeholder="From Date ~ To Date"
                      value={dateRange}
                      onChange={(values: any) => {
                        setDateRange(values);
                      }}
                    />
                  </div>
                  <div className="col-12 col-sm-6 col-lg-3">
                    <label
                      htmlFor="broker_id"
                      className="form-label"
                      style={{ fontSize: "0.9375rem" }}
                    >
                      Broker:
                    </label>
                    <select
                      className="form-select"
                      name="broker_id"
                      id="broker_id"
                      value={filterBroker}
                      onChange={(e) => {
                        setFilterBroker(e.target.value);
                        filterSuggestions(e);
                      }}
                    >
                      <option value="">Select Broker</option>
                      {brokerList.map((broker) => (
                        <option value={broker.id} key={broker.id}>
                          {broker.name}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="col-12 col-sm-6 col-lg-3">
                    <label
                      htmlFor="sale"
                      className="form-label"
                      style={{ fontSize: "0.9375rem" }}
                    >
                      Sales Contract No:
                    </label>
                    <select
                      className="form-select"
                      id="sale"
                      value={filterSale}
                      onChange={(e) => {
                        setFilterSale(e.target.value);
                      }}
                    >
                      <option value="">Select Sales Contract No</option>
                      {filteredSales.map((sale: any) => (
                        <option value={sale.id} key={sale.id}>
                          {sale.contract_no}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="col-12">
                    <button
                      type="button"
                      className="btn btn-primary"
                      onClick={filterList}
                    >
                      Filter
                    </button>
                    <button
                      type="button"
                      className="btn btn-label-github ms-5"
                      onClick={resetList}
                    >
                      Reset
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      )}

      <div className="card-datatable table-responsive">
        {loading && <Spinner isFullScreen={false} />}
        <table className="dt-advanced-search table table-nowrap">
          <thead>
            <tr>
              <th>#</th>
              <th
                className="cursor-pointer"
                onClick={() => sortTable("broker")}
              >
                Broker
                <span>
                  <i className="ti ti-arrows-down-up sorting-icon"></i>
                </span>
              </th>
              <th
                className="cursor-pointer"
                onClick={() => sortTable("contract_no")}
              >
                Sales Contract No
                <span>
                  <i className="ti ti-arrows-down-up sorting-icon"></i>
                </span>
              </th>
              <th
                className="cursor-pointer"
                onClick={() => sortTable("amount")}
              >
                Amount ({CURRENCY.INR.SYMBOL})
                <span>
                  <i className="ti ti-arrows-down-up sorting-icon"></i>
                </span>
              </th>
              <th
                className="cursor-pointer"
                onClick={() => sortTable("exchange_rate")}
              >
                Exchange Rate ({CURRENCY.INR.SYMBOL})
                <span>
                  <i className="ti ti-arrows-down-up sorting-icon"></i>
                </span>
              </th>
              <th
                className="cursor-pointer"
                onClick={() => sortTable("exchange_amount")}
              >
                Exchange Amount ({CURRENCY.USD.SYMBOL})
                <span>
                  <i className="ti ti-arrows-down-up sorting-icon"></i>
                </span>
              </th>
              <th
                className="cursor-pointer"
                onClick={() => sortTable("transaction_type")}
              >
                Transaction Type
                <span>
                  <i className="ti ti-arrows-down-up sorting-icon"></i>
                </span>
              </th>
              <th
                className="cursor-pointer"
                onClick={() => sortTable("payment_method")}
              >
                Payment Method
                <span>
                  <i className="ti ti-arrows-down-up sorting-icon"></i>
                </span>
              </th>
              <th
                className="cursor-pointer"
                onClick={() => sortTable("transaction_no")}
              >
                Transaction No
                <span>
                  <i className="ti ti-arrows-down-up sorting-icon"></i>
                </span>
              </th>
              <th
                className="cursor-pointer"
                onClick={() => sortTable("payment_status")}
              >
                Payment Status
                <span>
                  <i className="ti ti-arrows-down-up sorting-icon"></i>
                </span>
              </th>
              <th
                className="cursor-pointer"
                onClick={() => sortTable("remark")}
              >
                Remark
                <span>
                  <i className="ti ti-arrows-down-up sorting-icon"></i>
                </span>
              </th>
              <th
                className="cursor-pointer"
                onClick={() => sortTable("transaction_date")}
              >
                Transaction Date
                <span>
                  <i className="ti ti-arrows-down-up sorting-icon"></i>
                </span>
              </th>
            </tr>
          </thead>
          <tbody>
            {record.length > 0 ? (
              <>
                {record.map((item: any, i: number) => {
                  let transactionTypeClass = "";
                  if (item.transaction_type === TransactionTypes.CREDIT) {
                    transactionTypeClass = `bg-label-success`;
                  } else {
                    transactionTypeClass = `bg-label-danger`;
                  }
                  let statusClass = "";
                  if (item.payment_status === PaymentStatus.PENDING) {
                    statusClass = `bg-label-warning`;
                  } else if (item.payment_status === PaymentStatus.COMPLETED) {
                    statusClass = `bg-label-success`;
                  } else if (item.payment_status === PaymentStatus.ADVANCE) {
                    statusClass = `bg-label-info`;
                  } else {
                    statusClass = `bg-label-secondary`;
                  }
                  return (
                    <tr key={item.id}>
                      <td>{i + 1}</td>
                      <td>{item?.broker?.name || ""}</td>
                      <td>{item?.sales_contract?.contract_no || ""}</td>
                      <td>
                        {CURRENCY.INR.SYMBOL}
                        {formatAmount(item.amount)}
                      </td>
                      <td>
                        {CURRENCY.INR.SYMBOL}
                        {formatAmount(item.exchange_rate)}
                      </td>
                      <td>
                        {CURRENCY.USD.SYMBOL}
                        {formatAmount(item.exchange_amount)}
                      </td>
                      <td>
                        <span className={`badge ${transactionTypeClass} me-1`}>
                          {item.transaction_type}
                        </span>
                      </td>
                      <td>{item?.payment_method.replace("_", " ") || ""}</td>
                      <td>{item?.transaction_no || ""}</td>
                      <td>
                        <td>
                          <span className={`badge ${statusClass} me-1`}>
                            {formatString(item.payment_status)}
                          </span>
                        </td>
                      </td>
                      <td>{item?.remark || ""}</td>
                      <td>{formatDate(item.transaction_date)}</td>
                    </tr>
                  );
                })}
              </>
            ) : (
              <tr>
                <td colSpan={13} className="text-center">
                  No records found
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>

      <div className="row mt-3 mx-0 align-items-center">
        <div className="col-sm-4 text-sm-center">
          <div>
            Total Credit:{" "}
            <span
              className="fw-bold"
              style={{ fontWeight: 500, color: "#444050" }}
            >
              {CURRENCY.USD.SYMBOL}
              {formatAmount(totalCreditAmountUsd)}
            </span>
          </div>
        </div>
        <div className="col-sm-4 text-sm-center">
          <div>
            Total Debit:{" "}
            <span
              className="fw-bold"
              style={{ fontWeight: 500, color: "#444050" }}
            >
              {CURRENCY.USD.SYMBOL}
              {formatAmount(totalDebitAmountUsd)}
            </span>
          </div>
        </div>
        <div className="col-sm-4">
          <div className="d-flex justify-content-sm-center">
            <p
              className="p-0 m-0"
              style={{
                color: totalAmountUsd > 0 ? "green" : "red",
              }}
            >
              {totalAmountUsd > 0 ? "Profit:" : "Loss:"}
            </p>
            &nbsp;
            <span
              className="fw-bold"
              style={{ fontWeight: 500, color: "#444050" }}
            >
              {CURRENCY.USD.SYMBOL}
              {formatAmount(totalAmountUsd)}
            </span>
          </div>
        </div>
      </div>

      <div className="row mx-0 mt-1 align-items-center">
        <div className="col-sm-4 text-sm-center">
          <div>
            Total Credit:{" "}
            <span
              className="fw-bold"
              style={{ fontWeight: 500, color: "#444050" }}
            >
              {CURRENCY.INR.SYMBOL}
              {formatAmount(totalCreditAmountInr)}
            </span>
          </div>
        </div>
        <div className="col-sm-4 text-sm-center">
          <div>
            Total Debit:{" "}
            <span
              className="fw-bold"
              style={{ fontWeight: 500, color: "#444050" }}
            >
              {CURRENCY.INR.SYMBOL}
              {formatAmount(totalDebitAmountInr)}
            </span>
          </div>
        </div>
        <div className="col-sm-4">
          <div className="d-flex justify-content-sm-center">
            <p
              className="p-0 m-0"
              style={{
                color: totalAmountInr > 0 ? "green" : "red",
              }}
            >
              {totalAmountInr > 0 ? "Profit:" : "Loss:"}
            </p>
            &nbsp;
            <span
              className="fw-bold"
              style={{ fontWeight: 500, color: "#444050" }}
            >
              {CURRENCY.INR.SYMBOL}
              {formatAmount(totalAmountInr)}
            </span>
          </div>
        </div>
      </div>

      <Pagination
        page={page}
        perPage={perPage}
        totalRecords={totalRecords}
        totalPages={totalPages}
        records={record}
        prevButton={prevButton}
        nextButton={nextButton}
      />
    </div>
  );
};

export default BrokerWisePaymentReport;
