import { GET, POST, PUT } from "./_config";

export const portListApi = async (query: string) =>
  await GET(`/port?${query || ""}`, true);

export const getAllPortsApi = async () => await GET(`/port/list`, true);

export const createPortApi = async (body: any) =>
  await POST(`/port`, body, true);

export const getPortByIdApi = async (portId: string) =>
  await GET(`/port/id/${portId}`, true);

export const updatePortApi = async (portId: string, body: any) =>
  await PUT(`/port?id=${portId}`, body, true);

export const updatePortStatusApi = async (body: any) =>
  await POST(`/port/update-status`, body, true);

const PortService = {
  portListApi,
  getAllPortsApi,
  createPortApi,
  getPortByIdApi,
  updatePortApi,
  updatePortStatusApi,
};

export default PortService;
