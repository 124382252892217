import { DELETE, GET, POST, PUT } from "./_config";

export const purchaseListApi = async (query: string) =>
  await GET(`/purchase?${query || ""}`, true);

export const getAllPurchasesApi = async (query?: string) =>
  await GET(`/purchase/list?${query || ""}`, true);

export const createPurchaseApi = async (body: any) =>
  await POST(`/purchase`, body, true);

export const getPurchaseByIdApi = async (purchaseId: string) =>
  await GET(`/purchase/${purchaseId}`, true);

export const updatePurchaseApi = async (purchaseId: string, body: any) =>
  await PUT(`/purchase?id=${purchaseId}`, body, true);

export const deletePurchaseApi = async (purchaseId: string) =>
  await DELETE(`/purchase/${purchaseId}`, true);

const PurchaseService = {
  purchaseListApi,
  getAllPurchasesApi,
  createPurchaseApi,
  getPurchaseByIdApi,
  updatePurchaseApi,
  deletePurchaseApi,
};

export default PurchaseService;
