/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import {
  getAllSalesInvoiceApi,
  getAllSalesContractApi,
} from "../../services/_sales";
import {
  formatDate,
  formatAmount,
  formatString,
} from "../../components/Helper";
import moment from "moment";
import { CSVLink } from "react-csv";
import { Link } from "react-router-dom";
import { DateRangePicker } from "rsuite";
import Spinner from "../../theme/Spinner";
import { useEffect, useRef, useState } from "react";
import Pagination from "../../components/Pagination";
import { CURRENCY } from "../../components/Constants";
import { SalesInvoiceStatus } from "../../components/Enums";
import { getBuyerNameListApi } from "../../services/_buyer";
import { buyerWiseSalesReportApi } from "../../services/_report";

interface CustomCSVLink extends HTMLAnchorElement {
  link: HTMLAnchorElement;
}

const BuyerWiseSalesReport = () => {
  const [records, setRecords] = useState<any[]>([]);
  const [buyerList, setBuyerList] = useState<any[]>([]);
  const [saleList, setSaleList] = useState<any[]>([]);
  const [saleOrderList, setSaleOrderList] = useState<any[]>([]);
  const [filteredSales, setFilteredSales] = useState<any[]>([]);
  const [filteredSalesOrders, setFilteredSalesOrders] = useState<any[]>([]);
  const [dateRange, setDateRange] = useState(null);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [totalRecords, setTotalRecords] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);
  const [applyFilter, setApplyFilter] = useState<boolean>(false);
  const [filterBuyer, setFilterBuyer] = useState<string>("");
  const [filterSale, setFilterSale] = useState<string>("");
  const [filterSaleOrder, setFilterSaleOrder] = useState<string>("");
  const [page, setPage] = useState<number>(1);
  const [perPage] = useState<number>(10);
  const [displayFilter, setDisplayFilter] = useState(false);
  const [totalPrice, setTotalPrice] = useState(0);
  const [totalQuantity, setTotalQuantity] = useState(0);
  const [totalAmount, setTotalAmount] = useState(0);
  const csvLinkRef = useRef<CustomCSVLink>(null);
  const [exportData, setExportData] = useState([]);
  const [sortKey, setSortKey] = useState<string>("");
  const [sortType, setSortType] = useState<string>("");

  const toggleFilter = async () => {
    setDisplayFilter(!displayFilter);
  };
  const fetchBuyerNameList = async () => {
    try {
      const result: any = await getBuyerNameListApi();
      setBuyerList(result?.data);
    } catch (error) {
      console.error("Error fetching Buyer list", error);
    }
  };

  const fetchSaleList = async () => {
    try {
      const result: any = await getAllSalesContractApi();
      setSaleList(result?.data);
      setFilteredSales(result?.data);
    } catch (error) {
      console.error("Error fetching Sale list", error);
    }
  };

  const fetchSaleOrderList = async () => {
    try {
      const result: any = await getAllSalesInvoiceApi();
      setSaleOrderList(result?.data);
      setFilteredSalesOrders(result?.data);
    } catch (error) {
      console.error("Error fetching SaleOrder list", error);
    }
  };

  const getSaleReportList = async (
    page: number,
    perPage: number,
    isFilter?: boolean,
    sortKey?: string,
    sortType?: string
  ) => {
    setLoading(true);
    let query = `page=${page}&per_page=${perPage}`;
    if (isFilter) {
      if (filterBuyer) {
        query += `&buyer_id=${filterBuyer}`;
      }
      if (filterSale) {
        query += `&sales_contract_id=${filterSale}`;
      }
      if (filterSaleOrder) {
        query += `&sales_invoice_id=${filterSaleOrder}`;
      }
      if (dateRange) {
        query += `&from_date=${moment(dateRange[0]).format("YYYY-MM-DD")}`;
        query += `&to_date=${moment(dateRange[1]).format("YYYY-MM-DD")}`;
      }
      if (sortKey && sortType) {
        query += `&sort_key=${sortKey}&sort_type=${sortType}`;
      }
    }
    const result: any = await buyerWiseSalesReportApi(query);
    if (result.isSuccess) {
      const totalPagesCount = Math.ceil(result.data.total_records / perPage);
      setRecords(result?.data.records);
      setTotalPages(totalPagesCount);
      setTotalRecords(result.data.total_records);
      setTotalPrice(result.data.total_price);
      setTotalQuantity(result.data.total_quantity);
      setTotalAmount(result.data.total_amount);
    }
    setLoading(false);
  };

  const filterList = async () => {
    setApplyFilter(true);
    getSaleReportList(page, perPage, true);
  };

  const resetList = async () => {
    setPage(1);
    setApplyFilter(false);
    setFilterBuyer("");
    setFilterSale("");
    setFilterSaleOrder("");
    setDateRange(null);
    setFilteredSales(saleList);
    setFilteredSalesOrders(saleOrderList);
    getSaleReportList(page, perPage);
  };
  const prevButton = async () => {
    if (page > 1) {
      setPage(page - 1);
    }
  };

  const nextButton = async () => {
    if (totalPages > page) {
      setPage(page + 1);
    }
  };

  const getFileName = () => {
    const filerName = "buyer-wise-sales-report";
    return `${filerName}-${moment().format("MM-DD-YYYY-HH-mm-ss")}.csv`;
  };

  const exportSaleRecords = async () => {
    setExportData([]);
    let csvHeader: any;
    let csvData: any;
    let csvFooter: any;

    let query = `export=true`;
    if (filterBuyer) {
      query += `&buyer_id=${filterBuyer}`;
    }
    if (filterSale) {
      query += `&sales_contract_id=${filterSale}`;
    }
    if (filterSaleOrder) {
      query += `&sales_invoice_id=${filterSaleOrder}`;
    }
    if (dateRange) {
      query += `&from_date=${moment(dateRange[0]).format("YYYY-MM-DD")}`;
      query += `&to_date=${moment(dateRange[1]).format("YYYY-MM-DD")}`;
    }
    if (sortKey && sortType) {
      query += `&sort_key=${sortKey}&sort_type=${sortType}`;
    }

    const result: any = await buyerWiseSalesReportApi(query);
    if (result.isSuccess) {
      if (result.data.records) {
        csvHeader = [
          "Sr No.",
          "Buyer",
          "Sales Contract No",
          "Sales Invoice No",
          "Price",
          "Quantity",
          "Unit",
          "Total Amount",
          "Sales Invoice Status",
          "Sales Invoice Date",
        ];
        csvData = result.data.records.map((item: any, index: number) => [
          (index + 1).toString(),
          item?.sales_contract?.buyer?.name || "",
          item?.sales_contract?.contract_no || "",
          item?.invoice_no || "",
          `${CURRENCY.USD.SYMBOL}${formatAmount(item.price)}`,
          item?.quantity || "",
          item?.quantity_unit?.name || "",
          `${CURRENCY.USD.SYMBOL}${formatAmount(item.total_amount)}`,
          formatString(item?.status) || "",
          formatDate(item.invoice_date),
        ]);
        csvFooter = [
          "",
          "",
          "",
          "",
          `${CURRENCY.USD.SYMBOL}${formatAmount(totalPrice)}`,
          totalQuantity,
          "",
          `${CURRENCY.USD.SYMBOL}${formatAmount(totalAmount)}`,
          "",
          "",
        ];

        const exportData: any = [csvHeader, ...csvData, csvFooter];
        setExportData(exportData);

        if (csvLinkRef.current?.link) {
          setTimeout(() => {
            csvLinkRef.current!.link.click();
          }, 0);
        }
      }
    }
  };

  const sortTable = async (key: string) => {
    let type = sortType === "asc" ? "desc" : "asc";
    if (sortKey !== key) {
      type = "asc";
    }
    setSortKey(key);
    setSortType(type);
    await getSaleReportList(page, perPage, true, key, type);
  };

  const filterSuggestions = (e: any) => {
    const name = e.target.name;
    const value = e.target.value;

    if (name === "buyer_id") {
      const saleData = value
        ? saleList.filter(
            (sale_contract: any) => sale_contract.buyer_id === value
          )
        : saleList;

      const saleOrderData = value
        ? saleOrderList.filter(
            (sale_order: any) => sale_order.buyer_id === value
          )
        : saleOrderList;

      setFilterSale("");
      setFilterSaleOrder("");
      setFilteredSales(saleData);
      setFilteredSalesOrders(saleOrderData);
    }

    if (name === "sales_contract_id") {
      const newData = value
        ? saleOrderList.filter(
            (sale_order: any) => sale_order.sales_contract_id === value
          )
        : saleOrderList;

      setFilterSaleOrder("");
      setFilteredSalesOrders(newData);
    }
  };

  useEffect(() => {
    fetchBuyerNameList();
    fetchSaleList();
    fetchSaleOrderList();
  }, []);

  useEffect(() => {
    if (page && perPage) {
      getSaleReportList(page, perPage, applyFilter);
    }
  }, [page, perPage, applyFilter]);

  return (
    <>
      <div className="card">
        <div className="card-header pb-0 d-flex justify-content-between">
          <div>
            <h5>Buyer Wise Sales Report</h5>
          </div>
          <div>
            <Link
              to="#"
              type="button"
              className="btn btn-label-github"
              style={{ marginRight: "15px" }}
              onClick={toggleFilter}
            >
              <span>
                <i className="ti ti-filter me-sm-1"></i>{" "}
                <span className="d-none d-sm-inline-block">Filter</span>
              </span>
            </Link>
            <Link
              to={"#"}
              className="btn btn-primary waves-effect waves-light"
              type="button"
              onClick={() => {
                exportSaleRecords();
              }}
            >
              <span>
                <i className="ti ti-download me-sm-1"></i>{" "}
                <span className="d-none d-sm-inline-block">Download Excel</span>
              </span>
            </Link>
            <CSVLink
              data={exportData}
              filename={getFileName()}
              className="d-none"
              ref={csvLinkRef as any}
            >
              <span>
                <i className="ti ti-download me-sm-1"></i>{" "}
                <span className="d-none d-sm-inline-block">Download Excel</span>
              </span>
            </CSVLink>
          </div>
        </div>

        {displayFilter && (
          <div className="card-body">
            <form className="dt_adv_search">
              <div className="row">
                <div className="col-12">
                  <div className="row g-3">
                    <div className="col-12 col-sm-6 col-lg-3">
                      <label
                        htmlFor="date"
                        className="form-label"
                        style={{ fontSize: "0.9375rem" }}
                      >
                        Sales Invoice Date:
                      </label>
                      <DateRangePicker
                        format="dd-MM-yyyy"
                        className="form-control"
                        placeholder="From Date ~ To Date"
                        value={dateRange}
                        onChange={(values: any) => {
                          setDateRange(values);
                        }}
                      />
                    </div>
                    <div className="col-12 col-sm-6 col-lg-3">
                      <label
                        htmlFor="buyer_id"
                        className="form-label"
                        style={{ fontSize: "0.9375rem" }}
                      >
                        Buyer:
                      </label>
                      <select
                        className="form-select"
                        id="buyer_id"
                        name="buyer_id"
                        value={filterBuyer}
                        onChange={(e) => {
                          setFilterBuyer(e.target.value);
                          filterSuggestions(e);
                        }}
                      >
                        <option value="">Select Buyer</option>
                        {buyerList.map((buyer) => (
                          <option value={buyer.id} key={buyer.id}>
                            {buyer.name}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="col-12 col-sm-6 col-lg-3">
                      <label
                        htmlFor="sale"
                        className="form-label"
                        style={{ fontSize: "0.9375rem" }}
                      >
                        Sales Contract No:
                      </label>
                      <select
                        className="form-select"
                        id="sale"
                        name="sales_contract_id"
                        value={filterSale}
                        onChange={(e) => {
                          setFilterSale(e.target.value);
                          filterSuggestions(e);
                        }}
                      >
                        <option value="">Select Sales Contract No</option>
                        {filteredSales.map((sale: any) => (
                          <option value={sale.id} key={sale.id}>
                            {sale.contract_no}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="col-12 col-sm-6 col-lg-3">
                      <label
                        htmlFor="sale_order"
                        className="form-label"
                        style={{ fontSize: "0.9375rem" }}
                      >
                        Sales Invoice No:
                      </label>
                      <select
                        className="form-select"
                        name="sale_orders_id"
                        id="sale_order"
                        value={filterSaleOrder}
                        onChange={(e) => {
                          setFilterSaleOrder(e.target.value);
                        }}
                      >
                        <option value="">Select Sales Invoice No</option>
                        {filteredSalesOrders.map((order: any) => (
                          <option value={order.id} key={order.id}>
                            {order.invoice_no}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="col-12">
                      <button
                        type="button"
                        className="btn btn-primary"
                        onClick={filterList}
                      >
                        Filter
                      </button>
                      <button
                        type="button"
                        className="btn btn-label-github ms-5"
                        onClick={resetList}
                      >
                        Reset
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        )}

        <div className="card-datatable table-responsive">
          {loading && <Spinner isFullScreen={false} />}
          <table className="dt-advanced-search table table-nowrap">
            <thead>
              <tr>
                <th>#</th>
                <th
                  className="cursor-pointer"
                  onClick={() => sortTable("buyer")}
                >
                  Buyer
                  <span>
                    <i className="ti ti-arrows-down-up sorting-icon"></i>
                  </span>
                </th>
                <th
                  className="cursor-pointer"
                  onClick={() => sortTable("contract_no")}
                >
                  Sales Contract No
                  <span>
                    <i className="ti ti-arrows-down-up sorting-icon"></i>
                  </span>
                </th>
                <th
                  className="cursor-pointer"
                  onClick={() => sortTable("invoice_no")}
                >
                  Sales Invoice No
                  <span>
                    <i className="ti ti-arrows-down-up sorting-icon"></i>
                  </span>
                </th>
                <th
                  className="cursor-pointer"
                  onClick={() => sortTable("price")}
                >
                  Price
                  <span>
                    <i className="ti ti-arrows-down-up sorting-icon"></i>
                  </span>
                </th>
                <th
                  className="cursor-pointer"
                  onClick={() => sortTable("quantity")}
                >
                  Quantity
                  <span>
                    <i className="ti ti-arrows-down-up sorting-icon"></i>
                  </span>
                </th>
                <th
                  className="cursor-pointer"
                  onClick={() => sortTable("quantity_unit")}
                >
                  Unit
                  <span>
                    <i className="ti ti-arrows-down-up sorting-icon"></i>
                  </span>
                </th>
                <th
                  className="cursor-pointer"
                  onClick={() => sortTable("total_amount")}
                >
                  Total Amount
                  <span>
                    <i className="ti ti-arrows-down-up sorting-icon"></i>
                  </span>
                </th>
                <th
                  className="cursor-pointer"
                  onClick={() => sortTable("status")}
                >
                  Sales Invoice Status
                  <span>
                    <i className="ti ti-arrows-down-up sorting-icon"></i>
                  </span>
                </th>
                <th
                  className="cursor-pointer"
                  onClick={() => sortTable("date")}
                >
                  Sales Invoice Date
                  <span>
                    <i className="ti ti-arrows-down-up sorting-icon"></i>
                  </span>
                </th>
              </tr>
            </thead>
            <tbody>
              {records.length > 0 ? (
                <>
                  {records.map((item: any, i: number) => {
                    let statusClass = "";
                    if (item.status === SalesInvoiceStatus.PENDING) {
                      statusClass = `bg-label-warning`;
                    } else if (item.status === SalesInvoiceStatus.COMPLETED) {
                      statusClass = `bg-label-success`;
                    } else {
                      statusClass = `bg-label-secondary`;
                    }
                    return (
                      <tr key={item.id}>
                        <td>{i + 1}</td>
                        <td>{item?.sales_contract?.buyer?.name || ""}</td>
                        <td>{item?.sales_contract?.contract_no || ""}</td>
                        <td>{item?.invoice_no || ""}</td>
                        <td>
                          {CURRENCY.USD.SYMBOL}
                          {formatAmount(item.price)}
                        </td>
                        <td>{item?.quantity || ""}</td>
                        <td>{item?.quantity_unit?.name || ""}</td>
                        <td>
                          {CURRENCY.USD.SYMBOL}
                          {formatAmount(item.total_amount)}
                        </td>
                        <td>
                          <span className={`badge ${statusClass} me-1`}>
                            {formatString(item?.status)}
                          </span>
                        </td>
                        <td>{formatDate(item.invoice_date)}</td>
                      </tr>
                    );
                  })}
                  <tr key="total-amount-row" style={{ fontWeight: "bold" }}>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td>
                      {CURRENCY.USD.SYMBOL}
                      {formatAmount(totalPrice)}
                    </td>
                    <td>{totalQuantity}</td>
                    <td></td>
                    <td>
                      {CURRENCY.USD.SYMBOL}
                      {formatAmount(totalAmount)}
                    </td>
                    <td></td>
                    <td></td>
                  </tr>
                </>
              ) : (
                <tr>
                  <td colSpan={10} className="text-center">
                    No records found
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        <Pagination
          page={page}
          perPage={perPage}
          totalRecords={totalRecords}
          totalPages={totalPages}
          records={records}
          prevButton={prevButton}
          nextButton={nextButton}
        />
      </div>
    </>
  );
};

export default BuyerWiseSalesReport;
